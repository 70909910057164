import React from "react";
import styled from "styled-components";
import theme from "../../../theme";

import Flex from "../../ui/flex";
import Banner from "../../../assets/img/newLandingBannerLight.png";

const StyledContainer = styled(Flex)`
  background-color: ${theme.colors.white};
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-image: url(${Banner});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const TextArea = styled.div`
  width: 100%;
  height: 100%;
  font-family: "Open Sans";
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MainText = styled.div`
  color: #585858;
  margin: 0 20px 1rem 20px;
  font-weight: 500;
  font-size: 1.6rem;
  text-transform: uppercase;
  text-shadow: 0px 1px 4px white;
`;

const SubText = styled.div`
  color: #585858;
  margin: 0 20px 1rem 20px;
  font-weight: 300;
  font-size: 1.7rem;
  text-transform: uppercase;
  text-shadow: 0px 1px 4px white;
`;

const MobileNotSupported = () => {
  return (
    <StyledContainer>
      <TextArea>
        <MainText>we want to make sure you have the best experience</MainText>
        <SubText>
          that's why TeamSource is not available for mobile devices at the
          moment
        </SubText>
      </TextArea>
    </StyledContainer>
  );
};

export default MobileNotSupported;
