import { createActions } from "redux-actions";

export const MESSAGES_GET = "MESSAGES_GET";
export const MESSAGES_GET_SUCCESS = "MESSAGES_GET_SUCCESS";
export const MESSAGES_GET_FAILURE = "MESSAGES_GET_FAILURE";

export const {
  messagesGet,
  messagesGetSuccess,
  messagesGetFailure,
} = createActions({}, MESSAGES_GET, MESSAGES_GET_SUCCESS, MESSAGES_GET_FAILURE);
