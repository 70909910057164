import React from "react";
import theme from "../../../theme";

import Flex from "@ui/flex";
import Button from "@ui/button";
import Text from "@ui/text";
import Spinner from "@ui/spinner";
import ModalRemove from "@ui/modal-remove";
import SubNavbarTabs from "@ui/sub-navbar-tabs";

import EditAliasesModal from "./EditAliasesModal";

import { CredentialsList } from "../integrations/";
import { CredentialsIntro } from "../integrations/";
import { ApplicationsIntro } from "../integrations/";

//icons
import Google from "@ui/icons/google";
import Microsoft from "@ui/icons/microsoft";

const Integrations = ({
  viewport,
  integrations,
  newCredentialHandler,
  isRemoveActive,
  isEditActive,
  itemSelected,
  closeRemoveModalHandler,
  closeEditModalHandler,
  removeIntegrationHandler,
  rowMenuOptions,
}) => {
  return (
    <Flex
      paddingTop={"64px"}
      alignItems={"center"}
      minHeight={"100vh"}
      width={"100%"}
      flexDirection={"column"}
      justifyContent={"flex-start"}
      px={{ sm: 2, md: 0 }}
      overflow="hidden"
      backgroundColor={theme.colors.lighterGrey}
    >
      {isRemoveActive && (
        <ModalRemove
          removeItem={itemSelected}
          title="Remove Integration"
          handleRemoveClick={removeIntegrationHandler}
          closeModal={closeRemoveModalHandler}
        />
      )}
      {isEditActive && (
        <EditAliasesModal
          provider={itemSelected}
          closeModal={closeEditModalHandler}
        />
      )}
      {integrations.loading && <Spinner />}
      <SubNavbarTabs labels={["Credentials", "Applications"]}>
        {integrations.data?.data?.length < 1 ? (
          <CredentialsIntro
            newCredentialHandler={newCredentialHandler}
          />
        ) : (
          <CredentialsList
            viewport={viewport}
            newCredentialHandler={newCredentialHandler}
            integrations={integrations}
            rowMenuOptions={rowMenuOptions}
          />
        )}

        <ApplicationsIntro viewport={viewport} />
      </SubNavbarTabs>
    </Flex>
  );
};

export default Integrations;
