import React from "react";
import styled from "styled-components";
import { flex, layout } from "styled-system";
import theme from "../../../theme";

// Components
import Box from "../box";
import Workspace from "../sidebar-main-btn/index";

//icons
import Help from "../icons/help";
import TalentPool from "../icons/database";
import People from "../icons/people";
import Project from "../icons/project";
import Workflow from "../icons/workflow";
import Config from "../icons/config";
import Integration from "../icons/integration";
import Sequence from "../icons/template";

const StyledContainer = styled(Box)`
  position: relative;
  width: auto;
  ${({ onClick }) => onClick && `cursor: pointer;`}
  ${flex}
  ${layout}
`;

const StyledItem = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  color: ${theme.colors.grey};
  font-size: 1.3rem;
`;

const StyledIcon = styled.div`
  height: 25px;
  width: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 7px;
`;

const StyledContent = styled.div`
  cursor: default;
`;

const BreadcrumbItem = ({
  workspace, //true, full name of the workspace
  wsInitials, //workspace initials
  talentpool, //true
  talentsearch, //true
  integrations,
  sequence,
  config,
  profile,
  help, //true
  people, //true, needs prop value
  project, // true, needs prop value
  workflow, //true, needs prop value
  icon, //new icon
  iconLink, //add icon style when it's a link, direction as value
  value, //Title Text
  clicked, //to redirect
  ...props
}) => {
  return (
    <StyledContainer {...props} onClick={clicked}>
      <StyledItem>
        {workspace ? (
          <Workspace variant="circle" content={wsInitials} onClick={clicked} />
        ) : null}
        {talentpool ? (
          <Workspace
            variant="blue"
            icon={<TalentPool variant="white" />}
            onClick={clicked}
          />
        ) : null}
        {talentsearch ? (
          <Workspace
            variant="blue"
            icon={<TalentPool variant="white" />}
            onClick={clicked}
          />
        ) : null}
        {profile ? (
          <Workspace
            variant="blue"
            icon={<TalentPool variant="white" />}
            onClick={clicked}
          />
        ) : null}
        {help ? (
          <Workspace
            variant="blue"
            icon={<Help variant="white" />}
            onClick={clicked}
          />
        ) : null}
        {config ? (
          <Workspace
            variant="blue"
            icon={<Config variant="white" />}
            onClick={clicked}
          />
        ) : null}
        {project ? (
          <StyledIcon onClick={clicked}>
            <Project variant="white" linkable={iconLink} />
          </StyledIcon>
        ) : null}
        {people ? (
          <StyledIcon onClick={clicked}>
            <People variant="white" linkable={iconLink} />
          </StyledIcon>
        ) : null}
        {workflow ? (
          <StyledIcon onClick={clicked}>
            <Workflow variant="white" linkable={iconLink} />
          </StyledIcon>
        ) : null}
        {integrations ? (
          <StyledIcon onClick={clicked}>
            <Integration variant="white" />
          </StyledIcon>
        ) : null}
        {sequence ? (
          <StyledIcon onClick={clicked}>
            <Sequence variant="white" linkable={iconLink} />
          </StyledIcon>
        ) : null}
        {icon ? <StyledIcon onClick={clicked}>{icon}</StyledIcon> : null}
        {value ? (
          <StyledContent>{value}</StyledContent>
        ) : workspace ? (
          <StyledContent>{workspace}</StyledContent>
        ) : talentpool ? (
          <StyledContent>Talent Pool</StyledContent>
        ) : talentsearch ? (
          <StyledContent>Talent Search</StyledContent>
        ) : help ? (
          <StyledContent>Help Center</StyledContent>
        ) : config ? (
          <StyledContent>Settings</StyledContent>
        ) : null}
      </StyledItem>
    </StyledContainer>
  );
};

export default BreadcrumbItem;
