import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as R from "ramda";

//Actions
import { workflowUpdate, sequencesGet, workflowGet } from "../../actions";

//Reducers
import {
  getSequences,
  getWorkflowsDataIncluded,
  getWorkspaceCurrent,
} from "../../reducer";

//Utils
import { getRelated } from "../../utils";

//Component
import { EditWorkflowModal } from "../../components/domain/workflows";

const EditWorkflowModalContainer = ({
  workflow,
  isEditActive,
  closeEditModalHandler,
  stages,
}) => {
  const dispatch = useDispatch();
  const workspaceId = useSelector(getWorkspaceCurrent);
  const [dirtyChanges, setDirtyChanges] = useState({ stages: [] });
  const [showModal, setState] = useState(isEditActive);
  const [activeStageIndex, setActiveStageIndex] = useState(0);
  const sequences = useSelector(getSequences);

  const workflowsStagesList = (workflow) => {
    let relatedStages = getRelated(workflow.relationships.stages.data, stages);

    return relatedStages.map((stage) => ({
      id: stage.id,
      name: stage.attributes.name,
      sequence_id: stage.attributes.sequence_id,
      sequence: searchSequenceName(stage.attributes.sequence_id),
      position: stage.attributes.position,
      on_response_stage_id: stage.attributes.on_response_stage_id,
      on_response_stage: searchStageOnResponse(
        stage.attributes.on_response_stage_id
      ),
    }));
  };

  const handleAddStage = () => {
    const newStages = [
      ...dirtyChanges.stages,
      { position: dirtyChanges.stages.length },
    ];

    setDirtyChanges({ ...dirtyChanges, stages: newStages });
  };

  const handleRemoveStage = (index) => {
    const newStages = R.remove(index, 1, dirtyChanges.stages);
    let newArrayStages = [];
    newStages.forEach((_, index) => {
      newArrayStages[index] = { ...newStages[index], position: index };
    });

    setDirtyChanges({ ...dirtyChanges, stages: newArrayStages });
  };

  const handleInputChange = (event, index) => {
    if (index !== undefined && index !== null) {
      const stages = [...dirtyChanges.stages];

      stages[index] = {
        ...stages[index],
        name: event.target.value,
        description: event.target.value,
      };

      setDirtyChanges({
        ...dirtyChanges,
        stages: stages,
      });
    } else {
      setDirtyChanges({
        ...dirtyChanges,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleSubmitClick = () => {
    dispatch(
      workflowUpdate({
        workspace_id: workspaceId,
        id: workflow.id,
        name: dirtyChanges.name,
        description: dirtyChanges.description,
        stages: dirtyChanges.stages,
      })
    );
    dispatch(workflowGet({ workspace_id: workspaceId, id: workflow.id }));
    setState(false);
    closeEditModalHandler();
  };

  useEffect(() => {
    let attributes = {
      name: workflow.attributes.name,
      description: workflow.attributes.description,
      stages: workflowsStagesList(workflow, stages),
    };

    setDirtyChanges(attributes);
  }, [workflow]);

  useEffect(() => {
    if (workspaceId) {
      dispatch(sequencesGet({ workspace_id: workspaceId, limit: 9999 }));
    }
  }, [workspaceId]);

  const searchSequenceId = (sequenceName) => {
    return sequences.data.filter(
      (item) => item.attributes.name === sequenceName
    )[0].attributes.id;
  };

  const searchSequenceName = (sequenceId) => {
    if (sequenceId) {
      return sequences.data.filter(
        (item) => item.attributes.id === sequenceId
      )[0].attributes.name;
    }
  };

  const searchStageOnResponse = (stage_id) => {
    if (stage_id) {
      return stages.filter((item) => item.attributes.id === stage_id)[0]
        .attributes.name;
    }
  };

  const sequenceDropdownHandler = (name, value, index) => {
    const stages = [...dirtyChanges.stages];

    if (name.includes("sequence")) {
      stages[index] = {
        ...stages[index],
        [name]: value,
        ["sequence_id"]: searchSequenceId(value),
        ["sequence"]: value,
      };
    }
    if (name === "on_response_stage") {
      stages[index] = {
        ...stages[index],
        [name]: value,
        ["on_response_stage_position"]: searchStagePosition(value),
      };
    }

    setDirtyChanges({
      ...dirtyChanges,
      stages: stages,
    });
  };

  const searchStagePosition = (stageName) => {
    return dirtyChanges.stages.filter((item) => item.name === stageName)[0]
      .position;
  };

  const handleCleanAction = (rowClose, index) => {
    const stages = [...dirtyChanges.stages];
    if (rowClose) {
      delete stages[index]["sequence"];
      delete stages[index]["sequence_id"];
      delete stages[index]["on_response_stage"];
      delete stages[index]["on_response_stage_id"];
      delete stages[index]["on_response_stage_position"];
    } else {
      stages[index] = {
        ...stages[index],
        ["sequence"]: null,
        ["sequence_id"]: null,
        ["on_response_stage"]: null,
        ["on_response_stage_id"]: null,
        ["on_response_stage_position"]: null,
      };
    }
    setDirtyChanges({
      ...dirtyChanges,
      stages: stages,
    });
  };

  return (
    <EditWorkflowModal
      dispatch={dispatch}
      workflow={workflow}
      dirtyChanges={dirtyChanges}
      handleSubmitClick={handleSubmitClick}
      handleChange={handleInputChange}
      handleAddStage={handleAddStage}
      showModal={showModal}
      openModal={() => setState(true)}
      closeModal={() => closeEditModalHandler()}
      sequenceDropdownHandler={sequenceDropdownHandler}
      sequences={sequences}
      handleCleanAction={handleCleanAction}
      handleRemoveStage={handleRemoveStage}
      workflowsStagesList={workflowsStagesList}
      activeStageIndex={activeStageIndex}
      setActiveStageIndex={setActiveStageIndex}
      asyncReload={() =>
        dispatch(sequencesGet({ workspace_id: workspaceId, limit: 9999 }))
      }
    />
  );
};

export default EditWorkflowModalContainer;
