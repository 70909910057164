import axios from "axios";
import { assocPath } from "ramda";
import moment from "moment";

const handleRequest = (req) => {
  req.headers["access-token"] = localStorage.getItem("access-token");
  req.headers["uid"] = localStorage.getItem("uid");
  req.headers["client"] = localStorage.getItem("client");
  req.headers["expiry"] = localStorage.getItem("expiry");

  req.metadata = { start: moment() };

  return assocPath(
    ["headers", "Authorization"],
    `Bearer ${localStorage.getItem("access-token")}`,
    req
  );
};

const handleResponse = (resp) => {
  if (resp.headers["access-token"] && resp.headers["access-token"] !== '') {
    localStorage.setItem("access-token", resp.headers["access-token"]);
    localStorage.setItem("uid", resp.headers.uid);
    localStorage.setItem("client", resp.headers.client);
    localStorage.setItem("expiry", resp.headers.expiry);
  }

  return resp;
};

const handleError = (err) => {
  if (err.response.status === 401) {
    localStorage.clear();
    window.location.href = '/sign_in';
    sessionStorage.setItem("isExpired", true);
  }
  return Promise.reject(err);
};

export default () => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: { Accept: "application/json" },
  });

  instance.generateInterceptors = (store) => {
    instance.interceptors.request.use(handleRequest);
    instance.interceptors.response.use(handleResponse, handleError);
  }

  return instance;
};
