import { hot } from "react-hot-loader/root";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { bool } from "prop-types";
import { Route, Switch, useLocation, Redirect } from "react-router-dom";
import { space } from "styled-system";
import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { AuthenticatedNavbar, UnauthenticatedNavbar } from "../ui/navigation";

import { WorkflowIntro } from "../domain/workflows";
import { PeopleIntro } from "../domain/people";
import { ProjectIntro } from "../domain/projects";
import { TalentPoolIntro } from "../domain/talent-pool";
import LandingPage from "../domain/landing/LandingPage";
import ServicesPage from "../domain/landing/ServicesPage";
import PrivacyPolicyPage from "../domain/landing/PrivacyPolicy";

import Spinner from "../../components/ui/spinner";

//Containers
import People from "../../containers/people/PeopleContainer";
import Project from "../../containers/projects/ProjectContainer";
import Projects from "../../containers/projects/ProjectsContainer";
import SideBarNavigation from "../../containers/sidebar/SideBarNavigationContainer";
import SignIn from "../../containers/account/SignInContainer";
import SignUp from "../../containers/account/SignUpContainer";
import ResetPassword from "../../containers/account/ResetPasswordContainer";
import UpdatePassword from "../../containers/account/UpdatePasswordContainer";
import Workflows from "../../containers/workflows/WorkflowsContainer";
import Sequences from "../../containers/sequences/SequencesContainer";
import ProfilePage from "../../containers/people/PersonProfileContainer";
import TalentPool from "../../containers/talent-pool/TalentPoolContainer";
import TalentSearch from "../../containers/talent-search/TalentSearchContainer";
import WorkspacesIntro from "../../containers/workspaces/WorkspaceContainer";
import AccountSettings from "../../containers/account/AccountSettingsContainer";
import Omniauth from "../../containers/account/OmniauthContainer";
import Integrations from "../../containers/integrations/IntegrationsContainer";
import MobileNotSupported from "@components/domain/account/MobileNotSupported";

//Redirects
import WorkspaceHomeRedirect from "@components/redirects/WorkspaceHomeRedirect";

//Actions
import { signOutSet, setLocationPath } from "../../actions";

//Reducers
import { getAppLoading, getAppLoaded } from "../../reducer";

import ErrorBoundary from "../airbrake/index";
import { view } from "ramda";

const Main = styled.main`
  display: block;
  position: relative;
  background-color: #f2f2f2;
  ${space}
`;

const propTypes = {
  isAuthenticated: bool.isRequired,
};

const routes = [
  { to: "/", label: "Home" },
  { to: "/sign_out", label: "Sign Out" },
  { to: "/sign_in", label: "Sign In" },
  { to: "/sign_up", label: "Sign Up" },
  { to: "/workspace_intro", label: "Workspaces Intro" },
  { to: "/workflow_intro", label: "Workflow Intro" },
  { to: "/people_intro", label: "People Intro" },
  { to: "/project_intro", label: "Project Intro" },
  { to: "/talent_pool_intro", label: "Talent Pool Intro" },
  { to: "/people", label: "People" },
  { to: "/workflows", label: "Workflows" },
  { to: "/sequences", label: "Sequences" },
  { to: "/projects", label: "Projects" },
  { to: "/project", label: "Project" },
  { to: "/new_workspace", label: "Workspace Modal" },
  { to: "/profile", label: "Profile" },
  { to: "/talent_pool", label: "Talent Pool" },
  { to: "/talent_search", label: "Talent Search" },
];

const App = ({ isAuthenticated }) => {
  const dispatch = useDispatch();
  const isAppLoaded = useSelector(getAppLoaded);
  const appLoading = useSelector(getAppLoading);
  const [activeSection, setActiveSection] = useState({});
  const [viewport, setViewport] = useState(window.innerWidth);
  const [viewHeight, setViewHeight] = useState(window.innerHeight);
  const currentPath = useLocation();

  useEffect(() => {
    const currentPathUrl = window.location.pathname;
    setActiveSection(routes.filter((item) => item.to === currentPathUrl));
  }, [isAuthenticated]);

  useEffect(() => {
    dispatch(setLocationPath(currentPath));
    // eslint-disable-next-line
  }, [window.location.pathname, currentPath]);

  const handleSignOutClick = (e) => {
    dispatch(
      signOutSet({
        redirectPath: "/",
      })
    );
  };

  return (
    <>
      {!isAuthenticated && !isAppLoaded && !appLoading ? (
        <>
          <UnauthenticatedNavbar
            location={currentPath.pathname}
            viewport={viewport}
          />

          <Switch>
            <Route path="/" exact component={LandingPage} />
            <Route path="/services" exact component={ServicesPage} />
            <Route path="/privacy_policy" exact component={PrivacyPolicyPage} />
            <Route path="/sign_in" exact component={SignIn} />
            <Route
              path="/sign_up"
              component={(props) => <SignUp {...props} viewport={viewport} />}
            />
            <Route path="/reset_password" exact component={ResetPassword} />
            <Route path="/update_password" exact component={UpdatePassword} />
            <Route path="/auth/:provider" component={Omniauth} />

            {viewport < 800 && (
              <Route path="/mobile_warning" component={MobileNotSupported} />
            )}
          </Switch>
          <ToastContainer
            position="top-right"
            autoClose={9000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            style={{ zIndex: 999999 }}
          />

          <ErrorBoundary> </ErrorBoundary>
        </>
      ) : (
        <>
          {isAuthenticated && isAppLoaded && !appLoading && viewport > 800 ? (
            <>
              <AuthenticatedNavbar
                activeSection={activeSection}
                handleSignOutClick={handleSignOutClick}
                location={currentPath.pathname}
              />
              <SideBarNavigation viewport={viewHeight} />

              <Main>
                <div style={{ marginLeft: "60px", height: "100%" }}>
                  <Switch>
                    <Route path="/sign_out" exact component={"SignOut"} />
                    <Route path="/sign_in" exact component={SignIn} />
                    <Route
                      path="/sign_up"
                      component={(props) => (
                        <SignUp {...props} viewport={viewport} />
                      )}
                    />
                    <Route
                      path="/privacy_policy"
                      exact
                      component={PrivacyPolicyPage}
                    />
                    <Route path="/" exact>
                      <Redirect to="talent_pool" />
                    </Route>
                    <Route
                      path="/workspaces/me/home"
                      component={WorkspaceHomeRedirect}
                    />
                    <Route
                      path="/workspaces/:id/home"
                      component={WorkspacesIntro}
                    />

                    {/* redirect when login */}
                    <Route
                      path="/workspaces/:id/workflow_intro"
                      component={WorkflowIntro}
                    />
                    {/* redirect when workflow is empty */}
                    <Route
                      path="/workspaces/:id/people_intro"
                      component={PeopleIntro}
                    />
                    {/* redirect when people is empty */}
                    <Route
                      path="/workspaces/:id/project_intro"
                      component={ProjectIntro}
                    />
                    <Route
                      path="/workspaces/:id/talent_pool_intro"
                      component={TalentPoolIntro}
                    />
                    {/* redirect when talent pool is empty */}
                    <Route
                      path="/workspaces/:id/people"
                      component={(props) => (
                        <People {...props} viewport={viewport} />
                      )}
                    />
                    <Route
                      path="/workspaces/:id/workflows"
                      component={(props) => (
                        <Workflows {...props} viewport={viewport} />
                      )}
                    />
                    <Route
                      path="/workspaces/:id/sequences"
                      exact
                      component={(props) => (
                        <Sequences {...props} viewport={viewport} />
                      )}
                    />
                    <Route
                      path="/workspaces/:id/integrations"
                      component={(props) => (
                        <Integrations {...props} viewport={viewport} />
                      )}
                    />
                    <Route
                      path="/workspaces/:id/projects"
                      exact
                      component={(props) => (
                        <Projects {...props} viewport={viewport} />
                      )}
                    />
                    <Route
                      path="/workspaces/:workspace_id/project/:id"
                      exact
                      component={(props) => (
                        <Project
                          {...props}
                          viewport={viewport}
                          viewHeight={viewHeight}
                        />
                      )}
                    />
                    <Route
                      path="/workspaces/:workspace_id/profile/:id"
                      exact
                      component={(props) => (
                        <ProfilePage {...props} viewport={viewport} />
                      )}
                    />
                    {/* add person id to the path -> person/:id */}
                    <Route
                      path="/profile/:id"
                      exact
                      component={(props) => (
                        <ProfilePage {...props} viewport={viewport} />
                      )}
                    />
                    {/* add person id to the path -> person/:id */}
                    <Route
                      path="/talent_pool"
                      exact
                      component={(props) => (
                        <TalentPool {...props} viewport={viewport} />
                      )}
                    />
                    <Route
                      path="/account_settings"
                      exact
                      component={(props) => (
                        <AccountSettings {...props} viewport={viewport} />
                      )}
                    />
                    <Route
                      path="/workspaces/:workspace_id/integrations"
                      exact
                      component={Integrations}
                    />
                    <Route
                      path="/talent_search"
                      component={(props) => (
                        <TalentSearch {...props} viewport={viewport} />
                      )}
                    />
                  </Switch>
                </div>

                <ToastContainer
                  position="top-right"
                  autoClose={9000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  style={{ zIndex: 999999 }}
                />
                <ErrorBoundary> </ErrorBoundary>
              </Main>
            </>
          ) : isAuthenticated &&
            isAppLoaded &&
            !appLoading &&
            viewport < 800 ? (
            <>
              <UnauthenticatedNavbar
                location={currentPath.pathname}
                viewport={viewport}
              />

              <Switch>
                <Route path="/" exact component={LandingPage} />
                <Route path="/mobile_warning" component={MobileNotSupported} />
              </Switch>
              <ToastContainer
                position="top-right"
                autoClose={9000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                style={{ zIndex: 999999 }}
              />

              <ErrorBoundary> </ErrorBoundary>
            </>
          ) : (
            <>
              <Spinner />
            </>
          )}
        </>
      )}
    </>
  );
};

App.propTypes = propTypes;

export default hot(App);
