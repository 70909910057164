import { createActions } from "redux-actions";

// sign in actions
export const PEOPLE_GET = "PEOPLE_GET";
export const PEOPLE_GET_SUCCESS = "PEOPLE_GET_SUCCESS";
export const PEOPLE_GET_FAILURE = "PEOPLE_GET_FAILURE";

export const PERSON_GET = "PERSON_GET";
export const PERSON_GET_SUCCESS = "PERSON_GET_SUCCESS";
export const PERSON_GET_FAILURE = "PERSON_GET_FAILURE";

export const PERSON_UPDATE = "PERSON_UPDATE";
export const PERSON_UPDATE_SUCCESS = "PERSON_UPDATE_SUCCESS";
export const PERSON_UPDATE_FAILURE = "PERSON_UPDATE_FAILURE";

export const PEOPLE_DATA_CLEAN = "PEOPLE_DATA_CLEAN";

export const TALENT_POOL_GET = "TALENT_POOL_GET";
export const TALENT_POOL_PAGE_GET = "TALENT_POOL_PAGE_GET";
export const TALENT_POOL_GET_SUCCESS = "TALENT_POOL_GET_SUCCESS";
export const TALENT_POOL_GET_FAILURE = "TALENT_POOL_GET_FAILURE";

export const TALENT_POOL_PERSON_UPDATE = "TALENT_POOL_PERSON_UPDATE";
export const TALENT_POOL_UPDATE_SUCCESS = "TALENT_POOL_UPDATE_SUCCESS";
export const TALENT_POOL_UPDATE_FAILURE = "TALENT_POOL_UPDATE_FAILURE";

export const PEOPLE_IMPORT = "PEOPLE_IMPORT";
export const PEOPLE_IMPORT_SUCCESS = "PEOPLE_IMPORT_SUCCESS";
export const PEOPLE_IMPORT_FAILURE = "PEOPLE_IMPORT_FAILURE";

export const {
  peopleGet,
  peopleGetSuccess,
  peopleGetFailure,
  personGet,
  personGetSuccess,
  personGetFailure,
  personUpdate,
  personUpdateSuccess,
  personUpdateFailure,
  peopleDataClean,
  talentPoolGet,
  talentPoolPageGet,
  talentPoolGetSuccess,
  talentPoolGetFailure,
  talentPoolPersonUpdate,
  talentPoolUpdateSuccess,
  talentPoolUpdateFailure,
  peopleImport,
  peopleImportSuccess,
  peopleImportFailure,
} = createActions(
  {},
  PEOPLE_GET,
  PEOPLE_GET_SUCCESS,
  PEOPLE_GET_FAILURE,
  PERSON_GET,
  PERSON_GET_SUCCESS,
  PERSON_GET_FAILURE,
  PERSON_UPDATE,
  PERSON_UPDATE_SUCCESS,
  PERSON_UPDATE_FAILURE,
  PEOPLE_DATA_CLEAN,
  TALENT_POOL_GET,
  TALENT_POOL_PAGE_GET,
  TALENT_POOL_GET_SUCCESS,
  TALENT_POOL_GET_FAILURE,
  TALENT_POOL_PERSON_UPDATE,
  TALENT_POOL_UPDATE_SUCCESS,
  TALENT_POOL_UPDATE_FAILURE,
  PEOPLE_IMPORT,
  PEOPLE_IMPORT_SUCCESS
);
