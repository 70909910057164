import React from "react";
import theme from "@theme";
import styled from "styled-components";

import Box from "@ui/box";
import Row from "@ui/row";
import Text from "@ui/text";
import Flex from "@ui/flex";
import RowMenu from "@ui/row-menu-button";
import TableBar from "@ui/table-bar/index";
import GoogleButton from "@ui/google/googleSignInBtn";
import MicrosoftButton from "@ui/microsoft/microsoftSignInBtn";

//icons
import Google from "@ui/icons/google";
import Microsoft from "@ui/icons/microsoft";
import Valid from "@ui/icons/valid";
import Pending from "@ui/icons/waiting";
import Warning from "@ui/icons/warning";
import Reload from "@ui/icons/reload";

const StyledIcon = styled.div`
  ${(props) =>
    props.small ? "width: 16px; height:16px;" : "width: 20px; height:20px;"}
  margin-left: 5px;
`;

const CredentialInfo = styled(Text)`
  margin: 5px;
  align-items: center;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  color: ${theme.colors.grey};
  flex-wrap: nowrap;
  cursor: pointer;
`;

const StyledText = styled.div`
  white-space: pre-line;
  margin: 5px;
  align-items: center;
  font-size: 0.9rem;
  display: flex;
  flex-direction: row;
  color: ${theme.colors.grey};
  font-weight: 200;
`;

const StyledMenu = styled.div`
  display: flex;
  height: 60px;
  align-items: flex-start;
  justify-content: flex-end;

  * {
    height: 20px;
    width: 20px;
  }
`;

const IntegrationsList = ({
  viewport,
  integrations,
  rowMenuOptions,
  newCredentialHandler,
}) => {
  const handleReconnect = (provider) => {
    if (provider === "Google") {
      newCredentialHandler("google_oauth2");
    } else if (provider === "Microsoft") {
      newCredentialHandler("microsoft_graph_auth");
    }
  };

  return (
    <Flex
      marginTop="3%"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width={viewport > 2000 ? "85%" : "95%"}
      backgroundColor={theme.colors.lighterGrey}
      marginBottom="30px"
    >
      <TableBar
        iconButtons={[
          <StyledText key="label">
            Create a new credential by signing in with:
          </StyledText>,
          <GoogleButton
            key="google"
            onClick={() => {
              newCredentialHandler("google_oauth2");
            }}
          />,
          <MicrosoftButton
            key="microsoft"
            onClick={() => {
              newCredentialHandler("microsoft_graph_auth");
            }}
          />,
        ]}
      />
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="auto"
        backgroundColor="transparent"
      >
        {integrations.data.data?.map((integration, index) => (
          <Row key={index}>
            <Box width="35%">
              <CredentialInfo>
                {integration.attributes.name || "undefined"}
              </CredentialInfo>
            </Box>
            <Flex width="15%" alignItems="center">
              <StyledText>Provider</StyledText>
              {integration.attributes.provider_type === "Google" && (
                <StyledIcon>
                  <Google />
                </StyledIcon>
              )}
              {integration.attributes.provider_type === "Microsoft" && (
                <StyledIcon>
                  <Microsoft />
                </StyledIcon>
              )}
            </Flex>
            <Flex width="40%" alignItems="center" flexDirection="column">
              <Text>
                <b>Aliases</b>
              </Text>
              <Text>{integration.attributes.aliases.join(', ')}</Text>
            </Flex>
            <Flex width="10%" alignItems="center" flexDirection="column">
              <Flex>
                <StyledText>{integration.attributes.health}</StyledText>
                {integration.attributes.health === "Tested" && (
                  <StyledIcon small>
                    <Valid variant="blue" />
                  </StyledIcon>
                )}
                {integration.attributes.health === "Pending" && (
                  <StyledIcon>
                    <Pending variant="lightRed" />
                  </StyledIcon>
                )}
                {integration.attributes.health === "Error" && (
                  <StyledIcon>
                    <Warning variant="lightRed" />
                  </StyledIcon>
                )}
              </Flex>
              <Flex>
                {integration.attributes.health === "Error" && (
                  <Text fontSize="0.8rem" color="lightRed">
                    {integration.attributes.fail_message}
                  </Text>
                )}
              </Flex>
            </Flex>

            <StyledMenu>
              {integration.attributes.health === "Error" &&
                <StyledIcon>
                  <Reload
                    reconnect
                    variant="red"
                    onClick={() =>
                      handleReconnect(integration.attributes.provider_type)
                    }
                  />
                </StyledIcon>
              }
            </StyledMenu>
            <RowMenu
              width="2%"
              icon="config"
              options={rowMenuOptions}
              resource={integration}
            />
          </Row>
        ))}
      </Flex>
    </Flex>
  );
};

export default IntegrationsList;
