import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.linkable
        ? `&:hover {
      filter: drop-shadow(-1px 1px 1px #4BAAC8); cursor: pointer;}`
        : ""}

    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black);" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

export const ThumbUpLine = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <g>
        <path
          d="M7.7,18.8h-3c-0.3,0-0.5-0.3-0.5-0.6V9.4c0-0.4,0.2-0.6,0.5-0.6h2.9c0.4,0,0.6,0.3,0.6,0.6v8.8
		C8.2,18.6,7.9,18.8,7.7,18.8z M5.3,17.6h1.8V10H5.3V17.6z"
        />
        <path
          d="M17,18.8H9.8c-0.4,0-0.6-0.3-0.6-0.6V9.4c0-0.4,0.3-0.6,0.6-0.6c1.7,0,2.3-1.4,2.8-3.3c0.4-1.3,0.7-2.6,2-2.6
		c0.7,0,1.2,0.3,1.6,0.8c1,1.3,0.4,3.8,0.1,5.1h3.4c0.6,0,1.2,0.3,1.5,0.8c0.3,0.4,0.4,1.1,0.2,1.6l-1.9,5.7
		C19.1,18,18.1,18.8,17,18.8z M10.2,17.3h6.6c0.7,0,1.4-0.4,1.6-1.1l1.9-5.7c0.1-0.1,0.1-0.3-0.1-0.5c-0.1-0.1-0.3-0.2-0.5-0.2h-4.2
		c-0.2,0-0.4,0-0.5-0.2c-0.1-0.1-0.2-0.3-0.1-0.5c0.5-1.4,0.9-3.9,0.3-4.8C15.1,4.2,15,4,14.5,4c-0.4,0-0.7,0.9-0.9,1.8
		c-0.5,1.7-1.1,3.8-3.4,4.1C10.2,9.9,10.2,17.3,10.2,17.3z"
        />
      </g>
      <path
        d="M12.5,23.7c-6.2,0-11.2-5-11.2-11.2s5-11.2,11.2-11.2s11.2,5,11.2,11.2S18.7,23.7,12.5,23.7 M12.5,25
	C19.4,25,25,19.4,25,12.5S19.4,0,12.5,0S0,5.6,0,12.5S5.6,25,12.5,25L12.5,25z"
      />
    </StyledSvg>
  );
};

export const ThumbUpSolid = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <path
        fill="#4BAAC8"
        d="M25,12.5C25,5.6,19.4,0,12.5,0S0,5.6,0,12.5S5.6,25,12.5,25S25,19.4,25,12.5z M8.6,17.7c0,0.3-0.3,0.6-0.6,0.6
	H5.3c-0.3,0-0.5-0.3-0.5-0.6V9.6C4.8,9.2,5,9,5.3,9H8c0.3,0,0.6,0.3,0.6,0.6V17.7z M20.8,11.2L19,16.5c-0.4,1-1.3,1.7-2.4,1.8H9.9
	c-0.4,0-0.6-0.3-0.6-0.6V9.6C9.3,9.2,9.6,9,9.9,9c1.6,0,2.7-1,3.2-2.8c0.4-1.2,0.1-2.7,1.3-2.7c0.6,0,1.1,0.2,1.5,0.7
	C16.8,5.5,16.3,7.8,16,9h3.1c0.6,0,1.1,0.3,1.4,0.7C20.8,10.1,20.9,10.7,20.8,11.2z"
      />
    </StyledSvg>
  );
};
