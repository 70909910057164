import React from "react";
import styled from "styled-components";
import { flex, layout } from "styled-system";
import useBreadcrumbs from "use-react-router-breadcrumbs";

import { useHistory } from "react-router-dom";

// Components
import Box from "../box";
import BreadcrumbItem from "../breadcrumbs-item/index";

const StyledContainer = styled(Box)`
  position: relative;
  width: auto;
  display: flex;
  flex-wrap: nowrap;
  ${({ onClick }) => onClick && `cursor: pointer;`}
  ${flex}
  ${layout}
`;

const StyledBreadcrumb = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  width: auto;
  white-space: nowrap;
  margin: 5px 10px;
  background-color: transparent;
`;

const Breadcrumbs = ({
  workspaceName, //for the workspace button
  projectName, //for the breadcrumb inside a project
  person, //for the breadcrumb inside a profile
}) => {
  /* ------ redirect using the icon ------ */
  const history = useHistory();

  const RedirectPeople = (e) => {
    e.stopPropagation();
    history.goBack();
  };

  const RedirectProject = (e) => {
    e.stopPropagation();
    history.goBack();
  };
  /* ------ redirect using the icon ------ */
  let wsName = workspaceName.charAt(0) + workspaceName.charAt(1); //get the letters from workspace Name

  /* ------ elements that are concatenated creating the breadcrumb ------ */
  const Workspace = () => <BreadcrumbItem workspace wsInitials={wsName} />;

  const WorkspaceFull = () => <BreadcrumbItem value={workspaceName} />;

  const Talentpool = () => <BreadcrumbItem talentpool />;

  const Talentsearch = () => <BreadcrumbItem talentsearch />;

  const Profile = () => <BreadcrumbItem profile />;

  const Help = () => <BreadcrumbItem help />;

  const AccountSettings = () => <BreadcrumbItem config />;

  const Projects = () => <BreadcrumbItem project value="Projects" />;

  const WorkspacePeople = () => {
    return <BreadcrumbItem people value="People" />;
  };

  const ProfilePerson = () => {
    let personName = null;

    if (person.attributes) {
      personName =
        person.attributes.first_name + " " + person.attributes.last_name;
    }

    return (
      <BreadcrumbItem
        people
        value={personName}
        iconLink
        clicked={RedirectPeople}
      />
    );
  };

  const WorkspacePerson = () => {
    let personName = null;

    if (person.attributes) {
      personName =
        person.attributes.first_name + " " + person.attributes.last_name;
    }

    return (
      <BreadcrumbItem
        people
        value={personName}
        iconLink
        clicked={RedirectPeople}
      />
    );
  };

  const WorkspaceProject = () => (
    <BreadcrumbItem
      project
      value={projectName}
      iconLink
      clicked={RedirectProject}
    />
  );

  const WorkspaceInteg = () => (
    <BreadcrumbItem integrations value="Integrations" />
  );

  const WorkspaceWorkflow = () => <BreadcrumbItem workflow value="Workflows" />;

  const Sequence = () => <BreadcrumbItem sequence value="Sequences" />;

  /* ------ elements that are concatenated creating the breadcrumb ------ */

  /* ------ link path with breadcrumb section  ------ */
  const routes = [
    { path: "/", breadcrumb: null },
    { path: "/account_settings/", breadcrumb: AccountSettings },
    { path: "/help_center/", breadcrumb: Help },
    { path: "/profile/", breadcrumb: Profile },
    { path: "/profile/:id", breadcrumb: ProfilePerson },
    { path: "/talent_pool/", breadcrumb: Talentpool },
    { path: "/talent_search/", breadcrumb: Talentsearch },
    { path: "/workspaces", breadcrumb: Workspace },
    { path: "/workspaces/:id/", breadcrumb: null },
    { path: "/workspaces/:id/home", breadcrumb: WorkspaceFull },
    { path: "/workspaces/:id/integrations/", breadcrumb: WorkspaceInteg, },
    { path: "/workspaces/:id/people/", breadcrumb: WorkspacePeople },
    { path: "/workspaces/:id/people/:id", breadcrumb: null },
    { path: "/workspaces/:id/profile/", breadcrumb: WorkspacePerson },
    { path: "/workspaces/:id/profile/:id", breadcrumb: null },
    { path: "/workspaces/:id/project/", breadcrumb: WorkspaceProject },
    { path: "/workspaces/:id/project/:id", breadcrumb: null },
    { path: "/workspaces/:id/projects/", breadcrumb: Projects },
    { path: "/workspaces/:id/projects/:id", breadcrumb: null },
    { path: "/workspaces/:id/sequences/", breadcrumb: Sequence, },
    { path: "/workspaces/:id/workflows/", breadcrumb: WorkspaceWorkflow },
  ];

  const breadcrumbs = useBreadcrumbs(routes);
  /* ------ link path with breadcrumb section  ------ */

  return (
    <StyledContainer>
      {breadcrumbs.map(({ match, breadcrumb }) => (
        <StyledBreadcrumb key={match.url}>{breadcrumb}</StyledBreadcrumb>
      ))}
    </StyledContainer>
  );
};

export default Breadcrumbs;
