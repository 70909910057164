import React from "react";
import theme from "../../../theme";

import Box from "../../ui/box";
import Card from "../../ui/card";
import Image from "../../../assets/img/applications.svg";
import Flex from "../../ui/flex";

const ApplicationsIntro = ({ viewport }) => {
  return (
    <Flex
      alignItems={"center"}
      minHeight={"100vh"}
      width={"100%"}
      flexDirection={"column"}
      justifyContent={"flex-start"}
      px={{ sm: 2, md: 0 }}
      overflow="hidden"
      backgroundColor={theme.colors.lighterGrey}
    >
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        width={viewport > 2000 ? "85%" : "95%"}
        mt={"30px"}
        backgroundColor={theme.colors.lighterGrey}
      >
        <Card
          imageBlock={Image}
          textBlock={
            <Box marginTop="20px">
              <p
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                Coming Soon! Wait for it
              </p>
              <p
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                Here you will find the list of applications that you can
                integrate in TeamSourced, <br />
                ready for you to enable them globally for the Workspace. <br />
                <br />
                Once enabled you will be able to configure them within each of
                your projects.
              </p>
            </Box>
          }
        ></Card>
      </Flex>
    </Flex>
  );
};

export default ApplicationsIntro;
