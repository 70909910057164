import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//Reducers
import { getLimit } from "../../reducer";

//Component
import { EditPersonModal } from "../../components/domain/people";

const EditPersonModalContainer = ({
  person,
  isEditActive,
  closeEditModalHandler,
  handleSubmitEdit,
}) => {
  const dispatch = useDispatch();
  const limit = useSelector(getLimit);
  const [dirtyChanges, setDirtyChanges] = useState({});

  const handleDatepicker = (value, name) => {
    setDirtyChanges({
      ...dirtyChanges,
      [name]: value,
    });
  };

  const handleDropdown = (name, value) => {
    setDirtyChanges({
      ...dirtyChanges,
      [name]: value,
    });
  };

  const handleDropdownSelect = (name, value) => {
    setDirtyChanges({
      ...dirtyChanges,
      [name]: value,
    });
  };

  const handleInputChange = (event) => {
    setDirtyChanges({
      ...dirtyChanges,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmitClick = () => {
    if (Object.entries(dirtyChanges).length > 0)
      handleSubmitEdit({ id: person.id, ...dirtyChanges, limit });

    closeEditModalHandler();
  };

  useEffect(() => {
    setDirtyChanges({});
  }, [person]);

  return (
    <EditPersonModal
      dispatch={dispatch}
      person={person}
      dirtyChanges={dirtyChanges}
      handleSubmitClick={handleSubmitClick}
      handleChange={handleInputChange}
      handleDropdown={handleDropdown}
      handleDropdownSelect={handleDropdownSelect}
      showModal={isEditActive}
      closeModal={() => closeEditModalHandler()}
      handleDatepicker={handleDatepicker}
    />
  );
};

export default EditPersonModalContainer;
