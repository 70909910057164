import React, { useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import * as R from "ramda";

import SequenceModal from "./sequenceModal";
import Button from "@ui/button/index";

import { SequenceConfirmation, SequenceForm, StepForm } from ".";

//Icons
import Add from "@ui/icons/add";

const NewSequenceModal = ({
  handleDropdown,
  closeModal,
  showModal,
  showButton,
  variant,
  openModal,
  dirtyChanges,
  handleSubmitClick,
  handleConfirmedSubmitClick,
  handleSubmitNewVariable,
  handleInputChange,
  handleInputName,
  handleRemoveStep,
  createNewStep,
  steps,
  editorStates,
  handleInsertField,
  handleEditorChange,
  handleInputFocus,
  handleAllowSendWeekend,
  handleAllowSendHoliday,
  handleHasReview,
  handleSelectCountry,
  handleSelectTimezone,
  integrations,
  workspaceId,
  cardTabsLabels,
  isConfirmationActive,
  cleanConfirmation,
  customFieldsNames,
  asyncReload,
  handleSubjectBlur,
  handleHasProviderSignature,
  handleRemoveField,
  candidateVariables,
  projectVariables,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
    setValue,
    clearErrors,
    control,
    getValues,
    setError,
  } = useForm();

  const { remove } = useFieldArray({
    control,
    name: "steps",
  });

  const handleCloseModal = () => {
    reset();
    closeModal();
  };

  const handleSubmitForm = () => {
    trigger();
    handleSubmitClick();
  };

  const handleConfirmForm = () => {
    handleConfirmedSubmitClick();
    reset();
  };

  const handleRemoveTab = (index) => {
    let stepIndex = index - 1;
    remove(stepIndex);
    handleRemoveStep(stepIndex);
  };

  let iconColor = "white";
  if (variant === "secondaryLight" || variant === "secondaryLine") {
    iconColor = "blue";
  } else if (variant === "primaryLine") {
    iconColor = "red";
  }

  const cardTabsErrors = () => {
    let cardsIndexes = [];
    let stepIndex = (error) =>
      error && parseInt(R.values(error)[0].ref.name.split(".")[1]) + 1;

    if (errors?.name) cardsIndexes.push(0);
    if (errors?.steps) {
      cardsIndexes = R.concat(cardsIndexes, R.map(stepIndex, errors.steps));
    }
    return cardsIndexes;
  };

  useEffect(() => {
    cardTabsErrors();
  }, [cardTabsLabels]);

  return (
    <>
      {showButton === true && (
        <Button
          onClick={openModal}
          size="small"
          variant={variant ? variant : "primaryLight"}
          content="Sequence"
          icon={<Add variant={iconColor}></Add>}
        ></Button>
      )}
      {showModal === true && (
        <SequenceModal
          cardTabs
          onSubmit={handleSubmit(handleSubmitForm, cardTabsErrors)}
          onConfirm={handleConfirmForm}
          cleanConfirmation={cleanConfirmation}
          title="New Sequence"
          textWarning="* Field Required"
          handleCloseModal={handleCloseModal}
          onNewTab={createNewStep}
          onRemoveTab={handleRemoveTab}
          labels={cardTabsLabels()}
          handleNext={dirtyChanges.name}
          errors={errors}
          tabErrors={cardTabsErrors()}
          setValue={setValue}
          setError={setError}
          clearErrors={clearErrors}
          dirtyChanges={dirtyChanges}
        >
          {[
            <SequenceForm
              key="sequence"
              dirtyChanges={dirtyChanges}
              register={register}
              errors={errors}
              setValue={setValue}
              handleInputChange={handleInputChange}
              handleSelectCountry={handleSelectCountry}
              handleSelectTimezone={handleSelectTimezone}
              handleAllowSendWeekend={handleAllowSendWeekend}
              handleAllowSendHoliday={handleAllowSendHoliday}
              handleHasReview={handleHasReview}
            />,
            ...steps.map((step, indexStep) => (
              <StepForm
                key={indexStep}
                step={step}
                indexStep={indexStep}
                integrations={integrations}
                workspaceId={workspaceId}
                dirtyChanges={dirtyChanges}
                editorStates={editorStates}
                register={register}
                errors={errors}
                setValue={setValue}
                setError={setError}
                getValues={getValues}
                control={control}
                clearErrors={clearErrors}
                asyncReload={asyncReload}
                handleInputChange={handleInputChange}
                handleInputName={handleInputName}
                handleDropdown={handleDropdown}
                handleInputFocus={handleInputFocus}
                handleInsertField={handleInsertField}
                handleSubjectBlur={handleSubjectBlur}
                handleEditorChange={handleEditorChange}
                handleSubmitNewVariable={handleSubmitNewVariable}
                customFieldsNames={customFieldsNames}
                handleHasProviderSignature={handleHasProviderSignature}
                handleRemoveField={handleRemoveField}
                candidateVariables={candidateVariables}
                projectVariables={projectVariables}
              />
            )),
            isConfirmationActive && (
              <SequenceConfirmation
                dirtyChanges={dirtyChanges}
                steps={steps}
                candidateVariables={candidateVariables}
                projectVariables={projectVariables}
              />
            ),
          ]}
        </SequenceModal>
      )}
    </>
  );
};

export default NewSequenceModal;
