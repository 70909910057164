import { createActions } from "redux-actions";

// sign in actions

export const WORKSPACES_GET = "WORKSPACES_GET";
export const WORKSPACES_GET_SUCCESS = "WORKSPACES_GET_SUCCESS";
export const WORKSPACES_GET_FAILURE = "WORKSPACES_GET_FAILURE";
export const ON_WORKSPACES_LOADED = "ON_WORKSPACES_LOADED";

export const WORKSPACE_CREATE = "WORKSPACE_CREATE";
export const WORKSPACE_CREATE_SUCCESS = "WORKSPACE_CREATE_SUCCESS";
export const WORKSPACE_CREATE_FAILURE = "WORKSPACE_CREATE_FAILURE";

export const WORKSPACE_UPDATE = "WORKSPACE_UPDATE";
export const WORKSPACE_UPDATE_SUCCESS = "WORKSPACE_UPDATE_SUCCESS";
export const WORKSPACE_UPDATE_FAILURE = "WORKSPACE_UPDATE_FAILURE";

export const WORKSPACE_DELETE = "WORKSPACE_DELETE";
export const WORKSPACE_DELETE_SUCCESS = "WORKSPACE_DELETE_SUCCESS";
export const WORKSPACE_DELETE_FAILURE = "WORKSPACE_DELETE_FAILURE";

export const WORKSPACE_INVITE = "WORKSPACE_INVITE";
export const WORKSPACE_INVITE_SUCCESS = "WORKSPACE_INVITE_SUCCESS";
export const WORKSPACE_INVITE_FAILURE = "WORKSPACE_INVITE_FAILURE";

export const CURRENT_WORKSPACE_SET = "CURRENT_WORKSPACE_SET";
export const CURRENT_WORKSPACE_CLEAN = "CURRENT_WORKSPACE_CLEAN";

export const WORKSPACE_ANALYTICS_GET = "WORKSPACE_ANALYTICS_GET";
export const WORKSPACE_ANALYTICS_GET_SUCCESS = "WORKSPACE_ANALYTICS_GET_SUCCESS";

export const WORKSPACE_GET = "WORKSPACE_GET";
export const WORKSPACE_GET_SUCCESS = "WORKSPACE_GET_SUCCESS";
export const WORKSPACE_GET_FAILURE = "WORKSPACE_GET_FAILURE";

export const {
  workspacesGet,
  workspacesGetSuccess,
  workspacesGetFailure,
  onWorkspacesLoaded,
  workspaceCreate,
  workspaceCreateSuccess,
  workspaceCreateFailure,
  workspaceUpdate,
  workspaceUpdateSuccess,
  workspaceUpdateFailure,
  workspaceDelete,
  workspaceDeleteSuccess,
  workspaceDeleteFailure,
  currentWorkspaceSet,
  currentWorkspaceClean,
  workspaceAnalyticsGet,
  workspaceAnalyticsGetSuccess,
  workspaceInvite,
  workspaceInviteSuccess,
  workspaceInviteFailure,
  workspaceGet,
  workspaceGetSuccess,
  workspaceGetFailure,
} = createActions(
  {},
  WORKSPACES_GET,
  WORKSPACES_GET_SUCCESS,
  WORKSPACES_GET_FAILURE,
  ON_WORKSPACES_LOADED,
  WORKSPACE_CREATE,
  WORKSPACE_CREATE_SUCCESS,
  WORKSPACE_CREATE_FAILURE,
  WORKSPACE_UPDATE,
  WORKSPACE_UPDATE_SUCCESS,
  WORKSPACE_UPDATE_FAILURE,
  WORKSPACE_DELETE,
  WORKSPACE_DELETE_SUCCESS,
  WORKSPACE_DELETE_FAILURE,
  CURRENT_WORKSPACE_SET,
  CURRENT_WORKSPACE_CLEAN,
  WORKSPACE_ANALYTICS_GET,
  WORKSPACE_ANALYTICS_GET_SUCCESS,
  WORKSPACE_INVITE,
  WORKSPACE_INVITE_SUCCESS,
  WORKSPACE_INVITE_FAILURE,
  WORKSPACE_GET,
  WORKSPACE_GET_SUCCESS,
  WORKSPACE_GET_FAILURE
);
