import React from "react";
import styled from "styled-components";
import { variant, layout } from "styled-system";
import theme from "../../../theme";

// Components
import Box from "../box";
import Close from "../icons/close";

const StyledContainer = styled(Box)`
  position: relative;
  width: auto;
  display: flex;
  background-color: transparent;
  justify-content: center;
  ${({ onClick }) => onClick && `cursor: pointer;`}
  ${layout}
`;

const StyledTag = styled("div")(
  {
    fontFamily: "inherit",
    fontSize: "0.8rem",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "85px",
    padding: "8px 8px",
    margin: "7px",
    borderRadius: "4px",
    fontWeight: "400",
    border: "none",
    backgroundColor: theme.colors.lighterOrange,
    color: theme.colors.darkBlue,
  },
  (props) =>
    props.noPointer
      ? {
        "&:hover": {
          boxShadow: "0px 2px 2px lightgray",
          cursor: "default",
        },
      }
      : {
        "&:hover": {
          boxShadow: "0px 2px 2px lightgray",
          cursor: "pointer",
        },
      },

  variant({
    prop: "size",
    variants: {
      small: {
        minWidth: "40px",
        fontSize: "0.8rem",
        padding: "7px 15px",
      },
      big: {
        minWidth: "120px",
        fontSize: "1rem",
        padding: "10px 15px",
      },
      xsmall: {
        minWidth: "40px",
        fontSize: "0.7rem",
        padding: "7px 9px",
        margin: "4px",
      },
    },
  })
);

const StyledIcon = styled.div`
  width: 12px;
  height: 12px;
  margin-left: 15px;

  &:hover {
    filter: drop-shadow(1px 1px 1px lightGrey);
    * {
      fill: ${theme.colors.lightRed};
    }
  }
`;

const Tag = ({
  content,
  remove,
  disabled,
  onClick,
  onRemove,
  margin,
  size,
  noPointer,
  ...props
}) => {
  return (
    <StyledContainer onClick={onClick} {...props}>
      <StyledTag
        size={size}
        disabled={disabled}
        margin={margin}
        noPointer={noPointer}
      >
        {content}
        {remove ? (
          <StyledIcon onClick={onRemove}>
            <Close variant="darkBlue" />
          </StyledIcon>
        ) : null}
      </StyledTag>
    </StyledContainer>
  );
};

export default Tag;
