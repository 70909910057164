import React from "react";
import theme from "@theme";

//components
import Flex from "@ui/flex";
import SubNavbarTabs from "@ui/sub-navbar-tabs";

//Containers
import AccessListContainer from "@containers/access/AccessListContainer";
import WorkflowProgressContainer from "@containers/workflows/WorkflowProgressContainer";
import PayloadsContainer from "@containers/payloads/PayloadsContainer";
import PeopleProjectContainer from "@containers/people/PeopleProjectContainer";

//Helper
import { isArchivedProject } from "@utils/project-helper";

const Project = ({
  viewport,
  viewHeight,
  project,
  workspace,
  workflowId,
  projectAccessList,
}) => {
  return (
    <Flex
      paddingTop={"64px"}
      alignItems={"center"}
      width={"100%"}
      minHeight={"100vh"}
      flexDirection={"column"}
      justifyContent={"flex-start"}
      px={{ sm: 2, md: 0 }}
      overflow="auto"
      backgroundColor={theme.colors.lighterGrey}
    >
      <SubNavbarTabs labels={isArchivedProject(project) ? ["People", "Workflow", "Access"] : ["People", "Workflow", "Access", "Message"]}>
        <PeopleProjectContainer viewport={viewport} />

        <WorkflowProgressContainer
          viewport={viewport}
          workflowId={workflowId}
          workspace={workspace}
          project={project}
        />

        <AccessListContainer
          viewport={viewport}
          projectAccessList={projectAccessList}
          project={project}
        />

        <PayloadsContainer viewport={viewport} viewHeight={viewHeight} />
      </SubNavbarTabs>
    </Flex>
  );
};

export default Project;
