import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black)" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const Phone = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <g>
        <path
          d="M16.61,24.12c-0.38,0-0.77-0.06-1.09-0.18C9.96,22.09,3.71,16.17,2.9,5.1C2.84,4.22,3.12,3.07,4.1,2.46
		c0.59-0.37,1.55-0.91,2.75-1.42c0,0,0,0,0,0C7.79,0.64,9.12,1,9.62,2.17c0.27,0.62,0.49,1.27,0.72,1.91
		c0.24,0.68,0.47,1.32,0.74,1.94c0.36,0.83,0.23,1.67-0.38,2.38c-0.23,0.27-0.45,0.55-0.67,0.83L9.75,9.59
		c0.25,0.58,0.51,1.12,0.78,1.64c0.8,1.52,1.87,2.98,3.27,4.45c0.03,0.04,0.07,0.04,0.21,0c0.41-0.14,0.83-0.24,1.25-0.35l0.5-0.13
		c0.53-0.14,1.03-0.04,1.5,0.3c0.3,0.21,0.54,0.45,0.76,0.69c0.11,0.11,0.21,0.21,0.32,0.32l0.88,0.79
		c0.53,0.48,1.06,0.96,1.58,1.45c0.7,0.66,0.79,1.68,0.2,2.46c-0.74,0.99-1.71,1.83-2.97,2.58C17.67,24,17.15,24.12,16.61,24.12z
		 M7.44,2.42c-1.12,0.47-2,0.98-2.54,1.31C4.48,3.99,4.37,4.57,4.4,4.99c0.76,10.33,6.5,15.84,11.62,17.54
		c0.41,0.15,1.04,0.11,1.26-0.03c1.09-0.65,1.92-1.37,2.54-2.19c0.17-0.23,0.08-0.38-0.03-0.47c-0.51-0.48-1.04-0.96-1.56-1.43
		l-0.88-0.8c-0.13-0.12-0.25-0.25-0.38-0.37c-0.19-0.2-0.38-0.38-0.57-0.52c-0.14-0.1-0.19-0.08-0.24-0.07l-0.52,0.13
		c-0.38,0.1-0.76,0.19-1.13,0.32c-0.46,0.16-1.14,0.28-1.78-0.38c-1.5-1.58-2.65-3.14-3.52-4.79c-0.31-0.59-0.6-1.2-0.87-1.82
		C8.12,9.6,8.17,9.16,8.49,8.76L8.86,8.3c0.23-0.3,0.47-0.59,0.71-0.88c0.27-0.31,0.25-0.55,0.14-0.8c-0.3-0.68-0.54-1.37-0.78-2.04
		C8.71,3.97,8.5,3.36,8.24,2.76C8.07,2.35,7.6,2.35,7.44,2.42L7.44,2.42z"
        />
        <path
          d="M21.36,11.83C21.36,11.83,21.36,11.83,21.36,11.83c-0.42,0-0.75-0.34-0.75-0.75c0.01-2.1-2.45-4.61-4.78-4.9
		c-0.41-0.05-0.7-0.42-0.65-0.84c0.05-0.41,0.43-0.7,0.83-0.65c3.09,0.38,6.11,3.55,6.1,6.4C22.11,11.5,21.77,11.83,21.36,11.83z"
        />
        <path
          d="M17.5,12.4c-0.38,0-0.71-0.3-0.75-0.69c-0.06-0.7-0.83-1.51-1.56-1.63c-0.41-0.07-0.69-0.45-0.62-0.86
		s0.45-0.69,0.86-0.62c1.41,0.23,2.7,1.59,2.82,2.98c0.04,0.41-0.27,0.78-0.68,0.81C17.55,12.4,17.53,12.4,17.5,12.4z"
        />
      </g>
    </StyledSvg>
  );
};

export default Phone;
