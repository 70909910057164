import { combineEpics, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap, map, mapTo, tap } from "rxjs/operators";
import * as R from "ramda";
import { projectsGet } from "../actions";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import {
  WORKFLOWS_GET,
  workflowsGet,
  workflowsGetSuccess,
  workflowsGetFailure,
  WORKFLOW_CREATE,
  workflowCreateSuccess,
  workflowCreateFailure,
  WORKFLOW_UPDATE,
  workflowUpdateSuccess,
  workflowUpdateFailure,
  WORKFLOW_GET,
  workflowGet,
  workflowGetSuccess,
  workflowGetFailure,
  WORKFLOW_ASSIGN,
  workflowAssignSuccess,
  workflowAssignFailure,
  WORKFLOW_DELETE,
  workflowDeleteSuccess,
  workflowDeleteFailure,
} from "../actions";

const getWorkflowEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(WORKFLOW_GET),
    mergeMap(({ payload: { workspace_id, id } }) =>
      from(
        api
          .get(`/workspaces/${workspace_id}/workflows/${id}`)
          .then((resp) => resp)
      ).pipe(
        map(workflowGetSuccess),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map(error => toast.error(error.detail));
          return of(workflowGetFailure({ err, errors }));
        })
      )
    )
  );

const getWorkflowsEpic = (action$, state$, { api, store }) =>
  action$.pipe(
    ofType(WORKFLOWS_GET),
    mergeMap(({ payload: { workspace_id } }) =>
      from(
        api.get(
          `/workspaces/${workspace_id}/workflows`, {
          params: {
            assigned_to: state$.value.app.filters.workflows
          }
        }
        ).then((resp) => resp)
      ).pipe(
        map(workflowsGetSuccess),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map(error => toast.error(error.detail));
          return of(workflowsGetFailure({ err, errors }));
        })
      )
    )
  );

const createWorkflowEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(WORKFLOW_CREATE),
    mergeMap(({ payload: { workspace_id, name, description, stages } }) => {
      return from(
        api
          .post(`/workspaces/${workspace_id}/workflows`, {
            data: {
              type: "workflow",
              attributes: {
                name,
                description,
                stages_attributes: R.values(stages),
              },
            },
          })
          .then((resp) => resp)
      ).pipe(
        map(workflowCreateSuccess),
        tap(res => { toast.success(`Workflow ${name} created correctly.`) }),
        mapTo(workflowsGet({ workspace_id })),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map(error => toast.error(error.detail));
          return of(workflowCreateFailure({ err, errors }));
        })
      );
    })
  );

const assignWorkflowEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(WORKFLOW_ASSIGN),
    mergeMap(({ payload: { workspace_id, project_id, id } }) => {
      return from(
        api
          .post(
            `/workspaces/${workspace_id}/workflows/${id}/assign_to_project`,
            { project_id: project_id }
          )
          .then((resp) => resp)
      ).pipe(
        map(workflowAssignSuccess),
        mapTo(workflowsGet({ workspace_id })),
        mapTo(projectsGet({ workspace_id: workspace_id, id: project_id })),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map(error => toast.error(error.detail));
          return of(workflowAssignFailure({ err, errors }));
        })
      );
    })
  );

const updateWorkflowEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(WORKFLOW_UPDATE),
    mergeMap(
      ({ payload: { id, workspace_id, name, description, stages } }) => {
        return from(
          api
            .put(`/workspaces/${workspace_id}/workflows/${id}`, {
              data: {
                type: "workflow",
                attributes: {
                  name,
                  description,
                  stages_attributes: R.values(stages)
                },
              },
            })
            .then((resp) => resp)
        ).pipe(
          map(workflowUpdateSuccess),
          mapTo(workflowsGet({ workspace_id })),
          catchError((err) => {
            const errors = err.response ? err.response.data.errors : [];
            errors.map(error => toast.error(error.detail));
            return of(workflowUpdateFailure({ err, errors }));
          })
        );
      }
    )
  );

const deleteWorkflowEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(WORKFLOW_DELETE),
    mergeMap(({ payload: { workspace_id, id } }) => {
      return from(
        api
          .delete(`/workspaces/${workspace_id}/workflows/${id}`)
          .then((resp) => resp)
      ).pipe(
        map(workflowDeleteSuccess),
        tap((res) => {
          toast.success(`Workflow deleted.`);
        }),
        mapTo(workflowsGet({ workspace_id })),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map((error) => toast.error(error.detail));
          return of(workflowDeleteFailure({ err, errors }));
        })
      );
    })
  );

export default combineEpics(
  getWorkflowsEpic,
  createWorkflowEpic,
  updateWorkflowEpic,
  getWorkflowEpic,
  assignWorkflowEpic,
  deleteWorkflowEpic
);
