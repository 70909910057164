import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.linkable
        ? `&:hover {
      filter: drop-shadow(-2px 2px 2px lightgrey) }`
        : ""}

    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black))" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const Bell = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <path
        d="M21.7,19.2c-1.4-1.1-2.2-2.7-2.2-4.4v-3.8c0-3.6-2.7-6.6-6.2-7V2.3c0-0.4-0.3-0.8-0.7-0.8s-0.8,0.3-0.8,0.8v1.8
	c-3.5,0.4-6.2,3.4-6.2,7v3.8c0,1.7-0.8,3.3-2.2,4.4C3,19.4,2.9,19.8,3,20.1c0.1,0.3,0.4,0.5,0.7,0.5h5.5c0.1,1.7,1.5,3.1,3.2,3.1
	c1.7,0,3.2-1.4,3.2-3.1h5.5c0.3,0,0.6-0.2,0.7-0.5C22,19.8,21.9,19.4,21.7,19.2z M12.5,22.2c-0.9,0-1.7-0.7-1.7-1.6h3.5
	C14.1,21.5,13.4,22.2,12.5,22.2z M5.5,19.1C6.5,17.9,7,16.4,7,14.8v-3.8c0-3,2.5-5.5,5.5-5.5S18,8,18,11.1v3.8c0,1.6,0.5,3,1.4,4.2
	H5.5z"
      />
    </StyledSvg>
  );
};

export default Bell;
