import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { passwordReset } from "../../actions";

import {
  getAuthInError,
  getAuthError,
  getAuthLoading,
  getAuthMessage,
} from "../../reducer";

import { ResetPassword } from "../../components/domain/account";

import Spinner from "../../components/ui/spinner";

const SignInContainer = (props) => {
  const dispatch = useDispatch();
  const redirectPath = process.env.REACT_APP_BASE_URL + "/auth/update_password";
  const inError = useSelector(getAuthInError);
  const errorMsg = useSelector(getAuthError);
  const loading = useSelector(getAuthLoading);
  const message = useSelector(getAuthMessage);
  //------
  // data

  const [emailVal, setEmail] = useState("");

  //------
  // handlers

  const handleSetEmail = (e) => setEmail(e.target.value);
  const handleSubmitClick = () => {
    dispatch(
      passwordReset({
        email: emailVal,
        redirect_url: redirectPath,
      })
    );
  };

  return (
    <>
      {loading ? <Spinner /> : null}
      <ResetPassword
        emailVal={emailVal}
        errorMessage={errorMsg}
        inError={inError}
        handleSetEmail={handleSetEmail}
        handleSubmitClick={handleSubmitClick}
        message={message}
      />
    </>
  );
};

export default SignInContainer;
