import React from "react";
import theme from "../../../theme";
import styled from "styled-components";

import Avatar from "../../ui/avatar/index";

import { user2Chars, user2Title } from "../../../utils/user-helper";

const StyledText = styled.div`
  white-space: pre-line;
  margin: 5px;
  align-items: center;
  font-size: 0.9rem;
  display: flex;
  flex-direction: row;
  color: ${theme.colors.grey};
  font-weight: 200;

  ${({ pointer }) => pointer && "cursor: pointer;"}
  ${({ underlined }) => underlined && "text-decoration: underline;"}
`;

const StyledAccess = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Access = ({ access, label, ...props }) => {
  return (
    <StyledText {...props}>
      {label}
      <StyledAccess>
        {access.map((user) => (
          <Avatar
            key={user.id}
            role={user.role}
            variant={user.color}
            bgimage={user.image}
            content={user2Chars(user)}
            title={user2Title(user)}
            size="small"
          />
        ))}
      </StyledAccess>
    </StyledText>
  );
};

export default Access;
