import React from "react";
import styled, { keyframes } from "styled-components";
import {
  flex,
  layout,
  position,
  margin,
  width,
  height,
  display,
} from "styled-system";
import theme from "../../../theme";

// Components
import Box from "../box";

const StyledContainer = styled(Box)`
  width: auto;
  ${flex}
  ${layout}
  ${position}
  ${margin}
  ${width}
  ${height}
  ${display}
`;

const Transition = keyframes`
    0% {
    opacity: .2;
    transform: scale(.5, .5);
    }

    50% {
    opacity: 1;
    transform: scale(1, 1);
    }

    100% {
    opacity: .2;
    transform: scale(.5, .5);
    }
`;

const StyledDots = styled("div")`
  animation: ${Transition} 1.4s infinite ease-in-out;
  border-radius: 10px;
  display: inline-block;
  height: 8px;
  width: 8px;
  margin: 1.5px;
  ${({ blue }) => blue && `background-color: ${theme.colors.blue};`}
  ${({ red }) => red && `background-color: ${theme.colors.red};`}
  ${({ grey }) => grey && `background-color: ${theme.colors.grey};`}
`;

const StyledSubcontainer = styled("div")`
  text-align: center;
  display: flex;

  ${StyledDots}:nth-child(2) {
    animation-delay: 0.2s;
  }

  ${StyledDots}:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

const ThreeDotsLoader = ({ flex, layout, position, margin, ...props }) => {
  return (
    <StyledContainer
      flex={flex}
      layout={layout}
      position={position}
      width={width}
      height={height}
      display={display}
    >
      <StyledSubcontainer>
        <StyledDots {...props}></StyledDots>
        <StyledDots {...props}></StyledDots>
        <StyledDots {...props}></StyledDots>
      </StyledSubcontainer>
    </StyledContainer>
  );
};

export default ThreeDotsLoader;
