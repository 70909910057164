import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import "./index.css";
import GlobalStyle from "./global-style";

import * as serviceWorker from "./serviceWorker";
import { ThemeProvider } from "styled-components";
import { FlagsProvider } from "react-unleash-flags";

import theme from "./theme";

import Root from "././containers/root";

import configureStore from "./store";
import flagConfig from "./flags";

const { store, history } = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <FlagsProvider config={flagConfig}>
            <GlobalStyle />
            <Root />
          </FlagsProvider>
        </ThemeProvider>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
