import { countriesList } from "../utils/countries";

const useCountries = () => {
  const countries = countriesList();

  let countriesNames = () => {
    return countries.map(function (x) {
      return x["name"];
    });
  };

  let countryNameByCode = (code) => {
    return countries.filter(function (x) {
      return x.code === code;
    })[0].name;
  };

  return { countries, countriesNames, countryNameByCode };
};

export default useCountries;
