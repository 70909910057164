import React from "react";
import styled, { css } from "styled-components";
import theme from "../../../theme";
import { variant } from "styled-system";

//Component
import Box from "../box";
import Text from "../text";

const StyledContainer = styled(Box)`
  position: relative;
  width: auto;
`;

const StyledRequired = styled.span`
  color: ${theme.colors.red};
  font-size: 1rem;
  padding-left: 4px;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 52px;
`;

const StyledText = styled.div`
  border-bottom: ${(props) =>
    props.disabled
      ? `1px solid ${theme.colors.grey}`
      : props.error
      ? `1px solid ${theme.colors.red}`
      : `1px solid ${theme.colors.blue}`};
  color: ${(props) =>
    props.disabled
      ? theme.colors.lightGrey
      : props.error
      ? theme.colors.red
      : theme.colors.blue};
  font-size: 0.9rem;
  height: 42px;
  display: flex;
  padding-left: 15px;
  align-items: center;
  width: ${(props) => (props.full ? "100%" : "70%")};
`;

const StyledLinks = styled.a`
  font-size: 0.8rem;
  text-decoration: none;
  color: ${theme.colors.darkBlue};
  border-bottom: 1px solid ${theme.colors.darkBlue};
  margin-right: 15px;
`;

const commonInputStyles = css`
  color: ${(props) =>
    props.disabled ? theme.colors.lightGrey : theme.colors.grey};

  background: transparent;
  border: 0;
  font-family: ${theme.fonts[1]};
  font-size: 1rem;
  display: block;
  width: 100%;

  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${({ error, disabled, theme: { colors } }) =>
      error
        ? theme.colors.red
        : disabled
        ? theme.colors.lightGrey
        : theme.colors.darkBlue};

    font-style: italic;
    font-size: 0.9rem;
  }
`;

const StyledInput = styled.input`
  ${commonInputStyles}
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  &::-ms-clear {
    display: none;
  }
`;

const StyledButton = styled("label")(
  {
    fontFamily: "inherit",
    fontSize: "0.9rem",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "116px",
    padding: "8px 15px",
    margin: "10px 10px 10px 0px",
    borderRadius: "4px",
    textTransform: "uppercase",
    fontWeight: "500",
    border: "none",

    "&:hover": {
      boxShadow: "-2px 2px 2px lightgray",
      cursor: "pointer",
    },
  },

  variant({
    variants: {
      primary: {
        color: theme.colors.white,
        bg: theme.colors.red,
      },

      primaryLine: {
        color: theme.colors.red,
        bg: theme.colors.white,
        border: "1px solid",
        borderColor: theme.colors.red,
      },

      primaryLight: {
        color: theme.colors.white,
        bg: theme.colors.lightRed,
      },

      secondary: {
        color: theme.colors.white,
        bg: theme.colors.blue,
      },

      secondaryLine: {
        color: theme.colors.blue,
        bg: theme.colors.white,
        border: "1px solid",
        borderColor: theme.colors.blue,
      },

      secondaryLight: {
        color: theme.colors.blue,
        bg: theme.colors.lightBlue,
      },

      disabled: {
        color: theme.colors.lighterGrey,
        bg: theme.colors.lightGrey,
        "&:hover": { cursor: "not-allowed", boxShadow: "none" },
      },
    },
  })
);

const StyledError = styled.span`
  color: ${theme.colors.red};
  font-size: 0.7rem;
  font-weight: 200;
  display: block;
  margin-left: 9rem;
  margin-bottom: 1rem;
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
`;

const InputButton = ({
  mb,
  mt,
  label,
  required,
  disabled,
  variant,
  handleChange,
  value,
  full,
  error, // bool
  errorMsg, // content for the error span, needs error=true to show
}) => {
  return (
    <StyledContainer mb={mb} mt={mt}>
      {label && (
        <Text
          fontSize={theme.fontSizes[2]}
          fontFamily={theme.fonts[1]}
          fontWeight={0}
          mb={1}
          pt={2}
        >
          {label}
          {required ? <StyledRequired>*</StyledRequired> : null}
        </Text>
      )}
      <StyledButtonContainer>
        <StyledButton
          variant={disabled ? "disabled" : variant}
          disabled={disabled}
          htmlFor="file"
        >
          search
        </StyledButton>
        <StyledInput
          id="file"
          name="file"
          onChange={handleChange}
          type="file"
          accept=".csv"
        ></StyledInput>
        <StyledText 
          full={full} 
          disabled={disabled}
          error={error}
        >
          {value ? value.name : "Select your file .CSV"}
        </StyledText>
      </StyledButtonContainer>

     <StyledError show={error && errorMsg}>{errorMsg}</StyledError> 

      <StyledLinks
        disabled={disabled}
        href="/people_import_example_all_fields.csv"
      >
        All fields CSV Example
      </StyledLinks>
      <StyledLinks
        disabled={disabled}
        href="/people_import_example_mandatory_fields.csv"
      >
        Mandatory fields CSV Example
      </StyledLinks>
    </StyledContainer>
  );
};

export default InputButton;
