import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

//components
import { Modal } from "../../ui/modal";
import Box from "../../ui/box";
import Flex from "../../ui/flex";
import Image from "../../../assets/img/workspace.svg";
import Button from "../../ui/button";
import Submit from "../../ui/input-submit";
import Input from "../../ui/input";
// import SidebarSubBtn from "../../ui/sidebar-sub-btn";

//Icons
import Close from "../../ui/icons/close";
import Add from "../../ui/icons/add";

const CardImage = styled.div`
  width: 30%;
  height: 300px;
  display: flex;
  background-image: url(${(props) => props.image});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const NewWorkspaceModal = ({
  isExpanded,
  closeModal,
  showModal,
  handleSubmitClick,
  dirtyChanges,
  handleChange,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();

  const handleCLoseModal = () => {
    clearErrors();
    closeModal();
  };

  const handleOnEnter = () => {
    if (dirtyChanges.title === undefined || dirtyChanges.title.length == 0) {
      return setError('title');
    } else {
      handleSubmitClick();
    }
  };

  const handleOnChange = (e) => {
    handleChange(e);
    clearErrors();
  };

  if (!showModal) return null;

  return (
    <Modal
      fullHeader
      formContent
      onSubmit={handleSubmit(handleSubmitClick)}
      title="New workspace"
      icon={<Close variant="grey"></Close>}
      closeHandler={handleCLoseModal}
      buttons={[
        <Button
          key="cancel"
          variant="secondaryLine"
          content="Cancel"
          onClick={handleCLoseModal}
        />,
        <Submit key="continue" variant="secondary" content="Create" />,
      ]}
    >
      <Flex
        justifyContent="space-evenly"
        alignItems="center"
        marginTop="15px"
      >
        <Box width="60%">
              <p
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                Workspace is the main folder that manages projects, talents,
                workflows, general progress and more.
              </p>
              <p
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                You will be assigned the role of Owner, giving you complete
                control and access to the tools and advantages offered by
                TeamSourced
              </p>
              <p
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                Access to all the workspaces through the side menu on the left
                and immediately check their status.
              </p>
              <Input
                {...register("title", { required: true })}
                required
                name="title"
                error={errors.title ? true : false}
                errorMsg="This field is required"
                label="Name"
                onChange={handleOnChange}
                onEnter={handleOnEnter}
                value={dirtyChanges.title}
                mb={4}
                mt={2}
                placeholder="Enter Workspace Name"
                autoFocus
              />
            </Box>
            <CardImage image={Image} />
          </Flex>
        </Modal>
  );
};

export default NewWorkspaceModal;
