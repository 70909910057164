import React from "react";
import styled from "styled-components";
import { flex, layout } from "styled-system";
import theme from "../../../theme";

const StyledContainer = styled.div`
  width: 100%;
  height: 45px;
  ${({ onClick }) => onClick && `cursor: pointer;`}
  ${flex}
  ${layout}
`;

const StyledButton = styled("a")(({ isExpanded, ...props }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: isExpanded ? "flex-start" : "center",
  alignItems: "center",
  padding: "10px",
  background: props.dark
    ? theme.colors.grey
    : props.active
    ? theme.colors.lightRed
    : "#f2f2f2", // Updated background color
  width: "100%",
  color: props.disabled ? theme.colors.lightGrey : "#696969", // Updated text color

  "&:hover": {
    background: props.disabled || props.blur ? null : theme.colors.lightOrange,
    color: props.disabled ? theme.colors.lightGrey : theme.colors.white,
    cursor: props.disabled ? "default" : "pointer",
  },
}));

const StyledIcon = styled.div`
  width: 25px;
  height: 25px;
  padding: 3px;
  display: flex;
  justify-content: center;
  margin-left: ${({ isExpanded }) => isExpanded ? '12px' : '0'};
  align-items: center;
  svg {
    fill: "#808080";
  }
`;

const SubItemUrl = styled.div`
  width: 6px;
  background-color: ${theme.colors.lightRed};
  height: 45px;
  position: absolute;
  top: 10px;
`;

const StyledContent = styled.div`
  font-size: 0.9rem;
  font-weight: 400;
  text-transform: capitalize;
  text-align: center;
  font-family: inherit;
  display: block;
  margin-left: 12px;
  line-height: 1.3rem;
`;

const SidebarSubBtn = ({
  onClick,
  disabled,
  icon,
  dark,
  blur,
  content,
  active,
  subitem,
  isExpanded,
  tooltipContent,
  ...props
}) => {
  return (
    <StyledContainer onClick={onClick} {...props}>
      <StyledButton dark={dark} blur={blur} disabled={disabled} active={active} isExpanded={isExpanded}>
        {icon ? <StyledIcon>{icon}</StyledIcon> : null}
        {isExpanded && <StyledContent>{tooltipContent}</StyledContent>}
      </StyledButton>
      {subitem && <SubItemUrl></SubItemUrl>}
    </StyledContainer>
  );
};

export default SidebarSubBtn;
