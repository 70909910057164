import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.linkable
        ? `&:hover {
      filter: drop-shadow(-1px 1px 1px #4BAAC8); cursor: pointer;}`
        : ""}

    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black);" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

export const ThumbDownLine = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <g>
        <path
          d="M8.2,6.8v8.8c0,0.3-0.2,0.6-0.6,0.6H4.7c-0.3,0-0.5-0.2-0.5-0.6V6.8c0-0.3,0.2-0.6,0.5-0.6h3
		C7.9,6.2,8.2,6.4,8.2,6.8z M5.3,15h1.8V7.4H5.3V15z"
        />
        <path
          d="M19.5,8.1l1.9,5.7c0.2,0.5,0.1,1.2-0.2,1.6c-0.3,0.5-0.9,0.8-1.5,0.8h-3.4c0.3,1.3,0.9,3.8-0.1,5.1
		c-0.4,0.5-0.9,0.8-1.6,0.8c-1.3,0-1.6-1.3-2-2.6c-0.5-1.9-1.1-3.3-2.8-3.3c-0.3,0-0.6-0.2-0.6-0.6V6.8c0-0.3,0.2-0.6,0.6-0.6h7.2
		C18.1,6.2,19.1,7,19.5,8.1z M10.2,15.1c2.3,0.3,2.9,2.4,3.4,4.1c0.2,0.9,0.5,1.8,0.9,1.8c0.5,0,0.6-0.2,0.7-0.3
		c0.6-0.9,0.2-3.4-0.3-4.8c-0.1-0.2,0-0.4,0.1-0.5c0.1-0.2,0.3-0.2,0.5-0.2h4.2c0.2,0,0.4-0.1,0.5-0.2c0.2-0.2,0.2-0.4,0.1-0.5
		l-1.9-5.7c-0.2-0.7-0.9-1.1-1.6-1.1h-6.6V15.1z"
        />
      </g>
      <path
        d="M12.5,1.3c6.2,0,11.2,5,11.2,11.2s-5,11.2-11.2,11.2s-11.2-5-11.2-11.2S6.3,1.3,12.5,1.3 M12.5,0
	C5.6,0,0,5.6,0,12.5S5.6,25,12.5,25S25,19.4,25,12.5S19.4,0,12.5,0L12.5,0z"
      />
    </StyledSvg>
  );
};

export const ThumbDownSolid = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <path
        fill="#FFB07C"
        d="M12.5,0C5.6,0,0,5.6,0,12.5S5.6,25,12.5,25S25,19.4,25,12.5S19.4,0,12.5,0z M8.6,15.4C8.6,15.7,8.3,16,8,16H5.3
	c-0.3,0-0.5-0.2-0.5-0.6V7.3C4.8,7,5,6.7,5.3,6.7H8c0.3,0,0.6,0.3,0.6,0.6V15.4z M20.5,15.3c-0.3,0.4-0.8,0.7-1.4,0.7H16
	c0.3,1.2,0.8,3.5-0.1,4.8c-0.4,0.5-0.9,0.7-1.5,0.7c-1.2,0-0.9-1.5-1.3-2.7C12.6,17,11.5,16,9.9,16c-0.3,0-0.6-0.2-0.6-0.6V7.3
	c0-0.3,0.2-0.6,0.6-0.6h6.7c1.1,0.1,2,0.8,2.4,1.8l1.8,5.3C20.9,14.3,20.8,14.9,20.5,15.3z"
      />
    </StyledSvg>
  );
};
