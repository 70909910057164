import React, { useEffect } from "react";
import { bool, func, string } from "prop-types";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import theme from "../../../theme";

import Box from "../../ui/box";
import Flex from "../../ui/flex";
import Input from "../../ui/input";
import Submit from "../../ui/input-submit";

const propTypes = {
  emailVal: string,
  handleProjectslick: func,
  handlePeoplelick: func,
  handleSetEmail: func,
  handleSubmitClick: func,
  isAuthLoading: bool,
};

const StyledSpan = styled.span`
  display: flex;
  font-style: italic;
  color: ${theme.colors.red};
  font-size: 0.7rem;
  padding-top: 4px;
`;

const ResetPassword = ({
  emailVal,
  errorMessage,
  inError,
  handleSetEmail,
  handleSubmitClick,
  isAuthLoading,
  message,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    localStorage.clear(); // remove token
    // eslint-disable-next-line
  }, []);

  return (
    <Flex
      alignItems={"center"}
      minHeight={"100vh"}
      justifyContent={"center"}
      px={{ sm: 2, md: 0 }}
      width={1}
      overflow="hidden"
    >
      <Flex
        height={"100%"}
        width={0.6}
        style={{
          backgroundImage: `url("/images/team.jpg")`,
          backgroundSize: "cover",
        }}
      />
      <Flex
        flexDirection="column"
        height={"100%"}
        width={0.4}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {message ? (
          <div
            style={{
              width: "100%",
              fontFamily: "Open Sans",
              textAlign: "center",
            }}
          >
            <h2
              style={{
                color: "#585858",
                marginBottom: "1rem",
                fontWeight: "600",
                fontSize: "2rem",
                marginTop: "5rem",
              }}
            >
              CHECK YOUR INBOX!
            </h2>
            <h3
              style={{
                color: "#585858",
                marginBottom: "2rem",
                fontWeight: "300",
                fontSize: "1rem",
              }}
            >
              {message}
            </h3>
          </div>
        ) : (
          <form onSubmit={handleSubmit(handleSubmitClick)}>
            <div
              style={{
                width: "100%",
                fontFamily: "Open Sans",
                textAlign: "center",
              }}
            >
              <h2
                style={{
                  color: "#585858",
                  marginBottom: "1rem",
                  fontWeight: "600",
                  fontSize: "2rem",
                  marginTop: "5rem",
                }}
              >
                FORGOT YOUR PASSWORD?
              </h2>
              <h3
                style={{
                  color: "#585858",
                  marginBottom: "2rem",
                  fontWeight: "300",
                  fontSize: "1rem",
                }}
              >
                PLEASE ENTER YOUR EMAIL
              </h3>
            </div>
            <Box mb={3} width="300px">
              <Input
                {...register("email", {
                  required: true,
                  pattern: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
                })}
                label={"Email"}
                error={errors.email ? true : false}
                autoFocus={true}
                value={emailVal}
                onChange={handleSetEmail}
                placeholder={"Email address"}
                type={"email"}
              />
              {errors.email && <StyledSpan>This field is required</StyledSpan>}
            </Box>
            <Submit variant="primary" content="Reset" />
          </form>
        )}
      </Flex>
    </Flex>
  );
};

ResetPassword.propTypes = propTypes;

export default ResetPassword;
