import React, { useState, useEffect } from "react";
import styled from "styled-components";
import theme from "../../../theme";
import { useForm } from "react-hook-form";

// Components
import Card from "@ui/card/index";
import Flex from "@ui/flex";
import Button from "@ui/button/index";
import Text from "@ui/text/index";
import Box from "@ui/box";
import Input from "@ui/input";

//Icons
import Close from "@ui/icons/close";
import { Span } from "@airbrake/browser/dist/metrics";

const StyledBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99999;
`;

const StyledCardContainer = styled.div`
  position: fixed;
  background: transparent;
  width: 40%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const AlertTestSequence = ({ sequence, closeModal, handleSubmitClick, buttonSubmitText, title, description, variables }) => {
  const [dirtyChanges, setDirtyChanges] = useState({});
  const textVariables = variables.filter((variable) => variable.type === "Text")
  const textAreaVariables = variables.filter((variable) => variable.type === "Text area")

  const {
    register,
    formState: { errors },
  } = useForm();

  const handleInputChange = (event) => {
    setDirtyChanges({
      ...dirtyChanges,
      [event.target.name]: event.target.value,
    });
  };


  return (
    <StyledBackground>
      <StyledCardContainer>
        <Card
          fullHeader
          title={title}
          icon={<Close variant="grey"></Close>}
          closeHandler={closeModal}
          footer
          buttons={[
            <Button
              key="cancel"
              variant="secondaryLine"
              content="Cancel"
              onClick={closeModal}
            />,
            <Button
              key="submit"
              variant="secondaryLine"
              content={buttonSubmitText}
              onClick={() => handleSubmitClick(sequence, dirtyChanges)}
            />,
          ]}
        >
          {variables ? (
            <Flex display="contents " width="100%" justifyContent="space-evenly">
              {textVariables.map((variable, index) => (
                <Box width={"50%"} padding="10px" key={index}>
                  <Input
                    {...register(variable.name, { required: true })}
                    value={dirtyChanges[variable.name] || ""}
                    onChange={handleInputChange}
                    placeholder={variable.name}
                  />
                </Box>
              ))}
              {textAreaVariables.map((variable, index) => (
                <Box width={"100%"} padding="10px" key={index}>
                  <Input
                    {...register(variable.name, { required: true })}
                    value={dirtyChanges[variable.name] || ""}
                    onChange={handleInputChange}
                    placeholder={variable.name}
                    multiline={true}
                  />
                </Box>
              ))}
            </Flex>
          ) : null}
          <Flex width="90%" alignItems="center" flexDirection="column">
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="space-evenly"
            >


              <Text
                textAlign="center"
                fontSize="1rem"
                marginTop="15px"
                color={theme.colors.red}
                wrap
              >
                {description}
              </Text>

              <Text textAlign="center" fontSize="1rem" marginTop="15px">
                Are you sure you want to proceed?
              </Text>
            </Flex>
          </Flex>
        </Card>
      </StyledCardContainer>
    </StyledBackground >
  );
};

export default AlertTestSequence;
