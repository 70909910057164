import React from "react";
import styled from "styled-components";
import theme from "../../../theme";

// Components
import Card from "../card/index";
import Flex from "../flex";
import Box from "../box";
import Button from "../button/index";
import Text from "../text/index";
import Dropdown from "../dropdown/index";

//Icons
import Close from "../icons/close";

const StyledBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99999;
`;

const StyledCardContainer = styled.div`
  position: fixed;
  background: transparent;
  width: 40%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledItemName = styled.div`
  font-size: 1.1rem;
  display: flex;
  color: ${theme.colors.lightRed};
  font-weight: 400;
  margin: 5px 0px;
`;

const ImportModal = ({
  importData, // import data about the file
  closeModal,
  title, //modal title
}) => {
  return (
    <StyledBackground>
      <StyledCardContainer>
        <Card
          fullHeader
          title={title}
          icon={<Close variant="grey"></Close>}
          closeHandler={closeModal}
          footer
          buttons={[
            <Button
              key="cancel"
              variant="secondaryLine"
              content="Close"
              onClick={closeModal}
            />,
          ]}
        >
          <Flex width="90%" alignItems="center" flexDirection="column">
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="space-evenly"
              height="200px"
              marginTop="20px"
            >
              <Flex alignItems="center">
                <Text paddingRight="10px">New Candidates:</Text>
                <StyledItemName>{importData.new}</StyledItemName>
              </Flex>
              <Flex alignItems="center">
                <Text paddingRight="10px">Assigned Candidates:</Text>
                <StyledItemName>{importData.assigned}</StyledItemName>
              </Flex>
              <Flex alignItems="center">
                <Text paddingRight="10px">Existing Candidates:</Text>
                <StyledItemName>{importData.existing}</StyledItemName>
              </Flex>
              <Flex alignItems="center">
                <Text paddingRight="10px">Errors:</Text>
                <StyledItemName>{importData.errors}</StyledItemName>
              </Flex>
              <Flex alignItems="center">
                <Text paddingRight="10px">Success:</Text>
                <StyledItemName>{importData.success}</StyledItemName>
              </Flex>
              <Flex alignItems="center">
                <Text paddingRight="10px">Total Candidates:</Text>
                <StyledItemName>{importData.total}</StyledItemName>
              </Flex>
            </Flex>
          </Flex>
        </Card>
      </StyledCardContainer>
    </StyledBackground>
  );
};

export default ImportModal;
