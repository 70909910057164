import React from "react";
import theme from "../../../theme";
import { Link } from "react-router-dom";

import Box from "../../ui/box";
import Card from "../../ui/card";
import Image from "../../../assets/img/writing.svg";
import Flex from "../../ui/flex";

const PayloadIntro = ({ credentialsLink, sequencesLink }) => {
  return (
    <Flex
      alignItems={"center"}
      width={"100%"}
      flexDirection={"column"}
      justifyContent={"flex-start"}
      px={{ sm: 2, md: 0 }}
      overflow="hidden"
      backgroundColor={theme.colors.lighterGrey}
    >
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        width={"95%"}
        mt={"30px"}
        backgroundColor={theme.colors.lighterGrey}
      >
        <Card
          imageBlock={Image}
          textBlock={
            <Box>
              <p
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                Start contacting your candidates!
              </p>
              <p
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                In order to send messages you need to assign the project a
                workflow that contains a sequence.
                <br />
                As soon as a candidate enters a stage with sequence you will
                find here everything you need to write the messages. <br />
                <br />
                Keep in mind that the sequence needs the credentials that will
                be used to send the messages.
              </p>
              <p
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                Learn more about
                <Link
                  to={credentialsLink}
                  style={{
                    fontSize: "1rem",
                    textDecoration: "none",
                    color: theme.colors.lightRed,
                    borderBottom: `1px solid ${theme.colors.lightRed}`,
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                >
                  Credentials
                </Link>
                and
                <Link
                  to={sequencesLink}
                  style={{
                    fontSize: "1rem",
                    textDecoration: "none",
                    color: theme.colors.lightRed,
                    borderBottom: `1px solid ${theme.colors.lightRed}`,
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                >
                  Sequences
                </Link>
              </p>
            </Box>
          }
        ></Card>
      </Flex>
    </Flex>
  );
};

export default PayloadIntro;
