import { handleActions } from "redux-actions";
import { lensProp, pipe, set, view } from "ramda";
import { setLocationPath, setLimit, onExpiredToken} from "../actions";

const initialState = {
  locationPath: { pathname: "" },
  limit: 10,
  token: localStorage.getItem("access-token"),
};

const locationPathLens = lensProp("locationPath");
const tokenLens = lensProp("token");
const limitLens = lensProp("limit");

const setLocationPathLens = set(locationPathLens);
const setLimitLens = set(limitLens);

export const getToken = view(tokenLens);

export default handleActions(
  {
    // get location
    [setLocationPath]: (state, action) => {
    return   pipe(setLocationPathLens(action.payload))(state)
    },
    // set limit
    [setLimit]: (state, action) => pipe(setLimitLens(action.payload))(state),
    [onExpiredToken]: (state, action) => {
      return pipe(setLocationPathLens('/sign_in'))(state)
    }
  },
  initialState
);

export const getLimit = (state) => state.navigation.limit;
export const getLocationPath = (state) => state.navigation.locationPath;
export const getLocationPathname = (state) =>
  state.navigation.locationPath.pathname;
