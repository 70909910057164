import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//Component
import Spinner from "@components/ui/spinner";
import Selector from "@components/ui/selector/index";
import { People, PeopleIntro } from "@components/domain/people";

//Actions
import {
  peopleGet,
  projectsGet,
  currentWorkspaceSet,
  currentProjectClean,
  peopleToProjectAssign,
  personUpdate,
} from "@actions";

import {
  getPeopleCurrentPage,
  getPeopleTotalPages,
  getPeople,
  getProjects,
  getLimit,
  getWorkspaceCurrent,
} from "@reducer/";

const PeopleContainer = ({ viewport, ...props }) => {
  const people = useSelector(getPeople);
  const projects = useSelector(getProjects);
  const limit = useSelector(getLimit);
  const currentPage = useSelector(getPeopleCurrentPage);
  const totalPages = useSelector(getPeopleTotalPages);

  const dispatch = useDispatch();
  const workspace_id = useSelector(getWorkspaceCurrent);

  const [dirtyChanges, setDirtyChanges] = useState({});
  const [peopleCheckboxStatus, setPeopleCheckboxStatus] = useState({});
  const [totalSelected, setTotalSelected] = useState(0);
  const [assignTo, setAssignTo] = useState();
  const [isAssignActive, setIsAssignActive] = useState(false);
  const [checkAllState, setCheckAllState] = useState(false);

  const [isEditActive, setIsEditActive] = useState(false);
  const [activePerson, setActivePerson] = useState({});
  const [searchBy, setSearchBy] = useState("");

  const handleDropdownSelect = (name, value) => {
    setDirtyChanges({
      ...dirtyChanges,
      [name]: value,
    });
  };

  const handleDropdownAssign = (name, value) => {
    setAssignTo({
      [name]: value,
      workspace: props.match.params.id,
    });
  };

  const handleCheckbox = (event) => {
    setPeopleCheckboxStatus({
      ...peopleCheckboxStatus,
      [event.target.value]: event.target.checked,
    });
  };

  const handleCheckAll = (event) => {
    setCheckAllState(true);
    const newPeopleCheckedStatus = {};
    people.data.forEach((person) => {
      newPeopleCheckedStatus[person.id] = event.target.checked;
    });
    setPeopleCheckboxStatus(newPeopleCheckedStatus);
  };

  const openAssignModalHandler = () => {
    setIsAssignActive(true);
  };

  const closeAssignModalHandler = () => {
    setIsAssignActive(false);
  };

  const handleSubmitAssign = () => {
    let selectedPeople = [];
    for (const id in peopleCheckboxStatus) {
      if (peopleCheckboxStatus[id]) {
        selectedPeople.push({ id: id });
      }
    }
    dispatch(
      peopleToProjectAssign({
        workspace_id: props.match.params.id,
        project_id: assignTo.project,
        people_ids: selectedPeople,
      })
    );
    setIsAssignActive(false);
    setPeopleCheckboxStatus({});
    setDirtyChanges({});
    setAssignTo({});
    setCheckAllState(false);
  };

  const handleChangeSearchBy = (event) => {
    setSearchBy(event.target.value);
  };

  const handleEnterSearchBy = () => {
    dispatch(
      peopleGet({
        workspace_id: props.match.params.id,
        search_by: searchBy,
        limit: limit,
      })
    );
  };

  const handleSubmitEdit = (props) => {
    dispatch(
      personUpdate({
        workspace_id: workspace_id,
        search_by: "",
        ...props,
      })
    );
  };

  const closeEditModalHandler = () => {
    setIsEditActive(false);
  };

  const editPersonHandler = (person) => {
    setActivePerson(person);
    setIsEditActive(!isEditActive);
  };

  const firstPagePagination = () => {
    dispatch(peopleGet({ url: people.links.first }));
  };

  const lastPagePagination = () => {
    dispatch(peopleGet({ url: people.links.last }));
  };

  const nextPagePagination = () => {
    dispatch(peopleGet({ url: people.links.next }));
  };

  const prevPagePagination = () => {
    dispatch(peopleGet({ url: people.links.prev }));
  };

  const rowMenuOptions = [
    { name: "Edit", callback: editPersonHandler },
    /* { name: "Remove", alert: removeModalHandler }, */
  ];

  useEffect(() => {
    //TODO: delete this when context provider is working
    dispatch(currentWorkspaceSet({ workspace_id: props.match.params.id }));
    dispatch(projectsGet({ workspace_id: props.match.params.id }));
    dispatch(currentProjectClean());
  }, [props.match.params.id]);

  useEffect(() => {
    if (searchBy === "") {
      dispatch(
        peopleGet({
          workspace_id: props.match.params.id,
          search_by: searchBy,
          limit: limit,
        })
      );
    }
  }, [searchBy, limit]);

  useEffect(() => {
    const newPeopleCheckedStatus = {};
    people.data.forEach((person) => {
      newPeopleCheckedStatus[person.id] = false;
    });
    setPeopleCheckboxStatus(newPeopleCheckedStatus);
  }, [people]);

  useEffect(() => {
    let count = 0;
    for (const id in peopleCheckboxStatus) {
      if (peopleCheckboxStatus[id]) {
        count = count + 1;
      }
    }
    setTotalSelected(count);
  }, [peopleCheckboxStatus]);

  return (
    <>
      {searchBy.length <= 0 && people.data?.length <= 0 && !people.loading ? (
        <PeopleIntro viewport={viewport} />
      ) : people.loading ? (
        <Spinner />
      ) : (
        <People
          tableBarContent={
            <Selector
              assigned={assignTo}
              projects={projects}
              placeholderProject="Select Project"
              totalSelect={totalSelected}
              handleProject={handleDropdownAssign}
              isAssignActive={isAssignActive}
              closeAssignModalHandler={closeAssignModalHandler}
              openAssignModalHandler={openAssignModalHandler}
              handleSubmitAssign={handleSubmitAssign}
              handleCheckAll={handleCheckAll}
              checkAllState={checkAllState}
            />
          }
          viewport={viewport}
          peopleCheckboxStatus={peopleCheckboxStatus}
          handleDropdown={handleDropdownSelect}
          handleCheckbox={handleCheckbox}
          people={people}
          workspace={props.match.params.id}
          rowMenuOptions={rowMenuOptions}
          activePerson={activePerson}
          isEditActive={isEditActive}
          closeEditModalHandler={closeEditModalHandler}
          handleChangeSearchBy={handleChangeSearchBy}
          handleEnterSearchBy={handleEnterSearchBy}
          searchBy={searchBy}
          firstPage={firstPagePagination}
          lastPage={lastPagePagination}
          prevPage={prevPagePagination}
          nextPage={nextPagePagination}
          totalPages={totalPages}
          currentPage={currentPage}
          handleSubmitEdit={handleSubmitEdit}
          asyncReload={() =>
            dispatch(
              peopleGet({
                workspace_id: props.match.params.id,
                search_by: searchBy,
                limit: limit,
              })
            )
          }
        />
      )}
    </>
  );
};

export default PeopleContainer;
