import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { userGet } from "../../actions";

const OmniauthContainer = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    localStorage.clear();
    localStorage.setItem("access-token", params.get("auth_token"));
    localStorage.setItem("uid", params.get("uid"));
    localStorage.setItem("client", params.get("client_id"));
    localStorage.setItem("expiry", params.get("expiry"));
    dispatch(userGet({}));
  }, []);

  return <p>Redirecting...</p>;
};

export default OmniauthContainer;
