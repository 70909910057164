import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//Component
import Spinner from "../../components/ui/spinner";
import { Profile } from "../../components/domain/account";

//Actions
import { personGet, messagesGet } from "../../actions";

//Reducers
import { getMessagesData, getPeoplePersonData } from "../../reducer";

const PersonProfileContainer = ({ viewport, ...props }) => {
  const person = useSelector(getPeoplePersonData);
  const messages = useSelector(getMessagesData);
  const location = useSelector((state) => state.router.location.pathname);
  const dispatch = useDispatch();

  useEffect(() => {
    //TODO: delete this when context provider is working
    dispatch(personGet({ id: props.match.params.id }));
    dispatch(messagesGet({ person_id: props.match.params.id }));

    // eslint-disable-next-line
  }, [props.match.params.id]);

  return person?.attributes ? (
    <Profile
      person={person}
      location={props.location.pathname}
      messages={messages}
      viewport={viewport}
    />
  ) : (
    <Spinner />
  );
};

export default PersonProfileContainer;
