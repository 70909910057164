import React from "react";
import styled from "styled-components";
import theme from "../../../theme";

// Components
import Card from "@ui/card/index";
import Flex from "@ui/flex";
import Button from "@ui/button/index";
import Text from "@ui/text/index";

//Icons
import Close from "@ui/icons/close";

const StyledBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99999;
`;

const StyledCardContainer = styled.div`
  position: fixed;
  background: transparent;
  width: 40%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledItemName = styled.div`
  font-size: 1.1rem;
  display: flex;
  color: ${theme.colors.lightRed};
  font-weight: 400;
  margin: 5px 0px;
`;

const AlertSequence = ({ data, closeModal, handleSubmitClick }) => {
  return (
    <StyledBackground>
      <StyledCardContainer>
        <Card
          fullHeader
          title="Move person"
          icon={<Close variant="grey"></Close>}
          closeHandler={closeModal}
          footer
          buttons={[
            <Button
              key="cancel"
              variant="secondaryLine"
              content="Cancel"
              onClick={closeModal}
            />,
            <Button
              key="continue"
              variant="secondaryLine"
              content="Continue"
              onClick={handleSubmitClick}
            />,
          ]}
        >
          <Flex width="90%" alignItems="center" flexDirection="column">
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="space-evenly"
              height="200px"
            >
              <Flex
                alignItems="center"
                margin="0px 0px 15px 0px"
                flexDirection="column"
              >
                <Text
                  textAlign="center"
                  fontSize="1rem"
                  margin="20px 0px 0px 0px"
                >
                  Moving the candidate
                </Text>
                <StyledItemName>
                  {data.person.attributes["first_name"] +
                    " " +
                    data.person.attributes["last_name"]}
                </StyledItemName>
                <Flex alignItems="center">
                  <Text textAlign="center" fontSize="1rem" margin="0px 5px">
                    from
                  </Text>
                  <StyledItemName>{data.person.status}</StyledItemName>
                  <Text textAlign="center" fontSize="1rem" margin="0px 5px">
                    to
                  </Text>
                  <StyledItemName>{data.moveTo}</StyledItemName>
                </Flex>
                <Text textAlign="center" fontSize="1rem" margin="5px 0px">
                  while it has an active sequence
                </Text>
              </Flex>
              <Text
                textAlign="center"
                fontSize="1rem"
                marginTop="15px"
                color={theme.colors.red}
              >
                This action will stop the sequence
              </Text>
              <Text textAlign="center" fontSize="1rem" marginTop="15px">
                Are you sure you want to proceed?
              </Text>
            </Flex>
          </Flex>
        </Card>
      </StyledCardContainer>
    </StyledBackground>
  );
};

export default AlertSequence;
