import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import theme from "../../../theme";

import Box from "../../ui/box";
import Card from "../../ui/card";
import Image from "../../../assets/img/people.svg";
import Flex from "../../ui/flex";
import InputButton from "../../ui/input-import-csv";
import Button from "../../ui/button";

//Actions
import { peopleImport, peopleGet } from "../../../actions";

//reducers
import {
  getWorkspaceCurrent,
  getLimit,
} from "../../../reducer";

const PeopleIntro = ({ viewport }) => {
  const workspaceId = useSelector(getWorkspaceCurrent);
  const limit = useSelector(getLimit);
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState();
  const [showModal, setShowModal] = useState(false);

  const handleInputChange = (event) => {
    event.preventDefault();
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmitClick = () => {
    const formData = new FormData();
    formData.append("data[file]", selectedFile);
    if (workspaceId) formData.append("data[workspace_id]", workspaceId);
    setShowModal(true);
    dispatch(
      peopleImport({
        formData,
      })
    );
  };

  const handleCloseModal = () => {
    setShowModal(false);

    dispatch(
      peopleGet({
        workspace_id: workspaceId,
        search_by: "",
        limit: limit,
      })
    );
  };

  return (
    <>
      <Flex
        paddingTop={"64px"}
        alignItems={"center"}
        minHeight={"100vh"}
        width={"100%"}
        flexDirection={"column"}
        justifyContent={"flex-start"}
        px={{ sm: 2, md: 0 }}
        overflow="hidden"
        backgroundColor={theme.colors.lighterGrey}
      >
        <Flex
          flexDirection={"column"}
          alignItems={"center"}
          width={viewport > 2000 ? "85%" : "95%"}
          mt={"30px"}
          backgroundColor={theme.colors.lighterGrey}
        >
          <Card
            imageBlock={Image}
            textBlock={
              <Box marginTop="20px">
                <p
                  style={{
                    fontSize: "1.1rem",
                    fontWeight: "300",
                    lineHeight: "1.5rem",
                  }}
                >
                  This is the people database of your workspace.
                </p>
                <p
                  style={{
                    fontSize: "1.1rem",
                    fontWeight: "300",
                    lineHeight: "1.5rem",
                  }}
                >
                  The people assigned to a project within this workspace are
                  stored together here automatically.
                </p>
                <p
                  style={{
                    fontSize: "1.1rem",
                    fontWeight: "300",
                    lineHeight: "1.5rem",
                  }}
                >
                  In this section you can manage the candidates and assign them
                  to new projects.
                </p>
                <Flex width="100%">
                  <Box width="80%">
                    <InputButton
                      full
                      disabled={false}
                      mb={4}
                      mt={2}
                      variant="secondaryLight"
                      value={selectedFile}
                      handleChange={handleInputChange}
                    />
                  </Box>
                  <Button
                    marginTop="8px"
                    variant={selectedFile ? "secondary" : "disabled"}
                    content="Import"
                    onClick={handleSubmitClick}
                  />
                </Flex>
              </Box>
            }
          ></Card>
        </Flex>
      </Flex>
    </>
  );
};

export default PeopleIntro;
