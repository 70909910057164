import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import theme from "../../../theme";

import Menu from "../icons/menu";
import Config from "../icons/config";
import Edit from "../icons/edit";

const StyledMenu = styled.div`
  display: flex;
  height: 60px;
  align-items: flex-start;
  justify-content: flex-end;
  width: ${(props) => (props.width ? props.width : "5%")};

  * {
    height: 20px;
    width: 20px;
  }
`;

const StyledList = styled.div`
  display: block;
  flex-direction: column;
  margin-top: 25px;
  height: auto;
  position: absolute;
  z-index: 99999;
  background-color: white;
  box-shadow: 0px 1px 3px ${theme.colors.lightGrey};
  width: auto;
`;

const StyledOption = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  font-size: 0.8rem;
  white-space: nowrap;
  width: 100%;
  height: 30px;

  &:hover {
    background-color: ${theme.colors.lighterOrange};
    color: ${theme.colors.blue};
  }
`;

const RowMenu = ({ icon, options, resource, width }) => {
  const node = useRef();
  const [open, setOpen] = useState(false);

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
  };

  const handleAlert = (alert) => {
    alert(resource);
    setOpen(!open);
  };

  const handleClick = (callback) => {
    callback(resource);
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  return (
    <StyledMenu ref={node} width={width}>
      {icon === "menu" ? (
        <Menu variant="red" linkable onClick={() => setOpen(!open)} />
      ) : null}
      {icon === "config" ? (
        <Config variant="red" linkable onClick={() => setOpen(!open)} />
      ) : null}
      {icon === "edit" ? (
        <Edit variant="red" linkable onClick={() => setOpen(!open)} />
      ) : null}

      {open === true && (
        <StyledList>
          {options.map((opt) => (
            <StyledOption
              onClick={
                ["Remove", "Archive", "Leave", "Add Alias"].includes(opt.name) ? () => handleAlert(opt.alert)
                  : opt.callback ? () => handleClick(opt.callback)
                    : handleClose
              }
              key={resource.id + opt.name}
            >
              {opt.name}
            </StyledOption>
          ))}
        </StyledList>
      )}
    </StyledMenu>
  );
};

export default RowMenu;
