import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`

    ${(prop) =>
      prop.landing
        ? `&:hover {
        fill: ${theme.colors.lightBlue}; cursor: pointer;}`
        : ""}
    ${(prop) =>
      prop.linkable
        ? `&:hover {
      fill: ${theme.colors.lightRed}; cursor: pointer;}`
        : ""}
    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black)" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const Github = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <g>
        <path
          className="st0"
          d="M15.7,20.9c-0.1,0-0.4-0.1-0.4-0.5c0-0.5,0-1,0-1.4c0,0,0,0,0,0c0-0.4,0-0.9,0-1.3c0-0.6-0.1-1.1-0.6-1.5
		c-0.1-0.1,0-0.1,0.1-0.1c0.4-0.1,0.9-0.1,1.3-0.3c0.8-0.3,1.6-0.7,2.1-1.4c0.4-0.5,0.6-1.1,0.7-1.6c0.1-0.5,0.2-1,0.1-1.6
		c0-0.8-0.3-1.5-0.8-2.2c-0.1-0.1-0.2-0.3-0.1-0.4C18.4,7.7,18.3,7,18,6.2c0-0.1-0.1-0.1-0.2-0.1c-0.3,0-0.5,0-0.8,0.1
		c-0.6,0.2-1.1,0.5-1.6,0.8c-0.1,0.1-0.2,0.1-0.3,0.1c-0.7-0.2-1.4-0.3-2.1-0.3c-0.6,0-1.1,0-1.7,0.1C11.2,7,11,7,10.7,7.1
		c-0.1,0-0.2,0-0.3-0.1c-0.6-0.4-1.3-0.7-2-0.9c0,0,0,0,0,0C8,6.1,7.9,6.1,7.8,6.5c-0.2,0.7-0.2,1.4,0,2c0.1,0.2-0.1,0.2-0.1,0.3
		C7.3,9.3,7.1,9.7,7,10.2c-0.2,0.7-0.2,1.4-0.1,2.1c0.1,0.6,0.3,1.2,0.6,1.8c0.3,0.5,0.7,0.9,1.2,1.2c0.7,0.4,1.4,0.6,2.2,0.7
		c0.1,0,0.2,0,0.2,0.1c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.3,0.6-0.4,0.9c0,0.2-0.1,0.2-0.3,0.3c-0.4,0.2-0.9,0.2-1.3,0.1
		c-0.4-0.1-0.8-0.3-1-0.6c-0.1-0.2-0.2-0.4-0.4-0.6c-0.3-0.4-0.7-0.7-1.3-0.8c-0.1,0-0.3,0-0.4,0c-0.1,0-0.1,0.1-0.1,0.2
		C6,15.9,6.1,16,6.3,16c0.5,0.3,0.7,0.7,1,1.2c0.1,0.3,0.2,0.5,0.4,0.8c0.3,0.4,0.8,0.6,1.3,0.7c0.5,0.1,1,0.1,1.5,0
		c0.1,0,0.1,0,0.1,0.1c0,0.6,0,1.1,0,1.7c0,0.4-0.2,0.5-0.3,0.5L15.7,20.9z"
        />
      </g>
      <path
        className="st0"
        d="M12.5,24.9c-6.9,0-12.4-5.6-12.4-12.4c0-6.9,5.6-12.4,12.4-12.4s12.4,5.6,12.4,12.4
	C24.9,19.4,19.4,24.9,12.5,24.9z M12.5,1.7c-6,0-10.8,4.9-10.8,10.8c0,6,4.9,10.8,10.8,10.8s10.8-4.9,10.8-10.8
	C23.3,6.5,18.5,1.7,12.5,1.7z"
      />
    </StyledSvg>
  );
};

export default Github;
