import React from "react";
import styled from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

// Components
import Box from "../box";
import Next from "../icons/arrowRightSimple";
import Prev from "../icons/arrowLeftSimple";

const StyledContainer = styled(Box)`
  position: relative;
  margin-bottom: 20px;
  background-color: transparent;
  width: auto;
  display: flex;
  flex-direction: row;
  align-content: center;
`;

const StyledPageFirst = styled("div")(
  {
    fontFamily: "inherit",
    fontWeight: "400",
    fontSize: "0.85rem",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "27px",
    height: "27px",
    padding: "8px",
    margin: "2px",

    borderRadius: "5px 0px 0px 5px",
    border: "1px solid #d9d9d9",

    "&:hover": {
      boxShadow: "-2px 2px 2px lightgray",
      cursor: "pointer",
    },
  },

  variant({
    variants: {
      primary: {
        bg: theme.colors.white,
        color: theme.colors.lightRed,
      },
      secondary: {
        bg: theme.colors.white,
        color: theme.colors.blue,
      },
      disabled: {
        bg: theme.colors.lighterGrey,
        "&:hover": { cursor: "not-allowed", boxShadow: "none" },
      },
    },
  })
);

const StyledPageLast = styled("div")(
  {
    fontFamily: "inherit",
    fontWeight: "400",
    fontSize: "0.85rem",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "27px",
    height: "27px",
    padding: "8px",
    margin: "2px",

    borderRadius: "0px 5px 5px 0px",
    border: "1px solid #d9d9d9",

    "&:hover": {
      boxShadow: "-2px 2px 2px lightgray",
      cursor: "pointer",
    },
  },

  variant({
    variants: {
      primary: {
        bg: theme.colors.white,
        color: theme.colors.lightRed,
      },
      secondary: {
        bg: theme.colors.white,
        color: theme.colors.blue,
      },
      disabled: {
        bg: theme.colors.lighterGrey,
        "&:hover": { cursor: "not-allowed", boxShadow: "none" },
      },
    },
  })
);

const StyledPage = styled("div")(
  {
    fontFamily: "inherit",
    fontWeight: "400",
    fontSize: "0.85rem",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "27px",
    height: "27px",
    padding: "8px",
    margin: "2px",

    border: "1px solid #d9d9d9",

    "&:hover": {
      boxShadow: "-2px 2px 2px lightgray",
      cursor: "pointer",
    },
  },

  variant({
    prop: "currentState",
    variants: {
      primaryActive: {
        color: theme.colors.white,
        bg: theme.colors.lightRed,
        borderRadius: "4px",
        border: `1px solid ${theme.colors.lightRed}`,
      },

      secondaryActive: {
        color: theme.colors.white,
        bg: theme.colors.blue,
        borderRadius: "4px",
        border: `1px solid ${theme.colors.blue}`,
      },

      disabled: {
        borderRadius: "4px",
        bg: theme.colors.lighterGrey,
        "&:hover": { cursor: "not-allowed", boxShadow: "none" },
      },
    },
  }),

  variant({
    variants: {
      primary: {
        bg: theme.colors.white,
        color: theme.colors.lightRed,
      },
      secondary: {
        bg: theme.colors.white,
        color: theme.colors.blue,
      },
      disabled: {
        bg: theme.colors.lighterGrey,
        "&:hover": { cursor: "not-allowed", boxShadow: "none" },
      },
    },
  })
);

const StyledIcon = styled.div`
  width: 15px;
  height: 15px;
  display: flex;
  align-content: center;
`;

const Pagination = ({
  totalPages,
  currentPage,
  firstPage,
  lastPage,
  prevPage,
  nextPage,
  people,
  ...props
}) => (
  <StyledContainer {...props}>
    {totalPages > 1 && currentPage !== 1 ? (
      <>
        <StyledPageFirst variant="primary" onClick={firstPage}>
          1
        </StyledPageFirst>
        <StyledPage title="Prev. page" variant="primary" onClick={prevPage}>
          <StyledIcon>
            <Prev variant="lightRed" />
          </StyledIcon>
        </StyledPage>
      </>
    ) : null}

    <StyledPage currentState="primaryActive">{currentPage}</StyledPage>

    {currentPage !== totalPages ? (
      <>
        <StyledPage title="Next page" variant="primary" onClick={nextPage}>
          <StyledIcon>
            <Next variant="lightRed" />
          </StyledIcon>
        </StyledPage>
        <StyledPageLast variant="primary" onClick={lastPage}>
          {totalPages}
        </StyledPageLast>
      </>
    ) : null}
  </StyledContainer>
);

export default Pagination;
