import { combineActions, handleActions } from "redux-actions";
import { lensProp, pipe, set, view } from "ramda";
import {
  onExpiredToken,
  peopleGet,
  peopleGetSuccess,
  peopleGetFailure,
  personGet,
  personGetSuccess,
  personGetFailure,
  peopleDataClean,
  talentPoolGet,
  talentPoolPageGet,
  talentPoolGetSuccess,
  talentPoolGetFailure,
  peopleImport,
  peopleImportSuccess,
  peopleImportFailure,
} from "../actions";

const initialState = {
  inError: false,
  loading: false,
  token: localStorage.getItem("access-token"),
  data: [],
  personData: [],
  links: {},
  meta: {},
  import: {},
  loadingImport: false,
};

const isLoadingLens = lensProp("loading");
const inErrorLens = lensProp("inError");
const tokenLens = lensProp("token");
const dataLens = lensProp("data");
const personDataLens = lensProp("personData");
const linksLens = lensProp("links");
const metaLens = lensProp("meta");
const importLens = lensProp("import");
const isLoadingImportLens = lensProp("loadingImport");

const setData = set(dataLens);
const setPersonData = set(personDataLens);
const setLinks = set(linksLens);
const setMeta = set(metaLens);
const setImport = set(importLens);

export const getIsLoading = view(isLoadingLens);
export const getInError = view(inErrorLens);
export const getToken = view(tokenLens);

export default handleActions(
  {
    // get people list
    [peopleGetSuccess]: (state, action) =>
      pipe(
        setData(action.payload.data.data),
        setLinks(action.payload.data.links.nav),
        setMeta(action.payload.data.meta),
        set(isLoadingLens, false)
      )(state),
    [peopleGetFailure]: (state) =>
      pipe(set(inErrorLens, true), set(isLoadingLens, false))(state),
    [peopleGet]: (state) =>
      pipe(set(inErrorLens, false), set(isLoadingLens, true))(state),
    // Get Person Profile
    [personGetSuccess]: (state, action) =>
      pipe(
        setPersonData(action.payload.data.data),
        set(isLoadingLens, false)
      )(state),
    [personGetFailure]: (state) =>
      pipe(set(inErrorLens, true), set(isLoadingLens, false))(state),
    [personGet]: (state) =>
      pipe(set(inErrorLens, false), set(isLoadingLens, true))(state),
    //get Talent Pool
    [talentPoolGetSuccess]: (state, action) =>
      pipe(
        setData(action.payload.data.data),
        setLinks(action.payload.data.links.nav),
        setMeta(action.payload.data.meta),
        set(isLoadingLens, false)
      )(state),
    [talentPoolGetFailure]: (state) =>
      pipe(set(inErrorLens, true), set(isLoadingLens, false))(state),
    [talentPoolGet]: (state) =>
      pipe(set(inErrorLens, false), set(isLoadingLens, true))(state),
    [talentPoolPageGet]: (state) =>
      pipe(set(inErrorLens, false), set(isLoadingLens, true))(state),
    //import
    [peopleImport]: (state) => 
      pipe(set(importLens, {}), set(isLoadingImportLens, true))(state),
    [peopleImportSuccess]: (state, action) =>
      pipe(setImport(action.payload.data), set(isLoadingImportLens, false))(state),
    [peopleImportFailure]: (state) =>
      pipe(set(inErrorLens, true), set(isLoadingImportLens, false))(state),
    //clean people data
    [peopleDataClean]: (state, action) =>
      pipe(
        setData(initialState.data),
        setLinks({}),
        setMeta({}),
        set(isLoadingLens, false)
      )(state),
    // handle expired token
    [combineActions(onExpiredToken)]: (state) => {
      localStorage.clear(); // remove token
      return {
        ...initialState,
        token: null,
      };
    },
  },
  initialState
);

export const getPeople = (state) => state.people;
export const getPeopleImport = (state) => state.people.import;
export const getIsLoadingImport = (state) => state.people.loadingImport;
export const getPeoplePersonData = (state) => state.people.personData;
export const getPeopleByProjectId = (projectId) => (state) => {
  return (
    state.people.data.find(
      (person) => person.attributes.project_id === projectId
    ) || []
  );
};

export const getPeopleCurrentPage = (state) =>
  state.people.meta?.pages?.current;
export const getPeopleTotalPages = (state) => state.people.meta?.pages?.total;
