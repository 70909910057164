import React from "react";
import theme from "../../../theme";

//components
import Box from "../../ui/box";
import Flex from "../../ui/flex";
import SubNavbar from "../../ui/sub-navbar";
import PeopleList from "./PeopleList";

//container
import AddPeopleModalContainer from "../../../containers/people/AddPeopleModalContainer";
import EditPersonModalContainer from "../../../containers/people/EditPersonModalContainer";

const People = ({
  viewport,
  handleDropdown,
  handleCheckbox,
  people,
  workspace,
  tableBarContent,
  rowMenuOptions,
  activePerson,
  isEditActive,
  closeEditModalHandler,
  handleChangeSearchBy,
  handleEnterSearchBy,
  handleSubmitEdit,
  searchBy,
  firstPage,
  lastPage,
  prevPage,
  nextPage,
  currentPage,
  totalPages,
  asyncReload,
  peopleCheckboxStatus,
}) => {
  return (
    <Flex
      paddingTop="64px"
      alignItems="center"
      height="auto"
      width="100%"
      flexDirection="column"
      justifyContent="flex-start"
      backgroundColor={theme.colors.lighterGrey}
    >
      <Box width="inherit">
        <SubNavbar
          buttons
          actionButtons={<AddPeopleModalContainer />}
        ></SubNavbar>
      </Box>

      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width={viewport > 2000 ? "85%" : "95%"}
        backgroundColor={theme.colors.lighterGrey}
      >
        <EditPersonModalContainer
          person={activePerson}
          isEditActive={isEditActive}
          handleSubmitEdit={handleSubmitEdit}
          closeEditModalHandler={closeEditModalHandler}
        />
        <PeopleList
          rowMenuOptions={rowMenuOptions}
          handleCheckbox={handleCheckbox}
          tableBarContent={tableBarContent}
          people={people}
          handleDropdown={handleDropdown}
          workspace={workspace}
          handleChangeSearchBy={handleChangeSearchBy}
          handleEnterSearchBy={handleEnterSearchBy}
          searchBy={searchBy}
          firstPage={firstPage}
          lastPage={lastPage}
          prevPage={prevPage}
          nextPage={nextPage}
          currentPage={currentPage}
          totalPages={totalPages}
          asyncReload={asyncReload}
          peopleCheckboxStatus={peopleCheckboxStatus}
        />
      </Flex>
    </Flex>
  );
};

export default People;
