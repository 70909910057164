import React, {useState} from "react";
import styled from "styled-components";
import theme from "../../../theme";
import { useDispatch } from "react-redux";

// Components
import Card from "@ui/card/index";
import Flex from "@ui/flex";
import Input from "@ui/input";
import Button from "@ui/button/index";
import Text from "@ui/text/index";
import Box from "@ui/box";
import Close from "@ui/icons/close";

import {
  integrationAliasesUpdate
} from "@actions";

const StyledBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99999;
`;

const StyledCardContainer = styled.div`
  position: fixed;
  background: transparent;
  width: 40%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledItemName = styled.div`
  font-size: 1.3rem;
  padding-bottom: 15px;
  display: flex;
  justify-content: center;
  color: ${theme.colors.lightRed};
  font-weight: 400;
`;

const EditAliasesModal = ({
  provider,
  closeModal
}) => {
  const dispatch = useDispatch();
  const [dirtyChanges, setDirtyChanges] = useState({
    aliases: provider.attributes.aliases
  });

  const handleChange = (event) => {
    setDirtyChanges({
      aliases: event.target.value
    })
  }

  const handleSubmit = () => {
    let payload = {
      id: provider.attributes.id,
      workspace_id: provider.attributes.workspace_id,
      aliases: dirtyChanges.aliases.split(',').map((e) => e.trim())
    }

    dispatch(
      integrationAliasesUpdate(
        payload
      )
    );

    closeModal()
  }

  return <StyledBackground>
    <StyledCardContainer>
      <Card
          fullHeader
          title="Edit Povider Aliases"
          icon={<Close variant="grey"></Close>}
          closeHandler={closeModal}
          footer
          buttons={[
            <Button
              key="cancel"
              variant="secondaryLine"
              content="Cancel"
              onClick={closeModal}
            />,
            <Button
              key="save"
              variant="secondaryLine"
              content={"Save"}
              type="submit"
              onClick={handleSubmit}
            />,
          ]}
      >
        <Flex width="90%" justifyContent="space-evenly" alignItems="center">
          <Box width={"90%"} minHeight="300px">
            <form>
              <Input
                name="aliases"
                label="Email Aliases"
                mb={2}
                mt={2}
                placeholder={"Email separated by comma. E.g.: bob@email.com, steve@email.com"}
                value={dirtyChanges.aliases}
                onChange={handleChange}
                multiline
                autoFocus
              />
            </form>
          </Box>
        </Flex>
      </Card>
    </StyledCardContainer>
  </StyledBackground>
};

export default EditAliasesModal;
