import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as R from "ramda";

import Modal from "@ui/modal/Modal";

import { SequenceForm, StepForm } from ".";

const EditSequenceModal = ({
  sequence,
  sequenceSteps,
  dirtyChanges,
  editorStates,
  handleCloseModal,
  handleSubmitSequence,
  handleHasReview,
  handleAllowSendWeekend,
  handleAllowSendHoliday,
  handleSelectCountry,
  handleSelectTimezone,
  handleInputChange,
  integrations,
  hasChanges,
  setHasChanges,
  handleEditorChange,
  showNotification,
  setShowNotification,
  handleHasProviderSignature,
  handleRemoveField,
  handleInsertField,
  handleInputName,
  handleDropdown,
  handleInputFocus,
  handleSubjectBlur,
  handleSubmitNewVariable,
  stepLabels,
  candidateVariables,
  projectVariables,
  customFieldsNames,

}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
    control,
  } = useForm();

  const closeHandler = () => {
    clearErrors();
    setShowNotification(false);
    handleCloseModal();
  };

  const cardTabsErrors = () => {
    let cardsIndexes = [];
    let stepIndex = (error) =>
      error && parseInt(R.values(error)[0].ref.name.split(".")[1]) + 1;

    if (errors?.name) cardsIndexes.push(0);
    if (errors?.steps) {
      cardsIndexes = R.concat(cardsIndexes, R.map(stepIndex, errors.steps));
    }
    sequenceSteps.forEach((step, index) => {
      if (
        (step.attributes.provider_type == null || step.attributes.provider_name == null)
        && dirtyChanges?.steps[index]?.provider_id === undefined
      )
        cardsIndexes.push(index + 1);
    });
    return cardsIndexes;
  };

  useEffect(() => {
    cardTabsErrors();
  }, [dirtyChanges]);

  return (
    <Modal
      cardTabs
      shortWidth
      handleNext
      title={sequence.attributes.name}
      showNotification={showNotification}
      handleCloseModal={closeHandler}
      labels={["Info", ...stepLabels]}
      onSubmitTab={handleSubmit(handleSubmitSequence, cardTabsErrors)}
      isEdit={true}
      hasChanges={hasChanges}
      errors={cardTabsErrors()}
    >
      {[
        <SequenceForm
          key="info"
          sequence={sequence}
          dirtyChanges={dirtyChanges}
          register={register}
          errors={errors}
          setValue={setValue}
          setError={setError}
          clearErrors={clearErrors}
          handleInputChange={handleInputChange}
          handleSelectCountry={handleSelectCountry}
          handleSelectTimezone={handleSelectTimezone}
          handleAllowSendWeekend={handleAllowSendWeekend}
          handleAllowSendHoliday={handleAllowSendHoliday}
          handleHasReview={handleHasReview}
          isEdit={true}
        />,
        ...sequenceSteps.map((step, indexStep) => (
          <StepForm
            key={indexStep}
            step={step}
            indexStep={indexStep}
            workspaceId={sequence.attributes.workspace_id}
            dirtyChanges={dirtyChanges}
            editorStates={editorStates}
            integrations={integrations}
            register={register}
            errors={errors}
            setValue={setValue}
            setError={setError}
            getValues={getValues}
            control={control}
            clearErrors={clearErrors}
            handleEditorChange={handleEditorChange}
            handleInputChange={handleInputChange}
            handleInputName={handleInputName}
            handleDropdown={handleDropdown}
            handleInputFocus={handleInputFocus}
            handleSubjectBlur={handleSubjectBlur}
            handleInsertField={handleInsertField}
            handleSubmitNewVariable={handleSubmitNewVariable}
            customFieldsNames={customFieldsNames}
            isEdit={true}
            setHasChanges={setHasChanges}
            handleHasProviderSignature={handleHasProviderSignature}
            handleRemoveField={handleRemoveField}
            candidateVariables={candidateVariables}
            projectVariables={projectVariables}
          />
        )),
      ]}
    </Modal>
  );
};

export default EditSequenceModal;
