import { compose, createStore, applyMiddleware } from "redux";
import { createEpicMiddleware } from "redux-observable";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { composeWithDevTools as remoteDevToolsCompose } from "remote-redux-devtools";

import createRootReducer from "../reducer";
import epic from "../epic";
import { createApiClient } from "../utils";

const makeComposer = () =>
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })
    : remoteDevToolsCompose || compose;

const history = createBrowserHistory();
const composeEnhancers = makeComposer();
const configureStore = (initialState) => {
  const api = createApiClient();
  const epicMiddleWare = createEpicMiddleware({ dependencies: { api } });
  const store = createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), epicMiddleWare))
  );
  api.generateInterceptors(store);

  // Exposes the store for testing in Cypress
  if (window.Cypress) {
    window.store = store;
  }

  epicMiddleWare.run(epic);
  return { store, history };
};

export default configureStore;
