import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.linkable
        ? `&:hover {
      filter: drop-shadow(-1px 1px 1px #4BAAC8); cursor: pointer;}`
        : ""}

    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black);" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const People = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} width="30" height="30" viewBox="2.5 2.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" variant={variant}>
      <path fillRule="evenodd" clipRule="evenodd" d="M11.9895 19.0085C16.6008 19.0085 20.542 19.7073 20.542 22.4985C20.542 25.2898 16.627 26.0085 11.9895 26.0085C7.37701 26.0085 3.43701 25.316 3.43701 22.5235C3.43701 19.731 7.35076 19.0085 11.9895 19.0085Z" stroke="#515050" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M11.9893 15.0248C8.96184 15.0248 6.50684 12.571 6.50684 9.54351C6.50684 6.51601 8.96184 4.06226 11.9893 4.06226C15.0156 4.06226 17.4706 6.51601 17.4706 9.54351C17.4818 12.5598 15.0443 15.0135 12.0281 15.0248H11.9893Z" stroke="#363636" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20.604 13.602C22.6053 13.3207 24.1465 11.6032 24.1503 9.52447C24.1503 7.47572 22.6565 5.77572 20.6978 5.45447" stroke="#363636" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M23.2441 18.4153C25.1829 18.704 26.5366 19.384 26.5366 20.784C26.5366 21.7478 25.8991 22.3728 24.8691 22.764" stroke="#363636" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </StyledSvg>
  );
};

export default People;
