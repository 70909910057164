import React from "react";
import theme from "../../../theme";
import { Link } from "react-router-dom";
import Box from "../../ui/box";
import Card from "../../ui/card";
import Image from "../../../assets/img/workflow.svg";
import Flex from "../../ui/flex";
import Dropdown from "../../ui/dropdown/index";
import Button from "../../ui/button/index";

const WorkflowTemplateIntro = ({
  dirtyChanges,
  handleDropdownSelect,
  workflows,
  workspace,
  handleSubmitClick,
  viewport,
}) => {
  return (
    <Flex
      flexDirection={"column"}
      alignItems={"center"}
      width={"100%"}
      backgroundColor={theme.colors.lighterGrey}
    >
      <Card
        imageBlock={Image}
        textBlock={
          <Flex flexDirection="column">
            <Box>
              <p
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                Workflow helps you organize people within a project and also
                keep track of progress.
              </p>
              <p
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                In each instance you can add actions such as exporting people,
                starting a message sequence, etc.
              </p>
              <p
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                Also can be used in different projects within the same
                workspace, save time reusing your Workflows templates!
              </p>
            </Box>
            <Box>
              <p
                style={{
                  fontSize: "1rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                You can create a new Workflow template by clicking on the button{" "}
                <b>+ WORKFLOW</b> under the navbar.
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                Once you assign a Workflow template to a Project the Workflow
                will be duplicated and you will see it on the Assigned tab.
              </p>
            </Box>
          </Flex>
        }
      ></Card>
    </Flex>
  );
};

export default WorkflowTemplateIntro;
