import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black)" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const Setting = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <g>
        <path
          d="M22.5,4.3h-3V3.8c0-1.1-0.9-2-2-2h-1.3c-1.1,0-2,0.9-2,2v0.5H2.5C2.1,4.3,1.7,4.6,1.7,5s0.3,0.8,0.8,0.8h11.8
		v0.5c0,1.1,0.9,2,2,2h1.3c1.1,0,2-0.9,2-2V5.8h3c0.4,0,0.8-0.3,0.8-0.8S22.9,4.3,22.5,4.3z M18,6.3c0,0.3-0.2,0.5-0.5,0.5h-1.3
		c-0.3,0-0.5-0.2-0.5-0.5V3.8c0-0.3,0.2-0.5,0.5-0.5h1.3c0.3,0,0.5,0.2,0.5,0.5V6.3z"
        />
        <path
          d="M22.5,11.8H10.7v-0.5c0-1.1-0.9-2-2-2H7.5c-1.1,0-2,0.9-2,2v0.5h-3c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h3
		v0.5c0,1.1,0.9,2,2,2h1.3c1.1,0,2-0.9,2-2v-0.5h11.8c0.4,0,0.8-0.3,0.8-0.8S22.9,11.8,22.5,11.8z M9.2,13.8c0,0.3-0.2,0.5-0.5,0.5
		H7.5C7.2,14.3,7,14,7,13.8v-2.5c0-0.3,0.2-0.5,0.5-0.5h1.3c0.3,0,0.5,0.2,0.5,0.5V13.8z"
        />
        <path
          d="M22.5,19.3H17v-0.5c0-1.1-0.9-2-2-2h-1.3c-1.1,0-2,0.9-2,2v0.5H2.5c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8
		h9.3v0.5c0,1.1,0.9,2,2,2H15c1.1,0,2-0.9,2-2v-0.5h5.5c0.4,0,0.8-0.3,0.8-0.8S22.9,19.3,22.5,19.3z M15.5,21.3
		c0,0.3-0.2,0.5-0.5,0.5h-1.3c-0.3,0-0.5-0.2-0.5-0.5v-2.5c0-0.3,0.2-0.5,0.5-0.5H15c0.3,0,0.5,0.2,0.5,0.5V21.3z"
        />
      </g>
    </StyledSvg>
  );
};

export default Setting;
