import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import theme from "@theme";

import Flex from "@ui/flex";
import Text from "@ui/text";
import ReactTooltip from "react-tooltip";

//Icons
import Sequence from "@ui/icons/template";
import Play from "@ui/icons/play";
import Pause from "@ui/icons/pause";

//Actions
import { sequenceProcessorSendEvent } from "@actions";

//Reducer
import { getLimit } from "@reducer";

const SequenceStatusLabel = styled.div`
  font-family: "Open Sans";
  font-size: 0.9rem;
  margin-left: 15px;
  text-transform: capitalize;
`;

const StyledIcon = styled.button`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

const SequenceStatus = ({ person, project, index, sequence, workspace }) => {
  const dispatch = useDispatch();
  const limit = useSelector(getLimit);

  const showControls =
    sequence?.status !== "replied" &&
    sequence?.status !== "finished" &&
    sequence?.status !== "pending";

  const handleDateLastSent = (date) => {
    let newDate = date;
    if (date) {
      newDate = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZoneName: "short",
      }).format(new Date(date));
    }
    return newDate;
  };

  return (
    <Flex alignItems="flex-end" data-tip data-for={`dateLastStep${index}`}>
      <Text icon={<Sequence variant="blue" />}> </Text>

      <Text
        marginLeft="5px"
        fontSize="0.9rem"
        color={theme.colors.darkBlue}
        fontWeight={400}
      >
        {sequence?.last_step_sent_position?.toString()}
      </Text>
      <Text fontSize="0.8rem" color={theme.colors.darkBlue}>
        /{sequence?.total_steps?.toString()}
      </Text>
      <SequenceStatusLabel>{sequence?.status}</SequenceStatusLabel>

      {showControls && (
        <Flex marginLeft="25px">
          <StyledIcon disabled={sequence?.status === "sending"}>
            <Play
              variant={
                sequence?.status !== "sending"
                  ? "darkBlue"
                  : "lightGrey"
              }
              linkable={sequence?.status !== "sending"}
              disabled={sequence?.status === "sending"}
              onClick={() =>
                dispatch(
                  sequenceProcessorSendEvent({
                    limit: limit,
                    project_id: project.id,
                    project_people_id: person.project_person_id,
                    sequence_processor_id: sequence.id,
                    event_name: "event_resume",
                  })
                )
              }
            />
          </StyledIcon>
          <StyledIcon disabled={sequence?.status === "paused"}>
            <Pause
              variant={
                sequence?.status !== "sending"
                  ? "lightGrey"
                  : "darkBlue"
              }
              linkable={sequence?.status !== "paused"}
              disabled={sequence?.status === "paused"}
              onClick={() =>
                dispatch(
                  sequenceProcessorSendEvent({
                    limit: limit,
                    project_id: project.id,
                    project_people_id: person.project_person_id,
                    sequence_processor_id: sequence.id,
                    event_name: "event_pause",
                  })
                )
              }
            />
          </StyledIcon>
        </Flex>
      )}

      {sequence?.last_step_sent_date && (
        <ReactTooltip id={`dateLastStep${index}`} aria-haspopup="true">
          Sent on {handleDateLastSent(sequence?.last_step_sent_date)}
        </ReactTooltip>
      )}
    </Flex>
  );
};
export default SequenceStatus;
