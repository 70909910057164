import React from "react";
import styled from "styled-components";
import theme from "../../../theme";
import { color, fontSize, space, top } from "styled-system";

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  cursor: pointer;
  height: 0;
  opacity: 0;
  outline: none;
  position: absolute;
  width: 0;
  &:focus,
  &:hover,
  &:active {
    outline: none;
  }
`;

const CheckmarkIcon = styled.span`
  border-radius: ${({ shape }) => (shape === "square" ? "4px" : "50%")};
  border: 1px solid ${theme.colors.blue};
  background-color: ${theme.colors.white};
  height: 18px;
  left: ${({ iconPosition }) => (iconPosition === "left" ? "0px" : "auto")};
  position: absolute;
  right: ${({ iconPosition }) => (iconPosition === "right" ? "0px" : "auto")};
  top: 0px;
  width: 18px;
  ${color}
  :after {
    border-color: ${theme.colors.white};
    border-style: solid;
    background-color: transparent;
    border-width: 0 1px 1px 0;
    color: ${theme.colors.white};
    content: "";
    display: none;
    height: 10px;
    left: 5px;
    position: absolute;
    top: 1px;
    transform: rotate(45deg);
    width: 5px;
  }

  &:active,
  &:focus,
  &:hover {
    box-shadow: ${(props) =>
    props.inactive ? "none" : `0px 1px 2px ${theme.colors.blue}`};
  }

  ${(props) =>
    props.inactive
      ? `border-color: ${theme.colors.grey}; 
  background-color: ${theme.colors.lightGrey};`
      : null}
`;

const StyledCheckbox = styled.label`
  font-family:  ${theme.fonts[1]};
  line-height: 22px;
  cursor: ${(props) => (props.inactive ? "not-allowed" : "pointer")};;
  display: block;
  font-weight: 300;
  outline: none;
  padding: ${({ iconPosition }) =>
    iconPosition === "left" ? "0 0 0 32px" : "0 32px 0 0"};
  position: relative;
  user-select: none;
  ${color}
  ${fontSize}
  ${top}
  ${space}

  
  ${HiddenCheckbox}:checked ~ ${CheckmarkIcon} {
    background-color: ${theme.colors.blue};
    border-color: ${theme.colors.blue};
  }

  ${HiddenCheckbox}:checked ~ ${CheckmarkIcon}:after {
    display: block;
  }
  ${(props) => (props.inactive ? `color:${theme.colors.lightGrey}` : "")}
`;

const Checkbox = ({
  bg = "white",
  checked,
  color = "",
  fontSize = 2,
  iconPosition = "left",
  name,
  onChange,
  shape = "circle",
  top,
  value,
  inactive,
  ...props
}) => {
  return (
    <StyledCheckbox
      bg={bg}
      fontSize={fontSize}
      iconPosition={iconPosition}
      top={top}
      inactive={inactive}
      {...props}
    >
      {props.children}
      <HiddenCheckbox
        checked={checked}
        name={name}
        onChange={onChange}
        value={value}
        inactive={inactive}
      />
      <CheckmarkIcon
        color={color}
        iconPosition={iconPosition}
        shape={shape}
        inactive={inactive}
      />
    </StyledCheckbox>
  );
};

export default Checkbox;
