import React from "react";
import { ResponsivePie } from "@nivo/pie";

const ResponsivePieChart = ({ openedRate }) => {
  const data = [
    {
      id: "Opened",
      value: openedRate,
    },
    {
      id: "Waiting",
      value: 1 - openedRate,
    },
  ];

  const CenteredMetric = ({ centerX, centerY }) => {
    let total = Math.round(openedRate * 100);

    return (
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: "12px",
          color: "#ABABAB",
          fontWeight: 300,
        }}
      >
        {total}%
      </text>
    );
  };

  return (
    <ResponsivePie
      data={data}
      colors={["#4BAAC8", "#efefef"]}
      margin={{
        top: 5,
        right: 5,
        bottom: 5,
        left: 5,
      }}
      innerRadius={0.7}
      padAngle={0.7}
      cornerRadius={0}
      activeOuterRadiusOffset={3}
      enableArcLabels={false}
      enableArcLinkLabels={false}
      isInteractive={false}
      layers={["arcs", "arcLabels", "arcLinkLabels", "legends", CenteredMetric]}
    />
  );
};

export default ResponsivePieChart;
