import React, { useState } from "react";
import styled from "styled-components";
import theme from "../../../theme";
import { variant } from "styled-system";

const StyledTabContainer = styled.div`
  display: flex;
  background-color: white;
  justify-content: flex-start;
  flex-direction: row;
`;

const StyledTabList = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  width: auto;
`;

const StyledTab = styled.div`
  min-width: 5%;
  height: 100%;
  color: gray;

  &:hover {
    ${(props) =>
      props.variant === "blue" ? `color: ${theme.colors.blue}` : ``};
    ${(props) => (props.variant === "red" ? `color: ${theme.colors.red}` : ``)};
    cursor: pointer;
  }
`;

const TabLabel = styled.div`
  font-size: 16px;
  padding: 0px 20px;
  margin-top: 20px;
`;

const SelectIndicator = styled("div")(
  {
    width: "100%",
    height: "10px",
    borderBottom: "5px solid",
    position: "relative",
    bottom: "0px",
  },
  variant({
    variants: {
      blue: {
        borderColor: theme.colors.blue,
      },
      red: {
        borderColor: theme.colors.red,
      },
    },
  })
);

const StyledSubNavbar = styled.div`
  background-color: white;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0px 30px;
  position: relative;
  top: 0;

  ${(props) => (props.tags ? `justify-content: flex-start` : ``)}
  ${(props) =>
    props.iconButtons && props.tags ? `justify-content: space-between` : ``}
`;

const StyledArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  ${(props) => (props.left ? `justify-content: flex-start` : ``)}
  ${(props) => (props.right ? `justify-content: flex-end` : ``)}
`;

const StyledIcon = styled.div`
  width: 23px;
  height: 23px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const SubNavbarTabs = ({ iconButtons, labels, children, onSelect}) => {
  const [activeTabId, setState] = useState(0);

  return (
    <>
      <StyledSubNavbar>
        <StyledTabContainer>
          <StyledTabList>
            {labels.map((label, index) => (
              <StyledTab
                key={index}
                variant="red"
                onClick={() => {
                  setState(index);
                  onSelect ? onSelect(label) : null;
                }}
              >
                <TabLabel>{label}</TabLabel>
                {index === activeTabId ? (
                  <SelectIndicator variant="red"></SelectIndicator>
                ) : null}
              </StyledTab>
            ))}
          </StyledTabList>
        </StyledTabContainer>
        {iconButtons ? (
          <StyledArea right>
            {iconButtons.map((btn, index) => (
              <StyledIcon key={index}>{btn}</StyledIcon>
            ))}
          </StyledArea>
        ) : null}
      </StyledSubNavbar>
      {children[activeTabId]}
    </>
  );
};
export default SubNavbarTabs;
