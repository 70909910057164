import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as R from "ramda";

//Actions
import { sequenceUpdate, integrationsGet } from "@actions";

//Reducers
import {
  getIntegrations,
  getWorkspaceCurrent,
  getSequencesSteps,
} from "@reducer";

//Component
import { EditSequenceModal } from "@components/domain/sequences";

//Utils
import { getRelated } from "@utils";
import { countriesList } from "@utils/countries";

//Hooks
import { useSequenceForm } from "@hooks";

// Wysiwyg editor
import {
  EditorState,
  Modifier,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";

const EditSequenceModalContainer = ({
  sequence,
  steps,
  credentials,
  handleCloseModal,
}) => {
  const dispatch = useDispatch();

  const [dirtyChanges, setDirtyChanges] = useState({});
  const [sequenceSteps, setSequenceSteps] = useState([]);
  const [editorStates, setEditorStates] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [convertedTemplates, setConvertedTemplates] = useState([]);
  const countries = countriesList();
  const integrations = useSelector(getIntegrations);
  const workspaceId = useSelector(getWorkspaceCurrent);
  const payload_fields = { ...dirtyChanges.payload_fields || sequence.attributes.payload_fields };
  const {
    handleInputName,
    handleInsertSubjectField,
    handleDropdown,
    handleInputFocus,
    handleSubjectBlur,
    handleSubmitNewVariable,
    stepLabels,
    inputFocused,
    candidateVariables,
    projectVariables,
    customFieldsNames,
    defaultFieldsNames,
    handleHasProviderSignature,
  } = useSequenceForm(sequence, sequenceSteps, dirtyChanges, setDirtyChanges);

  useEffect(() => {
    if (steps) {
      const relatedSteps = getRelated(sequence.relationships.steps.data, steps);
      setSequenceSteps(relatedSteps);
    }
  }, [steps]);

  useEffect(() => {
    if (workspaceId) {
      dispatch(integrationsGet({ workspace_id: workspaceId }));
    }
  }, [workspaceId]);

  useEffect(() => {
    const emptyDirtySteps = {
      steps: sequenceSteps.map((step) => {
        return {
          id: step.id,
        };
      }),
    };
    setDirtyChanges(emptyDirtySteps);
  }, [sequenceSteps]);

  const handleSubmitSequence = () => {
    let steps = { ...dirtyChanges.steps };

    Object.values(steps).forEach((step) => {
      delete step.provider_type;
    });

    dispatch(
      sequenceUpdate({
        ...dirtyChanges,
        steps: steps,
        id: sequence.id,
        workspace_id: sequence.attributes.workspace_id,
      })
    );

    setHasChanges(false);
    setShowNotification(true);
  };

  const handleSelectCountry = (event, value) => {
    let country = countries.filter(function (e) {
      return e.name === value;
    })[0];
    setDirtyChanges({
      ...dirtyChanges,
      ["holiday_country"]: country["code"],
    });
    setHasChanges(true);
  };

  const handleAllowSendHoliday = (value) => {
    setDirtyChanges({
      ...dirtyChanges,
      allow_send_holiday: value,
    });
    setHasChanges(true);
  };

  const handleAllowSendWeekend = (value) => {
    setDirtyChanges({
      ...dirtyChanges,
      allow_send_weekend: value,
    });
    setHasChanges(true);
  };

  const handleHasReview = (value) => {
    setDirtyChanges({
      ...dirtyChanges,
      has_review: value,
    });

    setHasChanges(true);
  };

  const handleInputChange = (event, index) => {
    if (index !== undefined && index !== null) {
      let steps = { ...dirtyChanges.steps };
      let field = event.target.name.split(".")[2];
      steps[index][field] = event.target.value;

      setDirtyChanges({
        ...dirtyChanges,
        steps,
      });

      setHasChanges(true);
    } else {
      setDirtyChanges({
        ...dirtyChanges,
        [event.target.name]: event.target.value,
      });
      setHasChanges(true);
    }
  };

  const handleEditorChange = (state, index) => {
    const newEditorStates = [...editorStates];
    newEditorStates[index] = state;

    setEditorStates(newEditorStates);
    convertContentToHTML(index, state);
    setHasChanges(true);
  };

  const handleInsertEditorField = (field, index) => {
    if (customFieldsNames().includes(field) || defaultFieldsNames().includes(field)) {
      const newContentState = Modifier.insertText(
        editorStates[index].getCurrentContent(),
        editorStates[index].getSelection(),
        "{{" + field + "}}"
      );
      const newEditor = EditorState.push(
        editorStates[index],
        newContentState,
        "insert-fragment"
      );
      editorStates[index] = newEditor;
      setEditorStates(editorStates);
      convertContentToHTML(index, newEditor);
    }
  };

  const convertContentToHTML = (index, editor) => {
    let currentContentAsHTML = draftToHtml(
      convertToRaw(editor.getCurrentContent())
    );
    let dirtySteps = { ...dirtyChanges.steps };

    convertedTemplates[index] = currentContentAsHTML;
    setConvertedTemplates(convertedTemplates);
    dirtySteps[index]["template_body"] = currentContentAsHTML;

    setDirtyChanges({
      ...dirtyChanges,
      steps: dirtySteps,
    });
  };

  const convertHtmlToEditorState = (htmlContent) => {
    const blocksFromHTML = convertFromHTML(htmlContent);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    return EditorState.createWithContent(state);
  };

  useEffect(() => {
    if (sequenceSteps && sequenceSteps.length > 0) {
      setEditorStates([]);
      sequenceSteps.map((step, index) => {
        if (step?.attributes) {
          const state = convertHtmlToEditorState(step.attributes.template_body);
          editorStates[index] = state;
          setEditorStates(editorStates);
        }
      });
    }
  }, [sequenceSteps]);

  const handleRemoveField = (field, index) => {
    if (customFieldsNames().includes(field)) {
      let indexToRemove = R.findIndex(R.propEq("name", field))(payload_fields["customs"]);
      payload_fields["customs"].splice(indexToRemove, 1);
    }
    setDirtyChanges({
      ...dirtyChanges,
      ["payload_fields"]: payload_fields,
    });
    setHasChanges(true);
  };

  const handleInsertField = (field, index) => {
    if (customFieldsNames().includes(field) || defaultFieldsNames().includes(field)) {
      let input = inputFocused;
      if (input === "template_subject") handleInsertSubjectField(field, index);
      if (input === "editor") handleInsertEditorField(field, index);
    }
  };

  const handleSelectTimezone = (event, value) => {
    setDirtyChanges({
      ...dirtyChanges,
      ["timezone"]: value,
    });
  };

  return (
    <EditSequenceModal
      sequence={sequence}
      sequenceSteps={sequenceSteps}
      dirtyChanges={dirtyChanges}
      handleCloseModal={handleCloseModal}
      handleSubmitSequence={handleSubmitSequence}
      handleHasReview={handleHasReview}
      handleAllowSendWeekend={handleAllowSendWeekend}
      handleAllowSendHoliday={handleAllowSendHoliday}
      handleSelectCountry={handleSelectCountry}
      handleSelectTimezone={handleSelectTimezone}
      handleInputChange={handleInputChange}
      integrations={integrations}
      hasChanges={hasChanges}
      setHasChanges={setHasChanges}
      handleEditorChange={handleEditorChange}
      editorStates={editorStates}
      convertContentToHTML={convertContentToHTML}
      showNotification={showNotification}
      setShowNotification={setShowNotification}
      handleHasProviderSignature={handleHasProviderSignature}
      handleRemoveField={handleRemoveField}
      handleInsertField={handleInsertField}
      handleInputName={handleInputName}
      handleDropdown={handleDropdown}
      handleInputFocus={handleInputFocus}
      handleSubjectBlur={handleSubjectBlur}
      handleSubmitNewVariable={handleSubmitNewVariable}
      stepLabels={stepLabels}
      candidateVariables={candidateVariables}
      projectVariables={projectVariables}
      customFieldsNames={customFieldsNames}
    />
  );
};

export default EditSequenceModalContainer;
