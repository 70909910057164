import React from "react";
import styled from "styled-components";
import theme from "../../../theme";
//components
import Flex from "../flex/index";
import Box from "../box/index";
import DropdownId from "../dropdown-with-id/index";
import Button from "../button/index";
import Checkbox from "../../ui/checkbox/index";
import { AssignPeopleModal } from "../modal";

const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
`;

const StytedText = styled.div`
  display: flex;
  font-size: 1rem;
  font-weight: 200;
  padding-right: 10px;
  color: ${theme.colors.darkBlue};
`;

const StyledNumber = styled.div`
  display: flex;
  padding: 0;
  font-weight: 400;
  font-size: 0.9rem;
  color: ${theme.colors.darkBlue};
`;

const StyledCheckbox = styled.div`
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  position: relative;
  top: 5px;
`;

const Selector = ({
  assigned, //assignTo info (workspace & project)
  projects, // array of projects
  workspaces, // array of workspaces
  totalSelect, // total of elements selected - for display only
  isAssignActive, //modal state - bool
  openAssignModalHandler, // open confirmation modal
  closeAssignModalHandler, // close confirmation modal
  handleSubmitAssign, // submit assign
  handleWorkspace, //set projects options - keep Workspace ID for submit
  handleProject,
  handleCheckAll,
  checkAllState,
}) => {
  const handleAssignedNames = () => {
    const projectSelected = projects.data.find(
      (item) => item.id === assigned.project
    );

    return projectSelected.attributes.name;
  };

  return (
    <StyledContainer>
      {isAssignActive === true && (
        <AssignPeopleModal
          closeModal={closeAssignModalHandler}
          title="Assign People"
          assignItems={totalSelect}
          handleAssignClick={handleSubmitAssign}
          assignedTo={handleAssignedNames()}
        />
      )}
      <StyledCheckbox>
        <Checkbox
          key="checkAll"
          checked={checkAllState}
          top="-5px"
          margin="0px"
          padding="0px"
          shape="square"
          title="Select All"
          onChange={handleCheckAll}
        />
      </StyledCheckbox>

      <StytedText>Selected</StytedText>
      <StyledNumber>({totalSelect ? totalSelect : "0"})</StyledNumber>
      <Flex marginLeft="30px">
        {workspaces?.length > 0 && (
          <Box width="350px">
            <DropdownId
              marginRight="15px"
              formStyle
              name="workspace"
              value={assigned?.workspace}
              placeholder="Add to Workspace..."
              handleDropdown={handleWorkspace}
              options={workspaces.map((workspace) => ({
                id: workspace.id,
                val: workspace.attributes.name,
              }))}
              variant="blue"
              small
              short
            />
          </Box>
        )}

        {projects && (
          <Box width="250px">
            <DropdownId
              formStyle
              name="project"
              value={assigned?.project}
              placeholder="Add to Project..."
              handleDropdown={handleProject}
              options={projects?.data.map((project) => ({
                id: project.id,
                val: project.attributes.name,
              }))}
              variant="blue"
              small
              short
            />
          </Box>
        )}
      </Flex>

      <Button
        size="small"
        content="Assign"
        variant="secondaryLight"
        onClick={openAssignModalHandler}
        disabled={assigned?.workspace && assigned?.project ? false : true}
      ></Button>
    </StyledContainer>
  );
};
export default Selector;
