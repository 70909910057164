/* eslint-disable no-unused-expressions */

import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { omit, map } from "ramda";

import auth, * as fromAuth from "./auth";
import app from "./app";
import integrations from "./integrations";
import invitations from "./invitations";
import messages from "./messages";
import navigation from "./navigation";
import people from "./people";
import projects from "./projects";
import projectPeople from "./project-people";
import projectPeoplePayloads from "./project-people-payloads";
import projectUsers from "./project-users";
import sequences from "./sequences";
import workflows from "./workflows";
import workspace from "./workspaces";

import { bindSelectorState } from "../utils";

export * from "./auth";
export * from "./app";
export * from "./integrations";
export * from "./invitations";
export * from "./messages";
export * from "./navigation";
export * from "./people";
export * from "./projects";
export * from "./project-people";
export * from "./project-people-payloads";
export * from "./project-users";
export * from "./sequences";
export * from "./workflows";
export * from "./workspaces";

export const { getIsLoading: getIsAuthLoading, getToken, getInError } = map(
  bindSelectorState("auth"),
  omit("default", fromAuth)
);

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    app,
    invitations,
    integrations,
    navigation,
    messages,
    people,
    projects,
    projectPeople,
    projectPeoplePayloads,
    projectUsers,
    sequences,
    workflows,
    workspace,
  });

// ui selectors

//Router
export const getRouterState = (state) => state.router;

//Projects
export const getProjects = (state) => state.projects;

export const getCurrentProject = (state) => state.projects.current;

export const getProjectsIncluded = (projectsState, resource, includedMatch) => {
  const record = projectsState?.included?.find((item) =>
    includedMatch(resource, item)
  );
  return record;
};

const matchId = (type, id, item) => item.type === type && item.id === id;
const matchUser = (user_id, item) => matchId("user", user_id, item);
const matchProjectUser = (project_user_id, item) => matchId("project_user", project_user_id, item);

const ROLE_COLORS = { owner: "blue", manager: "red", collaborator: "yellow" };

export const getProjectAccessList = (project, projectsState) => {
  let accessList = [];

  // eslint-disable-next-line
  const pu = project.relationships.project_users?.data.map(
    (project_user, index) => {
      let projectUsersAttrs = getProjectsIncluded(
        projectsState,
        project_user.id,
        matchProjectUser
      )?.attributes;

      if (projectUsersAttrs === undefined) {
        return [];
      }

      let userAttrs = getProjectsIncluded(
        projectsState,
        projectUsersAttrs.user_id,
        matchUser
      ).attributes;

      projectUsersAttrs["first_name"] = userAttrs.first_name;
      projectUsersAttrs["last_name"] = userAttrs.last_name;
      projectUsersAttrs["email"] = userAttrs.email;
      projectUsersAttrs["last_activity"] = userAttrs.updated_at;
      projectUsersAttrs["color"] = ROLE_COLORS[projectUsersAttrs.role];

      accessList.push(projectUsersAttrs);
      return projectUsersAttrs;
    }
  );

  return accessList;
};

export const getProjectUser = (project, user) => (state) => {
  const projectUser = state.projects.included.find((item) => {
    return (
      item.type === "project_user" &&
      item.attributes.user_id === user.id &&
      item.attributes.project_id === project.id
    );
  });

  return projectUser;
};

export const getWorkspaceUser = (workspace_id, user) => (state) => {
  const workspaceUser = state.workspace.included.find((item) => {
    return (
      item.type === "workspace_user" &&
      item.attributes.user_id === user.id &&
      item.attributes.workspace_id === workspace_id
    );
  });

  return workspaceUser;
};

export const getProjectById = (projectId) => (state) => {
  return state.projects.data.find((project) => project.id === projectId) || {};
};
