import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import theme from "../../../theme";

//reducer
import { getWorkflows } from "../../../reducer";

//Component
import Box from "../box";
import Text from "../text";
import ArrowUp from "../icons/arrowUp";
import ArrowDown from "../icons/arrowDown";

//sidebar
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.css";

const StyledContainer = styled(Box)`
  position: relative;
`;

const StyledRequired = styled.span`
  color: ${theme.colors.red};
  font-size: 1rem;
  padding-left: 4px;
`;

const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 80px;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px 4px 0px 0px;
  padding: 0 10px 0 0;
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : `space-between`)};
  border-bottom: ${(props) =>
    props.disabled
      ? `1px solid ${theme.colors.lightGrey}`
      : props.error
        ? `1px solid ${theme.colors.red}`
        : props.formStyle
          ? `1px solid ${theme.colors.blue}`
          : null};
  ${(props) => (props.short ? `height: 40px;` : ` height: 52px;`)}
  ${(props) =>
    props.disabled
      ? `background-color #f6f6f6;
    cursor: not-allowed;
    `
      : "null"}
`;

const StyledIcon = styled.div`
  ${({ small }) =>
    small
      ? `height: 15px; width: 15px;
      margin-left: 10px;`
      : `height: 23px; width: 23px;`}
  ${({ disabled }) =>
    disabled &&
    `&:hover {
      cursor: default;}`}
  ${({ iconBtn, disabled }) =>
    iconBtn &&
    !disabled &&
    `&:hover {
      cursor: pointer;
    }
  `}
`;

const StyledList = styled.ul`
  display: block;
  flex-direction: column;
  margin-top: 1px;
  height: auto;
  position: absolute;
  z-index: 99999;
  background-color: white;
  box-shadow: 0px 1px 3px ${theme.colors.lightGrey};
  width: 100%;
  padding: 0px;
`;

const StyledPlaceholder = styled.div`
  color: ${(props) =>
    props.disabled
      ? `${theme.colors.lightGrey}`
      : props.error
        ? `${theme.colors.lightGrey}`
        : `${theme.colors.blue}`};
  font-style: italic;
  font-size: 0.8rem;
  font-weight: 300;
  padding-left: 8px;
`;

const StyledOption = styled.li`
  padding: 10px;
  font-size: 1rem;
  border: none;
  white-space: nowrap;
  color: ${(props) => (props.selected ? theme.colors.darkBlue : "none")};
  font-weight: ${(props) => (props.selected ? "400" : "100")};

  &:hover {
    background-color: ${theme.colors.lighterBlue};
    color: ${theme.colors.darkBlue};
    cursor: pointer;
  }
`;

const StyledSelected = styled.div`
  display: flex;
  padding: 10px;
  font-size: 1rem;
  border: none;
  color: ${theme.colors.grey};
  font-weight: ${(props) => (props.bold ? "400" : "200")};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledError = styled.span`
  color: ${theme.colors.red};
  font-size: 0.7rem;
  font-weight: 200;
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
`;

const StyledSimpleBar = styled(SimpleBar)`
  max-height: 8rem;
  margin-right: 5px;
  width: 100%;
  .simplebar-scrollbar:before {
    background: ${theme.colors.grey};
  }
`;

const Dropdown = ({
  name, //for State
  value, //for State
  defaultValue,
  handleDropdown, //handle selected option
  sequenceDropdownHandler, //handle selected option with id
  id,
  handleChange, //handle react-hooks-form value
  movePersonStatusHandler, //handle change workflow status
  personId, //person id for handle change workflow status
  projectPersonId, //project person id for handle change workflow status
  label, //form label
  options, //array of options inside the dropdown
  required, //add * to label
  disabled,
  placeholder,
  children, //use for complex options like workflow´s steps
  formStyle, //add border-bottom blue
  variant, //set arrow color
  small, //set arrow size
  short, // set height to 40px
  bold, // set value font-weight
  key,
  error, // bool
  errorMsg, // content for the error span, needs error=true to show
  asyncReload,
  person,
  currentStage,
  convertOptionResponse, //convert the options into boolean
  tabIndex,
  justifyContent,
  ...props
}) => {
  const node = useRef();

  const workflows = useSelector(getWorkflows);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    value || defaultValue || null
  );

  const onOptionClicked = (value, index) => () => {
    setSelectedOption(value);

    if (workflows.current.data) {
      var currentWorkflowId = workflows.current.data.id;
      var stages = workflows.current.included.filter(
        (obj) =>
          obj.type === "stage" &&
          obj.relationships.workflow.data.id === currentWorkflowId
      );
      var stage = stages.filter((stage) => value === stage.attributes.name);
    }
    if (!convertOptionResponse && handleDropdown) {
      handleDropdown(name, value);
    }
    if (sequenceDropdownHandler) {
      sequenceDropdownHandler(name, value, id);
    }
    if (handleChange) handleChange(value);

    if (movePersonStatusHandler && currentStage) {
      movePersonStatusHandler(
        projectPersonId,
        stage[0]["id"],
        person,
        value,
        currentStage
      );
    }

    //convert option into true or false depending on the index
    if (convertOptionResponse) {
      let newValue = null;
      if (index === 0) {
        newValue = true;
      } else newValue = false;
      handleDropdown(newValue);
    }
    setDropdownOpen(false);
  };

  //close dropdown when click outside
  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setDropdownOpen(false);
  };

  useEffect(() => {
    if (options && options.length > 0) {
      if (!defaultValue) setSelectedOption(value);
    } else {
      setSelectedOption(undefined);
    }
  }, [options]);

  //react-hooks-form get the initial value on edit form
  useEffect(() => {
    if (handleChange) handleChange(selectedOption);
  }, [selectedOption, handleChange]);

  useEffect(() => {
    if (asyncReload) {
      asyncReload();
    }
    if (dropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, [dropdownOpen]);

  return (
    <StyledContainer {...props} ref={node} key={key}>
      {label && (
        <Text
          disabled={disabled || !options || options.lenght === 0}
          fontSize={theme.fontSizes[2]}
          fontFamily={theme.fonts[1]}
          fontWeight={0}
          mb={1}
          pt={2}
        >
          {label}
          {required ? <StyledRequired>*</StyledRequired> : null}
        </Text>
      )}
      <StyledFormContainer
        formStyle={formStyle}
        tabIndex={tabIndex}
        error={error}
        short={short}
        disabled={disabled || !options || options.length === 0}
        justifyContent={justifyContent}
        onClick={() => (disabled === false || disabled === null || disabled === undefined) ? setDropdownOpen(!dropdownOpen) : null}
      >
        {children}
        {placeholder && !selectedOption ? (
          <StyledPlaceholder
            disabled={disabled || !options || options.length === 0}
            error={error}
          >
            {placeholder}
          </StyledPlaceholder>
        ) : null}
        {selectedOption && (
          <StyledSelected bold={bold}>{selectedOption}</StyledSelected>
        )}
        <StyledIcon small={small}>
          {!options || options.length === 0 ? (
            <ArrowDown variant="lightGrey" />
          ) : dropdownOpen === false ? (
            <ArrowDown variant={error ? "red" : variant} />
          ) : (
            <ArrowUp variant={error ? "red" : variant} />
          )}
        </StyledIcon>
      </StyledFormContainer>

      {dropdownOpen && options && !disabled ? (
        <StyledList short={short}>
          <StyledSimpleBar forceVisible="y" autoHide={false}>
            {options.map((opt, index) => (
              <StyledOption
                name={name}
                onClick={onOptionClicked(opt, index)}
                key={index}
                selected={selectedOption === opt}
              >
                {opt}
              </StyledOption>
            ))}
          </StyledSimpleBar>
        </StyledList>
      ) : null}

      <StyledError show={error && errorMsg}>{errorMsg}</StyledError>
    </StyledContainer>
  );
};

export default Dropdown;
