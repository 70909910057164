import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.linkable
        ? `&:hover {
      fill: ${theme.colors.lightRed}; cursor: pointer;}`
        : prop.disabled
        ? "cursor: not-allowed"
        : ""}
  `,

  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const Pause = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <path
        d="M12.5,24.5c-6.6,0-12-5.4-12-12s5.4-12,12-12s12,5.4,12,12S19.1,24.5,12.5,24.5z M12.5,2C6.7,2,2,6.7,2,12.5
	S6.7,23,12.5,23S23,18.3,23,12.5S18.3,2,12.5,2z"
      />
      <rect x="7.4" y="7.4" width="3.7" height="10.1" />
      <rect x="13.8" y="7.4" width="3.7" height="10.1" />
    </StyledSvg>
  );
};

export default Pause;
