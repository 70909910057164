import React, { useRef, useState, useEffect } from "react";
import { array, func } from "prop-types";
import styled from "styled-components";
import theme from "@theme";
import { Link } from "react-router-dom";
import { FeatureFlag } from "react-unleash-flags";
import Config from "../icons/config";

//components
import Flex from "../flex";
import SidebarMainBtn from "../sidebar-main-btn";
import SidebarSubBtn from "../sidebar-sub-btn";

import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.css";

//icons
import Project from "../icons/project";
import People from "../icons/people";
import Workflow from "../icons/workflow";
import Message from "../icons/message";
import Template from "../icons/template";
import Integration from "../icons/integration";
import TalentPool from "../icons/database";
import TopArrow from "../icons/arrowUp";
import BottomArrow from "../icons/arrowDown";
import ArrowRightDoubleSidebar from "../icons/arrowRightDoubleSidebar";
import ArrowLeftDoubleSidebar from "../icons/arrowLeftDoubleSidebar";

//container
import NewWorkspaceModalContainer from "../../../containers/workspaces/NewWorkspaceModalContainer";

const SideBarContent = styled.nav`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  left: 0;
  position: fixed;
  top: 0;
  width: ${props => props.isExpanded ? '200px' : '60px'};
  height: 100%;
  z-index: 11;
  transition: width 0.1s ease-in-out;
  box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.5); /* Adjust shadow for bevel effect */
`;

const StyledCircleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 5px 0px;
`;
const StyledCircle = styled.div`
  border-radius: 50%;
  font-size: 0.8rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8px;
  height: 8px;
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#656565")};
`;

const SubMenuContent = styled.div`
  height: ${(props) => (props.isOpen ? "100%" : "0")};
  width: 100%;
  overflow: hidden;
  background-color: ${theme.colors.grey};
  position: relative;
  transition: 0.8s ease-in-out;
  -webkit-transition: 0.8s ease-in-out;
  -moz-transition: 0.8s ease-in-out;
  -o-transition: 0.8s ease-in-out;
`;

const StyledSimpleBar = styled(SimpleBar)`
  max-height: ${props => props.activeWorkspace ? '65%' : '65%'};
  width: 100%;
  margin-top: 15px;

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`;

const SideBarItem = {
  mdHeight: `50px`,
  mdWidth: `94px`,
};

const SourcedLogo = () => (
  <>
    <img width={"34rem"} src="../../../images/sourcedLogo.svg" />
  </>
);

const SideBarLogo = ({ workspaces, isExpanded, toggleSidebar }) => {
  let logoPath = "/";
  if (workspaces.length > 0) {
    logoPath = workspaces[0].id;
  }

  const handleArrowClick = (event) => {
    event.stopPropagation();
    toggleSidebar();
  };

  return (
    <Flex
      backgroundColor="transparent"
      alignItems="center"
      justifyContent="space-between"
      pointer={true}
      display={{ sm: "none", md: "flex" }}
      marginTop="0.625rem"
      style={{
        width: '100%',
        paddingLeft: isExpanded ? '1.2rem' : '0.825rem',
        paddingRight: isExpanded ? '0.625rem' : '0.825rem',
        transition: 'all 0.3s ease-in-out'
      }}
    >
      <Link to={`/workspaces/${logoPath}/home`} style={{ textDecoration: "none", marginRight: isExpanded ? '0' : '.2rem' }}>
        <SourcedLogo />
      </Link>

      {isExpanded && (
        <span style={{
          margin: '0 0.625rem',
          color: '#515050',
          fontSize: '1rem',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          transition: 'opacity 3s ease-in-out 0.1s', // Start after sidebar begins to expand
          opacity: isExpanded ? 1 : 0,
        }}>
          <strong>TEAM</strong>SOURCED
        </span>
      )}

      {isExpanded ? (
        <button
          onClick={handleArrowClick}
          style={{
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'opacity 0.3s ease-in-out 0.1s',
          }}
        >
          <ArrowLeftDoubleSidebar />
        </button>
      ) : (
        <button
          onClick={handleArrowClick}
          style={{
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <ArrowRightDoubleSidebar />
        </button>
      )}
    </Flex>
  );
};

const SubmenuDividerTop = styled.div`
  width: 100%;
  height: 3px;
  background: linear-gradient(
    180deg,
    rgba(225, 225, 225, 1) 0%, 
    rgba(245, 245, 245, 1) 20%,
    rgba(245, 245, 245, 1) 80%,
    rgba(225, 225, 225, 1) 100%
  );
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
`;

const SubmenuDividerBottom = styled.div`
  width: 100%;
  height: 2px;
  background: linear-gradient(
    180deg,
    rgba(225, 225, 225, 1) 0%, 
    rgba(245, 245, 245, 1) 20%,
    rgba(245, 245, 245, 1) 80%,
    rgba(225, 225, 225, 1) 100%
  );
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.1);
`;

const ArrowContainer = styled.div`
  display: flex;
  height: 15px;
  width: 15px;
  visibility: ${(props) => (props.showButton ? "visible" : "hidden")};
`;

const propTypes = {
  handleNavItemClick: func,
  handleProfileItemClick: func,
  workspaces: array,
};

const SideBarNavigation = ({
  viewport,
  handleTalentPool,
  handleHelpCenter,
  handleWorkspace,
  onWorkspaceClicked,
  workspaces,
  submenuOpen,
  openLink,
  locationPath,
  locationSplit,
  ownWorkspaces,
  guestWorkspaces,
  activeWorkspace,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [topScroll, setTopScroll] = useState(false);
  const [bottomScroll, setBottomScroll] = useState(false);

  const scrollContainer = useRef({});

  const onCheckScroll = () => {
    if (!scrollContainer.current) {
      return;
    }

    const maxHeight = scrollContainer.current.offsetHeight;
    const scrollHeight = scrollContainer.current.scrollHeight;
    const position = scrollContainer.current.scrollTop;
    const maxPosition = scrollContainer.current.scrollTopMax;

    if (scrollHeight <= maxHeight) {
      setTopScroll(false);
      setBottomScroll(false);
    }

    if (scrollHeight > maxHeight && position !== 0) {
      setTopScroll(true);
    }
    if (scrollHeight > maxHeight && position === 0) {
      setTopScroll(false);
      setBottomScroll(true);
    }
    if (
      scrollHeight > maxHeight &&
      position === maxPosition &&
      maxPosition !== 0
    ) {
      setTopScroll(true);
      setBottomScroll(false);
    }
  };

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
    console.log("Toggled: ", !isExpanded);
  };

  useEffect(() => {
    onCheckScroll();
  }, []);

  useEffect(() => {
    onCheckScroll();
  }, [locationPath, submenuOpen, workspaces]);

  return (
    <>
      <SideBarContent
        boxShadow={5}
        width={isExpanded ? '200px' : `${SideBarItem.mdWidth}px`}
        px={{ md: "24px" }}
        background="none"
        isExpanded={isExpanded}
      >

        <Flex
          flexDirection="column"
          backgroundColor="none"
          alignItems={"center"}
          justifyContent={{ sm: "space-around", md: "space-between" }}
          width={{ sm: 1, md: "auto" }}
        >
          <SideBarLogo workspaces={workspaces} isExpanded={isExpanded} toggleSidebar={toggleSidebar} />
        </Flex>
        <Flex
          flexDirection="column"
          height="100%"
          alignItems="center"
          backgroundColor="none"
          marginTop="30px"
          justifyContent="flex-start"
          width={{ sm: 1, md: 1 }}
        >
          <NewWorkspaceModalContainer isExpanded={isExpanded} />
          <SidebarMainBtn
            data-tip="Talent Pool"
            content={"TP"}
            data-place="right"
            active={locationPath === `/talent_pool`}
            variant="circle"
            onClick={handleTalentPool}
            icon={<TalentPool variant="white" />}
            fullText={"Talent Pool"}
            isExpanded={isExpanded}
            showArrowCondition={false}
          />
          <StyledSimpleBar
            viewport={viewport}
            onScroll={onCheckScroll}
            scrollableNodeProps={{ ref: scrollContainer }}
            activeWorkspace={activeWorkspace}
          >
            {ownWorkspaces?.map((workspace, index) => (
              <React.Fragment key={`ow` + index}>
                <SidebarMainBtn
                  active={submenuOpen === workspace.id}
                  data-tip={`Workspace: ${workspace.attributes.name}`}
                  data-place="right"
                  variant="circle"
                  content={workspace.attributes.name.charAt(0) + workspace.attributes.name.charAt(1)}
                  fullText={workspace.attributes.name}
                  onClick={() => {
                    handleWorkspace(workspace);
                    onWorkspaceClicked(workspace.id);
                  }}
                  isExpanded={isExpanded}
                  circleVariant={true} // Keeping initials always in a circle
                />

                <SubMenuContent isOpen={submenuOpen === workspace.id}>
                  {submenuOpen === workspace.id && <SubmenuDividerTop />}
                  {submenuOpen === workspace.id && (
                    <>
                      <SidebarSubBtn
                        data-tip="Projects"
                        tooltipContent="Projects"
                        isExpanded={isExpanded}
                        data-place="right"
                        subitem={
                          locationSplit[2] === `${workspace.id}` &&
                          locationSplit[3] === "project"
                        }
                        active={
                          locationPath ===
                          `/workspaces/${workspace.id}/projects`
                        }
                        icon={<Project variant="white"></Project>}
                        onClick={() =>
                          openLink(`/workspaces/${workspace.id}/projects`)
                        }
                      />

                      <SidebarSubBtn
                        data-tip="People"
                        tooltipContent="People"
                        isExpanded={isExpanded}
                        data-place="right"
                        active={
                          locationPath === `/workspaces/${workspace.id}/people`
                        }
                        icon={<People variant="white"></People>}
                        onClick={() =>
                          openLink(`/workspaces/${workspace.id}/people`)
                        }
                        content={isExpanded ? 'People' : null}
                      />

                      <SidebarSubBtn
                        data-tip="Workflows"
                        tooltipContent="Workflows"
                        isExpanded={isExpanded}
                        data-place="right"
                        active={
                          locationPath ===
                          `/workspaces/${workspace.id}/workflows`
                        }
                        icon={<Workflow variant="white"></Workflow>}
                        onClick={() =>
                          openLink(`/workspaces/${workspace.id}/workflows`)
                        }
                        content={isExpanded ? 'Workflows' : null}
                      />

                      <SidebarSubBtn
                        data-tip="Coming soon"
                        tooltipContent="Coming Soon"
                        isExpanded={isExpanded}
                        data-place="right"
                        icon={<Message variant="lightGrey" disabled></Message>}
                        content={isExpanded ? 'Coming Soon' : null}
                        disabled
                      />
                      <FeatureFlag name="feature_sequence" invert={true}>
                        <SidebarSubBtn
                          data-tip="Coming soon"
                          data-place="right"
                          icon={
                            <Template variant="lightGrey" disabled></Template>
                          }
                          content={isExpanded ? 'Coming Soon' : null}
                          disabled
                        />
                        <SidebarSubBtn
                          data-tip="Coming soon"
                          data-place="right"
                          icon={
                            <Integration
                              variant="lightGrey"
                              disabled
                            ></Integration>
                          }
                          content={isExpanded ? 'Coming Soon' : null}
                          disabled
                        />
                      </FeatureFlag>

                      <FeatureFlag name="feature_sequence"></FeatureFlag>
                      <FeatureFlag name="feature_sequence" invert={true}>
                        <SidebarSubBtn
                          data-tip="Sequences"
                          tooltipContent="Sequences"
                          isExpanded={isExpanded}
                          data-place="right"
                          icon={
                            <Template variant="lightGrey" disabled></Template>
                          }
                          content={isExpanded ? 'Sequences' : null}
                          disabled
                        />
                        <SidebarSubBtn
                          data-tip="Coming soon"
                          data-place="right"
                          icon={
                            <Integration
                              variant="lightGrey"
                              disabled
                            ></Integration>
                          }
                          content={isExpanded ? 'Coming Soon' : null}
                          disabled
                        />
                      </FeatureFlag>
                      <FeatureFlag name="feature_sequence">
                        <SidebarSubBtn
                          data-tip="Sequences"
                          tooltipContent="Sequences"
                          isExpanded={isExpanded}
                          data-place="right"
                          icon={<Template variant="white"></Template>}
                          active={
                            locationPath ===
                            `/workspaces/${workspace.id}/sequences`
                          }
                          onClick={() =>
                            openLink(`/workspaces/${workspace.id}/sequences`)
                          }
                          content={isExpanded ? 'Sequences' : null}
                        />
                        <SidebarSubBtn
                          data-tip="Integrations"
                          tooltipContent="Integrations"
                          isExpanded={isExpanded}
                          data-place="right"
                          icon={<Integration variant="white"></Integration>}
                          active={
                            locationPath ===
                            `/workspaces/${workspace.id}/integrations`
                          }
                          onClick={() =>
                            openLink(`/workspaces/${workspace.id}/integrations`)
                          }
                          content={isExpanded ? 'Integrations' : null}
                        />
                      </FeatureFlag>
                    </>
                  )}
                  {submenuOpen === workspace.id && <SubmenuDividerBottom />}
                </SubMenuContent>
              </React.Fragment>
            ))}
            {guestWorkspaces?.length > 0 ? (
              <StyledCircleContainer>
                <StyledCircle />
              </StyledCircleContainer>
            ) : null}

            {guestWorkspaces?.map((workspace, index) => (
              <React.Fragment key={`gu` + index}>
                <SidebarMainBtn
                  active={submenuOpen === workspace.id}
                  data-tip={`Workspace: ${workspace.attributes.name}`}
                  data-place="right"
                  variant="circle"
                  content={workspace.attributes.name.charAt(0) + workspace.attributes.name.charAt(1)}
                  fullText={workspace.attributes.name}
                  onClick={() => {
                    handleWorkspace(workspace);
                    onWorkspaceClicked(workspace.id);
                  }}
                  isExpanded={isExpanded}
                  circleVariant={true} // Keeping initials always in a circle
                />

                <SubMenuContent isOpen={submenuOpen === workspace.id}>
                  {submenuOpen === workspace.id && <SubmenuDividerTop />}
                  {submenuOpen === workspace.id && (
                    <>
                      <SidebarSubBtn
                        data-tip="Projects"
                        tooltipContent="Projects"
                        isExpanded={isExpanded}
                        data-place="right"
                        subitem={
                          locationSplit[2] === `${workspace.id}` &&
                          locationSplit[3] === "project"
                        }
                        active={
                          locationPath ===
                          `/workspaces/${workspace.id}/projects`
                        }
                        icon={<Project variant="white"></Project>}
                        onClick={() =>
                          openLink(`/workspaces/${workspace.id}/projects`)
                        }
                        content={isExpanded ? 'Projects' : null}
                      />

                      <SidebarSubBtn
                        data-tip="People"
                        tooltipContent="People"
                        isExpanded={isExpanded}
                        data-place="right"
                        active={
                          locationPath ===
                          `/workspaces/${workspace.id}/people`
                        }
                        icon={<People variant="white"></People>}
                        onClick={() =>
                          openLink(`/workspaces/${workspace.id}/people`)
                        }
                        content={isExpanded ? 'People' : null}
                      />

                      <SidebarSubBtn
                        data-tip="Workflows"
                        tooltipContent="Workflows"
                        isExpanded={isExpanded}
                        data-place="right"
                        active={
                          locationPath ===
                          `/workspaces/${workspace.id}/workflows`
                        }
                        icon={<Workflow variant="white"></Workflow>}
                        onClick={() =>
                          openLink(`/workspaces/${workspace.id}/workflows`)
                        }
                        content={isExpanded ? 'Workflows' : null}
                      />

                      <SidebarSubBtn
                        data-tip="Coming soon"
                        tooltipContent="Coming Soon"
                        isExpanded={isExpanded}
                        data-place="right"
                        icon={<Message variant="lightGrey" disabled></Message>}
                        content={isExpanded ? 'Coming Soon' : null}
                        disabled
                      />

                      <SidebarSubBtn
                        data-tip="Sequences"
                        tooltipContent="Sequences"
                        isExpanded={isExpanded}
                        data-place="right"
                        icon={<Template variant="white"></Template>}
                        active={
                          locationPath ===
                          `/workspaces/${workspace.id}/sequences`
                        }
                        onClick={() =>
                          openLink(`/workspaces/${workspace.id}/sequences`)
                        }
                        content={isExpanded ? 'Sequences' : null}
                      />

                      <SidebarSubBtn
                        data-tip="Integrations"
                        tooltipContent="Integrations"
                        isExpanded={isExpanded}
                        data-place="right"
                        icon={<Integration variant="white"></Integration>}
                        active={
                          locationPath ===
                          `/workspaces/${workspace.id}/integrations`
                        }
                        onClick={() =>
                          openLink(`/workspaces/${workspace.id}/integrations`)
                        }
                        content={isExpanded ? 'Integrations' : null}
                      />
                    </>
                  )}
                  {submenuOpen === workspace.id && <SubmenuDividerBottom />}
                </SubMenuContent>
              </React.Fragment>
            ))}
          </StyledSimpleBar>
          <Flex
            position="sticky"
            bottom="0"
            flexDirection="column"
            alignItems={isExpanded ? "flex-start" : "center"}
            justifyContent="left"
            style={{ marginTop: 'auto', width: '100%' }}
          >
            <SidebarMainBtn
              active={locationPath === `/account_settings`}
              variant="grey"
              onClick={() => openLink(`/account_settings`)}
              icon={<Config variant="grey" />}
              fullText={"Account Settings"}
              isExpanded={isExpanded}
              showArrowCondition={false}
              customTextColor={theme.colors.grey}
              noEllipsis={true}
              isAccountSettings={true}
            // style={{ marginBottom: '10px', justifyContent: 'left' }}
            />
          </Flex>
          {/* <ArrowContainer showButton={topScroll}>
            <TopArrow variant="lightGrey" />
          </ArrowContainer>
          <ArrowContainer showButton={bottomScroll}>
            <BottomArrow variant="lightGrey" />
          </ArrowContainer> */}
          {/*           <SidebarMainBtn
            active={locationPath === `/help_center`}
            variant="blue"
            onClick={handleHelpCenter}
            icon={<Help variant="white" />}
          /> */}
        </Flex>
      </SideBarContent>
      {/* ------ sidebar main content ------ */}
    </>
  );
};

SideBarNavigation.propTypes = propTypes;

export default SideBarNavigation;
