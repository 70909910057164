import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html, body   {
    height: 100%;
    background: #F2F2F2;
    /* min-width: 1280px; */
    overflow: auto;
  }

  main, #root {
    height: 100%;
  }

  body {
    font-family: 'Rubik', 'Open Sans', Helvetica, Arial, sans-serif;
    margin: 0;
    overflow-x: hidden;
    text-rendering: optimizeLegibility;
    height: 100%;
  }
`;

export default GlobalStyle;
