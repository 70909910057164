export const user2Chars = (user) => {
  if (!user?.first_name) {
    if (user?.email) {
      return user.email.charAt(0);
    } else {
      return ' ';
    }
  }

  let content = user.first_name.charAt(0)
  if (user.last_name)
    content = content + user.last_name.charAt(0);
  return content;
}

export const user2Title = (user) => {
  if (!user?.first_name) {
    if (user?.email) {
      return user.email;
    } else {
      return ' ';
    }
  }

  let title = user.first_name
  if (user.last_name)
    title = title + " " + user.last_name;
  return title;
}