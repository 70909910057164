import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.linkable
        ? `&:hover {
      filter: drop-shadow(-1px 1px 1px #4BAAC8); cursor: pointer;}`
        : ""}

    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black);" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const Person = ({ variant, ...props }) => {
    return (
      <StyledSvg {...props} width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" variant={variant}>
        <mask id="mask0_1_45750" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="12" width="16" height="8">
          <path fillRule="evenodd" clipRule="evenodd" d="M0 12.4961H15.8399V19.8701H0V12.4961Z" fill="white"/>
        </mask>
        <g mask="url(#mask0_1_45750)">
          <path fillRule="evenodd" clipRule="evenodd" d="M7.921 13.9961C3.66 13.9961 1.5 14.7281 1.5 16.1731C1.5 17.6311 3.66 18.3701 7.921 18.3701C12.181 18.3701 14.34 17.6381 14.34 16.1931C14.34 14.7351 12.181 13.9961 7.921 13.9961ZM7.921 19.8701C5.962 19.8701 0 19.8701 0 16.1731C0 12.8771 4.521 12.4961 7.921 12.4961C9.88 12.4961 15.84 12.4961 15.84 16.1931C15.84 19.4891 11.32 19.8701 7.921 19.8701Z" fill="#363636"/>
        </g>
        <mask id="mask1_1_45750" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="2" y="0" width="12" height="11">
          <path fillRule="evenodd" clipRule="evenodd" d="M2.60986 0.00012207H13.2299V10.6187H2.60986V0.00012207Z" fill="white"/>
        </mask>
        <g mask="url(#mask1_1_45750)">
          <path fillRule="evenodd" clipRule="evenodd" d="M7.92089 1.42769C5.77989 1.42769 4.03789 3.16869 4.03789 5.30969C4.03089 7.4437 5.75989 9.18369 7.89189 9.19169L7.92089 9.90569V9.19169C10.0609 9.19169 11.8019 7.44969 11.8019 5.30969C11.8019 3.16869 10.0609 1.42769 7.92089 1.42769ZM7.92089 10.6187H7.8889C4.9669 10.6097 2.59989 8.2267 2.60989 5.30669C2.60989 2.3817 4.99189 -0.000305176 7.92089 -0.000305176C10.8489 -0.000305176 13.2299 2.38169 13.2299 5.30969C13.2299 8.23769 10.8489 10.6187 7.92089 10.6187Z" fill="#363636"/>
        </g>
      </StyledSvg>
    );
  };
  
  export default Person;
