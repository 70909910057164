import React from "react";
import styled from "styled-components";
import theme from "@theme";
import { Controller } from "react-hook-form";

import DropdownWithId from "../dropdown-with-id/";
import Dropdown from "../dropdown/";
import Input from "../input";
import Box from "../box";
import Toggle from "@ui/toggle-switch";

// Wysiwyg editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const StyledCard = styled.div`
  display: flex;
  background-color: white;
  justify-content: space-between;
  flex-direction: row;
  flex-flow: wrap;
  width: 100%;
  box-shadow: 0px 0px 6px 2px #8e8e8e60;
  margin: 0px;
`;

const CardContent = styled.div`
  width: 100%;
  margin: 15px;
  height: auto;
  display: flex;
  flex-flow: wrap;
  flex-direction: column;
  justify-content: space-between;
`;

const CardRow = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  width: 100%;
`;

const LabelRow = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  padding-left: 10px;
`;

const StyledLabelCredential = styled.div`
  display: flex;
  color: ${theme.colors.darkBlue};
  font-weight: 200;
  font-size: 0.8rem;
  margin-right: 5px;
`;

const CardHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;
const CardBody = styled.div`
  width: 100%;
  height: 320px;
  padding: 20px;
  display: flex;
  justify-content: center;
  border: ${(props) =>
    props.error
      ? `1px solid ${theme.colors.red}`
      : `1px solid ${theme.colors.darkBlue}`};
  border-radius: 3px;
`;

const CardFooter = styled.div`
  width: 100%;
  align-items: center;
  padding: 0px 10px;
  display: flex;
  float: end;
  justify-content: end;
`;

const CardInputContainer = styled.div`
  width: ${(props) => (props.width ? props.width : "auto")};
  display: flex;
  align-items: center;
  padding: 0px 10px;
`;
const CardInputLabel = styled.div`
  display: flex;
  font-family: ${theme.fonts[1]};
  font-size: 0.9rem;
  font-weight: 100;
  padding: ${(props) => (props.padding ? props.padding : "0 20px 0 0 ")};
  text-align: right;
  color: ${theme.colors.grey};
`;

const StyledLinks = styled.a`
  text-align: center;
  min-width: 50px;
  font-size: 0.7rem;
  text-decoration: none;
  color: ${theme.colors.darkBlue};
  border-bottom: 1px solid ${theme.colors.darkBlue};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "0px")};
`;

const StyledRequired = styled.span`
  color: ${theme.colors.red};
  font-weight: 200;
  font-size: 1rem;
  padding-left: 4px;
`;

const CardTemplate = ({
  step,
  dirtyChanges,
  handleDropdownSelect,
  handleInputChange,
  indexStep,
  editorStates,
  handleEditorChange,
  onInputFocus,
  onSubjectBlur,
  integrationsProviders,
  integrationsCredencials,
  workspaceId,
  register,
  errors,
  setValue,
  control,
  clearErrors,
  asyncReload,
  credentialAliases,
  checkSubjectValidation,
  checkBodyValidation,
  setHasChanges,
  isEdit,
  handleHasProviderSignature,
}) => {
  return (
    <StyledCard>
      <CardContent>
        <CardHeader>
          <CardRow>
            <CardInputContainer width="40%">
              <CardInputLabel>
                Provider
                <StyledRequired>*</StyledRequired>
              </CardInputLabel>
              <Box width="100%">
                <DropdownWithId
                  small
                  short
                  name={`steps.${indexStep}.provider_type`}
                  {...register(`steps.${indexStep}.provider_type`, {
                    required: true,
                  })}
                  required
                  error={errors["steps"]?.[indexStep]?.["provider_type"]}
                  errorMsg="This field is required"
                  options={integrationsProviders}
                  value={dirtyChanges.steps?.[indexStep].provider_type}
                  defaultValue={step.attributes?.provider_type}
                  handleDropdown={(name, value) => {
                    handleDropdownSelect(name, value, indexStep);
                    clearErrors(`steps.${indexStep}.provider_type`);
                    setHasChanges && setHasChanges(true);
                  }}
                  handleChange={(val) => {
                    setValue(`steps.${indexStep}.provider_type`, val);
                  }}
                  formStyle
                  variant="blue"
                  tabIndex="2"
                  placeholder="Select Credential Provider"
                />
              </Box>
            </CardInputContainer>
            <CardInputContainer width="60%">
              <CardInputLabel>
                Credential
                <StyledRequired>*</StyledRequired>
              </CardInputLabel>
              <Box width="100%">
                <DropdownWithId
                  asyncReload={asyncReload}
                  small
                  short
                  name={`steps.${indexStep}.provider_id`}
                  {...register(`steps.${indexStep}.provider_id`, {
                    required: true,
                  })}
                  error={errors["steps"]?.[indexStep]?.["provider_id"]}
                  errorMsg="This field is required"
                  value={dirtyChanges.steps?.[indexStep].provider_id}
                  defaultValue={dirtyChanges.steps?.[indexStep].provider_type ? dirtyChanges.steps?.[indexStep].provider_id : step.attributes?.provider_id}
                  handleDropdown={(name, value) => {
                    handleDropdownSelect(name, value, indexStep);
                    clearErrors(`steps.${indexStep}.provider_id`);
                    setHasChanges && setHasChanges(true);
                  }}
                  handleChange={(val) => {
                    setValue(`steps.${indexStep}.provider_id`, val);
                  }}
                  formStyle
                  variant="blue"
                  options={integrationsCredencials}
                  tabIndex="3"
                  placeholder="Select Credentials"
                />
              </Box>
            </CardInputContainer>
          </CardRow>

          <CardRow>
            <CardInputContainer width="40%">
              <CardInputLabel>
                From
                <StyledRequired>*</StyledRequired>
              </CardInputLabel>
              <Box width="100%">
                <Dropdown
                  asyncReload={asyncReload}
                  small
                  short
                  display={"grid"}
                  name={`steps.${indexStep}.from`}
                  {...register(`steps.${indexStep}.from`, {
                    required: true,
                  })}
                  error={errors["steps"]?.[indexStep]?.["from"]}
                  errorMsg="This field is required"
                  value={dirtyChanges.steps?.[indexStep].from}
                  defaultValue={dirtyChanges.steps?.[indexStep].provider_type ? dirtyChanges.steps?.[indexStep].from : step.attributes?.from}
                  handleDropdown={(name, value) => {
                    handleDropdownSelect(name, value, indexStep);
                    clearErrors(`steps.${indexStep}.from`);
                    setHasChanges && setHasChanges(true);
                  }}
                  handleChange={(val) => {
                    setValue(`steps.${indexStep}.from`, val);
                  }}
                  formStyle
                  variant="blue"
                  options={credentialAliases()}
                  placeholder="Select email"
                  tabIndex="4"
                />
              </Box>
            </CardInputContainer>

            <CardInputContainer width="60%">
              <CardInputLabel>
                Subject <StyledRequired>*</StyledRequired>
              </CardInputLabel>

              <Box width="100%">
                <Input
                  name={`steps.${indexStep}.template_subject`}
                  {...register(`steps.${indexStep}.template_subject`, {
                    required: true,
                  })}
                  short
                  mt={2}
                  tabIndex="5"
                  placeholder="Enter mail subject"
                  value={dirtyChanges.steps?.[indexStep].template_subject}
                  defaultValue={step.attributes?.template_subject}
                  onChange={(e) => {
                    handleInputChange(e, indexStep);
                    checkSubjectValidation(e.target.value);
                    setHasChanges && setHasChanges(true);
                  }}
                  error={errors["steps"]?.[indexStep]?.["template_subject"]}
                  errorMsg="This field is required"
                  onFocus={(event) => {
                    onInputFocus("template_subject");
                    if (isEdit) handleInputChange(event, indexStep);
                  }}
                  onBlur={(e) => onSubjectBlur(e)}
                />
              </Box>
            </CardInputContainer>

          </CardRow>

          <LabelRow>
            {integrationsProviders.length === 0 ? (
              <>
                <StyledLabelCredential>
                  There are no Credentials associated to this Workspace, please
                  add a
                </StyledLabelCredential>
                <StyledLinks
                  href={`/workspaces/${workspaceId}/integrations`}
                  target="_blank"
                >
                  New Credential
                </StyledLinks>
              </>
            ) : (
              <StyledLinks
                href={`/workspaces/${workspaceId}/integrations`}
                target="_blank"
                marginLeft="5rem"
              >
                Add New Credentials
              </StyledLinks>
            )}
          </LabelRow>

        </CardHeader>

        <CardBody error={errors["steps"]?.[indexStep]?.["template_body"]}>
          <Controller
            name={`steps.${indexStep}.template_body`}
            defaultValue={dirtyChanges.steps?.[indexStep].template_body}
            control={control}
            shouldUnregister={false}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <Editor
                toolbar={{
                  options: ["inline", "fontSize", "fontFamily", "list", "link"],
                  inline: {
                    options: ["bold", "italic", "underline"],
                  },
                  list: {
                    options: ["unordered", "ordered"],
                  },
                }}
                editorStyle={{
                  width: "100%",
                  color: "#000000",
                  maxHeight: "80%",
                  fontSize: "12px",
                }}
                wrapperStyle={{ maxHeight: "100%", width: "95%" }}
                editorState={editorStates[indexStep]}
                stripPastedStyles={true}
                onChange={(el) => {
                  onChange(el.blocks[0].text);
                  checkBodyValidation(el.blocks[0].text);
                }}
                onEditorStateChange={(state) => {
                  handleEditorChange(state, indexStep);
                }}
                onFocus={() => onInputFocus("editor")}
              />
            )}
          />
        </CardBody>

        <CardFooter>
          <CardInputLabel padding="0">Use email account signature?</CardInputLabel>
          <Toggle
            customeLabel=" "
            name={`steps.${indexStep}.has_provider_signature`}
            initialValue={step.attributes?.has_provider_signature || dirtyChanges.steps?.[indexStep].has_provider_signature}
            handleChange={(val, obj, name) => { handleHasProviderSignature(name, val, indexStep) }}
            tabIndex="5"
          />
        </CardFooter>

      </CardContent>
    </StyledCard>
  );
};

export default CardTemplate;
