import { forkJoin, of } from "rxjs";
import { combineEpics, ofType } from "redux-observable";
import { catchError, concatMap, mergeMap, map, mapTo } from "rxjs/operators";
import { lensPath, view } from "ramda";

import { userValidateFetch } from "../epic/auth";
import { workspacesFetch } from "../epic/workspaces";
import { invitationsFetch } from "../epic/invitations";

import {
  APP_MOUNT,
  ON_WORKSPACES_LOADED,
  ON_INVITATIONS_LOADED,
  WORKSPACES_GET,
  APP_WORKSPACES_LOAD,
  appWorkspacesLoad,
  appInvitationsLoad,
  appDataSuccess,
  appDataFailure,
  onAppStart,
  appWorkflowsFilterSuccess,
  APP_WORKFLOWS_FILTER_UPDATE,
  appUnmountWorkflowsFilterSuccess,
  APP_UNMOUNT_WORKFLOWS_FILTER,
} from "../actions";

const dataLens = lensPath(["data", "data"]);

const mountAppEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(APP_MOUNT),
    mergeMap(() =>
      forkJoin({
        user: userValidateFetch(api),
        workspaces: workspacesFetch(api),
        invitations: invitationsFetch(api),
      }).pipe(
        map(({ user, workspaces, invitations }) => ({
          user: view(dataLens, user),
          workspaces: view(dataLens, workspaces),
          notifications: view(dataLens, invitations),
        })),
        concatMap((payload) =>
          of(appDataSuccess(payload), onAppStart(payload))
        ),
        catchError((err) => of(appDataFailure({ error: err.message })))
      )
    )
  );

const workspacesLoaded = (action$, state$, { api }) =>
  action$.pipe(
    ofType(ON_WORKSPACES_LOADED),
    map(({payload}) => appWorkspacesLoad(payload))
  );

const invitationsLoaded = (action$, state$, { api }) =>
  action$.pipe(
    ofType(ON_INVITATIONS_LOADED),
    map(({payload}) => appInvitationsLoad(payload))
  );

const workflowsFilterUpdateEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(APP_WORKFLOWS_FILTER_UPDATE),
    map(({payload}) => appWorkflowsFilterSuccess(payload.assigned_to))
  );

const unmountWorkflowsFilterEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(APP_UNMOUNT_WORKFLOWS_FILTER),
    map(({payload}) => appUnmountWorkflowsFilterSuccess())
  );

export default combineEpics(
  mountAppEpic,
  workspacesLoaded,
  invitationsLoaded,
  workflowsFilterUpdateEpic,
  unmountWorkflowsFilterEpic,
  );
