import React from "react";
import theme from "../../../theme";
import { Link } from "react-router-dom";
import Box from "../../ui/box";
import Card from "../../ui/card";
import Image from "../../../assets/img/workflow.svg";
import Flex from "../../ui/flex";
import Dropdown from "../../ui/dropdown/index";
import Button from "../../ui/button/index";

const WorkflowIntro = ({
  dirtyChanges,
  handleDropdownSelect,
  workflows,
  workspace,
  handleSubmitClick,
  viewport,
}) => {
  return (
    <Flex
      alignItems={"center"}
      minHeight={"100vh"}
      width={"95%"}
      flexDirection={"column"}
      justifyContent={"flex-start"}
      px={{ sm: 2, md: 0 }}
      overflow="hidden"
      backgroundColor={theme.colors.lighterGrey}
    >
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        width={viewport > 2000 ? "85%" : "95%"}
        mt={"30px"}
        backgroundColor={theme.colors.lighterGrey}
      >
        <Card
          imageBlock={Image}
          textBlock={
            <Flex flexDirection="column">
              <Box>
                <p
                  style={{
                    fontSize: "1.1rem",
                    fontWeight: "300",
                    lineHeight: "1.5rem",
                  }}
                >
                  Workflow helps you organize people within a project and also
                  keep track of progress.
                </p>
                <p
                  style={{
                    fontSize: "1.1rem",
                    fontWeight: "300",
                    lineHeight: "1.5rem",
                  }}
                >
                  You can assign to this project any workflow already created
                  within this workspace
                </p>
              </Box>
              <Flex width="100%">
                <Dropdown
                  name="workflow"
                  value={dirtyChanges?.workflow}
                  handleDropdown={handleDropdownSelect}
                  formStyle
                  variant="blue"
                  options={workflows?.map(
                    (workflow) => workflow.attributes.name
                  )}
                  width={"90%"}
                  mb={4}
                  placeholder="Select Workflow"
                />
                <Button
                  key="continue"
                  variant="secondaryLight"
                  content="Continue"
                  onClick={handleSubmitClick}
                />
              </Flex>
              <Box>
                <p
                  style={{
                    fontSize: "1rem",
                    fontWeight: "300",
                    lineHeight: "1.5rem",
                  }}
                >
                  Or create a new one in
                  <Link
                    to={`/workspaces/${workspace}/workflows`}
                    style={{
                      fontSize: "1rem",
                      textDecoration: "none",
                      color: theme.colors.lightRed,
                      borderBottom: `1px solid ${theme.colors.lightRed}`,
                      marginLeft: "10px",
                    }}
                  >
                    Workflow
                  </Link>
                </p>
              </Box>
            </Flex>
          }
        ></Card>
      </Flex>
    </Flex>
  );
};

export default WorkflowIntro;
