import React, { useEffect } from "react";
import theme from "@theme";
import styled from "styled-components";

import Box from "@ui/box";
import Button from "@ui/button";
import CardTemplate from "@ui/cardTemplate";
import Flex from "@ui/flex";
import Input from "@ui/input";
import InputIntegrated from "@ui/input-integrated";
import Tag from "@ui/tag";
import Text from "@ui/text";
import TimePicker from "@ui/input-timepicker";
import Dropdown from "@ui/dropdown";

//Icons
import Add from "@ui/icons/add";
import { useIntegrations } from "@hooks";

const TagContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: wrap;
  align-items: center;
  margin-bottom: 15px;
`;

const StyledContainer = styled(Flex)`
  position: relative;
  width: auto;
  flex-direction: column;
  display: -webkit-box;
`;

const TextLabel = styled(Text)`
  font-size: ${theme.fontSizes[2]}px;
  font-family: ${theme.fonts[1]};
  font-weight: 100;
  margin-bottom: 4px;
`;

const StepForm = ({
  step,
  indexStep,
  integrations,
  workspaceId,
  dirtyChanges,
  editorStates,
  register,
  errors,
  setValue,
  getValues,
  setError,
  control,
  clearErrors,
  handleInputChange,
  handleEditorChange,
  handleInputName,
  handleDropdown,
  handleInputFocus,
  handleInsertField,
  handleSubmitNewVariable,
  customFieldsNames,
  isEdit,
  handleSubjectBlur,
  setHasChanges,
  handleHasProviderSignature,
  handleRemoveField,
  candidateVariables,
  projectVariables,
}) => {
  const {
    asyncReloadIntegrations,
    integrationsCredencials,
    integrationsProviders,
    searchCredencialAliases
  } = useIntegrations();

  const workdayValues = ["12am", "1am", "2am", "3am", "4am", "5am", "6am", "7am", "8am", "9am", "10am", '11am', "12pm", "1pm", "2pm", "3pm", "4pm", "5pm", "6pm", "7pm", '8pm', "9pm", "10pm", "11pm", "--"];
  const workdayEndValues = workdayValues.slice(0, workdayValues.indexOf(step?.attributes?.workday_end || dirtyChanges.steps?.[indexStep].workday_end || "8pm"))
  const workdayStartValues = workdayValues.slice(workdayValues.indexOf(step?.attributes?.workday_start || dirtyChanges.steps?.[indexStep].workday_start || "8am") + 1)

  const handleCheckInputName = (index) => {
    let name = `steps.${index}.name`;
    let value = `Step ${index + 1}`;
    if (!dirtyChanges.steps?.[index].name && !isEdit) {
      handleInputName(value, name, index);
    }
  };

  const handleDelay = (value, index) => {
    const name = `steps.${index}.delay`;
    handleInputName(value, name, index);
  };

  const checkSubjectValidation = (value) => {
    if (value === undefined || value.length === 0) {
      setError(`steps.${indexStep}.template_subject`);
      setValue(`steps.${indexStep}.template_subject`, null);
    } else {
      setValue(`steps.${indexStep}.template_subject`, value);
      clearErrors(`steps.${indexStep}.template_subject`);
    }
  };

  const checkBodyValidation = (value) => {
    if (value === undefined || value.length === 0 || value === "<p></p>\n") {
      setError(`steps.${indexStep}.template_body`);
      setValue(`steps.${indexStep}.template_body`, null);
    } else {
      setValue(`steps.${indexStep}.template_body`, value);
      clearErrors(`steps.${indexStep}.template_body`);
    }
  };

  const checkProviderValidation = (value) => {
    if (value === undefined || value === null || value.length === 0) {
      setError(`steps.${indexStep}.provider_type`);
      setValue(`steps.${indexStep}.provider_type`, null);
    } else {
      setValue(`steps.${indexStep}.provider_type`, value);
      clearErrors(`steps.${indexStep}.provider_type`);
    }
  };

  const checkCredentialValidation = (value) => {
    if (value === undefined || value === null || value.length === 0) {
      setError(`steps.${indexStep}.provider_id`);
      setValue(`steps.${indexStep}.provider_id`, null);
    } else {
      setValue(`steps.${indexStep}.provider_id`, value);
      clearErrors(`steps.${indexStep}.provider_id`);
    }
  };

  const credentialAliases = () => {
    let providerId = dirtyChanges.steps?.[indexStep].provider_id;

    if (providerId === undefined && step.attributes?.provider_id)
      providerId = step.attributes?.provider_id;

    return searchCredencialAliases(providerId);
  };

  useEffect(() => {
    if (isEdit && !dirtyChanges.steps[indexStep].template_body) {
      checkBodyValidation(step.attributes.template_body);
    }

    if (isEdit && !dirtyChanges.steps[indexStep].template_subject) {
      checkSubjectValidation(step.attributes.template_subject);
    }

    if (isEdit && !dirtyChanges.steps[indexStep].provider_type) {
      checkProviderValidation(step.attributes.provider_type);
    }

    if (isEdit && !dirtyChanges.steps[indexStep].provider_id) {
      checkCredentialValidation(step.attributes.provider_id);
    }
  }, []);

  //validate changes on subject, body, provider and credential
  useEffect(() => {
    if (dirtyChanges.steps[indexStep].provider_type) {
      checkProviderValidation(dirtyChanges.steps[indexStep].provider_type);
    }
    if (dirtyChanges.steps[indexStep].provider_id) {
      checkCredentialValidation(dirtyChanges.steps[indexStep].provider_id);
    }
    if (dirtyChanges.steps[indexStep].template_subject) {
      checkSubjectValidation(dirtyChanges.steps[indexStep].template_subject);
    }
    if (dirtyChanges.steps[indexStep].template_body) {
      checkBodyValidation(dirtyChanges.steps[indexStep].template_body);
    }
  }, [dirtyChanges]);

  return (
    <Flex key={indexStep} justifyContent="space-evenly" width="100%">
      <Box width="30%">
        <Input
          label="Step Name"
          width="75%"
          mb={3}
          placeholder={"Enter step name"}
          name={`steps.${indexStep}.name`}
          value={dirtyChanges?.steps?.[indexStep].name}
          defaultValue={step?.attributes?.name}
          onChange={(e) => handleInputChange(e, indexStep)}
          onFocus={() => handleInputFocus(null)}
          onBlur={() => handleCheckInputName(indexStep)}
          tabIndex="1"
          autoFocus
        />
        <TimePicker
          label={
            indexStep === 0 ? "Step Delay" : "Step Delay from Previous Step"
          }
          labelIntro="Send after"
          indexStep={indexStep}
          value={dirtyChanges?.steps?.[indexStep].delay}
          defaultValue={step.attributes?.delay}
          onChange={handleDelay}
          mb={3}
          tabIndex="2"
        />

        <TextLabel mb={4}>
          Send email between
        </TextLabel>
        <StyledContainer short small mb={3}>
          <Text margin="auto 10px" color={theme.colors.darkBlue}>
            Start at
          </Text>
          <Dropdown
            small
            short
            width="30%"
            name={`steps.${indexStep}.workday_start`}
            indexStep={indexStep}
            value={dirtyChanges.steps?.[indexStep].workday_start}
            defaultValue={step?.attributes?.workday_start}
            handleDropdown={(name, value) => {
              handleDropdown(name, value, indexStep);
              setHasChanges && setHasChanges(true);
            }}
            formStyle
            variant="blue"
            options={workdayEndValues}
            tabIndex="3"
          />
          <Text margin="auto 10px" color={theme.colors.darkBlue}>
            Pause at
          </Text>
          <Dropdown
            formStyle
            small
            short
            width="30%"
            name={`steps.${indexStep}.workday_end`}
            indexStep={indexStep}
            value={dirtyChanges.steps?.[indexStep].workday_end}
            defaultValue={step?.attributes?.workday_end}
            handleDropdown={(name, value) => {
              handleDropdown(name, value, indexStep);
              setHasChanges && setHasChanges(true);
            }}
            variant="blue"
            options={workdayStartValues}
            tabIndex="4"
          />
        </StyledContainer>

        <TextLabel mb={1} pt={2}>
          Candidate Variables
        </TextLabel>
        <TagContainer>
          {candidateVariables.map((variable, indexCandidateVariable) => (
            <Tag
              key={indexCandidateVariable}
              content={variable}
              onClick={() => {
                handleInsertField(variable, indexStep);
                setHasChanges && setHasChanges(true);
              }}
            />
          ))}
        </TagContainer>
        <TextLabel mb={1} pt={2}>
          Project Variables
        </TextLabel>
        <TagContainer>
          {projectVariables.map((variable, indexProjectVariable) => (
            <Tag
              key={indexProjectVariable}
              content={variable}
              onClick={() => {
                handleInsertField(variable, indexStep);
                setHasChanges && setHasChanges(true);
              }}
            />
          ))}
        </TagContainer>
        <InputIntegrated
          short
          small
          mb={10}
          label="Custom Variables"
          placeholderFirst="Enter custom variable name"
          placeholderSecond="Select Type"
          nameFirst="customFieldsName"
          nameSecond="customFieldsType"
          options={["Text", "Text area"]}
          dirtyChanges={dirtyChanges}
          onChange={(event, index) => {
            event.target.value = event.target.value.replace(/ /g, "_").trim();
            handleInputChange(event, index);
          }}
          handleDropdown={handleDropdown}
          indexStep={indexStep}
          button={
            <Button
              variant={
                dirtyChanges?.customFieldsName && dirtyChanges?.customFieldsType
                  ? "secondaryLight"
                  : "disabled"
              }
              content="Add"
              margin="5px 3px"
              icon={
                <Add
                  variant={
                    dirtyChanges?.customFieldsName &&
                      dirtyChanges?.customFieldsType
                      ? "blue"
                      : "lighterGrey"
                  }
                />
              }
              size="small"
              onClick={() => handleSubmitNewVariable(indexStep)}
              disabled={
                !dirtyChanges.customFieldsName || !dirtyChanges.customFieldsType
              }
            ></Button>
          }
        />
        <TagContainer>
          {customFieldsNames().map((variable, indexCustomField) => (
            <Tag
              key={indexCustomField}
              content={variable}
              remove={true}
              onRemove={() => {
                handleRemoveField(variable, indexStep);
              }}
              onClick={() => {
                handleInsertField(variable, indexStep);
              }}
            />
          ))}
        </TagContainer>
      </Box>
      <Box width="60%">
        <CardTemplate
          step={step}
          dirtyChanges={dirtyChanges}
          editorStates={editorStates}
          handleInputChange={handleInputChange}
          handleDropdownSelect={handleDropdown}
          handleEditorChange={handleEditorChange}
          indexStep={indexStep}
          onInputFocus={handleInputFocus}
          onSubjectBlur={handleSubjectBlur}
          integrations={integrations}
          integrationsCredencials={integrationsCredencials(
            dirtyChanges.steps?.[indexStep]?.provider_type ||
            step.attributes?.provider_type
          )}
          integrationsProviders={integrationsProviders()}
          workspaceId={workspaceId}
          register={register}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          setError={setError}
          control={control}
          clearErrors={clearErrors}
          asyncReload={() => asyncReloadIntegrations(workspaceId)}
          credentialAliases={credentialAliases}
          checkSubjectValidation={checkSubjectValidation}
          checkBodyValidation={checkBodyValidation}
          setHasChanges={setHasChanges}
          isEdit={isEdit}
          handleHasProviderSignature={handleHasProviderSignature}
        />
      </Box>
    </Flex>
  );
};

export default StepForm;
