import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`

    ${(prop) =>
      prop.landing
        ? `&:hover {
        fill: ${theme.colors.lightBlue}; cursor: pointer;}`
        : ""}
    ${(prop) =>
      prop.linkable
        ? `&:hover {
      fill: ${theme.colors.lightRed}; cursor: pointer;}`
        : ""}
    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black)" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const Linkedin = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <path
        d="M12.5,24.5c-6.6,0-12-5.4-12-12s5.4-12,12-12s12,5.4,12,12S19.1,24.5,12.5,24.5z M12.5,2C6.7,2,2,6.7,2,12.5
	S6.7,23,12.5,23S23,18.3,23,12.5S18.3,2,12.5,2z"
      />
      <path
        d="M10.6,10.5H8.7V13h1.9v7.5h3.1V13H16l0.2-2.5h-2.5v-1c0-0.6,0.1-0.8,0.7-0.8h1.8V5.5h-2.4c-2.2,0-3.2,1-3.2,2.9
	V10.5z"
      />
    </StyledSvg>
  );
};

export default Linkedin;
