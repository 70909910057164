import { combineEpics, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, map, flatMap, merge, mapTo, mergeAll} from "rxjs/operators";
import { push } from "connected-react-router";
import { toast } from "react-toastify";

import {
  SIGN_IN_SET,
  signInSetSuccess,
  signInSetFailure,
  SIGN_UP_SET,
  signUpSetSuccess,
  signUpSetFailure,
  USER_GET,
  userGetSuccess,
  userGetFailure,
  SIGN_OUT_SET,
  signOutSetSuccess,
  signOutSetFailure,
  onExpiredToken,
  PASSWORD_UPDATE,
  passwordUpdateSuccess,
  passwordUpdateFailure,
  PASSWORD_RESET,
  passwordResetSuccess,
  passwordResetFailure,
} from "../actions";

// Requests
export const userValidateFetch = (api) => api.get("/auth/validate_token");
//////////////////////////////////////////////

// Handles actions to trigger on api errors
export const onFailure = (failureAction) =>
  catchError((err) => {
    const actions = [failureAction(err)];

    if (err.response && err.response.status === 401) {
      actions.push(onExpiredToken());
    }

    return from(actions);
  });

const getUserEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(USER_GET),
    flatMap(() => {
      return from(userValidateFetch(api)).pipe(
        map(userGetSuccess),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map(error => toast.error(error));
          return of(userGetFailure({ err, errors }));
        })
      );
    })
  );

const signInEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(SIGN_IN_SET),
    flatMap(({ payload: { email, password, redirectPath } }) => {
      return from(
        api
          .post("/auth/sign_in", {
            email,
            password,
          })
          .then((resp) => {
            localStorage.clear();
            localStorage.setItem("access-token", resp.headers["access-token"]);
            localStorage.setItem("uid", resp.headers.uid);
            localStorage.setItem("client", resp.headers.client);
            localStorage.setItem("expiry", resp.headers.expiry);
            return resp;
          })
      ).pipe(
        map(signInSetSuccess),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map(error => toast.error(error));
          return of(signInSetFailure({ err, errors }));
        })
      );
    })
  );

const signUpEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(SIGN_UP_SET),
    flatMap(({ payload: { email, password, redirectPath } }) => {
      return from(
        api
          .post("/auth", {
            email,
            password,
          })
          .then((resp) => {
            localStorage.clear();
            localStorage.setItem("access-token", resp.headers["access-token"]);
            localStorage.setItem("uid", resp.headers.uid);
            localStorage.setItem("client", resp.headers.client);
            localStorage.setItem("expiry", resp.headers.expiry);
            return resp;
          })
      ).pipe(
        map(signUpSetSuccess),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors[0].full_messages.map(error => toast.error(error));
          return of(signUpSetFailure({ err, errors }));
        })
      );
    })
  );

const updatePasswordEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(PASSWORD_UPDATE),
    flatMap(({ payload: { password, reset_password_token, redirectPath } }) => {
      return from(
        api
          .put("/auth/password", {
            password,
            password_confirmation: password,
            reset_password_token: reset_password_token,
          })
          .then((resp) => {
            localStorage.clear();
            return resp;
          })
      ).pipe(
        map(passwordUpdateSuccess),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.full_messages.map(error => toast.error(error));
          return of(passwordUpdateFailure({ err, errors }));
        })
      );
    })
  );

const resetPasswordEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(PASSWORD_RESET),
    flatMap(({ payload: { email, redirect_url } }) => {
      return from(
        api
          .post("/auth/password", {
            email,
            redirect_url,
          })
          .then((resp) => {
            localStorage.clear();
            return resp;
          })
      ).pipe(
        map(passwordResetSuccess),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map(error => toast.error(error));
          return of(passwordResetFailure({ err, errors }));
        })
      );
    })
  );

const signOutEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(SIGN_OUT_SET),
    flatMap(({ payload: { redirectPath } }) => {
      return from(
        api
        .delete("/auth/sign_out")
        .then((resp) => resp)
      ).pipe(
        mapTo([signOutSetSuccess(), onExpiredToken(), push('/sign_in')]),
        mergeAll(),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map(error => toast.error(error));
          return of(signOutSetFailure({ err, errors }));
        })
      )
    })
  );

export default combineEpics(
  signInEpic,
  signUpEpic,
  signOutEpic,
  getUserEpic,
  updatePasswordEpic,
  resetPasswordEpic
);
