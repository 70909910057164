import { createActions } from "redux-actions";

// new session action
export const NEW_SESSION_ON_LOAD = "NEW_SESSION_ON_LOAD";

// sign in actions
export const USER_GET = "USER_GET";
export const USER_GET_SUCCESS = "USER_GET_SUCCESS";
export const USER_GET_FAILURE = "USER_GET_FAILURE";

export const SIGN_IN_SET = "SIGN_IN_SET";
export const SIGN_IN_SET_SUCCESS = "SIGN_IN_SET_SUCCESS";
export const SIGN_IN_SET_FAILURE = "SIGN_IN_SET_FAILURE";

export const SIGN_UP_SET = "SIGN_UP_SET";
export const SIGN_UP_SET_SUCCESS = "SIGN_UP_SET_SUCCESS";
export const SIGN_UP_SET_FAILURE = "SIGN_UP_SET_FAILURE";

export const SIGN_OUT_SET = "SIGN_OUT_SET";
export const SIGN_OUT_SET_SUCCESS = "SIGN_OUT_SET_SUCCESS";
export const SIGN_OUT_SET_FAILURE = "SIGN_OUT_SET_FAILURE";

export const PASSWORD_UPDATE = "PASSWORD_UPDATE";
export const PASSWORD_UPDATE_SUCCESS = "PASSWORD_UPDATE_SUCCESS";
export const PASSWORD_UPDATE_FAILURE = "PASSWORD_UPDATE_FAILURE";

export const PASSWORD_RESET = "PASSWORD_RESET";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_FAILURE = "PASSWORD_RESET_FAILURE";

export const GET_SIGN_IN_MICROSOFT = "GET_SIGN_IN_MICROSOFT";
export const CALLBACK_SIGN_IN_MICROSOFT = "CALLBACK_SIGN_IN_MICROSOFT";

export const ON_EXPIRED_TOKEN = "ON_EXPIRED_TOKEN";

export const {
  newSessionOnLoad,
  userGet,
  userGetSuccess,
  userGetFailure,
  signInSet,
  signInSetSuccess,
  signInSetFailure,
  signUpSet,
  signUpSetSuccess,
  signUpSetFailure,
  signOutSet,
  signOutSetSuccess,
  signOutSetFailure,
  passwordUpdate,
  passwordUpdateSuccess,
  passwordUpdateFailure,
  passwordReset,
  passwordResetSuccess,
  passwordResetFailure,
  getSignInMicrosoft,
  callbackSignInMicrosoft,
  onExpiredToken,
} = createActions(
  {},
  NEW_SESSION_ON_LOAD,
  USER_GET,
  USER_GET_SUCCESS,
  USER_GET_FAILURE,
  SIGN_IN_SET,
  SIGN_IN_SET_SUCCESS,
  SIGN_IN_SET_FAILURE,
  SIGN_UP_SET,
  SIGN_UP_SET_SUCCESS,
  SIGN_UP_SET_FAILURE,
  SIGN_OUT_SET,
  SIGN_OUT_SET_SUCCESS,
  SIGN_OUT_SET_FAILURE,
  PASSWORD_UPDATE,
  PASSWORD_UPDATE_SUCCESS,
  PASSWORD_UPDATE_FAILURE,
  PASSWORD_RESET,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAILURE,
  ON_EXPIRED_TOKEN
);
