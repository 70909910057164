import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//Actions
import { projectInvite } from "@actions";

//Reducers
import {
  getProjectUser,
  getCurrentUser,
  getWorkspaceCurrent,
  getWorkspaceIncluded,
} from "@reducer";

//Component
import { InviteToProjectModal } from "@components/domain/invitations";

const InviteToProjectModalContainer = ({ showButton, project }) => {
  const dispatch = useDispatch();
  const workspaceId = useSelector(getWorkspaceCurrent);
  const currentUser = useSelector(getCurrentUser);
  const workspaceIncluded = useSelector(getWorkspaceIncluded);
  const projectUser = useSelector(getProjectUser(project, currentUser));
  const [dirtyChanges, setDirtyChanges] = useState({});
  const [showModal, setState] = useState(false);
  const [userRole, setUserRole] = useState("");

  const [newUser, setNewUser] = useState(false);
  const [workspaceUsers, setWorkspaceUsers] = useState();

  const handleDropdownSelect = (name, value) => {
    setDirtyChanges({
      ...dirtyChanges,
      [name]: value,
    });
  };

  useEffect(() => {
    if (dirtyChanges.email) {
      //check if the user belongs to the workspace
      let currentUsers = workspaceUsers.map((user) => ({
        email: user.attributes.email,
        id: user.id,
      }));

      let checkNewUser = currentUsers.find(
        (user) => user.email === dirtyChanges.email
      );

      //set state according to whether the user is new or not
      if (checkNewUser) {
        const wsUserData = workspaceIncluded.filter(
          (item) => item.type === "workspace_user"
        );

        let handleUserRole = wsUserData.find(
          (user) => user.attributes.user_id === checkNewUser.id
        );

        setDirtyChanges({
          ...dirtyChanges,
          workspace_role: handleUserRole.attributes.role,
        });
        setNewUser(false);
      } else {
        setNewUser(true);
        delete dirtyChanges.workspace_role;
      }
    }
  }, [dirtyChanges.email]);

  const handleInputChange = (event) => {
    setDirtyChanges({
      ...dirtyChanges,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmitClick = () => {
    dispatch(
      projectInvite({
        ...dirtyChanges,
        project_id: project.id,
      })
    );
    setState(false);
  };

  useEffect(() => {
    if (workspaceIncluded) {
      const usersData = workspaceIncluded.filter(
        (item) => item.type === "user"
      );

      setWorkspaceUsers(usersData);
    }
  }, [workspaceIncluded]);

  useEffect(() => {
    setDirtyChanges({});
  }, [showModal]);

  useEffect(() => {
    setDirtyChanges({});
    if (projectUser && currentUser) {
      setUserRole(projectUser.attributes.role);
    }
  }, [projectUser, currentUser]);

  return (
    <InviteToProjectModal
      dispatch={dispatch}
      dirtyChanges={dirtyChanges}
      setDirtyChanges={setDirtyChanges}
      handleSubmitClick={handleSubmitClick}
      handleChange={handleInputChange}
      handleDropdown={handleDropdownSelect}
      workspaceId={workspaceId}
      workspaceUsers={workspaceUsers}
      showModal={showModal}
      showButton={showButton}
      newUser={newUser}
      openModal={() => setState(true)}
      closeModal={() => setState(false)}
    />
  );
};

export default InviteToProjectModalContainer;
