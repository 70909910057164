import React from "react";
import styled from "styled-components";
import theme from "../../../theme";

// Components
import Card from "../card/index";
import Flex from "../flex";
import Button from "../button/index";
import Text from "../text/index";

//Icons
import Close from "../icons/close";

const StyledBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99999;
`;

const StyledCardContainer = styled.div`
  position: fixed;
  background: transparent;
  width: 40%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledItemName = styled.div`
  font-size: 1.3rem;
  padding-bottom: 15px;
  display: flex;
  justify-content: center;
  color: ${theme.colors.lightRed};
  font-weight: 400;
`;

const RemoveModal = ({
  closeModal,
  title,
  removeItem,
  handleRemoveClick,
  modalType,
}) => {
  return (
    <StyledBackground>
      <StyledCardContainer>
        <Card
          fullHeader
          title={title}
          icon={<Close variant="grey"></Close>}
          closeHandler={closeModal}
          footer
          buttons={[
            <Button
              key="cancel"
              variant="secondaryLine"
              content="Cancel"
              onClick={closeModal}
            />,
            <Button
              key="remove"
              variant="secondaryLine"
              content={modalType === "leave" ? "Leave" : "Remove"}
              onClick={() => handleRemoveClick(removeItem)}
            />,
          ]}
        >
          <Flex width="90%" justifyContent="center">
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="space-evenly"
              height="150px"
            >
              {modalType === "remove" && (
                <>
                  <Text textAlign="center" fontSize="1.2rem" marginTop="15px">
                    You are about to delete this item.
                  </Text>
                  <StyledItemName>{removeItem.attributes.name}</StyledItemName>
                  <Text textAlign="center" fontSize="1rem">
                    Are you sure you want to proceed?
                  </Text>
                  <Text textAlign="center" fontSize="1rem">
                    Once deleted it cannot be recovered
                  </Text>
                </>
              )}
              {modalType === "leave" && (
                <>
                  <Text textAlign="center" fontSize="1.2rem" marginTop="15px">
                    You are about to leave
                  </Text>
                  <StyledItemName>{removeItem.attributes.name}</StyledItemName>
                  <Text textAlign="center" fontSize="1rem">
                    Are you sure you want to proceed?
                  </Text>
                  <Text textAlign="center" fontSize="1rem">
                    Once you leave it you will lose access to the Projects
                  </Text>
                </>
              )}
            </Flex>
          </Flex>
        </Card>
      </StyledCardContainer>
    </StyledBackground>
  );
};

export default RemoveModal;
