import React from "react";

const ArrowRightDoubleSidebar = () => {
  return (
    <div style={{
      width: '100%', 
      height: '100%', 
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '1.4rem'
    }}>
      <div style={{
        width: 11.25,
        height: 10.74,
        paddingTop: 0.765, 
        paddingBottom: 1.02,
        paddingLeft: 1.02,
        paddingRight: 1.02,
        transform: 'rotate(-90deg)', 
        transformOrigin: '0 0', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'flex-start', 
        display: 'inline-flex'
      }}>
        {/* First arrow */}
        <div style={{
          width: 9.20,
          height: 4.605,
          position: 'relative'
        }}>
          <div style={{
            width: 6.51,
            height: 0, 
            left: 0, 
            top: 0, 
            position: 'absolute', 
            transform: 'rotate(45deg)', 
            transformOrigin: '0 0', 
            border: '1.5px solid #E94E1B'
          }}></div>
          <div style={{
            width: 6.51,
            height: 0, 
            left: 4.605,
            top: 4.605,
            position: 'absolute', 
            transform: 'rotate(-45deg)', 
            transformOrigin: '0 0', 
            border: '1.5px solid #E94E1B'
          }}></div>
        </div>
        {/* Second arrow */}
        <div style={{
          width: 9.20,
          height: 4.605,
          position: 'relative'
        }}>
          <div style={{
            width: 6.51,
            height: 0, 
            left: 0, 
            top: 0, 
            position: 'absolute', 
            transform: 'rotate(45deg)', 
            transformOrigin: '0 0', 
            border: '1.5px solid #E94E1B'
          }}></div>
          <div style={{
            width: 6.51,
            height: 0, 
            left: 4.605,
            top: 4.605,
            position: 'absolute', 
            transform: 'rotate(-45deg)', 
            transformOrigin: '0 0', 
            border: '1.5px solid #E94E1B'
          }}></div>
        </div>
      </div>
    </div>
  );
};

export default ArrowRightDoubleSidebar;
