export const timezonesList = () => {
  return [
    {
      "id": "Pacific/Niue",
      "value": "(GMT-11:00) Niue",
    },
    {
      "id": "Pacific/Pago_Pago",
      "value": "(GMT-11:00) Pago Pago",
    },
    {
      "id": "Pacific/Honolulu",
      "value": "(GMT-10:00) Hawaii Time",
    },
    {
      "id": "Pacific/Rarotonga",
      "value": "(GMT-10:00) Rarotonga",
    },
    {
      "id": "Pacific/Tahiti",
      "value": "(GMT-10:00) Tahiti",
    },
    {
      "id": "Pacific/Marquesas",
      "value": "(GMT-09:30) Marquesas",
    },
    {
      "id": "America/Anchorage",
      "value": "(GMT-09:00) Alaska Time",
    },
    {
      "id": "Pacific/Gambier",
      "value": "(GMT-09:00) Gambier",
    },
    {
      "id": "America/Los_Angeles",
      "value": "(GMT-08:00) Pacific Time",
    },
    {
      "id": "America/Tijuana",
      "value": "(GMT-08:00) Pacific Time - Tijuana",
    },
    {
      "id": "America/Vancouver",
      "value": "(GMT-08:00) Pacific Time - Vancouver",
    },
    {
      "id": "America/Whitehorse",
      "value": "(GMT-08:00) Pacific Time - Whitehorse",
    },
    {
      "id": "Pacific/Pitcairn",
      "value": "(GMT-08:00) Pitcairn",
    },
    {
      "id": "America/Dawson_Creek",
      "value": "(GMT-07:00) Mountain Time - Dawson Creek",
    },
    {
      "id": "America/Denver",
      "value": "(GMT-07:00) Mountain Time",
    },
    {
      "id": "America/Edmonton",
      "value": "(GMT-07:00) Mountain Time - Edmonton",
    },
    {
      "id": "America/Hermosillo",
      "value": "(GMT-07:00) Mountain Time - Hermosillo",
    },
    {
      "id": "America/Mazatlan",
      "value": "(GMT-07:00) Mountain Time - Chihuahua, Mazatlan",
    },
    {
      "id": "America/Phoenix",
      "value": "(GMT-07:00) Mountain Time - Arizona",
    },
    {
      "id": "America/Yellowknife",
      "value": "(GMT-07:00) Mountain Time - Yellowknife",
    },
    {
      "id": "America/Belize",
      "value": "(GMT-06:00) Belize",
    },
    {
      "id": "America/Chicago",
      "value": "(GMT-06:00) Central Time",
    },
    {
      "id": "America/Costa_Rica",
      "value": "(GMT-06:00) Costa Rica",
    },
    {
      "id": "America/El_Salvador",
      "value": "(GMT-06:00) El Salvador",
    },
    {
      "id": "America/Guatemala",
      "value": "(GMT-06:00) Guatemala",
    },
    {
      "id": "America/Managua",
      "value": "(GMT-06:00) Managua",
    },
    {
      "id": "America/Mexico_City",
      "value": "(GMT-06:00) Central Time - Mexico City",
    },
    {
      "id": "America/Regina",
      "value": "(GMT-06:00) Central Time - Regina",
    },
    {
      "id": "America/Tegucigalpa",
      "value": "(GMT-06:00) Central Time - Tegucigalpa",
    },
    {
      "id": "America/Winnipeg",
      "value": "(GMT-06:00) Central Time - Winnipeg",
    },
    {
      "id": "Pacific/Galapagos",
      "value": "(GMT-06:00) Galapagos",
    },
    {
      "id": "America/Bogota",
      "value": "(GMT-05:00) Bogota",
    },
    {
      "id": "America/Cancun",
      "value": "(GMT-05:00) Cancun",
    },
    {
      "id": "America/Cayman",
      "value": "(GMT-05:00) Cayman",
    },
    {
      "id": "America/Guayaquil",
      "value": "(GMT-05:00) Guayaquil",
    },
    {
      "id": "America/Havana",
      "value": "(GMT-05:00) Havana",
    },
    {
      "id": "America/Iqaluit",
      "value": "(GMT-05:00) Eastern Time - Iqaluit",
    },
    {
      "id": "America/Jamaica",
      "value": "(GMT-05:00) Jamaica",
    },
    {
      "id": "America/Lima",
      "value": "(GMT-05:00) Lima",
    },
    {
      "id": "America/Nassau",
      "value": "(GMT-05:00) Nassau",
    },
    {
      "id": "America/New_York",
      "value": "(GMT-05:00) Eastern Time",
    },
    {
      "id": "America/Panama",
      "value": "(GMT-05:00) Panama",
    },
    {
      "id": "America/Port-au-Prince",
      "value": "(GMT-05:00) Port-au-Prince",
    },
    {
      "id": "America/Rio_Branco",
      "value": "(GMT-05:00) Rio Branco",
    },
    {
      "id": "America/Toronto",
      "value": "(GMT-05:00) Eastern Time - Toronto",
    },
    {
      "id": "Pacific/Easter",
      "value": "(GMT-05:00) Easter Island",
    },
    {
      "id": "America/Caracas",
      "value": "(GMT-04:00) Caracas",
    },
    {
      "id": "America/Asuncion",
      "value": "(GMT-03:00) Asuncion",
    },
    {
      "id": "America/Barbados",
      "value": "(GMT-04:00) Barbados",
    },
    {
      "id": "America/Boa_Vista",
      "value": "(GMT-04:00) Boa Vista",
    },
    {
      "id": "America/Campo_Grande",
      "value": "(GMT-03:00) Campo Grande",
    },
    {
      "id": "America/Cuiaba",
      "value": "(GMT-03:00) Cuiaba",
    },
    {
      "id": "America/Curacao",
      "value": "(GMT-04:00) Curacao",
    },
    {
      "id": "America/Grand_Turk",
      "value": "(GMT-04:00) Grand Turk",
    },
    {
      "id": "America/Guyana",
      "value": "(GMT-04:00) Guyana",
    },
    {
      "id": "America/Halifax",
      "value": "(GMT-04:00) Atlantic Time - Halifax",
    },
    {
      "id": "America/La_Paz",
      "value": "(GMT-04:00) La Paz",
    },
    {
      "id": "America/Manaus",
      "value": "(GMT-04:00) Manaus",
    },
    {
      "id": "America/Martinique",
      "value": "(GMT-04:00) Martinique",
    },
    {
      "id": "America/Port_of_Spain",
      "value": "(GMT-04:00) Port of Spain",
    },
    {
      "id": "America/Porto_Velho",
      "value": "(GMT-04:00) Porto Velho",
    },
    {
      "id": "America/Puerto_Rico",
      "value": "(GMT-04:00) Puerto Rico",
    },
    {
      "id": "America/Santo_Domingo",
      "value": "(GMT-04:00) Santo Domingo",
    },
    {
      "id": "America/Thule",
      "value": "(GMT-04:00) Thule",
    },
    {
      "id": "Atlantic/Bermuda",
      "value": "(GMT-04:00) Bermuda",
    },
    {
      "id": "America/St_Johns",
      "value": "(GMT-03:30) Newfoundland Time - St. Johns",
    },
    {
      "id": "America/Araguaina",
      "value": "(GMT-03:00) Araguaina",
    },
    {
      "id": "America/Argentina/Buenos_Aires",
      "value": "(GMT-03:00) Buenos Aires",
    },
    {
      "id": "America/Bahia",
      "value": "(GMT-03:00) Salvador",
    },
    {
      "id": "America/Belem",
      "value": "(GMT-03:00) Belem",
    },
    {
      "id": "America/Cayenne",
      "value": "(GMT-03:00) Cayenne",
    },
    {
      "id": "America/Fortaleza",
      "value": "(GMT-03:00) Fortaleza",
    },
    {
      "id": "America/Godthab",
      "value": "(GMT-03:00) Godthab",
    },
    {
      "id": "America/Maceio",
      "value": "(GMT-03:00) Maceio",
    },
    {
      "id": "America/Miquelon",
      "value": "(GMT-03:00) Miquelon",
    },
    {
      "id": "America/Montevideo",
      "value": "(GMT-03:00) Montevideo",
    },
    {
      "id": "America/Paramaribo",
      "value": "(GMT-03:00) Paramaribo",
    },
    {
      "id": "America/Recife",
      "value": "(GMT-03:00) Recife",
    },
    {
      "id": "America/Santiago",
      "value": "(GMT-03:00) Santiago",
    },
    {
      "id": "America/Sao_Paulo",
      "value": "(GMT-03:00) Sao Paulo",
    },
    {
      "id": "Antarctica/Palmer",
      "value": "(GMT-03:00) Palmer",
    },
    {
      "id": "Antarctica/Rothera",
      "value": "(GMT-03:00) Rothera",
    },
    {
      "id": "Atlantic/Stanley",
      "value": "(GMT-03:00) Stanley",
    },
    {
      "id": "America/Noronha",
      "value": "(GMT-02:00) Noronha",
    },
    {
      "id": "Atlantic/South_Georgia",
      "value": "(GMT-02:00) South Georgia",
    },
    {
      "id": "America/Scoresbysund",
      "value": "(GMT-01:00) Scoresbysund",
    },
    {
      "id": "Atlantic/Azores",
      "value": "(GMT-01:00) Azores",
    },
    {
      "id": "Atlantic/Cape_Verde",
      "value": "(GMT-01:00) Cape Verde",
    },
    {
      "id": "Africa/Abidjan",
      "value": "(GMT+00:00) Abidjan",
    },
    {
      "id": "Africa/Accra",
      "value": "(GMT+00:00) Accra",
    },
    {
      "id": "Africa/Bissau",
      "value": "(GMT+00:00) Bissau",
    },
    {
      "id": "Africa/Casablanca",
      "value": "(GMT+00:00) Casablanca",
    },
    {
      "id": "Africa/El_Aaiun",
      "value": "(GMT+00:00) El Aaiun",
    },
    {
      "id": "Africa/Monrovia",
      "value": "(GMT+00:00) Monrovia",
    },
    {
      "id": "America/Danmarkshavn",
      "value": "(GMT+00:00) Danmarkshavn",
    },
    {
      "id": "Atlantic/Canary",
      "value": "(GMT+00:00) Canary Islands",
    },
    {
      "id": "Atlantic/Faroe",
      "value": "(GMT+00:00) Faeroe",
    },
    {
      "id": "Atlantic/Reykjavik",
      "value": "(GMT+00:00) Reykjavik",
    },
    {
      "id": "Etc/GMT",
      "value": "(GMT+00:00) GMT (no daylight saving)",
    },
    {
      "id": "Europe/Dublin",
      "value": "(GMT+00:00) Dublin",
    },
    {
      "id": "Europe/Lisbon",
      "value": "(GMT+00:00) Lisbon",
    },
    {
      "id": "Europe/London",
      "value": "(GMT+00:00) London",
    },
    {
      "id": "Africa/Algiers",
      "value": "(GMT+01:00) Algiers",
    },
    {
      "id": "Africa/Ceuta",
      "value": "(GMT+01:00) Ceuta",
    },
    {
      "id": "Africa/Lagos",
      "value": "(GMT+01:00) Lagos",
    },
    {
      "id": "Africa/Ndjamena",
      "value": "(GMT+01:00) Ndjamena",
    },
    {
      "id": "Africa/Tunis",
      "value": "(GMT+01:00) Tunis",
    },
    {
      "id": "Africa/Windhoek",
      "value": "(GMT+02:00) Windhoek",
    },
    {
      "id": "Europe/Amsterdam",
      "value": "(GMT+01:00) Amsterdam",
    },
    {
      "id": "Europe/Andorra",
      "value": "(GMT+01:00) Andorra",
    },
    {
      "id": "Europe/Belgrade",
      "value": "(GMT+01:00) Central European Time - Belgrade",
    },
    {
      "id": "Europe/Berlin",
      "value": "(GMT+01:00) Berlin",
    },
    {
      "id": "Europe/Brussels",
      "value": "(GMT+01:00) Brussels",
    },
    {
      "id": "Europe/Budapest",
      "value": "(GMT+01:00) Budapest",
    },
    {
      "id": "Europe/Copenhagen",
      "value": "(GMT+01:00) Copenhagen",
    },
    {
      "id": "Europe/Gibraltar",
      "value": "(GMT+01:00) Gibraltar",
    },
    {
      "id": "Europe/Luxembourg",
      "value": "(GMT+01:00) Luxembourg",
    },
    {
      "id": "Europe/Madrid",
      "value": "(GMT+01:00) Madrid",
    },
    {
      "id": "Europe/Malta",
      "value": "(GMT+01:00) Malta",
    },
    {
      "id": "Europe/Monaco",
      "value": "(GMT+01:00) Monaco",
    },
    {
      "id": "Europe/Oslo",
      "value": "(GMT+01:00) Oslo",
    },
    {
      "id": "Europe/Paris",
      "value": "(GMT+01:00) Paris",
    },
    {
      "id": "Europe/Prague",
      "value": "(GMT+01:00) Central European Time - Prague",
    },
    {
      "id": "Europe/Rome",
      "value": "(GMT+01:00) Rome",
    },
    {
      "id": "Europe/Stockholm",
      "value": "(GMT+01:00) Stockholm",
    },
    {
      "id": "Europe/Tirane",
      "value": "(GMT+01:00) Tirane",
    },
    {
      "id": "Europe/Vienna",
      "value": "(GMT+01:00) Vienna",
    },
    {
      "id": "Europe/Warsaw",
      "value": "(GMT+01:00) Warsaw",
    },
    {
      "id": "Europe/Zurich",
      "value": "(GMT+01:00) Zurich",
    },
    {
      "id": "Africa/Cairo",
      "value": "(GMT+02:00) Cairo",
    },
    {
      "id": "Africa/Johannesburg",
      "value": "(GMT+02:00) Johannesburg",
    },
    {
      "id": "Africa/Maputo",
      "value": "(GMT+02:00) Maputo",
    },
    {
      "id": "Africa/Tripoli",
      "value": "(GMT+02:00) Tripoli",
    },
    {
      "id": "Asia/Amman",
      "value": "(GMT+02:00) Amman",
    },
    {
      "id": "Asia/Beirut",
      "value": "(GMT+02:00) Beirut",
    },
    {
      "id": "Asia/Damascus",
      "value": "(GMT+02:00) Damascus",
    },
    {
      "id": "Asia/Gaza",
      "value": "(GMT+02:00) Gaza",
    },
    {
      "id": "Asia/Jerusalem",
      "value": "(GMT+02:00) Jerusalem",
    },
    {
      "id": "Asia/Nicosia",
      "value": "(GMT+02:00) Nicosia",
    },
    {
      "id": "Europe/Athens",
      "value": "(GMT+02:00) Athens",
    },
    {
      "id": "Europe/Bucharest",
      "value": "(GMT+02:00) Bucharest",
    },
    {
      "id": "Europe/Chisinau",
      "value": "(GMT+02:00) Chisinau",
    },
    {
      "id": "Europe/Helsinki",
      "value": "(GMT+02:00) Helsinki",
    },
    {
      "id": "Europe/Istanbul",
      "value": "(GMT+03:00) Istanbul",
    },
    {
      "id": "Europe/Kaliningrad",
      "value": "(GMT+02:00) Moscow-01 - Kaliningrad",
    },
    {
      "id": "Europe/Kyiv",
      "value": "(GMT+02:00) Kyiv",
    },
    {
      "id": "Europe/Riga",
      "value": "(GMT+02:00) Riga",
    },
    {
      "id": "Europe/Sofia",
      "value": "(GMT+02:00) Sofia",
    },
    {
      "id": "Europe/Tallinn",
      "value": "(GMT+02:00) Tallinn",
    },
    {
      "id": "Europe/Vilnius",
      "value": "(GMT+02:00) Vilnius",
    },
    {
      "id": "Africa/Khartoum",
      "value": "(GMT+03:00) Khartoum",
    },
    {
      "id": "Africa/Nairobi",
      "value": "(GMT+03:00) Nairobi",
    },
    {
      "id": "Antarctica/Syowa",
      "value": "(GMT+03:00) Syowa",
    },
    {
      "id": "Asia/Baghdad",
      "value": "(GMT+03:00) Baghdad",
    },
    {
      "id": "Asia/Qatar",
      "value": "(GMT+03:00) Qatar",
    },
    {
      "id": "Asia/Riyadh",
      "value": "(GMT+03:00) Riyadh",
    },
    {
      "id": "Europe/Minsk",
      "value": "(GMT+03:00) Minsk",
    },
    {
      "id": "Europe/Moscow",
      "value": "(GMT+03:00) Moscow+00 - Moscow",
    },
    {
      "id": "Asia/Tehran",
      "value": "(GMT+03:30) Tehran",
    },
    {
      "id": "Asia/Baku",
      "value": "(GMT+04:00) Baku",
    },
    {
      "id": "Asia/Dubai",
      "value": "(GMT+04:00) Dubai",
    },
    {
      "id": "Asia/Tbilisi",
      "value": "(GMT+04:00) Tbilisi",
    },
    {
      "id": "Asia/Yerevan",
      "value": "(GMT+04:00) Yerevan",
    },
    {
      "id": "Europe/Samara",
      "value": "(GMT+04:00) Moscow+01 - Samara",
    },
    {
      "id": "Indian/Mahe",
      "value": "(GMT+04:00) Mahe",
    },
    {
      "id": "Indian/Mauritius",
      "value": "(GMT+04:00) Mauritius",
    },
    {
      "id": "Indian/Reunion",
      "value": "(GMT+04:00) Reunion",
    },
    {
      "id": "Asia/Kabul",
      "value": "(GMT+04:30) Kabul",
    },
    {
      "id": "Antarctica/Mawson",
      "value": "(GMT+05:00) Mawson",
    },
    {
      "id": "Asia/Aqtau",
      "value": "(GMT+05:00) Aqtau",
    },
    {
      "id": "Asia/Aqtobe",
      "value": "(GMT+05:00) Aqtobe",
    },
    {
      "id": "Asia/Ashgabat",
      "value": "(GMT+05:00) Ashgabat",
    },
    {
      "id": "Asia/Dushanbe",
      "value": "(GMT+05:00) Dushanbe",
    },
    {
      "id": "Asia/Karachi",
      "value": "(GMT+05:00) Karachi",
    },
    {
      "id": "Asia/Tashkent",
      "value": "(GMT+05:00) Tashkent",
    },
    {
      "id": "Asia/Yekaterinburg",
      "value": "(GMT+05:00) Moscow+02 - Yekaterinburg",
    },
    {
      "id": "Indian/Kerguelen",
      "value": "(GMT+05:00) Kerguelen",
    },
    {
      "id": "Indian/Maldives",
      "value": "(GMT+05:00) Maldives",
    },
    {
      "id": "Asia/Calcutta",
      "value": "(GMT+05:30) India Standard Time",
    },
    {
      "id": "Asia/Colombo",
      "value": "(GMT+05:30) Colombo",
    },
    {
      "id": "Asia/Katmandu",
      "value": "(GMT+05:45) Katmandu",
    },
    {
      "id": "Antarctica/Vostok",
      "value": "(GMT+06:00) Vostok",
    },
    {
      "id": "Asia/Almaty",
      "value": "(GMT+06:00) Almaty",
    },
    {
      "id": "Asia/Bishkek",
      "value": "(GMT+06:00) Bishkek",
    },
    {
      "id": "Asia/Dhaka",
      "value": "(GMT+06:00) Dhaka",
    },
    {
      "id": "Asia/Omsk",
      "value": "(GMT+06:00) Moscow+03 - Omsk, Novosibirsk",
    },
    {
      "id": "Asia/Thimphu",
      "value": "(GMT+06:00) Thimphu",
    },
    {
      "id": "Indian/Chagos",
      "value": "(GMT+06:00) Chagos",
    },
    {
      "id": "Asia/Rangoon",
      "value": "(GMT+06:30) Rangoon",
    },
    {
      "id": "Indian/Cocos",
      "value": "(GMT+06:30) Cocos",
    },
    {
      "id": "Antarctica/Davis",
      "value": "(GMT+07:00) Davis",
    },
    {
      "id": "Asia/Bangkok",
      "value": "(GMT+07:00) Bangkok",
    },
    {
      "id": "Asia/Hovd",
      "value": "(GMT+07:00) Hovd",
    },
    {
      "id": "Asia/Jakarta",
      "value": "(GMT+07:00) Jakarta",
    },
    {
      "id": "Asia/Krasnoyarsk",
      "value": "(GMT+07:00) Moscow+04 - Krasnoyarsk",
    },
    {
      "id": "Asia/Saigon",
      "value": "(GMT+07:00) Hanoi",
    },
    {
      "id": "Asia/Ho_Chi_Minh",
      "value": "(GMT+07:00) Ho Chi Minh",
    },
    {
      "id": "Indian/Christmas",
      "value": "(GMT+07:00) Christmas",
    },
    {
      "id": "Antarctica/Casey",
      "value": "(GMT+08:00) Casey",
    },
    {
      "id": "Asia/Brunei",
      "value": "(GMT+08:00) Brunei",
    },
    {
      "id": "Asia/Choibalsan",
      "value": "(GMT+08:00) Choibalsan",
    },
    {
      "id": "Asia/Hong_Kong",
      "value": "(GMT+08:00) Hong Kong",
    },
    {
      "id": "Asia/Irkutsk",
      "value": "(GMT+08:00) Moscow+05 - Irkutsk",
    },
    {
      "id": "Asia/Kuala_Lumpur",
      "value": "(GMT+08:00) Kuala Lumpur",
    },
    {
      "id": "Asia/Macau",
      "value": "(GMT+08:00) Macau",
    },
    {
      "id": "Asia/Makassar",
      "value": "(GMT+08:00) Makassar",
    },
    {
      "id": "Asia/Manila",
      "value": "(GMT+08:00) Manila",
    },
    {
      "id": "Asia/Shanghai",
      "value": "(GMT+08:00) China Time - Beijing",
    },
    {
      "id": "Asia/Singapore",
      "value": "(GMT+08:00) Singapore",
    },
    {
      "id": "Asia/Taipei",
      "value": "(GMT+08:00) Taipei",
    },
    {
      "id": "Asia/Ulaanbaatar",
      "value": "(GMT+08:00) Ulaanbaatar",
    },
    {
      "id": "Australia/Perth",
      "value": "(GMT+08:00) Western Time - Perth",
    },
    {
      "id": "Asia/Pyongyang",
      "value": "(GMT+08:30) Pyongyang",
    },
    {
      "id": "Asia/Dili",
      "value": "(GMT+09:00) Dili",
    },
    {
      "id": "Asia/Jayapura",
      "value": "(GMT+09:00) Jayapura",
    },
    {
      "id": "Asia/Seoul",
      "value": "(GMT+09:00) Seoul",
    },
    {
      "id": "Asia/Tokyo",
      "value": "(GMT+09:00) Tokyo",
    },
    {
      "id": "Asia/Yakutsk",
      "value": "(GMT+09:00) Moscow+06 - Yakutsk",
    },
    {
      "id": "Pacific/Palau",
      "value": "(GMT+09:00) Palau",
    },
    {
      "id": "Australia/Adelaide",
      "value": "(GMT+10:30) Central Time - Adelaide",
    },
    {
      "id": "Australia/Darwin",
      "value": "(GMT+09:30) Central Time - Darwin",
    },
    {
      "id": "Antarctica/DumontDUrville",
      "value": "(GMT+10:00) Dumont D'Urville",
    },
    {
      "id": "Asia/Magadan",
      "value": "(GMT+10:00) Moscow+07 - Magadan",
    },
    {
      "id": "Asia/Vladivostok",
      "value": "(GMT+10:00) Moscow+07 - Vladivostok",
    },
    {
      "id": "Australia/Brisbane",
      "value": "(GMT+10:00) Eastern Time - Brisbane",
    },
    {
      "id": "Australia/Hobart",
      "value": "(GMT+11:00) Eastern Time - Hobart",
    },
    {
      "id": "Australia/Sydney",
      "value": "(GMT+11:00) Eastern Time - Melbourne, Sydney",
    },
    {
      "id": "Pacific/Chuuk",
      "value": "(GMT+10:00) Truk",
    },
    {
      "id": "Pacific/Guam",
      "value": "(GMT+10:00) Guam",
    },
    {
      "id": "Pacific/Port_Moresby",
      "value": "(GMT+10:00) Port Moresby",
    },
    {
      "id": "Pacific/Efate",
      "value": "(GMT+11:00) Efate",
    },
    {
      "id": "Pacific/Guadalcanal",
      "value": "(GMT+11:00) Guadalcanal",
    },
    {
      "id": "Pacific/Kosrae",
      "value": "(GMT+11:00) Kosrae",
    },
    {
      "id": "Pacific/Norfolk",
      "value": "(GMT+11:00) Norfolk",
    },
    {
      "id": "Pacific/Noumea",
      "value": "(GMT+11:00) Noumea",
    },
    {
      "id": "Pacific/Pohnpei",
      "value": "(GMT+11:00) Ponape",
    },
    {
      "id": "Asia/Kamchatka",
      "value": "(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy",
    },
    {
      "id": "Pacific/Auckland",
      "value": "(GMT+13:00) Auckland",
    },
    {
      "id": "Pacific/Fiji",
      "value": "(GMT+13:00) Fiji",
    },
    {
      "id": "Pacific/Funafuti",
      "value": "(GMT+12:00) Funafuti",
    },
    {
      "id": "Pacific/Kwajalein",
      "value": "(GMT+12:00) Kwajalein",
    },
    {
      "id": "Pacific/Majuro",
      "value": "(GMT+12:00) Majuro",
    },
    {
      "id": "Pacific/Nauru",
      "value": "(GMT+12:00) Nauru",
    },
    {
      "id": "Pacific/Tarawa",
      "value": "(GMT+12:00) Tarawa",
    },
    {
      "id": "Pacific/Wake",
      "value": "(GMT+12:00) Wake",
    },
    {
      "id": "Pacific/Wallis",
      "value": "(GMT+12:00) Wallis",
    },
    {
      "id": "Pacific/Apia",
      "value": "(GMT+14:00) Apia",
    },
    {
      "id": "Pacific/Enderbury",
      "value": "(GMT+13:00) Enderbury",
    },
    {
      "id": "Pacific/Fakaofo",
      "value": "(GMT+13:00) Fakaofo",
    },
    {
      "id": "Pacific/Tongatapu",
      "value": "(GMT+13:00) Tongatapu",
    },
    {
      "id": "Pacific/Kiritimati",
      "value": "(GMT+14:00) Kiritimati"
    },
  ]
}
