import React, { useState } from "react";
import styled from "styled-components";
import theme from "../../../theme";

// Components
import Flex from "../../ui/flex";
import Row from "../../ui/row";
import Text from "../../ui/text";
import Input from "../../ui/input";

import Step from "../../ui/step";
import Box from "../../ui/box";
import Add from "../../ui/icons/add";

const StyledStepsContainer = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
  flex-direction: column;

  ${({ onClick }) => onClick && `cursor: pointer;`}
`;

const StyledDivider = styled.div`
  display: flex;
  align-self: center;
  margin: 8px;
  border-left: 2px solid ${theme.colors.blue};
  width: 0px;
  height: 20px;
`;

const StyledCircle = styled.div`
  display: flex;
  align-self: center;
  border-radius: 50%;
  height: 17px;
  width: 17px;
  padding: 3px;
  background-color: ${theme.colors.blue};

  &:hover {
    box-shadow: -2px 2px 2px lightgray;
    cursor: pointer;
  }
`;

const WorkflowCreation = ({ content, addBtn, dirtyChanges, handleChange }) => {
  const [activeStepId, setState] = useState(0);

  let totalSteps = "";
  for (let i = 1; i <= content; i++) totalSteps = [...totalSteps, i];

  return (
    <Flex width={"100%"} justifyContent={"space-evenly"}>
      <StyledStepsContainer>
        {totalSteps.map((step, index) => (
          <React.Fragment key={index}>
            <Step
              onClick={() => setState(index)}
              key={index}
              content={index + 1}
              variant={index === activeStepId ? "active" : "red"}
            ></Step>
            {index === totalSteps.length - 1 ? null : (
              <StyledDivider vertical></StyledDivider>
            )}
          </React.Fragment>
        ))}

        {addBtn ? (
          <>
            <StyledDivider vertical />
            <StyledCircle>
              <Add variant="white"></Add>
            </StyledCircle>
          </>
        ) : null}
      </StyledStepsContainer>

      <Flex flexDirection={"column"} width={"100%"}>
        {totalSteps.map((_, index) => (
          <Row
            onClick={() => setState(index)}
            key={index}
            active={index === activeStepId}
            flat={index !== activeStepId}
          >
            {index === activeStepId ? (
              <>
                <Input
                  width="50%"
                  required
                  label="Name"
                  mb={2}
                  mt={2}
                  name="name"
                  value={
                    dirtyChanges.steps && dirtyChanges.steps[index]
                      ? dirtyChanges.steps[index].name
                      : null
                  }
                  onChange={(e) => handleChange(e, index)}
                  placeholder="Set Step Name"
                />
              </>
            ) : null}
            {index !== activeStepId ? (
              <>
                <Text
                  color={theme.colors.blue}
                  fontFamily="Open Sans"
                  fontSize="1.2rem"
                >
                  {dirtyChanges.steps && dirtyChanges.steps[index]
                    ? dirtyChanges.steps[index].name
                    : "Step Name"}
                </Text>
              </>
            ) : null}
          </Row>
        ))}
      </Flex>
    </Flex>
  );
};

export default WorkflowCreation;
