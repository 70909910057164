import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black)" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const Help = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <g>
        <path
          d="M5.6,23.2c-0.1,0-0.2,0-0.4-0.1C5,23,4.8,22.8,4.8,22.5v-2.7c-0.9-0.1-1.6-0.5-2.1-1c-0.7-0.7-1-1.6-1-2.8V5.6
		c0-2,1.8-3.8,3.8-3.8h13.8c2.1,0,3.8,1.6,3.8,3.6l0,10.7c0,2-1.8,3.8-3.8,3.8H11l-5,3.2C5.9,23.2,5.7,23.2,5.6,23.2z M5.6,3.3
		c-1.2,0-2.3,1.2-2.3,2.3v10.5c0,0.8,0.2,1.4,0.6,1.7c0.5,0.5,1.2,0.6,1.8,0.6c0.4,0,0.8,0.3,0.8,0.8v2l4-2.6
		c0.1-0.1,0.3-0.1,0.4-0.1h8.7c1.2,0,2.3-1.2,2.3-2.3l0-10.7c0-1.1-1.1-2.1-2.3-2.1H5.6z"
        />
        <circle cx="12.5" cy="16.2" r="1.3" />
        <path
          d="M15.2,5.8C14.5,5.4,13.8,5,12.7,5c-1.1,0-2.3,0.4-3.3,1c0,0-0.8,0.4-0.8,1.2c0.1,0.7,0.6,1.3,1.3,1.3
		c0.4,0,1.9-0.8,2.6-0.8c0.9,0,1.4,0.1,1.3,0.7c0,0.6-1,1.1-1.4,1.3c-1.4,0.8-1.1,2.9-1.1,2.9h0c0,0,0,0,0,0c0,0.7,0.6,1.3,1.3,1.3
		s1.2-0.6,1.3-1.3c0-0.4-0.1-0.9,0.9-1.5c0.9-0.5,1.7-1.5,1.6-2.9C16.3,7.3,15.8,6.3,15.2,5.8z"
        />
      </g>
    </StyledSvg>
  );
};

export default Help;
