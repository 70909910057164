import { combineEpics, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap, map } from "rxjs/operators";
import { toast } from "react-toastify";

import {
  MESSAGES_GET,
  messagesGetSuccess,
  messagesGetFailure,
} from "../actions";

const getMessagesEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(MESSAGES_GET),
    mergeMap(({ payload: { person_id } }) =>
      from(api.get(`/people/${person_id}/messages`).then((resp) => resp)).pipe(
        map(messagesGetSuccess),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map(error => toast.error(error.detail));
          return of(messagesGetFailure({ err, errors }));
        })
      )
    )
  );

export default combineEpics(getMessagesEpic);
