import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getRelated } from "../utils";
import * as R from "ramda";

import {
  getWorkflowsCurrentData,
  getWorkflowsCurrentIncluded,
} from "../reducer";

const useWorkflow = () => {
  const workflowCurrentData = useSelector(getWorkflowsCurrentData);
  const workflowCurrentIncluded = useSelector(getWorkflowsCurrentIncluded);
  const [stagesOptions, setStagesOptions] = useState([]);

  useEffect(() => {
    if (workflowCurrentData?.id) {
      setWorkflowStagesOptions(workflowCurrentData, workflowCurrentIncluded);
    }
  }, [workflowCurrentData]);

  const setWorkflowStagesOptions = (workflow, stages) => {
    let relatedStages = getRelated(
      workflow.relationships.stages.data,
      stages
    );
    let workflowStages = relatedStages.map((stage) => ({
      variant: stage.attributes.sequence_id ? "ready" : "disabled",
      label: stage.attributes.name,
      sequence: stage.attributes.sequence_id,
      has_sequence_review: stage.meta.has_sequence_review
    }));
    setStagesOptions(workflowStages);
  };

  const hasSequence = () => {
    return stagesOptions.some((stage) => stage.sequence !== null);
  };

  return [
    workflowCurrentData,
    stagesOptions,
    hasSequence
  ];
};

export default useWorkflow;
