import React, { forwardRef, useState } from "react";
import theme from "../../../theme";
import { bool, element, func, string } from "prop-types";
import { color, typography, flex, layout } from "styled-system";
import styled, { keyframes, css } from "styled-components";

// Components
import Box from "../box";
import Search from "../icons/search";

const StyledContainer = styled(Box)`
  position: relative;
  width: auto;

  ${({ onClick }) => onClick && `cursor: pointer;`}
  ${flex}
  ${layout}
`;

const StyledIcon = styled.div`
  ${({ small }) =>
    small ? `height: 15px; width: 15px;` : `height: 23px; width: 23px;`}
  ${({ disabled }) =>
    disabled &&
    `&:hover {
      cursor: default;}`}
  ${({ iconBtn, disabled }) =>
    iconBtn &&
    !disabled &&
    `&:hover {
      cursor: pointer;
    }
  `}
`;

const StyledInputContainer = styled(Box)`
  background-color: ${({ disabled, theme: { colors } }) =>
    disabled ? theme.colors.lighterGrey : ""};
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
`;

const commonInputStyles = css`
  color: ${({ disabled, theme: { colors } }) => {
    if (disabled) {
      return theme.colors.lightGrey;
    }
    return theme.colors.grey;
  }};
  ${({ readOnly }) => readOnly && `cursor: inherit;`}
  background: transparent;
  border: 0;
  font-family: ${theme.fonts[1]};
  font-size: 1rem;
  display: block;
  width: 100%;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${({ error, disabled, theme: { colors } }) =>
      error
        ? theme.colors.red
        : disabled
        ? theme.colors.lightGrey
        : theme.colors.darkBlue};
    font-style: italic;
    font-size: 0.9rem;
  }
`;

const openAnimation = keyframes`
 0% { width: 0px; }
 100% { width: 300px; }
 `;

const closeAnimation = keyframes`
0% { width: 300px; }
100% { width: 0px; }
`;

const StyledInput = styled.input`
  ${commonInputStyles}
  height: auto;
  padding-top: 9px;
  padding-bottom: 10px;
  padding-left: 20px;

  border-bottom: 1px solid ${theme.colors.blue};
  width: 300px;

  &::-ms-clear {
    display: none;
  }

  ${(props) =>
    props.isOpen === true
      ? css`
          animation-name: ${openAnimation};
          animation-duration: 0.8s;
        `
      : css`
          animation-name: ${closeAnimation};
          animation-duration: 0.8s;
        `}

  ${color}
  ${typography}
`;

const propTypes = {
  disabled: bool,
  icon: element,
  iconBtn: bool,
  name: string,
  onChange: func,
  onClick: func,
  onEnter: func,
  onEsc: func,
  placeholder: string,
  type: string,
  value: string,
};

const InputSearch = forwardRef(
  function InputSearch(
    {
      disabled = false,
      icon,
      onClick,
      onEnter = () => null,
      onEsc,
      name,
      height,
      onChange = () => null,
      placeholder,
      type = "text",
      value = "",
      iconBtn,
      ...props
    },
    ref
  ) {
    const [isOpen, setState] = useState(value ? true : false);

    // This is here to avoid submitting form on Enter
    // (in case this component is inside a form)
    const handleKeyPressed = (event) => {
      if (!disabled) {
        switch (event.key) {
          case "Enter":
            if (onEnter && !event.shiftKey) {
              event.preventDefault();
              onEnter(event);
            }
            return;
          case "Escape":
            onEsc && onEsc();
            return;

          default:
        }
      }
    };

    const handleChange = (event) => (!disabled ? onChange(event) : null);
    const handleEnter = (event) => (!disabled ? onEnter(event) : null);

    return (
      <StyledContainer bg={"transparent"} onClick={onClick} {...props}>
        <StyledInputContainer disabled={disabled} py={0} icon={icon}>
          {isOpen ? (
            <StyledInput
              isOpen={isOpen}
              disabled={disabled}
              icon={icon}
              iconBtn={iconBtn}
              name={name}
              onChange={handleChange}
              onKeyDown={handleKeyPressed}
              onEnter={handleEnter}
              placeholder="Search..."
              ref={ref}
              type={type}
              value={value}
              autoFocus
            />
          ) : null}
          <StyledIcon
            onClick={() => setState(!isOpen)}
            iconBtn
            disabled={disabled}
          >
            <Search variant="blue" linkable />
          </StyledIcon>
        </StyledInputContainer>
      </StyledContainer>
    );
  }
);

InputSearch.propTypes = propTypes;

export default InputSearch;
