import React from "react";
import styled from "styled-components";
import theme from "../../../theme";

import Card from "../../ui/card/index";
import Notification from "../../ui/notification-button/index";
import Button from "../../ui/button/index";
import Flex from "../../ui/flex";

import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.css";

//Icons
import Close from "../../ui/icons/close";

const StyledBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99999;
`;

const StyledSimpleBar = styled(SimpleBar)`
  max-height: 60vh;
  width: 100%;
  margin-top: 15px;
  margin-right: 5px;

  .simplebar-scrollbar:before {
    background: ${theme.colors.grey};
  }
`;

const StyledCardContainer = styled.div`
  position: fixed;
  background: transparent;
  width: 60%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const NotificationsModal = ({ content, closeModal, openModal, invitations }) => {
  return (
    <>
      <Notification onClick={openModal} invitations={invitations} />
      <StyledBackground>
        <StyledCardContainer>
          <Card
            width="90%"
            fullHeader
            title="Notifications"
            icon={<Close variant="grey"></Close>}
            closeHandler={closeModal}
            footer
          buttons={[
            <Button key="close" variant="secondary" content="Close" onClick={closeModal} />,
          ]}
          >
            <StyledSimpleBar forceVisible="y" autoHide={false}>
              <Flex width="100%" justifyContent="space-evenly">
                {content}
              </Flex>
            </StyledSimpleBar>
          </Card>
        </StyledCardContainer>
      </StyledBackground>
    </>
  );
};

export default NotificationsModal;
