import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { push } from "connected-react-router";
import { bool, func } from "prop-types";
import styled, { keyframes, css } from "styled-components";
import theme from "../../../theme";

import { NavLink, Link } from "react-router-dom";
import { FeatureFlag } from "react-unleash-flags";
import { boxShadow, display, layout, space } from "styled-system";

import Flex from "../flex";
import Text from "../text";
import BreadCrumb from "../breadcrumbs";
import NavbarUserMenu from "../navbar-user-menu";
import Burger from "../icons/burger";
import InputSearchGlobal from "../input-search-global/index";
import Notification from "../../ui/notification-button/index";

import ArrowDown from "../icons/arrowDown";
import ArrowUp from "../icons/arrowUp";

//Actions
import { invitationsGet } from "../../../actions";

//Reducers
import {
  getCurrentProject,
  getCurrentWorkspace,
  getCurrentUser,
  getAuth,
  getPeoplePersonData,
  getAppNotifications,
} from "../../../reducer";

const NavContentFixed = styled.nav`
  align-items: center;
  background-color: white;
  display: flex;
  border-bottom: 2px solid ${theme.colors.red};
  justify-content: space-between;
  padding: 0px 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  ${boxShadow}
  ${layout}
  ${space}
`;

const IconBox = styled.div`
  width: 15px;
  height: 15px;
  margin-left: 10px;
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownContent = styled.div`
  width: 135px;
  height: 95px;
  position: absolute;
  background-color: white;
  border-radius: 0px 0px 3px 3px;
  box-shadow: 0px 2px 4px gray;
  padding: 15px 10px;
  z-index: 99999;
  margin: 20px -23px;

  ${(props) => (props.isOpen === true ? "display: block; " : "display: none;")};
`;

const NavContent = styled.nav`
  align-items: center;
  background-color: white;
  display: flex;
  border-bottom: 2px solid ${theme.colors.red};
  justify-content: space-between;
  padding-left: ${({ theme }) => theme.sidebar.width};
  position: absolute;
  top: 0;
  left: 0;
  /*  min-width: 1280px; */
  width: 100%;
  z-index: 10;
  ${boxShadow}
  ${layout}
  ${space}
`;

const ExternalLink = styled.a`
  align-items: center;
`;

const NavItem = {
  mdHeight: `64px`,
  mdWidth: `94px`,
};

const NavOption = styled((props) =>
  props.to ? (
    <NavLink
      activeClassName={"navlink-active"}
      height={{ md: `${NavItem.mdHeight}` }}
      width={{ md: `${NavItem.mdWidth}` }}
      flexDirection="row"
      {...props}
    />
  ) : props.href ? (
    <ExternalLink
      height={{ md: `${NavItem.mdHeight}` }}
      width={{ md: `${NavItem.mdWidth}` }}
      {...props}
    />
  ) : (
    <Text
      height={{ md: `${NavItem.mdHeight}` }}
      width={{ md: `${NavItem.mdWidth}` }}
      pointer={true}
      {...props}
    />
  )
)`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 10px;
  padding: 0 8px;
  text-decoration: none;
  &:last-child {
    margin-right: 0;
  }
  &.navlink-active {
    .paintable {
      fill: ${({ theme }) => theme.colors.blue};
    }
  }
  ${layout}
`;

const NavSubmenu = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 10px;
  padding: 0 8px;
  text-decoration: none;

  & :hover {
    cursor: pointer;
  }
`;

const NavLogo = () => (
  <Link className="logo-nav" to={"/"} style={{ textDecoration: "none" }}>
    <Flex
      backgroundColor="transparent"
      alignItems={"center"}
      pointer={true}
      display={"flex"}
    >
      <img width={"158px"} src="/images/TeamSourced.svg" />
    </Flex>
  </Link>
);

const NavText = styled.p`
  margin: ${(props) => (props.submenu ? "8px 0px" : "0")};
  color: ${(props) =>
    props.active
      ? `${theme.colors.red}`
      : props.onMenu
        ? `${theme.colors.white}`
        : `${theme.colors.darkGrey}`};
  font-size: 1rem;
  font-weight: 700;
  ${({ variant }) =>
   variant === "tertiary" &&
   `color: ${theme.colors.blueTertiary};`
  }
  &:hover {
    
  }
`;

const StyledButton = styled.a`
  font-family: inherit;
  text-decoration: none;
  font-size: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  padding: 7px 9px;
  margin: 5px 10px;
  border-radius: 5px;
  font-weight: 700;
  border: none;
  color: ${theme.colors.white};
  background-color: ${theme.colors.blue};
  ${({ outline }) =>
    outline &&
    `background-color: ${theme.colors.white};
     border: 1px solid ${theme.colors.orange3};
     color: ${theme.colors.orange3};`}
  &:hover {
    opacity: .9;
    cursor: pointer;
  }
`;

const StyledIcon = styled.div`
  height: 30px;
  width: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${({ disabled }) =>
    disabled &&
    `&:hover {
      cursor: default;}`}
  ${({ iconBtn, disabled }) =>
    iconBtn &&
    !disabled &&
    `&:hover {
      cursor: pointer;
    }
  `}
`;

const ShowListAnimation = keyframes`
 0% { transform: translateY(0%); opacity: 0; }
 50% { transform: translateY(80%); opacity: 0.5; }
 100% {transform: translateY(0%); opacity: 1 }
 `;

const ShowMenuAnimation = keyframes`
 from { transform: translateY(-100%); opacity: 0; }
 to {transform: translateY(0%);  }
 `;

const HideMenuAnimation = keyframes`
 from {transform: translateY(0%);  }
 to { transform: translateY(-100%); opacity: 0; }
`;

const BurgerToggle = styled.input`
  position: absolute;
  appearance: none;
  opacity: 0;
`;

const Line = styled.div`
  position: absolute;
  left: 25%;
  width: 50%;
  height: 3px;
  background: hsla(210, 29%, 24%, 1);
  border-radius: 10px;
  overflow: hidden;
  transition: 0.5s;
  z-index: 9999999;

  &::after {
    ${(props) => (props.isChecked ? "transform: translateX(0)" : null)};
  }

  &:nth-child(1) {
    top: 30%;
    ${(props) =>
    props.isChecked
      ? `transform: translateY(calc(var(--burger-menu-radius) / 5)) rotate(45deg); background: rgb(250, 250, 250);`
      : null};
  }

  &:nth-child(2) {
    top: 50%;
    ${(props) => (props.isChecked ? `transform: scaleX(0)` : null)};
  }

  &:nth-child(3) {
    top: 70%;
    ${(props) =>
    props.isChecked
      ? `transform: translateY(calc(var(--burger-menu-radius) / -5)) rotate(-45deg); background: rgb(250, 250, 250);`
      : null};
  }
`;

const BurgerMenu = styled.label`
  --burger-menu-radius: 2.5em;
  position: fixed;
  top: 10px;
  right: 7px;
  z-index: 100;
  display: block;
  width: var(--burger-menu-radius);
  height: var(--burger-menu-radius);
  outline: none;
  cursor: pointer;

  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--primary-color-darker);
    transform: translateX(-100%);
    transition: 0.25s;

    @for $i from 2 through 3 {
      &:nth-child(#{$i})::after {
        transition-delay: 0.1s * ($i - 1);
      }
    }
  }

  &:hover {
    *::after {
      transform: translateX(0);
    }
  }
`;

const Menu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1a1e23;
  overflow-x: hidden;
  animation: ${ShowMenuAnimation} 0.9s;

  ${(props) =>
    props.isChecked
      ? `opacity: 0.9; visibility: visible;`
      : ` opacity: 0; visibility: hidden;`}

  @include sp-layout {
    display: block;
  }
`;

const MenuInner = styled.div`
  list-style-type: none;
  opacity: 1;
`;

const MenuNav = styled.ul`
  display: flex;
  flex-wrap: wrap;
  opacity: 1;
  flex-direction: column;
  margin: 0;
  padding: 0;
  text-align: center;
  list-style-type: none;

  @include sp-layout {
    flex-direction: column;
  }
`;

const MenuNavItem = styled.li`
  flex: 1;
`;

const MenuNavLink = styled.a`
  position: relative;
  display: inline-flex;
  text-decoration: none;

  span {
    div {
      color: white;
      display: flex;
      margin: 10px 0px;
      animation-delay: 2s;
      animation: ${ShowListAnimation} 1.7s;
    }
  }
`;

const propTypes = {
  handleNavItemClick: func,
  handleProfileItemClick: func,
  handleSignOutClick: func,
  isAdmin: bool,
};

export const UnauthenticatedNavbar = ({
  handleNavItemClick,
  handleProfileItemClick,
  handleSignOutClick,
  location,
  viewport,
  isAdmin = false,
}) => {
  const dispatch = useDispatch();
  const [servicesMenuOpen, setServicesMenuOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleCheckbox = (event) => {
    setIsMenuOpen(event.target.checked);
  };

  const openLink = (path) => {
    dispatch(push(path));
  };

  const handleJoinNow = () => {
    openLink(`/sign_up`);
  };

  useEffect(() => {
    console.log("state:", isMenuOpen);
  }, [isMenuOpen]);

  /*  // profile dropdown menu options
  const menuOptions = [
    {
      id: "profile",
      label: "My Profile",
      onClick: handleProfileItemClick,
      to: "/profile"
    },
    {
      id: "settings",
      admin: true,
      label: "Settings",
      onClick: handleNavItemClick,
      to: "/settings"
    },
    {
      id: "logout",
      label: "Logout",
      onClick: handleSignOutClick
    }
  ];

  // admin filtered profile dropdown menu options
  const options = menuOptions.filter(
    option => !option.admin || (option.admin && isAdmin)
  );
 */

  /* user = {id:"1", color: "blue", firstName:"Daniela", lastName: "Reina" */

  return (
    <>
      {/* ------ nav main content ------ */}
      <NavContentFixed
        boxShadow={5}
        height={NavItem.mdHeight}
        padding={{ sm: "0px 10px", md: "0px 20px", lg: "0px 35px" }}
      >
        <NavLogo />
        {viewport > 800 ? (
          <Flex
            alignItems={"center"}
            justifyContent={{ sm: "space-around", md: "space-between" }}
            width={{ sm: "auto", md: "auto" }}
            height="60px"
          >
            <DropdownContainer>
              <NavOption to="/services">
                <NavText>We Recruit for You</NavText>
              </NavOption>
            </DropdownContainer>

            <StyledButton onClick={handleJoinNow} outline>
              Get Started Now
            </StyledButton>
            <NavOption to={"/sign_in"}>
              <NavText variant="tertiary">Login</NavText>
            </NavOption>
          </Flex>
        ) : (
          <>
            <BurgerToggle
              type="checkbox"
              id="burger-toggle"
              onClick={(event) => handleCheckbox(event)}
            />
            <BurgerMenu for="burger-toggle">
              <Line isChecked={isMenuOpen}></Line>
              <Line isChecked={isMenuOpen}></Line>
              <Line isChecked={isMenuOpen}></Line>
            </BurgerMenu>
            {isMenuOpen && (
              <Menu isChecked={isMenuOpen}>
                <MenuInner>
                  <MenuNav>
                    <MenuNavItem>
                      <MenuNavLink href="https://calendly.com/teamsourced/learn-about-teamsourced">
                        <span>
                          <div>
                            <NavText onMenu submenu>
                              NEED HELP HIRING?
                            </NavText>
                          </div>
                        </span>
                      </MenuNavLink>
                    </MenuNavItem>
                    <MenuNavItem>
                      <MenuNavLink>
                        <span>
                          <div>
                            <NavText
                              onMenu
                              submenu
                              active={location === "/sign_up"}
                            >
                              GET STARTED NOW
                            </NavText>
                          </div>
                        </span>
                      </MenuNavLink>
                    </MenuNavItem>
                    <MenuNavItem>
                      <MenuNavLink>
                        <span>
                          <div>
                            <NavText
                              onMenu
                              submenu
                              active={location === "/sign_in"}
                            >
                              LOGIN
                            </NavText>
                          </div>
                        </span>
                      </MenuNavLink>
                    </MenuNavItem>
                    <MenuNavItem>
                      {location === "/" && (
                        <MenuNavLink href="mailto:info@sourcedllc.com">
                          <span>
                            <div>
                              <NavText onMenu>CONNECT WITH US</NavText>
                            </div>
                          </span>
                        </MenuNavLink>
                      )}
                      {location === "/services" && (
                        <MenuNavLink href="https://calendly.com/sourced/intro">
                          <span>
                            <div>
                              <NavText onMenu>CONNECT WITH US</NavText>
                            </div>
                          </span>
                        </MenuNavLink>
                      )}
                    </MenuNavItem>
                  </MenuNav>
                </MenuInner>
              </Menu>
            )}
          </>
        )}
      </NavContentFixed>

      {/* ------ nav main content ------ */}
    </>
  );
};

UnauthenticatedNavbar.propTypes = propTypes;

export const AuthenticatedNavbar = ({
  activeSection,
  handleProfileItemClick,
  location,
  handleSignOutClick,
  isAdmin = false,
}) => {
  // profile dropdown menu options
  const user = useSelector(getCurrentUser);
  const auth = useSelector(getAuth);
  const person = useSelector(getPeoplePersonData);
  const dispatch = useDispatch();
  const currentWorkspace = useSelector(getCurrentWorkspace);
  const currentProject = useSelector(getCurrentProject);
  const invitations = useSelector(getAppNotifications);
  const [workspaceItem, setWorkspaceItem] = useState("");
  const [projectItem, setProjectItem] = useState(null);

  useEffect(() => {
    let workspace = "";
    let project = "";

    if (currentWorkspace?.id) {
      workspace = currentWorkspace.attributes.name;
    }
    if (currentProject?.id) {
      project = currentProject.attributes.name;
    }

    setWorkspaceItem(workspace);
    setProjectItem(project);
  }, [currentWorkspace, currentProject]);

  const openLink = (path) => {
    dispatch(push(path));
  };

  const openModal = (e) => {
    dispatch(invitationsGet());
  };

  const handleSettings = () => {
    openLink(`/account_settings`);
  };

  const options = [
    {
      id: "settings",
      /* admin: true, */
      label: "Settings",
      /* onClick: handleNavItemClick, */
      callback: handleSettings,
    },
    /*   {
      id: "profile",
      label: "My Profile",
      to: "/profile",
    }, */
    {
      id: "logout",
      label: "Logout",
      callback: handleSignOutClick,
    },
    {
      id: "extension",
      label: "Chrome Extension",
      callback: () =>
        window.open(
          "https://chrome.google.com/webstore/detail/sourced/pnjhmpllnbbpdigilcceijoeoakmfdpd?hl=en"
        ),
    },
    {
      id: "privacy_policy",
      label: "Privacy Policy",
      callback: () => openLink("/privacy_policy"),
    },
  ];

  /* // admin filtered profile dropdown menu options
  const options = menuOptions.filter(
    option => !option.admin || (option.admin && isAdmin)
  ); */

  return (
    <>
      {/* ------ nav main content ------ */}
      <NavContent
        boxShadow={5}
        height={NavItem.mdHeight}
        pr={{ md: "24px" }}
        justifyContent="flex-end"
      >
        <Flex
          alignItems={"center"}
          justifyContent="flex-start"
          width={{ sm: 1 }}
          backgroundColor="none"
          pl="30px"
        >
          <BreadCrumb
            workspaceName={workspaceItem}
            projectName={projectItem}
            person={person}
          />
        </Flex>
        <Flex alignItems={"center"} justifyContent="flex-end" width={{ sm: 1 }}>
          {/* <Button variant="primaryLine" content="More Talent" /> */}
          <InputSearchGlobal />
          <Notification onClick={openModal} invitations={invitations} />
          <NavbarUserMenu
            auth={auth}
            user={user}
            options={options}
            resource={user}
          />
        </Flex>
      </NavContent>
      {/* ------ nav main content ------ */}
    </>
  );
};

AuthenticatedNavbar.propTypes = propTypes;
