import React from "react";
import styled from "styled-components";
import theme from "../../../theme";
import { variant, flex, layout } from "styled-system";

// Components
import Flex from "../flex";
// import Time from "../icons/waiting";
import Alert from "../icons/bell";

const StyledContainer = styled(Flex)`
  position: relative;
  align-items: center;
  width: auto;
  margin: 0px 15px;
  background-color: transparent;
  ${({ onClick }) => onClick && `cursor: pointer;`}
  ${flex}
  ${layout}
`;

const IconContainer = styled.div`
  width: 30px;
  height: 30px;
`;

const StyledCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: ${theme.colors.darkBlue};
  color: white;
  font-size: 1rem;
`;

const Notifications = ({ invitations, onClick }) => {
  return (
    <StyledContainer onClick={onClick}>
      <IconContainer>
        <Alert variant="grey" linked />
      </IconContainer>
      {invitations.length > 0 && (
        <StyledCircle>{invitations.length}</StyledCircle>
      )}
    </StyledContainer>
  );
};

export default Notifications;
