import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import styled from "styled-components";
import theme from "@theme";
import { FeatureFlag } from "react-unleash-flags";
import moment from "moment";
import DOMPurify from 'dompurify';
import * as R from "ramda";

//components
import Box from "@ui/box";
import Flex from "@ui/flex";
import Card from "@ui/card/index";
import WorkSpaceAvatar from "@ui/avatar";
import Picture from "@ui/picture/index";
import Image from "@assets/img/avatar.png";
import Text from "@ui/text";

//icons
import Location from "@ui/icons/location";
import Phone from "@ui/icons/phone";
import CalendarLast from "@ui/icons/calendarLast";
import Degree from "@ui/icons/degree";
import Mail from "@ui/icons/message";
import Twitter from "@ui/icons/twitter";
import Linkedin from "@ui/icons/linkedin";
import Facebook from "@ui/icons/facebook";
import ArrowLeft from "@ui/icons/arrowLeftSimple";
import Valid from "@ui/icons/statActive";
import Null from "@ui/icons/statInactive";

import { personLocation } from "@utils/people-helper";

const SocialLink = styled.a`
  height: 20px;
  width: 20px;
  margin: 0px 4px;
  text-decoration: none;
  color: ${theme.colors.blue};
`;

const Divider = styled.div`
  height: 5px;
  width: 100%;
  border-bottom: 1px solid ${theme.colors.darkBlue};
  margin-bottom: 20px;
`;

const StyledTitle = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.5rem;
  width: 100%;
  margin: 10px 0px;
  color: ${theme.colors.blue};

  & > div {
    margin: 0px 4px;
    text-transform: capitalize;
  }
`;

const StyledBody = styled.div`
  width: auto;
  font-size: 0.9rem;
  font-weight: 200;
  margin: 5px 10px;
  padding: 3px 5px;
  color: ${theme.colors.grey};

  ${(props) => (props.withWhiteSpace ? "white-space: pre-line" : null)}
`;

const StyledLabel = styled(Text)`
  font-size: 0.8rem;
  color: ${theme.colors.lightGrey};
  font-weight: 200;
  width: 50px;
  text-align: right;
`;

const StyledInfo = styled(Text)`
  font-size: 0.9rem;
  font-weight: 300;
  margin: 5px 10px;
  padding: 3px 5px;
`;

const PersonData = styled(Text)`
  margin: 12px;
  align-items: center;
  font-size: 0.9rem;
  display: flex;
  flex-direction: row;
  color: ${theme.colors.gray};
  font-family: Open Sans;
  flex-flow: nowrap;
`;

const StyledNavLink = styled(Text)`
  color: ${theme.colors.red};
  font-family: Open Sans;
  font-size: 0.7rem;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
`;

const ProjectRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-flow: nowrap;
  font-size: 0.95rem;
  color: ${theme.colors.gray};
  width: 100%;
  margin: 7px 0px;
`;

const ProjectName = styled.div`
  display: flex;
  width: 65%;
  flex-direction: row;
  justify-content: flex-start;
  flex-flow: nowrap;
  color: ${theme.colors.blue};

  &:hover {
    text-shadow: 0px 3px 3px ${theme.colors.lightBlue};
    cursor: pointer;
  }
`;

const ProjectStatus = styled.div`
  display: flex;
  flex-direction: row;
  width: 30%;
  justify-content: flex-end;
  flex-flow: nowrap;
`;

const StyledText = styled.div`
  white-space: pre-line;
  margin-right: 5px;
  align-items: center;
  font-weight: 300;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  color: ${theme.colors.grey};
  line-height: 1.5rem;
`;

const StyledIcon = styled.div`
  height: 22px;
  width: 22px;
  margin-left: 10px;
`;

const StyledSubtitle = styled("p")`
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
  font-weight: 400;
  margin: 5px 0px;
  color: ${theme.colors.grey};
`;

const StyledDateText = styled("p")`
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
  color: ${theme.colors.lightGrey};
  margin: 2px 0px;
`;

const Profile = ({ person, messages, location, viewport, props }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const RedirectPeople = (e) => {
    e.stopPropagation();
    history.goBack();
  };

  const openLink = (path) => {
    dispatch(push(path));
  };

  const handleWorkspace = (workspace_id) => {
    openLink(`/workspaces/${workspace_id}/home`);
  };

  const handleProject = (workspace_id, project_id) => {
    openLink(`/workspaces/${workspace_id}/project/${project_id}`);
  };

  const handleWorkspaceName = (id) => {
    let workspaceName = null;
    let workspace = person.meta.related_workspaces.find(
      (item) => item.id === id
    );
    if (workspace) {
      workspaceName = workspace.name.charAt(0) + workspace.name.charAt(1);
    }

    return workspaceName;
  };

  const handleProjectName = (id) => {
    let projectName = null;
    let project = person.meta.related_projects.find((item) => item.id === id);
    if (project) {
      projectName = project.name;
    }

    return projectName;
  };

  return (
    <Flex
      paddingTop="64px"
      alignItems="center"
      height="auto"
      width="100%"
      flexDirection="column"
      justifyContent="flex-start"
      backgroundColor={theme.colors.lighterGrey}
    >
      <Flex
        flexDirection="column"
        alignItems="left"
        justifyContent="center"
        height="53px"
        width="100%"
      >
        <StyledNavLink onClick={RedirectPeople}>
          <ArrowLeft width="6px" variant="red" style={{ marginRight: "3px" }} />
          Back to People
        </StyledNavLink>
      </Flex>
      <Flex
        marginTop="2%"
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="space-between"
        width={viewport > 2000 ? "85%" : "95%"}
        backgroundColor={theme.colors.lighterGrey}
      >
        <Flex
          width="70%"
          flexDirection="column"
          backgroundColor={theme.colors.lighterGrey}
        >
          <Card>
            <Flex
              padding="10px 15px"
              width="100%"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Flex alignItems="center" width="30%">
                <Box>
                  <Picture
                    size="big"
                    bgImage={person.attributes.avatar_url || Image}
                  ></Picture>
                </Box>
                <Box>
                  <Text
                    minWidth="250px"
                    color={theme.colors.gray}
                    fontSize="1.2rem"
                    fontFamily="Open Sans"
                    wrap="true"
                  >
                    {person.attributes.first_name +
                      " " +
                      person.attributes.last_name}
                  </Text>
                </Box>
              </Flex>
              <Box width="30%">
                <PersonData icon={<Location variant="blue" />}>
                  {personLocation(person)}
                </PersonData>
                <PersonData icon={<Phone variant="blue" />}>
                  {person.attributes.phone}
                </PersonData>
                <PersonData icon={<Mail variant="blue" />}>
                  {person.attributes.email}
                </PersonData>
              </Box>
              <Box width="30%">
                <PersonData icon={<CalendarLast variant="blue" />}>
                  {person.attributes.company} - {person.attributes.title}
                </PersonData>
                <PersonData icon={<Degree variant="blue" />}>
                  {person.attributes.degree}
                </PersonData>
                <Flex margin="12px 10px 0px 7px">
                  <SocialLink
                    target="_blank"
                    href={person.attributes.linkedin}
                    title={person.attributes.linkedin}
                    hidden={!person.attributes.linkedin}
                  >
                    <Linkedin variant="blue" linkable />
                  </SocialLink>

                  <SocialLink
                    target="_blank"
                    href={person.attributes.twitter}
                    title={person.attributes.twitter}
                    hidden={!person.attributes.twitter}
                  >
                    <Twitter variant="blue" linkable />
                  </SocialLink>

                  <SocialLink
                    target="_blank"
                    href={person.attributes.facebook}
                    title={person.attributes.facebook}
                    hidden={!person.attributes.facebook}
                  >
                    <Facebook variant="blue" linkable />
                  </SocialLink>
                </Flex>
              </Box>
            </Flex>
          </Card>

          <Card>
            <Flex width="100%" justifyContent="space-evenly" flexDirection="column" padding="30px">
              <StyledTitle>About (Linkedin)</StyledTitle>
              <Box margin="10px" marginBottom="15px">
                <StyledText>{person.attributes.about || "-"}</StyledText>
              </Box>

              <StyledTitle>Experience (Linkedin)</StyledTitle>
              <Box margin="10px" marginBottom="15px">
                {R.isEmpty(person.attributes.experience) ?
                  <StyledText> - </StyledText>
                  : person.attributes.experience.map((experience) =>
                    <Box marginBottom="15px" key="experience">
                      <StyledSubtitle>· {experience['company']}</StyledSubtitle>
                      <StyledText>{experience['title']}</StyledText>
                      <StyledText>{experience['location']}</StyledText>
                      <StyledDateText>{experience['period']}</StyledDateText>
                      {experience['jobs']?.map((job) =>
                        <>
                          <StyledText>{job['title']}</StyledText>
                          <StyledText>{job['location']}</StyledText>
                          <StyledDateText>{job['period']}</StyledDateText>
                        </>
                      )}
                    </Box>
                  )
                }
              </Box>

              <Box width="95%">
                <StyledTitle>Education (Linkedin)</StyledTitle>
                <Box margin="10px" marginBottom="15px">
                  {R.isEmpty(person.attributes.education) ?
                    <StyledText> - </StyledText>
                    : person.attributes.education.map((education) =>
                      <Box marginBottom="15px" key="education">
                        <StyledSubtitle>· {education['title']}</StyledSubtitle>
                        <StyledText>{education['description']}</StyledText>
                        <StyledDateText>{education['period']}</StyledDateText>
                      </Box>
                    )
                  }
                </Box>
              </Box>


            </Flex>

          </Card>

          {location !== `/profile/${person.id}` ? (
            <Card header title="Messages">
              <Flex flexDirection="column" alignItems="start" width="100%">
                <FeatureFlag name="feature_sequence">
                  {messages.length > 0 ? (
                    <Flex
                      flexDirection="column"
                      alignItems="start"
                      width="100%"
                      padding="20px"
                    >
                      {messages.map((message, index) => (
                        <Box
                          key={message.id}
                          flexDirection="column"
                          alignItems="start"
                          width="100%"
                        >
                          {message.attributes.type === "Message::Out" && (
                            <>
                              <Flex width="100%" flexDirection="column">
                                <StyledTitle>
                                  {`Project ${handleProjectName(
                                    message.attributes.project_id
                                  )}`}
                                  <div>
                                    {" - "} {message.attributes.status}
                                  </div>
                                  on{" "}
                                  {moment(
                                    message.attributes.created_at
                                  ).calendar()}
                                </StyledTitle>
                                <Flex alignItems="center">
                                  <StyledLabel>From:</StyledLabel>
                                  <StyledInfo>
                                    {message.attributes.from}
                                  </StyledInfo>
                                </Flex>
                                <Flex alignItems="center">
                                  <StyledLabel>To:</StyledLabel>
                                  <StyledInfo>
                                    {message.attributes.to}
                                  </StyledInfo>
                                </Flex>
                                <Flex alignItems="center">
                                  <StyledLabel>Subject: </StyledLabel>
                                  <StyledInfo>
                                    {message.attributes.subject}
                                  </StyledInfo>
                                </Flex>
                              </Flex>
                              <Flex padding="10px 0px">
                                <StyledLabel>Body:</StyledLabel>

                                <StyledBody
                                  dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(message.attributes.body_untracked),
                                  }}
                                />
                              </Flex>
                              <Divider />
                            </>
                          )}
                          {message.attributes.type === "Message::In" && (
                            <>
                              <Flex width="100%" flexDirection="column">
                                <StyledTitle>
                                  {`Project ${handleProjectName(
                                    message.attributes.project_id
                                  )}`}
                                  - Replied on{" "}
                                  {moment(
                                    message.attributes.created_at
                                  ).calendar()}
                                </StyledTitle>
                                <Flex alignItems="center">
                                  <StyledLabel>From:</StyledLabel>
                                  <StyledInfo>
                                    {message.attributes.from}
                                  </StyledInfo>
                                </Flex>
                                <Flex alignItems="center">
                                  <StyledLabel>To:</StyledLabel>
                                  <StyledInfo>
                                    {message.attributes.to}
                                  </StyledInfo>
                                </Flex>
                                <Flex alignItems="center">
                                  <StyledLabel>Subject: </StyledLabel>
                                  <StyledInfo>
                                    {message.attributes.subject}
                                  </StyledInfo>
                                </Flex>
                              </Flex>
                              <Flex padding="10px 0px">
                                <StyledLabel>Body:</StyledLabel>

                                <StyledBody
                                  withWhiteSpace
                                  dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(message.attributes.body_untracked),
                                  }}
                                />
                              </Flex>
                              <Divider />
                            </>
                          )}
                        </Box>
                      ))}
                    </Flex>
                  ) : (
                    <Flex
                      width="100%"
                      justifyContent="center"
                      alignItems="center"
                      margin="20px 0px"
                      minHeight="200px"
                    >
                      <Text color={theme.colors.lightGrey}>No Messages</Text>
                    </Flex>
                  )}
                </FeatureFlag>
              </Flex>
            </Card>
          ) : (
            <Card header title="Summary">
              <Flex
                width="100%"
                justifyContent="center"
                alignItems="center"
                margin="20px 0px"
                minHeight="200px"
              >
                <Text color={theme.colors.lightGrey}>No summary</Text>
              </Flex>
            </Card>
          )}
        </Flex>
        <Flex width="28%" backgroundColor={theme.colors.lighterGrey}>
          <Card header title="Projects">
            {person.meta.related_projects.length > 0 ? (
              <Flex
                flexDirection="column"
                alignItems="start"
                width="100%"
                minHeight="440px"
                padding="20px"
              >
                {person.meta.related_projects.map((project) => (
                  <Box
                    key={project.id}
                    flexDirection="column"
                    alignItems="start"
                    width="100%"
                  >
                    <ProjectRow>
                      <WorkSpaceAvatar
                        size="small"
                        variant="blue"
                        onClick={() => handleWorkspace(project.workspace_id)}
                        content={handleWorkspaceName(project.workspace_id)}
                      />
                      <ProjectName
                        onClick={() =>
                          handleProject(project.workspace_id, project.id)
                        }
                      >
                        {project.name}
                      </ProjectName>
                      <ProjectStatus>
                        <StyledText>{project.status}</StyledText>
                        <StyledIcon>
                          {project.status === "Active" ? <Valid /> : <Null />}
                        </StyledIcon>
                      </ProjectStatus>
                    </ProjectRow>
                  </Box>
                ))}
              </Flex>
            ) : (
              <Flex
                flexDirection="column"
                alignItems="center"
                width="100%"
                padding="20px"
              >
                No Project
              </Flex>
            )}
          </Card>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Profile;
