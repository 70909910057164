import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import theme from "../../../theme";

import Box from "../../ui/box";
import Flex from "../../ui/flex";
import Input from "../../ui/input/index";
import Row from "../../ui/rowCollapsable";
import Text from "../../ui/text";
import Remove from "../../ui/icons/trash";

import Dropdown from "../../ui/dropdown";

const StyledTitle = styled(Text)`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  width: 60%;
`;

const StyledTextBox = styled.div`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  align-items: center;
  width: 30%;
`;

const StyledLabel = styled.span`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  font-size: 0.8rem;
  color: ${theme.colors.lightGrey};
  padding-right: 10px;
  white-space: nowrap;
`;

const StyledInfo = styled(Text)`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  color: ${theme.colors.darkBlue};
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: "...";

  font-size: 1rem;
`;

const StyledLinks = styled.a`
  margin-left: 5px;
  font-size: 0.7rem;
  text-decoration: none;
  color: ${theme.colors.darkBlue};
  border-bottom: 1px solid ${theme.colors.darkBlue};
  margin-right: 15px;
`;

const RowWorkflow = ({
  workspaceId, // new sequence button href
  onClick, //click on row handler
  isActive, // bool - active row
  handleIcon, // handle onClick icon - remove row
  sequenceDropdownHandler, // handle dropdown related to sequence - need stage id
  stage, // stage data
  onChange, // handle inputChange
  register, // validation
  errors, // validation
  setValue, //validation
  showIcon, //bool - show remove icon when there are more than 3 stages
  id, // stage index - add sequence info inside dirtyChanges.stages
  sequences,
  stages,
  handleCleanAction, //handle clean Action Switch
  asyncReload,
}) => {

  let stagesOnResponse = () => {
    if (stage.sequence) {
      return stages.filter((s) => s !== stage && s.name).map((s) => s["name"]);
    }
  };

  return (
    <Row
      onClick={() => {
        onClick();
      }}
      error={errors[`stage-${id}`] || errors[`stage-${id}-sequence`]}
      active={isActive}
      flat={!isActive}
      icon={showIcon && <Remove variant="red" linkable />}
      handleIcon={handleIcon}
      handleCleanAction={(e, index) => handleCleanAction(e, index)}
      open={!!stage.sequence_id}
      rowContent={
        <Box width="70%" height="auto">
          <Input
            small
            hidden={!isActive}
            {...register(`stage-${id}`, { required: true })}
            error={errors[`stage-${id}`] ? true : false}
            required
            label="Name"
            showCharLimitCount
            maxLength="40"
            name={`stage-${id}`}
            value={stage.name}
            onChange={onChange}
            placeholder={`Set Stage ${id + 1} Name`}
            tabIndex={id + 2}
          />
          <Flex alignItems="center">
            <StyledTitle
              hidden={isActive}
              color={
                errors[`stage-${id}`]
                  ? theme.colors.lightRed
                  : theme.colors.blue
              }
              fontFamily="Open Sans"
              fontSize="1.2rem"
            >
              {stage.name ? stage.name : "Stage Name"}
            </StyledTitle>

            <StyledTextBox
              hidden={isActive}
              color={theme.colors.blue}
              fontFamily="Open Sans"
              fontSize="1.1rem"
            >
              {stage.sequence ? (
                <>
                  <StyledLabel>Sequence</StyledLabel>
                  <StyledInfo>{stage.sequence}</StyledInfo>
                </>
              ) : null}
            </StyledTextBox>
            <StyledTextBox
              hidden={isActive}
              color={theme.colors.blue}
              fontFamily="Open Sans"
              fontSize="1.1rem"
            >
              {stage.on_response_stage ? (
                <>
                  <StyledLabel>On response</StyledLabel>
                  <StyledInfo>{stage.on_response_stage}</StyledInfo>
                </>
              ) : null}
            </StyledTextBox>
          </Flex>
        </Box>
      }
      bodyContent={
        <>
          <Box width="45%" height="115px">
            <Dropdown
              id={id}
              {...register(`stage-${id}-sequence`, {
                required: (stage["sequence"] !== undefined)? true : false
              })}
              required
              error={errors[`stage-${id}-sequence`] ? true : false}
              errorMsg="This field is required"
              asyncReload={asyncReload}
              name={`stage-${id}-sequence`}
              value={stage.sequence}
              sequenceDropdownHandler={sequenceDropdownHandler}
              handleChange={(val) => {
                setValue(`stage-${id}-sequence`, val);
              }}
              formStyle
              variant="blue"
              options={sequences?.data.map(
                (sequence) => sequence.attributes.name
              )}
              label="Sequence"
              mb={2}
              placeholder="Select Sequence"
              short="true"
            />
            <StyledLinks
              target="_blank"
              href={`/workspaces/${workspaceId}/sequences`}
            >
              New Sequence
            </StyledLinks>
          </Box>
          <Box width="45%" height="115px">
            <Dropdown
              id={id}
              name="on_response_stage"
              value={stage.on_response_stage}
              sequenceDropdownHandler={sequenceDropdownHandler}
              handleChange={(val) => {
                setValue("on_response_stage", val);
              }}
              formStyle
              variant="blue"
              options={stagesOnResponse()}
              label="On response"
              mb={2}
              placeholder="Set action on response"
              short
            />
          </Box>
        </>
      }
    ></Row>
  );
};

export default RowWorkflow;
