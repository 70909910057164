import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//Component
import { People } from "../../components/domain/people";

import Spinner from "../../components/ui/spinner";
import Selector from "../../components/ui/selector/index";

//Actions
import {
  talentPoolGet,
  projectsGet,
  talentPoolPageGet,
  peopleToProjectAssign,
  talentPoolPersonUpdate,
  currentWorkspaceClean,
  currentProjectClean,
} from "../../actions";

import {
  getPeopleCurrentPage,
  getPeopleTotalPages,
  getPeople,
  getProjects,
  getLimit,
} from "../../reducer/";

import { useWorkspace } from "@hooks";

const TalentPoolContainer = ({ viewport, ...props }) => {
  const people = useSelector(getPeople);
  const limit = useSelector(getLimit);
  const projects = useSelector(getProjects);

  const currentPage = useSelector(getPeopleCurrentPage);
  const totalPages = useSelector(getPeopleTotalPages);

  const dispatch = useDispatch();
  const [dirtyChanges, setDirtyChanges] = useState({});

  const [peopleCheckboxStatus, setPeopleCheckboxStatus] = useState({});
  const [totalSelected, setTotalSelected] = useState(0);
  const [assignTo, setAssignTo] = useState({});
  const [isAssignActive, setIsAssignActive] = useState(false);
  const [checkAllState, setCheckAllState] = useState(false);

  const [isEditActive, setIsEditActive] = useState(false);
  const [activePerson, setActivePerson] = useState({});
  const [searchBy, setSearchBy] = useState("");
  const [workspaces, currentWorkspace, visiblesWorkspaces, workspacesByAccess] = useWorkspace();
  const orderedWorkspaces = workspacesByAccess?.owner?.concat(workspacesByAccess.guest);

  const handleChangeSearchBy = (event) => {
    setSearchBy(event.target.value);
  };

  const handleEnterSearchBy = () => {
    dispatch(
      talentPoolGet({
        search_by: searchBy,
        limit: limit,
      })
    );
  };

  const handleDropdownSelect = (name, value) => {
    setDirtyChanges({
      ...dirtyChanges,
      [name]: value,
    });
  };

  const openAssignModalHandler = () => {
    setIsAssignActive(true);
  };

  const closeAssignModalHandler = () => {
    setIsAssignActive(false);
  };

  const handleSubmitAssign = () => {
    let selectedPeople = [];
    for (const id in peopleCheckboxStatus) {
      if (peopleCheckboxStatus[id]) {
        selectedPeople.push({ id: id });
      }
    }

    dispatch(
      peopleToProjectAssign({
        workspace_id: assignTo.workspace,
        project_id: assignTo.project,
        people_ids: selectedPeople,
      })
    );
    setIsAssignActive(false);
    setPeopleCheckboxStatus({});
    setDirtyChanges({});
    setAssignTo({});
    setCheckAllState(false);
  };

  const handleCheckbox = (event) => {
    setPeopleCheckboxStatus({
      ...peopleCheckboxStatus,
      [event.target.value]: event.target.checked,
    });
  };

  const handleCheckAll = (event) => {
    setCheckAllState(!checkAllState);
    const newPeopleCheckedStatus = {};
    people.data.forEach((person) => {
      newPeopleCheckedStatus[person.id] = event.target.checked;
    });
    setPeopleCheckboxStatus(newPeopleCheckedStatus);
  };

  const handleDropdownWorkspace = (name, value) => {
    setAssignTo({
      [name]: value,
    });
    handleSetProjects(value);
  };

  const handleSetProjects = (value) => {
    delete assignTo["project"];
    dispatch(projectsGet({ workspace_id: value }));
  };

  const handleDropdownProject = (name, value) => {
    setAssignTo({
      ...assignTo,
      [name]: value,
    });
  };

  const handleSubmitEdit = (props) => {
    dispatch(
      talentPoolPersonUpdate({
        search_by: "",
        ...props,
      })
    );
  };

  const closeEditModalHandler = () => {
    setIsEditActive(false);
  };

  const editPersonHandler = (person) => {
    setActivePerson(person);
    setIsEditActive(!isEditActive);
  };

  const firstPagePagination = () => {
    dispatch(talentPoolPageGet({ url: people.links.first, limit: limit }));
  };

  const lastPagePagination = () => {
    dispatch(talentPoolPageGet({ url: people.links.last, limit: limit }));
  };

  const nextPagePagination = () => {
    dispatch(talentPoolPageGet({ url: people.links.next, limit: limit }));
  };

  const prevPagePagination = () => {
    dispatch(talentPoolPageGet({ url: people.links.prev, limit: limit }));
  };

  const rowMenuOptions = [
    { name: "Edit", callback: editPersonHandler },
    // { name: "Remove", alert: editPersonHandler },
  ];

  useEffect(() => {
    //TODO: delete this when context provider is working
    dispatch(talentPoolGet({ search_by: searchBy, limit: limit }));
    dispatch(currentWorkspaceClean());
    dispatch(currentProjectClean());
  }, []);

  useEffect(() => {
    const newPeopleCheckedStatus = {};
    people.data.forEach((person) => {
      newPeopleCheckedStatus[person.id] = false;
    });
    setPeopleCheckboxStatus(newPeopleCheckedStatus);
  }, [people]);

  useEffect(() => {
    let count = 0;
    for (const id in peopleCheckboxStatus) {
      if (peopleCheckboxStatus[id]) {
        count = count + 1;
      }
    }
    setTotalSelected(count);
  }, [peopleCheckboxStatus]);

  useEffect(() => {
    if (people.loading === true) {
      return;
    }
    if (searchBy === "") {
      dispatch(
        talentPoolGet({
          search_by: searchBy,
          limit: limit,
        })
      );
    }
  }, [searchBy, limit]);

  return people.loading ? (
    <Spinner />
  ) : (
    <People
      viewport={viewport}
      tableBarContent={
        <Selector
          key="talentPoolSelector"
          assigned={assignTo}
          workspaces={orderedWorkspaces}
          projects={projects}
          totalSelect={totalSelected}
          handleWorkspace={handleDropdownWorkspace}
          handleProject={handleDropdownProject}
          isAssignActive={isAssignActive}
          closeAssignModalHandler={closeAssignModalHandler}
          openAssignModalHandler={openAssignModalHandler}
          handleSubmitAssign={handleSubmitAssign}
          handleCheckAll={handleCheckAll}
          checkAllState={checkAllState}
        />
      }
      peopleCheckboxStatus={peopleCheckboxStatus}
      handleChangeSearchBy={handleChangeSearchBy}
      handleEnterSearchBy={handleEnterSearchBy}
      searchBy={searchBy}
      handleCheckbox={handleCheckbox}
      handleDropdown={handleDropdownSelect}
      people={people}
      rowMenuOptions={rowMenuOptions}
      activePerson={activePerson}
      isEditActive={isEditActive}
      closeEditModalHandler={closeEditModalHandler}
      handleSubmitEdit={handleSubmitEdit}
      firstPage={firstPagePagination}
      lastPage={lastPagePagination}
      prevPage={prevPagePagination}
      nextPage={nextPagePagination}
      totalPages={totalPages}
      currentPage={currentPage}
      asyncReload={() => {
        dispatch(
          talentPoolGet({
            search_by: searchBy,
            limit: limit,
          })
        );
      }}
    />
  );
};

export default TalentPoolContainer;
