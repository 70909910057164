import React, { useState, Fragment, useRef, useEffect } from "react";
import styled from "styled-components";
import theme from "../../../theme";

// Components
import Step from "../step/index";
import Box from "../box";

import LeftArrow from "../icons/arrowLeftSimple";
import RightArrow from "../icons/arrowRightSimple";

const StyledContainer = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  flex-direction: row;
  ${({ onClick }) => onClick && `cursor: pointer;`}
`;

const StyledDivider = styled.div`
  display: flex;
  align-self: center;
  margin: 8px;
  border-bottom: 2px solid ${theme.colors.blue};
  width: 20px;
  height: 0px;
`;

const StyledStageArea = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 380px;
`;

const ArrowContainer = styled.div`
  display: flex;
  height: 15px;
  width: 15px;
  visibility: ${(props) => (props.showButton ? "visible" : "hidden")};

  &:hover {
    cursor: pointer;
  }
`;

const CardStageContainer = styled.div`
  display: flex;
  width: 98%;
  padding: 0px 10px;
  overflow-x: auto;
  scrollbar-color: transparent transparent; /* thumb and track color */
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    width: 0;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
    border: none;
  }
`;

const StageFlex = styled.div`
  display: flex;
`;

const StepsFilter = ({
  content,
  onSelect,
  stageSelected,
  byStage,
  hasScroll,
  id,
  ...props
}) => {
  const scrollContainer = useRef(null);
  const stagesContainer = useRef(null);
  const [activeScroll, setActiveScroll] = useState(false);

  const onCheckScroll = () => {
    if (
      stagesContainer.current.offsetWidth > scrollContainer.current.offsetWidth
    ) {
      setActiveScroll(true);
    } else {
      setActiveScroll(false);
    }
  };

  useEffect(() => {
    if (hasScroll) {
      onCheckScroll();
    }
  }, [stagesContainer.current?.offsetWidth]);

  const onScrollRight = () => {
    const container = document.getElementById(`container${id}`);
    sideScroll(container, "right", 0, 600, 200);
  };

  const onScrollLeft = () => {
    const container = document.getElementById(`container${id}`);
    sideScroll(container, "left", 0, 600, 200);
  };

  function sideScroll(element, direction, speed, distance, step) {
    let scrollAmount = 0;
    const slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  }

  return (
    <StyledContainer {...props}>
      {hasScroll ? (
        <StyledStageArea>
          <ArrowContainer showButton={activeScroll} onClick={onScrollLeft}>
            <LeftArrow hoverBlue variant="darkBlue" />
          </ArrowContainer>
          <CardStageContainer id={`container${id}`} ref={scrollContainer}>
            <StageFlex ref={stagesContainer}>
              {content.map((stage, index) => (
                <Fragment key={index}>
                  <Step
                    size="small"
                    label={stage.label}
                    showLabel={stage.variant !== "disabled"}
                    variant={stage.label === byStage ? "active" : stage.variant}
                    key={index}
                    content={index + 1}
                    onClick={() => onSelect(stage)}
                    hasSequence={stage.variant === "disabled" ? false : true}
                  ></Step>
                  {index === content.length - 1 ? null : (
                    <StyledDivider></StyledDivider>
                  )}
                </Fragment>
              ))}
            </StageFlex>
          </CardStageContainer>
          <ArrowContainer showButton={activeScroll} onClick={onScrollRight}>
            <RightArrow hoverBlue variant="darkBlue" />
          </ArrowContainer>
        </StyledStageArea>
      ) : (
        <>
          {content.map((stage, index) => (
            <Fragment key={index}>
              <Step
                size={stage.variant !== "disabled" ? "small" : "xsmall"}
                label={stage.label}
                showLabel={stage.variant !== "disabled"}
                variant={stage.label === byStage ? "active" : stage.variant}
                key={index}
                content={index + 1}
                onClick={() => onSelect(stage)}
              ></Step>
              {index === content.length - 1 ? null : (
                <StyledDivider></StyledDivider>
              )}
            </Fragment>
          ))}
        </>
      )}
    </StyledContainer>
  );
};

export default StepsFilter;
