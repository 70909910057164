import { combineActions, handleActions } from "redux-actions";
import { lensProp, pipe, set, view } from "ramda";

import {
  onExpiredToken,
  projectPeoplePayloadsGet,
  projectPeoplePayloadsGetSuccess,
  projectPeoplePayloadsGetFailure,
  // updateProjectPeople,
  // updateProjectPeopleSuccess,
  // updateProjectPeopleFailure,
  // sendEventProjectPeople,
  // sendEventProjectPeopleSuccess,
  // sendEventProjectPeopleFailure,
} from "../actions";

const initialState = {
  inError: false,
  loading: false,
  token: localStorage.getItem("access-token"),
  data: [],
  meta: {},
  links: {},
  included: [],
};

const isLoadingLens = lensProp("loading");
const inErrorLens = lensProp("inError");
const tokenLens = lensProp("token");
const dataLens = lensProp("data");
const includedLens = lensProp("included");
const metaLens = lensProp("meta");
const linksLens = lensProp("links");

const setData = set(dataLens);
const setIncluded = set(includedLens);
const setMeta = set(metaLens);
const setLinks = set(linksLens);

export const getIsLoading = view(isLoadingLens);
export const getInError = view(inErrorLens);
export const getToken = view(tokenLens);

export default handleActions(
  {
    [projectPeoplePayloadsGetSuccess]: (state, actions) =>
      pipe(
        setData(actions.payload.data.data),
        setIncluded(actions.payload.data.included),
        setLinks(actions.payload.data.links.nav),
        setMeta(actions.payload.data.meta),
        set(inErrorLens, false),
        set(isLoadingLens, false)
      )(state),

    [projectPeoplePayloadsGetFailure]: (state) =>
      pipe(set(inErrorLens, true), set(isLoadingLens, false))(state),

    [projectPeoplePayloadsGet]: (state) =>
      pipe(set(inErrorLens, false), set(isLoadingLens, true))(state),

    // handle expired token
    [combineActions(onExpiredToken)]: (state) => {
      localStorage.clear(); // remove token
      return {
        ...initialState,
        token: null,
      };
    },
  },
  initialState
);

export const getPayloadLoading = (state) => state.projectPeoplePayloads.loading;
export const getPayloadsIncluded = (state) =>
  state.projectPeoplePayloads.included;
export const getPayloadsData = (state) => state.projectPeoplePayloads.data;
export const getPayloadsLinks = (state) => state.projectPeoplePayloads.links;

export const getPayloadsCurrentPage = (state) =>
  state.projectPeoplePayloads.meta?.pages?.current;
export const getPayloadsTotalPages = (state) =>
  state.projectPeoplePayloads.meta?.pages?.total;
