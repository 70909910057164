import * as R from "ramda"

export default (relationships, included) => {
  const ids = R.pluck('id', relationships)
  const type = relationships[0]?.type

  return R.filter(
    R.allPass([
      R.propEq("type", type),
      R.compose(R.flip(R.contains)(ids), R.prop('id'))
    ]),
    included
  )
};
