import React from "react";
import { useDispatch, useSelector } from "react-redux";

//Actions
import {
  invitationsGet,
  invitationAccept,
  invitationReject,
  invitationLoadedClean,
  invitationLoadedSet
} from "../../actions";

//Reducer
import { getInvitationsErrors, getInvitations } from "../../reducer";

//Component
import { NotificationsModal } from "../../components/domain/notifications";
import { Invitations } from "../../components/domain/invitations";
import Loading from "../../components/ui/three-dots-loader/index";

//Helper
import { user2Title } from "../../utils/user-helper";

const NotificationsModalContainer = ({ showModal }) => {
  const dispatch = useDispatch();
  const invitations = useSelector(getInvitations);
  const errorMsg = useSelector(getInvitationsErrors);

  const handleAccept = (invitation) => {
    dispatch(
      invitationAccept({
        id: invitation.id,
      })
    );
  };

  const handleReject = (invitation) => {
    dispatch(
      invitationReject({
        id: invitation.id,
      })
    );
  };

  const handleOpenModal = () => {
    dispatch(invitationLoadedSet());
  };

  const handleCloseModal = () => {
    dispatch(invitationLoadedClean());
  };

  const getInvitationText = (invitation) => {
    const user = invitations.included.find(
      (item) =>
        item.type === "user" && item.id === invitation.attributes.user_from_id
    );
    const invitable = invitations.included.find(
      (item) =>
        item.type === "invitable" &&
        item.id === invitation.attributes.invitable_id
    );
  
    const message = `${user2Title(user.attributes)} invited you to ${invitation.attributes.invitable_type} ${invitable.attributes.name} as ${invitation.attributes.project_role}`;
  
    return (
      <span
        title={message}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: 'block',
          maxWidth: '100%',
        }}
      >
        {message}
      </span>
    );
  };

  return (
    <>
      {showModal && (
        <NotificationsModal
          closeModal={handleCloseModal}
          openModal={handleOpenModal}
          invitations={invitations}
          content={
            invitations.loading ? (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  marginTop: "35px",
                }}
              >
                <Loading blue />
              </div>
            ) : (
              <Invitations
                invitations={invitations}
                errorMsg={errorMsg}
                handleAccept={handleAccept}
                handleReject={handleReject}
                getInvitationText={getInvitationText}
              />
            )
          }
        />
      )}
    </>
  );
};

export default NotificationsModalContainer;
