import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
  ${(prop) =>
    prop.landing
      ? `&:hover {
        fill: ${theme.colors.lightBlue}; cursor: pointer;}`
      : ""}
    ${(prop) =>
      prop.linkable
        ? `&:hover {
      fill: ${theme.colors.lightRed}; cursor: pointer;}`
        : ""}
    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black)" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const Twitter = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <path
        d="M12.5,24.5c-6.62,0-12-5.38-12-12c0-6.62,5.38-12,12-12c6.62,0,12,5.38,12,12C24.5,19.12,19.12,24.5,12.5,24.5z
	 M12.5,2C6.71,2,2,6.71,2,12.5C2,18.29,6.71,23,12.5,23C18.29,23,23,18.29,23,12.5C23,6.71,18.29,2,12.5,2z"
      />
      <path
        d="M19.71,8.83c-0.51,0.23-1.06,0.38-1.63,0.45c0.59-0.35,1.04-0.91,1.25-1.57c-0.55,0.33-1.16,0.56-1.8,0.69
	c-0.52-0.55-1.26-0.9-2.07-0.9c-1.83,0-3.18,1.71-2.77,3.49c-2.36-0.12-4.45-1.25-5.85-2.97c-0.74,1.28-0.39,2.95,0.88,3.79
	c-0.46-0.01-0.9-0.14-1.29-0.36C6.39,12.77,7.34,14,8.7,14.28c-0.4,0.11-0.84,0.13-1.28,0.05c0.36,1.13,1.41,1.95,2.65,1.97
	c-1.19,0.94-2.7,1.35-4.2,1.18c1.26,0.81,2.75,1.28,4.35,1.28c5.27,0,8.25-4.45,8.07-8.45C18.85,9.9,19.33,9.4,19.71,8.83z"
      />
    </StyledSvg>
  );
};

export default Twitter;
