import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black)" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const Logout = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <g>
        <path
          d="M12.5,22.9c-5.4,0-9.8-4.4-9.8-9.7c0-3.7,2.2-7.2,5.6-8.8c0.5-0.2,1.1,0,1.3,0.5c0.2,0.5,0,1.1-0.5,1.3
		c-2.7,1.3-4.4,4-4.4,7c0,4.3,3.5,7.7,7.8,7.7s7.8-3.5,7.8-7.7c0-3-1.7-5.7-4.4-7c-0.5-0.2-0.7-0.8-0.5-1.3c0.2-0.5,0.8-0.7,1.3-0.5
		c3.4,1.6,5.6,5.1,5.6,8.8C22.3,18.5,17.9,22.9,12.5,22.9z"
        />
        <path d="M12.5,14.1c-0.6,0-1-0.4-1-1v-10c0-0.6,0.4-1,1-1s1,0.4,1,1v10C13.5,13.7,13.1,14.1,12.5,14.1z" />
      </g>
    </StyledSvg>
  );
};

export default Logout;
