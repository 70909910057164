import React from "react";
import styled from "styled-components";
import theme from "../../../theme";

const StyledRow = styled.div`
  display: flex;
  width: 100%;
  margin: 6px 0px;
  padding: 10px 10px 10px 15px;
  height: 80px;
  background-color: white;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  ${(props) =>
    props.flat
      ? "cursor:default; border: 1px solid lightgray;  border-radius: 3px"
      : null};
  ${(props) =>
    props.active
      ? `height: 100px; box-shadow: 0px 4px 4px lightgray; border-radius: 3px; border: 1px solid ${theme.colors.blue};`
      : null};
  ${(props) =>
    props.error
      ? ` border-radius: 3px; border: 1px solid ${theme.colors.lightRed};`
      : null};

  &:hover {
    box-shadow: 0px 4px 4px lightgray;
  }
`;

const StyledIcon = styled.div`
  display: flex;
  height: 60px;
  width: ${(props) => (props.flat ? "5%" : "10%")};
  align-items: flex-start;
  justify-content: flex-end;

  * {
    height: 15px;
    width: 15px;
  }
`;

const Row = ({
  onClick,
  children,
  flat,
  active,
  icon,
  error,
  handleIcon,
  ...props
}) => {
  return (
    <StyledRow
      flat={flat}
      onClick={onClick}
      active={active}
      error={error}
      {...props}
    >
      {children}
      {icon && (
        <StyledIcon onClick={handleIcon} flat={flat}>
          {icon}
        </StyledIcon>
      )}
    </StyledRow>
  );
};

export default Row;
