import React from "react";
import styled from "styled-components";
import { variant, flex, layout } from "styled-system";
import theme from "../../../theme";

// Components
import Box from "../box";

const StyledContainer = styled(Box)`
  position: relative;
  width: auto;
  margin-right: 15px;
  ${({ onClick }) => onClick && `cursor: pointer;`}
  ${flex}
  ${layout}
`;

const StyledPicture = styled("div")(
  (props) => ({
    width: "60px",
    height: "60px",
    verticalAlign: "middle",
    borderRadius: "50%",
    margin: "5px",
    background: props.bgCustom,
    backgroundImage: `url(${props.bgImage})`,
    backgroundSize: "contain",
    color: theme.colors.white,
  }),

  variant({
    prop: "size",
    variants: {
      big: {
        width: "100px",
        height: "100px",
      },
    },
  }),

  variant({
    variants: {
      blue: {
        // background: "rgb(63, 177, 213)",
        background:
          "linear-gradient(130deg, rgba(63, 177, 213, 1) 0%, rgba(47, 133, 160, 1) 100%)",
      },
      yellow: {
        // background: "rgb(251,237,0)",
        background:
          "linear-gradient(130deg, rgba(251,237,0,1) 0%, rgba(255,163,0,1) 100%)",
      },
      violet: {
        // background: "rgb(194,122,191)",
        background:
          "linear-gradient(130deg, rgba(194,122,191,1) 0%, rgba(123,12,141,1) 100%)",
      },

      red: {
        // background: "rgb(255,97,9)",
        background:
          "linear-gradient(130deg, rgba(255,97,9,1) 0%, rgba(160,47,47,1) 100%)",
      },
      green: {
        // background: "rgb(153,217,41)",
        background:
          "linear-gradient(130deg, rgba(153,217,41,1) 0%, rgba(47,160,91,1) 100%)",
      },
      pink: {
        // background: "rgb(240,152,228)",
        background:
          "linear-gradient(130deg, rgba(240,152,228,1) 0%, rgba(177,24,147,1) 100%)",
      },
      disabled: {
        color: theme.colors.lighterGrey,
        // background: "rgb(198,198,198)",
        background:
          "linear-gradient(130deg, rgba(198,198,198,1) 0%, rgba(148,148,148,1) 100%)",

        "&:hover": { cursor: "not-allowed", boxShadow: "none" },
      },
    },
  })
);

const Picture = ({ variant, size, bgCustom, bgImage, onClick, ...props }) => {
  return (
    <StyledContainer onClick={onClick} {...props}>
      <StyledPicture
        size={size}
        variant={variant}
        bgCustom={bgCustom}
        bgImage={bgImage}
      ></StyledPicture>
    </StyledContainer>
  );
};

export default Picture;
