import React from "react";
import styled, { keyframes } from "styled-components";

//Icons
import People from "../icons/people.js";
import Search from "../icons/search.js";
import Project from "../icons/project.js";

const Loadicon = keyframes`
  0% {
  transform: scale(0) translate3d(0, 0, 0);
  }
    11% {
  transform: scale(1.2) translate3d(0, 0, 0);
  }
    22% {
  transform: scale(1) translate3d(0, 0, 0);
  }
    33% {
  transform: scale(0) translate3d(0, 0, 0);
  }
`;

const Rotate = keyframes`
  100% {
  transform: rotate(360deg)
  }
`;

const StyledContainer = styled("div")`
  box-sizing: border-box;
  width: auto;
`;

const StyledSubcontainer = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.1s linear;
  opacity: 0.5;
  height: 100vh;
  transform: scale(1);
  z-index: 99999;
  background: rgba(0, 0, 0, 0.8);
  box-sizing: border-box;
`;

const StyledSymbol = styled("div")`
  font-size: 25px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  color: #fff;
  width: 24px;
  height: 24px;
  line-height: 1;
  transform: scale(0) translate3d(0, 0, 0);
  box-sizing: border-box;
`;

const StyledIcons = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
  box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.8);
  width: 60px;
  height: 60px;
  padding: 5px;
  box-sizing: border-box;

  ${StyledSymbol}:nth-child(1) {
    animation: ${Loadicon} 3s infinite ease-in-out;
  }
  ${StyledSymbol}:nth-child(2) {
    animation: ${Loadicon} 3s 1s infinite ease-in-out;
  }
  ${StyledSymbol}:nth-child(3) {
    animation: ${Loadicon} 3s 2s infinite ease-in-out;
  }
`;

const StyledDiv = styled("div")`
  animation-play-state: paused;
  border: 5px solid rgba(200, 200, 200, 1);
  border-radius: 50%;
  animation: ${Rotate} 1s infinite linear;
  border-left-color: rgba(200, 200, 200, 0.4);
  width: 100%;
  height: 100%;
  animation-play-state: running;
  box-sizing: border-box;
`;

const Spinner = () => {
  return (
    <StyledContainer>
      <StyledSubcontainer>
        <StyledIcons>
          <StyledSymbol>
            <People variant="lightGrey"></People>
          </StyledSymbol>

          <StyledSymbol>
            <Search variant="lightGrey"></Search>
          </StyledSymbol>

          <StyledSymbol>
            <Project variant="lightGrey"></Project>
          </StyledSymbol>

          <StyledDiv />
        </StyledIcons>
      </StyledSubcontainer>
    </StyledContainer>
  );
};

export default Spinner;
