import React from "react";
import theme from "../../../theme";

import Box from "@ui/box";
import Card from "@ui/card";
import Image from "@assets/img/credentials.svg";
import Flex from "@ui/flex";
import GoogleButton from "@ui/google/googleSignInBtn";
import MicrosoftButton from "@ui/microsoft/microsoftSignInBtn";

const IntegrationsIntro = ({ newCredentialHandler }) => {
  return (
    <Flex
      alignItems={"center"}
      minHeight={"100vh"}
      width={"100%"}
      flexDirection={"column"}
      justifyContent={"flex-start"}
      px={{ sm: 2, md: 0 }}
      overflow="hidden"
      backgroundColor={theme.colors.lighterGrey}
    >
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        width={"95%"}
        mt={"30px"}
        backgroundColor={theme.colors.lighterGrey}
      >
        <Card
          imageBlock={Image}
          textBlock={
            <Box marginTop="20px">
              <p
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                All the credentials you add to this workspace will be listed
                here.
              </p>
              <p
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                A credential is a permission that TeamSourced needs to send
                emails on behalf of someone else, using their email address. For
                security reasons they will be kept encrypted and will only be
                used for the purposes of sending emails and reviewing their
                responses.
              </p>
              <p
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                Just click on the button that corresponds to the email provider
                and follow the steps to complete the integration.
              </p>
              <Flex alignItems="center">
                <p
                  style={{
                    fontSize: "1.1rem",
                    fontWeight: "300",
                    lineHeight: "1.5rem",
                    marginRight: "10px",
                  }}
                >
                  Create a new credential by signing in with
                </p>
                <GoogleButton
                  key="google"
                  onClick={() => {
                    newCredentialHandler("google_oauth2");
                  }}
                />
                <p
                  style={{
                    fontSize: "1.1rem",
                    fontWeight: "300",
                    lineHeight: "1.5rem",
                    margin: "0px 5px",
                  }}
                >
                  or
                </p>
                <MicrosoftButton
                  key="microsoft"
                  onClick={() => {
                    newCredentialHandler("microsoft_graph_auth");
                  }}
                />
                ,
              </Flex>
            </Box>
          }
        ></Card>
      </Flex>
    </Flex>
  );
};

export default IntegrationsIntro;
