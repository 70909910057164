import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.isExpanded ? 'flex-start' : 'center'};
  ${props => !props.isExpanded && `padding-left: 5px;`} // Adjust padding when not expanded
`;

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.blur
        ? `&:hover {
      fill: ${theme.colors.blue}; cursor: pointer;
      filter: drop-shadow(0px 0px 1px #4BAAC8)}`
        : prop.linkable
        ? `&:hover {
      fill: ${theme.colors.darkBlue}; cursor: pointer;}`
        : prop.disabled
        ? "filter: drop-shadow(-2px 2px 3px black)"
        : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const Add = ({ variant, sidebarVariant, isExpanded, ...props }) => {
  const SvgComponent = sidebarVariant ? StyledSvgForSidebar : StyledSvg;

  return (
    <SvgComponent {...props} viewBox="0 0 25 25" variant={variant} isExpanded={isExpanded}>
      <path
        d="M21.3,10H15V3.8c0-1.4-1.1-2.5-2.5-2.5S10,2.4,10,3.8V10H3.8c-1.4,0-2.5,1.1-2.5,2.5S2.4,15,3.8,15H10v6.3
        c0,1.4,1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5V15h6.3c1.4,0,2.5-1.1,2.5-2.5S22.6,10,21.3,10z"
      />
    </SvgComponent>
  );
};

export default Add;
