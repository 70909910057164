import React from "react";
import styled from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

// Components
import Box from "../box";
import ReactTooltip from "react-tooltip";

const StyledContainer = styled(Box)`
  position: relative;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: ${(props) => (props.onClick ? "pointer" : "default")};
  }
`;

const StyledCircleOne = styled("div")(
  {
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "30px",
    height: "30px",

    "&:hover": {
      boxShadow: "-2px 2px 2px lightgray",
      cursor: "pointer",
    },
  },

  variant({
    prop: "size",
    variants: {
      small: {
        width: "25px",
        height: "25px",
      },
      xsmall: {
        width: "20px",
        height: "20px",
      },
    },
  }),

  variant({
    variants: {
      red: {
        color: theme.colors.red,
        bg: theme.colors.white,
        border: theme.borders[1],
        borderColor: theme.colors.lightRed,
      },

      ready: {
        color: theme.colors.darkBlue,
        bg: theme.colors.white,
        border: theme.borders[1],
        borderColor: theme.colors.darkBlue,
      },

      active: {
        color: theme.colors.blue,
        bg: theme.colors.white,
        border: theme.borders[1],
        borderColor: theme.colors.blue,
        boxShadow: "-2px 2px 2px lightgray",
      },

      static: {
        color: theme.colors.lightRed,
        bg: theme.colors.white,
        border: theme.borders[1],
        borderColor: theme.colors.blue,
        width: "19px",
        height: "19px",
      },

      disabled: {
        color: "#D2D2D2",
        bg: theme.colors.white,
        border: theme.borders[1],
        borderColor: "#D2D2D2",
        "&:hover": { cursor: "default", boxShadow: "none" },
      },
    },
  })
);

const StyledFlatCircleOne = styled("div")(
  {
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "30px",
    height: "30px",

    "&:hover": {
      cursor: "default",
    },
  },

  variant({
    prop: "size",
    variants: {
      small: {
        width: "25px",
        height: "25px",
      },
      xsmall: {
        width: "20px",
        height: "20px",
      },
    },
  }),

  variant({
    variants: {
      red: {
        color: theme.colors.red,
        bg: theme.colors.white,
        border: theme.borders[1],
        borderColor: theme.colors.lightRed,
      },

      ready: {
        color: theme.colors.blue,
        bg: theme.colors.white,
        border: theme.borders[1],
        borderColor: theme.colors.blue,
      },

      active: {
        color: theme.colors.blue,
        bg: theme.colors.white,
        border: theme.borders[1],
        borderColor: theme.colors.blue,
        boxShadow: "-2px 2px 2px lightgray",
      },

      static: {
        color: theme.colors.lightRed,
        bg: theme.colors.white,
        border: theme.borders[1],
        borderColor: theme.colors.blue,
        width: "19px",
        height: "19px",
      },

      disabled: {
        color: "#D2D2D2",
        bg: theme.colors.whitey,
        border: theme.borders[1],
        borderColor: "#D2D2D2",
        "&:hover": { cursor: "default", boxShadow: "none" },
      },
    },
  })
);

const StyledCircleTwo = styled("div")(
  {
    borderRadius: "50%",
    width: "22px",
    height: "22px",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    lineHeight: "21px",
    fontWeight: "500",
  },

  variant({
    prop: "size",
    variants: {
      small: {
        width: "17px",
        height: "17px",
        lineHeight: "16px",
      },
      xsmall: {
        width: "15px",
        height: "15px",
        lineHeight: "14px",
      },
    },
  }),

  variant({
    variants: {
      red: {
        color: theme.colors.lightRed,
        bg: theme.colors.white,
        border: theme.borders[1],
        borderColor: theme.colors.lightRed,
      },
      ready: {
        color: theme.colors.darkBlue,
        bg: theme.colors.lightBlue,
        border: theme.borders[1],
        borderColor: "#8fcadd",
      },
      active: {
        color: theme.colors.white,
        bg: theme.colors.blue,
      },

      static: {
        color: theme.colors.white,
        bg: theme.colors.blue,
        width: "11px",
        height: "11px",
      },

      disabled: {
        color: "#777777",
        bg: "#f5f5f5",
        border: theme.borders[1],
        borderColor: "#777777",
        fontWeight: "200",
      },
    },
  })
);

const StyledNum = styled.div`
  text-align: center;
  font-size: 0.75rem;
`;

const StyledLabel = styled.div`
  font-size: 0.8rem;
  color: ${theme.colors.grey};
  display: flex;
  align-self: center;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 3px;
`;

const Step = ({
  flat,
  content,
  label,
  showLabel,
  variant,
  onClick,
  title,
  size,
  hasSequence,
  ...props
}) => {
  const stepDisabled = variant === "disabled" ? true : false;
  return (
    <StyledContainer
      onClick={stepDisabled ? null : onClick}
      {...props}
      title={title}
    >
      {flat && hasSequence ? (
        <StyledFlatCircleOne variant={variant} size={size}>
          <StyledCircleTwo
            data-tip
            data-place="bottom"
            data-for={label}
            variant={variant}
            size={size}
          >
            <StyledNum>{content}</StyledNum>
          </StyledCircleTwo>
        </StyledFlatCircleOne>
      ) : hasSequence ? (
        <StyledCircleOne variant={variant} size={size}>
          <StyledCircleTwo
            data-tip
            data-place="bottom"
            data-for={label}
            variant={variant}
            size={size}
          >
            <StyledNum>{content}</StyledNum>
          </StyledCircleTwo>
        </StyledCircleOne>
      ) : (
        <StyledCircleTwo
          data-tip
          data-place="bottom"
          data-for={label}
          variant={variant}
          size={size}
        >
          <StyledNum>{content}</StyledNum>
        </StyledCircleTwo>
      )}
      {label && (
        <ReactTooltip id={label} aria-haspopup="true">
          {label}
        </ReactTooltip>
      )}

      {label && showLabel && <StyledLabel>{label}</StyledLabel>}
    </StyledContainer>
  );
};

export default Step;
