import React from "react";
import styled from "styled-components";
import { variant, layout } from "styled-system";
import theme from "../../../theme";

// Components
import Box from "../box";

const StyledContainer = styled(Box)`
  position: relative;
  width: auto;
  display: flex;
  background-color: transparent;
  justify-content: center;
  ${({ onClick }) => onClick && `cursor: pointer;`}
  ${layout}
`;

const StyledButton = styled("button")(
  {
    fontFamily: "inherit",
    fontSize: "0.9rem",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "85px",
    padding: "8px 15px",
    margin: (props) => (props.margin ? props.margin : "10px"),
    borderRadius: "4px",
    textTransform: "uppercase",
    fontWeight: "500",
    border: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },

  variant({
    prop: "size",
    variants: {
      small: {
        minWidth: "40px",
        fontSize: "0.8rem",
        padding: "7px 15px",
      },
      big: {
        minWidth: "180px",
        height: "40px", 
        fontSize: "1rem",
        padding: "10px 15px",
      },
      xsmall: {
        minWidth: "40px",
        fontSize: "0.7rem",
        padding: "7px 9px",
      },
    },
  }),

  variant({
    variants: {
      primary: {
        color: theme.colors.white,
        bg: theme.colors.red,
      },

      primaryLine: {
        color: theme.colors.red,
        bg: theme.colors.white,
        border: "1px solid",
        borderColor: theme.colors.red,
      },

      primaryLight: {
        color: theme.colors.white,
        bg: theme.colors.lightRed,
      },

      secondary: {
        color: theme.colors.white,
        bg: theme.colors.blue,
      },

      secondaryLine: {
        color: theme.colors.blue,
        bg: theme.colors.white,
        border: "1px solid",
        borderColor: theme.colors.blue,
      },

      secondaryLight: {
        color: theme.colors.blue,
        bg: theme.colors.lightBlue,
        fontWeight: 700,
      },

      disabled: {
        color: "#fdfdfd",
        bg: "#d2d2d2",
        "&:hover": { cursor: "not-allowed", boxShadow: "none" },
      },

      servicesDark: {
        color: theme.colors.white,
        bg: theme.colors.red,
        "&:hover": {
          boxShadow: "0px 2px 3px #585858",
          background: theme.colors.redGradient,
          color: theme.colors.white,
        },
      },
      servicesLight: {
        color: theme.colors.white,
        bg: theme.colors.orange3,
        textTransform: "none",
        fontWeight: 700,
        "&:hover": {
          opacity: "0.9",
          color: theme.colors.white,
        },
      },
    },
  })
);

const StyledIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`;

const Button = ({
  content,
  icon,
  disabled,
  variant,
  onClick,
  margin,
  size,
  ...props
}) => {
  return (
    <StyledContainer onClick={disabled ? null : onClick} {...props}>
      <StyledButton
        variant={disabled ? "disabled" : variant}
        size={size}
        disabled={disabled}
        margin={margin}
      >
        {icon ? <StyledIcon>{icon}</StyledIcon> : null}
        {content}
      </StyledButton>
    </StyledContainer>
  );
};

export default Button;
