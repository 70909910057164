import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Spinner from "../../components/ui/spinner";
import { Workflows, WorkflowIntro } from "../../components/domain/workflows";

//Actions
import {
  workflowsGet,
  currentWorkspaceSet,
  projectsGet,
  appWorkflowsFilterUpdate,
  appUnmountWorkflowsFilter,
  workflowDelete,
} from "../../actions";

//Reducers
import {
  getProjects,
  getWorkflowsData,
  getWorkflowsLoading,
  getWorkflowsDataIncluded,
} from "../../reducer";

const WorkflowsContainer = ({ viewport, ...props }) => {
  const workflows = useSelector(getWorkflowsData);
  const workspaceId = props.match.params.id || "me";
  const loading = useSelector(getWorkflowsLoading);
  const projects = useSelector(getProjects);
  const stages = useSelector(getWorkflowsDataIncluded);
  const dispatch = useDispatch();

  const [editActive, setIsEditActive] = useState(false);
  const [activeWorkflow, setActiveWorkflow] = useState({});
  const [addToActive, setAddToActive] = useState(false);
  const [byAssignment, setByAssignment] = useState('workspace');
  const [removeActive, setRemoveActive] = useState(false);
  const [itemToRemove, setItemToRemove] = useState();

  const closeEditModalHandler = () => {
    setIsEditActive(false);
  };

  const closeAddToHandler = () => {
    setAddToActive(false);
  };

  const closeRemoveModalHandler = () => {
    setRemoveActive(false);
  };

  const editWorkflowHandler = (workflow) => {
    setActiveWorkflow(workflow);
    setIsEditActive(true);
  };

  const addWorkflowHandler = (workflow) => {
    setActiveWorkflow(workflow);
    setAddToActive(true);
  };

  const removeModalHandler = (item) => {
    setRemoveActive(true);
    setItemToRemove(item);
  };

  const handleSubNavbarTabs = (label) => {
    if (label == "Templates")
      setByAssignment("workspace");
    if (label == "Assigned")
      setByAssignment("project");
  }

  const rowTemplateMenuOptions = [
    {
      name: "Edit",
      callback: editWorkflowHandler,
    },
    {
      name: "Add to Project",
      callback: addWorkflowHandler,
    },
    {
      name: "Remove",
      alert: removeModalHandler
    },
  ];

  const rowAssignedMenuOptions = [
    {
      name: "Edit",
      callback: editWorkflowHandler,
    },
    {
      name: "Remove",
      alert: removeModalHandler
    },
  ];

  const removeWorkflowHandler = (workflow) => {
    dispatch(
      workflowDelete({
        workspace_id: workspaceId,
        id: workflow.id,
      })
    );
    setRemoveActive(false);
  };

  useEffect(() => {
    dispatch(appWorkflowsFilterUpdate({ assigned_to: byAssignment }));
    dispatch(workflowsGet({ workspace_id: workspaceId }));
  }, [byAssignment]);

  useEffect(() => {
    return () => dispatch(appUnmountWorkflowsFilter());
  }, []);

  useEffect(() => {
    //TODO: delete this when context provider is working
    dispatch(currentWorkspaceSet({ workspace_id: workspaceId }));
    dispatch(projectsGet({ workspace_id: workspaceId, by_status: ["Active", "Inactive", "Archived"] }));
  }, [workspaceId]);

  return loading && !workspaceId ? (
    <Spinner />
  ) : (
    <Workflows
      viewport={viewport}
      editActive={editActive}
      addToProjectActive={addToActive}
      activeWorkflow={activeWorkflow}
      workflows={workflows.data}
      stages={stages}
      rowTemplateMenuOptions={rowTemplateMenuOptions}
      rowAssignedMenuOptions={rowAssignedMenuOptions}
      closeEditModal={closeEditModalHandler}
      closeAddModal={closeAddToHandler}
      closeRemoveModal={closeRemoveModalHandler}
      addToOptions={projects?.data.map((project) => project.attributes.name)}
      handleSubNavbarTabs={handleSubNavbarTabs}
      projects={projects?.data}
      removeActive={removeActive}
      itemToRemove={itemToRemove}
      removeWorkflowHandler={removeWorkflowHandler}
    />
  );
};

export default WorkflowsContainer;
