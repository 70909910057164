import React, { useState, useEffect } from "react";
import theme from "../../../theme";
import styled from "styled-components";
import * as R from "ramda";

import Modal from "../../ui/modal/Modal";
import Flex from "../../ui/flex";
import Box from "../../ui/box";

import Tag from "../../ui/tag";

import { getRelated } from "../../../utils";
import PreviewStep from "./PreviewStep";

import useCountries from "../../../hooks/useCountries";

const TagFinishContainer = styled.div`
  width: 600px;
  display: flex;
  flex-flow: wrap;
  align-items: center;
`;
const FinishLabel = styled.div`
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.5rem;
  width: 250px;
  text-align: right;
  margin: 15px;
`;
const FinishValue = styled.div`
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #1b7b99;
  width: 600px;
  text-align: start;
  margin: 15px;
`;

const PreviewSequenceModal = ({
  handleCloseModal,
  steps,
  sequence,
  credentials,
}) => {
  const [sequencesMetaData, setSequencesMetaData] = useState([]);
  const { countryNameByCode } = useCountries();

  useEffect(() => {
    setSequencesSteps();
  }, []);

  const sequenceLabels = sequencesMetaData.map((s) => s["attributes"]["name"]);

  const setSequencesSteps = () => {
    const relatedSteps = getRelated(sequence.relationships.steps.data, steps);
    setSequencesMetaData(relatedSteps);
  };

  let stepCredencialsNames = () => {
    return R.uniq(
      sequencesMetaData.map((step) => {
        const provider_type = step.attributes.provider_type;
        return `${step.attributes.provider_name} - (${provider_type})`;
      })
    );
  };

  return (
    <Modal
      cardTabs
      shortWidth
      handleNext
      title={sequence.attributes.name}
      handleCloseModal={handleCloseModal}
      labels={["Info", ...sequenceLabels]}
    >
      {[
        <Flex key="info" width="90%" justifyContent="space-evenly">
          <Box alignItems="center">
            <Flex>
              <FinishLabel>Sequence Name</FinishLabel>
              <FinishValue>{sequence.attributes.name}</FinishValue>
            </Flex>
            <Flex>
              <FinishLabel>Sequence Description</FinishLabel>
              <FinishValue>{sequence.attributes.description}</FinishValue>
            </Flex>

            <Flex>
              <FinishLabel>Requires Review</FinishLabel>
              <FinishValue>
                {sequence.attributes.has_review ? "Yes" : "No"}
              </FinishValue>
            </Flex>
            <Flex>
              <FinishLabel>Send on Holidays</FinishLabel>
              <FinishValue>
                {sequence.attributes.allow_send_holiday ? "Yes" : "No"}
              </FinishValue>
            </Flex>
            <Flex>
              <FinishLabel>Send on Weekends</FinishLabel>
              <FinishValue>
                {sequence.attributes.allow_send_weekend ? "Yes" : "No"}
              </FinishValue>
            </Flex>

            <Flex>
              <FinishLabel>Holiday Country</FinishLabel>
              <FinishValue>
                {sequence.attributes.holiday_country
                  ? countryNameByCode(sequence.attributes.holiday_country)
                  : "None"}
              </FinishValue>
            </Flex>
            <Flex>
              <FinishLabel>Credentials</FinishLabel>
              <FinishValue>
                {credentials[sequence.id].count > 0
                  ? stepCredencialsNames().join(", ")
                  : "None"}
              </FinishValue>
            </Flex>
            <Flex>
              <FinishLabel>Variables</FinishLabel>
              <TagFinishContainer>
                {sequence.attributes.payload_fields.customs.map(
                  (variable, indexProjectVariable) =>
                    variable.used && (
                      <Tag
                        noPointer
                        key={indexProjectVariable}
                        content={variable.name}
                      />
                    )
                )}
                {sequence.attributes.payload_fields.defaults.map(
                  (variable, indexProjectVariable) =>
                    variable.used && (
                      <Tag
                        noPointer
                        key={indexProjectVariable}
                        content={variable.name}
                      />
                    )
                )}
              </TagFinishContainer>
            </Flex>
          </Box>
        </Flex>,
        ...sequencesMetaData.map((step, indexStep) => (
          <PreviewStep key={indexStep} step={step} indexStep={indexStep} />
        )),
      ]}
    </Modal>
  );
};

export default PreviewSequenceModal;
