import { combineActions, handleActions } from "redux-actions";
import { lensProp, pipe, set, view } from "ramda";
import {
  onExpiredToken,
  invitationsGet,
  invitationsGetSuccess,
  invitationsGetFailure,
  projectInvitationsGet,
  projectInvitationsGetSuccess,
  projectInvitationsGetFailure,
  workspaceInvitationsGet,
  workspaceInvitationsGetSuccess,
  workspaceInvitationsGetFailure,
  invitationAcceptSuccess,
  invitationAcceptFailure,
  invitationLoadedClean,
  invitationLoadedSet,
} from "../actions";

const initialState = {
  inError: false,
  loading: false,
  loaded: false,
  token: localStorage.getItem("access-token"),
  data: [],
  included: [],
  error: "",
};

const isLoadingLens = lensProp("loading");
const isLoadedLens = lensProp("loaded");
const inErrorLens = lensProp("inError");
const errorLens = lensProp("error");
const tokenLens = lensProp("token");
const dataLens = lensProp("data");
const includedLens = lensProp("included");

const setData = set(dataLens);
const setIncluded = set(includedLens);

export const getIsLoading = view(isLoadingLens);
export const getInError = view(inErrorLens);
export const getToken = view(tokenLens);

export default handleActions(
  {
    [invitationsGetSuccess]: (state, action) =>
      pipe(
        setData(action.payload.data.data),
        setIncluded(action.payload.data.included),
        set(isLoadingLens, false),
        set(inErrorLens, false),
        set(isLoadedLens, true),
        set(errorLens, "")
      )(state),
    [invitationsGetFailure]: (state, action) =>
      pipe(
        set(inErrorLens, true),
        set(errorLens, action.payload),
        set(isLoadingLens, false)
      )(state),
    [invitationsGet]: (state) =>
      pipe(
        setData([]),
        setIncluded([]),
        set(inErrorLens, false),
        set(isLoadingLens, true)
      )(state),
    [projectInvitationsGetSuccess]: (state, action) =>
      pipe(
        setData(action.payload.data.data),
        setIncluded(action.payload.data.included),
        set(isLoadingLens, false),
        set(inErrorLens, false),
        set(errorLens, "")
      )(state),
    [projectInvitationsGetFailure]: (state, action) =>
      pipe(
        set(inErrorLens, true),
        set(errorLens, action.payload),
        set(isLoadingLens, false)
      )(state),
    [projectInvitationsGet]: (state) =>
      pipe(
        setData([]),
        setIncluded([]),
        set(inErrorLens, false),
        set(isLoadingLens, true)
      )(state),
    [workspaceInvitationsGetSuccess]: (state, action) =>
      pipe(
        setData(action.payload.data.data),
        setIncluded(action.payload.data.included),
        set(isLoadingLens, false),
        set(inErrorLens, false),
        set(errorLens, "")
      )(state),
    [workspaceInvitationsGetFailure]: (state, action) =>
      pipe(
        set(inErrorLens, true),
        set(errorLens, action.payload),
        set(isLoadingLens, false)
      )(state),
    [workspaceInvitationsGet]: (state) =>
      pipe(
        setData([]),
        setIncluded([]),
        set(inErrorLens, false),
        set(isLoadingLens, true)
      )(state),
    [invitationAcceptFailure]: (state, action) =>
      pipe(
        set(inErrorLens, true),
        set(errorLens, action.payload.err.response.data.error),
        set(isLoadingLens, false)
      )(state),
    [invitationAcceptSuccess]: (state, action) =>
      pipe(
        set(inErrorLens, false),
        set(errorLens, ""),
        set(isLoadingLens, false)
      )(state),

    //handle notification modal
    [invitationLoadedClean]: (state, action) =>
      pipe(set(isLoadedLens, false))(state),
    [invitationLoadedSet]: (state, action) =>
      pipe(set(isLoadedLens, true))(state),

    // handle expired token
    [combineActions(onExpiredToken)]: (state) => {
      localStorage.clear(); // remove token
      return {
        ...initialState,
        token: null,
      };
    },
  },
  initialState
);

export const getInvitations = (state) => state.invitations;
export const getInvitationsLoading = (state) => state.invitations.loading;
export const getInvitationsLoaded = (state) => state.invitations.loaded;
export const getInvitationsData = (state) => state.invitations.data;
export const getInvitationsErrors = (state) => state.invitations.error;
export const getInvitationsIncluded = (state) => state.invitations.included;
