import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

//components
import { Modal } from "../../ui/modal";
import Box from "../../ui/box";
import Flex from "../../ui/flex";
import Button from "../../ui/button";
import Submit from "../../ui/input-submit";
import Input from "../../ui/input";
import Image from "../../ui/../../assets/img/workspace.svg";
//Icons
import Close from "../../ui/icons/close";

const CardImage = styled.div`
  width: 30%;
  height: 300px;
  display: flex;
  background-image: url(${(props) => props.image});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const EditWorkspaceModal = ({
  closeModal,
  showModal,
  dirtyChanges,
  handleSubmitClick,
  handleChange,
  workspace,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm();

  const handleCLoseModal = () => {
    clearErrors();
    closeModal();
  };

  return (
    <>
      {showModal === true && (
        <Modal
          fullHeader
          formContent
          onSubmit={handleSubmit(handleSubmitClick)}
          title="Edit workspace"
          icon={<Close variant="grey"></Close>}
          closeHandler={handleCLoseModal}
          buttons={[
            <Button
              key="cancel"
              variant="secondaryLine"
              content="Cancel"
              onClick={handleCLoseModal}
            />,
            <Submit
              key="continue"
              variant="secondary"
              content="Update"
              disabled={Object.keys(dirtyChanges).length <= 0}
            />,
          ]}
        >
          <Flex
            justifyContent="space-evenly"
            alignItems="center"
            marginTop="15px"
          >
            <Box width="60%">
              <p
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                You can change the name of your workspaces as many times as you
                want.
              </p>
              <p
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                In the menu on the left you can see all your workspaces
                identified by the first two letters of their name, keep this in
                mind to avoid repetitions that can be confusing
              </p>
              <Input
                {...register("name", { required: true })}
                error={errors.name ? true : false}
                errorMsg="This field is required"
                name="name"
                label="Name"
                defaultValue={workspace.attributes.name}
                mb={4}
                mt={2}
                placeholder="Enter Workspace Name"
                value={dirtyChanges.name}
                onChange={handleChange}
              />
            </Box>

            <CardImage image={Image} />
          </Flex>
        </Modal>
      )}
    </>
  );
};

export default EditWorkspaceModal;
