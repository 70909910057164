import { createActions } from "redux-actions";

// sign in actions
export const SET_LOCATION_PATH = "SET_LOCATION_PATH";
export const SET_LIMIT = "SET_LIMIT";

export const { setLocationPath, setLimit } = createActions(
  {},
  SET_LOCATION_PATH,
  SET_LIMIT
);
