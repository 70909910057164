import React from "react";
import styled from "styled-components";

const StyledSubNavbar = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0px 30px;
  position: relative;
  top:0;

  ${(props) =>
    props.iconButtons || props.actionButtons ? `justify-content: flex-end` : ``}
  ${(props) => (props.element ? `justify-content: flex-start` : ``)}
  ${(props) =>
    props.iconButtons && props.element ? `justify-content: space-between` : ``}
`;

const StyledArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  ${(props) => (props.left ? `justify-content: flex-start` : ``)}
  ${(props) => (props.right ? `justify-content: flex-end` : ``)}
`;

const StyledIcon = styled.div`
  width: 23px;
  height: 23px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const SubNavbar = ({ buttons, actionButtons, iconButtons, element }) => {
  return (
    <StyledSubNavbar iconButtons={iconButtons} element={element}>
      {element ? <StyledArea left>{element}</StyledArea> : null}

      {buttons ? (
        <StyledArea right>
          {iconButtons
            ? iconButtons.map((btn, index) => (
                <StyledIcon key={index}>{btn}</StyledIcon>
              ))
            : null}
          {actionButtons}
        </StyledArea>
      ) : null}
    </StyledSubNavbar>
  );
};
export default SubNavbar;
