import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { flex, layout } from "styled-system";
import theme from "../../../theme";

// Components
import Box from "../box";

const StyledText = styled.div`
  white-space: pre-line;
  margin: 5px;
  align-items: center;
  font-weight: 200;
  font-size: 0.9rem;
  display: flex;
  flex-direction: row;
  color: ${theme.colors.grey};
  justify-content: flex-end;
`;

const StyledContainer = styled(Box)`
  position: relative;
  width: auto;
  background-color: transparent;
  margin: 6px 6px 6px 10px;
  ${({ onClick }) => onClick && `cursor: pointer;`}
  ${flex}
  ${layout}
`;

const StyledLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 50px;
  height: 22px;
`;

const StyledInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + span {
    background-color: ${theme.colors.darkBlue};
  }

  &:focus & > span {
    box-shadow: 0 0 1px #2196f3;
  }

  &:checked + span:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
`;

const StyledSpan = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${theme.colors.lightGrey};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 5px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const Toggle = ({
  customeLabel,
  variant,
  handleChange,
  initialLabel,
  initialValue,
  tabIndex,
  disabled,
  name,
  ...props
}) => {
  const [state, setState] = useState(initialValue || false);
  const [label, setLabel] = useState(initialLabel);

  const toggleState = () => {
    setState(!state);
    handleChange(!state, props, name);
  };

  useEffect(() => {
    setLabel(state ? "Active" : "Inactive");
  }, [state]);

  return (
    <StyledText>
      {customeLabel ? customeLabel : label}
      <StyledContainer {...props}>
        <StyledLabel>
          <StyledInput
            tabIndex={tabIndex}
            name={name}
            onChange={() => {
              !disabled ?
                toggleState()
                :
                null
            }}
            type="checkbox"
            checked={state}
          />
          <StyledSpan />
        </StyledLabel>
      </StyledContainer>
    </StyledText>
  );
};

export default Toggle;
