import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import Helmet from "react-helmet";
import App from "../../components/root";

// Actions
import { userGet, appMount } from "../../actions";

// Reducer
import { getIsUserLoaded } from "../../reducer";

const RootContainer = () => {
  const title = "TeamSourced";
  const isUserLoaded = useSelector(getIsUserLoaded);
  const dispatch = useDispatch();
  const gtmParams = { id: process.env.REACT_APP_GTM_ID };
  const authPaths = ["/auth/google_oauth2", "/auth/microsoft_graph_auth"]

  useEffect(() => {
    if (
      localStorage.getItem("access-token") &&
      localStorage.getItem("access-token") !== "null" &&
      !isUserLoaded
    ) {
      dispatch(userGet());
    }
    if (isUserLoaded) {
      dispatch(appMount());
      if (authPaths.some(path => window.location.pathname.includes(path)))
        dispatch(push("/"));
      sessionStorage.setItem("isExpired", false);
    }
  }, [isUserLoaded]);

  useEffect(() => {
    if (isUserLoaded) {
      dispatch(appMount());
      if (authPaths.some(path => window.location.pathname.includes(path)))
        dispatch(push("/"));
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={title} />
        <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/images/icon.png" />
        <script>
          {`
    (function(apiKey){
      (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
      v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
          o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
          y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
          z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
  })('${process.env.REACT_APP_PENDO_ID}');
          `}
        </script>

        <script>
          {`
          !function () {
            var reb2b = window.reb2b = window.reb2b || [];
            if (reb2b.invoked) return;
            reb2b.invoked = true;
            reb2b.methods = ["identify", "collect"];
            reb2b.factory = function (method) {
              return function () {
                var args = Array.prototype.slice.call(arguments);
                args.unshift(method);
                reb2b.push(args);
                return reb2b;
              };
            };
            for (var i = 0; i < reb2b.methods.length; i++) {
              var key = reb2b.methods[i];
              reb2b[key] = reb2b.factory(key);
            }
            reb2b.load = function (key) {
              var script = document.createElement("script");
              script.type = "text/javascript";
              script.async = true;
              script.src = "https://s3-us-west-2.amazonaws.com/b2bjsstore/b/" + key + "/reb2b.js.gz";
              var first = document.getElementsByTagName("script")[0];
              first.parentNode.insertBefore(script, first);
            };
            reb2b.SNIPPET_VERSION = "1.0.1";
            reb2b.load("5DNXY8HVJXO0");
          }();
          `}
        </script>

      </Helmet>
      <GTMProvider state={gtmParams}>
        <App isAuthenticated={isUserLoaded} />
      </GTMProvider>
    </>
  );
};

export default RootContainer;
