import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black)" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const PriorityMid = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <g>
        <path
          d="M5.6,23.2c-0.1,0-0.2,0-0.4-0.1C5,23,4.8,22.8,4.8,22.5v-2.7c-0.9-0.1-1.6-0.5-2.1-1c-0.7-0.7-1-1.6-1-2.8V5.6
		c0-2,1.8-3.8,3.8-3.8h13.8c2.1,0,3.8,1.6,3.8,3.6l0,10.7c0,2-1.8,3.8-3.8,3.8H11l-5,3.2C5.9,23.2,5.7,23.2,5.6,23.2z M5.6,3.3
		c-1.2,0-2.3,1.2-2.3,2.3v10.5c0,0.8,0.2,1.4,0.6,1.7c0.5,0.5,1.2,0.6,1.8,0.6c0.4,0,0.8,0.3,0.8,0.8v2l4-2.6
		c0.1-0.1,0.3-0.1,0.4-0.1h8.7c1.2,0,2.3-1.2,2.3-2.3l0-10.7c0-1.1-1.1-2.1-2.3-2.1H5.6z"
        />
        <circle cx="10" cy="15" r="1.3" />
        <circle cx="15" cy="15" r="1.3" />
        <path
          d="M10,12.5L10,12.5c-0.7,0-1.3-0.6-1.3-1.2v-5C8.7,5.6,9.3,5,10,5h0c0.7,0,1.2,0.6,1.2,1.2v5
		C11.2,11.9,10.7,12.5,10,12.5z"
        />
        <path
          d="M15,12.5L15,12.5c-0.7,0-1.3-0.6-1.3-1.2v-5C13.7,5.6,14.3,5,15,5h0c0.7,0,1.2,0.6,1.2,1.2v5
		C16.2,11.9,15.7,12.5,15,12.5z"
        />
      </g>
    </StyledSvg>
  );
};

export default PriorityMid;
