import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.linkable
        ? `&:hover {
      fill: ${theme.colors.darkBlue}; cursor: pointer;}`
        : prop.reconnect
          ? `&:hover {
      fill: ${theme.colors.lightRed}; cursor: pointer;}`
          : ""}

    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black)" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);
const ExportToProject = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant} >
      <g>
        <path
          d="M21.5 5.8h-4.6V4c0-1-.8-1.8-1.8-1.8H9.8C8.9 2.3 8.1 3 8.1 4v1.8H3.5C2 5.8.8 7 .8 8.6V20c0 1.5 1.2 2.8 2.8 2.8h18c1.5 0 2.8-1.2 2.8-2.8V8.6C24.3 7 23 5.8 21.5 5.8zM9.6 4c0-.1.1-.3.3-.3h5.3c.1 0 .3.1.3.3v1.8H9.6V4zM22.8 20c0 .7-.6 1.3-1.3 1.3h-18c-.7 0-1.3-.6-1.3-1.3V8.6c0-.7.6-1.3 1.3-1.3h4.6 8.8 4.6c.7 0 1.3.6 1.3 1.3V20zM21.5 5.8H16.9V4C16.9 3 16.1 2.2 15.1 2.2H9.8C8.9 2.3 8.1 3 8.1 4V5.8H3.5C2 5.8.8 7 .8 8.6V20C.8 21.5 2 22.8 3.6 22.8H21.6C23.1 22.8 24.4 21.6 24.4 20V8.6C24.3 7 23 5.8 21.5 5.8ZM9.6 4C9.6 3.9 9.7 3.7 9.9 3.7H15.2C15.3 3.7 15.5 3.8 15.5 4V5.8H9.6V4ZM22.8 20C22.8 20.7 22.2 21.3 21.5 21.3H3.5C2.8 21.3 2.2 20.7 2.2 20V8.6C2.2 7.9 2.8 7.3 3.5 7.3H8.1 16.9 21.5C22.2 7.3 22.8 7.9 22.8 8.6V20ZM17.901 12.908l-3.4-3.4c-.3-.3-.8-.3-1.1 0s-.3.8 0 1.1l2.563 2.388h-10.252c-.4 0-.8.3-.8.8s.3.8.8.8h10.292l-2.1 2.1c-.3.3-.3.8 0 1.1.1.1.3.2.5.2s.4-.1.5-.2l3.21-3.426C18.563 13.961 18.513 13.459 18.063 13.068zM19.8 9.8c-.4 0-.8.3-.8.8v7.5c0 .4.3.8.8.8s.8-.3.8-.8v-7.5C20.6 10.1 20.2 9.8 19.8 9.8z"
        />
      </g>
    </StyledSvg>
  );
};
export default ExportToProject;
