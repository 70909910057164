import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as R from "ramda";

//Actions
import { workflowCreate, sequencesGet } from "@actions";

//Reducers
import { getSequences, getWorkspaceCurrent } from "@reducer";

//Component
import { NewWorkflowModal } from "@components/domain/workflows";

const NewWorkflowModalContainer = ({ showButton, buttonCard }) => {
  const minimumStages = 3;
  const dispatch = useDispatch();
  const workspace_id = useSelector(getWorkspaceCurrent);

  const [dirtyChanges, setDirtyChanges] = useState({ stages: [] });
  const [showModal, setShowModal] = useState(false);
  const [activeStageIndex, setActiveStageIndex] = useState(0);
  const sequences = useSelector(getSequences);

  const handleAddStage = () => {
    const newStages = [
      ...dirtyChanges.stages,
      { position: dirtyChanges.stages.length },
    ];
    setDirtyChanges({ ...dirtyChanges, stages: newStages });
  };

  const handleRemoveStage = (index) => {
    const newStages = R.remove(index, 1, dirtyChanges.stages);
    let newArrayStages = [];
    newStages.forEach((_, index) => {
      newArrayStages[index] = { ...newStages[index], position: index };
    });

    setDirtyChanges({ ...dirtyChanges, stages: newArrayStages });
  };

  const handleSubmitClick = () => {
    const newStages = { ...dirtyChanges.stages };
    dispatch(
      workflowCreate({
        ...dirtyChanges,
        stages: newStages,
        workspace_id,
      })
    );
    setShowModal(false);
    setDirtyChanges({ stages: [] });
  };

  const handleInputChange = (event, index) => {
    if (index !== undefined && index !== null) {
      const stages = [...dirtyChanges.stages];

      stages[index] = {
        ...stages[index],
        name: event.target.value,
        description: event.target.value,
      };

      setDirtyChanges({
        ...dirtyChanges,
        stages: stages,
      });
    } else {
      setDirtyChanges({
        ...dirtyChanges,
        [event.target.name]: event.target.value,
      });
    }
  };

  const sequenceDropdownHandler = (name, value, index) => {
    const stages = [...dirtyChanges.stages];

    if (name.includes("sequence")) {
      stages[index] = {
        ...stages[index],
        [name]: value,
        ["sequence_id"]: searchSequenceId(value),
        ["sequence"]: value,
      };
    }
    if (name === "on_response_stage") {
      stages[index] = {
        ...stages[index],
        [name]: value,
        ["on_response_stage_position"]: searchStagePosition(value),
      };
    }

    setDirtyChanges({
      ...dirtyChanges,
      stages: stages,
    });
  };

  const searchSequenceId = (sequenceName) => {
    return sequences.data.filter(
      (item) => item.attributes.name === sequenceName
    )[0].attributes.id;
  };

  const searchStagePosition = (stageName) => {
    return dirtyChanges.stages.filter((item) => item.name === stageName)[0]
      .position;
  };

  const handleCleanAction = (rowClose, index) => {
    const stages = [...dirtyChanges.stages];
    if (rowClose) {
      delete stages[index]["sequence"];
      delete stages[index]["sequence_id"];
      delete stages[index]["on_response_stage"];
      delete stages[index]["on_response_stage_position"];
    } else {
      stages[index] = {
        ...stages[index],
        ["sequence"]: null,
        ["sequence_id"]: null,
        ["on_response_stage"]: null,
        ["on_response_stage_position"]: null,
      };
    }
    setDirtyChanges({
      ...dirtyChanges,
      stages: stages,
    });
  };

  useEffect(() => {
    if (showModal) {
      let initialStagesArray = [];

      for (let i = 0; i < minimumStages; i++) {
        initialStagesArray.push({
          position: i,
        });
      }
      setDirtyChanges({ ...dirtyChanges, stages: initialStagesArray });
    } else setDirtyChanges({ stages: [] });
  }, [showModal]);

  useEffect(() => {
    if (workspace_id) {
      dispatch(sequencesGet({ workspace_id: workspace_id, limit: 9999 }));
    }
  }, [workspace_id]);

  return (
    <NewWorkflowModal
      workspaceId={workspace_id}
      dirtyChanges={dirtyChanges}
      handleSubmitClick={handleSubmitClick}
      handleChange={handleInputChange}
      showModal={showModal}
      showButton={showButton}
      buttonCard={buttonCard}
      openModal={() => setShowModal(true)}
      closeModal={() => setShowModal(false)}
      activeStageIndex={activeStageIndex}
      setActiveStageIndex={setActiveStageIndex}
      handleAddStage={handleAddStage}
      handleRemoveStage={handleRemoveStage}
      sequenceDropdownHandler={sequenceDropdownHandler}
      sequences={sequences}
      handleCleanAction={handleCleanAction}
      asyncReload={() =>
        dispatch(sequencesGet({ workspace_id: workspace_id, limit: 9999 }))
      }
    />
  );
};

export default NewWorkflowModalContainer;
