import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//Component
import { Workspace } from "@components/domain/workspaces";
import Spinner from "@components/ui/spinner";

//Actions
import {
  currentWorkspaceSet,
  projectsGet,
  currentProjectClean,
  workspaceAnalyticsGet,
} from "@actions";

//Reducers
import {
  getProjects,
  getAppLoading,
  getWorkspaceAnalytics,
  getCurrentWorkspace,
} from "@reducer";

const WorkspaceContainer = (props) => {
  const dispatch = useDispatch();

  const projects = useSelector(getProjects);
  const workspaceAnalytics = useSelector(getWorkspaceAnalytics);
  const projectsLink = `/workspaces/${props.match.params.id}/projects`;
  const currentWorkspace = useSelector(getCurrentWorkspace);
  const loading = useSelector(getAppLoading);

  useEffect(() => {
    dispatch(currentWorkspaceSet({ workspace_id: props.match.params.id }));
    dispatch(projectsGet({ workspace_id: props.match.params.id, meta: true }));
    // dispatch(workspaceAnalyticsGet({ id: props.match.params.id }));
    dispatch(currentProjectClean());
  }, [props.match.params.id]);

  return loading || projects.loading ? (
    <Spinner />
  ) : (
    <Workspace
      projects={projects}
      projectsLink={projectsLink}
      currentWorkspace={currentWorkspace}
      workspaceAnalytics={workspaceAnalytics}
    />
  );
};

export default WorkspaceContainer;
