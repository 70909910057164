import { createActions } from "redux-actions";

// sign in actions
export const PROJECT_PEOPLE_GET = "PROJECT_PEOPLE_GET";
export const PROJECT_PEOPLE_GET_SUCCESS = "PROJECT_PEOPLE_GET_SUCCESS";
export const PROJECT_PEOPLE_GET_FAILURE = "PROJECT_PEOPLE_GET_FAILURE";

export const PROJECT_PEOPLE_MOVE = "PROJECT_PEOPLE_MOVE";
export const PROJECT_PEOPLE_MOVE_WITH_RELOAD =
  "PROJECT_PEOPLE_MOVE_WITH_RELOAD";
export const PROJECT_PEOPLE_MOVE_SUCCESS = "PROJECT_PEOPLE_MOVE_SUCCESS";
export const PROJECT_PEOPLE_MOVE_FAILURE = "PROJECT_PEOPLE_MOVE_FAILURE";

export const PROJECT_PEOPLE_SEARCH = "PROJECT_PEOPLE_SEARCH";
export const PROJECT_PEOPLE_SEARCH_SUCCESS = "PROJECT_PEOPLE_SEARCH_SUCCESS";
export const PROJECT_PEOPLE_SEARCH_FAILURE = "PROJECT_PEOPLE_SEARCH_FAILURE";

export const PROJECT_PEOPLE_UPDATE = "PROJECT_PEOPLE_UPDATE";
export const PROJECT_PEOPLE_UPDATE_SUCCESS = "PROJECT_PEOPLE_UPDATE_SUCCESS";
export const PROJECT_PEOPLE_UPDATE_FAILURE = "PROJECT_PEOPLE_UPDATE_FAILURE";

export const PROJECT_PEOPLE_RESPONSE_UPDATE = "PROJECT_PEOPLE_RESPONSE_UPDATE";
export const PROJECT_PEOPLE_RESPONSE_UPDATE_SUCCESS =
  "PROJECT_PEOPLE_RESPONSE_UPDATE_SUCCESS";
export const PROJECT_PEOPLE_RESPONSE_UPDATE_FAILURE =
  "PROJECT_PEOPLE_RESPONSE_UPDATE_FAILURE";

export const PROJECT_PEOPLE_DELETE = "PROJECT_PEOPLE_DELETE";
export const PROJECT_PEOPLE_DELETE_SUCCESS = "PROJECT_PEOPLE_DELETE_SUCCESS";
export const PROJECT_PEOPLE_DELETE_FAILURE = "PROJECT_PEOPLE_DELETE_FAILURE";

export const SEQUENCE_PROCESSOR_SEND_EVENT = "SEQUENCE_PROCESSOR_SEND_EVENT";
export const SEQUENCE_PROCESSOR_SEND_EVENT_SUCCESS = "SEQUENCE_PROCESSOR_SEND_EVENT_SUCCESS";
export const SEQUENCE_PROCESSOR_SEND_EVENT_FAILURE = "SEQUENCE_PROCESSOR_SEND_EVENT_FAILURE";

export const PROJECT_PEOPLE_CLEAN = "PROJECT_PEOPLE_CLEAN";

export const {
  projectPeopleGet,
  projectPeopleGetSuccess,
  projectPeopleGetFailure,
  projectPeopleSearch,
  projectPeopleSearchSuccess,
  projectPeopleSearchFailure,
  projectPeopleMove,
  projectPeopleMoveWithReload,
  projectPeopleMoveSuccess,
  projectPeopleMoveFailure,
  projectPeopleUpdate,
  projectPeopleUpdateSuccess,
  projectPeopleUpdateFailure,
  projectPeopleResponseUpdate,
  projectPeopleResponseUpdateSuccess,
  projectPeopleResponseUpdateFailure,
  projectPeopleDelete,
  projectPeopleDeleteSuccess,
  projectPeopleDeleteFailure,
  sequenceProcessorSendEvent,
  sequenceProcessorSendEventSuccess,
  sequenceProcessorSendEventFailure,
  projectPeopleClean,
} = createActions(
  {},
  PROJECT_PEOPLE_GET,
  PROJECT_PEOPLE_GET_SUCCESS,
  PROJECT_PEOPLE_GET_FAILURE,
  PROJECT_PEOPLE_SEARCH,
  PROJECT_PEOPLE_SEARCH_SUCCESS,
  PROJECT_PEOPLE_SEARCH_FAILURE,
  PROJECT_PEOPLE_MOVE,
  PROJECT_PEOPLE_MOVE_WITH_RELOAD,
  PROJECT_PEOPLE_MOVE_SUCCESS,
  PROJECT_PEOPLE_MOVE_FAILURE,
  PROJECT_PEOPLE_UPDATE,
  PROJECT_PEOPLE_UPDATE_SUCCESS,
  PROJECT_PEOPLE_UPDATE_FAILURE,
  PROJECT_PEOPLE_RESPONSE_UPDATE,
  PROJECT_PEOPLE_RESPONSE_UPDATE_SUCCESS,
  PROJECT_PEOPLE_RESPONSE_UPDATE_FAILURE,
  PROJECT_PEOPLE_DELETE,
  PROJECT_PEOPLE_DELETE_SUCCESS,
  PROJECT_PEOPLE_DELETE_FAILURE,
  SEQUENCE_PROCESSOR_SEND_EVENT,
  SEQUENCE_PROCESSOR_SEND_EVENT_SUCCESS,
  SEQUENCE_PROCESSOR_SEND_EVENT_FAILURE,
  PROJECT_PEOPLE_CLEAN
);
