import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.linkable
        ? `&:hover {
      fill: ${theme.colors.lightRed}; cursor: pointer;}`
        : ""}
    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black)" : ""}
  `,

  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const Config = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <g>
        <path
          d="M23.3,9.9c-0.2,0-0.4-0.1-0.6-0.1l-0.2,0c-0.4-0.1-0.8-0.1-1.1-0.2c-0.1-0.4-0.3-0.7-0.5-1.1L21.9,7
		c0.3-0.4,0.3-0.8-0.1-1.2l-2.5-2.6c-0.4-0.5-0.9-0.3-1.2-0.1l-1.5,1c-0.4-0.2-0.7-0.3-1.1-0.5l-0.3-1.8c-0.1-0.4-0.4-0.7-0.9-0.7
		l-3.6,0c-0.4,0-0.8,0.3-0.9,0.7L9.5,3.7C9.1,3.9,8.8,4,8.4,4.2l-1.5-1C6.5,2.9,6.1,2.9,5.8,3.2l-2,1.9C3.6,5.4,3.4,5.6,3.2,5.8
		C2.8,6.2,2.8,6.6,3.1,7l1.1,1.5C4,8.9,3.8,9.2,3.7,9.6L1.9,9.9c0,0-0.1,0-0.2,0c-0.3,0.1-0.6,0.4-0.6,0.7v3.9
		c0,0.3,0.2,0.7,0.6,0.7c0.2,0,0.4,0.1,0.6,0.1l0.2,0c0.4,0.1,0.7,0.1,1.1,0.2C3.8,16,4,16.3,4.1,16.7c-0.3,0.4-0.5,0.7-0.8,1.1
		l-0.3,0.4c-0.4,0.5-0.1,0.9,0.1,1.2l2.4,2.4c0,0,0.1,0.1,0.1,0.1C6,22.3,6.5,22.3,6.9,22L8.4,21c0.4,0.2,0.8,0.3,1.1,0.5l0.3,1.7
		c0,0.4,0.4,0.7,0.7,0.7h3.9c0.4,0,0.7-0.3,0.7-0.6l0.3-1.8c0.4-0.1,0.7-0.3,1.1-0.5l1.5,1.1c0.4,0.3,0.8,0.2,1.1-0.1
		c0.9-0.9,1.7-1.7,2.6-2.6c0.3-0.3,0.4-0.8,0.1-1.2l-1.1-1.5c0.2-0.4,0.3-0.7,0.5-1.1l1.9-0.3c0.4-0.1,0.6-0.4,0.6-0.7v-3.9
		C23.9,10.3,23.6,10,23.3,9.9z M22.4,13.9l-1.6,0.2c-0.4,0.1-0.7,0.3-0.7,0.7c-0.1,0.5-0.3,0.9-0.6,1.4c-0.2,0.3-0.2,0.7,0.1,1
		l1,1.4c-0.6,0.6-1.2,1.2-1.9,1.9l-1.4-1c-0.3-0.2-0.7-0.2-1-0.1c-0.5,0.3-1,0.5-1.5,0.6c-0.3,0.1-0.6,0.4-0.6,0.7l-0.3,1.5h-2.7
		L11,20.8c-0.1-0.3-0.3-0.6-0.6-0.7c-0.5-0.1-1-0.3-1.5-0.6c-0.3-0.2-0.7-0.2-1,0.1l-1.4,1l-1.9-1.9l0,0c0.3-0.4,0.6-0.9,0.9-1.3
		c0.2-0.3,0.3-0.7,0.1-1c-0.3-0.5-0.5-1-0.6-1.4c-0.1-0.4-0.4-0.6-0.7-0.7c-0.5-0.1-1-0.2-1.5-0.2l-0.2,0v-2.6L4.3,11
		C4.6,11,4.9,10.7,5,10.4c0.1-0.5,0.3-1,0.6-1.5c0.2-0.3,0.1-0.7-0.1-1l-1-1.3c0.1-0.1,0.2-0.2,0.3-0.3l1.6-1.6l1.3,0.9
		c0.3,0.2,0.7,0.2,1,0.1c0.5-0.3,1-0.5,1.4-0.6c0.4-0.1,0.6-0.4,0.6-0.7l0.3-1.7h2.6l0.3,1.6C14.2,4.8,14.5,5,14.7,5
		c0.5,0.2,1,0.4,1.5,0.6c0.3,0.2,0.6,0.1,0.9-0.1l1.4-1l1.9,1.9l-1,1.4c-0.2,0.3-0.2,0.6-0.1,1c0.3,0.5,0.5,0.9,0.6,1.5
		c0.1,0.4,0.4,0.6,0.7,0.7c0.5,0.1,1,0.2,1.5,0.2l0.2,0V13.9z"
        />
        <path
          d="M15.8,9.1c-0.9-0.9-2.2-1.4-3.5-1.3C9.8,7.8,7.7,10,7.7,12.6c0,2.6,2.2,4.8,4.8,4.8c0,0,0,0,0.1,0
		c1.3,0,2.5-0.5,3.4-1.4c0.9-0.9,1.4-2.1,1.4-3.5C17.3,11.2,16.8,10,15.8,9.1z M14.8,14.9c-0.6,0.6-1.5,1-2.3,1c0,0,0,0,0,0
		c0,0,0,0,0,0c-1.8,0-3.3-1.5-3.3-3.3c0-1.8,1.4-3.3,3.2-3.3c0.9,0,1.7,0.3,2.4,0.9c0.6,0.6,1,1.5,1,2.4
		C15.8,13.5,15.5,14.3,14.8,14.9z"
        />
      </g>
    </StyledSvg>
  );
};

export default Config;
