import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.hoverBlue
        ? `&:hover {
      fill: ${theme.colors.blue}; cursor: pointer;}`
        : ""}
    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black)" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const ArrowLeft = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <path
        d="M6.4,13.93l9.58,9.58c0.73,0.73,1.92,0.73,2.65,0c0.73-0.73,0.73-1.92,0-2.65l-8.25-8.25l8.25-8.25
	c0.73-0.73,0.73-1.92,0-2.65c-0.37-0.37-0.85-0.55-1.33-0.55c-0.48,0-0.96,0.18-1.33,0.55L6.4,11.28C5.67,12.01,5.67,13.2,6.4,13.93
	z"
      />
    </StyledSvg>
  );
};

export default ArrowLeft;
