import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import theme from "../../../theme";

import { height, minHeight } from "styled-system";

const StyledCard = styled.div`
  display: flex;
  width: 100%;
  background-color: white;
  justify-content: space-between;
  flex-direction: row;
  flex-flow: wrap;
  box-shadow: 0px 4px 4px #cdcdcd;
`;

const CardContent = styled.div`
  display: flex;
  padding: 10px 20px;
  width: 100%;
  background-color: white;
  ${height}
  ${minHeight}
`;

const CardHeaderTab = styled.div`
  width: 100%;
  height: 54px;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: center;
  padding: 0px 10px;
`;

const CardDivider = styled.div`
  width: 100%;
  height: 15px;
  background: rgb(201, 201, 201);
  background: linear-gradient(
    180deg,
    rgba(231, 231, 231, 1) 0%,
    rgba(255, 255, 255, 1) 70%
  );
`;

const CardTab = styled.div`
  min-width: 5%;
  height: 100%;
  color: ${(props) => (props.error ? theme.colors.orange : 'gray')};

  :hover {
    color: ${(props) => (props.error ? theme.colors.orange : theme.colors.blue)};
    cursor: pointer;
  }
`;
const CardLabel = styled.div`
  font-size: 16px;
  padding: 0px 20px;
  margin-top: 20px;
`;

const SelectIndicator = styled.div`
  width: 100%;
  height: 10px;
  border-bottom: 5px solid ${(props) => (props.error ? theme.colors.orange : theme.colors.blue)};
  position: relative;
  bottom: -5px;
`;

const CardFooter = styled.div`
  width: 100%;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: center;
  align-self: flex-end;
  justify-content: flex-end;
  padding: 10px 20px;
`;

const CardTabs = ({

  labels,
  labelsWithError,
  children,
  height,
  minHeight,
  footer,
  onSaveChange,
  dirtyChanges,
  ...props
}) => {
  const node = useRef();

  const [activeTabId, setState] = useState(0);
  const [clickOutside, setClickOutside] = useState("false");

  const handleTabChange = (index) => {
    onSaveChange();
    setState(index);
  };

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      setClickOutside("false");
    } else {
      // outside click
      setClickOutside("true");
    }
  };

  const hasError = (label) => {
    return labelsWithError.includes(label);
  }

  useEffect(() => {
    if (children) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, [children]);

  useEffect(() => {
    onSaveChange();
  }, [clickOutside]);

  return (
    <StyledCard {...props} ref={node}>
      <CardHeaderTab>
        {labels.map((label, index) => (
          <CardTab
            key={index}
            onClick={() => handleTabChange(index)}
            error={hasError(label)}
          >
            <CardLabel>{label}</CardLabel>
            {index === activeTabId ? <SelectIndicator error={hasError(label)}></SelectIndicator> : null}
          </CardTab>
        ))}
      </CardHeaderTab>
      <CardDivider></CardDivider>
      <CardContent height={height} minHeight={minHeight}>
        {children[activeTabId]}
      </CardContent>
      {footer ? <CardFooter>{footer}</CardFooter> : null}
    </StyledCard>
  );
};

export default CardTabs;
