import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import theme from "@theme";

import Button from "@ui/button/index";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.css";

//icon
import Add from "@ui/icons/add";
import Close from "@ui/icons/close";
import Trash from "@ui/icons/trash";
import LeftArrow from "@ui/icons/arrowLeftSimple";
import RightArrow from "@ui/icons/arrowRightSimple";

const StyledBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99999;
`;

const StyledCardContainer = styled.div`
  position: fixed;
  background: transparent;
  height: 95vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;

  ${(props) => (props.cardTabs ? "width: 85%;" : "width: 80%;")};
  ${(props) => (props.shortWidth ? "width: 85%;" : "")}
`;

const StyledCard = styled.div`
  display: flex;
  width: 100%;
  background-color: white;
  justify-content: space-between;
  flex-direction: row;
  flex-flow: wrap;
  max-height: 100vh;
`;

const StyledSimpleBar = styled(SimpleBar)`
  max-height: 70vh;
  width: 100%;
  margin-top: 15px;
  margin-right: 5px;

  .simplebar-scrollbar:before {
    background: ${theme.colors.grey};
  }
`;

const CardContent = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  background-color: white;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 50px;
`;

const StyledTabsArea = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 75%;
`;
const StyledButtonsArea = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 25%;
`;

const CardHeader = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: center;
  justify-content: space-between;
`;

const CardTitle = styled.div`
  ${(props) => (props.full ? "width: 100%;" : "width: 70%;")};
  height: 100%;
  margin-left: 20px;
  ${(props) => (props.small ? "font-size: 1.1rem;" : "font-size: 1.4rem;")};
  font-weight: 300;
  color: gray;
  text-transform: uppercase;
  vertical-align: middle;
  display: flex;
  align-items: center;
`;

const CardHeaderTab = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: center;
  padding-left: 20px;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.darkBlue};
`;

const CardDivider = styled.div`
  width: 100%;
  height: 15px;
  background: rgb(201, 201, 201);
  background: linear-gradient(
    180deg,
    rgba(231, 231, 231, 1) 0%,
    rgba(255, 255, 255, 1) 70%
  );
`;

const CardTabContainer = styled.div`
  display: flex;
  width: 98%;
  overflow-x: auto;
  scrollbar-color: transparent transparent; /* thumb and track color */
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    width: 0;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
    border: none;
  }
`;

const TabsFlex = styled.div`
  display: flex;
`;

const CardTab = styled.div`
  min-width: 30px;
  white-space: nowrap;
  height: 100%;
  color: ${(props) =>
    props.active ? `${theme.colors.darkBlue}` : `${theme.colors.lightGrey}`};

  :hover {
    color: ${theme.colors.blue};
    cursor: pointer;
  }
`;

const CardLabel = styled.div`
  font-size: 16px;
  padding: 0px 15px;
  margin-top: 15px;
  text-transform: uppercase;
  color: ${(props) =>
    props.withError ? `${theme.colors.lightRed}` : `${theme.colors.grey}`};
`;

const SelectIndicator = styled.div`
  width: 100%;
  height: 10px;
  border-bottom: ${(props) =>
    props.withError
      ? `3px solid ${theme.colors.lightRed}`
      : `3px solid ${theme.colors.darkBlue}`};
  position: relative;
  bottom: 4px;
`;

const CardControl = styled.div`
  width: 35px;
  height: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: flex-end;

  :hover {
    cursor: pointer;
  }
`;

const CardFooter = styled.div`
  width: 100%;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: center;
  align-self: flex-end;
  justify-content: flex-end;
  padding: 10px 20px;
`;

const CardButtonsFooter = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: center;
  justify-content: flex-end;
`;

const CardTextWarning = styled.p`
  font-size: 1rem;
  font-weight: 300;
  color: ${theme.colors.lightGrey};
  padding: 0px 20px;
`;

const StyledButton = styled.button`
  font-family: inherit;
  font-size: 0.9rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  margin: 0px 20px;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 500;
  border: none;
  color: ${theme.colors.white};
  background-color: ${theme.colors.darkBlue};

  &:disabled {
    background-color: ${theme.colors.lighterGrey};
    color: ${theme.colors.lightGrey};

    &:hover {
      cursor: not-allowed;
      box-shadow: none;
    }
  }

  &:hover {
    box-shadow: 0px 2px 2px lightgray;
    cursor: pointer;
  }
`;

const StyledRemoveButton = styled.div`
  height: 20px;
  width: 20px;
  margin-right: 5px;

  &:hover {
    cursor: pointer;
  }
`;

const IconContainer = styled.div`
  height: 15px;
  width: 15px;
  margin-right: 5px;
`;

const ArrowContainer = styled.div`
  display: flex;
  height: 15px;
  width: 15px;
  visibility: ${(props) => (props.showButton ? "visible" : "hidden")};

  &:hover {
    cursor: pointer;
  }
`;

const SequenceModal = ({
  cardTabs, //bool - style related
  children, //card content for each tab - e.: tab[2] show children[2]
  title, // modal title
  textWarning, // fild required message
  handleCloseModal, //close modal & clean form errors handler
  onNewTab, // add tab to modal bar handler
  onRemoveTab, //remove tab from modal bar handler
  labels, //tabs labels
  onSubmit, // finish Modal
  onSubmitTab, // submit each tab
  hasChanges, //bool - input changes - edit
  handleNext, // bool - enable the "next" button -
  onConfirm, // confirm Modal
  cleanConfirmation,
  shortWidth,
  tabErrors, // tabs index with errors
  errors, // useForm errors
  isEdit, // bool - handle edit sequence buttons
  setValue, // useForm
  setError, //useForm
  clearErrors, // useForm
  dirtyChanges,
}) => {
  const [activeTabId, setActiveTabId] = useState(0);
  const [activeScroll, setActiveScroll] = useState(false);

  const scrollContainer = useRef(null);
  const tabsContainer = useRef(null);

  const onCheckScroll = () => {
    if (
      tabsContainer.current.offsetWidth > scrollContainer.current.offsetWidth
    ) {
      setActiveScroll(true);
    } else {
      setActiveScroll(false);
    }
  };

  useEffect(() => { }, [errors]);

  useEffect(() => {
    onCheckScroll();
    onScrollRight();
  }, [tabsContainer.current?.offsetWidth, onNewTab]);

  useEffect(() => {
    if (labels[labels.length - 1] === "Finish")
      setActiveTabId(labels.length - 1);
  }, [labels]);

  useEffect(() => {
    if (
      activeTabId !== labels.length - 1 &&
      labels[labels.length - 1] === "Finish"
    )
      cleanConfirmation();
  }, [activeTabId]);

  const onScrollRight = () => {
    const container = document.getElementById("container");
    sideScroll(container, "right", 0, 6000, 300);
  };

  const onScrollLeft = () => {
    const container = document.getElementById("container");
    sideScroll(container, "left", 0, 6000, 300);
  };

  function sideScroll(element, direction, speed, distance, step) {
    let scrollAmount = 0;
    const slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  }

  //----new sequence - validation on +Step button---------------------------
  const checkSubjectValidation = (value) => {
    if (value === undefined || value.length === 0) {
      setError(`steps.${activeTabId - 1}.template_subject`);
      setValue(`steps.${activeTabId - 1}.template_subject`, null);
    } else {
      setValue(`steps.${activeTabId - 1}.template_subject`, value);
      clearErrors(`steps.${activeTabId - 1}.template_subject`);
    }
  };

  const checkBodyValidation = (value) => {
    if (value === undefined || value.length === 0 || value === "<p></p>\n") {
      setError(`steps.${activeTabId - 1}.template_body`);
      setValue(`steps.${activeTabId - 1}.template_body`, null);
    } else {
      setValue(`steps.${activeTabId - 1}.template_body`, value);
      clearErrors(`steps.${activeTabId - 1}.template_body`);
    }
  };

  const checkProviderValidation = (value) => {
    if (value === undefined || value === null || value.length === 0) {
      setError(`steps.${activeTabId - 1}.provider_type`);
      setValue(`steps.${activeTabId - 1}.provider_type`, null);
    } else {
      setValue(`steps.${activeTabId - 1}.provider_type`, value);
      clearErrors(`steps.${activeTabId - 1}.provider_type`);
    }
  };

  const checkCredentialValidation = (value) => {
    if (value === undefined || value === null || value.length === 0) {
      setError(`steps.${activeTabId - 1}.provider_id`);
      setValue(`steps.${activeTabId - 1}.provider_id`, null);
    } else {
      setValue(`steps.${activeTabId - 1}.provider_id`, value);
      clearErrors(`steps.${activeTabId - 1}.provider_id`);
    }
  };

  const handleNewTab = () => {
    checkProviderValidation(dirtyChanges.steps[activeTabId - 1].provider_type);
    checkCredentialValidation(dirtyChanges.steps[activeTabId - 1].provider_id);
    checkSubjectValidation(
      dirtyChanges.steps[activeTabId - 1].template_subject
    );
    checkBodyValidation(dirtyChanges.steps[activeTabId - 1].template_body);

    if (!errors.steps) {
      onNewTab();
      setActiveTabId(activeTabId + 1);
    }
  };
  //-------------------------------------------------------------------------

  return (
    <StyledBackground>
      <StyledCardContainer cardTabs={cardTabs} shortWidth={shortWidth}>
        <StyledCard>
          <CardHeader>
            <CardTitle>{title}</CardTitle>
            <CardControl onClick={handleCloseModal}>
              <Close variant="grey"></Close>
            </CardControl>
            <CardDivider />
          </CardHeader>

          <CardHeaderTab>
            <StyledTabsArea>
              <ArrowContainer showButton={activeScroll} onClick={onScrollLeft}>
                <LeftArrow hoverBlue variant="darkBlue" />
              </ArrowContainer>

              <CardTabContainer id="container" ref={scrollContainer}>
                <TabsFlex ref={tabsContainer}>
                  {labels.map((name, index) => (
                    <CardTab
                      key={index}
                      onClick={() => setActiveTabId(index)}
                      active={index === activeTabId}
                    >
                      <CardLabel withError={tabErrors?.includes(index)}>
                        {name}
                      </CardLabel>
                      {index === activeTabId ? (
                        <SelectIndicator
                          withError={tabErrors?.includes(index)}
                        />
                      ) : null}
                    </CardTab>
                  ))}
                </TabsFlex>
              </CardTabContainer>

              <ArrowContainer showButton={activeScroll} onClick={onScrollRight}>
                <RightArrow hoverBlue variant="darkBlue" />
              </ArrowContainer>
            </StyledTabsArea>

            <StyledButtonsArea>
              {!isEdit &&
                activeTabId !== 0 &&
                activeTabId !== 1 &&
                activeTabId !== children.length - 1 && (
                  <StyledRemoveButton
                    onClick={() => {
                      setActiveTabId(activeTabId - 1);
                      onRemoveTab(activeTabId);
                    }}
                  >
                    <Trash variant="darkBlue" linkable />
                  </StyledRemoveButton>
                )}

              {onNewTab &&
                activeTabId !== 0 &&
                activeTabId !== children.length - 1 && (
                  <StyledButton
                    disabled={
                      activeTabId !== children.length - 2 || errors.steps
                    }
                    onClick={() => {
                      handleNewTab();
                    }}
                  >
                    <IconContainer>
                      <Add
                        variant={
                          activeTabId !== children.length - 2 || errors.steps
                            ? "lightGrey"
                            : "white"
                        }
                      />
                    </IconContainer>
                    Step
                  </StyledButton>
                )}
            </StyledButtonsArea>
          </CardHeaderTab>

          <StyledSimpleBar forceVisible="y" autoHide={false}>
            <CardContent>{children[activeTabId]}</CardContent>
          </StyledSimpleBar>

          <CardFooter>
            {textWarning && activeTabId !== children.length - 1 && (
              <CardTextWarning>{textWarning}</CardTextWarning>
            )}
            <CardButtonsFooter>
              {onSubmitTab && isEdit && activeTabId === children.length - 1 && (
                <Button
                  key="save"
                  variant={
                    hasChanges && tabErrors.length === 0
                      ? "secondary"
                      : "disabled"
                  }
                  content="Save"
                  onClick={() => onSubmitTab()}
                />
              )}
              {onSubmit &&
                activeTabId === children.length - 1 &&
                children.length > 2 ? (
                <Button
                  key="confirm"
                  variant="secondary"
                  content="Confirm"
                  onClick={onConfirm}
                />
              ) : onSubmit &&
                activeTabId === children.length - 2 &&
                children.length > 2 ? (
                <Button
                  key="finish"
                  variant={tabErrors.length > 0 ? "disabled" : "secondary"}
                  content="Finish"
                  disabled={tabErrors.length > 0}
                  onClick={onSubmit}
                />
              ) : !onSubmit &&
                activeTabId === children.length - 1 &&
                children.length > 1 ? (
                <Button
                  key="close"
                  variant={onSubmitTab ? "secondaryLine" : "secondary"}
                  content="Close"
                  onClick={handleCloseModal}
                />
              ) : (
                <Button
                  key="next"
                  variant={handleNext ? "secondary" : "disabled"}
                  disabled={!handleNext}
                  content="Next"
                  onClick={
                    children.length === 2 && onNewTab
                      ? () => {
                        onNewTab();
                        setActiveTabId(activeTabId + 1);
                      }
                      : () => setActiveTabId(activeTabId + 1)
                  }
                />
              )}
            </CardButtonsFooter>
          </CardFooter>
        </StyledCard>
      </StyledCardContainer>
    </StyledBackground>
  );
};

export default SequenceModal;
