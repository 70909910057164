import { combineEpics, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, flatMap, map, mapTo, tap } from "rxjs/operators";
import { toast } from "react-toastify";
import qs from "qs";

import {
  PROJECTS_GET,
  projectsGet,
  projectsGetSuccess,
  projectsGetFailure,
  PROJECT_GET,
  projectGetSuccess,
  projectGetFailure,
  PROJECT_CREATE,
  projectCreateSuccess,
  projectCreateFailure,
  PROJECT_UPDATE,
  projectUpdateSuccess,
  projectUpdateFailure,
  PROJECT_DELETE,
  projectDeleteSuccess,
  projectDeleteFailure,
  PROJECT_INVITE,
  projectInviteSuccess,
  projectInviteFailure,
  PEOPLE_TO_PROJECT_ASSIGN,
  peopleToProjectAssignSuccess,
  peopleToProjectAssignFailure,
  projectInvitationsGet,
  PROJECT_ANALYTICS_GET,
  projectAnalyticsGetSuccess,
} from "../actions";

const getProjectsEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(PROJECTS_GET),
    flatMap(({ payload: { workspace_id, by_status, meta } }) => {
      return from(
        api
          .get(
            `/workspaces/${workspace_id}/projects`, {
            params: {
              "by_status[]": by_status,
              "meta": meta || false,
            },
            paramsSerializer: (params) => {
              return qs.stringify(params, { arrayFormat: "repeat" });
            },
          })
          .then((resp) => resp)
      ).pipe(
        map(projectsGetSuccess),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map((error) => toast.error(error.detail));
          return of(projectsGetFailure({ err, errors }));
        })
      );
    })
  );

const getProjectEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(PROJECT_GET),
    flatMap(({ payload: { workspace_id, id } }) =>
      from(
        api
          .get(`/workspaces/${workspace_id}/projects/${id}`)
          .then((resp) => resp)
      ).pipe(
        map(projectGetSuccess),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map((error) => toast.error(error.detail));
          return of(projectGetFailure({ err, errors }));
        })
      )
    )
  );

const createProjectEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(PROJECT_CREATE),
    flatMap(
      ({
        payload: {
          workspace_id,
          workflow_id,
          title,
          goal,
          status,
          priority,
          deadline,
          startDate,
          visible,
        },
      }) => {
        return from(
          api
            .post(`/workspaces/${workspace_id}/projects`, {
              data: {
                type: "project",
                attributes: {
                  name: title,
                  description: goal,
                  priority,
                  status,
                  deadline,
                  workflow_id,
                  start_date: startDate,
                  visible: visible,
                },
              },
            })
            .then((resp) => resp)
        ).pipe(
          map(projectCreateSuccess),
          tap((res) => {
            toast.success(`Project ${title} created correctly.`);
          }),
          mapTo(projectsGet({ workspace_id })),
          catchError((err) => {
            const errors = err.response ? err.response.data.errors : [];
            errors.map((error) => toast.error(error.detail));
            return of(projectCreateFailure({ err, errors }));
          })
        );
      }
    )
  );

const updateProjectEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(PROJECT_UPDATE),
    flatMap(
      ({
        payload: {
          workspace_id,
          id,
          title,
          goal,
          status,
          priority,
          deadline,
          startDate,
          visible,
        },
      }) => {
        return from(
          api
            .put(`/workspaces/${workspace_id}/projects/${id}`, {
              data: {
                type: "project",
                attributes: {
                  name: title,
                  description: goal,
                  priority,
                  status,
                  deadline,
                  start_date: startDate,
                  visible: visible,
                },
              },
            })
            .then((resp) => resp)
        ).pipe(
          map(projectUpdateSuccess),
          mapTo(projectsGet({ workspace_id })),
          catchError((err) => {
            const errors = err.response ? err.response.data.errors : [];
            errors.map((error) => toast.error(error.detail));
            return of(projectUpdateFailure({ err, errors }));
          })
        );
      }
    )
  );

const deleteProjectEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(PROJECT_DELETE),
    flatMap(({ payload: { workspace_id, id, by_status } }) => {
      return from(
        api
          .delete(`/workspaces/${workspace_id}/projects/${id}`)
          .then((resp) => resp)
      ).pipe(
        map(projectDeleteSuccess),
        tap((res) => {
          toast.success(`Project deleted.`);
        }),
        mapTo(projectsGet({ workspace_id, by_status })),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map((error) => toast.error(error.detail));
          return of(projectDeleteFailure({ err, errors }));
        })
      );
    })
  );

const inviteProjectEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(PROJECT_INVITE),
    flatMap(
      ({ payload: { project_id, email, project_role, workspace_role } }) => {
        return from(
          api.post(`/invitations`, {
            data: {
              type: "invitation",
              attributes: {
                user_to_email: email,
                invitable_id: project_id,
                invitable_type: "Project",
                project_role: project_role.toLowerCase(),
                workspace_role: workspace_role.toLowerCase(),
              },
            },
          })
        ).pipe(
          map(projectInviteSuccess),
          mapTo(projectInvitationsGet({ project_id: project_id })),
          catchError((err) => {
            const errors = err.response ? err.response.data.errors : [];
            errors.map((error) => toast.error(error.detail));
            return of(projectInviteFailure({ err, errors }));
          })
        );
      }
    )
  );

const assignPeopleToProjectEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(PEOPLE_TO_PROJECT_ASSIGN),
    flatMap(({ payload: { workspace_id, project_id, people_ids } }) => {
      return from(
        api
          .post(
            `/workspaces/${workspace_id}/projects/${project_id}/assign_people`,
            {
              data: {
                type: "project",
                attributes: {
                  people_attributes: people_ids,
                },
              },
            }
          )
          .then((resp) => resp)
      ).pipe(
        map(peopleToProjectAssignSuccess),
        tap((res) => {
          toast.success(`Candidates assigned`);
        }),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map((error) => toast.error(error.detail));
          return of(peopleToProjectAssignFailure({ err, errors }));
        })
      );
    })
  );

const getProjectAnalyticsEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(PROJECT_ANALYTICS_GET),
    flatMap(({ payload: { workspace_id, id, stage_id } }) =>
      from(
        api
          .get(`/workspaces/${workspace_id}/projects/${id}/export_analytics`, {
            params: {
              stage_id: stage_id,
            },
          })
          .then((resp) => resp)
      ).pipe(
        map(projectAnalyticsGetSuccess),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map((error) => toast.error(error.detail));
          return of(projectsGetFailure({ err, errors }));
        })
      )
    )
  );

export default combineEpics(
  getProjectsEpic,
  createProjectEpic,
  updateProjectEpic,
  deleteProjectEpic,
  inviteProjectEpic,
  assignPeopleToProjectEpic,
  getProjectEpic,
  getProjectAnalyticsEpic
);
