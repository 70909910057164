import { combineEpics, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, flatMap, map, mapTo, mergeAll } from "rxjs/operators";
import { toast } from "react-toastify";

import {
  PROJECT_USER_UPDATE,
  projectUserUpdateSuccess,
  projectUserUpdateFailure,
  PROJECT_USER_DELETE,
  projectUserDeleteSuccess,
  projectUserDeleteFailure,
  projectsGet,
} from "../actions";

const updateProjectUsersEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(PROJECT_USER_UPDATE),
    flatMap(({ payload: { project_id, id, role } }) => {
      return from(
        api
          .put(`/projects/${project_id}/project_users/${id}`, {
            data: {
              type: "project_user",
              attributes: {
                role: role.toLowerCase(),
              },
            },
          })
          .then((resp) => resp)
      ).pipe(
        map(projectUserUpdateSuccess),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map(error => toast.error(error.detail));
          return of(projectUserUpdateFailure({ err, errors }));
        })
      );
    })
  );

const deleteProjectUsersEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(PROJECT_USER_DELETE),
    flatMap(({ payload: { workspace_id, project_id, id } }) => {
      return from(
        api
          .delete(`/projects/${project_id}/project_users/${id}`)
          .then((resp) => resp)
      ).pipe(
        mapTo([projectUserDeleteSuccess({}), projectsGet({ workspace_id })]),
        mergeAll(),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map(error => toast.error(error.detail));
          return of(projectUserDeleteFailure({ err, errors }));
        })
      );
    })
  );

export default combineEpics(updateProjectUsersEpic, deleteProjectUsersEpic);
