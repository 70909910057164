import React from "react";
import theme from "../../../theme";
import styled from "styled-components";

import Box from "../../ui/box";
import Card from "../../ui/card";
import Row from "../../ui/row";
import Image from "../../ui/../../assets/img/workspace.svg";
import Flex from "../../ui/flex";
import Text from "../../ui/text";
import PieChart from "../../ui/charts/pie";
import Button from "../../ui/button/index";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";

//icons
import Export from "../../ui/icons/export";

import * as R from "ramda";
import { CSVLink, CSVDownload } from "react-csv";

import { analyticsFilename, workspaceCsvHeaders } from "../../../utils/files";

const RowTitle = styled(Link)`
  font-family: "Open Sans";
  color: ${theme.colors.blue};
  font-size: 1.2rem;
  padding-left: 3px;
  width: 100%;
  font-weight: 200;
  text-decoration: none;
  &:hover {
    text-shadow: 0px 0px 6px ${theme.colors.lightBlue};
  }
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DataNumber = styled(Text)`
  margin: 5px;
  align-items: center;
  font-size: 1.5rem;
  display: flex;
  flex-direction: row;
  color: ${theme.colors.blue};
  font-family: "Open Sans";
`;

const DataLabel = styled(Text)`
  align-items: center;
  font-size: 0.8rem;
  display: flex;
  flex-direction: row;
  color: ${theme.colors.grey};
  font-family: "Open Sans";
`;

const DataLabelSmall = styled(Text)`
  align-items: center;
  font-size: 0.7rem;
  display: flex;
  flex-direction: row;
  color: ${theme.colors.grey};
  font-family: "Open Sans";
`;

const StyledText = styled.p`
  font-size: ${(props) => (props.big ? "1.2rem" : "1.1rem")};
  font-weight: 300;
  line-height: 1.5rem;
  ${(props) => (props.short ? "margin: 10px 0px" : "")}
`;

const StyledIcon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
`;

const Workspace = ({
  projects,
  projectsLink,
  currentWorkspace,
  workspaceAnalytics,
}) => {
  const statsWithProjectName = (project) => {
    let stats = project.meta?.stats || {};
    stats.project = project.attributes?.name;
    return [stats];
  };

  return (
    <Flex
      paddingTop={"64px"}
      alignItems={"center"}
      minHeight={"100vh"}
      width={"100%"}
      flexDirection={"column"}
      justifyContent={"flex-start"}
      px={{ sm: 2, md: 0 }}
      overflow="hidden"
      backgroundColor={theme.colors.lighterGrey}
    >
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        width={"95%"}
        mt={"30px"}
        mb={"15px"}
        backgroundColor={theme.colors.lighterGrey}
      >
        {projects?.data.length === 0 && (
          <Card
            imageBlock={Image}
            textBlock={
              <Box>
                <StyledText big>Welcome to Workspace!</StyledText>
                <StyledText>
                  Here you can manage your projects and groups of people,
                  <br /> create worflows and invite users to work together.
                </StyledText>
                <StyledText>
                  You can also configure the credentials you need in your
                  projects and the message sequences that you are going to send
                  to your candidates.
                </StyledText>
                <StyledText>
                  Explore the menu on the left and discover all the features
                  <br /> that TeamSourced offers.
                </StyledText>
              </Box>
            }
          />
        )}

        <Card
          fullHeader
          title="Progress"
          smallTitle
          icon={
            <CSVLink
              headers={workspaceCsvHeaders()}
              data={workspaceAnalytics}
              filename={analyticsFilename(
                currentWorkspace.attributes?.name || "workspace"
              )}
            >
              <StyledIcon>
                <Export variant="red" linkable data-tip data-for="titleTooltip" />
                <ReactTooltip id="titleTooltip" aria-haspopup="true">
                  Export Workspace progress CSV
                </ReactTooltip>
              </StyledIcon>
            </CSVLink>
          }
        >
          {projects?.data.map((project, index) => (
            <Row
              flat
              key={index}
              icon={
                <CSVLink
                  data={statsWithProjectName(project)}
                  headers={workspaceCsvHeaders()}
                  filename={analyticsFilename(project.attributes.name)}
                >
                  <Export
                    variant="red"
                    linkable
                    data-tip
                    data-for="projectTooltip"
                  />
                </CSVLink>
              }
            >
              <Box width="20%" paddingLeft="15px">
                <RowTitle
                  to={`/workspaces/${currentWorkspace.attributes?.id}/project/${project.id}`}
                >
                  {project.attributes.name}
                </RowTitle>
              </Box>
              <Box width="60%">
                <StatusContainer>
                  <DataContainer>
                    <DataNumber>{project.meta?.stats.sourced || "-"}</DataNumber>
                    <DataLabel>Sourced</DataLabel>
                  </DataContainer>
                  <DataContainer>
                    <DataNumber>
                      {project.meta?.stats.contacted || "-"}
                    </DataNumber>
                    <DataLabel>Contacted</DataLabel>
                  </DataContainer>
                  <DataContainer>
                    <DataNumber>{project.meta?.stats.replies || "-"}</DataNumber>
                    <DataLabel>Replies</DataLabel>
                  </DataContainer>
                  <DataContainer>
                    <DataNumber>
                      {project.meta?.stats.interested || "-"}
                    </DataNumber>
                    <DataLabel>Interested</DataLabel>
                  </DataContainer>
                  <DataContainer>
                    <DataNumber>
                      {project.meta?.stats.not_interested || "-"}
                    </DataNumber>
                    <DataLabel>Not Interested</DataLabel>
                  </DataContainer>
                  <DataContainer>
                    <DataNumber>
                      {project.meta?.stats.follow_up || "-"}
                    </DataNumber>
                    <DataLabel>Follow-Up</DataLabel>
                  </DataContainer>
                </StatusContainer>
              </Box>
              <Flex width="10%" justifyContent="flex-end">
                <Flex
                  justifyContent="center"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Box width="100px" height="55px">
                    <PieChart openedRate={project.meta?.stats.open_rate} />
                  </Box>
                  <DataLabelSmall>Open Rate</DataLabelSmall>
                </Flex>
              </Flex>
              <ReactTooltip id="projectTooltip" aria-haspopup="true">
                Export Project progress CSV
              </ReactTooltip>
            </Row>
          ))}
          {projects.data.length === 0 && (
            <Flex width="100%" alignItems="center" flexDirection="column">
              <StyledText short>
                There are no projects in this workspace
              </StyledText>
              <StyledText short>
                Here you can create your first
                <Link
                  to={projectsLink}
                  style={{
                    fontSize: "1rem",
                    textDecoration: "none",
                    color: theme.colors.lightRed,
                    borderBottom: `1px solid ${theme.colors.lightRed}`,
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                >
                  Project
                </Link>
              </StyledText>
            </Flex>
          )}
        </Card>
      </Flex>
    </Flex>
  );
};

export default Workspace;
