/* eslint-disable react/display-name */
/* eslint-disable default-case */

import React, { forwardRef, useState, useEffect } from "react";
import theme from "../../../theme";
import {
  bool,
  element,
  func,
  number,
  string,
  oneOfType,
  node,
  object,
} from "prop-types";
import { color, typography, height, flex, layout } from "styled-system";
import styled, { css } from "styled-components";

// Components
import Box from "../box";
import Text from "../text";
import Valid from "../icons/valid";

const StyledContainer = styled(Box)`
  position: relative;
  width: auto;
  display: ${(props) => (props.hidden ? "none" : "block")};

  ${({ onClick }) => onClick && `cursor: pointer;`};
  ${flex};
  ${layout};
`;

const StyledIcon = styled.div`
  ${({ small }) =>
    small ? `height: 15px; width: 15px;` : `height: 23px; width: 23px;`}
  ${({ disabled }) =>
    disabled &&
    `filter: grayscale(100%);

    :hover {
      cursor: default;}`}
  ${({ iconBtn, disabled }) =>
    iconBtn &&
    !disabled &&
    `&:hover {
      cursor: pointer;
    }
  `}
`;

const StyledLengthCount = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.8rem;
`;

const StyledRequired = styled.span`
  color: ${theme.colors.red};
  font-size: 1rem;
  padding-left: 4px;
`;

const StyledInputContainer = styled(Box)`
  border-color: ${({ error, disabled, theme: { colors } }) =>
    error
      ? theme.colors.red
      : disabled
        ? theme.colors.lightGrey
        : color};
  background-color: ${({ disabled, theme: { colors } }) =>
    disabled ? theme.colors.lighterGrey : ""};
  border-style: solid;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  ${(props) => (props.short ? `height: 40px;` : ` height: 100%;`)}

  ${({ disabled, error, theme: { colors } }) =>
    !disabled &&
    `&:hover {
      border-color: ${error ? theme.colors.red : color};
    }
  `}

  ${({ multiline }) =>
    multiline
      ? `border-width: 1px; border-radius: 3px; margin-top: 7px;`
      : `border-width: 0 0 1px 0;`}
  ${color}
`;

const commonInputStyles = css`
  color: ${({ disabled, theme: { colors } }) => {
    if (disabled) {
      return theme.colors.lightGrey;
    }
    return theme.colors.grey;
  }};
  ${({ readOnly }) => readOnly && `cursor: inherit;`}
  background: transparent;
  border: 0;
  font-family: ${theme.fonts[1]};
  font-size: 1rem;
  display: block;
  width: 100%;

  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${({ error, disabled, theme: { colors } }) =>
    error
      ? theme.colors.grey
      : disabled
        ? theme.colors.lightGrey
        : theme.colors.darkBlue};

    font-style: italic;
    font-size: 0.8rem;
  }
`;

const StyledInput = styled.input`
  ${commonInputStyles}
  height: auto;
  padding-top: 12px;
  padding-bottom: 12px;

  ${({ confirmed, disabled, error }) =>
    confirmed || disabled || error ? `padding-right: 28px;` : 0};
  ${({ icon, iconBtn }) => (icon && !iconBtn ? `padding-left: 20px; ` : 0)};
  ${({ icon, iconBtn }) => (iconBtn && icon ? `padding-right: 20px;` : 0)};
  ${color}
  ${typography}

  &[type="number"] {
    padding-top: 6px;
    padding-bottom: 6px;
    appearance: textfield;
    margin: 0;
  }
  &::-ms-clear {
    display: none;
  }
  &::placeholder {
    ${color}
  }

`;

const StyledTextarea = styled.textarea`
  ${commonInputStyles}
  padding: 0 30px 0 0;
  resize: none;
  ${height}
  ${color}
  ${typography}
  -ms-overflow-style: none;

  &::placeholder {
    ${color}
  }
`;

const StyledError = styled.span`
  color: ${theme.colors.red};
  font-size: 0.7rem;
  font-weight: 200;
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
`;

const propTypes = {
  confirmed: bool,
  disabled: bool,
  error: bool,
  errorMsg: string,
  icon: element,
  iconBtn: bool,
  label: oneOfType([string, node]),
  loading: bool,
  loadingIcon: element,
  multiline: bool,
  name: string,
  onChange: func,
  onClick: func,
  onEnter: func,
  onEsc: func,
  placeholder: string,
  readOnly: bool,
  rows: number,
  showCharLimitCount: bool,
  type: string,
  value: oneOfType([string, number]),
  required: bool,
  short: bool,
  autoFocus: bool,
  forwardedRef: object,
};

const Input = forwardRef(
  (
    {
      confirmed = false,
      disabled = false,
      error: errorProp = false,
      errorMsg = null,
      icon,
      onClick,
      onEnter,
      onEsc,
      label,
      loading = false,
      loadingIcon,
      maxLength = 500,
      multiline = false,
      name,
      height,
      onChange = () => null,
      placeholder,
      readOnly = false,
      rows = null,
      showCharLimitCount = false,
      type = "text",
      defaultValue,
      value = defaultValue || "",
      iconBtn,
      required,
      hidden,
      short,
      autoFocus,
      forwardedRef,
      tabIndex,
      color,
      ...props
    },
    ref
  ) => {
    const [error, setError] = useState(errorProp);

    const adjustTextAreaContent = () => {
      let elem = document.getElementById("textarea-${name}");

      if (elem) {
        elem.style.height = "auto";
        elem.style.height = elem.scrollHeight + "px";
      }
    };

    useEffect(() => {
      if (errorProp !== error) {
        setError(errorProp);
      }

      if (showCharLimitCount && !errorProp) {
        setError(value.length > maxLength);
      }

      if (showCharLimitCount && errorProp) {
        setError(errorProp);
      }
      adjustTextAreaContent();
    }, [errorProp, value]);

    // This is here to avoid submitting form on Enter
    // (in case this component is inside a form)
    const handleKeyPressed = (event) => {
      if (!readOnly && !disabled) {
        switch (event.key) {
          case "Enter":
            if (onEnter && !event.shiftKey) {
              event.preventDefault();
              onEnter(event);
            }
            return;
          case "Escape":
            onEsc && onEsc();
            return;
        }
      }
    };

    const handleChange = (event) =>
      !readOnly && !disabled ? onChange(event) : null;

    return (
      <StyledContainer
        hidden={hidden}
        bg={"transparent"}
        onClick={onClick}
        {...props}
      >
        {label && (
          <Text
            fontSize={"0.9rem"}
            fontFamily={theme.fonts[1]}
            fontWeight={0}
            mb={1}
            pt={2}
          >
            {label}
            {required && !disabled ? <StyledRequired>*</StyledRequired> : null}
          </Text>
        )}

        <StyledInputContainer
          disabled={disabled}
          error={error}
          pr={showCharLimitCount ? 5 : error || confirmed ? "52px" : null}
          px={2}
          py={!multiline ? 0 : "10px"}
          icon={icon}
          confirmed={confirmed}
          loading={loading.toString()}
          multiline={multiline}
          color={color || theme.colors.blue}
        >
          {icon && !iconBtn ? (
            <StyledIcon disabled={disabled}>{icon}</StyledIcon>
          ) : null}

          {!multiline ? (
            <StyledInput
              tabIndex={tabIndex}
              autoFocus={autoFocus}
              confirmed={confirmed}
              disabled={disabled}
              error={error}
              icon={icon}
              iconBtn={iconBtn}
              loadingIcon={loadingIcon}
              maxLength={maxLength}
              name={name}
              onChange={handleChange}
              onKeyDown={handleKeyPressed}
              placeholder={placeholder}
              readOnly={readOnly}
              ref={forwardedRef}
              type={type}
              value={value}
              color={color}
            />
          ) : (
            <StyledTextarea
              confirmed={confirmed}
              disabled={disabled}
              error={error}
              height={!rows ? height : null}
              maxLength={maxLength}
              name={name}
              onChange={handleChange}
              onKeyDown={handleKeyPressed}
              placeholder={placeholder}
              readOnly={readOnly}
              ref={ref}
              rows={rows}
              type={type}
              value={value}
              color={color}
              id={"textarea-${name}"}
            />
          )}

          {iconBtn && icon ? (
            <StyledIcon iconBtn={iconBtn} disabled={disabled}>
              {icon}
            </StyledIcon>
          ) : null}

          {loading && loadingIcon ? loadingIcon : null}

          {confirmed ? (
            <StyledIcon small>
              <Valid variant="blue"></Valid>
            </StyledIcon>
          ) : null}
        </StyledInputContainer>

        {showCharLimitCount ? (
          <Box bg={"transparent"} mt={2}>
            <StyledLengthCount>{maxLength - value.length}</StyledLengthCount>
          </Box>
        ) : null}

        <StyledError show={error && errorMsg}>{errorMsg}</StyledError>
      </StyledContainer>
    );
  }
);

Input.propTypes = propTypes;

export default Input;
