import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as R from "ramda";

//Actions
import { sequenceCreate, integrationsGet } from "@actions";

//Reducers
import { getIntegrations, getWorkspaceCurrent } from "@reducer";

//Component
import { NewSequenceModal } from "@components/domain/sequences";

// Wysiwyg editor
import { EditorState, Modifier, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

//Utils
import { countriesList } from "@utils/countries";

//Hooks
import { useSequenceForm } from "@hooks";

const NewProjectModalContainer = ({ showButton, variant }) => {
  const dispatch = useDispatch();
  const workspaceId = useSelector(getWorkspaceCurrent);
  const [showModal, setShowModal] = useState(false);
  const [editorStates, setEditorStates] = useState([]);
  const [convertedTemplates, setConvertedTemplates] = useState([]);
  const [steps, setSteps] = useState([]);
  const countries = countriesList();
  const integrations = useSelector(getIntegrations);
  const [isConfirmationActive, setIsConfirmationActive] = useState(false);
  const [dirtyChanges, setDirtyChanges] = useState({
    allow_send_weekend: true,
    allow_send_holiday: true,
    has_review: false,
    holiday_country: "US",
    payload_fields: { defaults: [], customs: [] },
  });

  const {
    handleInputName,
    handleInsertSubjectField,
    handleDropdown,
    handleInputFocus,
    handleSubjectBlur,
    handleSubmitNewVariable,
    inputFocused,
    candidateVariables,
    projectVariables,
    customFieldsNames,
    defaultFieldsNames,
    handleHasProviderSignature,
  } = useSequenceForm(null, null, dirtyChanges, setDirtyChanges);

  const handleInputChange = (event, index) => {
    if (index !== undefined && index !== null) {
      let steps = { ...dirtyChanges.steps };
      let field = event.target.name.split(".")[2];
      steps[index][field] = event.target.value;

      setDirtyChanges({
        ...dirtyChanges,
        steps,
      });
    } else {
      setDirtyChanges({
        ...dirtyChanges,
        [event.target.name]: event.target.value,
      });
    }
  };

  const cleanConfirmation = () => {
    setIsConfirmationActive(false);
  };

  const handleSubmitClick = () => {
    setIsConfirmationActive(true);
  };

  const handleConfirmedSubmitClick = () => {
    let steps = { ...dirtyChanges.steps };

    Object.values(steps).forEach((step) => {
      delete step.provider_type;
    });

    dispatch(
      sequenceCreate({
        ...dirtyChanges,
        steps: steps,
        workspace_id: workspaceId,
      })
    );
    closeModal();
  };

  useEffect(() => {
    setDirtyChanges({
      allow_send_weekend: false,
      allow_send_holiday: false,
      has_review: false,
      holiday_country: "US",
      payload_fields: { defaults: [], customs: [] },
      timezone: "America/Chicago",
    });
    setSteps([]);
    setEditorStates([]);
  }, [showModal]);

  useEffect(() => {
    if (workspaceId) {
      dispatch(integrationsGet({ workspace_id: workspaceId }));
    }
  }, [workspaceId]);

  const createNewStep = () => {
    editorStates.push(EditorState.createEmpty());
    let previousProviderType = steps[steps.length - 1]?.provider_type || null;
    let previousProviderId = steps[steps.length - 1]?.provider_id || null;
    let previousCredential = steps[steps.length - 1]?.credential || null;
    let previousCredentialEmail = steps[steps.length - 1]?.credential_email || null;
    let previousCredentialFrom = steps[steps.length - 1]?.from || null;
    let previousWorkdayStart = steps[steps.length - 1]?.workday_start || "8am";
    let previousWorkdayEnd = steps[steps.length - 1]?.workday_end || "8pm";
    let newStep = {
      position: steps.length + 1,
      type: "Step::Message",
      name: `Step ${steps.length + 1}`,
      delay: (steps.length === 0) ? "0" : "1440",
      custom_fields: [],
      default_fields: [],
      template_subject: "",
      template_body: "",
      provider_type: previousProviderType,
      provider_id: previousProviderId,
      credential: previousCredential,
      credential_email: previousCredentialEmail,
      from: previousCredentialFrom,
      has_provider_signature: true,
      workday_start: previousWorkdayStart,
      workday_end: previousWorkdayEnd,
    };

    steps[steps.length] = newStep;
    setDirtyChanges({
      ...dirtyChanges,
      steps,
    });
  };

  const handleRemoveStep = (value) => {
    let newSteps = [...steps];
    if (newSteps[value]) {
      newSteps.splice(value, 1);
    }

    let newEditorStates = [...editorStates];
    if (newEditorStates[value]) {
      newEditorStates.splice(value, 1);
    }

    newSteps = newSteps.map((step, index) => ({
      ...step,
      position: index + 1,
    }));

    setSteps(newSteps);
    setDirtyChanges({
      ...dirtyChanges,
      steps: newSteps,
    });
    setEditorStates(newEditorStates);
  };

  const handleAllowSendHoliday = (value) => {
    setDirtyChanges({
      ...dirtyChanges,
      allow_send_holiday: value,
    });
  };

  const handleAllowSendWeekend = (value) => {
    setDirtyChanges({
      ...dirtyChanges,
      allow_send_weekend: value,
    });
  };

  const handleHasReview = (value) => {
    setDirtyChanges({
      ...dirtyChanges,
      has_review: value,
    });
  };

  const closeModal = () => {
    setSteps([]);
    setShowModal(false);
    setIsConfirmationActive(false);
  };

  const handleEditorChange = (state, index) => {
    const newEditorStates = [...editorStates];
    newEditorStates[index] = state;
    setEditorStates(newEditorStates);
    convertContentToHTML(index, state);
  };

  const convertContentToHTML = (index, editor) => {
    let steps = { ...dirtyChanges.steps };
    let currentContentAsHTML = draftToHtml(
      convertToRaw(editor.getCurrentContent())
    );

    convertedTemplates[index] = currentContentAsHTML;
    setConvertedTemplates(convertedTemplates);
    steps[index]["template_body"] = currentContentAsHTML;

    setDirtyChanges({
      ...dirtyChanges,
      steps,
    });
  };

  const handleInsertField = (field, index) => {
    let input = inputFocused;
    if (customFieldsNames().includes(field) || defaultFieldsNames().includes(field)) {
      if (input === "template_subject") handleInsertSubjectField(field, index);
      if (input === "editor") insertEditorField(field, index);
    }
  };

  const insertEditorField = (field, index) => {
    const newContentState = Modifier.insertText(
      editorStates[index].getCurrentContent(),
      editorStates[index].getSelection(),
      "{{" + field + "}}"
    );
    const newEditor = EditorState.push(
      editorStates[index],
      newContentState,
      "insert-fragment"
    );
    editorStates[index] = newEditor;
    setEditorStates(editorStates);
    convertContentToHTML(index, newEditor);
  };

  const handleSelectCountry = (event, value) => {
    let country = countries.filter(function (e) {
      return e.name === value;
    })[0];
    setDirtyChanges({
      ...dirtyChanges,
      ["holiday_country"]: country["code"],
    });
  };

  const handleSelectTimezone = (event, value) => {
    setDirtyChanges({
      ...dirtyChanges,
      ["timezone"]: value,
    });
  };

  const cardTabsLabels = () => {
    if (isConfirmationActive === true) {
      return [
        "Start",
        ...steps.map((_, index) => `Step ${index + 1}`),
        "Finish",
      ];
    } else {
      return ["Start", ...steps.map((_, index) => `Step ${index + 1}`)];
    }
  };

  const handleRemoveField = (field, index) => {
    let payload_fields = { ...dirtyChanges.payload_fields };
    if (customFieldsNames().includes(field)) {
      let indexToRemove = R.findIndex(R.propEq("name", field))(payload_fields["customs"]);
      payload_fields["customs"].splice(indexToRemove, 1);
    }
    setDirtyChanges({
      ...dirtyChanges,
      ["payload_fields"]: payload_fields,
    });
  };

  return (
    <NewSequenceModal
      dirtyChanges={dirtyChanges}
      handleSubmitClick={handleSubmitClick}
      handleConfirmedSubmitClick={handleConfirmedSubmitClick}
      handleInputChange={handleInputChange}
      handleInputName={handleInputName}
      workspaceId={workspaceId}
      handleDropdown={handleDropdown}
      showModal={showModal}
      showButton={showButton}
      variant={variant}
      openModal={() => setShowModal(true)}
      closeModal={closeModal}
      createNewStep={createNewStep}
      handleAllowSendHoliday={handleAllowSendHoliday}
      handleAllowSendWeekend={handleAllowSendWeekend}
      handleHasReview={handleHasReview}
      handleSelectCountry={handleSelectCountry}
      handleSelectTimezone={handleSelectTimezone}
      handleRemoveStep={handleRemoveStep}
      steps={steps}
      editorStates={editorStates}
      handleInsertField={handleInsertField}
      handleEditorChange={handleEditorChange}
      handleSubmitNewVariable={handleSubmitNewVariable}
      handleInputFocus={handleInputFocus}
      handleSubjectBlur={handleSubjectBlur}
      integrations={integrations}
      cardTabsLabels={cardTabsLabels}
      isConfirmationActive={isConfirmationActive}
      cleanConfirmation={cleanConfirmation}
      customFieldsNames={customFieldsNames}
      handleHasProviderSignature={handleHasProviderSignature}
      handleRemoveField={handleRemoveField}
      candidateVariables={candidateVariables}
      projectVariables={projectVariables}
      asyncReload={() =>
        dispatch(integrationsGet({ workspace_id: workspaceId }))
      }
    />
  );
};

export default NewProjectModalContainer;
