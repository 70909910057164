import { combineEpics, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap, map, mapTo, flatMap } from "rxjs/operators";
import { toast } from "react-toastify";

import {
  PEOPLE_GET,
  peopleGet,
  peopleGetSuccess,
  peopleGetFailure,
  PERSON_GET,
  personGetSuccess,
  personGetFailure,
  TALENT_POOL_GET,
  TALENT_POOL_PAGE_GET,
  talentPoolGet,
  talentPoolGetSuccess,
  talentPoolGetFailure,
  PERSON_UPDATE,
  personUpdateSuccess,
  personUpdateFailure,
  TALENT_POOL_PERSON_UPDATE,
  talentPoolUpdateSuccess,
  talentPoolUpdateFailure,
  PEOPLE_IMPORT,
  peopleImportSuccess,
  peopleImportFailure,
} from "../actions";

const getPeopleEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(PEOPLE_GET),
    mergeMap(({ payload: { workspace_id, search_by, url, limit } }) => {
      const getPath = url
        ? url
        : `/workspaces/${workspace_id}/people?search_by=${search_by}&limit=${limit}`;

      return from(api.get(getPath).then((resp) => resp)).pipe(
        map(peopleGetSuccess),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map(error => toast.error(error.detail));
          return of(peopleGetFailure({ err, errors }));
        })
      );
    })
  );

const updatePersonEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(PERSON_UPDATE),
    mergeMap(
      ({
        payload: {
          id,
          first_name,
          last_name,
          email,
          email_second,
          email_work,
          company,
          title,
          phone,
          city,
          state,
          country,
          linkedin,
          github,
          twitter,
          facebook,
          degree,
          website,
          workspace_id,
          search_by,
          limit,
        },
      }) => {
        return from(
          api
            .put(`/people/${id}`, {
              data: {
                type: "person",
                id,
                attributes: {
                  first_name,
                  last_name,
                  email,
                  email_second,
                  email_work,
                  phone,
                  city,
                  state,
                  country,
                  linkedin,
                  github,
                  twitter,
                  facebook,
                  degree,
                  website,
                  company,
                  title,
                },
              },
            })
            .then((resp) => resp)
        ).pipe(
          map(personUpdateSuccess),
          mapTo(peopleGet({ workspace_id, search_by, limit })),
          catchError((err) => {
            const errors = err.response ? err.response.data.errors : [];
            errors.map(error => toast.error(error.detail));
            return of(personUpdateFailure({ err, errors }));
          })
        );
      }
    )
  );

const getPersonEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(PERSON_GET),
    mergeMap(({ payload: { id } }) => {
      return from(api.get(`/people/${id}`).then((resp) => resp)).pipe(
        map(personGetSuccess),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map(error => toast.error(error.detail));
          return of(personGetFailure({ err, errors }));
        })
      );
    })
  );

const getTalentPoolEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(TALENT_POOL_GET),
    mergeMap(({ payload: { search_by, limit } }) => {
      return from(
        api
          .get(`/people?search_by=${search_by}&limit=${limit}`)
          .then((resp) => {
            return resp;
          })
      ).pipe(
        map(talentPoolGetSuccess),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map(error => toast.error(error.detail));
          return of(talentPoolGetFailure({ err, errors }));
        })
      );
    })
  );

const updateTalentPoolEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(TALENT_POOL_PERSON_UPDATE),
    mergeMap(
      ({
        payload: {
          id,
          first_name,
          last_name,
          email,
          email_second,
          email_work,
          company,
          title,
          phone,
          city,
          state,
          country,
          linkedin,
          github,
          twitter,
          facebook,
          website,
          degree,
          search_by,
          limit,
        },
      }) => {
        return from(
          api
            .put(`/people/${id}`, {
              data: {
                type: "person",
                id,
                attributes: {
                  first_name,
                  last_name,
                  email,
                  email_second,
                  email_work,
                  phone,
                  city,
                  state,
                  country,
                  linkedin,
                  github,
                  twitter,
                  facebook,
                  website,
                  degree,
                  company,
                  title,
                },
              },
            })
            .then((resp) => resp)
        ).pipe(
          map(talentPoolUpdateSuccess),
          mapTo(talentPoolGet({ search_by, limit })),
          catchError((err) => {
            const errors = err.response ? err.response.data.errors : [];
            errors.map(error => toast.error(error.detail));
            return of(talentPoolUpdateFailure({ err, errors }));
          })
        );
      }
    )
  );

const getTalentPoolPageEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(TALENT_POOL_PAGE_GET),
    mergeMap(({ payload: { url } }) => {
      return from(
        api.get(url).then((resp) => {
          return resp;
        })
      ).pipe(
        map(talentPoolGetSuccess),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map(error => toast.error(error.detail));
          return of(talentPoolGetFailure({ err, errors }));
        })
      );
    })
  );

const importPeopleEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(PEOPLE_IMPORT),
    flatMap(({ payload: { formData } }) => {
      return from(
        api.post(`/people/import_csv`, formData).then((resp) => resp)
      ).pipe(
        map(peopleImportSuccess),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map(error => toast.error(error.detail));
          return of(peopleImportFailure({ err, errors }));
        })
      );
    })
  );

export default combineEpics(
  getPeopleEpic,
  getPersonEpic,
  getTalentPoolEpic,
  updatePersonEpic,
  getTalentPoolPageEpic,
  updateTalentPoolEpic,
  importPeopleEpic
);
