import React, { useState } from "react";
import { useDispatch } from "react-redux";
import theme from "@theme";
import styled from "styled-components";
import * as R from "ramda";
import ReactTooltip from "react-tooltip";

import Box from "@ui/box";
import Row from "@ui/row";
import Text from "@ui/text";
import Flex from "@ui/flex";
import RowMenu from "@ui/row-menu-button";
import ModalRemove from "@ui/modal-remove";
import RowsPerPage from "@ui/rows-per-page/index";
import Pagination from "@ui/pagination/index";
import AlertTestSequence from "@ui/modal/AlertTestSequence";

//icon
import Valid from "@ui/icons/statActive";
import Null from "@ui/icons/statInactive";

import EditSequenceModalContainer from "@containers/sequences/EditSequenceModalContainer";
import { PreviewSequenceModal } from "../sequences";

//Actions
import { sequencesGet } from "@actions";

const StyledLabel = styled.div`
  white-space: pre-line;
  margin: 5px;
  align-items: center;
  font-size: 0.9rem;
  display: flex;
  flex-direction: row;
  color: ${theme.colors.grey};
  font-weight: 200;
`;

const StyledIcon = styled.div`
  height: 22px;
  width: 22px;
  margin-left: 10px;
`;

const StyledCircle = styled.div`
  margin-left: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.width ? props.width : "25px")};
  height: ${(props) => (props.height ? props.height : "25px")};
  font-weight: 500;
  font-size: 0.9rem;
  border: ${(props) => (props.border ? props.border : '1px solid' + theme.colors.darkBlue)};
  color: ${(props) => (props.color ? props.color : theme.colors.darkBlue)};
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#fff")};
`;
const TextLink = styled(Text)`
  font-family: "Open Sans";
  font-size: 1.1rem;
  padding-left: 3px;
  width: auto;
  cursor: pointer;

  &:hover {
    filter: drop-shadow(0px 0px 3px #caf0fc);
    color: ${theme.colors.darkBlue};
  }
`;

const VariableTooltip = styled.div`
  margin: 3px;
  font-weight: 200;
`;
const ContainerTooltip = styled.div`
  display: flex;
  flex-flow: wrap;
  width: 200px;
`;
const StyledPaginationEmpty = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.1rem;
  font-weight: 200;
  margin-top: 10px;
`;
const IconWrap = styled.div`
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledSpan = styled.span`
  font-weight: 100;
`;

const SequencesList = ({
  workspaceId,
  sequences,
  viewport,
  credentials,
  steps,
  removeSequenceHandler,
  testSequenceHandler,
  firstPage,
  lastPage,
  prevPage,
  nextPage,
  currentPage,
  totalPages,
}) => {
  const dispatch = useDispatch();
  const [showModalPreview, setShowModalPreview] = useState(false);
  const [currentSequence, setCurrentSequence] = useState("");
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalRemove, setShowModalRemove] = useState(false);
  const [showModalTest, setShowModalTest] = useState(false);

  const usedVariables = (sequence) => {
    const isUsed = (field) => field["used"] && field["used"] === true;
    let defaults = R.filter(isUsed, sequence.attributes.payload_fields?.defaults);
    let customs = R.filter(isUsed, sequence.attributes.payload_fields?.customs);
    return defaults.concat(customs);
  };

  const handleEditSequence = (sequence) => {
    setCurrentSequence(sequence);
    setShowModalEdit(true);
  };

  const handleRemoveModal = (sequence) => {
    setCurrentSequence(sequence);
    setShowModalRemove(true);
  };

  const handleTestModal = (sequence) => {
    setCurrentSequence(sequence);
    setShowModalTest(true);
  };

  const handleRemoveSequence = (sequence) => {
    removeSequenceHandler(sequence);
    setShowModalRemove(false);
  };

  const handleTestSequence = (sequence, testParams) => {
    testSequenceHandler(sequence, testParams);
    setShowModalTest(false);
  };

  const handleCloseEditSequenceModal = () => {
    setShowModalEdit(false);
    dispatch(sequencesGet({ workspace_id: workspaceId }));
  };

  const rowMenuOptions = [
    { name: "Edit", callback: handleEditSequence },
    { name: "Remove", alert: handleRemoveModal },
    { name: "Test", callback: handleTestModal },
  ];

  return (
    <Flex
      marginTop={"3%"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      width={viewport > 2000 ? "85%" : "95%"}
      backgroundColor={theme.colors.lighterGrey}
      marginBottom="30px"
    >
      {showModalPreview && (
        <PreviewSequenceModal
          steps={steps}
          sequence={currentSequence}
          credentials={credentials}
          handleCloseModal={() => setShowModalPreview(false)}
        />
      )}
      {showModalEdit && (
        <EditSequenceModalContainer
          steps={steps}
          sequence={currentSequence}
          credentials={credentials}
          handleCloseModal={() => handleCloseEditSequenceModal()}
        />
      )}
      {showModalRemove && (
        <ModalRemove
          removeItem={currentSequence}
          title="Remove Sequence"
          handleRemoveClick={handleRemoveSequence}
          closeModal={() => setShowModalRemove(false)}
        />
      )}
      {showModalTest && (
        <AlertTestSequence
          title="Test Sequence"
          description="Emails will be sent only to your email address."
          handleSubmitClick={handleTestSequence}
          closeModal={() => setShowModalTest(false)}
          buttonSubmitText="Test"
          sequence={currentSequence}
          variables={usedVariables(currentSequence)}
        />
      )}

      {sequences.map((sequence, index) => (
        <Row key={index}>
          <Box minWidth="400px">
            <Flex>
              <TextLink
                color={theme.colors.blue}
                fontFamily="Open Sans"
                fontSize="1.15rem"
                onClick={() => {
                  setShowModalPreview(true);
                  setCurrentSequence(sequence);
                }}
              >
                {sequence.attributes.name}
              </TextLink>
              {credentials[sequence.id]?.empty_providers > 0 ? (
                <StyledCircle bgColor={"#F45C2C"} color={"#FFFFFF"} width="20px" height="20px" border="none">
                  <IconWrap data-tip data-for="emptyProviders">
                    !
                    <ReactTooltip id="emptyProviders" aria-haspopup="true">
                      <StyledSpan> Credencial missing </StyledSpan>
                    </ReactTooltip>
                  </IconWrap>
                </StyledCircle>
              ) : null}
            </Flex>
          </Box>
          <Box width="85px">
            <Flex alignItems="center">
              <StyledLabel>Write</StyledLabel>
              <StyledIcon>
                {sequence.attributes.has_write ? <Valid /> : <Null />}
              </StyledIcon>
            </Flex>
          </Box>
          <Box width="85px">
            <Flex alignItems="center">
              <StyledLabel>Steps</StyledLabel>
              <StyledCircle>
                {sequence.relationships.steps?.data?.length}
              </StyledCircle>
            </Flex>
          </Box>
          <Box width="115px">
            <Flex alignItems="center">
              <StyledLabel>Credentials</StyledLabel>
              <StyledCircle
                data-tip={credentials[sequence.id]?.provider_names}
                data-place="bottom"
              >
                {credentials[sequence.id]?.count}
              </StyledCircle>
            </Flex>
          </Box>
          <Box width="150px">
            <Flex alignItems="center">
              <StyledLabel>Variables</StyledLabel>
              <StyledCircle
                data-tip
                data-place="bottom"
                data-for={`variables-${index}`}
              >
                {usedVariables(sequence).length}
              </StyledCircle>
              {usedVariables(sequence).length > 0 ? (
                <ReactTooltip id={`variables-${index}`} aria-haspopup="true">
                  <ContainerTooltip>
                    {usedVariables(sequence).map((field, index) => (
                      <VariableTooltip key={index}>
                        {field.name},
                      </VariableTooltip>
                    ))}
                  </ContainerTooltip>
                </ReactTooltip>
              ) : null}
            </Flex>
          </Box>
          <RowMenu icon="config" options={rowMenuOptions} resource={sequence} />
        </Row>
      ))}
      <Flex
        width="100%"
        height="30px"
        justifyContent="flex-end"
        backgroundColor="transparent"
        marginTop="10px"
      >
        {totalPages > 0 && (
          <>
            <RowsPerPage
              name="rowsperpage"
              placeholder="10"
              options={["10", "20", "30", "40", "50"]}
            />

            <Pagination
              firstPage={firstPage}
              lastPage={lastPage}
              prevPage={prevPage}
              nextPage={nextPage}
              currentPage={currentPage}
              totalPages={totalPages}
            />
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default SequencesList;
