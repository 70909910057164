import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//Actions
import { projectInvitationsGet } from "../../actions";

//Reducers
import { getInvitationsData, getInvitationsIncluded } from "../../reducer";

//Component
import { PendingInvitationsList } from "../../components/domain/access";

const PendingInvitationsListContainer = ({ project, viewport }) => {
  const dispatch = useDispatch();

  const invitations = useSelector(getInvitationsData);
  const included = useSelector(getInvitationsIncluded);

  useEffect(() => {
    dispatch(projectInvitationsGet({ project_id: project?.id }));
  }, [project, dispatch]);

  return (
    <PendingInvitationsList
      invitations={invitations}
      included={included}
      viewport={viewport}
    />
  );
};

export default PendingInvitationsListContainer;
