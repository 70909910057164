import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.linkable
        ? `&:hover {
      filter: drop-shadow(-1px 1px 1px #4BAAC8); cursor: pointer;}`
        : ""}

    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black);" : ""}
  `,

  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const Project = ({ size, ...props }) => {
  return (
    <StyledSvg {...props} width={size} height={size} viewBox="2.5 2.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.9941 20.8461V17.6748" stroke="#515050" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M22.7375 6.66272C24.85 6.66272 26.55 8.37522 26.55 10.4877V14.7877C23.475 16.5877 19.4125 17.6752 14.9875 17.6752C10.5625 17.6752 6.5125 16.5877 3.4375 14.7877V10.4752C3.4375 8.36272 5.15 6.66272 7.2625 6.66272H22.7375Z" stroke="#515050" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M19.3691 6.65726V6.19976C19.3691 4.67476 18.1316 3.43726 16.6066 3.43726H13.3816C11.8566 3.43726 10.6191 4.67476 10.6191 6.19976V6.65726" stroke="#515050" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.46826 19.3538L3.70451 22.49C3.86451 24.6038 5.62576 26.2375 7.74451 26.2375H22.2433C24.362 26.2375 26.1233 24.6038 26.2833 22.49L26.5195 19.3538" stroke="#515050" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </StyledSvg>
  );
};


export default Project;