import { createActions } from "redux-actions";

// sign in actions
export const INVITATIONS_GET = "INVITATIONS_GET";
export const INVITATIONS_GET_SUCCESS = "INVITATIONS_GET_SUCCESS";
export const INVITATIONS_GET_FAILURE = "INVITATIONS_GET_FAILURE";
export const ON_INVITATIONS_LOADED = "ON_INVITATIONS_LOADED";

export const PROJECT_INVITATIONS_GET = "PROJECT_INVITATIONS_GET";
export const PROJECT_INVITATIONS_GET_SUCCESS = "PROJECT_INVITATIONS_GET_SUCCESS";
export const PROJECT_INVITATIONS_GET_FAILURE = "PROJECT_INVITATIONS_GET_FAILURE";

export const WORKSPACE_INVITATIONS_GET = "WORKSPACE_INVITATIONS_GET";
export const WORKSPACE_INVITATIONS_GET_SUCCESS = "WORKSPACE_INVITATIONS_GET_SUCCESS";
export const WORKSPACE_INVITATIONS_GET_FAILURE = "WORKSPACE_INVITATIONS_GET_FAILURE";

export const INVITATION_ACCEPT = "INVITATION_ACCEPT";
export const INVITATION_ACCEPT_SUCCESS = "INVITATION_ACCEPT_SUCCESS";
export const INVITATION_ACCEPT_FAILURE = "INVITATION_ACCEPT_FAILURE";

export const INVITATION_REJECT = "INVITATION_REJECT";
export const INVITATION_REJECT_SUCCESS = "INVITATION_REJECT_SUCCESS";
export const INVITATION_REJECT_FAILURE = "INVITATION_REJECT_FAILURE";

export const INVITATION_LOADED_SET = "INVITATION_LOADED_SET";
export const INVITATION_LOADED_CLEAN = "INVITATION_LOADED_CLEAN";

export const {
  invitationsGet,
  invitationsGetSuccess,
  invitationsGetFailure,
  onInvitationsLoaded,
  projectInvitationsGet,
  projectInvitationsGetSuccess,
  projectInvitationsGetFailure,
  workspaceInvitationsGet,
  workspaceInvitationsGetSuccess,
  workspaceInvitationsGetFailure,
  invitationAccept,
  invitationAcceptSuccess,
  invitationAcceptFailure,
  invitationReject,
  invitationRejectSuccess,
  invitationRejectFailure,
  invitationLoadedSet,
  invitationLoadedClean,
} = createActions(
  {},
  INVITATIONS_GET,
  INVITATIONS_GET_SUCCESS,
  INVITATIONS_GET_FAILURE,
  ON_INVITATIONS_LOADED,
  PROJECT_INVITATIONS_GET,
  PROJECT_INVITATIONS_GET_SUCCESS,
  PROJECT_INVITATIONS_GET_FAILURE,
  WORKSPACE_INVITATIONS_GET,
  WORKSPACE_INVITATIONS_GET_SUCCESS,
  WORKSPACE_INVITATIONS_GET_FAILURE,
  INVITATION_ACCEPT,
  INVITATION_ACCEPT_SUCCESS,
  INVITATION_ACCEPT_FAILURE,
  INVITATION_REJECT,
  INVITATION_REJECT_SUCCESS,
  INVITATION_REJECT_FAILURE,
  INVITATION_LOADED_SET,
  INVITATION_LOADED_CLEAN
);
