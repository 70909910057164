import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
  ${(prop) =>
    prop.landing
      ? `&:hover {
        fill: ${theme.colors.lightBlue}; cursor: pointer;}`
      : ""}
    ${(prop) =>
      prop.linkable
        ? `&:hover {
      filter: drop-shadow(-1px 1px 1px #4BAAC8); cursor: pointer;}`
        : ""}

    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black);" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const WebIcon = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <path
        className="st0"
        d="M12.5,0.2C5.7,0.2,0.2,5.7,0.2,12.5c0,6.8,5.5,12.3,12.3,12.3c6.8,0,12.3-5.5,12.3-12.3
	C24.8,5.7,19.3,0.2,12.5,0.2z M23.2,11.7h-3.6c0-1.9-0.3-3.7-0.8-5.2c0.7-0.3,1.2-0.8,1.6-1.2C22,7,23,9.2,23.2,11.7z M13.3,1.8
	c1.5,0.4,2.7,1.8,3.6,3.8c-1,0.3-2.2,0.5-3.6,0.6V1.8z M17,2.7c0.8,0.4,1.6,0.9,2.3,1.4c-0.2,0.3-0.5,0.6-1,0.9
	C18,4.2,17.5,3.4,17,2.7z M11.7,1.8v4.4C10.3,6.2,9.1,6,8.1,5.6C9,3.6,10.3,2.2,11.7,1.8z M6.7,5c-0.5-0.3-0.8-0.6-1-0.9
	c0.7-0.6,1.5-1,2.3-1.4C7.5,3.4,7,4.2,6.7,5z M11.7,7.8v3.9H6.9c0-1.7,0.3-3.3,0.7-4.7C8.8,7.5,10.3,7.7,11.7,7.8z M11.7,13.3v3.6
	c-1.4,0.1-2.7,0.3-3.9,0.6c-0.5-1.2-0.8-2.7-0.9-4.2H11.7z M11.7,18.4v3.5c-1.2-0.3-2.4-1.4-3.2-3C9.4,18.6,10.5,18.5,11.7,18.4z
	 M10.5,23.1c-1.8-0.3-3.5-1.2-4.9-2.3c0.1-0.4,0.6-0.9,1.4-1.3C7.9,21.2,9.1,22.4,10.5,23.1z M18,19.4c0.9,0.4,1.4,0.9,1.4,1.3
	c-1.4,1.2-3.1,2-4.9,2.3C15.9,22.4,17.1,21.2,18,19.4z M13.3,21.9v-3.5c1.2,0.1,2.3,0.2,3.2,0.5C15.6,20.5,14.5,21.5,13.3,21.9z
	 M13.3,16.9v-3.6h4.8c-0.1,1.5-0.4,3-0.9,4.2C16,17.1,14.6,16.9,13.3,16.9z M13.3,11.7V7.8c1.5-0.1,2.9-0.3,4.2-0.7
	c0.4,1.4,0.7,3,0.7,4.7H13.3z M4.6,5.3c0.4,0.5,0.9,0.9,1.6,1.2c-0.5,1.6-0.8,3.3-0.8,5.2H1.8C2,9.2,3,7,4.6,5.3z M1.8,13.3h3.6
	c0.1,1.7,0.5,3.4,1,4.8c-0.9,0.4-1.6,0.9-2,1.6C2.9,17.9,1.9,15.7,1.8,13.3z M20.6,19.6c-0.4-0.6-1.1-1.1-2-1.6c0.5-1.4,0.9-3,1-4.8
	h3.6C23.1,15.7,22.1,17.9,20.6,19.6z"
      />
    </StyledSvg>
  );
};

export default WebIcon;
