import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

// Component
import { NewWorkspaceModal } from "../../components/domain/workspaces";
import Add from "../../components/ui/icons/add"; // Importing the Add icon component

// Actions
import { workspaceCreate } from "../../actions";

// Reducers
import { getAuthId, getWorkspaces } from "../../reducer";

// Styled Button
const NewWorkspaceButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #EB6538;
  border: none;
  border-radius: 5px;
  padding: 0.7rem;
  color: white;
  cursor: pointer;
  width: ${props => props.isExpanded ? "80%" : "70%"};
  height: 2.2rem;

  &:hover {
    background-color: #F97044; // Changes background color on hover
    color: #EAEAEA; // Changes text color on hover

    svg {
      fill: #EAEAEA; // Changes icon color on hover (assuming it's an SVG)
    }
  }

  &:active {
    background-color: #FF835B; // Changes background color on click/tap
  }
`;

const NewWorkspaceModalContainer = ({ isExpanded, tooltip, location }) => {
  const dispatch = useDispatch();
  const user_id = useSelector(getAuthId);
  const workspaces = useSelector(getWorkspaces);

  const [dirtyChanges, setDirtyChanges] = useState({});
  const [showModal, setShowModal] = useState(false); // Changed to boolean

  const handleInputChange = (event) => {
    setDirtyChanges({
      ...dirtyChanges,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmitClick = () => {
    dispatch(workspaceCreate({ user_id, ...dirtyChanges }));
    setShowModal(false);
  };

  useEffect(() => {
    setDirtyChanges({});
  }, [workspaces]);

  const handleOpenModal = (event) => {
    event.stopPropagation();
    setShowModal(true);
  };

  return (
    <>
      <NewWorkspaceButton isExpanded={isExpanded} onClick={handleOpenModal}>
        <Add variant="white" />
        {isExpanded && <span style={{ marginLeft: '10px' }}>New workspace</span>}
      </NewWorkspaceButton>

      {showModal && ( // Render modal based on showModal state
        <NewWorkspaceModal
          handleSubmitClick={handleSubmitClick}
          dirtyChanges={dirtyChanges}
          handleChange={handleInputChange}
          showModal={showModal}
          closeModal={() => setShowModal(false)}
          tooltip={tooltip}
          location={location}
          isExpanded={isExpanded}
        />
      )}
    </>
  );
};

export default NewWorkspaceModalContainer;
