import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black)" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const Goal = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <g>
        <path
          d="M12.5,8C10,8,8,10,8,12.5s2,4.5,4.5,4.5s4.5-2,4.5-4.5S15,8,12.5,8z M12.5,15.5c-1.7,0-3-1.3-3-3s1.3-3,3-3
		s3,1.3,3,3S14.2,15.5,12.5,15.5z"
        />
        <path
          d="M23.8,11.8H22c-0.4-4.6-4.1-8.3-8.7-8.7V1.3c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8V3C7.1,3.4,3.4,7.1,3,11.8
		H1.3c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8H3c0.4,4.6,4.1,8.3,8.7,8.7v1.8c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8V22
		c4.6-0.4,8.3-4.1,8.7-8.7h1.8c0.4,0,0.8-0.3,0.8-0.8S24.2,11.8,23.8,11.8z M13.3,20.5v-1.7c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8
		v1.7c-3.8-0.4-6.9-3.4-7.2-7.2h1.7c0.4,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8H4.5c0.4-3.8,3.4-6.9,7.2-7.2v1.7c0,0.4,0.3,0.8,0.8,0.8
		s0.8-0.3,0.8-0.8V4.5c3.8,0.4,6.9,3.4,7.2,7.2h-1.7c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h1.7C20.1,17.1,17.1,20.1,13.3,20.5z"
        />
      </g>
    </StyledSvg>
  );
};

export default Goal;
