import React from "react";
import { useDispatch } from "react-redux";

//Component
import { AccessList } from "@components/domain/access";

//Actions
import { projectUserUpdate, projectUserDelete } from "@actions";

//Containers
import PendingInvitationsListContainer from "@containers/access/PendingInvitationsListContainer";

const AccessListContainer = ({ projectAccessList, project, viewport }) => {
  const dispatch = useDispatch();

  const deleteProjectUserHandler = (id) => {
    dispatch(
      projectUserDelete({
        project_id: project.id,
        workspace_id: project.attributes.workspace_id,
        id,
      })
    );
  };

  const updateUserRoleHandler = (id, role) => {
    dispatch(
      projectUserUpdate({
        project_id: project.id,
        workspace_id: project.attributes.workspace_id,
        id,
        role,
      })
    );
  };

  return (
    <>
      <AccessList
        viewport={viewport}
        projectAccessList={projectAccessList}
        project={project}
        deleteProjectUserHandler={deleteProjectUserHandler}
        updateUserRoleHandler={updateUserRoleHandler}
      />
      <PendingInvitationsListContainer project={project} viewport={viewport} />
    </>
  );
};

export default AccessListContainer;
