import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Spinner from "@components/ui/spinner";
import { SequencesIntro, Sequences } from "@components/domain/sequences";

//Actions
import { sequencesGet, sequenceDelete, currentWorkspaceSet, sequenceTest } from "@actions";

//Reducers
import {
  getSequencesData,
  getSequencesLinks,
  getSequencesIncluded,
  getSequenceCurrentPage,
  getSequenceTotalPages,
  getLimit,
} from "@reducer";

import { getRelated } from "@utils";

const SequencesContainer = ({ viewport, ...props }) => {
  const sequences = useSelector(getSequencesData);
  const sequencesLink = useSelector(getSequencesLinks);
  const includedSteps = useSelector(getSequencesIncluded);
  const currentPage = useSelector(getSequenceCurrentPage);
  const totalPages = useSelector(getSequenceTotalPages);

  const [sequencesMetaData, setSequencesMetaData] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(currentWorkspaceSet({ workspace_id: props.match.params.id }));
    dispatch(sequencesGet({ workspace_id: props.match.params.id }));
  }, [props.match.params.id]);

  useEffect(() => {
    setSequencesCredentials();
  }, [sequences]);

  const setSequencesCredentials = () => {
    const relatedCredentials = sequences.map((sequence) =>
      getRelated(sequence.relationships.steps.data, includedSteps)
    );

    let newSequencesMeta = {};

    relatedCredentials.forEach((steps) => {
      let stepMeta = {
        count: 0,
        provider_names: [],
        empty_providers: 0
      };

      steps.forEach((step) => {
        if (
          step.attributes.provider_name &&
          !stepMeta.provider_names.includes(step.attributes.provider_name)
        ) {
          stepMeta.count = stepMeta.count + 1;
          stepMeta.provider_names.push(step.attributes.provider_name);
        } else if (step.attributes.provider_name == null) {
          stepMeta.empty_providers = stepMeta.empty_providers + 1;
        }
      });

      newSequencesMeta[steps[0]?.attributes.sequence_id] = stepMeta;
    });

    setSequencesMetaData(newSequencesMeta);
  };

  const removeSequenceHandler = (sequence) => {
    dispatch(
      sequenceDelete({
        workspace_id: sequence.attributes.workspace_id,
        id: sequence.id,
      })
    );
  };

  const testSequenceHandler = (sequence, testParams) => {
    dispatch(
      sequenceTest({
        workspace_id: sequence.attributes.workspace_id,
        id: sequence.id,
        testParams: testParams,
      })
    );
  };

  const firstPagePagination = () => {
    dispatch(sequencesGet({ url: sequencesLink.first }));
  };

  const lastPagePagination = () => {
    dispatch(sequencesGet({ url: sequencesLink.last }));
  };

  const nextPagePagination = () => {
    dispatch(sequencesGet({ url: sequencesLink.next }));
  };

  const prevPagePagination = () => {
    dispatch(sequencesGet({ url: sequencesLink.prev }));
  };

  return sequences.loading ? (
    <Spinner />
  ) : sequences?.length > 0 ? (
    <Sequences
      viewport={viewport}
      sequences={sequences}
      credentials={sequencesMetaData}
      steps={includedSteps}
      removeSequenceHandler={removeSequenceHandler}
      testSequenceHandler={testSequenceHandler}
      workspaceId={props.match.params.id}
      firstPage={firstPagePagination}
      lastPage={lastPagePagination}
      prevPage={prevPagePagination}
      nextPage={nextPagePagination}
      totalPages={totalPages}
      currentPage={currentPage}
    />
  ) : (
    <SequencesIntro viewport={viewport} />
  );
};

export default SequencesContainer;
