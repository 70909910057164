import { combineActions, handleActions } from "redux-actions";
import { lensProp, lensPath, pipe, set, view } from "ramda";

import {
  onExpiredToken,
  appMount,
  appDataSuccess,
  appDataFailure,
  appWorkspacesLoad,
  appInvitationsLoad,
  appWorkflowsFilterSuccess,
  appUnmountWorkflowsFilterSuccess,
  onAppStart,
  signOutSetSuccess
} from "../actions";

const initialState = {
  data: {
    token: null,
    user: null,
    workspaces: [],
    notifications: [],
  },
  errors: [],
  status: {
    loading: false,
    loaded: false,
  },
  filters:{
    workflows: "workspace",
  }
};

const dataLens = lensProp("data");
const tokenLens = lensPath(["data", "token"]);
const userLens = lensPath(["data", "user"]);
const workspacesLens = lensPath(["data", "workspaces"]);
const notificationsLens = lensPath(["data", "notifications"]);
const loadingLens = lensPath(["status", "loading"]);
const loadedLens = lensPath(["status", "loaded"]);
const workflowsFilterLens = lensPath(["filters", "workflows"]);

export default handleActions(
  {
    [onAppStart]: (state) => pipe(set(loadedLens, true))(state),
    [appMount]: (state) =>
      pipe(
        set(tokenLens, localStorage.getItem("access-token")),
        set(loadingLens, true)
      )(state),
    [appDataSuccess]: (state, { payload }) =>
      pipe(
        set(userLens, payload.user),
        set(workspacesLens, payload.workspaces),
        set(notificationsLens, payload.notifications),
        set(loadingLens, false)
      )(state),
    [appWorkspacesLoad]: (state, { payload }) =>
      pipe(set(workspacesLens, payload.data))(state),
    [appInvitationsLoad]: (state, { payload }) =>
      pipe(set(notificationsLens, payload.data))(state),
    [appWorkflowsFilterSuccess]: (state, { payload }) =>
      pipe(set(workflowsFilterLens, payload))(state),
    [appUnmountWorkflowsFilterSuccess]: (state, {}) =>
      pipe(set(workflowsFilterLens, "workspace"))(state),
    [combineActions(onExpiredToken, appDataFailure, signOutSetSuccess)]: (state) => {
      localStorage.clear(); // remove token
      return {
        ...initialState,
        token: null,
      };
    },
  },
  initialState
);

export const getCurrentUser = (state) => state.app.data.user;
export const getAppLoading = (state) => state.app.status.loading;
export const getAppLoaded = (state) => state.app.status.loaded;
export const getAppNotifications = (state) => state.app.data.notifications;
export const getAppWorkspaces = (state) => state.app.data.workspaces;
export const getAppWorkflowsFilter = (state) => state.app.filters.workflows;
