import React from "react";
import styled from "styled-components";
import theme from "../../../theme";

// Components
import Card from "../card/index";
import Flex from "../flex";
import Box from "../box";
import Button from "../button/index";
import Text from "../text/index";
import Dropdown from "../dropdown/index";

//Icons
import Close from "../icons/close";

const StyledBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99999;
`;

const StyledCardContainer = styled.div`
  position: fixed;
  background: transparent;
  width: 40%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledItemName = styled.div`
  font-size: 1.1rem;
  display: flex;
  color: ${theme.colors.lightRed};
  font-weight: 400;
  margin: 5px 0px;
`;

const AddToModal = ({
  itemAttached, // Item to add  (person,workflow, etc)
  closeModal,
  title, //modal title
  addToOptions, //array of options,[{name,id...}]
  handleSubmitClick,
  dirtyChanges,
  handleDropdown,
}) => {
  return (
    <StyledBackground>
      <StyledCardContainer>
        <Card
          fullHeader
          title={"Add to " + title}
          icon={<Close variant="grey"></Close>}
          closeHandler={closeModal}
          footer
          buttons={[
            <Button
              key="cancel"
              variant="secondaryLine"
              content="Cancel"
              onClick={closeModal}
            />,
            <Button
              key="continue"
              variant="secondaryLine"
              content="Continue"
              onClick={handleSubmitClick}
            />,
          ]}
        >
          <Flex width="90%" alignItems="center" flexDirection="column">
            <Box width="80%">
              <Dropdown
                name="add_to"
                value={dirtyChanges.add_to}
                handleDropdown={handleDropdown}
                onChange={handleDropdown}
                formStyle
                variant="blue"
                required
                options={addToOptions}
                label="Add To"
                mb={4}
                placeholder="Select the destination"
              />
            </Box>
            {dirtyChanges.add_to && (
              <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="space-evenly"
                height="150px"
              >
                <Flex
                  alignItems="center"
                  margin="0px 0px 15px 0px"
                  flexDirection="column"
                >
                  <Text
                    textAlign="center"
                    fontSize="1rem"
                    margin="20px 0px 0px 0px"
                  >
                    You are about to add
                  </Text>
                  <StyledItemName>
                    {itemAttached.attributes.name}
                  </StyledItemName>

                  <Text textAlign="center" fontSize="1rem" margin="5px 0px">
                    to
                  </Text>

                  <StyledItemName>{dirtyChanges.add_to}</StyledItemName>
                  <Text textAlign="center" fontSize="1rem" margin="0 10px">
                    {title}
                  </Text>
                </Flex>
                <Text textAlign="center" fontSize="1rem" marginTop="15px">
                  Are you sure you want to proceed?
                </Text>
              </Flex>
            )}
          </Flex>
        </Card>
      </StyledCardContainer>
    </StyledBackground>
  );
};

export default AddToModal;
