import { handleActions } from "redux-actions";
import { lensProp, pipe, set } from "ramda";
import {
  projectUsersGet,
  projectUserUpdate,
  projectUserUpdateSuccess,
  projectUserUpdateFailure,
  projectUserDelete,
  projectUserDeleteSuccess,
  projectUserDeleteFailure,
  onExpiredToken,
} from "../actions";

const initialState = {
  inError: false,
  errors: [],
  data: [],
};

const inErrorLens = lensProp("inError");
const errorsLens = lensProp("errors");
const dataLens = lensProp("data");

const setErrors = set(errorsLens);
const setData = set(dataLens);

export default handleActions(
  {
    [projectUsersGet]: (state, action) => pipe(setData(action.payload))(state),

    [projectUserUpdate]: (state, action) =>
      pipe(set(inErrorLens, true), setErrors([]))(state),
    [projectUserUpdateFailure]: (state, action) =>
      pipe(set(inErrorLens, true), setErrors(action.payload.errors))(state),
    [projectUserUpdateSuccess]: (state, action) =>
      pipe(set(inErrorLens, true), setErrors([]))(state),
    [projectUserDelete]: (state, action) =>
      pipe(set(inErrorLens, true), setErrors([]))(state),
    [projectUserDeleteFailure]: (state, action) =>
      pipe(set(inErrorLens, true), setErrors(action.payload.errors))(state),
    [projectUserDeleteSuccess]: (state, action) =>
      pipe(set(inErrorLens, true), setErrors([]))(state),
    [onExpiredToken]: (state) => {
      return { ...initialState };
    },
  },
  initialState
);

export const getProjectUserErrors = (state) => state.projectUsers.errors;
export const getProjectUsersData = (state) => state.projectUsers.data;
