import React from "react";
import styled from "styled-components";
import theme from "@theme";

import Flex from "@ui/flex";
import Image from "@assets/img/avatar.png";
import Picture from "@ui/picture";
import Row from "@ui/row";
import Text from "@ui/text";
import RowMenu from "@ui/row-menu-button/index";
import RowsPerPage from "@ui/rows-per-page/index";
import Pagination from "@ui/pagination/index";

import ReactTooltip from "react-tooltip";

import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.css";

//icons
import Valid from "@ui/icons/valid";
import Sent from "@ui/icons/waiting";
import Fail from "@ui/icons/close";
import Linkedin from "@ui/icons/linkedin";
import Replied from "@ui/icons/repliedArrow";
import RadioIcons from "@ui/radioIcon";
import { ThumbUpLine } from "@ui/icons/thumbUp";
import { ThumbDownLine } from "@ui/icons/thumbDown";
import { FollowUpLine } from "@ui/icons/followUp";

const InfoFlex = styled(Flex)`
  padding: 0px 5px;
  width: 55%;
  font-family: "Open Sans";
  align-items: center;
`;

const StyledContainer = styled(Flex)`
  width: 35%;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0px 15px 0px 0px;
  background-color: transparent;
  height: 70vh;
`;

const StyledList = styled(Flex)`
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  background-color: transparent;
`;

const StyledSimpleBar = styled(SimpleBar)`
  width: 100%;
  padding-top: 0;
  margin-top: 0;
  max-height: ${(props) =>
    props.viewport > 2000 ? "73vh" : props.viewHeight < 800 ? "60vh" : "65vh"};
  padding-right: 15px;

  .simplebar-scrollbar:before {
    background: ${theme.colors.grey};
  }
`;

const SelectorRow = styled(Row)`
  cursor: pointer;
  margin-top: 0;
  margin-bottom: 10px;
  padding: 5px;
  padding-left: 10px;
  width: 100%;
  height: 100px;
  border-left: ${(props) =>
    props.selected ? `8px solid ${theme.colors.darkBlue}` : ""};
`;

const SocialLink = styled.a`
  height: 20px;
  width: 20px;
  margin: 0px 4px;
  text-decoration: none;
  color: ${theme.colors.blue};
`;

const PersonInfo = styled(Text)`
  margin-bottom: 5px;
  align-items: center;
  font-size: 0.8rem;
  display: flex;
  flex-direction: row;
  color: ${theme.colors.gray};
  font-family: "Open Sans";
  flex-wrap: nowrap;
`;

const PayloadStatus = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledCircle = styled.div`
  color: ${(props) => (props.color ? props.color : theme.colors.grey)};
  margin-left: 5px;
  border-radius: 50%;
  font-size: 0.8rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#fff")};
`;

const IconWrap = styled.div`
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconWrapBig = styled.div`
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSpan = styled.span`
  font-weight: 100;
`;

const PayloadSelector = ({
  projectPeople,
  payloads,
  selectorHandler,
  selectedPayloadId,
  getPersonByPayload,
  getSequenceProgressByPayload,
  activePerson,
  rowMenuOptions,
  handleUpdateResponse,
  firstPage,
  lastPage,
  prevPage,
  nextPage,
  totalPages,
  currentPage,
  viewport,
  viewHeight,
}) => {
  const getPersonResponse = (id) => {
    return projectPeople.data.filter(
      (item) => item.type === "project_person" && item.id === id
    )[0];
  };

  const handleDateLastSent = (payload) => {
    let date = getSequenceProgressByPayload(payload)?.meta.last_step_sent_date;
    let newDate = date;
    if (date) {
      newDate = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZoneName: "short",
      }).format(new Date(date));
    }
    return newDate;
  };

  const handleDateSchedule = (payload) => {
    let date = getSequenceProgressByPayload(payload)?.meta
      .current_step_schedule_date;
    let newDate = date;
    if (date) {
      newDate = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }).format(new Date(date));
    }
    return newDate;
  };

  const handleTimeSchedule = (payload) => {
    let date = getSequenceProgressByPayload(payload)?.meta
      .current_step_schedule_date;
    let newDate = date;
    if (date) {
      newDate = new Intl.DateTimeFormat("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZoneName: "short",
      }).format(new Date(date));
    }
    return newDate;
  };

  return (
    <StyledContainer>
      <StyledList>
        <StyledSimpleBar
          forceVisible="y"
          autoHide={false}
          viewport={viewport}
          viewHeight={viewHeight}
        >
          {payloads &&
            payloads.map((payload, index) => (
              <SelectorRow
                key={index}
                onClick={() => selectorHandler(payload)}
                selected={payload.id === selectedPayloadId}
              >
                <Flex alignItems="center" width="70%">
                  <Picture bgImage={Image}></Picture>
                  <InfoFlex>
                    <Flex flexDirection="column">
                      <Text marginBottom="5px">
                        {getPersonByPayload(payload)?.attributes.first_name}{" "}
                        {getPersonByPayload(payload)?.attributes.last_name}
                      </Text>
                      <PersonInfo>
                        {getPersonByPayload(payload)?.attributes.company}
                      </PersonInfo>
                      <SocialLink
                        target="_blank"
                        href={getPersonByPayload(payload)?.attributes.linkedin}
                        hidden={
                          !getPersonByPayload(payload)?.attributes.linkedin
                        }
                      >
                        <Linkedin variant="blue" linkable />
                      </SocialLink>
                    </Flex>
                  </InfoFlex>
                </Flex>
                <Flex width="20%" flexDirection="column">
                  <PayloadStatus>
                    <Text fontSize="0.8rem">{payload.extra.status?.name}</Text>
                    <StyledCircle
                      bgColor={payload.extra.status?.bgColor}
                      color={payload.extra.status?.iconColor}
                    >
                      {payload.extra.status?.name === "Pending" && payload.extra.emptyCount > 0
                        ? payload.extra.emptyCount
                        : null}
                      {payload.extra.status?.name === "Draft" ? "!" : null}
                      {payload.extra.status?.name === "Ready" ? (
                        <IconWrap>
                          <Valid variant="white" />
                        </IconWrap>
                      ) : null}
                      {payload.extra.status?.name === "Sending" ? (
                        <IconWrapBig>
                          <Sent variant="darkBlue" />
                        </IconWrapBig>
                      ) : null}
                      {payload.extra.status?.name === "Failed" ? (
                        <IconWrap data-tip data-for="failMessage">
                          !
                          <ReactTooltip id="failMessage" aria-haspopup="true">
                            <StyledSpan> {payload.extra.status.failMessage} </StyledSpan>
                          </ReactTooltip>
                        </IconWrap>
                      ) : null}
                      {payload.extra.status?.name === "Paused" ? (
                        <IconWrap data-tip data-for="statusMessage">
                          !
                          <ReactTooltip id="statusMessage" aria-haspopup="true">
                            <StyledSpan> {payload.extra.status.statusMessage} </StyledSpan>
                          </ReactTooltip>
                        </IconWrap>
                      ) : null}
                      {payload.extra.status?.name === "Bounced" ? (
                        <IconWrap>
                          <Fail variant="white" />
                        </IconWrap>
                      ) : null}
                      {payload.extra.status?.name === "Finished" ? (
                        <IconWrap>
                          <Valid variant="white" />
                        </IconWrap>
                      ) : null}
                      {payload.extra.status?.name === "Replied" ? (
                        <IconWrap>
                          <Replied variant="darkBlue" />
                        </IconWrap>
                      ) : null}
                    </StyledCircle>
                  </PayloadStatus>
                  {payload.extra.status?.name === "Replied" && (
                    <Flex marginTop="7px">
                      <form style={{ display: "flex" }}>
                        <RadioIcons
                          name="response"
                          isChecked={
                            getPersonResponse(
                              payload.relationships.project_person.data.id
                            )?.attributes.response === "interested"
                          }
                          value="interested"
                          icon={<ThumbUpLine variant="lightGrey" />}
                          onChange={() =>
                            handleUpdateResponse({
                              response: "interested",
                              projectPeopleId:
                                payload.relationships.project_person.data.id,
                            })
                          }
                        />
                        <RadioIcons
                          name="response"
                          isChecked={
                            getPersonResponse(
                              payload.relationships.project_person.data.id
                            )?.attributes.response === "not_interested"
                          }
                          value="not_interested"
                          icon={<ThumbDownLine variant="lightGrey" />}
                          onChange={() =>
                            handleUpdateResponse({
                              response: "not_interested",
                              projectPeopleId:
                                payload.relationships.project_person.data.id,
                            })
                          }
                        />
                        <RadioIcons
                          name="response"
                          isChecked={
                            getPersonResponse(
                              payload.relationships.project_person.data.id
                            )?.attributes.response === "follow_up"
                          }
                          value="follow_up"
                          icon={<FollowUpLine variant="lightGrey" />}
                          onChange={() =>
                            handleUpdateResponse({
                              response: "follow_up",
                              projectPeopleId:
                                payload.relationships.project_person.data.id,
                            })
                          }
                        />
                      </form>
                    </Flex>
                  )}
                  {["Sending", "Replied", "Finished"].includes(payload.extra.status?.name) ? (
                    <Flex
                      marginTop="10px"
                      alignItems="flex-end"
                      data-tip
                      data-for="dateCurrentStep"
                    >
                      <Text fontSize="0.8rem">Last step </Text>

                      <Text
                        marginLeft="5px"
                        fontSize="0.9rem"
                        color={theme.colors.darkBlue}
                        fontWeight={400}
                      >
                        {getSequenceProgressByPayload(payload)?.meta.last_step_sent_position.toString()}
                      </Text>
                      <Text fontSize="0.8rem" color={theme.colors.darkBlue}>
                        /
                        {
                          getSequenceProgressByPayload(payload)?.meta
                            .total_steps
                        }
                      </Text>
                      <ReactTooltip id="dateCurrentStep" aria-haspopup="true">
                        Sent on {handleDateLastSent(payload)}
                      </ReactTooltip>
                    </Flex>
                  ) : null}
                  {payload.extra.status?.name === "Sending" ? (
                    <Flex
                      marginTop="10px"
                      alignItems="flex-end"
                      color={theme.colors.blue}
                      data-tip
                      data-for="dateScheduleStep"
                    >
                      <Text fontSize="0.8rem">Next on </Text>
                      <Text
                        marginLeft="5px"
                        fontSize="0.8rem"
                        color={theme.colors.blue}
                        fontWeight={400}
                      >
                        {handleDateSchedule(payload)}
                      </Text>
                      <ReactTooltip id="dateScheduleStep" aria-haspopup="true">
                        At {handleTimeSchedule(payload)}
                      </ReactTooltip>
                    </Flex>
                  ) : null}
                </Flex>

                <RowMenu
                  icon="menu"
                  options={rowMenuOptions}
                  resource={activePerson}
                />
              </SelectorRow>
            ))}
        </StyledSimpleBar>
      </StyledList>

      <Flex
        width="100%"
        justifyContent="flex-end"
        backgroundColor="transparent"
        marginTop="10px"
      >
        <RowsPerPage
          name="rowsperpage"
          placeholder="10"
          options={["10", "20", "30", "40", "50"]}
        />

        <Pagination
          firstPage={firstPage}
          lastPage={lastPage}
          prevPage={prevPage}
          nextPage={nextPage}
          currentPage={currentPage}
          totalPages={totalPages}
        />
      </Flex>
    </StyledContainer>
  );
};

export default PayloadSelector;
