import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//Component
import { AddToModal } from "../../components/ui/modal";

//Actions
import { workflowAssign } from "../../actions";

//Reducers
import { getProjects, getWorkspaceCurrent } from "../../reducer";

const AddToModalContainer = ({
  title,
  closeAddModal,
  addToOptions,
  itemAttached,
}) => {
  const dispatch = useDispatch();
  const projects = useSelector(getProjects);
  const workspace = useSelector(getWorkspaceCurrent);

  const [dirtyChanges, setDirtyChanges] = useState({});

  const handleDropdown = (name, value) => {
    setDirtyChanges({
      [name]: value,
    });
  };

  const handleSubmitClick = () => {
    const record = projects.data.find(
      (item) => item.attributes.name === dirtyChanges.add_to
    );

    dispatch(
      workflowAssign({
        workspace_id: workspace,
        project_id: record.id,
        id: itemAttached.id,
      })
    );
    closeAddModal();
  };

  useEffect(() => {
    setDirtyChanges({});
  }, []);

  return (
    <AddToModal
      itemAttached={itemAttached}
      title={title}
      addToOptions={addToOptions}
      handleDropdown={handleDropdown}
      dirtyChanges={dirtyChanges}
      handleSubmitClick={handleSubmitClick}
      closeModal={() => closeAddModal()}
    />
  );
};

export default AddToModalContainer;
