import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import theme from "../../../theme";
import { useHistory } from "react-router-dom";

//Icons
import Search from "../icons/search";

//Actions
import { projectPeopleSearch } from "../../../actions";

//Reducers
import { getLimit } from "../../../reducer";

const Form = styled.form`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${(props) =>
    props.barOpened ? `0 4px 8px rgba(0, 0, 0, 0.2)` : `0 0 0 0`};
  background-color: #fff;
  /* Change width of the form depending if the bar is opened or not */
  width: ${(props) => (props.barOpened ? "30rem" : "2rem")};
  /* If bar opened, normal cursor on the whole form. If closed, show pointer on the whole form so user knows he can click to open it */
  cursor: ${(props) => (props.barOpened ? "auto" : "pointer")};
  padding: 1.2rem;
  height: 1.2rem;
  border-radius: 10rem;
  transition: width 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
`;

const Input = styled.input`
  font-size: 14px;
  line-height: 1;
  background-color: transparent;
  width: 100%;
  margin-left: ${(props) => (props.barOpened ? "1rem" : "0rem")};
  border: none;
  color: ${theme.colors.grey};
  transition: margin 300ms cubic-bezier(0.645, 0.045, 0.355, 1);

  &:focus,
  &:active {
    outline: none;
  }
  &::placeholder {
    color: ${theme.colors.darkBlue};
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    outline: none;
  }
`;

const Button = styled.button`
  line-height: 1;
  pointer-events: ${(props) => (props.barOpened ? "auto" : "none")};
  cursor: ${(props) => (props.barOpened ? "pointer" : "none")};
  background-color: transparent;
  border: none;
  outline: none;
  color: white;

  *,
  *::before,
  *::after {
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    outline: none;
  }
`;

const StyledIcon = styled.div`
  height: 30px;
  width: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const InputSearchGlobal = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchBy, setSearchBy] = useState("");
  const [barOpened, setBarOpened] = useState(false);
  const limit = useSelector(getLimit);
  const formRef = useRef();
  const inputFocus = useRef();

  const handleChangeSearchBy = (event) => {
    setSearchBy(event.target.value);
  };

  const handleEnterSearchBy = (e) => {
    e.preventDefault();
    dispatch(
      projectPeopleSearch({
        search_by: searchBy,
        limit: limit,
      })
    );

    history.push(`/talent_search`);
  };

  return (
    <div className="App">
      <Form
        barOpened={barOpened}
        onClick={() => {
          // When form clicked, set state of baropened to true and focus the input
          setBarOpened(true);
          inputFocus.current.focus();
        }}
        // on focus open search bar
        onFocus={() => {
          setBarOpened(true);
          inputFocus.current.focus();
        }}
        // on blur close search bar
        onBlur={() => {
          setBarOpened(false);
        }}
        // On submit, call the onFormSubmit function
        onSubmit={handleEnterSearchBy}
        ref={formRef}
      >
        <Button type="submit" barOpened={barOpened}>
          <StyledIcon>
            <Search variant="darkBlue" />
          </StyledIcon>
        </Button>
        <Input
          onChange={handleChangeSearchBy}
          ref={inputFocus}
          value={searchBy}
          barOpened={barOpened}
          placeholder="Search for a talent..."
        />
      </Form>
    </div>
  );
};

export default InputSearchGlobal;
