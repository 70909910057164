import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//Actions
import { projectCreate, workflowsGet, peopleImport } from "../../actions";

//Reducers
import { getWorkflowsDataData, getWorkspaceCurrent } from "../../reducer";

//Component
import { NewProjectModal } from "../../components/domain/projects";

const NewProjectModalContainer = ({ showButton, variant }) => {
  const dispatch = useDispatch();
  const [dirtyChanges, setDirtyChanges] = useState({});
  const [showModal, setState] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const workspaceId = useSelector(getWorkspaceCurrent);
  const workflows = useSelector(getWorkflowsDataData);

  const getWorkflowByName = (workflowName) => {
    return (
      workflows.find((workflow) => workflow.attributes.name === workflowName) ||
      {}
    );
  };

  const handleDatepicker = (value, name) => {
    setDirtyChanges({
      ...dirtyChanges,
      [name]: value,
    });
  };

  const handleDropdownSelect = (name, value) => {
    setDirtyChanges({
      ...dirtyChanges,
      [name]: value,
    });
  };

  const handleInputChange = (event) => {
    setDirtyChanges({
      ...dirtyChanges,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmitClick = () => {
    const workflow = getWorkflowByName(dirtyChanges.workflow);
    const formData = new FormData();
    if (workspaceId) formData.append("data[workspace_id]", workspaceId);

    dispatch(
      projectCreate({
        ...dirtyChanges,
        workspace_id: workspaceId,
        workflow_id: workflow.id,
      })
    );

    if (selectedFile) {
      formData.append("data[file]", selectedFile);
      dispatch(
        peopleImport({
          formData,
        })
      );
    }

    setState(false);
  };

  const handleInputAddPeopleChange = (event) => {
    event.preventDefault();
    setSelectedFile(event.target.files[0]);
  };

  const handleVisibility = (value) => {
    setDirtyChanges({
      ...dirtyChanges,
      visible: value,
    });
  };

  useEffect(() => {
    //TODO: delete this when context provider is working
    if (showModal) {
      dispatch(workflowsGet({ workspace_id: workspaceId }));
    }
    setDirtyChanges({
      visible: true
    });
  }, [showModal]);

  return (
    <NewProjectModal
      dispatch={dispatch}
      workflows={workflows}
      dirtyChanges={dirtyChanges}
      handleSubmitClick={handleSubmitClick}
      handleChange={handleInputChange}
      handleDropdown={handleDropdownSelect}
      handleDropdownSelect={handleDropdownSelect}
      selectedFile={selectedFile}
      workspaceId={workspaceId}
      handleInputAddPeopleChange={handleInputAddPeopleChange}
      showModal={showModal}
      showButton={showButton}
      variant={variant}
      openModal={() => setState(true)}
      closeModal={() => setState(false)}
      handleDatepicker={handleDatepicker}
      asyncReload={() => dispatch(workflowsGet({ workspace_id: workspaceId }))}
      handleVisibility={handleVisibility}
    />
  );
};

export default NewProjectModalContainer;
