import React from "react";
import moment from "moment";

import styled from "styled-components";
import theme from "../../../theme";

//components
import Box from "../../ui/box";
import Flex from "../../ui/flex";
import Text from "../../ui/text";
import Picture from "../../ui/picture";
import Row from "../../ui/row";
import Status from "../../ui/status";
import Avatar from "../../ui/avatar/index";

import Image from "../../../assets/img/avatar.png";

//icons
import Calendar from "../../ui/icons/calendar";

const InfoBox = styled(Box)`
  padding: 0px 5px;
  width: 300px;
  font-family: "Open Sans";
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const UserName = styled(Text)`
  font-size: 1rem;
  color: ${theme.colors.gray};
  font-family: "Open Sans";
  flex-wrap: nowrap;
`;

const UserData = styled(Text)`
  align-items: center;
  font-size: 0.9rem;
  display: flex;
  flex-direction: row;
  color: ${theme.colors.gray};
  font-family: "Open Sans";
  flex-wrap: nowrap;
`;

const PendingRow = styled(Row)`
  padding-left: 10px;
  border-left: 8px solid ${theme.colors.lightRed};
  justify-content: space-around;
`;

const PendingInvitationsList = ({ invitations, included, viewport }) => {
  const strTitleize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const getUserIncludedById = (id) => {
    const record = included.find(
      (item) => item.id === id && item.type === "user"
    );
    return record.attributes;
  };

  return (
    <Flex
      marginTop="2%"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width={viewport > 2000 ? "85%" : "95%"}
      backgroundColor={theme.colors.lighterGrey}
    >
      {invitations.map((invitation, index) => (
        <PendingRow key={index}>
          <InfoBox>
            <Box marginRight="15px">
              <Picture bgImage={Image}></Picture>
            </Box>
            <Box justifyContent="space-evenly">
              <UserName>
                {getUserIncludedById(invitation.attributes.user_to_id).email}
              </UserName>
            </Box>
          </InfoBox>
          <Box>
            <Flex alignItems="center">
              <Avatar
                noLink="true"
                size="small"
                variant="blue"
                content={
                  invitation.attributes.project_role.charAt(0) +
                  invitation.attributes.project_role.charAt(1)
                }
              />
              <UserData>{strTitleize(invitation.attributes.project_role)}</UserData>
            </Flex>
          </Box>
          <Box>
            <Status status="waiting" />
          </Box>
          <Box>
            <UserData icon={<Calendar variant="blue" />}>
              {moment(invitation.attributes.update_at).calendar() ||
                "No recent activity"}
            </UserData>
          </Box>
        </PendingRow>
      ))}
    </Flex>
  );
};

export default PendingInvitationsList;
