import React from "react";
import styled from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";
import ArrowUp from "../icons/arrowUp";
import ArrowDown from "../icons/arrowDown";

const StyledContainer = styled.div`
  display: flex;
  justify-content: ${({ isExpanded }) => isExpanded ? "flex-start" : "center"};
  align-items: center;
  width: 100%;
  height: 52px;
  ${({ onClick }) => onClick && `cursor: pointer;`}
`;

const StyledIcon = styled.div`
  width: 21px;
  height: 21px;
`;

const StyledContent = styled.span`
  font-size: 9px;
  font-weight: 400;
  text-transform: uppercase;
  color: ${props => props.variant === 'grey' ? theme.colors.grey : '#E94E1B'};
  font-family: 'Rubik';
  display: flex;
  align-items: center;
  justify-content: center;

  ${props => props.circleVariant && `
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 1px solid #E94E1B;
    background: white;
    margin-right: ${props.isExpanded ? '10px' : '0'};
    padding: ${props.isExpanded ? '0' : '10px'};
  `}
`;


const FullText = styled.span`
  font-size: 0.9rem;
  font-weight: ${props => props.active ? 'bold' : 'normal'};
  margin-left: ${props => props.isAccountSettings ? '-10.5rem' : '6px'};
  white-space: nowrap;
  overflow: ${props => props.noEllipsis ? 'visible' : 'hidden'};
  text-overflow: ${props => props.noEllipsis ? 'clip' : 'ellipsis'};
  color: ${props => props.variant === 'grey' ? theme.colors.grey : '#E94E1B'};
`;


const ArrowContainer = styled.div`
  display: flex;
  height: 15px;
  width: 15px;
  margin-left: auto;
  margin-right: 10px;
`;

const StyledButton = styled("a")(
  ({ isExpanded, ...props }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: isExpanded ? "flex-start" : "center",
    alignItems: "center",
    width: "100%", 
    height: "27px",
    padding: "3px",
    margin: "10px",
    borderRadius: "4px",
    background: props.bgCustom,
    color: theme.colors.white,
    "&:hover": {
      boxShadow: "0px 0px 3px #CAF0FC",
      cursor: "pointer",
    },
  }),

  variant({
    variants: {
      blue: {
        // background: "rgb(63, 177, 213)",
        background:
          "linear-gradient(130deg, rgba(63, 177, 213, 1) 0%, rgba(47, 133, 160, 1) 100%)",
      },
      yellow: {
        // background: "rgb(251,237,0)",
        background:
          "linear-gradient(130deg, rgba(251,237,0,1) 0%, rgba(255,163,0,1) 100%)",
      },
      violet: {
        // background: "rgb(194,122,191)",
        background:
          "linear-gradient(130deg, rgba(194,122,191,1) 0%, rgba(123,12,141,1) 100%)",
      },
      red: {
        // background: "rgb(255,97,9)",
        background:
          "linear-gradient(130deg, rgba(255,97,9,1) 0%, rgba(160,47,47,1) 100%)",
      },
      green: {
        // background: "rgb(153,217,41)",
        background:
          "linear-gradient(130deg, rgba(153,217,41,1) 0%, rgba(47,160,91,1) 100%)",
      },
      pink: {
        // background: "rgb(240,152,228)",
        background:
          "linear-gradient(130deg, rgba(240,152,228,1) 0%, rgba(177,24,147,1) 100%)",
      },
      orange: {
        fill: "#e94e1b",
      },
      /*  active: {
        background:
          "linear-gradient(130deg, rgba(248,153,123,1) 0%, rgba(255,207,177,1) 100%)",
      }, */
      circle: {
        width: "26px",
        height: "26px",
        padding: "10px",
        background: "white",
        borderRadius: "50%",
        border: "1px solid #E94E1B",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        fontSize: "9px",
        color: "#E94E1B",
        fontFamily: "Rubik",
        fontWeight: "400",
        "&:focus": {
          // Add focus style here if needed, else leave blank to use default
        },
        active: {
          // ... active styles
        },
      },
      active: {
        border: "1px solid #CAF0FC",
        background:
          "linear-gradient(130deg, rgba(63, 177, 213, 1) 0%, rgba(47, 133, 160, 1) 100%)",
      },
      disabled: {
        color: theme.colors.lighterGrey,
        bg: theme.colors.lightGrey,
        "&:hover": { cursor: "not-allowed", boxShadow: "none" },
      },
    },
  })
);

const SidebarMainBtn = ({
  content,
  fullText,
  active,
  variant,
  bgCustom,
  icon,
  onClick,
  isExpanded,
  showArrowCondition = true,
  isAccountSettings,
  ...props
}) => {
  const showArrow = isExpanded && showArrowCondition;

  return (
    <StyledContainer onClick={onClick} isExpanded={isExpanded} {...props}>
      <StyledButton
        active={active}
        variant={variant}
        bgCustom={bgCustom}
        isExpanded={isExpanded}
        {...props}
      >
        {icon && <StyledIcon>{icon}</StyledIcon>}
        <StyledContent circleVariant={variant === "circle"}>{content}</StyledContent>
      </StyledButton>
      {isExpanded && (
        <>
          <FullText variant={variant} active={active} isExpanded={isExpanded} isAccountSettings={isAccountSettings}>{fullText}</FullText> {/* Pass the variant prop */}
          {showArrow && (
            <ArrowContainer>
              {active ? <ArrowUp variant="orange" /> : <ArrowDown variant="orange" />}
            </ArrowContainer>
          )}
        </>
      )}
    </StyledContainer>
  );
};

export default SidebarMainBtn;