import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//Actions
import {
  projectUpdate,
  peopleImport,
  workflowsGet,
  workflowGet,
} from "../../actions";

//Reducer
import {
  getWorkflowsDataData,
  getWorkflowsCurrent,
  getWorkspaceCurrent,
} from "../../reducer";

//Component
import { EditProjectModal, ShowProjectModal } from "../../components/domain/projects";

//Helper
import { isArchivedProject } from "@utils/project-helper";

const EditProjectContainerModal = ({
  project,
  isEditActive,
  closeEditModalHandler,
}) => {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState();
  const [dirtyChanges, setDirtyChanges] = useState({});
  const [showModal, setState] = useState(isEditActive);
  const workspaceId = useSelector(getWorkspaceCurrent);
  const workflows = useSelector(getWorkflowsDataData);
  const currentWorkflow = useSelector(getWorkflowsCurrent);

  const handleDatepicker = (value, name) => {
    setDirtyChanges({
      ...dirtyChanges,
      [name]: value,
    });
  };

  const handleDropdown = (name, value) => {
    setDirtyChanges({
      ...dirtyChanges,
      [name]: value,
    });
  };

  const handleDropdownSelect = (name, value) => {
    setDirtyChanges({
      ...dirtyChanges,
      [name]: value,
    });
  };

  const handleInputChange = (event) => {
    setDirtyChanges({
      ...dirtyChanges,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmitClick = () => {
    const formData = new FormData();
    if (workspaceId) formData.append("data[workspace_id]", workspaceId);

    dispatch(
      projectUpdate({
        ...dirtyChanges,
        id: project.id,
        workspace_id: project.attributes.workspace_id,
      })
    );

    if (selectedFile) {
      formData.append("data[file]", selectedFile);
      formData.append("data[project_id]", project.id);
      dispatch(
        peopleImport({
          formData,
        })
      );
    }

    setState(false);
    closeEditModalHandler();
  };

  const handleInputAddPeopleChange = (event) => {
    event.preventDefault();
    setSelectedFile(event.target.files[0]);
  };

  const handleVisibility = (value) => {
    setDirtyChanges({
      ...dirtyChanges,
      visible: value,
    });
  };

  useEffect(() => {
    setDirtyChanges({});
    if (showModal) {
      dispatch(workflowsGet({ workspace_id: workspaceId }));
      const workflowId = project && project.attributes?.workflow_id;
      if (workflowId) {
        dispatch(workflowGet({ workspace_id: workspaceId, id: workflowId }));
      }
    }
  }, [showModal]);

  return (
    <>
      {(isArchivedProject(project)) ?
        <ShowProjectModal
          dispatch={dispatch}
          project={project}
          dirtyChanges={dirtyChanges}
          handleSubmitClick={handleSubmitClick}
          handleChange={handleInputChange}
          handleDropdown={handleDropdown}
          handleDropdownSelect={handleDropdownSelect}
          showModal={showModal}
          openModal={() => setState(true)}
          closeModal={() => closeEditModalHandler()}
          handleDatepicker={handleDatepicker}
          selectedFile={selectedFile}
          handleInputAddPeopleChange={handleInputAddPeopleChange}
          workflows={workflows}
          currentWorkflow={currentWorkflow}
          handleVisibility={handleVisibility}
        />
        :
        <EditProjectModal
          dispatch={dispatch}
          project={project}
          dirtyChanges={dirtyChanges}
          handleSubmitClick={handleSubmitClick}
          handleChange={handleInputChange}
          handleDropdown={handleDropdown}
          handleDropdownSelect={handleDropdownSelect}
          showModal={showModal}
          openModal={() => setState(true)}
          closeModal={() => closeEditModalHandler()}
          handleDatepicker={handleDatepicker}
          selectedFile={selectedFile}
          handleInputAddPeopleChange={handleInputAddPeopleChange}
          workflows={workflows}
          currentWorkflow={currentWorkflow}
          handleVisibility={handleVisibility}
        />
      }
    </>
  )
};

export default EditProjectContainerModal;
