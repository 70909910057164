import React from "react";
import styled from "styled-components";
import theme from "../../../theme";

// Components
import Card from "@ui/card/index";
import Flex from "@ui/flex";
import Button from "@ui/button/index";
import Text from "@ui/text/index";

//Icons
import Close from "@ui/icons/close";

const StyledBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99999;
`;

const StyledCardContainer = styled.div`
  position: fixed;
  background: transparent;
  width: 40%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const AlertModal = ({ item, closeModal, handleSubmitClick, buttonSubmitText, title, description }) => {
  return (
    <StyledBackground>
      <StyledCardContainer>
        <Card
          fullHeader
          title={title}
          icon={<Close variant="grey"></Close>}
          closeHandler={closeModal}
          footer
          buttons={[
            <Button
              key="cancel"
              variant="secondaryLine"
              content="Cancel"
              onClick={closeModal}
            />,
            <Button
              key="submit"
              variant="secondaryLine"
              content={buttonSubmitText}
              onClick={() => handleSubmitClick(item)}
            />,
          ]}
        >
          <Flex width="90%" alignItems="center" flexDirection="column">
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="space-evenly"
              height="200px"
            >
              <Text
                textAlign="center"
                fontSize="1rem"
                marginTop="15px"
                color={theme.colors.red}
                wrap
              >
                {description}
              </Text>
              <Text textAlign="center" fontSize="1rem" marginTop="15px">
                Are you sure you want to proceed?
              </Text>
            </Flex>
          </Flex>
        </Card>
      </StyledCardContainer>
    </StyledBackground>
  );
};

export default AlertModal;
