import { useSelector, useDispatch } from "react-redux";
import * as R from "ramda";

//Actions
import { integrationsGet } from "../actions";

//Reducers
import { getIntegrations } from "../reducer";

const useIntegrations = () => {
  const dispatch = useDispatch();
  const integrations = useSelector(getIntegrations);

  const asyncReloadIntegrations = (workspaceId) => {
    dispatch(integrationsGet({ workspace_id: workspaceId }));
  };

  const integrationsProviders = () => {
    return R.uniq(
      integrations?.data.data.map((integration) => {
        return {
          id: integration.attributes.provider_type,
          val: integration.attributes.provider_type,
        };
      })
    );
  };

  const integrationsCredencials = (providerType) => {
    return integrations?.data.data
      .filter((item) => item.attributes.provider_type === providerType)
      .map((integration) => {
        return {
          id: integration.id,
          val: integration.attributes.name,
        };
      });
  };

  const searchCredencialNameById = (credentialId) => {
    return integrations.data.data.filter(
      (item) => item.attributes.id === credentialId
    )[0].attributes.name;
  };

  const searchCredencialTypeById = (credentialId) => {
    return integrations.data.data.filter(
      (item) => item.attributes.id === credentialId
    )[0].attributes.provider_type;
  };

  const searchCredencialEmailById = (credentialId) => {
    return integrations.data.data.filter(
      (item) => item.attributes.id === credentialId
    )[0].attributes.email;
  };

  const searchCredencialAliases = (providerId) => {
    if (!providerId) { return [] }

    const credential = integrations.data.data.filter(
      (item) => item.attributes.id === providerId
    );

    if (credential.length === 0) return null;

    return [
      credential[0].attributes.email,
      ...credential[0].attributes.aliases
    ];
  };

  return {
    asyncReloadIntegrations,
    integrationsCredencials,
    integrationsProviders,
    searchCredencialNameById,
    searchCredencialEmailById,
    searchCredencialAliases,
    searchCredencialTypeById
  };
};

export default useIntegrations;
