import { combineEpics, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, flatMap, map, mergeMap, mapTo } from "rxjs/operators";
import qs from "qs";
import { toast } from "react-toastify";

import {
  PROJECT_PEOPLE_GET,
  projectPeopleGet,
  projectPeopleGetSuccess,
  projectPeopleGetFailure,
  PROJECT_PEOPLE_MOVE,
  PROJECT_PEOPLE_MOVE_WITH_RELOAD,
  projectPeopleMoveSuccess,
  projectPeopleMoveFailure,
  PROJECT_PEOPLE_UPDATE,
  projectPeopleUpdateSuccess,
  projectPeopleUpdateFailure,
  PROJECT_PEOPLE_DELETE,
  projectPeopleDeleteSuccess,
  projectPeopleDeleteFailure,
  PROJECT_PEOPLE_SEARCH,
  projectPeopleSearchSuccess,
  projectPeopleSearchFailure,
  PROJECT_PEOPLE_RESPONSE_UPDATE,
  projectPeopleResponseUpdateSuccess,
  projectPeopleResponseUpdateFailure,
  SEQUENCE_PROCESSOR_SEND_EVENT,
  sequenceProcessorSendEventSuccess,
  sequenceProcessorSendEventFailure,
  projectPeoplePayloadsGet,
} from "@actions";

const getProjectPeopleEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(PROJECT_PEOPLE_GET),
    flatMap(({ payload: { id, search_by, by_stages, limit, url } }) => {
      const getPath = url ? url : `/projects/${id}/project_people`;
      return from(
        api
          .get(getPath, {
            params: {
              search_by: search_by,
              "by_stages[]": by_stages,
              limit: limit,
            },
            paramsSerializer: (params) => {
              return qs.stringify(params, { arrayFormat: "repeat" });
            },
          })
          .then((resp) => resp)
      ).pipe(
        map(projectPeopleGetSuccess),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map((error) => toast.error(error.detail));
          return of(projectPeopleGetFailure({ err, errors }));
        })
      );
    })
  );

const searchProjectPeopleEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(PROJECT_PEOPLE_SEARCH),
    flatMap(({ payload: { id, search_by, limit, url } }) => {
      const getPath = url ? url : `/project_people/global_search`;
      return from(
        api
          .get(getPath, {
            params: {
              search_by: search_by,
              limit: limit,
            },
            paramsSerializer: (params) => {
              return qs.stringify(params, { arrayFormat: "repeat" });
            },
          })
          .then((resp) => resp)
      ).pipe(
        map(projectPeopleSearchSuccess),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map((error) => toast.error(error.detail));
          return of(projectPeopleSearchFailure({ err, errors }));
        })
      );
    })
  );

const moveProjectPeopleEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(PROJECT_PEOPLE_MOVE),
    flatMap(
      ({
        payload: { project_id, id, stage_id, search_by, limit, by_stages },
      }) => {
        return from(
          api
            .put(`/projects/${project_id}/project_people/${id}/move`, {
              data: {
                type: "project_person",
                id,
                attributes: {
                  stage_id,
                },
              },
            })
            .then((resp) => resp)
        ).pipe(
          map(projectPeopleMoveSuccess),
          catchError((err) => {
            let errors = [];
            if (err.response) {
              errors = err.response.data.errors;
            }
            return of(projectPeopleMoveFailure({ err, errors }));
          })
        );
      }
    )
  );

const moveProjectPeopleWithReloadEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(PROJECT_PEOPLE_MOVE_WITH_RELOAD),
    flatMap(
      ({
        payload: { project_id, id, stage_id, search_by, limit, by_stages },
      }) => {
        return from(
          api
            .put(`/projects/${project_id}/project_people/${id}/move`, {
              data: {
                type: "project_person",
                id,
                attributes: {
                  stage_id,
                },
              },
            })
            .then((resp) => resp)
        ).pipe(
          map(projectPeopleMoveSuccess),
          mapTo(
            projectPeopleGet({
              id: project_id,
              search_by: search_by,
              limit: limit,
              by_stages: by_stages,
            })
          ),
          catchError((err) => {
            let errors = [];
            if (err.response) {
              errors = err.response.data.errors;
            }
            return of(projectPeopleMoveFailure({ err, errors }));
          })
        );
      }
    )
  );

const updateProjectPeopleEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(PROJECT_PEOPLE_UPDATE),
    mergeMap(
      ({
        payload: {
          id,
          first_name,
          last_name,
          email,
          email_second,
          email_work,
          company,
          title,
          phone,
          city,
          state,
          country,
          linkedin,
          github,
          twitter,
          facebook,
          degree,
          website,
          workspace_id,
          project_id,
          search_by,
          limit,
          by_stages,
        },
      }) => {
        return from(
          api
            .put(`/people/${id}`, {
              data: {
                type: "person",
                id,
                attributes: {
                  first_name,
                  last_name,
                  email,
                  email_second,
                  email_work,
                  phone,
                  city,
                  state,
                  country,
                  linkedin,
                  github,
                  twitter,
                  facebook,
                  degree,
                  website,
                  company,
                  title,
                },
              },
            })
            .then((resp) => resp)
        ).pipe(
          map(projectPeopleUpdateSuccess),
          mapTo(
            projectPeopleGet({
              id: project_id,
              search_by,
              limit,
              by_stages,
            })
          ),
          catchError((err) => {
            let errors = [];
            if (err.response) {
              errors = err.response.data.errors;
            }
            return of(projectPeopleUpdateFailure({ err, errors }));
          })
        );
      }
    )
  );

const updateProjectPeopleResponseEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(PROJECT_PEOPLE_RESPONSE_UPDATE),
    mergeMap(({ payload: { project_id, project_people_id, response } }) => {
      return from(
        api
          .put(
            `projects/${project_id}/project_people/${project_people_id}/person_response`,
            {
              data: {
                type: "project_person",
                attributes: {
                  response,
                },
              },
            }
          )
          .then((resp) => resp)
      ).pipe(
        map(projectPeopleResponseUpdateSuccess),
        mapTo(
          projectPeopleGet({
            id: project_id,
          })
        ),
        catchError((err) => {
          let errors = [];
          if (err.response) {
            errors = err.response.data.errors;
          }
          return of(projectPeopleResponseUpdateFailure({ err, errors }));
        })
      );
    })
  );

const deleteProjectPeopleEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(PROJECT_PEOPLE_DELETE),
    mergeMap(({ payload: { id, project_id, search_by, limit, by_stages } }) => {
      return from(
        api
          .delete(`/projects/${project_id}/project_people/${id}`, {
            data: {
              type: "project_person",
              id,
            },
          })
          .then((resp) => resp)
      ).pipe(
        map(projectPeopleDeleteSuccess),
        mapTo(
          projectPeopleGet({
            id: project_id,
            search_by,
            limit,
            by_stages,
          })
        ),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map((error) => toast.error(error.detail));
          return of(projectPeopleDeleteFailure({ err, errors }));
        })
      );
    })
  );

const sendEventSequenceProcessorEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(SEQUENCE_PROCESSOR_SEND_EVENT),
    mergeMap(
      ({
        payload: {
          limit,
          project_id,
          project_people_id,
          sequence_processor_id,
          event_name,
        },
      }) => {
        return from(
          api
            .put(
              `projects/${project_id}/project_people/${project_people_id}/sequence_processors/${sequence_processor_id}/event`,
              {
                data: {
                  type: "sequence_processor",
                  event_name,
                },
              }
            )
            .then((resp) => resp)
        ).pipe(
          map(sequenceProcessorSendEventSuccess),
          mapTo(
            projectPeopleGet({
              id: project_id,
              limit: limit,
            })
          ),
          catchError((err) => {
            const errors = err.response ? err.response.data.errors : [];
            errors.map((error) => toast.error(error.detail));
            return of(sequenceProcessorSendEventFailure({ err, errors }));
          })
        );
      }
    )
  );

export default combineEpics(
  getProjectPeopleEpic,
  searchProjectPeopleEpic,
  moveProjectPeopleEpic,
  moveProjectPeopleWithReloadEpic,
  updateProjectPeopleEpic,
  updateProjectPeopleResponseEpic,
  deleteProjectPeopleEpic,
  sendEventSequenceProcessorEpic
);
