import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import theme from "../../../theme";
import { NavLink } from "react-router-dom";

import Box from "../../ui/box";
import Row from "../../ui/row";
import Text from "../../ui/text";
import Flex from "../../ui/flex";
import RowMenu from "../../ui/row-menu-button";
import Toggle from "../../ui/toggle-switch";
import Spinner from "../../ui/spinner";

//Component
import ProjectIntro from "./ProjectIntro";

//Reducer
import { getProjectsLoading, getProjectAccessList } from "@reducer";

//Icon
import Goal from "../../ui/icons/goal";
import Date from "../../ui/icons/calendar";

import Priority from "../priority/Priority";
import Access from "../access/Access";

//Helper
import { isArchivedProject } from "@utils/project-helper";

const StyledNavLink = styled(NavLink)`
  width: 100%;
  margin: 0;
  text-decoration: none;
  outline: none;
  display: flex;
`;

const TextLink = styled(Text)`
  font-family: "Open Sans";
  color: ${theme.colors.blue};
  font-size: 1.2rem;
  padding-left: 3px;
  width: 100%;

  * &:hover {
    filter: drop-shadow(0px 0px 3px #caf0fc);
    color: ${theme.colors.darkBlue};
  }
`;

const ProjectsListb = ({
  projects,
  viewport,
  rowMenuOptions,
  statusProjectHandler
}) => {
  const isPayloadLoading = useSelector(getProjectsLoading);

  return isPayloadLoading ? (
    <Spinner />
  ) : (
    <Flex
      marginTop="3%"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width={viewport > 2000 ? "85%" : "95%"}
      backgroundColor={theme.colors.lighterGrey}
      marginBottom="30px"
    >
      {projects.data?.length > 0 ? (
        projects.data.map((project, index) => (
          <Row key={project.id}>
            <Box flex="column" width="20%" paddingLeft="15px">
              <StyledNavLink
                to={`/workspaces/${project.attributes.workspace_id}/project/${project.id}`}
                style={{ textDecoration: "none" }}
              >
                <TextLink
                  color={theme.colors.blue}
                  fontFamily="Open Sans"
                  fontSize="1.2rem"
                >
                  {project.attributes.name}
                </TextLink>
              </StyledNavLink>
            </Box>
            <Box flex="column" width="25%">
              <Text
                margin="5px"
                alignItems="center"
                fontSize="1"
                display="flex"
                flexDirection="row"
                icon={<Goal variant="blue" />}
                color={theme.colors.gray}
                fontFamily="Open Sans"
              >
                {project.attributes.description}
              </Text>
              <Text
                margin="5px"
                alignItems="center"
                fontSize="1"
                display="flex"
                flexDirection="row"
                icon={<Date variant="blue" />}
                color={theme.colors.gray}
                fontFamily="Open Sans"
              >
                {project.attributes.start_date} -{" "}
                {project.attributes.deadline || "//"}
              </Text>
            </Box>
            <Box width="130px">
              <Toggle
                initialLabel={project.attributes.status}
                initialValue={project.attributes.status === "Active"}
                handleChange={statusProjectHandler}
                disabled={isArchivedProject(project)}
                customeLabel={(isArchivedProject(project)) ? "Archived" : null}
              />
            </Box>
            <Box width="100px">
              <Priority priority={project.attributes.priority} />
            </Box>
            <Box width="20%">
              <Access
                label="Access"
                access={getProjectAccessList(project, projects)}
              />
            </Box>
            <RowMenu
              icon="config"
              options={rowMenuOptions}
              resource={project}
            />
          </Row>
        ))
      ) : (
        <ProjectIntro viewport={viewport} />
      )}

    </Flex>

  );
};

export default ProjectsListb;
