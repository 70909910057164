import React from "react";
import styled from "styled-components";
import theme from "../../../theme";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

import Button from "../../ui/button";
import Flex from "../../ui/flex";

//icons
import Valid from "../../ui/icons/valid";
import Facebook from "../../ui/icons/facebook";
import Twitter from "../../ui/icons/twitter";
import Mail from "../../ui/icons/message";
import Linkedin from "../../ui/icons/linkedin";
import WebIcon from "../../ui/icons/web";

const StyledContainer = styled.div`
  margin: 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: white;

  @media (max-width: 660px) {
    width: 100%;
  }
`;

const StyledContainerWhite = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (max-width: 660px) {
    width: 100%;
    height: auto;
  }
`;

const StyledWhiteArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 70%;
  height: 100%;
  margin: 30px 0px 120px 0px;

  @media (max-width: 660px) {
    width: 90%;
    justify-content: center;
    flex-flow: wrap;
    height: 100%;
  }

  @media (min-width: 660px) and (max-width: 1260px) {
    width: 95%;
    margin: 50px 0px 0px 0px;
  }
`;

const StyledfeatureArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;

  @media (max-width: 660px) {
    width: 100%;
    ${(props) => (props.marginBottom ? "margin-bottom: 30px;" : null)}
  }
`;

const StyledCase = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;

  @media (max-width: 660px) {
    width: 100%;
  }
`;

const StyledfeatureBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  @media (max-width: 660px) {
    width: 100%;
  }
`;

const StyledTitle = styled.div`
  display: flex;
  font-size: 3rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: 200;
  width: 900px;
  color: ${theme.colors.grey};
  font-family: ${theme.fonts[1]};
  text-shadow: 0px 2px 2px #afafaf;
  height: 300px;

  @media (max-width: 660px) {
    font-size: 2rem;
    width: 90%;
  }
`;

const StyledTitleSmall = styled.div`
  font-size: ${(props) => (props.bold ? "1.9rem" : "2rem")};
  text-align: center;
  text-transform: uppercase;
  font-weight: ${(props) => (props.bold ? 800 : 200)};
  color: ${theme.colors.grey};
  font-family: ${theme.fonts[1]};

  ${(props) => (props.bold ? `margin-bottom: 20px;` : null)};

  @media (min-width: 660px) and (max-width: 1260px) {
    font-size: ${(props) => (props.bold ? "1.5rem" : "1.6rem")};
  }
`;

const StyledTextBox = styled.div`
  margin-bottom: 100px;
  width: 70%;
  color: ${theme.colors.grey};
  font-family: ${theme.fonts[0]};

  @media (max-width: 660px) {
    width: 90%;
    margin-top: 30px;
    margin-bottom: 0px;
  }

  @media (min-width: 660px) and (max-width: 1260px) {
    width: 40%;
    margin-top: 40px;
    margin-bottom: 40px;
  }
`;

const StyledSubtitleSmall = styled.div`
  text-transform: uppercase;
  width: 70%;
  font-size: 1.3rem;
  margin: 15px 0px;
  font-weight: 400;

  @media (max-width: 660px) {
    width: 80%;
  }
  @media (min-width: 660px) and (max-width: 1260px) {
    width: 80%;
  }
`;

const StyledFeatures = styled.div`
  width: 100%;
  font-size: 1.2rem;
  margin: 15px 0px;
  font-weight: 200;
  color: ${theme.colors.grey};

  @media (min-width: 660px) and (max-width: 1260px) {
    font-size: 1rem;
  }
`;

const StyledText = styled.div`
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin: 10px 0px;
  font-weight: 200;
  width: 90%;
`;

const CardDividerTop = styled.div`
  width: 100%;
  height: 20px;
  background: rgb(201, 201, 201);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(230, 230, 230, 1) 80%
  );
`;

const StyledFooter = styled.div`
  font-family: "Open Sans", sans-serif;
  min-height: 20vmin;
  background: ${theme.colors.grey};
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: auto;
  padding: 20px 0px;
`;

const StyledFooterArea = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 660px) {
    width: 85%;
    justify-content: space-between;
    margin: 15px 0px;
  }
  @media (min-width: 660px) and (max-width: 1260px) {
    margin: 15px 0px;
  }
`;

const StyledFooterRow = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;

  @media (max-width: 660px) {
    width: 80%;
    flex-direction: column;
  }
`;

const StyledFooterDivider = styled.div`
  margin: 15px 0px;
  width: 70%;
  border-bottom: 1px solid #666;

  @media (max-width: 1260px) {
    width: 90%;
    margin: 0px;
  }
`;

const StyledFooterLink = styled.a`
  text-decoration: none;
  text-transform: uppercase;
  color: ${theme.colors.white};
  margin-right: 20px;
  font-size: 0.7rem;

  &:hover {
    cursor: pointer;
    color: ${theme.colors.lightBlue};
  }

  @media (max-width: 660px) {
    margin-right: 0px;
    padding: 0px 2px;
    color: ${theme.colors.lighterBlue};
  }

  @media (min-width: 660px) and (max-width: 1260px) {
    margin-right: 20px;
  }
`;

const StyledFooterLinkSmall = styled.a`
  text-decoration: none;
  text-transform: uppercase;
  color: ${theme.colors.blue};
  margin-right: 10px;
  font-size: 0.5rem;

  &:hover {
    cursor: pointer;
    color: ${theme.colors.lightBlue};
  }

  @media (max-width: 660px) {
    margin-right: 0px;
    padding: 0px 2px;
    color: ${theme.colors.lightBlue};
    border-bottom: 0.5px solid ${theme.colors.lightBlue};
  }

  @media (min-width: 660px) and (max-width: 1260px) {
    padding: 0px 2px;
    color: ${theme.colors.lightBlue};
    border-bottom: 0.5px solid ${theme.colors.lightBlue};
  }
`;

const StyledFooterIconLink = styled.a`
  text-decoration: none;
  margin-left: 20px;
  height: 25px;
  width: 25px;

  @media (max-width: 660px) {
    margin-right: 0px;
  }
`;
const Circle = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${theme.colors.red};
`;

const StyledRow = styled.div`
  display: flex;
  border-top: 1px solid #f1f1f1;
`;

const StyledCellTitle = styled(Flex)`
  padding: 20px 10px;
  font-size: 1rem;
`;

const StyledCellExample = styled(Flex)`
  padding: 25px 20px;
  font-size: 1.1rem;
  line-height: 1.3rem;
  font-weight: 200;
  color: ${theme.colors.grey};
`;

const PrivacyPolicyPage = () => {
  const dispatch = useDispatch();
  const viewportWidht = window.innerWidth;

  const openLink = (path) => {
    dispatch(push(path));
  };

  const handleJoinNow = () => {
    openLink(`/sign_up`);
  };

  return (
    <Flex
      marginTop={"64px"}
      alignItems={"center"}
      width={"100%"}
      flexDirection={"column"}
      justifyContent={"flex-start"}
      px={{ sm: 2, md: 0 }}
      overflow="hidden"
      backgroundColor={theme.colors.white}
    >
      <StyledTitle>TEAMSOURCED Privacy Policy</StyledTitle>

      <StyledContainer>
        <StyledTextBox>
          <StyledText>
            <StyledSubtitleSmall>
              California Consumer Privacy Notice
            </StyledSubtitleSmall>
            Effective Date: [January 1, 2020] <br />
            <br />
            This Notice was created on January 15, 2020, and is intended for
            California residents only. If you are not a California resident,
            this California Consumer Privacy Notice does not apply to you.
            <br />
            <br />
            This Notice was created by Sourced, LLC dba Sourced ( “we”, “our”,
            or “us”), following the instructions of California Consumer Privacy
            Act (“CCPA”), and it will apply to any California resident
            (“California consumer” as defined under CCPA, or “you”) of whom we
            may collect Personal Information (defined below), regardless of if
            you are or not a user of sourcedllc.com (“Site”), and any mobile
            application, application programming interfaces, and other services
            offered by us (collectively the “Services”). If you are a California
            consumer, this Notice explains your rights for managing your
            Personal Information, including opting-out of certain uses of your
            Personal Information.
          </StyledText>
        </StyledTextBox>

        <StyledTextBox>
          <StyledSubtitleSmall>
            1. TeamSourced Role under CCPA
          </StyledSubtitleSmall>
          <StyledText>
            TeamSourced as a Business in California <br />
            <br />
            Under CCPA framework, TeamSourced is defined as a Business as to
            activities of collecting, sharing, and determining the means of
            processing California consumer information for Commercial Purposes.
            TeamSourced may collect Personal Information about you in order to
            provide Services to our customers including the customers’
            end-users. Personal Information is information that identifies,
            relates to, describes, is cable of being associated with, or could
            reasonably be linked, directly or indirectly, with you. Commercial
            Purpose means to advance our commercial or economic interests.
            <br />
            <br />
            TeamSourced as a Service Provider in California
            <br />
            <br /> Under CCPA framework, in certain scenarios, TeamSourced may
            be considered as a Service Provider who processes Personal
            Information on behalf of a Business – TeamSourced’s customer, and
            TeamSourced’s customer may disclose your Personal Information to
            TeamSourced for a Business Purpose pursuant to a written contract
            between TeamSourced and our customer. Business Purpose means the use
            of Personal Information for the Business’s or Service Provider’s
            operational purposes, including auditing, detecting security
            incidents, debugging, short-term/transient use, performing customer
            services, internal research, and maintain/improve/upgrade/enhance
            services.
          </StyledText>
        </StyledTextBox>

        <StyledTextBox>
          <StyledSubtitleSmall>
            2. TeamSourced’s Purposes for Collecting or Selling Personal
            Information
          </StyledSubtitleSmall>
          <StyledText>
            Commercial Purpose <br />
            <br />
            TeamSourced is a recruiting services company. We provide services to
            customers through a combination of our software solution which
            collects Personal Information and shares with our team for their
            internal operational purposes – specifically to engage with a person
            with regard to opportunities that fit that person’s skillsets and
            professional background. By sharing your Personal Information with
            our customers, we aspire to help you get to where you want to be
            professionally and personally and hope that through our Services you
            will get better career development from companies that you want to
            work with and that need your profession.
            <br />
            <br />
            Business Purpose
            <br />
            <br /> If you are a user of TeamSourced’s Site or Services, you may
            be required to provide your Personal Information to TeamSourced so
            that we can open a user account for you and provide Services to you.
            In addition, when you visit TeamSourced Site, we use cookies and
            other anonymous identifiers for authentication purposes (to keep
            track of the fact that you have logged in) and to analyze the use of
            and improve the Site and Services. We use your Personal Information
            for the following Business Purposes:
          </StyledText>
          <Flex flexDirection="column">
            <StyledCase>
              <Flex width="10px" margin={"22px 10px"}>
                <Circle></Circle>
              </Flex>
              <StyledFeatures>
                to maintain Services for you, including to send you alerts about
                your account, debug errors, and resolve problems related to your
                account;
              </StyledFeatures>
            </StyledCase>

            <StyledCase>
              <Flex width="10px" margin={"22px 10px"}>
                <Circle></Circle>
              </Flex>
              <StyledFeatures>
                to analyze, research and better understand how users access and
                use our Site and Services, both on an aggregated and
                individualized basis, to maintain, support, and improve our Site
                and Services, to respond to user preferences, and for research
                and analytical purposes;
              </StyledFeatures>
            </StyledCase>

            <StyledCase>
              <Flex width="10px" margin={"22px 10px"}>
                <Circle></Circle>
              </Flex>
              <StyledFeatures>
                to keep you informed of our programs, products and other
                services we think may be of interest to you; or
              </StyledFeatures>
            </StyledCase>

            <StyledCase>
              <Flex width="10px" margin={"22px 10px"}>
                <Circle></Circle>
              </Flex>
              <StyledFeatures>
                to otherwise communicate with you about TeamSourced.
              </StyledFeatures>
            </StyledCase>
          </Flex>
          <StyledText>
            If you are not a user of TeamSourced’s Site or Services, you believe
            that TeamSourced has collected your Personal Information and you
            want to assert your rights governed under CCPA (see below) with
            regard to your Personal Information, we may require you to provide
            additional necessary Personal Information so that we can verify your
            identify and facilitate your request.
            <br />
            <br /> We will not use your Personal Information we collected for
            materially different, unrelated, or incompatible purposes other than
            the purposes indicated above without providing you notice.
          </StyledText>
        </StyledTextBox>

        <StyledTextBox>
          <StyledSubtitleSmall>
            3. Categories of Sources of Personal Information
          </StyledSubtitleSmall>
          <StyledText>
            Non-user Information:
            <br />
          </StyledText>
          <Flex flexDirection="column">
            <StyledCase>
              <Flex width="10px" margin={"22px 10px"}>
                <Circle></Circle>
              </Flex>
              <StyledFeatures>
                Your Public Profiles. TeamSourced may collect your Personal
                Information related to your profession that you published on the
                open websites and shared with the general public.
              </StyledFeatures>
            </StyledCase>

            <StyledCase>
              <Flex width="10px" margin={"22px 10px"}>
                <Circle></Circle>
              </Flex>
              <StyledFeatures>
                TeamSourced Data Vendors. TeamSourced may collect your Personal
                Information from our Data Vendors who are legally able to or
                authorized by you to share your Personal Information related to
                your profession.
              </StyledFeatures>
            </StyledCase>

            <StyledCase>
              <Flex width="10px" margin={"22px 10px"}>
                <Circle></Circle>
              </Flex>
              <StyledFeatures>
                TeamSourced’s Customers. TeamSourced may collect your Personal
                Information from our customers who are legally able to or
                authorized by you to use TeamSourced’s Services to process your
                Personal Information related to your profession.
              </StyledFeatures>
            </StyledCase>
          </Flex>

          <StyledText>
            If you are not a user of TeamSourced’s Site or Services, you believe
            that TeamSourced has collected your Personal Information and you
            want to assert your rights governed under CCPA (see below) with
            regard to your Personal Information, we may require you to provide
            additional necessary Personal Information so that we can verify your
            identify and facilitate your request.
            <br />
            <br /> We will not use your Personal Information we collected for
            materially different, unrelated, or incompatible purposes other than
            the purposes indicated above without providing you notice.
            <br />
            <br />
            <br />
            User Information:
            <br />
            <br /> In order to use TeamSourced’s Site and Services, you, as a
            user, will provide your Personal Information to TeamSourced to
            create an account and facilitate your activities on TeamSourced’s
            Site.
            <br />
            <br />
            <br />
            Cookies and Anonymous Identifiers:
            <br />
            <br /> In order to verify users’ identity and improve our Site and
            Services, we use cookies (a small text file placed on your computer
            to identify your computer and web browser), web beacons (a
            transparent image placed on our website to monitor the activities of
            users) and other anonymous identifiers (a random string of
            characters that is used for the same purposes as a cookie) to
            collect necessary information for our business operation.
          </StyledText>
        </StyledTextBox>

        <StyledTextBox>
          <StyledSubtitleSmall>
            4. Personal Information We Collected for the Preceding 12 Months
          </StyledSubtitleSmall>
          <Flex flexDirection="column" margin="60px 0px">
            <StyledRow>
              <StyledCellTitle width="300px">
                Categories of Data
              </StyledCellTitle>
              <StyledCellTitle width="700px">Examples</StyledCellTitle>
              <StyledCellTitle width="200px">Collected Data</StyledCellTitle>
            </StyledRow>

            <StyledRow>
              <StyledCellExample width="300px">
                A. Identifiers.
              </StyledCellExample>
              <StyledCellExample width="700px">
                A real name, alias, postal address, unique personal identifier,
                online identifier, Internet Protocol address, email address,
                account name, Social Security number, driver’s license number,
                passport number, or other similar identifiers.
              </StyledCellExample>
              <StyledCellExample width="200px">
                Name, alias, email
              </StyledCellExample>
            </StyledRow>

            <StyledRow>
              <StyledCellExample width="300px">
                B. Personal information categories listed in the California
                Customer Records statute (Cal. Civ. Code § 1798.80(e)).
              </StyledCellExample>
              <StyledCellExample width="700px">
                A name, signature, Social Security number, physical
                characteristics or description, address, telephone number,
                passport number, driver’s license or state identification card
                number, insurance policy number, education, employment,
                employment history, bank account number, credit card number,
                debit card number, or any other financial information, medical
                information, or health insurance information. Some personal
                information included in this category may overlap with other
                categories.
              </StyledCellExample>
              <StyledCellExample width="200px">
                Name, phone number
              </StyledCellExample>
            </StyledRow>

            <StyledRow>
              <StyledCellExample width="300px">
                C. Protected classification characteristics under California or
                federal law.
              </StyledCellExample>
              <StyledCellExample width="700px">
                Age (40 years or older), race, color, ancestry, national origin,
                citizenship, religion or creed, marital status, medical
                condition, physical or mental disability, sex (including gender,
                gender identity, gender expression, pregnancy or childbirth and
                related medical conditions), sexual orientation, veteran or
                military status, genetic information (including familial genetic
                information).
              </StyledCellExample>
              <StyledCellExample width="200px">NOT COLLECTED</StyledCellExample>
            </StyledRow>

            <StyledRow>
              <StyledCellExample width="300px">
                D. Commercial information.
              </StyledCellExample>
              <StyledCellExample width="700px">
                Records of personal property, products or services purchased,
                obtained, or considered, or other purchasing or consuming
                histories or tendencies.
              </StyledCellExample>
              <StyledCellExample width="200px">NOT COLLECTED</StyledCellExample>
            </StyledRow>

            <StyledRow>
              <StyledCellExample width="300px">
                E. Biometric information.
              </StyledCellExample>
              <StyledCellExample width="700px">
                Genetic, physiological, behavioral, and biological
                characteristics, or activity patterns used to extract a template
                or other identifier or identifying information, such as,
                fingerprints, faceprints, and voiceprints, iris or retina scans,
                keystroke, gait, or other physical patterns, and sleep, health,
                or exercise data.
              </StyledCellExample>
              <StyledCellExample width="200px">NOT COLLECTED</StyledCellExample>
            </StyledRow>

            <StyledRow>
              <StyledCellExample width="300px">
                F. Internet or other similar network activity.
              </StyledCellExample>
              <StyledCellExample width="700px">
                Browsing history, search history, information on a consumer’s
                interaction with a website, application, or advertisement.
              </StyledCellExample>
              <StyledCellExample width="200px">NOT COLLECTED</StyledCellExample>
            </StyledRow>

            <StyledRow>
              <StyledCellExample width="300px">
                G. Geolocation data.
              </StyledCellExample>
              <StyledCellExample width="700px">
                Physical location or movements.
              </StyledCellExample>
              <StyledCellExample width="200px">NOT COLLECTED</StyledCellExample>
            </StyledRow>

            <StyledRow>
              <StyledCellExample width="300px">
                H. Sensory data.
              </StyledCellExample>
              <StyledCellExample width="700px">
                Audio, electronic, visual, thermal, olfactory, or similar
                information.
              </StyledCellExample>
              <StyledCellExample width="200px">NOT COLLECTED</StyledCellExample>
            </StyledRow>

            <StyledRow>
              <StyledCellExample width="300px">
                I. Professional or employment-related information.
              </StyledCellExample>
              <StyledCellExample width="700px">
                Current or past job history or performance evaluations
              </StyledCellExample>
              <StyledCellExample width="200px">
                Job title, skills, education, work history
              </StyledCellExample>
            </StyledRow>

            <StyledRow>
              <StyledCellExample width="300px">
                J. Non-public education information (per the Family Educational
                Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part
                99)).
              </StyledCellExample>
              <StyledCellExample width="700px">
                Education records directly related to a student maintained by an
                educational institution or party acting on its behalf, such as
                grades, transcripts, class lists, student schedules, student
                identification codes, student financial information, or student
                disciplinary records.
              </StyledCellExample>
              <StyledCellExample width="200px">NOT COLLECTED</StyledCellExample>
            </StyledRow>

            <StyledRow>
              <StyledCellExample width="300px">
                K. Inferences drawn from other personal information.
              </StyledCellExample>
              <StyledCellExample width="700px">
                Profile reflecting a person’s preferences, characteristics,
                psychological trends, predispositions, behavior, attitudes,
                intelligence, abilities, and aptitudes.
              </StyledCellExample>
              <StyledCellExample width="200px">NOT COLLECTED</StyledCellExample>
            </StyledRow>
          </Flex>

          <StyledText>
            Specifically, TeamSourced collected Personal Information differently
            based on your role as a non-user or a user:
          </StyledText>
          <Flex flexDirection="column">
            <StyledCase>
              <Flex width="10px" margin={"22px 10px"}>
                <Circle></Circle>
              </Flex>
              <StyledFeatures>
                Categories of Non-user Personal Information Collected: real
                name, alias, phone number, email, approximate location
                (city/metro area, not your actual address), job title, skills,
                education, work history, professional social network profile
                picture, professional social network link, and inferences drawn
                from the aforementioned information.
              </StyledFeatures>
            </StyledCase>

            <StyledCase>
              <Flex width="10px" margin={"22px 10px"}>
                <Circle></Circle>
              </Flex>
              <StyledFeatures>
                Categories of User Personal Information Collected: real name,
                alias, address, phone number, mobile phone number, email,
                payment information, company name, computer/web browser/IP
                address information from cookies, web beacons and other
                identifiers, and other specific information you choose to
                provide us with or that you choose to include in your account.
              </StyledFeatures>
            </StyledCase>
          </Flex>
        </StyledTextBox>

        <StyledTextBox>
          <StyledSubtitleSmall>
            5. Personal Information We Sold for the Preceding 12 Months
          </StyledSubtitleSmall>
          <StyledText>
            Through TeamSourced’s paid Services, we share non-user information
            with our customers so that they may reach out to you for career
            opportunities that you may have interest in, and which may better
            fit to your professional background.
            <br />
            <br />
            If you are a user, your information provided in your user account is
            confidential to TeamSourced, and we neither sell your Personal
            Information nor share your Personal Information with others. Besides
            you, however, your Personal Information may be shared with your
            company’s Admin and other users who have the authority to view your
            Personal Information.
          </StyledText>
        </StyledTextBox>

        <StyledTextBox>
          <StyledSubtitleSmall>
            6. Personal Information We Disclosed for Business Purposes for the
            Preceding 12 Months
          </StyledSubtitleSmall>
          <StyledText>
            As a Service Provider to our customers, TeamSourced may receive your
            Personal Information from a customer who requires TeamSourced to
            process that information. In that case, we may disclose your
            Personal Information with that customer only for that customer’s
            business purpose.
            <br />
            <br />
            If you are a user of TeamSourced’s Site or Services, TeamSourced
            does not disclose your Personal Information to others in order to
            facilitate TeamSourced’s Business Purposes. Your Personal
            Information will only be used internally within TeamSourced in order
            to provide services to you.
          </StyledText>
        </StyledTextBox>

        <StyledTextBox>
          <StyledSubtitleSmall>
            7. Categories of Third Parties We Shared Personal Information With
          </StyledSubtitleSmall>
          <StyledText>
            In order to provide Services to our customers and for TeamSourced’s
            Commercial Purposes, TeamSourced may share your Personal Information
            with our customers. Our customers are: employers and recruiters.
          </StyledText>
        </StyledTextBox>

        <StyledTextBox>
          <StyledSubtitleSmall>
            8. California Consumers’ Rights under CCPA
          </StyledSubtitleSmall>
          <StyledText>
            CCPA provides California consumers’ rights to control how their
            Personal Information is collected and shared by businesses.
            “California consumer” means a natural person who is a California
            resident as defined in 18 CCR §17014.
            <br />
            <br />
            As a California consumer, you have the following rights regarding
            how TeamSourced processes your Personal Information:
          </StyledText>
          <Flex flexDirection="column">
            <StyledCase>
              <Flex width="10px" margin={"22px 10px"}>
                <Circle></Circle>
              </Flex>
              <StyledFeatures>
                Right to Notice: Provides you the right to request TeamSourced
                disclose the categories of Personal Information to be collected
                and the purposes for which the categories of Personal
                Information will be used.
              </StyledFeatures>
            </StyledCase>

            <StyledCase>
              <Flex width="10px" margin={"22px 10px"}>
                <Circle></Circle>
              </Flex>
              <StyledFeatures>
                Right to Access: Provides you the right to access your Personal
                Information free of charge, not more than twice within a year,
                upon TeamSourced’s verification of your identity. You can
                request TeamSourced to disclose categories of Personal
                Information collected, sold or disclosed; categories of sources
                of where the information was collected; Business or Commercial
                Purposes of collecting or selling the information; categories of
                third parties to whom the information was disclosed or sold; and
                the specific piece of information collected by TeamSourced.
              </StyledFeatures>
            </StyledCase>

            <StyledCase>
              <Flex width="10px" margin={"22px 10px"}>
                <Circle></Circle>
              </Flex>
              <StyledFeatures>
                Right to Delete Information: Provides you the right to request
                TeamSourced delete your Personal Information that TeamSourced
                collected, upon a verifiable request, unless such Personal
                information was collected for the purpose of completing a
                transaction involving you; detecting security-related incidents
                or illegal activities; debugging or fixing errors; protecting
                other consumers’ rights or other rights protected by law;
                preventing criminal offenses; engaging in scientific, historical
                or statistical reach in the public interest; internal uses that
                are reasonably aligned with your expectation; or compliance with
                a legal obligation.
              </StyledFeatures>
            </StyledCase>

            <StyledCase>
              <Flex width="10px" margin={"22px 10px"}>
                <Circle></Circle>
              </Flex>
              <StyledFeatures>
                Right to Equal Services and Prices: Provides you the right not
                to be discriminated against for exercising your rights under
                CCPA. Examples of discrimination include: denying goods or
                services to you, charging you different prices or rates for
                goods or services, providing you with a different level or
                quality of goods or services, or suggesting that you will
                receive a different price or rate for goods or services or a
                different level or quality of goods or services.
              </StyledFeatures>
            </StyledCase>

            <StyledCase>
              <Flex width="10px" margin={"22px 10px"}>
                <Circle></Circle>
              </Flex>
              <StyledFeatures>
                Right to Opt-out: Provides you the right to request to opt-out
                and delete your personal information collected by TeamSourced.
              </StyledFeatures>
            </StyledCase>
          </Flex>
        </StyledTextBox>

        <StyledTextBox>
          <StyledSubtitleSmall>
            9. Response Timing and Format
          </StyledSubtitleSmall>
          <StyledText>
            We endeavor to respond to a verifiable consumer request within 45
            days of our receipt. If we have the necessity to request for
            additional information from you to verify your identity, we will use
            that information solely for purposes of verification. If we require
            more time (up to 90 days), we will inform you of the reason and
            extension period in writing. If you have an account with us, we will
            deliver our written response to your email address linked to your
            account. If you do not have an account with us, we will deliver our
            written response by email in our record or an alternative way at
            your option. Any disclosures we provide will only cover the 12-month
            period preceding the verifiable consumer request’s receipt. The
            response we provide will also explain the reasons we cannot comply
            with a request, if applicable. For data portability requests, we
            will select a format to provide your Personal Information that is
            readily useable and should allow you to transmit the information
            from one entity to another entity without hindrance. Notwithstanding
            the foregoing, we have no obligation to respond to you if your
            Personal Information was collected for a single, one-time
            transaction if such information is not sold or retained by
            TeamSourced.
            <br />
            <br />
            We do not charge a fee to process or respond to your verifiable
            consumer request unless it is excessive, repetitive, or manifestly
            unfounded. If we determine that the request warrants a fee, we will
            tell you why we made that decision and provide you with a cost
            estimate before completing your request. We have no obligation to
            provide you with responses for more than two times in a 12-month
            period.
          </StyledText>
        </StyledTextBox>

        <StyledTextBox>
          <StyledSubtitleSmall>
            10. Designated Methods for Asserting California Consumers’ Rights
            under CCPA
          </StyledSubtitleSmall>
          <StyledText>
            We respect your privacy. If you wish to assert your CCPA rights,
            please use the following contact methods to reach out to us:
            <br />
            <br />
            Consumer Support Email:{" "}
            <a href="mailto:support@sourcedllc.com">support@sourcedllc.com</a>
            <br />
            <br />
            Security Report/Complaint:{" "}
            <a href="mailto:security@sourcedllc.com">security@sourcedllc.com</a>
            <br />
            <br />
            Mailing Address: Attn: Legal Department Sourced, LLC 2401 Tremont Ct
            Brentwood, CA 94513
            <br />
            <br />
            Website:{" "}
            <a href="https://blog.teamsourced.com/" target="_blank" rel="noreferrer">
              www.sourcedllc.com
            </a>
          </StyledText>
        </StyledTextBox>

        <StyledTextBox>
          <StyledSubtitleSmall>11. Opt-out</StyledSubtitleSmall>
          <StyledText>
            We understand that not everyone is willing to learn about
            opportunities out there for one’s professional development. We
            respect your preference and choice.
            <br />
            <br />
            If you are a user of TeamSourced’s Sites or Services, you can
            review, correct, update, delete, or change most of your Personal
            Information used to establish your account by using your account
            settings or opt-out of receiving certain e-mails and other
            communications from us by e-mailing us at support@sourcedllc.com.
            You are able to opt-out of receiving marketing e-mails from us,
            however, you cannot opt-out of receiving all e-mails from us, such
            as e-mails about the status of your account with us.
            <br />
            <br />
            If you are not a user of TeamSourced’s Sites or Services, you can
            opt-out by providing your name and email address via this link, and
            we will remove your information from our system, or follow-up with
            you if we need further information in order to honor your request.
            Please note, we may need to retain certain Personal Information
            about you in order to ensure that we can verify and continue to
            honor your request to opt-out. We will maintain the minimum amount
            of information necessary for this purpose, and will not share this
            information with our customers, employers, recruiters or other users
            of our Sites and Services.
          </StyledText>
        </StyledTextBox>

        <StyledTextBox>
          <StyledSubtitleSmall>12. Children’s Privacy</StyledSubtitleSmall>
          <StyledText>
            TeamSourced Site and Services are intended for users who are 16
            years old or older. We do not knowingly collect Personal Information
            from children under the age of 16. If we become aware that we have
            inadvertently received Personal Information from a child under the
            age of 16, we will delete such information from our records.
          </StyledText>
        </StyledTextBox>

        <StyledTextBox>
          <StyledSubtitleSmall>
            13. Processing in the United States
          </StyledSubtitleSmall>
          <StyledText>
            Please be aware that your Personal Information and communications
            are processed and maintained on our servers or databases in the
            United States. By visiting and using the Site or Services, you are
            agreeing to the collection, use, transfer, and disclosure of your
            Personal Data and communications will be governed by CCPA and other
            applicable laws in the United States.
          </StyledText>
        </StyledTextBox>

        <StyledTextBox>
          <StyledSubtitleSmall>14. Security</StyledSubtitleSmall>
          <StyledText>
            We maintain physical, electronic, and procedural safeguards to
            protect the confidentiality and security of information with
            encryption in transit and at rest. However, no data transmission
            over the Internet or other network can be guaranteed to be 100%
            secure. As a result, while we strive to protect information
            transmitted on or through the Site or Services, we cannot and do not
            guarantee the security of any information you transmit on or through
            the Site or Services, and you do so at your own risk.
          </StyledText>
        </StyledTextBox>

        <StyledTextBox>
          <StyledSubtitleSmall>15. Privacy Notice Changes</StyledSubtitleSmall>
          <StyledText>
            We may change this Notice from time to time without giving you
            notice. In addition, according to CCPA, we are required to update
            this Notice annually. If we decide to change this Notice, we will
            inform you by posting the revised Notice on the Site. Those changes
            will go into effect on the “Effective Date” shown in the revised
            Notice. By continuing to use the Site or Services, you are
            consenting to the revised Notice.
            <br />
            <br />
            <br />
            PLEASE PRINT A COPY OF THIS NOTICE FOR YOU RECORDS AND PLEASE CHECK
            THE SITE FREQUENTLY FOR ANY CHANGES.
            <br />

          </StyledText>
        </StyledTextBox>

        <StyledTextBox>
          <StyledSubtitleSmall>16. Google API</StyledSubtitleSmall>
          <StyledText>
            TeamSourced use and transfer to any other app of information received
            from Google APIs will adhere to the <a
              href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
              target="_blank"
              rel="noreferrer"
            >
              Google API Services User Data Policy
            </a>, including the Limited Use requirements.
          </StyledText>
        </StyledTextBox>

      </StyledContainer>

      <StyledContainerWhite>
        <StyledWhiteArea>
          <StyledfeatureArea marginBottom>
            <StyledTitleSmall>Become part of</StyledTitleSmall>
            <StyledTitleSmall bold>the revolution</StyledTitleSmall>
            <Button
              variant="primary"
              content="Join Now!"
              size="big"
              onClick={() => handleJoinNow()}
            />
          </StyledfeatureArea>

          <StyledfeatureArea>
            <StyledfeatureBlock>
              <Flex width="20px" margin={"0px 10px"}>
                <Valid variant="red" />
              </Flex>
              <StyledFeatures>10X your productivity level</StyledFeatures>
            </StyledfeatureBlock>

            <StyledfeatureBlock>
              <Flex width="20px" margin={"0px 10px"}>
                <Valid variant="red" />
              </Flex>
              <StyledFeatures>Email Sequences</StyledFeatures>
            </StyledfeatureBlock>

            <StyledfeatureBlock>
              <Flex width="20px" margin={"0px 10px"}>
                <Valid variant="red" />
              </Flex>
              <StyledFeatures>Retain everyone you source</StyledFeatures>
            </StyledfeatureBlock>

            <StyledfeatureBlock>
              <Flex width="20px" margin={"0px 10px"}>
                <Valid variant="red" />
              </Flex>
              <StyledFeatures>Analytics</StyledFeatures>
            </StyledfeatureBlock>
          </StyledfeatureArea>

          <StyledfeatureArea>
            <StyledfeatureBlock>
              <Flex width="20px" margin={"0px 10px"}>
                <Valid variant="red" />
              </Flex>
              <StyledFeatures>Multiple Workspaces One Database</StyledFeatures>
            </StyledfeatureBlock>

            <StyledfeatureBlock>
              <Flex width="20px" margin={"0px 10px"}>
                <Valid variant="red" />
              </Flex>
              <StyledFeatures>Chrome Extension</StyledFeatures>
            </StyledfeatureBlock>

            <Flex
              alignItems={"center"}
              justifyContent={"flex-start"}
              width={"100%"}
            >
              <Flex width="20px" margin={"0px 10px"}>
                <Valid variant="red" />
              </Flex>
              <StyledFeatures>
                Lifetime Guaranteed free CRM access
              </StyledFeatures>
            </Flex>
            <Flex
              alignItems={"center"}
              justifyContent={"flex-start"}
              width={"100%"}
            >
              <Flex width="20px" margin={"0px 10px"}>
                <Valid variant="red" />
              </Flex>
              <StyledFeatures>Greenhouse Compatible</StyledFeatures>
            </Flex>
          </StyledfeatureArea>
        </StyledWhiteArea>
      </StyledContainerWhite>

      <CardDividerTop></CardDividerTop>
      <StyledFooter>
        <StyledFooterRow>
          <StyledFooterArea>
            <StyledFooterLink
              href="https://boards.greenhouse.io/teamsourced"
              target="_blank"
              rel="noreferrer"
            >
              careers
            </StyledFooterLink>

            <StyledFooterLink
              href="https://blog.teamsourced.com/"
              target="_blank"
              rel="noreferrer"
            >
              blog
            </StyledFooterLink>

            <StyledFooterLink
              href="/privacy_policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </StyledFooterLink>

            <StyledFooterLink href="mailto:security@sourcedllc.com" target="_blank">
              Security Contact
            </StyledFooterLink>

          </StyledFooterArea>

          <StyledFooterArea>
            <StyledFooterIconLink
              href="mailto:info@sourcedllc.com"
              title="Write Us"
            >
              <Mail variant="white" landing />
            </StyledFooterIconLink>

            <StyledFooterIconLink
              href="https://www.linkedin.com/company/sourced-llc"
              title="Linkedin"
              target="_blank"
              rel="noreferrer"
            >
              <Linkedin variant="white" landing />
            </StyledFooterIconLink>

            <StyledFooterIconLink
              href="https://twitter.com/SourcedSF"
              title="Twitter"
              target="_blank"
              rel="noreferrer"
            >
              <Twitter variant="white" landing />
            </StyledFooterIconLink>

            <StyledFooterIconLink
              href="https://www.facebook.com/sourcedSF/"
              title="Facebook"
              target="_blank"
              rel="noreferrer"
            >
              <Facebook variant="white" landing />
            </StyledFooterIconLink>
          </StyledFooterArea>
        </StyledFooterRow>

        <StyledFooterDivider />

      </StyledFooter>
    </Flex>
  );
};

export default PrivacyPolicyPage;
