import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as R from "ramda";
import theme from "@theme";

//components
import Flex from "@components/ui/flex";
import Spinner from "@components/ui/spinner";
import { PayloadList, PayloadIntro } from "@components/domain/payloads";
import ModalRemove from "@ui/modal-remove";

//Containers
import EditPersonModalContainer from "../people/EditPersonModalContainer";

//Actions
import {
  sequencesGet,
  projectPeopleUpdate,
  projectPeoplePayloadUpdate,
  projectPeoplePayloadSendEvent,
  projectPeopleResponseUpdate,
  projectAnalyticsGet,
  personGet,
  messagesGet,
  projectPeopleDelete,
} from "@actions";

import {
  getCurrentProject,
  getWorkspaceCurrent,
  getProjectPeople,
  getPayloadsCurrentPage,
  getPayloadsTotalPages,
  getPayloadLoading,
  getSequencesData,
  getSequencesIncluded,
  getLimit,
  getPayloadsIncluded,
  getProjectAnalytics,
  getWorkflowsCurrentIncluded,
  getMessagesData,
  getPeoplePersonData,
} from "@reducer";

import {
  usePayloadsWithPagination,
  usePayloadsStatusEvents,
  useWorkflow,
} from "@hooks";

const PayloadContainer = ({ viewport, viewHeight }) => {
  const dispatch = useDispatch();

  const project = useSelector(getCurrentProject);
  const workspaceId = useSelector(getWorkspaceCurrent);
  const projectPeople = useSelector(getProjectPeople);
  const limit = useSelector(getLimit);
  const projectAnalytics = useSelector(getProjectAnalytics);
  const workflowStagesCurrent = useSelector(getWorkflowsCurrentIncluded);
  const messages = useSelector(getMessagesData);
  const currentPage = useSelector(getPayloadsCurrentPage);
  const totalPages = useSelector(getPayloadsTotalPages);
  const steps = useSelector(getSequencesIncluded);
  const loading = useSelector(getPayloadLoading);

  const [isEditActive, setIsEditActive] = useState(false);
  const [activePerson, setActivePerson] = useState({});
  const [selectedPayloadId, setSelectedPayloadId] = useState("");
  const [selectedPayloadData, setSelectedPayloadData] = useState("");
  const [selectedSteps, setSelectedSteps] = useState({});
  const [searchBy, setSearchBy] = useState("");
  const [byStage, setByStage] = useState(undefined);
  const [sequenceWithReview, setSequenceWithReview] = useState(false);
  const [byStatus, setByStatus] = useState("pending");
  const [activedSpinner, setActivedSpinner] = useState(true);
  const [currentMessages, setCurrentMessages] = useState([]);
  const [filterOptions, setFilterOptions] = useState([
    "pending",
    "queue",
    "paused",
    "failed",
    "finished",
  ]);

  const [
    payloads,
    payloadIncluded,
    decoratedPayloads,
    handleReloadPayloads,
    firstPagePayloadPagination,
    lastPagePayloadPagination,
    nextPagePayloadPagination,
    prevPagePayloadPagination,
  ] = usePayloadsWithPagination(
    byStage,
    byStatus,
    searchBy,
    workspaceId,
    project.id,
    limit
  );

  const [
    getStatusByPayload,
    byStatusToParams,
    payloadValuesEmpty,
    isPayloadValuesComplete,
  ] = usePayloadsStatusEvents();

  const [workflowCurrentData, stagesOptions, hasSequence] = useWorkflow();

  const credentialsLink = `/workspaces/${workspaceId}/integrations`;
  const sequencesLink = `/workspaces/${workspaceId}/sequences`;
  const [itemToRemove, setItemToRemove] = useState();
  const [isRemoveActive, setIsRemoveActive] = useState(false);

  useEffect(() => {
    if (searchBy === "") {
      setSelectedPayloadId(null);
      dispatch(sequencesGet({ workspace_id: workspaceId, limit: 9999 }));
    }
  }, [searchBy, limit]);

  useEffect(() => {
    dispatch(sequencesGet({ workspace_id: workspaceId, limit: 9999 }));
  }, [workspaceId, project, projectPeople]);

  useEffect(() => {
    if (!byStage && stagesOptions.length > 0) {
      const stage = R.find(R.propEq("variant", "ready"), stagesOptions);
      setByStage(stage?.label);
      setSequenceWithReview(stage?.has_sequence_review);
      if (!stage?.has_sequence_review && byStatus === "ready")
        setByStatus("pending");
    }
  }, [stagesOptions]);

  useEffect(() => {
    if (sequenceWithReview) {
      setFilterOptions(["pending", "review", "queue", "paused", "failed", "finished"]);
    }
  }, [sequenceWithReview]);

  const getStepsBySequenceId = (sequence_id) => {
    return steps.filter((s) => s["attributes"]["sequence_id"] === sequence_id);
  };

  // Handlers UI
  const selectorHandler = (payload) => {
    setSelectedSteps(
      getStepsBySequenceId(payload["attributes"]["sequence_id"])
    );
    setSelectedPayloadId(payload.id);
    const currentPerson = getPersonByPayload(payload);
    setActivePerson(currentPerson);
  };

  const closeEditModalHandler = () => {
    setIsEditActive(false);
  };

  const editPersonHandler = (person) => {
    setActivePerson(person);
    setIsEditActive(!isEditActive);
  };

  const handleSubmitPersonEdit = (attrs) => {
    dispatch(
      projectPeopleUpdate({
        ...attrs,
        workspace_id: project.attributes.workspace_id,
        project_id: project.id,
        id: activePerson.attributes.id,
      })
    );
  };

  const getPersonByPayload = (payload) => {
    if (payload) {
      const personByPayload = (item) => item.type === "person" && item.id === payload["attributes"]["person_id"];
      const person = R.find(personByPayload, payloadIncluded);
      person && (person["project_person_id"] = payload.relationships.project_person.data.id);
      return person;
    }
  };

  const getSequenceProgressByPayload = (payload) => {
    const personId = payload.relationships.project_person.data.id;
    return payloadIncluded.filter(
      (item) =>
        item.type === "sequence_processor" &&
        item.relationships.project_person.data.id === personId
    )[0];
  };

  //Handlers Logic
  const updatePayloadHandler = (payloadId, payloadValues) => {
    setActivedSpinner(false);
    dispatch(
      projectPeoplePayloadUpdate({
        workspace_id: workspaceId,
        project_id: project.id,
        payload_id: payloadId,
        payload_values: { data: payloadValues },
        by_stages: byStage,
        limit: limit,
        by_status: byStatusToParams(byStatus),
        currentPage: currentPage,
        search_by: searchBy,
      })
    );
  };

  const sendEventPayloadHandler = (payload, eventName) => {
    if (eventName !== "none") {
      const payloadId = payload.id;
      dispatch(
        projectPeoplePayloadSendEvent({
          workspace_id: workspaceId,
          project_id: project.id,
          payload_id: payloadId,
          event_name: eventName,
          by_stages: byStage,
          limit: limit,
          by_status: byStatusToParams(byStatus),
        })
      );
      setSelectedPayloadId(null);
    }
  };

  const handleSelectedMessageStage = (selectedMessageStage) => {
    const stageOption = stagesOptions.find((stage) => {
      if (stage.label === selectedMessageStage.label) return { ...stage };
    });
    setByStage(stageOption.label);
    setSelectedPayloadId(null);
    setSelectedSteps(null);
    setSequenceWithReview(stageOption.has_sequence_review);
    if (!stageOption.has_sequence_review && byStatus === "ready") {
      setByStatus("pending");
    }
  };

  const handleUpdateResponse = ({ response, projectPeopleId }) => {
    dispatch(
      projectPeopleResponseUpdate({
        project_id: project.id,
        project_people_id: projectPeopleId,
        response: response,
      })
    );
  };

  const removeModalHandler = (removeItem) => {
    setItemToRemove({
      attributes: {
        ...removeItem.attributes,
        name: removeItem.attributes.first_name + " " + removeItem.attributes.last_name,
        project_person_id: removeItem.project_person_id,
      },
    });
    setIsRemoveActive(true);
  };

  const closeRemoveModalHandler = () => {
    setIsRemoveActive(false);
  };

  const rowMenuOptions = [
    { name: "Edit", callback: editPersonHandler },
    {
      name: "Remove",
      alert: removeModalHandler,
    },
  ];

  const removeProjectPersonHandler = (project_people) => {
    dispatch(
      projectPeopleDelete({
        id: project_people.attributes.project_person_id,
        project_id: project.id,
        search_by: searchBy,
        by_stages: byStage,
        limit: limit,
      })
    );
    setSelectedPayloadId(null);
    closeRemoveModalHandler();
  };

  const handleStatusFilter = (name, value) => {
    setByStatus(value);
    setSelectedPayloadId(null);
    setSelectedSteps(null);
  };

  const handleChangeSearchBy = (event) => {
    setSearchBy(event.target.value);
  };

  useEffect(() => {
    if (byStage) {
      const stageByName = (stage) => stage.attributes.name === byStage;
      const currentStage = R.filter(stageByName, workflowStagesCurrent);

      dispatch(
        projectAnalyticsGet({
          workspace_id: workspaceId,
          id: project.id,
          stage_id: currentStage[0].id,
        })
      );
    }
  }, [byStage]);

  const projectMessages = () => {
    let candidateMessages = messages.filter(
      (m) => m.attributes.project_id === project.id
    );
    setCurrentMessages(candidateMessages);
  };

  useEffect(() => {
    if (selectedPayloadId) {
      const selectedPayload = decoratedPayloads.find(
        (p) => p.id === selectedPayloadId
      );
      setSelectedPayloadData(selectedPayload);
    } else {
      setSelectedPayloadData(null);
    }
  }, [selectedPayloadId]);

  useEffect(() => {
    if (selectedPayloadData?.attributes?.person_id) {
      dispatch(personGet({ id: selectedPayloadData.attributes.person_id }));
      dispatch(
        messagesGet({ person_id: selectedPayloadData.attributes.person_id })
      );
    }
  }, [selectedPayloadData]);

  useEffect(() => {
    projectMessages();
  }, [messages]);

  useEffect(() => {
    setSelectedPayloadId(null);
  }, [currentPage]);

  useEffect(() => {
    setActivedSpinner(true);
  }, [decoratedPayloads]);

  return loading && activedSpinner ? (
    <Spinner />
  ) : hasSequence() ? (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width={viewport > 2000 ? "85%" : "95%"}
      backgroundColor={theme.colors.lighterGrey}
    >
      <EditPersonModalContainer
        person={activePerson}
        isEditActive={isEditActive}
        closeEditModalHandler={closeEditModalHandler}
        handleSubmitEdit={handleSubmitPersonEdit}
      />

      {isRemoveActive && (
        <ModalRemove
          removeItem={itemToRemove}
          title="Remove Person"
          handleRemoveClick={removeProjectPersonHandler}
          closeModal={closeRemoveModalHandler}
          modalType="remove"
        />
      )}

      <PayloadList
        projectPeople={projectPeople}
        viewport={viewport}
        viewHeight={viewHeight}
        payloads={decoratedPayloads} //payloadSelector && payloadEditor
        steps={selectedSteps} //payloadEditor
        selectedPayloadId={selectedPayloadId} //payloadSelector && payloadEditor
        selectorHandler={selectorHandler} //payloadSelector
        getPersonByPayload={getPersonByPayload} //payloadSelector
        getStatusByPayload={getStatusByPayload} //payloadSelector
        getSequenceProgressByPayload={getSequenceProgressByPayload} //payloadSelector
        updatePayloadHandler={updatePayloadHandler} //payloadEditor
        sendEventPayloadHandler={sendEventPayloadHandler} //payloadEditor
        rowMenuOptions={rowMenuOptions}
        activePerson={activePerson}
        firstPage={firstPagePayloadPagination}
        lastPage={lastPagePayloadPagination}
        prevPage={prevPagePayloadPagination}
        nextPage={nextPagePayloadPagination}
        totalPages={totalPages}
        currentPage={currentPage}
        handleChangeSearchBy={handleChangeSearchBy}
        handleEnterSearchBy={handleReloadPayloads}
        searchBy={searchBy}
        handleSelectedMessageStage={handleSelectedMessageStage} //StepsFilter
        stagesOptions={stagesOptions} //StepsFilter
        byStage={byStage}
        byStatus={byStatus} //RadioButton
        handleStatusFilter={handleStatusFilter} //RadioButton
        handleUpdateResponse={handleUpdateResponse} //payloadSelector
        filterOptions={filterOptions}
        projectAnalytics={projectAnalytics}
        project={project}
        selectedPayloadData={selectedPayloadData}
        currentMessages={currentMessages}
      />
    </Flex>
  ) : (
    <PayloadIntro
      credentialsLink={credentialsLink}
      sequencesLink={sequencesLink}
    />
  );
};

export default PayloadContainer;
