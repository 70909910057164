import React from "react";
import styled from "styled-components";
import theme from "../../../theme";

import High from "../../ui/icons/priorityHigh";
import Mid from "../../ui/icons/priorityMid";
import Low from "../../ui/icons/priorityLow";

const StyledText = styled.div`
  white-space: pre-line;
  margin: 5px;
  align-items: center;
  font-size: 0.9rem;
  display: flex;
  flex-direction: row;
  color: ${theme.colors.grey};
  font-weight: 200;

  ${({ pointer }) => pointer && "cursor: pointer;"}
  ${({ underlined }) => underlined && "text-decoration: underline;"}
`;

const StyledPriority = styled.div`
  height: 30px;
  width: 30px;
  margin-left: 10px;
`;

const Priority = ({ priority, ...props }) => {
  return (
    <StyledText {...props}>
      Priority
      <StyledPriority>
        {priority === "High" ? <High variant="red" /> : null}
        {priority === "Medium" ? <Mid variant="orange" /> : null}
        {priority === "Low" ? <Low variant="blue" /> : null}
      </StyledPriority>
    </StyledText>
  );
};

export default Priority;
