import React from "react";
import theme from "@theme";
import styled from "styled-components";

import Google from "./googleBtn";

const StyledButton = styled.div`
  height: 40px;
  border: 1px solid lightgrey;
  display: flex;
  align-items: center;
  padding-right: 14px;
  border-radius: 5px;
  margin: 0px 6px;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 2px 4px #e3e3e3;
  }
`;

const StyledText = styled.div`
  font-family: ${theme.fonts[2]};
  font-size: 14px;
  padding-left: 10px;
`;

const SignInGoogleButton = ({ onClick }) => {
  return (
    <StyledButton onClick={onClick}>
      <Google />
      <StyledText>Google</StyledText>
    </StyledButton>
  );
};

export default SignInGoogleButton;
