import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
  ${(prop) =>
    prop.landing
      ? `&:hover {
        fill: ${theme.colors.lightBlue}; cursor: pointer;}`
      : ""}
    ${(prop) =>
      prop.linkable
        ? `&:hover {
      filter: drop-shadow(-1px 1px 1px #4BAAC8); cursor: pointer;}`
        : ""}

    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black);" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const Message = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <g>
        <path
          d="M20.5,21.7h-16c-2.1,0-3.8-1.7-3.8-3.8V7c0-2.1,1.7-3.8,3.8-3.8h16c2.1,0,3.8,1.7,3.8,3.8v11
		C24.3,20.1,22.6,21.7,20.5,21.7z M4.5,4.8c-1.2,0-2.3,1-2.3,2.3v11c0,1.2,1,2.3,2.3,2.3h16c1.2,0,2.3-1,2.3-2.3V7
		c0-1.2-1-2.3-2.3-2.3H4.5z"
        />
        <path
          d="M12.5,13.3c-0.1,0-0.3,0-0.4-0.1L4.8,8.3C4.4,8,4.3,7.6,4.5,7.2c0.2-0.3,0.7-0.4,1-0.2l6.9,4.6L19.4,7
		c0.3-0.2,0.8-0.1,1,0.2c0.2,0.3,0.1,0.8-0.2,1l-7.3,4.9C12.8,13.2,12.6,13.3,12.5,13.3z"
        />
      </g>
    </StyledSvg>
  );
};

export default Message;
