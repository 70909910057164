import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//Actions
import { projectPeoplePayloadsGet } from "../actions";

//Reducers
import {
  getPayloadsData,
  getPayloadsLinks,
  getPayloadsIncluded,
  getSequencesIncluded,
} from "../reducer";

import { usePayloadsStatusEvents } from ".";

const usePayloadsWithPagination = (
  byStage,
  byStatus,
  searchBy,
  workspaceId,
  projectId,
  limit
) => {
  const dispatch = useDispatch();
  const steps = useSelector(getSequencesIncluded);
  const payloads = useSelector(getPayloadsData);
  const payloadsLinks = useSelector(getPayloadsLinks);
  const payloadIncluded = useSelector(getPayloadsIncluded);
  const [decoratedPayloads, setDecoratedPayloads] = useState([]);
  const [
    getStatusByPayload,
    byStatusToParams,
    payloadValuesEmpty,
    isPayloadValuesComplete,
  ] = usePayloadsStatusEvents();

  useEffect(() => {
    if (steps.length > 0 && !!byStage) {
      handleReloadPayloads();
    }
  }, [steps, byStage, byStatus, limit]);

  useEffect(() => {
    decoratePayloads();
  }, [payloads]);

  const handleReloadPayloads = () => {
    dispatch(
      projectPeoplePayloadsGet({
        workspace_id: workspaceId,
        project_id: projectId,
        search_by: searchBy,
        by_stages: byStage,
        limit: limit,
        by_status: byStatusToParams(byStatus),
      })
    );
  };

  //pagination
  const getPagePayloadByUrl = (url) => {
    dispatch(
      projectPeoplePayloadsGet({
        url: url,
        by_stages: byStage,
        by_status: byStatusToParams(byStatus),
        limit: limit,
      })
    );
  };

  const firstPagePayloadPagination = () => {
    getPagePayloadByUrl(payloadsLinks.first);
  };

  const lastPagePayloadPagination = () => {
    getPagePayloadByUrl(payloadsLinks.last);
  };

  const nextPagePayloadPagination = () => {
    getPagePayloadByUrl(payloadsLinks.next);
  };

  const prevPagePayloadPagination = () => {
    getPagePayloadByUrl(payloadsLinks.prev);
  };

  //Decoration
  const decoratePayloads = () => {
    const sortedPayloads = payloads.sort((a, b) => {
      let positionA = getStatusByPayload(a).position || 1000;
      let positionB = getStatusByPayload(b).position || 1000;
      return positionA < positionB ? -1 : positionA > positionB ? 1 : 0;
    });

    const sortedDecoratedPayloads = sortedPayloads.map((payload) => {
      const sortedDecoratedPayload = {
        ...payload,
        extra: {
          emptyCount: payloadValuesEmpty(payload),
          status: getStatusByPayload(payload),
        },
      };
      return sortedDecoratedPayload;
    });

    setDecoratedPayloads(sortedDecoratedPayloads);
  };

  return [
    payloads,
    payloadIncluded,
    decoratedPayloads,
    handleReloadPayloads,
    firstPagePayloadPagination,
    lastPagePayloadPagination,
    nextPagePayloadPagination,
    prevPagePayloadPagination,
  ];
};

export default usePayloadsWithPagination;
