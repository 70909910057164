import React, { useState, Fragment, useRef, useEffect } from "react";
import styled from "styled-components";
import theme from "../../../theme";

// Components
import Step from "../step/index";
import Box from "../box";
import Add from "../icons/add";
import Checkbox from "@ui/checkbox/index";

import LeftArrow from "../icons/arrowLeftSimple";
import RightArrow from "../icons/arrowRightSimple";

const StyledContainer = styled(Box)`
  position: relative;
  display: flex;
  align-content: center;
  justify-content: flex-start;

  ${(props) =>
    props.vertical ? "flex-direction: column;" : "flex-direction: row;"}
  ${({ onClick }) => onClick && `cursor: pointer;`}
`;

const StyledDivider = styled.div`
  display: flex;
  align-self: center;
  margin: 8px;

  ${(props) =>
    props.vertical
      ? `border-left: 2px solid ${theme.colors.blue}; width: 0px; height: 20px;`
      : `border-bottom: 2px solid ${theme.colors.blue}; width: 20px; height: 0px;`}
`;

const StyledCircle = styled.div`
  display: flex;
  align-self: center;
  border-radius: 50%;
  height: 17px;
  width: 17px;
  padding: 3px;
  background-color: ${theme.colors.blue};

  &:hover {
    box-shadow: -2px 2px 2px lightgray;
    cursor: pointer;
  }
`;

const StyledSelectArea = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 30px;
`;

const StyledStageArea = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 500px;
  max-width: 700px;
`;

const ArrowContainer = styled.div`
  display: flex;
  height: 15px;
  width: 15px;
  visibility: ${(props) => (props.showButton ? "visible" : "hidden")};

  &:hover {
    cursor: pointer;
  }
`;

const CardStageContainer = styled.div`
  display: flex;
  width: 98%;
  padding: 0px 10px;
  overflow-x: auto;
  scrollbar-color: transparent transparent; /* thumb and track color */
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    width: 0;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
    border: none;
  }
`;

const StageFlex = styled.div`
  display: flex;
`;

const StyledNumber = styled.div`
  display: flex;
  padding: 0;
  font-weight: 400;
  font-size: 0.9rem;
  color: ${theme.colors.darkBlue};
`;

const StyledCheckbox = styled.div`
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  position: relative;
  top: 5px;
`;

const StepsSelector = ({
  flat,
  content,
  onClick,
  onSelect,
  vertical,
  addBtn,
  title,
  hasScroll,
  id,
  checkAllState,
  handleCheckAll,
  ...props
}) => {
  const scrollContainer = useRef(null);
  const stagesContainer = useRef(null);
  const [activeScroll, setActiveScroll] = useState(false);

  const onCheckScroll = () => {
    if (
      stagesContainer.current.offsetWidth > scrollContainer.current.offsetWidth
    ) {
      setActiveScroll(true);
    } else {
      setActiveScroll(false);
    }
  };

  useEffect(() => {
    if (hasScroll) {
      onCheckScroll();
    }
  }, [stagesContainer.current?.offsetWidth]);

  const onScrollRight = () => {
    const container = document.getElementById(`container${id}`);
    sideScroll(container, "right", 0, 6000, 300);
  };

  const onScrollLeft = () => {
    const container = document.getElementById(`container${id}`);
    sideScroll(container, "left", 0, 6000, 300);
  };

  function sideScroll(element, direction, speed, distance, step) {
    let scrollAmount = 0;
    const slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  }

  return (
    <StyledContainer onClick={onClick} vertical={vertical} {...props}>
      {
        <StyledSelectArea>
          <StyledCheckbox>
            <Checkbox
              key="checkAll"
              checked={checkAllState}
              top="-5px"
              margin="0px"
              padding="0px"
              shape="square"
              title="Select All"
              onChange={handleCheckAll}
            />
          </StyledCheckbox>
        </StyledSelectArea>
      }
      {hasScroll ? (
        <StyledStageArea>
          <ArrowContainer showButton={activeScroll} onClick={onScrollLeft}>
            <LeftArrow hoverBlue variant="darkBlue" />
          </ArrowContainer>
          <CardStageContainer id={`container${id}`} ref={scrollContainer}>
            <StageFlex ref={stagesContainer}>
              {content.map((step, index) => (
                <Fragment key={index}>
                  <Step
                    size="small"
                    showLabel
                    flat={flat}
                    title={title}
                    key={index}
                    content={index + 1}
                    label={step.label}
                    onClick={() => onSelect(step)}
                    hasSequence={step.hasSequence}
                    {...step}
                  ></Step>
                  {index === content.length - 1 ? null : (
                    <StyledDivider vertical={vertical}></StyledDivider>
                  )}
                </Fragment>
              ))}
            </StageFlex>
          </CardStageContainer>
          <ArrowContainer showButton={activeScroll} onClick={onScrollRight}>
            <RightArrow hoverBlue variant="darkBlue" />
          </ArrowContainer>
        </StyledStageArea>
      ) : (
        <>
          {content.map((step, index) => (
            <Fragment key={index}>
              <Step
                size="small"
                showLabel
                flat={flat}
                title={title}
                variant={step.variant}
                key={index}
                content={index + 1}
                onClick={() => onSelect(step)}
                hasSequence={step.hasSequence}
                {...step}
              ></Step>
              {index === content.length - 1 ? null : (
                <StyledDivider vertical={vertical}></StyledDivider>
              )}
            </Fragment>
          ))}
        </>
      )}
      {addBtn ? (
        <>
          <StyledDivider vertical={vertical} />
          <StyledCircle>
            <Add variant="white"></Add>
          </StyledCircle>
        </>
      ) : null}
    </StyledContainer>
  );
};

export default StepsSelector;
