import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black)" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const Location = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <g>
        <path
          d="M21.3,23H13c0,0,0,0,0,0c0.3-0.4,7.7-9.9,7.7-14.2c0-4.5-3.7-8.3-8.3-8.3C8,0.5,4.2,4.2,4.2,8.8
		c0,4.4,7.3,13.8,7.7,14.2c0,0,0,0,0,0H3.7C3.3,23,3,23.3,3,23.7s0.3,0.8,0.8,0.8h17.5c0.4,0,0.8-0.3,0.8-0.8S21.7,23,21.3,23z
		 M5.7,8.8C5.7,5,8.8,2,12.5,2s6.8,3,6.8,6.8c0,2.9-4.4,9.4-6.8,12.5C10.2,18.2,5.7,11.7,5.7,8.8z"
        />
        <path
          d="M17,8.8c0-2.5-2-4.5-4.5-4.5C10,4.3,8,6.3,8,8.8s2,4.5,4.5,4.5C15,13.3,17,11.2,17,8.8z M9.5,8.8
		c0-1.7,1.3-3,3-3c1.7,0,3,1.3,3,3s-1.3,3-3,3C10.8,11.8,9.5,10.4,9.5,8.8z"
        />
      </g>
    </StyledSvg>
  );
};

export default Location;
