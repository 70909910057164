import { createActions } from "redux-actions";

// sign in actions
export const PROJECT_GET = "PROJECT_GET";
export const PROJECT_GET_SUCCESS = "PROJECT_GET_SUCCESS";
export const PROJECT_GET_FAILURE = "PROJECT_GET_FAILURE";

export const PROJECTS_GET = "PROJECTS_GET";
export const PROJECTS_GET_SUCCESS = "PROJECTS_GET_SUCCESS";
export const PROJECTS_GET_FAILURE = "PROJECTS_GET_FAILURE";

export const PROJECT_CREATE = "PROJECT_CREATE";
export const PROJECT_CREATE_SUCCESS = "PROJECT_CREATE_SUCCESS";
export const PROJECT_CREATE_FAILURE = "PROJECT_CREATE_FAILURE";

export const PROJECT_UPDATE = "PROJECT_UPDATE";
export const PROJECT_UPDATE_SUCCESS = "PROJECT_UPDATE_SUCCESS";
export const PROJECT_UPDATE_FAILURE = "PROJECT_UPDATE_FAILURE";

export const PROJECT_DELETE = "PROJECT_DELETE";
export const PROJECT_DELETE_SUCCESS = "PROJECT_DELETE_SUCCESS";
export const PROJECT_DELETE_FAILURE = "PROJECT_DELETE_FAILURE";

export const PROJECT_INVITE = "PROJECT_INVITE";
export const PROJECT_INVITE_SUCCESS = "PROJECT_INVITE_SUCCESS";
export const PROJECT_INVITE_FAILURE = "PROJECT_INVITE_FAILURE";

export const PEOPLE_TO_PROJECT_ASSIGN = "PEOPLE_TO_PROJECT_ASSIGN";
export const PEOPLE_TO_PROJECT_ASSIGN_SUCCESS =
  "PEOPLE_TO_PROJECT_ASSIGN_SUCCESS";
export const PEOPLE_TO_PROJECT_ASSIGN_FAILURE =
  "PEOPLE_TO_PROJECT_ASSIGN_FAILURE";

export const CURRENT_PROJECT_SET = "CURRENT_PROJECT_SET";
export const CURRENT_PROJECT_CLEAN = "CURRENT_PROJECT_CLEAN";

export const PROJECT_ANALYTICS_GET = "PROJECT_ANALYTICS_GET";
export const PROJECT_ANALYTICS_GET_SUCCESS = "PROJECT_ANALYTICS_GET_SUCCESS";

export const {
  projectGet,
  projectGetSuccess,
  projectGetFailure,
  projectsGet,
  projectsGetSuccess,
  projectsGetFailure,
  projectCreate,
  projectCreateSuccess,
  projectCreateFailure,
  projectUpdate,
  projectUpdateSuccess,
  projectUpdateFailure,
  projectDelete,
  projectDeleteSuccess,
  projectDeleteFailure,
  projectInvite,
  projectInviteSuccess,
  projectInviteFailure,
  peopleToProjectAssign,
  peopleToProjectAssignSuccess,
  peopleToProjectAssignFailure,
  currentProjectSet,
  currentProjectClean,
  projectAnalyticsGet,
  projectAnalyticsGetSuccess,
} = createActions(
  {},
  PROJECT_GET, //: project => ({ project }),
  PROJECT_GET_SUCCESS,
  PROJECT_GET_FAILURE,
  PROJECTS_GET,
  PROJECTS_GET_SUCCESS,
  PROJECTS_GET_FAILURE,
  PROJECT_CREATE, //: project => ({ project }),
  PROJECT_CREATE_SUCCESS,
  PROJECT_CREATE_FAILURE,
  PROJECT_UPDATE, //: project => ({ project }),
  PROJECT_UPDATE_SUCCESS,
  PROJECT_UPDATE_FAILURE,
  PROJECT_DELETE, // : id => ({ id }),
  PROJECT_DELETE_SUCCESS,
  PROJECT_DELETE_FAILURE,
  PROJECT_INVITE,
  PROJECT_INVITE_SUCCESS,
  PROJECT_INVITE_FAILURE,
  PEOPLE_TO_PROJECT_ASSIGN,
  PEOPLE_TO_PROJECT_ASSIGN_SUCCESS,
  PEOPLE_TO_PROJECT_ASSIGN_FAILURE,
  CURRENT_PROJECT_SET,
  CURRENT_PROJECT_CLEAN,
  PROJECT_ANALYTICS_GET,
  PROJECT_ANALYTICS_GET_SUCCESS,
);
