import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//Component
import Spinner from "../../components/ui/spinner";
import {
  WorkflowIntro,
  WorkflowProgress,
} from "../../components/domain/workflows";

//Actions
import { workflowAssign } from "../../actions";

//Reducers
import {
  getWorkflowsCurrentData,
  getWorkflowsLoading,
  getWorkflowsData,
  getWorkflowsCurrentIncluded,
} from "../../reducer";

const WorkflowProgressContainer = ({
  workflowId,
  workspace,
  project,
  viewport,
}) => {
  const dispatch = useDispatch();
  const workflowData = useSelector(getWorkflowsCurrentData);
  const loading = useSelector(getWorkflowsLoading);
  const workflows = useSelector(getWorkflowsData);
  const stages = useSelector(getWorkflowsCurrentIncluded);

  const [editActive, setIsEditActive] = useState(false);
  const [dirtyChanges, setDirtyChanges] = useState({});

  const handleDropdownSelect = (name, value) => {
    setDirtyChanges({
      ...dirtyChanges,
      [name]: value,
    });
  };

  const handleSubmitClick = () => {
    const workflowSelected = workflows.data.find(
      (item) => item.attributes.name === dirtyChanges.workflow
    );

    dispatch(
      workflowAssign({
        workspace_id: workspace,
        project_id: project.id,
        id: workflowSelected.id,
      })
    );
  };

  useEffect(() => {
    setDirtyChanges({});
  }, []);

  return loading ? (
    <Spinner />
  ) : workflowId ? (
    <WorkflowProgress
      viewport={viewport}
      editActive={editActive}
      setIsEditActive={setIsEditActive}
      workspace={workspace}
      workflow={workflowData}
      project={project}
      stages={stages}
    />
  ) : (
    <WorkflowIntro
      viewport={viewport}
      workspace={workspace}
      workflows={workflows.data}
      handleDropdownSelect={handleDropdownSelect}
      dirtyChanges={dirtyChanges}
      handleSubmitClick={handleSubmitClick}
    />
  );
};

export default WorkflowProgressContainer;
