import React, { useEffect, useState } from "react";

import Box from "@ui/box";
import Dropdown from "@ui/dropdown";
import DropdownWithId from "@ui/dropdown-with-id";
import Flex from "@ui/flex";
import Input from "@ui/input";
import Toggle from "@ui/toggle-switch";
import useCountries from "@hooks/useCountries";
import { timezonesList } from "@utils/timezones";

const SequenceForm = ({
  sequence,
  dirtyChanges,
  register,
  errors,
  setValue,
  setError,
  clearErrors,
  handleInputChange,
  handleSelectCountry,
  handleSelectTimezone,
  handleAllowSendWeekend,
  handleAllowSendHoliday,
  handleHasReview,
  isEdit,
}) => {
  const { countriesNames, countryNameByCode } = useCountries();
  const timezones = timezonesList();

  const countryValue = () => {
    let value = null;
    if (sequence?.attributes?.holiday_country) {
      value = countryNameByCode(sequence.attributes.holiday_country);
    }
    if (dirtyChanges.holiday_country) {
      value = countryNameByCode(dirtyChanges.holiday_country);
    }
    return value;
  };

  const handleHasReviewValue = () => {
    if (dirtyChanges?.has_review === true) {
      return "Required - must be aproved before being sent";
    } else if (dirtyChanges?.has_review === false) {
      return "Not Required - will be sent without a review instance";
    }
  };

  const handleDefaultHasReviewValue = () => {
    if (sequence?.attributes?.has_review === true) {
      return "Required - must be aproved before being sent";
    } else if (sequence?.attributes?.has_review === false) {
      return "Not Required - will be sent without a review instance";
    }
  };

  const handleChangeWithValidation = (event) => {
    handleInputChange(event);
    if (isEdit && event.target.value.length === 0) {
      setError(`name`);
      setValue(`name`, null);
    } else if (isEdit && event.target.value.length > 0) {
      setValue(`name`, event.target.value);
      clearErrors(`name`);
    }
  };

  return (
    <Flex width="90%" justifyContent="space-evenly">
      <Box width={"40%"} pb={4}>
        <Input
          name="name"
          {...register("name", { required: true })}
          error={errors[`name`]}
          errorMsg="This field is required"
          required
          label="Sequence Name"
          placeholder={"Enter sequence name"}
          value={dirtyChanges?.name}
          defaultValue={sequence?.attributes?.name}
          onChange={handleChangeWithValidation}
          handleChange={(val) => {
            setValue("name", val);
          }}
          showCharLimitCount={Object.keys(errors).length === 0}
          maxLength="50"
          autoFocus
          tabIndex="1"
        />

        <Dropdown
          name="holiday_country"
          {...register("holiday_country", { required: true })}
          error={errors[`holiday_country`]}
          errorMsg="This field is required"
          required
          label="Region"
          formStyle
          placeholder="Select a region"
          options={countriesNames()}
          value={countryValue()}
          handleDropdown={(name, value) => handleSelectCountry(name, value)}
          handleChange={(val) => { setValue("holiday_country", val); }}
          short="true"
          small
          pb={3}
          variant="blue"
          width={"100%"}
          tabIndex={3}
        />
        <DropdownWithId
          name="timezone"
          {...register("timezone", { required: true })}
          error={errors[`timezone`]}
          errorMsg="This field is required"
          required
          label="Timezone"
          formStyle
          placeholder="Select a Timezone"
          options={timezones?.map((timezone) => ({
            id: timezone.id,
            val: timezone.value,
          }))}
          value={dirtyChanges?.timezone || sequence?.attributes?.timezone}
          handleDropdown={(name, value) => handleSelectTimezone(name, value)}
          handleChange={(val) => { setValue("timezone", val); }}
          short="true"
          small
          variant="blue"
          tabIndex={5}
          pb={30}
        />
      </Box>

      <Box width="45%" pb={4}>
        <Input
          {...register("description", { required: false })}
          label="Sequence Description"
          placeholder="Enter a description for this sequence"
          value={dirtyChanges?.description}
          defaultValue={sequence?.attributes?.description}
          onChange={handleInputChange}
          disabled={false}
          showCharLimitCount={Object.keys(errors).length === 0}
          maxLength="100"
          tabIndex="2"
          mb={"10px"}
          handleChange={(val) => {
            setValue("description", val);
          }}
        />
        <Dropdown
          width={"100%"}
          label="Messages Review"
          name="has_review"
          required
          formStyle
          short="true"
          small
          variant="blue"
          tabIndex="4"
          placeholder="Select a Message Review option"
          options={[
            "Required - must be aproved before being sent",
            "Not Required - will be sent without a review instance",
          ]}
          convertOptionResponse
          defaultValue={handleDefaultHasReviewValue()}
          value={handleHasReviewValue()}
          handleDropdown={handleHasReview}
        />

        <Flex
          justifyContent="space-around"
          alignItems="center"
          mt={50}
        >
          <Toggle
            customeLabel="Send on weekends?"
            initialValue={
              sequence?.attributes?.allow_send_weekend ||
              dirtyChanges?.allow_send_weekend
            }
            handleChange={handleAllowSendWeekend}
            tabIndex="6"
          />

          <Toggle
            customeLabel="Send on holidays?"
            initialValue={
              sequence?.attributes?.allow_send_holiday ||
              dirtyChanges?.allow_send_holiday
            }
            handleChange={handleAllowSendHoliday}
            tabIndex="7"
          />
        </Flex>
      </Box >
    </Flex >
  );
};

export default SequenceForm;
