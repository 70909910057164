import React from "react";
import theme from "@theme";

//components
import Flex from "@ui/flex";
import SubNavbarTabs from "@ui/sub-navbar-tabs";
import TemplateWorkflowsList from "./TemplateWorkflowsList";
import AssignedWorkflowsList from "./AssignedWorkflowsList";
import ModalRemove from "@ui/modal-remove";

//containers
import NewWorkflowModal from "@containers/workflows/NewWorkflowModalContainer";
import EditWorkflowModal from "@containers/workflows/EditWorkflowModalContainer";
import AddToModal from "@containers/shared/AddToModalContainer";


const Workflows = ({
  viewport,
  workflows,
  stages,
  rowTemplateMenuOptions,
  rowAssignedMenuOptions,
  editActive,
  addToProjectActive,
  closeEditModal,
  closeAddModal,
  activeWorkflow,
  addToOptions,
  submitAction,
  handleSubNavbarTabs,
  projects,
  removeActive,
  itemToRemove,
  removeWorkflowHandler,
  closeRemoveModal,
}) => {
  return (
    <Flex
      paddingTop={"64px"}
      alignItems={"center"}
      minHeight={"100vh"}
      width={"100%"}
      flexDirection={"column"}
      justifyContent={"flex-start"}
      px={{ sm: 2, md: 0 }}
      overflow="hidden"
      backgroundColor={theme.colors.lighterGrey}
    >
      <SubNavbarTabs
        labels={["Templates", "Assigned"]}
        onSelect={handleSubNavbarTabs}
        iconButtons={[
          <NewWorkflowModal showButton key="newWorkflow" />,
        ]}
      >
        <TemplateWorkflowsList
          viewport={viewport}
          workflows={workflows}
          stages={stages}
          rowTemplateMenuOptions={rowTemplateMenuOptions}
        />
        <AssignedWorkflowsList
          viewport={viewport}
          workflows={workflows}
          stages={stages}
          rowAssignedMenuOptions={rowAssignedMenuOptions}
          projects={projects}
        />
      </SubNavbarTabs>

      {editActive === true && (
        <EditWorkflowModal
          workflow={activeWorkflow}
          stages={stages}
          closeEditModalHandler={closeEditModal}
          isEditActive={editActive}
        />
      )}
      {addToProjectActive === true && (
        <AddToModal
          closeAddModal={closeAddModal}
          title="Project"
          addToOptions={addToOptions}
          itemAttached={activeWorkflow}
          submitAction={submitAction}
        />
      )}
      {removeActive && (
        <ModalRemove
          modalType="remove"
          removeItem={itemToRemove}
          title="Remove Workflow"
          handleRemoveClick={removeWorkflowHandler}
          closeModal={closeRemoveModal}
        />
      )}

    </Flex>
  );
};

export default Workflows;
