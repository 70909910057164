import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import theme from "../../../theme";

import Spinner from "../../ui/spinner";
import WorkflowsIntro from "./WorkflowsTemplateIntro";

import { getRelated } from "../../../utils";

import Box from "../../ui/box";
import Row from "../../ui/row";
import Text from "../../ui/text";
import Flex from "../../ui/flex";
import Steps from "../../ui/steps-list";
import RowMenu from "../../ui/row-menu-button";

//reducer
import { getWorkflowsLoading } from "@reducer";

//icon
import Valid from "../../ui/icons/statActive";
import Null from "../../ui/icons/statInactive";

const StyledText = styled.div`
  white-space: pre-line;
  margin: 5px;
  align-items: center;
  font-size: 0.9rem;
  display: flex;
  flex-direction: row;
  color: ${theme.colors.grey};
  font-weight: 200;
`;

const StyledIcon = styled.div`
  height: 22px;
  width: 22px;
  margin-left: 10px;
`;

const workflowsStagesList = (workflow, included) => {
  const relatedStages = getRelated(
    workflow.relationships.stages.data,
    included
  );

  return relatedStages.map((stage) => ({
    variant: "ready",
    label: stage.attributes.name,
    sequence: stage.attributes.sequence_id,
  }));
};

const hasSequence = (workflow, included) => {
  const stages = workflowsStagesList(workflow, included);
  return stages.some((stage) => stage.sequence !== null);
};

const TemplateWorkflowsList = ({
  workflows,
  stages,
  rowTemplateMenuOptions,
  viewport,
}) => {
  const isPayloadLoading = useSelector(getWorkflowsLoading);

  return isPayloadLoading ? (
    <Spinner />
  ) : (
    <Flex
      marginTop={"3%"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      width={viewport > 2000 ? "85%" : "95%"}
      backgroundColor={theme.colors.lighterGrey}
      marginBottom="30px"
    >
      {workflows && workflows.length > 0 ? (
        workflows?.map((workflow, index) => (
          <Row key={index}>
            <Box width="25%">
              <Text
                color={theme.colors.blue}
                fontFamily="Open Sans"
                fontSize="1.15rem"
              >
                {workflow.attributes.name}
              </Text>
            </Box>
            <Box width="55%">
              <Steps
                id={index}
                hasScroll
                showLabel
                flat
                content={workflowsStagesList(workflow, stages)}
              />
            </Box>
            <Box width="10%">
              <Flex alignItems="center">
                <StyledText>Sequence</StyledText>
                <StyledIcon>
                  {hasSequence(workflow, stages) ? <Valid /> : <Null />}
                </StyledIcon>
              </Flex>
            </Box>

            <RowMenu
              icon="config"
              options={rowTemplateMenuOptions}
              resource={workflow}
            />
          </Row>
        ))
      ) : (
        <WorkflowsIntro />
      )}
    </Flex>
  );
};

export default TemplateWorkflowsList;
