import React, { forwardRef } from "react";
import styled from "styled-components";
import {
  alignItems,
  alignContent,
  alignSelf,
  border,
  boxShadow,
  flex,
  flexBasis,
  flexDirection,
  flexGrow,
  flexShrink,
  flexWrap,
  justifyContent,
  justifySelf,
  layout,
  order,
  position
} from "styled-system";

import Box from "../box";

const StyledFlex = styled(Box)`
  ${({ pointer }) => pointer && "cursor: pointer;"}
  ${({ hoverColor, theme }) =>
    hoverColor && `&:hover {background-color: ${theme.colors[hoverColor]};`}
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  ${alignItems}
  ${alignContent}
  ${alignSelf}
  ${border}
  ${boxShadow}
  ${flex}
  ${flexBasis}
  ${flexDirection}
  ${flexGrow}
  ${flexShrink}
  ${flexWrap}
  ${justifyContent}
  ${justifySelf}
  ${layout}
  ${order}
  ${position}
`;

const Flex = forwardRef(
  function Flex(
    {
      alignItems = "flex-start",
      flexDirection = "row",
      hoverColor = "",
      justifyContent = "flex-start",
      ...props
    },
    ref
  ) {
    return <StyledFlex
      alignItems={alignItems}
      flexDirection={flexDirection}
      hoverColor={hoverColor}
      justifyContent={justifyContent}
      ref={ref}
      {...props}
    />
  }
);

export default Flex;
