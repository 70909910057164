import React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
 * circle {
  fill: #E5F8FE;
}
 * path {
  fill: #4BAAC8;
}
`;

const StatActive = ({
  ...props
}) => {
  return (
    <StyledSvg
      {...props}
      viewBox="0 0 25 25">
      <g>
        <circle cx="12.5" cy="12.5" r="12" />
        <path d="M11.1,20c-0.5,0-1-0.2-1.4-0.6l-4.1-4.1c-0.8-0.8-0.8-2,0-2.8c0.8-0.8,2-0.8,2.8,0l2.3,2.3l5.5-8.9
	c0.6-0.9,1.8-1.2,2.8-0.6c0.9,0.6,1.2,1.8,0.6,2.8l-6.9,11c-0.3,0.5-0.9,0.9-1.5,0.9C11.3,20,11.2,20,11.1,20z"/>
      </g>

    </StyledSvg>
  )
}

export default StatActive;
