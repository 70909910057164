/* eslint-disable react/jsx-key */
import React from "react";
import * as R from "ramda";
import styled from "styled-components";

//Components
import Modal from "../../ui/modal/Modal";
import Flex from "../../ui/flex";
import Box from "../../ui/box";
import Button from "../../ui/button/index";
import Input from "../../ui/input/index";
import theme from "@theme";

//Icons
import Close from "../../ui/icons/close";
import Person from "../../ui/icons/person";
import Email from "../../ui/icons/message";
import Phone from "../../ui/icons/phone";
import Degree from "../../ui/icons/degree";
import Location from "../../ui/icons/location";
import Facebook from "../../ui/icons/facebook";
import Linkedin from "../../ui/icons/linkedin";
import Twitter from "../../ui/icons/twitter";
import Github from "../../ui/icons/github";
import Web from "../../ui/icons/web";

const StyledInput = styled(Input)`
  margin-bottom: 8px;
  margin-top: 4px;
`;

const StyledTitle = styled("p")`
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.5rem;
  margin-bottom: 20px;
  margin-top: 30px;
  color: ${theme.colors.blue};
`;

const StyledSubtitle = styled("p")`
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
  font-weight: 400;
  margin: 5px 0px;
  color: ${theme.colors.grey};
`;

const StyledText = styled("p")`
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
  color: ${theme.colors.grey};
  margin: 2px 0px;
`;

const StyledDateText = styled("p")`
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
  color: ${theme.colors.lightGrey};
  margin: 2px 0px;
`;

const EditPersonModal = ({
  closeModal,
  showModal,
  openModal,
  dispatch,
  dirtyChanges,
  handleSubmitClick,
  handleChange,
  handleDatepicker,
  person,
}) => {
  return (
    <>
      {showModal === true && (
        <Modal
          modal
          fullHeader
          title="Edit profile"
          icon={<Close variant="grey"></Close>}
          closeHandler={closeModal}
          footer
          buttons={[
            <Button
              variant="secondaryLine"
              content="Cancel"
              onClick={closeModal}
            />,
            <Button
              variant={"secondary"}
              content="Update"
              onClick={handleSubmitClick}
            />,
          ]}
        >
          <Flex width="100%" flexDirection="column">
            <Flex width="100%" justifyContent="space-evenly">
              <Box width={"30%"}>
                <StyledInput
                  disabled={false}
                  icon={<Person variant="white" />}
                  name="first_name"
                  label="First Name"
                  defaultValue={person.attributes.first_name}
                  mb={4}
                  mt={2}
                  placeholder="Enter First Name"
                  value={dirtyChanges.first_name}
                  onChange={handleChange}
                />
                <StyledInput
                  disabled={false}
                  icon={<Person variant="white" />}
                  name="last_name"
                  label="Last Name"
                  defaultValue={person.attributes.last_name}
                  mb={4}
                  mt={2}
                  placeholder="Enter Last Name"
                  value={dirtyChanges.last_name}
                  onChange={handleChange}
                />
                <StyledInput
                  disabled={false}
                  icon={<Email variant="grey" />}
                  name="email"
                  label="Email"
                  defaultValue={person.attributes.email}
                  mb={4}
                  mt={2}
                  placeholder="Enter Contact Email"
                  value={dirtyChanges.email}
                  onChange={handleChange}
                />
                <StyledInput
                  disabled={false}
                  icon={<Email variant="grey" />}
                  name="email_second"
                  label="Alternative Email"
                  defaultValue={person.attributes.email_second}
                  mb={4}
                  mt={2}
                  placeholder="Enter Alternative Contact Email"
                  value={dirtyChanges.email_second}
                  onChange={handleChange}
                />
                <StyledInput
                  disabled={false}
                  icon={<Email variant="grey" />}
                  name="email_work"
                  label="Work Email"
                  defaultValue={person.attributes.email_work}
                  mb={4}
                  mt={2}
                  placeholder="Enter Work Contact Email"
                  value={dirtyChanges.email_work}
                  onChange={handleChange}
                />
                <StyledInput
                  disabled={false}
                  icon={<Phone variant="grey" />}
                  name="phone"
                  label="Phone"
                  defaultValue={person.attributes.phone}
                  mb={4}
                  mt={2}
                  placeholder="Enter Contact Phone Number"
                  value={dirtyChanges.phone}
                  onChange={handleChange}
                />
              </Box>

              <Box width={"30%"}>
                <StyledInput
                  disabled={false}
                  icon={<Location variant="grey" />}
                  name="city"
                  label="City"
                  defaultValue={person.attributes.city}
                  mb={4}
                  mt={2}
                  placeholder="Enter Current City"
                  value={dirtyChanges.city}
                  onChange={handleChange}
                />
                <StyledInput
                  disabled={false}
                  icon={<Location variant="grey" />}
                  name="state"
                  label="State"
                  defaultValue={person.attributes.state}
                  mb={4}
                  mt={2}
                  placeholder="Enter City's State"
                  value={dirtyChanges.state}
                  onChange={handleChange}
                />
                <StyledInput
                  disabled={false}
                  icon={<Location variant="grey" />}
                  name="country"
                  label="Country"
                  defaultValue={person.attributes.country}
                  mb={4}
                  mt={2}
                  placeholder="Enter Candidate's Country"
                  value={dirtyChanges.country}
                  onChange={handleChange}
                />
                <StyledInput
                  disabled={false}
                  icon={<Degree variant="grey" />}
                  name="degree"
                  label="Degree"
                  defaultValue={person.attributes.degree}
                  mb={4}
                  mt={2}
                  placeholder="Enter Candidate Degree"
                  value={dirtyChanges.degree}
                  onChange={handleChange}
                />
                <StyledInput
                  disabled={false}
                  name="company"
                  label="Company"
                  defaultValue={person.attributes.company}
                  mb={4}
                  mt={2}
                  placeholder="Enter Company Name"
                  value={dirtyChanges.company}
                  onChange={handleChange}
                />

                <StyledInput
                  disabled={false}
                  name="title"
                  label="Title"
                  defaultValue={person.attributes.title}
                  mb={4}
                  mt={2}
                  placeholder="Enter Position Title"
                  value={dirtyChanges.title}
                  onChange={handleChange}
                />
              </Box>

              <Box width={"30%"}>
                <StyledInput
                  disabled={false}
                  icon={<Linkedin variant="grey" />}
                  name="linkedin"
                  label="Linkedin"
                  defaultValue={person.attributes.linkedin}
                  mb={4}
                  mt={2}
                  placeholder="Enter Link to Linkedin Profile"
                  value={dirtyChanges.linkedin}
                  onChange={handleChange}
                />
                <StyledInput
                  disabled={false}
                  icon={<Github variant="grey" />}
                  name="github"
                  label="Github"
                  defaultValue={person.attributes.github}
                  mb={4}
                  mt={2}
                  placeholder="Enter Link to Github Profile"
                  value={dirtyChanges.github}
                  onChange={handleChange}
                />
                <StyledInput
                  disabled={false}
                  icon={<Twitter variant="grey" />}
                  name="twitter"
                  label="Twitter"
                  defaultValue={person.attributes.twitter}
                  mb={4}
                  mt={2}
                  placeholder="Enter Link to Twitter Profile"
                  value={dirtyChanges.twitter}
                  onChange={handleChange}
                />
                <StyledInput
                  disabled={false}
                  icon={<Facebook variant="grey" />}
                  name="facebook"
                  label="Facebook"
                  defaultValue={person.attributes.facebook}
                  mb={4}
                  mt={2}
                  placeholder="Enter Link to Facebook Profile"
                  value={dirtyChanges.facebook}
                  onChange={handleChange}
                />
                <StyledInput
                  disabled={false}
                  icon={<Web variant="grey" />}
                  name="website"
                  label="website"
                  defaultValue={person.attributes.website}
                  mb={4}
                  mt={2}
                  placeholder="Enter Link to Website"
                  value={dirtyChanges.website}
                  onChange={handleChange}
                />
              </Box>
            </Flex>

            <Flex width="100%" justifyContent="space-evenly" flexDirection="column" padding="30px">
              <Box width="95%" >
                <StyledTitle>About (Linkedin)</StyledTitle>
                <Box margin="10px">
                  <StyledText>{person.attributes.about || "-"}</StyledText>
                </Box>
              </Box>

              <Box width="95%">
                <StyledTitle>Experience (Linkedin)</StyledTitle>
                <Box margin="10px" marginBottom="15px">
                  {R.isEmpty(person.attributes.experience) ?
                    <StyledText> - </StyledText>
                    : person.attributes.experience.map((experience) =>
                      <Box marginBottom="15px">
                        <StyledSubtitle>· {experience['company']}</StyledSubtitle>
                        <StyledText>{experience['title']}</StyledText>
                        <StyledText>{experience['location']}</StyledText>
                        <StyledDateText>{experience['period']}</StyledDateText>
                        {experience['jobs']?.map((job) =>
                          <>
                            <StyledText>{job['title']}</StyledText>
                            <StyledText>{job['location']}</StyledText>
                            <StyledDateText>{job['period']}</StyledDateText>
                          </>
                        )}
                      </Box>
                    )
                  }
                </Box>
              </Box>

              <Box width="95%">
                <StyledTitle>Education (Linkedin)</StyledTitle>
                <Box margin="10px" marginBottom="15px">
                  {R.isEmpty(person.attributes.education) ?
                    <StyledText> - </StyledText>
                    : person.attributes.education.map((education) =>
                      <Box marginBottom="15px">
                        <StyledSubtitle>· {education['title']}</StyledSubtitle>
                        <StyledText>{education['description']}</StyledText>
                        <StyledDateText>{education['period']}</StyledDateText>
                      </Box>
                    )
                  }
                </Box>
              </Box>


            </Flex>
          </Flex>
        </Modal>
      )}
    </>
  );
};

export default EditPersonModal;
