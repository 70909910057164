import { combineActions, handleActions } from "redux-actions";
import { lensProp, pipe, set, view } from "ramda";
import {
  projectPeopleGet,
  projectPeopleGetSuccess,
  projectPeopleGetFailure,
  projectPeopleSearch,
  projectPeopleSearchSuccess,
  projectPeopleSearchFailure,
  onExpiredToken,
  projectPeopleClean,
} from "../actions";

const initialState = {
  inError: false,
  loading: false,
  token: localStorage.getItem("access-token"),
  data: [],
  meta: {},
  links: {},
  included: [],
  current: "",
};

const isLoadingLens = lensProp("loading");
const inErrorLens = lensProp("inError");
const tokenLens = lensProp("token");
const dataLens = lensProp("data");
const includedLens = lensProp("included");
const metaLens = lensProp("meta");
const linksLens = lensProp("links");

const setData = set(dataLens);
const setIncluded = set(includedLens);
const setMeta = set(metaLens);
const setLinks = set(linksLens);

export const getIsLoading = view(isLoadingLens);
export const getInError = view(inErrorLens);
export const getToken = view(tokenLens);

export default handleActions(
  {
    [projectPeopleGetSuccess]: (state, action) =>
      pipe(
        setData(action.payload.data.data),
        setIncluded(action.payload.data.included),
        setLinks(action.payload.data.links.nav),
        setMeta(action.payload.data.meta),
        set(isLoadingLens, false)
      )(state),

    [projectPeopleGetFailure]: (state) =>
      pipe(set(inErrorLens, true), set(isLoadingLens, false))(state),

    [projectPeopleGet]: (state) =>
      pipe(set(inErrorLens, false), set(isLoadingLens, true))(state),

    [projectPeopleSearchSuccess]: (state, action) =>
      pipe(
        setData(action.payload.data.data),
        setIncluded(action.payload.data.included),
        set(isLoadingLens, false)
      )(state),

    [projectPeopleSearchFailure]: (state) =>
      pipe(set(inErrorLens, true), set(isLoadingLens, false))(state),

    [projectPeopleSearch]: (state) =>
      pipe(
        setData([]),
        setIncluded([]),
        set(inErrorLens, false),
        set(isLoadingLens, true)
      )(state),

    //clean People list
    [projectPeopleClean]: (state, action) =>
      pipe(
        setData([]),
        setIncluded([]),
        setMeta({}),
        set(isLoadingLens, false)
      )(state),
    // handle expired token
    [combineActions(onExpiredToken)]: (state) => {
      localStorage.clear(); // remove token
      return {
        ...initialState,
        token: null,
      };
    },
  },
  initialState
);

export const getProjectPeople = (state) => state.projectPeople;

export const getProjectPeopleCurrentPage = (state) =>
  state.projectPeople.meta?.pages?.current;
export const getProjectPeopleTotalPages = (state) =>
  state.projectPeople.meta?.pages?.total;
