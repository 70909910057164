export const separateWorkspaces = (userId, workspaces) => {
  let result = {
    owner: [],
    guest: [],
  };

  workspaces.forEach((workspace) => {
    if (workspace.attributes.user_id === userId) {
      result.owner.push(workspace);
    } else {
      result.guest.push(workspace);
    }
  });

  return result;
};
