import React, { useState, useEffect } from "react";
import styled from "styled-components";
import theme from "@theme";
import { useForm } from "react-hook-form";

import Modal from "@ui/modal/Modal";
import Flex from "@ui/flex";
import Box from "@ui/box";
import Submit from "@ui/input-submit";
import DatalistInput from "@ui/input-datalist/index";
import Dropdown from "@ui/dropdown/index";
import Button from "@ui/button";

//Icons
import Add from "@ui/icons/add";
import Close from "@ui/icons/close";

const ButtonAdd = styled.div`
  width: 20px;
  height: 20px;
`;

const StyledTextColor = styled.p`
  font-size: 1.1rem;
  color: ${theme.colors.blue};
  font-weight: ${(props) => (props.bold ? "500" : "300")};
  line-height: 1.2rem;
  margin: 0;
`;

const StyledTextblockColor = styled.p`
  font-size: 1.1rem;
  color: ${theme.colors.blue};
  font-weight: 300;
  line-height: 1.5rem;
  margin: ${(props) => (props.margin ? props.margin : 0)};
`;

const StyledTextAlert = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2rem;
`;

const StyledText = styled.p`
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.2rem;
`;

const StyledTextblock = styled.p`
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.5rem;
`;

const InviteToProjectModal = ({
  closeModal,
  showModal,
  showButton,
  openModal,
  dirtyChanges,
  setDirtyChanges,
  handleSubmitClick,
  handleChange,
  handleDropdown,
  workspaceId,
  workspaceUsers,
  newUser,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();

  const [projectRoleInfo, setProjectRoleInfo] = useState("");
  const [workspaceRoleInfo, setWorkspacetRoleInfo] = useState("");
  const datalistOptions = workspaceUsers?.map((user) => user.attributes.email);

  useEffect(() => {
    switch (dirtyChanges.project_role) {
      case "Owner":
        setProjectRoleInfo(
          "Unlimited access to the Project. Access to edit and delete the Project."
        );
        break;
      case "Manager":
        setProjectRoleInfo(
          "Invite and manage users in the Project. Add people, write and send messages."
        );
        break;
      case "Collaborator":
        setProjectRoleInfo(
          "Add people to the Project, edit people, use the Workflow and write messages."
        );
        break;
      default:
        return "";
    }
  }, [dirtyChanges.project_role]);

  useEffect(() => {
    setValue("workspaceRole", dirtyChanges.workspace_role);

    switch (dirtyChanges.workspace_role) {
      case "Manager":
        setWorkspacetRoleInfo(
          "Add and remove users. Manage integrations, Sequences, Workflows and Projects. Access all Projects."
        );
        break;
      case "Collaborator":
        setWorkspacetRoleInfo(
          "Create new Sequences, Workflows and Projects. Need invitations to projects."
        );
        break;
      case "Viewer":
        setWorkspacetRoleInfo(
          "Can't modify anything within the Workspace. Need invitations to projects."
        );
        break;
      default:
        return "";
    }
  }, [dirtyChanges.workspace_role]);

  useEffect(() => {
    if (dirtyChanges.email) {
      setValue("email", dirtyChanges.email);
    }
  }, [dirtyChanges.email]);

  const handleInputChange = (event) => {
    setValue("email", event.target.value);
    setDirtyChanges({
      ...dirtyChanges,
      [event.target.name]: event.target.value,
    });
  };

  const handleCLoseModal = () => {
    clearErrors();
    setProjectRoleInfo("");
    setWorkspacetRoleInfo("");
    closeModal();
  };

  return (
    <>
      {showButton === true && (
        <ButtonAdd onClick={openModal}>
          <Add variant="blue" linkable />
        </ButtonAdd>
      )}
      {showModal === true && (
        <Modal
          fullHeader
          formContent
          onSubmit={handleSubmit(handleSubmitClick)}
          title="Add Person"
          icon={<Close variant="grey"></Close>}
          closeHandler={handleCLoseModal}
          textWarning="* Field Required"
          buttons={[
            <Button
              key="cancel"
              variant="secondaryLine"
              content="Cancel"
              onClick={handleCLoseModal}
            />,
            <Submit key="continue" variant="secondary" content="Continue" />,
          ]}
        >
          <Flex width="90%" justifyContent="space-evenly" alignItems="center">
            <Box width={"35%"} minHeight="300px">
              <DatalistInput
                {...register("email", { required: true })}
                required
                name="email"
                error={errors.email ? true : false}
                errorMsg="This field is required"
                label="User Email"
                mb={"15px"}
                mt={2}
                placeholder={"Enter Person Email"}
                inputValue={dirtyChanges.email}
                handleChange={handleInputChange}
                handleDropdown={handleDropdown}
                autoFocus
                tabIndex="1"
                datalistOptions={datalistOptions}
              />
              <Dropdown
                {...register("projectRole", { required: true })}
                error={errors.projectRole ? true : false}
                errorMsg="This field is required"
                name="project_role"
                value={dirtyChanges.project_role}
                handleDropdown={handleDropdown}
                handleChange={(val) => {
                  setValue("projectRole", val);
                }}
                small
                formStyle
                variant="blue"
                required
                options={["Owner", "Manager", "Collaborator"]}
                label="Project Role"
                mb={4}
                placeholder="Set Project Role"
                tabIndex="2"
              />
              {newUser && (
                <>
                  <StyledTextAlert>
                    You are inviting a New user! please select a Workspace Role
                    too.
                  </StyledTextAlert>
                  <Dropdown
                    {...register("workspaceRole", { required: true })}
                    error={errors.workspaceRole ? true : false}
                    errorMsg="This field is required"
                    name="workspace_role"
                    value={dirtyChanges.workspace_role}
                    handleDropdown={handleDropdown}
                    handleChange={(val) => {
                      setValue("workspaceRole", val);
                    }}
                    small
                    formStyle
                    variant="blue"
                    required
                    options={["Manager", "Collaborator", "Viewer"]}
                    label="Workspace Role"
                    mb={4}
                    placeholder="Set Workspace Role"
                    tabIndex="3"
                  />
                </>
              )}
            </Box>
            <Flex
              flexDirection="column"
              alignItems="center"
              width="40%"
              height="300px"
              padding="20px 0px"
            >
              <Box minHeight="150px">
                <StyledTextblock>
                  Please enter the email of the user and select a role for this
                  project.
                </StyledTextblock>
                <StyledTextblock>
                  Roles grant a series of permissions within the project
                </StyledTextblock>
                <br />
                <StyledText>Project Role</StyledText>
                <Flex>
                  <StyledTextColor bold={dirtyChanges.project_role}>
                    {dirtyChanges.project_role
                      ? dirtyChanges.project_role + ":"
                      : "Waiting to be selected"}
                  </StyledTextColor>
                  <StyledTextblockColor margin="0px 10px">
                    {projectRoleInfo}
                  </StyledTextblockColor>
                </Flex>

                {newUser && (
                  <>
                    <StyledText>Workspace Role</StyledText>
                    <Flex>
                      <StyledTextColor bold={dirtyChanges.workspace_role}>
                        {dirtyChanges.workspace_role
                          ? dirtyChanges.workspace_role + ":"
                          : "Waiting to be selected"}
                      </StyledTextColor>
                      {dirtyChanges.workspace_role && (
                        <StyledTextblockColor margin="0px 10px">
                          {workspaceRoleInfo}
                        </StyledTextblockColor>
                      )}
                    </Flex>
                  </>
                )}
              </Box>
            </Flex>
          </Flex>
        </Modal>
      )}
    </>
  );
};

export default InviteToProjectModal;
