import React from "react";
import styled from "styled-components";
import * as R from "ramda";
import mustache from "mustache/mustache.mjs";

import Flex from "../../ui/flex";
import Box from "../../ui/box";
import Tag from "../../ui/tag";

import { useCountries } from "../../../hooks";

const Mustache = mustache;

const FinishLabel = styled.div`
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.5rem;
  width: 30%;
  text-align: end;
  margin: 1rem;
`;
const FinishValue = styled.div`
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #1b7b99;
  width: 70%;
  text-align: start;
  margin: 1rem;
`;
const FinishText = styled.div`
  width: 80%;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 300;
`;
const TagFinishContainer = styled.div`
  width: 70%;
  display: flex;
  flex-flow: wrap;
  align-items: center;
`;

const SequenceConfirmation = ({
  steps,
  dirtyChanges,
  candidateVariables,
  projectVariables,
}) => {
  const stepCredencialsEmails = () => {
    return R.uniq(steps.map((step) => step.credential_email));
  };

  const usedVariables = () => {
    let notNil = R.compose(R.not, R.isNil);
    let stepVariables = candidateVariables.concat(projectVariables).concat(dirtyChanges.payload_fields.customs.map((custom) => custom['name']));
    let variables = steps.map((step) =>
      Mustache.parse(step["template_body"] + step["template_subject"]).map(
        (item) => {
          if (item[0] === "name" && R.includes(item[1], stepVariables)) return item[1];
        }
      )
    );
    return R.uniq(R.filter(notNil, [].concat(...variables)));
  };

  const { countryNameByCode } = useCountries();

  return (
    <Flex key="finish" width="90%" justifyContent="space-evenly">
      <Box width={"60%"} textAlign="center">
        <Flex>
          <FinishLabel>Sequence Name</FinishLabel>
          <FinishValue>{dirtyChanges.name}</FinishValue>
        </Flex>
        <Flex>
          <FinishLabel>Sequence Description</FinishLabel>
          <FinishValue>{dirtyChanges.description}</FinishValue>
        </Flex>
        <Flex>
          <FinishLabel>Country</FinishLabel>
          <FinishValue>
            {countryNameByCode(dirtyChanges.holiday_country)}
          </FinishValue>
        </Flex>
        <Flex>
          <FinishLabel>Requires Review</FinishLabel>
          <FinishValue>{dirtyChanges.has_review ? "Yes" : "No"}</FinishValue>
        </Flex>
        <Flex>
          <FinishLabel>Send on weekends?</FinishLabel>
          <FinishValue>
            {dirtyChanges.allow_send_weekend ? "Yes" : "No"}
          </FinishValue>
        </Flex>
        <Flex>
          <FinishLabel>Send on holidays?</FinishLabel>
          <FinishValue>
            {dirtyChanges.allow_send_holiday ? "Yes" : "No"}
          </FinishValue>
        </Flex>
        <Flex>
          <FinishLabel>Steps</FinishLabel>
          <FinishValue>
            ({Object.keys(dirtyChanges.steps).length})
            {steps.map((step, index) => (
              <div
                key={index}
                style={{
                  margin: "0.5rem",
                  display: "inline",
                }}
              >
                {step.name}
              </div>
            ))}
          </FinishValue>
        </Flex>
        <Flex>
          <FinishLabel>Credentials</FinishLabel>
          <FinishValue>{stepCredencialsEmails().join(", ")}</FinishValue>
        </Flex>
        <Flex>
          <FinishLabel>Variables</FinishLabel>
          <TagFinishContainer>
            {usedVariables().map((variable, indexProjectVariable) => (
              <Tag key={indexProjectVariable} content={variable} />
            ))}
          </TagFinishContainer>
        </Flex>
      </Box>

      <Box width="40%" margin="auto 0">
        <Flex justifyContent="center" alignItems="center" height="100%">
          <FinishText>
            Before continuing please check that the sequence configuration is
            correct and that nothing is missing
          </FinishText>
        </Flex>
      </Box>
    </Flex>
  );
};

export default SequenceConfirmation;
