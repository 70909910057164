import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { FeatureFlag } from "react-unleash-flags";

import theme from "../../../theme";

import Modal from "../../ui/modal/Modal";
import Flex from "../../ui/flex";
import Box from "../../ui/box";
import Button from "../../ui/button/index";
import Submit from "../../ui/input-submit";
import Input from "../../ui/input/index";
import Stage from "../../ui/step";
import RowWorkflow from "../../domain/workflows/row-modal";

//Icons
import Add from "../../ui/icons/add";
import Close from "../../ui/icons/close";

const StyledStepsContainer = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
  flex-direction: column;

  ${({ onClick }) => onClick && `cursor: pointer;`}
`;

const StyledRowContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;
`;

const StyledDivider = styled.div`
  display: flex;
  align-self: center;
  margin: 8px;
  border-left: 2px solid ${theme.colors.blue};
  width: 0px;
  height: 20px;
`;

const StyledCircle = styled.div`
  display: flex;
  align-self: center;
  border-radius: 50%;
  height: 17px;
  width: 17px;
  padding: 3px;
  background-color: ${theme.colors.blue};

  &:hover {
    box-shadow: -2px 2px 2px lightgray;
    cursor: pointer;
  }
`;

const NewWorkflowModal = ({
  workspaceId, // new sequence button href
  closeModal, // handle close modal
  openModal, // handle open modal
  dirtyChanges,
  handleChange,
  handleSubmitClick,
  showModal, // boolean - modal state
  buttonCard, // color of the button that open the modal
  showButton, // boolean - show the button that open the modal
  activeStageIndex, // index of selected stage
  setActiveStageIndex, // set selected stage
  handleAddStage, // handle new stage
  handleRemoveStage, // handle remove stage
  sequenceDropdownHandler, // handleDropdownSelect with id
  sequences,
  handleCleanAction, //handle clean Action Switch
  asyncReload,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();

  const handleCloseModal = () => {
    clearErrors();
    closeModal();
  };

  return (
    <>
      {showButton === true && (
        <Button
          onClick={openModal}
          size="small"
          variant={buttonCard ? "secondaryLight" : "primaryLight"}
          content="Workflow"
          icon={<Add variant={buttonCard ? "blue" : "white"}></Add>}
        ></Button>
      )}
      {showModal === true && (
        <Modal
          fullHeader
          formContent
          onSubmit={handleSubmit(handleSubmitClick)}
          title="New Workflow"
          icon={<Close variant="grey"></Close>}
          closeHandler={handleCloseModal}
          textWarning="* Field Required"
          buttons={[
            <Button
              key="cancel"
              variant="secondaryLine"
              content="Cancel"
              onClick={handleCloseModal}
            />,
            <Submit key="continue" variant="secondary" content="Continue" />,
          ]}
        >
          <Flex
            width="90%"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <Flex width="100%" justifyContent="space-evenly">
              <Box width={"40%"} mb={3}>
                <Input
                  {...register("name", { required: true })}
                  required
                  error={errors.name ? true : false}
                  errorMsg="This field is required"
                  label="Name"
                  showCharLimitCount
                  maxLength="50"
                  mb={3}
                  mt={2}
                  placeholder="Set Workflow Name"
                  name="name"
                  value={dirtyChanges.name}
                  onChange={handleChange}
                  autoFocus
                  tabIndex={1}
                />
              </Box>

              <Box width={"40%"} mb={3}>
                <Input
                  label="Description"
                  mb={3}
                  mt={2}
                  placeholder="Description"
                  name="description"
                  value={dirtyChanges.description}
                  onChange={handleChange}
                  tabIndex={2}
                />
              </Box>
            </Flex>
            <Box width="85%" mb={5}>
              <Flex width={"100%"} justifyContent={"space-evenly"}>
                <StyledStepsContainer>
                  {dirtyChanges.stages.map((stage, index) => (
                    <React.Fragment key={index}>
                      <Stage
                        onClick={() => setActiveStageIndex(index)}
                        content={index + 1}
                        variant={index === activeStageIndex ? "active" : "red"}
                      ></Stage>
                      {index === dirtyChanges.stages.length - 1 ? null : (
                        <StyledDivider vertical></StyledDivider>
                      )}
                    </React.Fragment>
                  ))}

                  <FeatureFlag name="feature_sequence">
                    <StyledDivider vertical />
                    <StyledCircle onClick={() => handleAddStage()}>
                      <Add variant="white"></Add>
                    </StyledCircle>
                  </FeatureFlag>

                </StyledStepsContainer>

                <StyledRowContainer>
                  {dirtyChanges.stages.map((stage, index) => (
                    <RowWorkflow
                      asyncReload={asyncReload}
                      workspaceId={workspaceId}
                      key={index}
                      id={index}
                      register={register}
                      errors={errors}
                      setValue={setValue}
                      onClick={() => {
                        setActiveStageIndex(index);
                      }}
                      handleIcon={(e) => {
                        e.stopPropagation();
                        handleRemoveStage(activeStageIndex);
                      }}
                      stage={stage}
                      onChange={(e) => handleChange(e, index)}
                      showIcon={
                        dirtyChanges.stages.length > 3 &&
                        activeStageIndex === index
                      }
                      isActive={activeStageIndex === index}
                      sequenceDropdownHandler={sequenceDropdownHandler}
                      sequences={sequences}
                      stages={dirtyChanges.stages}
                      handleCleanAction={(e) => handleCleanAction(e, index)}
                    />
                  ))}
                </StyledRowContainer>
              </Flex>
            </Box>

          </Flex>
        </Modal>
      )}
    </>
  );
};

export default NewWorkflowModal;
