import * as R from "ramda"

export const analyticsFilename = (name) => {
  return R.compose(R.concat, R.toLower, R.replace)(/ /g, "_", name)(
    "_analytics.csv"
  );
};

export const workspaceCsvHeaders = () => {
  return [
    { label: "Project", key: "project" },
    { label: "Sourced", key: "sourced" },
    { label: "Contacted", key: "contacted" },
    { label: "Replies", key: "replies" },
    { label: "Interested", key: "interested" },
    { label: "Not Interested", key: "not_interested" },
    { label: "Follow Up", key: "follow_up" },
    { label: "Open Rate", key: "open_rate" },
  ]
};

export const analyticsCsvHeaders = (data) => {
  let headers = [];
  let keys = R.keys(data[0]);

  keys.forEach( key =>
    headers.push({ label: key, key: key })
  );
  return headers;
};