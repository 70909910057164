import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";
import theme from "@theme";

import mustache from "mustache/mustache.mjs";
import * as R from "ramda";
import DOMPurify from 'dompurify';

//reducer
import { getPayloadLoading } from "@reducer";

import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.css";

import Box from "@ui/box";
import Button from "@ui/button";
import Flex from "@ui/flex";
import CardTabs from "@ui/cardTabs";
import Text from "@ui/text";
import Input from "@ui/input/index";
import Spinner from "@ui/three-dots-loader";

const Mustache = mustache;

const CardInputLabel = styled.div`
  display: flex;
  font-family: ${theme.fonts[1]};
  font-size: 0.8rem;
  padding-right: 10px;
  text-align: right;
`;

const StyledSimpleBar = styled(SimpleBar)`
  width: 100%;
  max-height: 35vh;
  padding-right: 15px;

  .simplebar-scrollbar:before {
    background: ${theme.colors.grey};
  }
`;

const StyledField = styled(Text)`
  border-bottom: 1px solid ${theme.colors.blue};
  padding-bottom: 5px;
  padding-left: 10px;
  margin-right: 15px;
  color: ${(props) => (props.color ? props.color : theme.colors.grey)};
`;

const StyledLine = styled.div`
  display: inline-block;
  padding: 0px 15px;
  width: 600px;
`;

const StyledArea = styled.div`
  padding: 0px 15px;
`;

const PayloadEditor = ({
  selectedPayloadId,
  payloads,
  steps,
  updatePayloadHandler,
  sendEventPayloadHandler,
  getPersonByPayload,
  viewport,
}) => {
  const isPayloadLoading = useSelector(getPayloadLoading);

  const [payload, setPayload] = useState({});

  const [templateFields, setTemplateFields] = useState([]);
  const [defaultFields, setDefaultFields] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [fieldValues, setFieldValues] = useState({});
  const [stepsTemplates, setStepsTemplates] = useState([]);

  const [dirtyChanges, setDirtyChanges] = useState(false);

  const stepNames = steps.map((s) => s.attributes.name);

  const stepTotalFields = (step) => {
    let stepDefaultFields = step.attributes.default_fields.data.map(
      (f) => f["name"]
    );
    let stepCustomFields = step.attributes.custom_fields.data.map(
      (f) => f["name"]
    );
    return R.concat(stepDefaultFields, stepCustomFields);
  };

  // 0 - Set Selected Payload
  useEffect(() => {
    if (selectedPayloadId) {
      const selectedPayload = payloads.find((p) => p.id === selectedPayloadId);
      setPayload(selectedPayload);
    } else {
      setPayload(null);
      setDefaultFields(null);
    }
  }, [payloads, selectedPayloadId]);

  // 1 - Prepare fields
  useEffect(() => {
    if (!R.isEmpty(payload) && !R.isNil(payload)) {
      setFieldValues(payload.attributes.payload_values.data || []);
      setDefaultFields(payload.attributes.payload_fields.defaults || []);
      setCustomFields(payload.attributes.payload_fields.customs || []);
    }
  }, [payload, steps]);

  // 2 - Prepare templates
  useEffect(() => {
    let templates = [];
    steps.map((step) => {
      templates[step["id"]] = {
        body: wrapFields(step.attributes.template_body).join(""),
        subject: wrapFields(step.attributes.template_subject).join(""),
      };
      return templates[step["id"]];
    });
    setStepsTemplates(templates);
  }, [defaultFields, steps, payload]);

  const isDefaultField = (fieldName) => {
    return defaultFields.some((field) => field.name === fieldName);
  };

  const isCustomField = (fieldName) => {
    return customFields.some((field) => field.name === fieldName);
  };

  const getFieldType = (fieldName) => {
    return customFields.filter((field) => field.name === fieldName)[0].type;
  };

  const getFieldValue = (fieldName) => {
    if (fieldValues) return fieldValues[fieldName] || fieldName;
    else return fieldName;
  };

  const getFieldColor = (fieldName) => {
    if (fieldName === getFieldValue(fieldName)) {
      return theme.colors.lightRed;
    } else {
      return payload?.extra?.status?.textColor;
    }
  };

  // Wrappers
  const fieldWrapper = (fieldName) => {
    if (isDefaultField(fieldName)) {
      return defaultFieldWrapper(fieldName);
    } else if (isCustomField(fieldName)) {
      return customFieldWrapper(fieldName);
    } else {
      return undefinedFieldWrapper(fieldName);
    }
  };

  const defaultFieldWrapper = (fieldName) => {
    return `<span
              class="default-field"
              style="color:${getFieldColor(fieldName)}"
            > ${getFieldValue(fieldName)}
            </span>`;
  };

  const customFieldWrapper = (fieldName) => {
    return `<span id="${fieldName}"
              data-name="${fieldName}"
              data-type="${getFieldType(fieldName)}">
            </span>`;
  };

  const undefinedFieldWrapper = (fieldName) => {
    return `<span class="wrong-field" style="color:red">${fieldName}</span>`;
  };

  const wrapFields = (template) => {
    const resultTemplate = Mustache.parse(template).map((item) => {
      if (item[0] === "name") {
        setTemplateFields([...templateFields, item[1]]);
        return fieldWrapper(item[1]);
      } else {
        return item[1];
      }
    });
    return resultTemplate;
  };

  const transformInput = (node, index) => {
    if (node.type === "tag" && node.attribs["data-type"] === "Text") {
      const fieldName = node.attribs["data-name"];
      const fieldColor = getFieldColor(fieldName);

      return (
        <StyledLine>
          <Input
            key={`input` + index}
            color={fieldColor}
            name={fieldName}
            value={fieldValues[fieldName] || ""}
            defaultValue={getFieldValue(fieldName)}
            title={fieldName ? fieldName : ""}
            mb={2}
            mt={2}
            maxLength="80"
            inlineCount
            placeholder={fieldName || ""}
            onChange={onChangeHandler}
            disabled={payload?.extra?.status.editable ? false : true}
          />
        </StyledLine>
      );
    }
    if (node.type === "tag" && node.attribs["data-type"] === "Text area") {
      const fieldName = node.attribs["data-name"];
      const fieldColor = getFieldColor(fieldName);

      return (
        <StyledArea>
          <Input
            key={`input` + index}
            color={fieldColor}
            multiline={node.attribs["data-type"] === "Text area"}
            name={fieldName}
            value={fieldValues[fieldName] || ""}
            defaultValue={getFieldValue(fieldName)}
            title={fieldName ? fieldName : ""}
            mb={2}
            mt={2}
            placeholder={fieldName || ""}
            onChange={onChangeHandler}
            disabled={payload?.extra?.status.editable ? false : true}
          />
        </StyledArea>
      );
    }
  };

  // Handlers
  const saveChangeHandler = (event) => {
    if (dirtyChanges) {
      updatePayloadHandler(payload?.id, fieldValues);
      setDirtyChanges(false);
    }
  };

  const onChangeHandler = (event) => {
    setDirtyChanges(true);
    setFieldValues({
      ...fieldValues,
      [event.target.name]: event.target.value,
    });
  };

  const isIntegrationPresent = (step) => {
    return step.attributes.provider_id;
  };

  const labelsWithError = () => {
    const isIncompletedField = (field) => R.isEmpty(field) || R.isNil(field);
    const hasFieldsIncompleted = (step) =>
      R.intersection(fieldsNamesIncompleted, stepTotalFields(step)).length > 0;

    let payloadValues = payload?.attributes?.payload_values.data || [];
    let fieldsIncompleted = R.filter(isIncompletedField, payloadValues);
    let fieldsNamesIncompleted = R.keys(fieldsIncompleted);
    return R.filter(hasFieldsIncompleted, steps).map(
      (step) => step.attributes.name
    );
  };

  return (
    <Flex width="65%" flexDirection="column">
      <CardTabs
        height={viewport > 2000 ? "65vh" : "50vh"}
        margin="6px 0px"
        labels={stepNames}
        labelsWithError={labelsWithError()}
        onSaveChange={saveChangeHandler}
        dirtyChanges={dirtyChanges}
        footer={
          <Flex>
            {isPayloadLoading && (
              <Flex alignItems="center" height="50px" marginRight="20px">
                <Text color="darkGray" margin="20px">
                  Saving
                </Text>
                <Spinner blue />
              </Flex>
            )}

            {payload?.extra && payload?.extra?.status?.editable === true && (
              <>
                <Button
                  content="Save"
                  variant={dirtyChanges ? "secondaryLine" : "disabled"}
                  disabled={!dirtyChanges}
                  onClick={() => {
                    setDirtyChanges(false);
                    updatePayloadHandler(payload?.id, fieldValues);
                  }}
                />

                {payload?.extra?.status?.name !== "Pending" && (
                  <Button
                    content={payload?.extra?.status?.button}
                    variant="secondaryLine"
                    onClick={() =>
                      sendEventPayloadHandler(
                        payload,
                        payload?.extra?.status?.event
                      )
                    }
                  />
                )}
              </>
            )}
          </Flex>
        }
      >
        {steps.map((step, index) => (
          <Flex
            key={index}
            width="100%"
            height="100%"
            flexDirection="column"
            padding="10px"
          >
            <Flex width="100%">
              {stepsTemplates[step["id"]] && (
                <Flex width="100%" height="100%" flexDirection="column">
                  <Flex width="100%" justifyContent="space-between">
                    <Flex width="50%">
                      <CardInputLabel>From</CardInputLabel>
                      <Box width="100%" marginBottom="20px">
                        <StyledField
                          color={(step.attributes.from || step.attributes.provider_name) ? "grey" : "red"}
                        >
                          {step.attributes.from || step.attributes.provider_name || "Origin not found"}
                        </StyledField>
                      </Box>
                    </Flex>

                    <Flex width="50%">
                      <CardInputLabel>To</CardInputLabel>
                      <Box width="100%" marginBottom="20px">
                        <StyledField>
                          {getPersonByPayload(payload)?.attributes.email ||
                            "Destination not found"}
                        </StyledField>
                      </Box>
                    </Flex>
                  </Flex>

                  <Flex width="100%">
                    <CardInputLabel>Subject</CardInputLabel>
                    <Box width="100%" marginBottom="15px">
                      <form>
                        <StyledField>
                          {ReactHtmlParser(stepsTemplates[step["id"]].subject, {
                            transform: transformInput,
                          })}
                        </StyledField>
                      </form>
                    </Box>
                  </Flex>
                  <Flex width="100%" flexDirection="column">
                    <StyledSimpleBar forceVisible="y" autoHide={false}>
                      <Box width="100%">
                        {ReactHtmlParser(
                          DOMPurify.sanitize(stepsTemplates[step["id"]].body),
                          { transform: transformInput }
                        )}
                      </Box>
                    </StyledSimpleBar>
                  </Flex>
                </Flex>
              )}
            </Flex>
            <Flex width="100%">
              {!isIntegrationPresent(step) && (
                <Text
                  margin="40px 0 0 0"
                  color="red"
                  fontSize="0.7rem"
                  width="100%"
                  textAlign="left"
                >
                  No integration found for this step, please check!
                </Text>
              )}
            </Flex>
          </Flex>
        ))}
      </CardTabs>
    </Flex>
  );
};

export default PayloadEditor;
