import { combineEpics, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap, flatMap, map, mapTo } from "rxjs/operators";
import { toast } from "react-toastify";

import {
  INTEGRATIONS_GET,
  integrationsGet,
  integrationsGetSuccess,
  integrationsGetFailure,
  INTEGRATION_UPDATE,
  INTEGRATION_ALIASES_UPDATE,
  integrationUpdateSuccess,
  integrationUpdateFailure,
  INTEGRATION_DELETE,
  integrationDeleteSuccess,
  integrationDeleteFailure,
  INTEGRATION_TEST,
  integrationTestSuccess,
  integrationTestFailure,
  INTEGRATION_GET,
  integrationGetSuccess,
  integrationGetFailure,
} from "../actions";

const getIntegrationEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(INTEGRATION_GET),
    mergeMap(({ payload: { workspace_id, id } }) =>
      from(
        api
          .get(`/workspaces/${workspace_id}/providers/${id}`)
          .then((resp) => resp)
      ).pipe(
        map(integrationGetSuccess),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map(error => toast.error(error.detail));
          return of(integrationGetFailure({ err, errors }));
        })
      )
    )
  );

const getIntegrationsEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(INTEGRATIONS_GET),
    mergeMap(({ payload: { workspace_id } }) =>
      from(
        api.get(`/workspaces/${workspace_id}/providers`).then((resp) => resp)
      ).pipe(
        map(integrationsGetSuccess),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map(error => toast.error(error.detail));
          return of(integrationsGetFailure({ err, errors }));
        })
      )
    )
  );

const updateIntegrationAliasesEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(INTEGRATION_ALIASES_UPDATE),
    mergeMap(
      ({ payload: {id, aliases, workspace_id} }) => {
        return from(
          api
            .patch(`/workspaces/${workspace_id}/providers/${id}`, {
              data: {
                id: id,
                attributes: {
                  aliases
                },
              },
            })
            .then((resp) => resp)
        ).pipe(
          map(integrationUpdateSuccess),
          mapTo(integrationsGet({ workspace_id })),
          catchError((err) => {
            const errors = err.response ? err.response.data.errors : [];
            errors.map(error => toast.error(error.detail));
            return of(integrationUpdateFailure({ err, errors }));
          })
        );
      }
    )
  );

const deleteIntegrationEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(INTEGRATION_DELETE),
    flatMap(({ payload: { workspace_id, id } }) => {
      return from(
        api
          .delete(`/workspaces/${workspace_id}/providers/${id}`)
          .then((resp) => resp)
      ).pipe(
        map(integrationDeleteSuccess),
        mapTo(integrationsGet({ workspace_id })),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map(error => toast.error(error.detail));
          return of(integrationDeleteFailure({ err, errors }));
        })
      );
    })
  );

const testIntegrationEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(INTEGRATION_TEST),
    flatMap(({ payload: { workspace_id, id } }) => {
      return from(
        api
          .put(`/workspaces/${workspace_id}/providers/${id}/test`)
          .then((resp) => resp)
      ).pipe(
        map(integrationTestSuccess),
        mapTo(integrationsGet({ workspace_id })),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map(error => toast.error(error.detail));
          return of(integrationTestFailure({ err, errors }));
        })
      );
    })
  );

export default combineEpics(
  getIntegrationsEpic,
  updateIntegrationAliasesEpic,
  testIntegrationEpic,
  deleteIntegrationEpic,
  deleteIntegrationEpic,
  getIntegrationEpic
);
