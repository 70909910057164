import React from "react";
import styled from "styled-components";
import theme from "../../../theme";

import StatActive from "../icons/statActive";
import StatInactive from "../icons/statInactive";
import Waiting from "../icons/waiting";

const StyledText = styled.div`
  white-space: pre-line;
  margin: 5px;
  align-items: center;
  font-size: 0.9rem;
  display: flex;
  flex-direction: row;
  color: ${theme.colors.grey};
  font-family: ${theme.fonts[1]};
  font-weight: 200;

  ${({ pointer }) => pointer && "cursor: pointer;"}
  ${({ underlined }) => underlined && "text-decoration: underline;"}
`;

const StyledStatus = styled.div`
  height: 25px;
  width: 25px;
  margin-left: 15px;
`;

const StyledCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${theme.colors.lighterOrange};
  height: 25px;
  width: 25px;
  padding: 3px;

  &:hover {
    box-shadow: -2px 2px 2px lightgray;
    cursor: pointer;
  }
`;

const Status = ({ status, ...props }) => {
  return (
    <StyledText {...props}>
      {status === "active" ? "Active" : null}
      {status === "waiting" ? "Pending" : null}
      {status === "inactive" ? "Inactive" : null}
      <StyledStatus>
        {status === "active" ? <StatActive /> : null}
        {status === "waiting" ? (
          <StyledCircle>
            <Waiting variant="lightRed" />
          </StyledCircle>
        ) : null}
        {status === "inactive" ? <StatInactive /> : null}
      </StyledStatus>
    </StyledText>
  );
};

export default Status;
