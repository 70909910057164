import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import theme from "@theme";
import { NavLink } from "react-router-dom";
import * as R from "ramda";

//components
import Box from "@ui/box";
import Row from "@ui/row/index";
import Picture from "@ui/picture/index";
import Flex from "@ui/flex";
import Text from "@ui/text";
import Checkbox from "@ui/checkbox/index";
import Dropdown from "@ui/dropdown/index";
import RowMenu from "@ui/row-menu-button/index";
import InputSearch from "@ui/input-search/index";
import Image from "@assets/img/avatar.png";
import RowsPerPage from "@ui/rows-per-page/index";
import Pagination from "@ui/pagination/index";
import TableBar from "@ui/table-bar/index";
import SequenceStatus from "@ui/sequence-status/index";
import { ExportToProjectModal } from "@ui/modal";

//containers
import AddPeopleModalContainer from "@containers/people/AddPeopleModalContainer";

//icons
import Location from "@ui/icons/location";
import CalendarLast from "@ui/icons/calendarLast";
import Degree from "@ui/icons/degree";
import Mail from "@ui/icons/message";
import Phone from "@ui/icons/phone";
import Twitter from "@ui/icons/twitter";
import Linkedin from "@ui/icons/linkedin";
import Facebook from "@ui/icons/facebook";
import Reload from "@ui/icons/reload";
import ExportToProject from "@ui/icons/exportToProject";

//reducer
import { getProjects } from "@reducer";

//actions
import { peopleToProjectAssign } from "@actions";

//Helper
import { isArchivedProject } from "@utils/project-helper";
import { personLocation } from "@utils/people-helper";

const StyledNavLink = styled(NavLink)`
  cursor: pointer;
  margin: 0;
  text-decoration: none;
  outline: none;
  display: flex;
  width: 35%;
`;

const PersonInfo = styled(Text)`
  margin: 5px;
  align-items: center;
  font-size: 0.9rem;
  display: flex;
  flex-direction: row;
  color: ${theme.colors.gray};
  font-family: "Open Sans";
  flex-wrap: nowrap;
  cursor: pointer;

  &:hover {
    filter: drop-shadow(0px 0px 3px #caf0fc);
    color: ${theme.colors.darkBlue};
  }
`;

const TextLink = styled(Text)`
  font-family: "Open Sans";
  font-size: 1.1rem;
  padding-left: 3px;
  width: 100%;
  cursor: pointer;

  &:hover {
    filter: drop-shadow(0px 0px 3px #caf0fc);
    color: ${theme.colors.darkBlue};
  }
`;

const InfoBox = styled(Box)`
  padding: 0px 15px;
  font-family: "Open Sans";
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SocialLink = styled.a`
  height: 20px;
  width: 20px;
  margin: 0px 4px;
  text-decoration: none;
  color: ${theme.colors.blue};
`;

const StyledCircleOne = styled.div`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: white;
  border: 1px solid ${theme.colors.blue};
`;

const StyledCircleTwo = styled.div`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  background-color: ${theme.colors.blue};
`;

const StyledPaginationEmpty = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  font-weight: 200;
  margin-top: 50px;
`;

const ExportToProjectBtn = styled.div`
  height: 20px;
  width: 20px;
  margin-left: 15px;
  text-decoration: none;
`;

const ReloadBtn = styled.div`
  height: 20px;
  width: 20px;
  margin-left: 15px;
  text-decoration: none;
`;

const PeopleList = ({
  people,
  project,
  handleCheckbox,
  workspace,
  stageOptions,
  tableBarContent,
  rowMenuOptions,
  movePersonStatusHandler,
  handleChangeSearchBy,
  handleEnterSearchBy,
  searchBy,
  firstPage,
  lastPage,
  prevPage,
  nextPage,
  currentPage,
  totalPages,
  addPeopleBtn,
  asyncReload,
  peopleCheckboxStatus,
  getSequenceProcessor,
  totalSelected,
}) => {
  const dispatch = useDispatch();
  const projects = useSelector(getProjects);
  const [isExportActive, setIsExportActive] = useState(false);
  const [exportProjectId, setExportProjectId] = useState(false);

  const openExportToProject = () => {
    setIsExportActive(true);
  };

  const handleSelectProject = (value, id) => {
    setExportProjectId(id);
  };

  const closeExportToProjectModal = () => {
    setIsExportActive(false);
  };

  const projectsToExport = () => {
    let notCurrentProject = (p) => p.id != project.id;
    return R.filter(notCurrentProject, projects?.data);
  };

  const handleClick = () => {
    let selectedPeople = [];
    for (const id in peopleCheckboxStatus) {
      if (peopleCheckboxStatus[id]) {
        selectedPeople.push({ id: id });
      }
    }
    dispatch(
      peopleToProjectAssign({
        workspace_id: workspace,
        project_id: exportProjectId,
        people_ids: selectedPeople,
      })
    );
    closeExportToProjectModal();
  };

  return (
    <Flex
      marginTop="3%"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      backgroundColor={theme.colors.lighterGrey}
    >
      {isExportActive === true && (
        <ExportToProjectModal
          closeModal={closeExportToProjectModal}
          title="Export People"
          projects={projectsToExport()}
          assignItems={totalSelected}
          exportProjectId={exportProjectId}
          handleSelectProject={handleSelectProject}
          handleClick={handleClick}
        />
      )}
      {addPeopleBtn ? (
        <TableBar
          iconButtons={[
            <InputSearch
              key="search"
              onChange={handleChangeSearchBy}
              onEnter={handleEnterSearchBy}
              name="search_by"
              value={searchBy}
            />,
            !isArchivedProject(project) && <AddPeopleModalContainer
              addPeopleBtn
              key="modal"
              projectId={project.id}
            />,
            <ExportToProjectBtn onClick={openExportToProject} key="assign">
              < ExportToProject variant="blue" linkable />,
            </ExportToProjectBtn>,
            <ReloadBtn onClick={asyncReload} key="reload">
              <Reload variant="blue" linkable />
            </ReloadBtn>,
          ]}
          mainContent={tableBarContent}
        />
      ) : (
        <TableBar
          iconButtons={[
            <InputSearch
              key="search"
              onChange={handleChangeSearchBy}
              onEnter={handleEnterSearchBy}
              name="search_by"
              value={searchBy}
            />,
            <ReloadBtn onClick={asyncReload} key="reload">
              <Reload variant="blue" linkable />
            </ReloadBtn>,
          ]}
          mainContent={tableBarContent}
        />
      )}

      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="auto"
        backgroundColor="transparent"
      >
        {people.data?.map((person, index) => (
          <Row key={index}>
            {handleCheckbox && (
              <Checkbox
                top="-5px"
                margin="0px"
                padding="0px"
                shape="square"
                title="Select candidate"
                checked={peopleCheckboxStatus[person.attributes.id] || false}
                onChange={handleCheckbox}
                value={person.attributes.id}
              />
            )}
            <StyledNavLink
              to={
                workspace
                  ? `/workspaces/${workspace}/profile/${person.attributes.id}`
                  : `/profile/${person.attributes.id}`
              }
              style={{ textDecoration: "none" }}
            >
              <InfoBox>
                <Box>
                  <Picture
                    bgImage={person.attributes.avatar_url || Image}
                  ></Picture>
                </Box>
                <Box>
                  <TextLink nowrap title="To Profile">
                    {person.attributes["first_name"] +
                      " " +
                      person.attributes["last_name"]}
                  </TextLink>
                  <PersonInfo icon={<Location variant="blue" />}>
                    {personLocation(person)}
                  </PersonInfo>
                  <PersonInfo icon={<Phone variant="blue" />}>
                    {person.attributes["phone"]}
                  </PersonInfo>
                </Box>
              </InfoBox>
            </StyledNavLink>
            <Box width="30%">
              <PersonInfo icon={<CalendarLast variant="blue" />}>
                {person.attributes["company"]} - {person.attributes["title"]}
              </PersonInfo>
              <PersonInfo icon={<Degree variant="blue" />}>
                {person.attributes["degree"]}
              </PersonInfo>
              <PersonInfo icon={<Mail variant="blue" />}>
                {person.attributes["email"] || person.attributes["email_work"] || person.attributes["email_second"]}
              </PersonInfo>
            </Box>
            <Flex marginRight="1%" width="10%">
              <SocialLink
                target="_blank"
                href={person.attributes["linkedin"]}
                title={person.attributes["linkedin"]}
                hidden={!person.attributes["linkedin"]}
              >
                <Linkedin variant="blue" linkable />
              </SocialLink>

              <SocialLink
                target="_blank"
                href={person.attributes["twitter"]}
                title={person.attributes["twitter"]}
                hidden={!person.attributes["twitter"]}
              >
                <Twitter variant="blue" linkable />
              </SocialLink>

              <SocialLink
                target="_blank"
                href={person.attributes["facebook"]}
                title={person.attributes["facebook"]}
                hidden={!person.attributes["facebook"]}
              >
                <Facebook variant="blue" linkable />
              </SocialLink>
            </Flex>

            {project && (
              <Flex flexDirection="column" alignItems="flex-start" width="10%">
                <Flex alignItems="center">
                  <StyledCircleOne>
                    <StyledCircleTwo></StyledCircleTwo>
                  </StyledCircleOne>
                  <Dropdown
                    variant="blue"
                    movePersonStatusHandler={movePersonStatusHandler}
                    person={person}
                    name={person.project_person_id}
                    value={person["status"]}
                    placeholder={person["status"]}
                    options={stageOptions.map((opt) => opt.label)}
                    personId={person.attributes.id}
                    projectPersonId={person.project_person_id}
                    small
                    short
                    currentStage={person.relationships.stage.data.id}
                    disabled={isArchivedProject(project)}
                    padding={"0 5px"}
                    justifyContent={"end"}
                  />
                </Flex>

                {person.current_sequence_processor &&
                  <SequenceStatus
                    person={person}
                    project={project}
                    index={index}
                    workspace={workspace}
                    sequence={person.current_sequence_processor}
                  />
                }

              </Flex>
            )}
            {!isArchivedProject(project) && rowMenuOptions && (
              <RowMenu icon="menu" options={rowMenuOptions} resource={person} />
            )}
            {isArchivedProject(project) && (
              <Flex width="5%"></Flex>
            )}
          </Row>
        ))}
      </Flex>

      <Flex
        width="100%"
        justifyContent="flex-end"
        backgroundColor="transparent"
        marginTop="10px"
      >
        {totalPages > 0 ? (
          <>
            <RowsPerPage
              name="rowsperpage"
              placeholder="10"
              options={["10", "20", "30", "40", "50"]}
            />

            <Pagination
              firstPage={firstPage}
              lastPage={lastPage}
              prevPage={prevPage}
              nextPage={nextPage}
              people={people}
              currentPage={currentPage}
              totalPages={totalPages}
            />
          </>
        ) : (
          <StyledPaginationEmpty>No results</StyledPaginationEmpty>
        )}
      </Flex>
    </Flex>
  );
};

export default PeopleList;
