import React, { useEffect, useState } from "react";
import styled from "styled-components";
import theme from "../../../theme";

//Component
import Box from "../box";
import Text from "../text";
import Flex from "../flex";

import { delaySplitDHM, delayJoinDHM } from "../../../utils/date";

const StyledContainer = styled(Box)`
  position: relative;
  width: auto;
`;

const StyledRequired = styled.span`
  color: ${theme.colors.red};
  font-size: 1rem;
  padding-left: 4px;
`;

const StyledText = styled(Box)`
  font-size: 0.8rem;
  font-weight: 200;
  color: ${theme.colors.darkBlue};
  text-align: center;
`;

const StyledInput = styled.input`
  color: ${({ disabled, theme: { colors } }) => {
    if (disabled) {
      return theme.colors.lightGrey;
    }
    return theme.colors.grey;
  }};

  background: transparent;
  border: 0;
  text-align: center;
  font-family: ${theme.fonts[1]};
  font-size: 1rem;
  display: block;
  min-width: 15px;
  width: 100%;
  height: auto;
  padding-top: 6px;
  padding-bottom: 6px;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0;

  &:focus {
    outline: none;
  }

  ${({ disabled, error }) => (disabled || error ? `padding-right: 28px;` : 0)};

  &::-ms-clear {
    display: none;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const InputTimepicker = ({
  mb,
  mt,
  label,
  labelIntro,
  onChange,
  value,
  defaultValue,
  required,
  disabled,
  indexStep,
}) => {
  const [valueDays, setValueDays] = useState(0);
  const [valueHours, setValueHours] = useState(0);
  const [valueMinutes, setValueMinutes] = useState(0);
  const [val, setVal] = useState(defaultValue || value || 0);

  useEffect(() => {
    const [d, h, m] = delaySplitDHM(value || val);
    setValueDays(d);
    setValueHours(h);
    setValueMinutes(m);
  }, [val, value]);

  useEffect(() => {
    handleChange();
  }, [valueDays, valueHours, valueMinutes]);

  const handleChange = () => {
    onChange(delayJoinDHM(valueDays, valueHours, valueMinutes), indexStep);
  };

  const handleUpdateDays = (e) => {
    setValueDays(e.target.value);
  };

  const handleUpdateHours = (e) => {
    setValueHours(e.target.value);
  };

  const handleUpdateMinutes = (e) => {
    setValueMinutes(e.target.value);
  };

  return (
    <StyledContainer mb={mb} mt={mt}>
      {label && (
        <Text
          fontSize={theme.fontSizes[2]}
          fontFamily={theme.fonts[1]}
          fontWeight={0}
          mb={1}
          pt={2}
        >
          {label}
          {required ? <StyledRequired>*</StyledRequired> : null}
        </Text>
      )}
      <Flex
        alignItems="center"
        marginLeft="20px"
        marginTop="5px"
        flexWrap="wrap"
      >
        <Text marginRight="10px" color={theme.colors.darkBlue}>
          {labelIntro}
        </Text>
        <Box marginRight="20px">
          <Box width="45px" borderBottom={`1px solid ${theme.colors.blue}`}>
            <StyledInput
              pattern="[0-9]*"
              type="number"
              name={`steps.${indexStep}.delaydays`}
              disabled={disabled}
              value={valueDays}
              onChange={(e) => handleUpdateDays(e)}
              min="0"
            ></StyledInput>
          </Box>
          <StyledText>day/s</StyledText>
        </Box>
        <Box marginRight="20px">
          <Box width="45px" borderBottom={`1px solid ${theme.colors.blue}`}>
            <StyledInput
              type="number"
              min="0"
              max="23"
              pattern="[0-9]*"
              name={`steps.${indexStep}.delayhours`}
              disabled={disabled}
              value={valueHours}
              onChange={(e) => handleUpdateHours(e)}
            ></StyledInput>
          </Box>
          <StyledText>hour/s</StyledText>
        </Box>
        <Box>
          <Box width="45px" borderBottom={`1px solid ${theme.colors.blue}`}>
            <StyledInput
              type="number"
              min="0"
              max="59"
              pattern="[0-9]*"
              name={`steps.${indexStep}.delaymins`}
              value={valueMinutes}
              onChange={(e) => handleUpdateMinutes(e)}
              disabled={disabled}
            ></StyledInput>
          </Box>
          <StyledText>min/s</StyledText>
        </Box>
      </Flex>
    </StyledContainer>
  );
};

export default InputTimepicker;
