import React from "react";
// import styled from "styled-components";
import theme from "../../../theme";

import Box from "../../ui/box";
import Card from "../../ui/card";
import Image from "../../../assets/img/peopleDB.svg";
import Flex from "../../ui/flex";

const TalentPoolIntro = () => {
  return (
    <Flex
      marginTop={"64px"}
      alignItems={"center"}
      minHeight={"100vh"}
      width={"100%"}
      flexDirection={"column"}
      justifyContent={"flex-start"}
      px={{ sm: 2, md: 0 }}
      overflow="hidden"
      backgroundColor={theme.colors.lighterGrey}
    >
      <Flex
        mt={"30px"}
        flexDirection={"column"}
        alignItems={"center"}
        width={"95%"}
        backgroundColor={theme.colors.lighterGrey}
      >
        <Card
          imageBlock={Image}
          textBlock={
            <Box>
              <p
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                In Talent Pool you can keep and manage the candidates you
                source,
                <br /> it belongs to your user so you will never lose their
                profiles.
              </p>
              <p
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                You can add these people to any project you are working on,
                saving search time.
              </p>
              <p
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                Every time you use the chrome extension to add candidates to any
                project, <br />
                that person will automatically be added to your talent pool as
                well.
              </p>
              {/* <p
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                You can import people through a .csv file
              </p>
              <
                defaultValue=""
                mb={4}
                mt={2}
                placeholder="Select file .CSV"
                button={
                  <Button
                    variant="secondaryLight"
                    content="Search"
                    size="small"
                  ></Button>
                }
              />
              <p
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                Or using our
                <a
                  href="/"
                  style={{
                    fontSize: "1.05rem",
                    color: theme.colors.red,
                    borderBottom: `1px solid ${theme.colors.red}`,
                    marginLeft: "10px",
                  }}
                >
                  Chrome extension
                </a>
              </p> */}
            </Box>
          }
        ></Card>
      </Flex>
    </Flex>
  );
};

export default TalentPoolIntro;
