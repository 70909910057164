import React from "react";
import styled from "styled-components";
import theme from "../../../theme";

//sidebar
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.css";

const StyledCard = styled.div`
  display: flex;
  background-color: white;
  ${(props) =>
    props.control
      ? "justify-content: flex-end;"
      : "justify-content: space-between;"}
  flex-direction: row;
  flex-flow: wrap;
  box-shadow: 0px 4px 4px #8e8e8e50;

  ${(props) =>
    props.small
      ? "width: 25%; margin-bottom: 20px; margin-left: 5px; margin-right:5px; "
      : props.noMargin
      ? "width: 100%; margin: 0px;"
      : "width: 100%;  margin: 20px 0px;"}
`;

const StyledSimpleBar = styled(SimpleBar)`
  max-height: 60vh;
  width: 100%;
  margin-top: 15px;
  margin-right: 5px;

  .simplebar-scrollbar:before {
    background: ${theme.colors.grey};
  }
`;

const CardHeader = styled.div`
  width: 100%;
  height: ${(props) => (props.smallTitle ? "60px" : "70px")};
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: center;
  justify-content: space-between;
`;

const CardContent = styled.div`
  width: ${(props) => (props.formContent ? "auto" : "100%")};
  margin: 15px;
  height: auto;
  display: flex;
  flex-flow: wrap;
  ${(props) =>
    props.column
      ? "flex-direction: column; justify-content: flex-start"
      : "flex-direction: row; justify-content: space-evenly; align-items: center;"};
`;

const CardText = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
`;

const CardImage = styled.div`
  width: 30%;
  height: 300px;
  display: flex;
  background-image: url(${(props) => props.image});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const CardFooter = styled.div`
  width: 100%;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: center;
  align-self: flex-end;
  justify-content: flex-end;
  padding: 10px 20px;
`;

const CardDivider = styled.div`
  width: 100%;
  height: 15px;
  background: rgb(201, 201, 201);
  background: linear-gradient(
    180deg,
    rgba(231, 231, 231, 1) 0%,
    rgba(255, 255, 255, 1) 70%
  );
`;

const CardControl = styled.div`
  width: 35px;
  height: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: flex-end;

  :hover {
    cursor: pointer;
  }
`;

const CardTitle = styled.div`
  ${(props) => (props.full ? "width: 100%;" : "width: 70%;")};
  height: 100%;
  margin-left: 20px;
  ${(props) =>
    props.small || props.smallTitle
      ? "font-size: 1.1rem;"
      : "font-size: 1.4rem;"};
  font-weight: 300;
  color: gray;
  text-transform: uppercase;
  vertical-align: middle;
  display: flex;
  align-items: center;
`;

const CardButtonsFooter = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: center;
  justify-content: flex-end;
`;

const CardTextWarning = styled.p`
  font-size: 1rem;
  font-weight: 300;
  color: ${theme.colors.lightGrey};
  padding: 0px 20px;
`;

const CardTextButton = styled.a`
  text-decoration: none;
  color: ${theme.colors.red};
  padding: 0px 10px;
  :hover {
    cursor: pointer;
    text-shadow: 0px 0px 5px ${theme.colors.lightOrange};
  }
`;

const StyledForm = styled.form`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
`;

const Card = ({
  small,
  smallTitle,
  formContent,
  modal,
  onSubmit,
  open,
  closeHandler,
  header,
  collapse,
  footer,
  control,
  fullHeader,
  children,
  title,
  textButton,
  buttons,
  icon,
  textBlock,
  imageBlock,
  textWarning,
  column,
  full,
  noMargin,
}) => {
  return (
    <StyledCard
      small={small}
      control={control}
      open={open}
      collapse={collapse}
      noMargin={noMargin}
    >
      {header && title ? (
        <CardHeader smallTitle={smallTitle}>
          <CardTitle full={full} small={small} smallTitle={smallTitle}>
            {title}
          </CardTitle>
          <CardDivider></CardDivider>
        </CardHeader>
      ) : null}

      {collapse ? (
        <CardHeader>
          <CardTitle>{title}</CardTitle>
          <CardControl onClick={closeHandler}>{icon}</CardControl>
        </CardHeader>
      ) : null}

      {control ? (
        <CardControl onClick={closeHandler}>{icon}</CardControl>
      ) : null}

      {fullHeader ? (
        <CardHeader smallTitle={smallTitle}>
          <CardTitle full={full} small={small} smallTitle={smallTitle}>
            {title}
          </CardTitle>
          <CardControl onClick={closeHandler}>{icon}</CardControl>
          <CardDivider />
        </CardHeader>
      ) : null}

      {formContent ? (
        <StyledForm onSubmit={onSubmit}>
          <StyledSimpleBar forceVisible="y" autoHide={false}>
            <CardContent formContent={formContent} column={column}>
              {children}
            </CardContent>
          </StyledSimpleBar>
          <CardFooter>
            {textWarning && <CardTextWarning>{textWarning}</CardTextWarning>}
            {buttons && <CardButtonsFooter>{buttons}</CardButtonsFooter>}
            {textButton && <CardTextButton>{textButton}</CardTextButton>}
          </CardFooter>
        </StyledForm>
      ) : modal ? (
        <CardContent column={column}>
          <StyledSimpleBar forceVisible="y" autoHide={false}>
            {children}
          </StyledSimpleBar>
        </CardContent>
      ) : (
        <CardContent column={column}>
          {children}
          {textBlock ? <CardText>{textBlock}</CardText> : null}
          {imageBlock ? <CardImage image={imageBlock}></CardImage> : null}
        </CardContent>
      )}

      {footer ? (
        <CardFooter>
          {textWarning && <CardTextWarning>{textWarning}</CardTextWarning>}
          {buttons && <CardButtonsFooter>{buttons}</CardButtonsFooter>}
          {textButton && <CardTextButton>{textButton}</CardTextButton>}
        </CardFooter>
      ) : null}
    </StyledCard>
  );
};

export default Card;
