import React from "react";
import theme from "../../../theme";
import styled from "styled-components";

//components
import Box from "../../ui/box";
import Row from "../../ui/row/index";
import Flex from "../../ui/flex";
import Text from "../../ui/text";

//icons
import Cancel from "../../ui/icons/close";
import Accept from "../../ui/icons/valid";

const StyledButton = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    * svg {
      filter: drop-shadow(0px 0px 2px #cfcfcf);
    }

    span {
      text-shadow: 0px 0px 2px #cfcfcf;
    }
  }
`;

const StyledIconBtn = styled.div`
  width: 20px;
  height: 20px;
`;

const StyledTextBtn = styled.span`
  font-family: "Open Sans";
  font-size: 0.9rem;
  padding-top: 5px;
  color: ${(props) =>
    props.reject ? theme.colors.lightRed : theme.colors.blue};
`;

const Invitations = ({
  invitations,
  handleAccept,
  handleReject,
  getInvitationText,
  errorMsg,
}) => {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      {errorMsg !== "" && (
        <Text
          color={theme.colors.red}
          fontFamily="Open Sans"
          fontSize="0.75rem"
        >
          {errorMsg}
        </Text>
      )}
      {invitations?.data.map((invitation, index) => (
        <Row flat key={invitation.id}>
          <Box flex="column" width="60%" paddingLeft="15px">
            <Text
              color={theme.colors.blue}
              fontFamily="Open Sans"
              fontSize="1rem"
            >
              {getInvitationText(invitation)}
            </Text>
          </Box>
          <Flex width="20%">
            <Box width="50%">
              <StyledButton onClick={() => handleReject(invitation)}>
                <StyledIconBtn>
                  <Cancel variant="lightRed" />
                </StyledIconBtn>
                <StyledTextBtn reject>Reject</StyledTextBtn>
              </StyledButton>
            </Box>

            <Box width="50%">
              <StyledButton onClick={() => handleAccept(invitation)}>
                <StyledIconBtn>
                  <Accept variant="blue" />
                </StyledIconBtn>
                <StyledTextBtn>Accept</StyledTextBtn>
              </StyledButton>
            </Box>
          </Flex>
        </Row>
      ))}
    </Flex>
  );
};

export default Invitations;
