import React from "react";
import styled from "styled-components";

// Components
import Close from "../icons/close";
import Card from "../card/index";
import CardTabsModal from "../cardTabsModal/index";

const StyledBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999;
`;

const StyledCardContainer = styled.div`
  position: fixed;
  background: transparent;
  height: 95vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;

  ${(props) => (props.cardTabs ? "width: 85%;" : "width: 80%;")};
  ${(props) => (props.shortWidth ? "width: 85%;" : "")}
`;

const Modal = ({ cardTabs, shortWidth, ...props }) => {
  return (
    <StyledBackground>
      <StyledCardContainer cardTabs={cardTabs} shortWidth={shortWidth}>
        {cardTabs ? (
          <CardTabsModal
            fullHeader
            icon={<Close variant="grey"></Close>}
            {...props}
          />
        ) : (
          <Card fullHeader icon={<Close variant="grey"></Close>} {...props} />
        )}
      </StyledCardContainer>
    </StyledBackground>
  );
};

export default Modal;
