import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import * as R from "ramda";

import {
  getAppWorkspaces,
  getWorkspaceCurrent,
  getCurrentUser,
} from "../reducer";

const useWorkspace = () => {
  const workspaces = useSelector(getAppWorkspaces);
  const currentWorkspace = useSelector(getWorkspaceCurrent);
  const userId = useSelector(getCurrentUser).id;

  const [visiblesWorkspaces, setVisiblesWorkspaces] = useState([]);
  const [workspacesByAccess, setWorkspacesByAccess] = useState([]);

  useEffect(() => {
    if (workspaces) {
      let visibles = getVisiblesWorkspaces(workspaces);
      setVisiblesWorkspaces(visibles);
      setWorkspacesByAccess(getWorkspacesByAccess(visibles));
    }
  }, [workspaces]);

  const getVisiblesWorkspaces = (workspaces) => {
    const isVisible = w => w.attributes.visible === true;
    return R.filter(isVisible, workspaces);
  };

  const getWorkspacesByAccess = (workspaces) => {
    let result = {
      owner: [],
      guest: [],
    };

    workspaces.forEach((workspace) => {
      if (workspace.attributes.user_id === userId) {
        result.owner.push(workspace);
      } else {
        result.guest.push(workspace);
      }
    });

    return result;
  };

  return [
    workspaces,
    currentWorkspace,
    visiblesWorkspaces,
    workspacesByAccess,
  ];
};

export default useWorkspace;
