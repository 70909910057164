import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.linkable
        ? `&:hover {
      fill: ${theme.colors.darkBlue}; cursor: pointer;}`
        : prop.reconnect
        ? `&:hover {
      fill: ${theme.colors.lightRed}; cursor: pointer;}`
        : ""}

    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black)" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const Reload = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <path
        d="M21.6,12.5c-0.2-1.1-1.3-1.7-2.3-1.5c-1.1,0.2-1.7,1.3-1.5,2.3c0.5,2.5-0.5,5.1-2.7,6.5c-1.4,0.9-3,1.2-4.6,0.8
	c-1.6-0.3-3-1.3-3.9-2.7c-0.9-1.4-1.2-3-0.8-4.6c0.3-1.6,1.3-3,2.7-3.9c1.1-0.7,2.3-1,3.6-0.9L11.5,9c-0.8,0.7-0.9,2-0.1,2.8
	c0.4,0.4,0.9,0.6,1.5,0.6c0.5,0,1-0.2,1.3-0.5l4.6-4.2c0.4-0.4,0.7-0.9,0.6-1.5c0-0.6-0.3-1.1-0.8-1.5l-4.9-3.9
	c-0.9-0.7-2.1-0.5-2.8,0.3c-0.7,0.9-0.5,2.1,0.3,2.8l0.9,0.7c-2.1-0.1-4.2,0.4-6,1.6C4,7.6,2.5,9.8,1.9,12.5
	c-0.6,2.6-0.1,5.3,1.4,7.6c1.5,2.3,3.7,3.8,6.3,4.4c0.7,0.2,1.4,0.2,2.2,0.2c1.9,0,3.8-0.5,5.4-1.6C20.7,20.8,22.5,16.5,21.6,12.5z"
      />
    </StyledSvg>
  );
};

export default Reload;
