import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black)" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const CalendarLast = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <g>
        <path
          d="M21.7,3.4h-1V1.7c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8v1.8H5.8V1.7c0-0.4-0.3-0.8-0.8-0.8S4.3,1.2,4.3,1.7
		v1.8h-1c-1.5,0-2.8,1.2-2.8,2.8v15.2c0,1.5,1.2,2.8,2.8,2.8h18.5c1.5,0,2.8-1.2,2.8-2.8V6.2C24.5,4.6,23.3,3.4,21.7,3.4z M3.3,4.9
		h1v2.2c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8V4.9h13.5v2.2c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8V4.9h1c0.7,0,1.3,0.6,1.3,1.3
		v2.3H2V6.2C2,5.5,2.6,4.9,3.3,4.9z M21.7,22.6H3.3C2.6,22.6,2,22,2,21.3V9.9h21v11.4C23,22,22.4,22.6,21.7,22.6z"
        />
        <path
          d="M16.9,11.6l-5.6,6.9l-3.1-2.7c-0.3-0.3-0.8-0.2-1.1,0.1c-0.3,0.3-0.2,0.8,0.1,1.1l3.7,3.2
		c0.1,0.1,0.3,0.2,0.5,0.2c0,0,0,0,0.1,0c0.2,0,0.4-0.1,0.5-0.3l6.1-7.5c0.3-0.3,0.2-0.8-0.1-1.1C17.7,11.3,17.2,11.3,16.9,11.6z"
        />
      </g>
    </StyledSvg>
  );
};

export default CalendarLast;
