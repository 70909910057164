import { combineEpics } from "redux-observable";
import { catchError } from 'rxjs/operators';

import authEpic from "./auth";
import appEpic from "./app";
import integrationsEpic from "./integrations";
import invitationsEpic from "./invitations";
import messagesEpic from "./messages";
import peopleEpic from "./people";
import projectsEpic from "./projects";
import projectPeopleEpic from "./project-people";
import projectPeoplePayloadsEpic from "./project-people-payloads";
import projectUsersEpic from "./project-users";
import sequencesEpic from "./sequences";
import workflowsEpic from "./workflows";
import workspacesEpic from "./workspaces";

export default (action$, store$, dependencies) => combineEpics(
  authEpic,
  appEpic,
  integrationsEpic,
  invitationsEpic,
  messagesEpic,
  peopleEpic,
  projectsEpic,
  projectPeopleEpic,
  projectPeoplePayloadsEpic,
  projectUsersEpic,
  sequencesEpic,
  workflowsEpic,
  workspacesEpic
)(action$, store$, dependencies).pipe(
  // Global catchError
  //Uncaught errors can bubble up to the root epic and
  //cause the entire stream to terminate. Adding `catchError`
  //to alleviate this issue
  catchError((error, source) => {
    return source;
  })
);
