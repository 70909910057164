import { combineActions, handleActions } from "redux-actions";
import { lensProp, pipe, set, view } from "ramda";
import { getRelated } from "../utils";

import {
  onExpiredToken,
  sequencesGet,
  sequencesGetSuccess,
  sequencesGetFailure,
} from "../actions";

const initialState = {
  inError: false,
  loading: false,
  token: localStorage.getItem("access-token"),
  data: [],
  included: [],
  links: {},
  meta: {},
};

const isLoadingLens = lensProp("loading");
const inErrorLens = lensProp("inError");
const tokenLens = lensProp("token");
const dataLens = lensProp("data");
const includedLens = lensProp("included");
const linksLens = lensProp("links");
const metaLens = lensProp("meta");

const setData = set(dataLens);
const setIncluded = set(includedLens);
const setLinks = set(linksLens);
const setMeta = set(metaLens);

export const getIsLoading = view(isLoadingLens);
export const getInError = view(inErrorLens);
export const getToken = view(tokenLens);

export default handleActions(
  {
    [sequencesGetSuccess]: (state, actions) =>
      pipe(
        setData(actions.payload.data.data),
        setIncluded(actions.payload.data.included),
        setLinks(actions.payload.data.links.nav),
        setMeta(actions.payload.data.meta),
        set(inErrorLens, false),
        set(isLoadingLens, false)
      )(state),

    [sequencesGetFailure]: (state) =>
      pipe(set(inErrorLens, true), set(isLoadingLens, false))(state),

    [sequencesGet]: (state) =>
      pipe(set(inErrorLens, false), set(isLoadingLens, true))(state),

    // handle expired token
    [combineActions(onExpiredToken)]: (state) => {
      return { ...initialState };
    },
  },
  initialState
);

export const getSequences = (state) => state.sequences;
export const getSequencesData = (state) => state.sequences.data;
export const getSequencesIncluded = (state) => state.sequences.included;

export const getSequencesSteps = (sequence) => (state) => {
  const relatedSteps = getRelated(
    sequence.relationships.steps.data,
    state.sequences.included
  );
  return relatedSteps;
};

export const getSequencesLinks = (state) => state.sequences.links;
export const getSequenceCurrentPage = (state) =>
  state.sequences.meta?.pages?.current;
export const getSequenceTotalPages = (state) =>
  state.sequences.meta?.pages?.total;
