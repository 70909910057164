const colorPalette = {
  white: "#ffffff",

  lighterGrey: "#F2F2F2",
  lightGrey: "#ABABAB",
  grey: "#585858",
  grey2: "#515050",
  darkGrey: "#2F2F2F",

  lighterBlue: "#E5F8FE",
  lightBlue: "#EFF7FF",
  lightBlue2: "#CAF0FC",
  lightBlueTertiary: "#D7E7F8",
  blue: "#4BAAC8",
  blueTertiary: "#145DA0",
  darkBlue: "#1B7B99",
  darkerBlue: "#264550",

  lightRed: "#F8997B",
  red: "#F45C2C",

  lighterOrange: "#FFEFE5",
  lightOrange: "#FFCFB1",
  orange: "#FFB07C",
  orange2: "#F45C2C",
  orange3: "#E94E1B",
  redGradient:
    "linear-gradient(180deg, rgba(244,92,44,1) 0%, rgba(248,153,123,1) 100%)",
};

const theme = {
  navbar: {
    height: "64px",
  },
  sidebar: {
    width: "60px",
  },
  breakpoints: ["0px", "600px", "1439px", "1800px"],
  breakpointInts: {
    sm: 0,
    md: 999,
    lg: 1439,
    xl: 1800,
  },
  borders: [0, "1px solid", "2px solid"],
  colors: colorPalette,
  colorStyles: {
    modal: {
      backgroundColor: "rgba(0,0,0,0.5)",
    },
  },
  fonts: ["Rubik", "Open Sans", "Roboto"],
  fontSizes: [10, 12, 14, 16, 18, 20, 22],
  fontWeights: [100, 300, 500, 600, 800],
  lineHeights: [1, 1.43],
  radii: [0, "4px", "8px", "16px", "32px", "50%"],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  textStyles: {
    underline: {
      textDecoration: "underline",
    },
    overflow: {
      textOverflow: "ellipsis",
    },
    preline: {
      whiteSpace: "pre-line",
    },
    noUnderline: {
      textDecoration: "none",
    },
  },
};

theme.breakpoints.sm = theme.breakpoints[0];
theme.breakpoints.md = theme.breakpoints[1];
theme.breakpoints.lg = theme.breakpoints[2];
theme.breakpoints.xl = theme.breakpoints[3];

export default theme;
