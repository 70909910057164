import React from "react";
import theme from "../../../theme";
import { Link } from "react-router-dom";
import styled from "styled-components";

import Modal from "@ui/modal/Modal";
import Box from "@ui/box";
import Flex from "@ui/flex";
import Text from "@ui/text/";
import Image from "@assets/img/people.svg";
import Button from "@ui/button/index";
import InputButton from "@ui/input-import-csv";
import DropdownId from "@ui/dropdown-with-id/index";
import Spinner from "@components/ui/spinner";

//Icons
import Close from "@ui/icons/close";
import Add from "@ui/icons/add";

const StyledIconBtn = styled.div`
  height: 20px;
  width: 20px;
  margin-left: 15px;

  &:hover {
    cursor: pointer;
  }
`;

const StyledItemName = styled.div`
  font-size: 1.1rem;
  display: flex;
  color: ${theme.colors.lightRed};
  font-weight: 400;
  margin: 5px 0px;
`;

const AddPeopleModal = ({
  location,
  addPeopleBtn,
  importPeopleBtn,
  handleCloseModal,
  handleInputChange,
  handleSubmitClick,
  setShowModal,
  showModal,
  selectedFile,
  showImportData,
  importData,
  stages,
  handleStage,
  isLoadingImport,
  errorExcessPeople,
  projectId,
}) => {
  return (
    <>
      {isLoadingImport ? <Spinner/> : null}

      {addPeopleBtn ? (
        <StyledIconBtn>
          <Add variant="blue" onClick={() => setShowModal(true)} linkable />
        </StyledIconBtn>
      ) : importPeopleBtn ? (
        <Button
          onClick={() => setShowModal(true)}
          size="small"
          variant="secondaryLight"
          content="Import People"
        ></Button>
      ) : (
        <Button
          onClick={() => setShowModal(true)}
          size="small"
          variant="primaryLight"
          content="People"
          icon={<Add variant="white"></Add>}
        ></Button>
      )}

      {showModal === true && (
        <Modal
          fullHeader
          title="Add People"
          icon={<Close variant="grey"></Close>}
          closeHandler={handleCloseModal}
          footer
          buttons={[
            <Button
              key="Import"
              variant={"secondary"}
              disabled={errorExcessPeople || selectedFile === undefined}
              content="Import"
              onClick={handleSubmitClick}
            />,
          ]}
          imageBlock={Image}
          textBlock={
            <>
              <Box width="100%">
                {location === "/talent_pool" ? (
                  <p
                    style={{
                      fontSize: "1.1rem",
                      fontWeight: "300",
                      lineHeight: "1.5rem",
                    }}
                  >
                    Add people in your Talent Pool easily importing them through
                    a CSV file, or through our chrome extension while searching
                    on Linkedin.
                  </p>
                ) : (
                  <p
                    style={{
                      fontSize: "1.1rem",
                      fontWeight: "300",
                      lineHeight: "1.5rem",
                    }}
                  >
                    You can import people through a .csv file
                  </p>
                )}
                <Flex width="100%">
                  <Box width="80%">
                    <InputButton
                      full
                      disabled={false}
                      mb={4}
                      mt={2}
                      variant="secondaryLight"
                      value={selectedFile}
                      handleChange={handleInputChange}
                      error={errorExcessPeople}
                      errorMsg="Limit of candidates must be less of 400"
                    />
                  </Box>
                </Flex>
                {projectId && (
                  <Flex width="100%">
                    <Box width="80%">
                      <DropdownId
                        formStyle
                        name="stage"
                        label="Import on stage"
                        placeholder="Select a Workflow Stage"
                        handleDropdown={handleStage}
                        disabled={selectedFile == null}
                        options={stages?.map((stage) => ({
                          id: stage.id,
                          val: stage.attributes.name,
                        }))}
                        variant="blue"
                        small
                        short
                      />
                    </Box>
                  </Flex>
                )}
                {showImportData && (
                  <>
                    <Flex fontSize="1rem" fontWeight="200" paddingTop="20px">
                      Import Results:
                    </Flex>
                    <Flex width="90%" alignItems="center" justifyContent>
                      <Flex
                        flexDirection="column"
                        justifyContent="space-evenly"
                        height="100px"
                        margin="10px 20px 10px 10px"
                      >
                        <Flex alignItems="center">
                          <Text paddingRight="10px">New Candidates:</Text>
                          <StyledItemName>{importData.new}</StyledItemName>
                        </Flex>
                        <Flex alignItems="center">
                          <Text paddingRight="10px">Assigned Candidates:</Text>
                          <StyledItemName>{importData.assigned}</StyledItemName>
                        </Flex>
                        <Flex alignItems="center">
                          <Text paddingRight="10px">Existing Candidates:</Text>
                          <StyledItemName>{importData.existing}</StyledItemName>
                        </Flex>
                      </Flex>
                      <Flex
                        flexDirection="column"
                        justifyContent="space-evenly"
                        height="100px"
                        margin="10px 10px 10px 20px"
                      >
                        <Flex alignItems="center">
                          <Text paddingRight="10px">Errors:</Text>
                          <StyledItemName>{importData.errors}</StyledItemName>
                        </Flex>
                        <Flex alignItems="center">
                          <Text paddingRight="10px">Success:</Text>
                          <StyledItemName>{importData.success}</StyledItemName>
                        </Flex>
                        <Flex alignItems="center">
                          <Text paddingRight="10px">Total Candidates:</Text>
                          <StyledItemName>{importData.total}</StyledItemName>
                        </Flex>
                      </Flex>
                    </Flex>
                  </>
                )}
                {location !== "/talent_pool" ? (
                  <>
                    <p
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "300",
                        lineHeight: "1.5rem",
                      }}
                    >
                      Go to your
                      <Link
                        to={"/talent_pool"}
                        style={{
                          fontSize: "1.2rem",
                          textDecoration: "none",
                          color: theme.colors.lightRed,
                          borderBottom: `1px solid ${theme.colors.lightRed}`,
                          marginLeft: "10px",
                        }}
                      >
                        Talent Pool
                      </Link>
                    </p>
                    <p
                      style={{
                        fontSize: "1rem",
                        fontWeight: "300",
                        lineHeight: "1.5rem",
                      }}
                    >
                      Or get our Chrome extension
                    </p>
                  </>
                ) : null}
              </Box>
            </>
          }
        ></Modal>
      )}
    </>
  );
};

export default AddPeopleModal;
