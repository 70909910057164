import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactTooltip from "react-tooltip";

//Component
import Spinner from "@components/ui/spinner";
import { Project } from "@components/domain/projects";

//Actions
import {
  currentWorkspaceSet,
  workspaceGet,
  workflowGet,
  projectGet,
  sequencesGet,
  projectUsersGet,
} from "@actions";

import {
  getProjectAccessList,
  getCurrentProject,
  getProjects,
  getWorkspaceCurrent,
  getProjectUsersData,
} from "@reducer";

const ProjectContainer = ({ viewport, viewHeight, ...props }) => {
  const dispatch = useDispatch();
  const workspace = useSelector(getWorkspaceCurrent);
  const projects = useSelector(getProjects);
  const project = useSelector(getCurrentProject);
  const currentAccessList = useSelector(getProjectUsersData);

  useEffect(() => {
    dispatch(
      currentWorkspaceSet({ workspace_id: props.match.params.workspace_id })
    );
    dispatch(workspaceGet({ id: props.match.params.workspace_id }));
    dispatch(
      projectGet({
        workspace_id: props.match.params.workspace_id,
        id: props.match.params.id,
      })
    );
    dispatch(
      sequencesGet({
        workspace_id: props.match.params.workspace_id,
        limit: 9999,
      })
    );
  }, [props.match.params.workspace_id, props.match.params.id]);

  useEffect(() => {
    const workflowId = project && project.attributes?.workflow_id;
    if (workflowId) {
      dispatch(workflowGet({ workspace_id: workspace, id: workflowId }));
    }
  }, [workspace, project]);

  useEffect(() => {
    if (project.relationships && projects) {
      const accessList = getProjectAccessList(project, projects);
      dispatch(projectUsersGet(accessList));
    }
  }, [project]);

  return projects.loading || !project.id ? (
    <Spinner />
  ) : (
    <>
      <Project
        viewHeight={viewHeight}
        viewport={viewport}
        project={project}
        workspace={workspace}
        workflowId={project.attributes.workflow_id}
        projectAccessList={currentAccessList}
      />
      <ReactTooltip />
    </>
  );
};

export default ProjectContainer;
