import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Integrations } from "../../components/domain/integrations";

import {
  integrationsGet,
  integrationDelete,
  integrationTest,
  currentWorkspaceSet,
} from "../../actions";

import { getIntegrations } from "../../reducer";

const IntegrationsContainer = ({ viewport, ...props }) => {
  const integrations = useSelector(getIntegrations);
  const [workspaceId, setWorkspaceId] = useState("");
  const [isRemoveActive, setIsRemoveActive] = useState(false);
  const [isEditActive, setIsEditActive] = useState(false);
  const [itemSelected, setItemSelected] = useState();
  const dispatch = useDispatch();

  const newCredentialHandler = (provider) => {
    const origin =
      "&origin=" +
      process.env.REACT_APP_BASE_URL +
      `/workspaces/${workspaceId}/integrations&`;

    const redirect_uri =
      "&redirect_uri=" +
      process.env.REACT_APP_API_BASE_URL +
      `/auth/${provider}/callback`;

    const oauthEndpoint =
      process.env.REACT_APP_API_BASE_URL +
      `/auth/${provider}?` +
      "option=provider" +
      origin +
      redirect_uri;

    window.location.href = oauthEndpoint;
  };

  const removeIntegrationHandler = (integration) => {
    dispatch(
      integrationDelete({
        workspace_id: integration.attributes.workspace_id,
        id: integration.id,
      })
    );
    setIsRemoveActive(false);
  };

  const testIntegrationHandler = (integration) => {
    dispatch(
      integrationTest({
        workspace_id: integration.attributes.workspace_id,
        id: integration.id,
      })
    );
  };

  const removeModalHandler = (item) => {
    setIsRemoveActive(true);
    setItemSelected(item);
  };

  const closeRemoveModalHandler = () => {
    setIsRemoveActive(false);
  };

  const editModalHandler = (item) => {
    setIsEditActive(true);
    setItemSelected(item);
  }

  const closeEditModalHandler = () => {
    setIsEditActive(false);
  };

  const rowMenuOptions = [
    {
      name: "Add Alias",
      alert: editModalHandler,
    },
    {
      name: "Remove",
      alert: removeModalHandler,
    },
    {
      name: "Test",
      callback: testIntegrationHandler,
    },
  ];

  useEffect(() => {
    dispatch(currentWorkspaceSet({ workspace_id: props.match.params.id }));
    dispatch(integrationsGet({ workspace_id: props.match.params.id }));
  }, [props.match.params.id]);

  useEffect(() => {
    setWorkspaceId(props.match.params.id);
  }, [props.match.params.id]);

  return (
    <Integrations
      viewport={viewport}
      integrations={integrations}
      newCredentialHandler={newCredentialHandler}
      rowMenuOptions={rowMenuOptions}
      isRemoveActive={isRemoveActive}
      isEditActive={isEditActive}
      itemSelected={itemSelected}
      closeRemoveModalHandler={closeRemoveModalHandler}
      closeEditModalHandler={closeEditModalHandler}
      removeIntegrationHandler={removeIntegrationHandler}
    />
  );
};

export default IntegrationsContainer;
