import { combineActions, handleActions } from "redux-actions";
import { lensProp, pipe, set, view } from "ramda";
import {
  projectGet,
  projectGetSuccess,
  projectGetFailure,
  projectsGet,
  projectsGetSuccess,
  projectsGetFailure,
  projectCreate,
  currentProjectSet,
  currentProjectClean,
  onExpiredToken,
  projectAnalyticsGetSuccess
} from "../actions";

const initialState = {
  inError: false,
  loading: false,
  token: localStorage.getItem("access-token"),
  data: [],
  included: [],
  current: {},
  analytics: [],
};

const isLoadingLens = lensProp("loading");
const inErrorLens = lensProp("inError");
const tokenLens = lensProp("token");
const dataLens = lensProp("data");
const includedLens = lensProp("included");
const currentLens = lensProp("current");
const analyticsLens = lensProp("analytics");

const setData = set(dataLens);
const setIncluded = set(includedLens);
const setAnalytics = set(analyticsLens);

export const getIsLoading = view(isLoadingLens);
export const getInError = view(inErrorLens);
export const getToken = view(tokenLens);

export default handleActions(
  {
    // Get 1 project
    [projectGetSuccess]: (state, action) =>
      pipe(
        set(currentLens, action.payload.data.data),
        setIncluded(action.payload.data.included),
        set(inErrorLens, false),
        set(isLoadingLens, false)
      )(state),
    [projectGetFailure]: (state) =>
      pipe(set(inErrorLens, true), set(isLoadingLens, false))(state),
    [projectGet]: (state) =>
      pipe(set(inErrorLens, false), set(isLoadingLens, true))(state),

    // Get all
    [projectsGetSuccess]: (state, action) =>
      pipe(
        setData(action.payload.data.data),
        setIncluded(action.payload.data.included),
        set(isLoadingLens, false)
      )(state),
    [projectsGetFailure]: (state) =>
      pipe(set(inErrorLens, true), set(isLoadingLens, false))(state),
    [projectsGet]: (state) =>
      pipe(
        setData([]),
        setIncluded([]),
        setAnalytics([]),
        set(inErrorLens, false),
        set(isLoadingLens, true),
      )(state),

    // Create
    [projectCreate]: (state, action) =>
      pipe(set(inErrorLens, false), set(isLoadingLens, true))(state),
    [currentProjectSet]: (state, action) =>
      pipe(set(currentLens, action.payload.project_id))(state),
    [currentProjectClean]: (state, action) => pipe(set(currentLens, {}))(state),
    // handle expired token
    [combineActions(onExpiredToken)]: (state) => {
      return { ...initialState };
    },
    // project analýtics
    [projectAnalyticsGetSuccess]: (state, action) =>
      pipe(setAnalytics(action.payload.data))(state),
  },
  initialState
);

export const getProjectAnalytics = (state) => state.projects.analytics;
export const getProjectsLoading = (state) => state.projects.loading;