import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { useEffect } from "react";
import { signInSet } from "../../actions";
import { toast } from "react-toastify";

import {
  getAuthInError,
  getAuthError,
  getAuthLoading,
  getIsUserLoaded,
} from "../../reducer";

import { SignIn } from "../../components/domain/account";

import Spinner from "../../components/ui/spinner";

const SignInContainer = (props) => {
  const dispatch = useDispatch();
  const redirectPath = "/workspaces/me/projects";
  const isUserLoaded = useSelector(getIsUserLoaded);
  const inError = useSelector(getAuthInError);
  const errorMsg = useSelector(getAuthError);
  const loading = useSelector(getAuthLoading);

  const handleSubmitClick = (form) => {
    dispatch(
      signInSet({
        email: form.email,
        password: form.password,
        redirectPath,
      })
    );
  };

  useEffect(() => {
    if (isUserLoaded)
      dispatch(push("/"));
    if (JSON.parse(sessionStorage.getItem("isExpired"))) {
      toast.error("Session expired.");
      sessionStorage.setItem("isExpired", false);
    }
  }, [isUserLoaded]);

  return (
    <>
      {loading ? <Spinner /> : null}
      <SignIn
        errorMessage={errorMsg}
        inError={inError}
        handleSubmitClick={handleSubmitClick}
      />
    </>
  );
};

export default SignInContainer;
