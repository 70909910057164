import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black)" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const PeopleGroup = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <path
        d="M22.7,11.9c-0.5-0.5-1.2-1-1.8-1.3c0.1-0.1,0.3-0.2,0.4-0.3c0.8-0.8,1.3-1.8,1.3-3c0-1.1-0.4-2.2-1.2-3
	c-0.8-0.8-1.9-1.2-3-1.3c0,0,0,0-0.1,0c-1.1,0-2.2,0.4-3,1.2c-0.1,0.1-0.2,0.3-0.3,0.4c-0.7-0.3-1.4-0.5-2.1-0.5c0,0,0,0-0.1,0
	c-0.8,0-1.6,0.2-2.3,0.5C9.7,3.7,8.5,2.9,7,2.9c-1.2,0-2.2,0.4-3,1.2c-0.8,0.8-1.3,1.8-1.3,3c0,1.3,0.6,2.5,1.6,3.3
	c-2.2,1-3.8,3.1-3.8,5.7c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.2l4.6,0.1c-0.9,1.3-1.3,2.7-1.3,4.3c0,0.4,0.3,0.8,0.7,0.8
	l14.7,0.2c0,0,0,0,0,0c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5c0-1.6-0.4-3-1.2-4.3l4.3,0.1c0,0,0,0,0,0
	c0.4,0,0.7-0.3,0.8-0.7C24.5,14.7,23.9,13.1,22.7,11.9z M16.3,5.4c0.5-0.5,1.2-0.8,1.9-0.8c0,0,0,0,0,0c0.7,0,1.4,0.3,2,0.8
	C20.7,6,21,6.6,21,7.4v0c0,0.7-0.3,1.4-0.8,1.9c-0.5,0.5-1.2,0.8-2,0.8c-0.1,0-0.1,0-0.2,0c0-0.1,0-0.3,0.1-0.4
	c0-1.4-0.5-2.7-1.5-3.7c-0.1-0.1-0.2-0.2-0.4-0.3C16.2,5.5,16.3,5.4,16.3,5.4z M12.8,5.7c1,0,2,0.4,2.7,1.1c0.7,0.7,1.1,1.7,1.1,2.7
	c0,2.1-1.7,3.7-3.8,3.7c0,0,0,0,0,0c-1,0-2-0.4-2.7-1.1c-0.7-0.7-1.1-1.7-1.1-2.7C8.9,7.4,10.6,5.7,12.8,5.7
	C12.7,5.7,12.8,5.7,12.8,5.7z M5,5.2C5.6,4.7,6.3,4.4,7,4.4c0,0,0,0,0,0c0.9,0,1.7,0.5,2.2,1.1c-1.1,1-1.8,2.3-1.8,3.9
	c0,0.1,0,0.2,0,0.3c-0.2,0-0.3,0.1-0.5,0c-1.5,0-2.8-1.2-2.7-2.7C4.2,6.4,4.5,5.7,5,5.2z M6.9,11.3C6.9,11.3,6.9,11.3,6.9,11.3
	c0.3,0,0.6,0,0.9,0.1c0.3,0.7,0.6,1.3,1.2,1.8c0.2,0.2,0.5,0.4,0.8,0.6c-0.9,0.4-1.8,0.9-2.5,1.6l-5.1-0.1C2.5,13,4.5,11.3,6.9,11.3
	z M6,20.4C6.2,19,6.9,17.6,8,16.6c1.2-1.2,2.9-1.8,4.6-1.8c0,0,0.1,0,0.1,0c3.4,0,6.1,2.6,6.5,5.8L6,20.4z M18.3,15.6
	c-0.7-0.7-1.6-1.3-2.6-1.7c0.9-0.6,1.5-1.4,2-2.3c0.2,0,0.4,0,0.5,0c1.3,0,2.5,0.5,3.4,1.4c0.7,0.7,1.2,1.6,1.3,2.6L18.3,15.6z"
      />
    </StyledSvg>
  );
};

export default PeopleGroup;
