import React from "react";
import styled from "styled-components";
import { variant, flex, layout } from "styled-system";
import theme from "../../../theme";

// Components
import Box from "../box";

const StyledContainer = styled(Box)`
  position: relative;
  width: auto;
  background-color: transparent;
  ${({ onClick }) => onClick && `cursor: pointer;`}
  ${flex}
  ${layout}
`;

const StyledAvatar = styled("div")(
  (props) => ({
    display: "inline-block",
    verticalAlign: "middle",
    textTransform: "uppercase",
    borderRadius: "50%",
    margin: "5px",
    background: props.bgCustom,
    backgroundImage: `url(${props.bgImage})`,
    backgroundSize: "cover",
    color: theme.colors.white,

    "&:hover": props.noLink
      ? {
          boxShadow: "none",
          cursor: "default",
        }
      : {
          boxShadow: "-2px 2px 2px lightgray",
          cursor: "pointer",
        },
  }),

  variant({
    prop: "size",
    variants: {
      big: {
        width: "35px",
        height: "35px",
      },
      small: {
        width: "30px",
        height: "30px",
      },
    },
  }),

  variant({
    prop: "role",
    variants: {
      owner: {
        "::after": {
          content: "'O'",
          display: "inline-block",
          height: "20px",
          width: "20px",
          background:
            "linear-gradient(130deg, rgba(63, 177, 213, 1) 0%, rgba(47, 133, 160, 1) 100%)",
          borderRadius: "50%",
          position: "relative",
          top: "-15px",
          left: "14px",
          zIndex: 9,
          textAlign: "center",
          fontSize: "0.75rem",
          lineHeight: "1.3rem",
        },
      },
      manager: {
        "::after": {
          content: "'M'",
          display: "inline-block",
          height: "20px",
          width: "20px",
          background:
            "linear-gradient(130deg, rgba(63, 177, 213, 1) 0%, rgba(47, 133, 160, 1) 100%)",
          borderRadius: "50%",
          position: "relative",
          top: "-15px",
          left: "14px",
          zIndex: 9,
          textAlign: "center",
          fontSize: "0.75rem",
          lineHeight: "1.3rem",
        },
      },
      collaborator: {
        "::after": {
          content: "'C'",
          display: "inline-block",
          height: "20px",
          width: "20px",
          background:
            "linear-gradient(130deg, rgba(63, 177, 213, 1) 0%, rgba(47, 133, 160, 1) 100%)",
          borderRadius: "50%",
          position: "relative",
          top: "-15px",
          left: "14px",
          zIndex: 9,
          textAlign: "center",
          fontSize: "0.75rem",
          lineHeight: "1.3rem",
        },
      },
      waiting: {
        "::after": {
          content: "'...'",
          background:
            "linear-gradient(130deg, rgba(63, 177, 213, 1) 0%, rgba(47, 133, 160, 1) 100%)",
          display: "inline-block",
          height: "20px",
          width: "20px",
          borderRadius: "50%",
          position: "relative",
          top: "-15px",
          left: "14px",
          zIndex: 9,
          textAlign: "center",
          fontSize: "0.75rem",
          lineHeight: "1rem",
        },
      },
    },
  }),

  variant({
    prop: "misc",
    variants: {
      alert: {
        "::after": {
          content: "''",
          display: "inline-block",
          border: "2px solid #ffffff",
          height: "8px",
          width: "8px",
          background:
            "linear-gradient(130deg, rgba(255,97,9,1) 0%, rgba(160,47,47,1) 100%)",
          padding: "4px",
          borderRadius: "50%",
          position: "relative",
          top: "-40px",
          left: "25px",
          zIndex: 9,
        },
      },
      message: {
        "::after": {
          content: "''",
          display: "inline-block",
          border: "2px solid #ffffff",
          height: "8px",
          width: "8px",
          background:
            "linear-gradient(130deg, rgba(63, 177, 213, 1) 0%, rgba(47, 133, 160, 1) 100%)",
          padding: "4px",
          borderRadius: "50%",
          position: "relative",
          top: "-40px",
          left: "25px",
          zIndex: 9,
        },
      },
    },
  }),

  variant({
    variants: {
      blue: {
        // background: "rgb(63, 177, 213)",
        background:
          "linear-gradient(130deg, rgba(63, 177, 213, 1) 0%, rgba(47, 133, 160, 1) 100%)",
      },
      yellow: {
        // background: "rgb(251,237,0)",
        background:
          "linear-gradient(130deg, rgba(251,237,0,1) 0%, rgba(255,163,0,1) 100%)",
      },
      violet: {
        // background: "rgb(194,122,191)",
        background:
          "linear-gradient(130deg, rgba(194,122,191,1) 0%, rgba(123,12,141,1) 100%)",
      },

      red: {
        // background: "rgb(255,97,9)",
        background:
          "linear-gradient(130deg, rgba(255,97,9,1) 0%, rgba(160,47,47,1) 100%)",
      },
      green: {
        // background: "rgb(153,217,41)",
        background:
          "linear-gradient(130deg, rgba(153,217,41,1) 0%, rgba(47,160,91,1) 100%)",
      },
      pink: {
        // background: "rgb(240,152,228)",
        background:
          "linear-gradient(130deg, rgba(240,152,228,1) 0%, rgba(177,24,147,1) 100%)",
      },
      disabled: {
        color: theme.colors.lighterGrey,
        // background: "rgb(198,198,198)",
        background:
          "linear-gradient(130deg, rgba(198,198,198,1) 0%, rgba(148,148,148,1) 100%)",

        "&:hover": { cursor: "not-allowed", boxShadow: "none" },
      },
    },
  })
);

const StyledContent = styled("div")`
  height: inherit;
  width: inherit;
  display: flex;
  font-size: 0.9rem;
  font-weight: 300;
  justify-content: center;
  align-items: center;
`;

const Avatar = ({
  size,
  content,
  variant,
  notification,
  misc,
  role,
  bgCustom,
  bgImage,
  onClick,
  title,
  noLink,
  ...props
}) => {
  return (
    <StyledContainer onClick={onClick} {...props}>
      <StyledAvatar
        noLink={noLink}
        title={title}
        size={size}
        variant={variant}
        notification={notification}
        role={role}
        misc={misc}
        bgCustom={bgCustom}
        bgImage={bgImage}
      >
        {!bgImage ? <StyledContent>{content}</StyledContent> : null}
      </StyledAvatar>
    </StyledContainer>
  );
};

export default Avatar;
