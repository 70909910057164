import React, { useEffect } from "react";
import theme from "../../../theme";

//components
import Box from "../../ui/box";
import Flex from "../../ui/flex";
import SubNavbar from "../../ui/sub-navbar";
import SequencesList from "./SequencesList";

import ReactTooltip from "react-tooltip";

//containers
import NewSequenceModal from "../../../containers/sequences/NewSequenceModalContainer";

const Sequences = ({
  viewport,
  sequences,
  credentials,
  steps,
  removeSequenceHandler,
  testSequenceHandler,
  workspaceId,
  firstPage,
  lastPage,
  prevPage,
  nextPage,
  currentPage,
  totalPages,
}) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [credentials]);

  return (
    <Flex
      paddingTop="64px"
      alignItems="center"
      height="auto"
      width="100%"
      flexDirection="column"
      justifyContent="flex-start"
      backgroundColor={theme.colors.lighterGrey}
    >
      <Box width="inherit">
        <SubNavbar
          buttons
          actionButtons={<NewSequenceModal showButton />}
        ></SubNavbar>
      </Box>

      <SequencesList
        steps={steps}
        viewport={viewport}
        sequences={sequences}
        credentials={credentials}
        removeSequenceHandler={removeSequenceHandler}
        testSequenceHandler={testSequenceHandler}
        workspaceId={workspaceId}
        firstPage={firstPage}
        lastPage={lastPage}
        prevPage={prevPage}
        nextPage={nextPage}
        currentPage={currentPage}
        totalPages={totalPages}
      />
      <ReactTooltip />
    </Flex>
  );
};

export default Sequences;
