import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black)" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const Degree = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <path
        className="st0"
        d="M24.4,8L12.9,1.7c-0.2-0.1-0.5-0.1-0.7,0L0.6,8C0.4,8.2,0.2,8.4,0.2,8.7s0.1,0.5,0.4,0.7l2.5,1.4v7
	c0,0.2,0.1,0.3,0.2,0.5c0.1,0.2,3.2,4.1,9.2,4.1c3.7,0,6.2-1.4,7.7-2.6c0,0,0.1,0,0.1,0.1l-0.8,3.3c-0.1,0.4,0.1,0.8,0.5,0.9
	c0.4,0.1,0.8-0.1,0.9-0.5l0.6-2.2l0.6,2.2c0.1,0.3,0.4,0.6,0.7,0.6c0.1,0,0.1,0,0.2,0c0.4-0.1,0.6-0.5,0.5-0.9l-0.8-3.3
	c0.9-0.6,1.5-1.7,1.5-3.1c0-1.3-0.9-3-1.9-3.6v-2.5l2.2-1.2c0.2-0.1,0.4-0.4,0.4-0.7S24.6,8.2,24.4,8z M4.6,17.5v-5.9l7.5,4.2
	c0.1,0.1,0.2,0.1,0.4,0.1s0.2,0,0.4-0.1l7.8-4.3v1.7c-1,0.7-1.9,2.4-1.9,3.6c0,0.7,0.2,1.3,0.4,1.8c-1.3,1-3.5,2.2-6.7,2.2
	C7.9,20.8,5.3,18.2,4.6,17.5z M21.5,18.6c-0.5,0-1.2-0.9-1.2-1.9c0-0.9,0.8-2.1,1.2-2.4c0.4,0.3,1.2,1.4,1.2,2.4
	C22.6,17.8,22,18.6,21.5,18.6z M21.2,9.4l-7.4-1.7c-0.4-0.1-0.8,0.2-0.9,0.6c-0.1,0.4,0.2,0.8,0.6,0.9l5.8,1.4l-6.8,3.7l-10-5.5
	l10-5.5l10,5.5L21.2,9.4z"
      />
    </StyledSvg>
  );
};

export default Degree;
