import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
  ${(prop) =>
    prop.landing
      ? `&:hover {
        fill: ${theme.colors.lightBlue}; cursor: pointer;}`
      : ""}
    ${(prop) =>
      prop.linkable
        ? `&:hover {
      fill: ${theme.colors.lightRed}; cursor: pointer;}`
        : ""}
    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black)" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const Linkedin = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <path
        d="M9.09,7.05c0,0.79-0.63,1.43-1.42,1.43S6.26,7.84,6.26,7.05c0-0.79,0.63-1.43,1.42-1.43S9.09,6.26,9.09,7.05z
	 M9.1,9.62H6.25v9.13H9.1V9.62z M13.66,9.62h-2.84v9.13h2.84v-4.79c0-2.66,3.44-2.88,3.44,0v4.79h2.85v-5.78
	c0-4.5-5.09-4.33-6.29-2.12C13.66,10.85,13.66,9.62,13.66,9.62z"
      />
      <path
        d="M12.5,24.5c-6.62,0-12-5.38-12-12c0-6.62,5.38-12,12-12c6.62,0,12,5.38,12,12C24.5,19.12,19.12,24.5,12.5,24.5z
	 M12.5,2C6.71,2,2,6.71,2,12.5C2,18.29,6.71,23,12.5,23C18.29,23,23,18.29,23,12.5C23,6.71,18.29,2,12.5,2z"
      />
    </StyledSvg>
  );
};

export default Linkedin;
