import React from "react";
import styled from "styled-components";
import theme from "../../../theme";

import Box from "../../ui/box";
import SubNavbarTabs from "../../ui/sub-navbar-tabs";
import Row from "../../ui/row";
import Flex from "../../ui/flex";
import Text from "../../ui/text";
import RowMenu from "../../ui/row-menu-button";
import ModalRemove from "../../ui/modal-remove";
import { EditWorkspaceModal } from "../workspaces";
import Toggle from "../../ui/toggle-switch";

//icons
import Goal from "../../ui/icons/goal";
import Project from "../../ui/icons/project";

const TextLink = styled(Text)`
  font-family: "Open Sans";
  color: ${(props) => (props.color ? props.color : theme.colors.blue)};
  font-size: 1.2rem;
  padding-left: 3px;
  width: 100%;

  * &:hover {
    cursor: default;
  }
`;

const AccountSettings = ({
  workspaces,
  ownWorkspaces,
  guestWorkspaces,
  rowOwnerMenuOptions,
  rowGuestMenuOptions,
  activeWorkspace,
  isEditActive,
  closeEditModalHandler,
  editWorkspaceHandler,
  handleInputChange,
  handleSubmitClick,
  dirtyChanges,
  isRemoveActive,
  isLeaveActive,
  closeRemoveModalHandler,
  closeLeaveModalHandler,
  itemToRemove,
  removeWorkspaceHandler,
  viewport,
  handleVisibility,
}) => {
  return (
    <Flex
      paddingTop={"64px"}
      alignItems={"center"}
      width={"100%"}
      minHeight={"100vh"}
      // overflow={"hidden"}
      flexDirection={"column"}
      justifyContent={"flex-start"}
      px={{ sm: 2, md: 0 }}
      overflow="auto"
      backgroundColor={theme.colors.lighterGrey}
    >
      {isEditActive && (
        <EditWorkspaceModal
          workspace={activeWorkspace}
          dirtyChanges={dirtyChanges}
          handleSubmitClick={handleSubmitClick}
          handleChange={handleInputChange}
          showModal={isEditActive}
          closeModal={() => closeEditModalHandler()}
        />
      )}
      {isRemoveActive && (
        <ModalRemove
          modalType="remove"
          removeItem={itemToRemove}
          title="Remove Workspace"
          handleRemoveClick={removeWorkspaceHandler}
          closeModal={closeRemoveModalHandler}
        />
      )}

      {isLeaveActive && (
        <ModalRemove
          modalType="leave"
          removeItem={itemToRemove}
          title="Leave Workspace"
          handleRemoveClick={removeWorkspaceHandler}
          closeModal={closeLeaveModalHandler}
        />
      )}
      <SubNavbarTabs labels={["Workspaces" /*  "Notifications", "Payments" */]}>
        <Flex
          marginTop="3%"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width={viewport > 2000 ? "85%" : "95%"}
          backgroundColor={theme.colors.lighterGrey}
        >
          {ownWorkspaces?.map((workspace, index) => (
            <Row key={index}>
              <Box flex="column" width="20%" paddingLeft="15px">
                <TextLink
                  color={theme.colors.blue}
                  fontFamily="Open Sans"
                  fontSize="1.2rem"
                >
                  {workspace.attributes.name}
                </TextLink>
              </Box>
              <Box width="20%">
                <Text
                  margin="5px"
                  alignItems="center"
                  fontSize="1"
                  display="flex"
                  flexDirection="row"
                  icon={<Goal variant="blue" />}
                  color={theme.colors.gray}
                  fontFamily="Open Sans"
                >
                  {workspace.attributes.description}
                </Text>
              </Box>
              <Box width="20%">
                <Text
                  margin="5px"
                  alignItems="center"
                  fontSize="1"
                  display="flex"
                  flexDirection="row"
                  icon={<Project variant="blue" size="small" />}
                  color={theme.colors.gray}
                  fontFamily="Open Sans"
                >
                  Total Projects: {workspace.relationships.projects.data.length}
                </Text>
              </Box>
              <Box width="20%">
                <Toggle
                  customeLabel="Visible"
                  initialValue={workspace.attributes.visible}
                  handleChange={() => handleVisibility(!workspace.attributes.visible, workspace.id)}
                  name="visible"
                />
              </Box>
              <RowMenu
                icon="config"
                options={rowOwnerMenuOptions}
                resource={workspace}
              />
            </Row>
          ))}
          <Flex
            marginTop="3%"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
            backgroundColor={theme.colors.lighterGrey}
          >
            {guestWorkspaces?.map((workspace, index) => (
              <Row key={index}>
                <Box flex="column" width="20%" paddingLeft="15px">
                  <TextLink
                    color={theme.colors.darkBlue}
                    fontFamily="Open Sans"
                    fontSize="1.2rem"
                  >
                    {workspace.attributes.name}
                  </TextLink>
                </Box>
                <Box width="20%">
                  <Text
                    margin="5px"
                    alignItems="center"
                    fontSize="1"
                    display="flex"
                    flexDirection="row"
                    icon={<Goal variant="blue" />}
                    color={theme.colors.gray}
                    fontFamily="Open Sans"
                  >
                    {workspace.attributes.description}
                  </Text>
                </Box>
                <Box width="20%">
                  <Text
                    margin="5px"
                    alignItems="center"
                    fontSize="1"
                    display="flex"
                    flexDirection="row"
                    icon={<Project variant="blue" />}
                    color={theme.colors.gray}
                    fontFamily="Open Sans"
                  >
                    Projects: {workspace.relationships.projects.data.length}
                  </Text>
                </Box>
                <Box width="20%">
                  <Toggle
                    customeLabel="Visible"
                    initialValue={workspace.attributes.visible}
                    handleChange={() => handleVisibility(!workspace.attributes.visible, workspace.id)}
                    name="visible"
                  />
                </Box>
                <RowMenu
                  icon="config"
                  options={rowGuestMenuOptions}
                  resource={workspace}
                />
              </Row>
            ))}
          </Flex>
        </Flex>

        <Flex
          marginTop="3%"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
          backgroundColor={theme.colors.lighterGrey}
        >
          {/*  Notifications
          <p>
            space where the user chooses the notifications he wants to receive
            (a list with on / off switch){" "}
          </p> */}
        </Flex>
        <Flex
          marginTop="3%"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
          backgroundColor={theme.colors.lighterGrey}
        >
          {/*   Payments
          <p>
            space where the payments made by the user in the different
            workspaces are displayed
          </p>
          +<p>hint about upgrades</p> */}
        </Flex>
      </SubNavbarTabs>
    </Flex>
  );
};

export default AccountSettings;
