import { createActions } from "redux-actions";

export const APP_MOUNT = "APP_MOUNT";
export const APP_DATA_SUCCESS = "APP_DATA_SUCCESS";
export const APP_DATA_FAILURE = "APP_DATA_FAILURE";
export const APP_WORKSPACES_LOAD = "APP_WORKSPACES_LOAD";
export const APP_INVITATIONS_LOAD = "APP_INVITATIONS_LOAD";
export const ON_APP_START = "ON_APP_START";
export const APP_WORKFLOWS_FILTER_UPDATE = "APP_WORKFLOWS_FILTER_UPDATE";
export const APP_WORKFLOWS_FILTER_SUCCESS = "APP_WORKFLOWS_FILTER_SUCCESS";
export const APP_UNMOUNT_WORKFLOWS_FILTER = "APP_UNMOUNT_WORKFLOWS_FILTER";
export const APP_UNMOUNT_WORKFLOWS_FILTER_SUCCESS = "APP_UNMOUNT_WORKFLOWS_FILTER_SUCCESS";

export const {
  appMount,
  appDataSuccess,
  appDataFailure,
  appWorkspacesLoad,
  appInvitationsLoad,
  onAppStart,
  appWorkflowsFilterUpdate,
  appWorkflowsFilterSuccess,
  appUnmountWorkflowsFilter,
  appUnmountWorkflowsFilterSuccess
} = createActions(
  {},
  APP_MOUNT,
  APP_DATA_SUCCESS,
  APP_DATA_FAILURE,
  APP_WORKSPACES_LOAD,
  APP_INVITATIONS_LOAD,
  ON_APP_START,
  APP_WORKFLOWS_FILTER_UPDATE,
  APP_WORKFLOWS_FILTER_SUCCESS,
  APP_UNMOUNT_WORKFLOWS_FILTER,
  APP_UNMOUNT_WORKFLOWS_FILTER_SUCCESS,
);
