import { createActions } from "redux-actions";

// sign in actions
export const PROJECT_USER_UPDATE = "PROJECT_USER_UPDATE";
export const PROJECT_USER_UPDATE_SUCCESS = "PROJECT_USER_UPDATE_SUCCESS";
export const PROJECT_USER_UPDATE_FAILURE = "PROJECT_USER_UPDATE_FAILURE";

export const PROJECT_USER_DELETE = "PROJECT_USER_DELETE";
export const PROJECT_USER_DELETE_SUCCESS = "PROJECT_USER_DELETE_SUCCESS";
export const PROJECT_USER_DELETE_FAILURE = "PROJECT_USER_DELETE_FAILURE";

export const PROJECT_USERS_GET = "PROJECT_USERS_GET";

export const {
  projectUserUpdate,
  projectUserUpdateSuccess,
  projectUserUpdateFailure,
  projectUserDelete,
  projectUserDeleteSuccess,
  projectUserDeleteFailure,
  projectUsersGet,
} = createActions(
  {},
  PROJECT_USER_UPDATE,
  PROJECT_USER_UPDATE_SUCCESS,
  PROJECT_USER_UPDATE_FAILURE,
  PROJECT_USER_DELETE,
  PROJECT_USER_DELETE_SUCCESS,
  PROJECT_USER_DELETE_FAILURE,
  PROJECT_USERS_GET
);
