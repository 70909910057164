import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.linkable
        ? `&:hover {
      fill: ${theme.colors.lightRed}; cursor: pointer;}`
        : prop.blueLinkable
        ? `&:hover {
      fill: ${theme.colors.darkBlue}; cursor: pointer;}`
        : prop.disabled
        ? "filter: drop-shadow(-2px 2px 3px black);"
        : ""};
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);
const Export = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <g>
        <path
          d="M22.8,12.8l-3.4-3.4c-0.3-0.3-0.8-0.3-1.1,0s-0.3,0.8,0,1.1l2.1,2.1h-9.7c-0.4,0-0.8,0.3-0.8,0.8
        s0.3,0.8,0.8,0.8h9.7l-2.1,2.1c-0.3,0.3-0.3,0.8,0,1.1c0.1,0.1,0.3,0.2,0.5,0.2s0.4-0.1,0.5-0.2l3.4-3.4C23,13.5,23,13.1,22.8,12.8
        z"
        />
        <path
          d="M16.5,16.9c-0.4,0-0.8,0.3-0.8,0.8V19c0,0.7-0.6,1.4-1.4,1.4H4.9c-0.7,0-1.4-0.6-1.4-1.4V9.9h2.8
        c1.3,0,2.4-1.1,2.4-2.4V4.7h5.7c0.7,0,1.4,0.6,1.4,1.4V8c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8V6c0-1.6-1.3-2.9-2.9-2.9H7.2
        C7,3.2,6.8,3.3,6.6,3.4L2.2,7.9C2.1,8.1,2,8.2,2,8.4V19c0,1.6,1.3,2.9,2.9,2.9h9.6c1.6,0,2.9-1.3,2.9-2.9v-1.3
        C17.3,17.2,17,16.9,16.5,16.9z M7.2,4.9v2.6c0,0.5-0.4,0.9-0.9,0.9H3.9L7.2,4.9z"
        />
      </g>
    </StyledSvg>
  );
};
export default Export;
