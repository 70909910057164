import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.linkable
        ? `&:hover {
      filter: drop-shadow(-1px 1px 1px #4BAAC8); cursor: pointer;}`
        : ""}

    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black);" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

export const FollowUpLine = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <g>
        <path
          d="M16.2,5.2c-0.2-0.1-0.5,0-0.6,0.2c0,0-2.9,2.9-6.1,2.9H4.2c-0.5-0.1-1,0.4-1,0.9v5.4c0,0.5,0.5,1,1,1h1.5
		c0,0,0.5,3.4,0.6,3.7c0.1,0.3,0.3,0.5,0.6,0.5c0.3,0,1.8,0,2.1,0c0.3,0,0.6-0.4,0.5-0.7c-0.1-0.3-0.6-3.5-0.6-3.5h0.6
		c3.1,0,6.1,2.9,6.1,2.9c0.1,0.1,0.4,0.2,0.6,0.1c0.2-0.1,0.4-0.3,0.4-0.5V5.7C16.6,5.4,16.4,5.3,16.2,5.2z M8.4,18.9H7.5l-0.6-3.3
		h0.8L8.4,18.9z M15.4,16.8c-1.2-1-3.5-2.4-5.9-2.4H8.2H6.1H4.4V9.3h5.1c2.5,0,4.7-1.5,5.9-2.4V16.8z"
        />
        <path
          d="M18.2,9.2l2-1c0.3-0.2,0.3-0.5,0.2-0.8C20.3,7.2,20,7,19.7,7.2l-2,1c-0.3,0.2-0.3,0.5-0.2,0.8
		S18.1,9.3,18.2,9.2z"
        />
        <path
          d="M20.3,15.5l-2-1c-0.3-0.2-0.6-0.1-0.8,0.2c-0.2,0.3,0,0.6,0.2,0.8l2,1c0.1,0.1,0.5,0.1,0.7-0.2
		S20.5,15.6,20.3,15.5z"
        />
        <path
          d="M21.1,11.3H18c-0.3,0-0.6,0.2-0.6,0.6c0,0.4,0.2,0.6,0.6,0.6h3.1c0.3,0,0.6-0.2,0.6-0.6
		C21.7,11.6,21.4,11.3,21.1,11.3z"
        />
      </g>
      <path
        d="M12.5,1.3c6.2,0,11.2,5,11.2,11.2s-5,11.2-11.2,11.2s-11.2-5-11.2-11.2S6.3,1.3,12.5,1.3 M12.5,0
	C5.6,0,0,5.6,0,12.5S5.6,25,12.5,25S25,19.4,25,12.5S19.4,0,12.5,0L12.5,0z"
      />
    </StyledSvg>
  );
};

export const FollowUpSolid = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <path
        fill="#FFEFE5"
        d="M12.5,1.3c-6.2,0-11.2,5-11.2,11.2s5,11.2,11.2,11.2s11.2-5,11.2-11.2S18.7,1.3,12.5,1.3L12.5,1.3z"
      />
      <g>
        <path
          fill="#1B7B99"
          d="M16.2,5.2c-0.2-0.1-0.5,0-0.6,0.2c0,0-2.9,2.9-6.1,2.9H4.2c-0.5-0.1-1,0.4-1,0.9v5.4c0,0.5,0.5,1,1,1h1.5
		c0,0,0.5,3.4,0.6,3.7c0.1,0.3,0.3,0.5,0.6,0.5c0.3,0,1.8,0,2.1,0c0.3,0,0.6-0.4,0.5-0.7c-0.1-0.3-0.6-3.5-0.6-3.5h0.6
		c3.1,0,6.1,2.9,6.1,2.9c0.1,0.1,0.4,0.2,0.6,0.1c0.2-0.1,0.4-0.3,0.4-0.5V5.7C16.6,5.4,16.4,5.3,16.2,5.2z M8.4,18.9H7.5l-0.6-3.3
		h0.9L8.4,18.9z M15.4,16.8c-1.2-1-3.5-2.4-5.9-2.4H8.2H6.1H4.4V9.3h5.1c2.5,0,4.7-1.5,5.9-2.4V16.8z"
        />
        <path
          fill="#1B7B99"
          d="M18.2,9.2l2-1c0.3-0.2,0.3-0.5,0.2-0.8S20,7,19.7,7.2l-2,1c-0.3,0.2-0.3,0.5-0.2,0.8
		C17.6,9.3,18.1,9.3,18.2,9.2z"
        />
        <path
          fill="#1B7B99"
          d="M20.3,15.5l-2-1c-0.3-0.2-0.6-0.1-0.8,0.2c-0.2,0.3,0,0.6,0.2,0.8l2,1c0.1,0.1,0.5,0.1,0.7-0.2
		S20.5,15.6,20.3,15.5z"
        />
        <path
          fill="#1B7B99"
          d="M21.1,11.3H18c-0.3,0-0.6,0.2-0.6,0.6c0,0.4,0.2,0.6,0.6,0.6h3.1c0.3,0,0.6-0.2,0.6-0.6
		C21.7,11.6,21.4,11.3,21.1,11.3z"
        />
      </g>
      <path
        fill="#1B7B99"
        d="M12.5,1.3c6.2,0,11.2,5,11.2,11.2s-5,11.2-11.2,11.2s-11.2-5-11.2-11.2S6.3,1.3,12.5,1.3 M12.5,0
	C5.6,0,0,5.6,0,12.5S5.6,25,12.5,25S25,19.4,25,12.5S19.4,0,12.5,0L12.5,0z"
      />
    </StyledSvg>
  );
};
