import React from "react";
import styled from "styled-components";
import theme from "../../../theme";
import moment from "moment-timezone";

//Component
import Input from "../input/index";
import DatePicker from "react-datepicker";
import Box from "../box";
import Text from "../text";

//stylesheet
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import "./datepicker-style.css";

//Icon
import Calendar from "../icons/calendar";

const StyledContainer = styled(Box)`
  position: relative;
  width: auto;
`;

const StyledRequired = styled.span`
  color: ${theme.colors.red};
  font-size: 1rem;
  padding-left: 4px;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 52px;
`;

window.x = moment;

const InputDatepicker = ({
  mb,
  mt,
  label,
  name,
  value,
  handleDatepicker,
  placeholder,
  required,
  disabled,
  tabIndex,
}) => {
  return (
    <StyledContainer mb={mb} mt={mt}>
      {label && (
        <Text
          fontSize={theme.fontSizes[2]}
          fontFamily={theme.fonts[1]}
          fontWeight={0}
          mb={1}
          pt={2}
        >
          {label}
          {required ? <StyledRequired>*</StyledRequired> : null}
        </Text>
      )}
      <StyledButtonContainer>
        <DatePicker
          popperPlacement="top"
          name={name}
          selected={Date.parse(value)}
          placeholderText={placeholder}
          disabled={disabled}
          tabIndex={tabIndex}
          onChange={(date) =>
            handleDatepicker(
              moment(date).subtract(date.getTimezoneOffset(), "minutes"),
              name
            )
          }
          customInput={
            <Input
              width="100%"
              iconBtn
              icon={<Calendar variant="blue"></Calendar>}
            />
          }
        />
      </StyledButtonContainer>
    </StyledContainer>
  );
};

export default InputDatepicker;
