import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.linkable
        ? `&:hover {
      fill: ${theme.colors.lightRed}; cursor: pointer;}`
        : ""}
    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black)" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const Edit = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <g>
        <path
          d="M20.2,8.4c-0.2,0-0.3-0.1-0.5-0.2l-4.3-3.7c-0.3-0.3-0.3-0.7-0.1-1.1l1.6-1.9c0.6-0.7,1.7-0.8,2.4-0.2l2.9,2.5
		c0.7,0.6,0.8,1.7,0.2,2.4l-1.6,1.9C20.6,8.3,20.4,8.4,20.2,8.4z M16.9,3.9l3.2,2.7l1.1-1.3c0.1-0.1,0.1-0.2,0-0.3l-2.9-2.5
		c-0.1-0.1-0.2-0.1-0.3,0L16.9,3.9z"
        />
        <path
          d="M3,24c-0.2,0-0.4-0.1-0.5-0.2c-0.2-0.2-0.3-0.4-0.3-0.7c0.7-5.3,0.9-5.6,1.1-5.8l9.9-11.5
		c0.3-0.3,0.7-0.5,1.1-0.6c0.4,0,0.9,0.1,1.2,0.4l2.9,2.5c0.3,0.3,0.5,0.7,0.6,1.1c0,0.4-0.1,0.9-0.4,1.2L8.8,22
		c-0.2,0.2-0.4,0.5-5.6,1.9C3.1,24,3.1,24,3,24z M4.5,18.3c-0.1,0.4-0.4,2.2-0.7,3.9c1.7-0.5,3.4-1,3.8-1.2l9.8-11.4
		c0-0.1,0-0.2,0-0.2l0,0l-2.9-2.5c-0.1,0-0.2,0-0.2,0L4.5,18.3z M8.1,21.6L8.1,21.6L8.1,21.6z"
        />
      </g>
    </StyledSvg>
  );
};

export default Edit;
