import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//Component
import { AddPeopleModal } from "../../components/domain/people";

//Actions
import { peopleImport, peopleGet, projectPeopleGet, talentPoolGet } from "../../actions";

//Reducers
import {
  getLocationPathname,
  getWorkspaceCurrent,
  getIsLoadingImport,
  getPeopleImport,
  getLimit,
  getWorkflowsCurrentIncluded,
} from "../../reducer";

const AddPeopleModalContainer = ({
  addPeopleBtn,
  importPeopleBtn,
  projectId,
}) => {
  const dispatch = useDispatch();
  const workspaceId = useSelector(getWorkspaceCurrent);
  const location = useSelector(getLocationPathname);
  const isLoadingImport = useSelector(getIsLoadingImport);
  const importData = useSelector(getPeopleImport);
  const limit = useSelector(getLimit);
  const stages = useSelector(getWorkflowsCurrentIncluded);

  const [showImportData, setShowImportData] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(undefined);
  const [stageId, setStageId] = useState(false);
  const [errorExcessPeople, setErrorExcessPeople] = useState(false);

  const handleInputChange = (event) => {
    event.preventDefault();
    const reader = new FileReader();
    reader.onload = function (e) {
      const lines = e.target.result.split('\n');
      const peopleCount = lines.length - 1;
      setErrorExcessPeople(peopleCount > 400);
    };

    if (event.target.files.length > 0) {
      reader.readAsText(event.target.files[0]);
      setSelectedFile(event.target.files[0]);
      setShowImportData(false);
    }
  };

  const handleSubmitClick = () => {
    const formData = new FormData();
    formData.append("data[file]", selectedFile);
    if (workspaceId) formData.append("data[workspace_id]", workspaceId);
    if (projectId) formData.append("data[project_id]", projectId);
    if (stageId) formData.append("data[stage_id]", stageId);

    dispatch(
      peopleImport({
        formData: formData,
      })
    );
    setShowImportData(true);
  };

  const handleCloseModal = () => {
    setShowImportData(false);
    setShowModal(false);

    if (projectId) {
      dispatch(projectPeopleGet({ id: projectId, search_by: "", limit: limit }));
    } else if (workspaceId) {
      dispatch(peopleGet({ workspace_id: workspaceId, search_by: "", limit: limit }));
    } else {
      dispatch(talentPoolGet({ search_by: "", limit: "" }));
    }
    setShowImportData(true);
  };

  const handleStage = (name, value) => {
    setStageId(value);
  }

  return (
    <AddPeopleModal
      addPeopleBtn={addPeopleBtn}
      importPeopleBtn={importPeopleBtn}
      location={location}
      handleCloseModal={handleCloseModal}
      handleInputChange={handleInputChange}
      handleSubmitClick={handleSubmitClick}
      setShowModal={setShowModal}
      showModal={showModal}
      selectedFile={selectedFile}
      showImportData={showImportData}
      importData={importData}
      stages={stages}
      handleStage={handleStage}
      isLoadingImport={isLoadingImport}
      errorExcessPeople={errorExcessPeople}
      projectId={projectId}
    />
  );
};

export default AddPeopleModalContainer;
