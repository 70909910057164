import { combineActions, handleActions } from "redux-actions";
import { lensProp, pipe, set, view } from "ramda";
import {
  onExpiredToken,
  signInSet,
  signInSetSuccess,
  signInSetFailure,
  signUpSet,
  signUpSetSuccess,
  signUpSetFailure,
  userGetSuccess,
  userGetFailure,
  signOutSetSuccess,
  passwordUpdate,
  passwordUpdateSuccess,
  passwordUpdateFailure,
  passwordResetSuccess,
} from "../actions";

const initialState = {
  inError: false,
  error: "",
  loading: false,
  token: localStorage.getItem("access-token"),
  data: {},
  isUserLoaded: false,
  message: "",
};

const isLoadingLens = lensProp("loading");
const tokenLens = lensProp("token");
const inErrorLens = lensProp("inError");
const errorLens = lensProp("error");
const dataLens = lensProp("data");
const messageLens = lensProp("message");
const isUserLoadedLens = lensProp("isUserLoaded");

export const getIsLoading = view(isLoadingLens);
export const getInError = view(inErrorLens);
export const getData = view(dataLens);

const setData = set(dataLens);

export default handleActions(
  {
    // handle getUser actions
    [userGetSuccess]: (state, action) =>
      pipe(
        setData(action.payload.data.data),
        set(tokenLens, action.payload.headers["access-token"]),
        set(isUserLoadedLens, true)
      )(state),
    [userGetFailure]: (state, action) =>
      pipe(
        set(inErrorLens, true),
        set(isLoadingLens, false),
        set(isUserLoadedLens, false)
      )(state),
    // handle sign-in actions
    [signInSetSuccess]: (state, action) =>
      pipe(
        setData(action.payload.data.data),
        set(tokenLens, action.payload.headers["access-token"]),
        set(isLoadingLens, false),
        set(isUserLoadedLens, true)
      )(state),
    [signInSetFailure]: (state, action) =>
      pipe(
        set(inErrorLens, true),
        set(isLoadingLens, false),
        set(isUserLoadedLens, false),
        set(errorLens, action.payload.errors[0])
      )(state),
    [signInSet]: (state) =>
      pipe(
        set(inErrorLens, false),
        set(isLoadingLens, true),
        set(messageLens, "")
      )(state),
    // handle sign-up actions
    [signUpSetSuccess]: (state, action) =>
      pipe(
        setData(action.payload.data.data),
        set(isLoadingLens, false),
        set(isUserLoadedLens, true)
      )(state),
    [signUpSetFailure]: (state, action) =>
      pipe(
        set(inErrorLens, true),
        set(isLoadingLens, false),
        set(isUserLoadedLens, false),
        set(errorLens, action.payload.errors?.[0] || action.payload.error)
      )(state),
    [signUpSet]: (state) =>
      pipe(set(inErrorLens, false), set(isLoadingLens, true))(state),
    [signOutSetSuccess]: (state, action) => {
      pipe(
        setData({}),
        set(tokenLens, ""),
        set(isUserLoadedLens, false)
      )(state);
    },
    // handle reset-password actions
    [passwordResetSuccess]: (state, action) =>
      pipe(
        setData({}),
        set(tokenLens, ""),
        set(isLoadingLens, false),
        set(isUserLoadedLens, false),
        set(messageLens, action.payload.data.message)
      )(state),
    // handle update-password actions
    [passwordUpdate]: (state) =>
      pipe(
        set(inErrorLens, false),
        set(isLoadingLens, true),
        set(isUserLoadedLens, false)
      )(state),
    [passwordUpdateSuccess]: (state, action) =>
      pipe(
        setData(action.payload.data.data),
        // set(tokenLens, action.payload.headers["access-token"]),
        set(isLoadingLens, false),
        set(isUserLoadedLens, false),
        set(messageLens, action.payload.data.message)
      )(state),
    [passwordUpdateFailure]: (state, action) =>
      pipe(
        set(inErrorLens, true),
        set(isLoadingLens, false),
        set(isUserLoadedLens, false),
        set(
          errorLens,
          action.payload.errors.password
            ? action.payload.errors.password[0]
            : action.payload.errors[0]
        )
      )(state),
    // handle expired token
    [combineActions(onExpiredToken, passwordUpdateFailure)]: (state) => {
      localStorage.clear(); // remove token
      return {
        ...initialState,
        token: null,
      };
    },
  },
  initialState
);

export const getAuth = (state) => state.auth;
export const getAuthId = (state) => state.auth.data.id;
export const getAuthInError = (state) => state.auth.inError;
export const getAuthError = (state) => state.auth.error;
export const getAuthLoading = (state) => state.auth.loading;
export const getAuthMessage = (state) => state.auth.message;
export const getIsUserLoaded = (state) => state.auth.isUserLoaded;
