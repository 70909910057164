import React from "react";
import theme from "@theme";
import styled from "styled-components";

//components
import Flex from "@ui/flex";
import Card from "@ui/card";
import TableBar from "@ui/table-bar";
import InputSearch from "@ui/input-search/index";
import Text from "@ui/text";
import StepsFilter from "@ui/step-filter/index";
import Dropdown from "@ui/dropdown";
import { PayloadEditor, PayloadSelector } from ".";
import { CSVLink } from "react-csv";
import ReactTooltip from "react-tooltip";
import DOMPurify from 'dompurify';

//icons
import Filter from "@ui/icons/setting";
import Export from "@ui/icons/export";

//Utils
import { analyticsFilename, analyticsCsvHeaders } from "@utils/files";

const TotalContainer = styled.div`
  display: flex;
  width: 10%;
`;

const StytedText = styled.div`
  display: flex;
  font-size: 1rem;
  font-weight: 200;
  padding-right: 10px;
  color: ${theme.colors.darkBlue};
`;

const StyledNumber = styled.div`
  display: flex;
  padding: 0;
  font-weight: 400;
  font-size: 0.9rem;
  color: ${theme.colors.darkBlue};
`;

const StyledIcon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
`;

const StyledLabel = styled(Text)`
  font-size: 0.8rem;
  color: ${theme.colors.lightGrey};
  font-weight: 200;
  width: 50px;
  text-align: right;
`;

const StyledInfo = styled(Text)`
  font-size: 0.9rem;
  font-weight: 300;
  margin: 5px 10px;
  padding: 3px 5px;
`;

const Divider = styled.div`
  height: 5px;
  width: 100%;
  border-bottom: 1px solid ${theme.colors.darkBlue};
  margin-bottom: 20px;
`;

const StyledBody = styled.div`
  width: auto;
  font-size: 0.9rem;
  font-weight: 200;
  margin: 5px 10px;
  padding: 3px 5px;
  color: ${theme.colors.grey};
  ${(props) => (props.withWhiteSpace ? "white-space: pre-line" : null)}
`;

const PayloadList = ({
  projectPeople,
  payloads,
  selectedPayloadId,
  steps,
  selectorHandler,
  getPersonByPayload,
  getStatusByPayload,
  getSequenceProgressByPayload,
  updatePayloadHandler,
  sendEventPayloadHandler,
  activePerson, // person info - handle edit
  rowMenuOptions, // row menu - options
  stagesOptions, // workflow stages - for StageFilter component
  handleSelectedMessageStage, // workflow stages - for StageFilter component
  byStage, // workflow stages - for StageFilter component
  handleChangeSearchBy,
  handleEnterSearchBy,
  searchBy,
  byStatus,
  handleStatusFilter,
  handleUpdateResponse,
  firstPage,
  lastPage,
  prevPage,
  nextPage,
  totalPages,
  currentPage,
  viewport,
  viewHeight,
  filterOptions,
  projectAnalytics,
  project,
  selectedPayloadData,
  currentMessages,
}) => {
  return (
    <Flex
      marginTop="3%"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      backgroundColor={theme.colors.lighterGrey}
    >
      <TableBar
        iconButtons={[
          <InputSearch
            key="search"
            onChange={handleChangeSearchBy}
            onEnter={handleEnterSearchBy}
            name="search_by"
            value={searchBy}
          />,
        ]}
        mainContent={
          <>
            <TotalContainer>
              <StytedText>Total</StytedText>
              <StyledNumber>({payloads ? payloads.length : "0"})</StyledNumber>
            </TotalContainer>

            <StepsFilter
              hasScroll
              onSelect={handleSelectedMessageStage}
              content={stagesOptions || []}
              byStage={byStage}
            />

            <Flex width="150px" alignItems="center" marginLeft="35px">
              <StyledIcon>
                <Filter variant="darkBlue" />
              </StyledIcon>
              <Dropdown
                formStyle
                short
                bold
                variant="blue"
                name={"status"}
                value={byStatus}
                handleDropdown={handleStatusFilter}
                options={filterOptions}
                small
              />
            </Flex>
            <Flex alignItems="center" marginLeft="2%" marginRight="1%">
              {projectAnalytics?.length > 0 ? (
                <CSVLink
                  headers={analyticsCsvHeaders(projectAnalytics)}
                  data={projectAnalytics}
                  filename={analyticsFilename(
                    project.attributes.name + " people"
                  )}
                >
                  <StyledIcon>
                    <Export
                      variant="blue"
                      blueLinkable
                      data-tip
                      data-for="titleTooltip"
                    />
                    <ReactTooltip id="titleTooltip" aria-haspopup="true">
                      Export current stage <br />
                      messaging analytics
                    </ReactTooltip>
                  </StyledIcon>
                </CSVLink>
              ) : (
                <StyledIcon>
                  <Export
                    variant="lightGrey"
                    data-tip
                    data-for="titleTooltip"
                  />
                  <ReactTooltip id="titleTooltip" aria-haspopup="true">
                    Getting messaging analytics ...
                  </ReactTooltip>
                </StyledIcon>
              )}
            </Flex>
          </>
        }
      />
      <Flex
        justifyContent="space-between"
        width="100%"
        minHeight="500px"
        backgroundColor="transparent"
      >
        <PayloadSelector
          viewport={viewport}
          viewHeight={viewHeight}
          projectPeople={projectPeople}
          payloads={payloads}
          selectorHandler={selectorHandler}
          selectedPayloadId={selectedPayloadId}
          getPersonByPayload={getPersonByPayload}
          getStatusByPayload={getStatusByPayload}
          getSequenceProgressByPayload={getSequenceProgressByPayload}
          handleUpdateResponse={handleUpdateResponse}
          rowMenuOptions={rowMenuOptions}
          activePerson={activePerson}
          firstPage={firstPage}
          lastPage={lastPage}
          prevPage={prevPage}
          nextPage={nextPage}
          totalPages={totalPages}
          currentPage={currentPage}
        />

        {selectedPayloadData &&
          selectedPayloadData.extra.status.name === "Replied" ? (
          <Flex
            width="65%"
            minHeight="300px"
            alignItems="flex-start"
            justifyContent="center"
            backgroundColor="transparent"
          >
            {currentMessages.length > 0 && (
              <Card header title="Messages" noMargin>
                <Flex flexDirection="column" alignItems="start" width="100%">
                  {currentMessages.map((message) => (
                    <Flex width="100%" flexDirection="column" key={message.id}>
                      <Flex alignItems="center">
                        <StyledLabel>From:</StyledLabel>
                        <StyledInfo>{message.attributes.from}</StyledInfo>
                      </Flex>
                      <Flex alignItems="center">
                        <StyledLabel>To:</StyledLabel>
                        <StyledInfo>{message.attributes.to}</StyledInfo>
                      </Flex>
                      <Flex alignItems="center">
                        <StyledLabel>Subject: </StyledLabel>
                        <StyledInfo>{message.attributes.subject}</StyledInfo>
                      </Flex>
                      <Flex marginTop="20px">
                        <StyledLabel>Body:</StyledLabel>

                        <StyledBody
                          withWhiteSpace={
                            message.attributes.type === "Message::In"
                          }
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(message.attributes.body_untracked),
                          }}
                        />
                      </Flex>
                      <Divider />
                    </Flex>
                  ))}
                </Flex>
              </Card>
            )}
          </Flex>
        ) : selectedPayloadId && steps?.length > 0 ? (
          <PayloadEditor
            viewport={viewport}
            payloads={payloads}
            selectedPayloadId={selectedPayloadId}
            steps={steps}
            updatePayloadHandler={updatePayloadHandler}
            sendEventPayloadHandler={sendEventPayloadHandler}
            getPersonByPayload={getPersonByPayload}
          />
        ) : (
          <Flex
            width="65%"
            minHeight="300px"
            alignItems="center"
            justifyContent="center"
            backgroundColor="transparent"
          >
            <Text fontSize="1.1rem" color={theme.colors.lightGrey}>
              Select a person to start writing
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default PayloadList;
