import React, { useState } from "react";
import styled from "styled-components";
import theme from "../../../theme";
import { FeatureFlag } from "react-unleash-flags";

import Switch from "../../ui/toggle-switch";

const RowContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 6px 0px;
  background-color: white;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  ${(props) =>
    props.flat
      ? "cursor:default; border: 1px solid lightgray;  border-radius: 3px"
      : null};
  ${(props) =>
    props.active && !props.isOpen
      ? `height: auto;  border-radius: 3px; border: 1.5px solid ${theme.colors.blue};`
      : null};
  ${(props) =>
    props.isOpen && props.active
      ? `height: auto;  border-radius: 3px; border: 1.5px solid ${theme.colors.blue};`
      : null};
  ${(props) =>
    props.error
      ? ` border-radius: 3px; border: 1px solid ${theme.colors.lightRed};`
      : null};

  &:hover {
    box-shadow: 0px 4px 4px lightgray;
  }
`;
const StyledRow = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  padding: 10px 10px 10px 15px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`;

const StyledBody = styled.div`
  display: ${(props) => (props.show ? "flex" : "none")};
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

const StyledBodyContent = styled.div`
  display: flex;
  padding: 10px 10px 10px 15px;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
`;

const StyledIcon = styled.div`
  display: flex;
  height: 60px;
  width: 10%;
  align-items: flex-start;
  justify-content: flex-end;

  * {
    height: 15px;
    width: 15px;
  }
`;
const StyledButtonArea = styled.div`
  display: flex;
  height: 60px;
  width: 25%;
  align-items: center;
  justify-content: space-between;
`;

const CardDivider = styled.div`
  margin-top: 5px;
  width: 100%;
  height: 15px;
  background: rgb(201, 201, 201);
  background: linear-gradient(
    180deg,
    rgba(231, 231, 231, 1) 0%,
    rgba(255, 255, 255, 1) 70%
  );
`;

const Row = ({
  onClick,
  rowContent,
  bodyContent,
  flat,
  active,
  icon,
  error,
  handleIcon,
  handleCleanAction,
  open,
  ...props
}) => {
  const [rowOpen, setRowOpen] = useState(open);
  return (
    <RowContainer
      flat={flat}
      isOpen={rowOpen}
      active={active}
      onClick={onClick}
      error={error}
      {...props}
    >
      <StyledRow>
        {rowContent}
        <FeatureFlag name="feature_sequence">
          <StyledButtonArea>
            <Switch
              customeLabel="Action"
              initialValue={rowOpen}
              handleChange={() => {
                setRowOpen(!rowOpen);
                handleCleanAction(rowOpen);
              }}
            />
            {icon && <StyledIcon onClick={handleIcon}>{icon}</StyledIcon>}
          </StyledButtonArea>
        </FeatureFlag>
      </StyledRow>
      <StyledBody show={active && rowOpen}>
        <CardDivider />
        <StyledBodyContent>{bodyContent}</StyledBodyContent>
      </StyledBody>
    </RowContainer>
  );
};

export default Row;
