import React from "react";
import theme from "../../../theme";
import styled from "styled-components";

//Component
import Button from "../../ui/button";
import Flex from "../../ui/flex";
import Text from "../../ui/text";
import InputIntegrated from "../../ui/input-integrated/index";

//icon
import Success from "../../ui/icons/valid";

//Helper
import {
  isAllowedRole,
  getAllowedRoles,
} from "../../../utils/user-role-helper";

const SuccessIcon = styled.div`
  height: 20px;
  width: 20px;
  margin-right: 5px;
`;

const Invitation = ({
  dirtyChanges,
  handleSubmitClick,
  handleChange,
  handleDropdown,
  handleDropdownSelect,
  userRole = "",
  invitationSent,
  ...props
}) => {
  return isAllowedRole(userRole) ? (
    <Flex
      paddingTop={"0px"}
      alignItems={"center"}
      /*  height={"100%"}
        width={"100%"} */
      flexDirection={"column"}
      justifyContent={"flex-start"}
      backgroundColor={theme.colors.lighterGrey}
    >
      <Flex width="100%" alignItems="center">
        {invitationSent && (
          <SuccessIcon title="Invitation Sent">
            <Success variant="blue" />
          </SuccessIcon>
        )}
        <InputIntegrated
          short
          small
          placeholderFirst="Enter E-Mail Address"
          placeholderSecond="Select Role"
          nameFirst="email"
          nameSecond="project_role"
          options={getAllowedRoles(userRole)}
          dirtyChanges={dirtyChanges}
          handleSubmitClick={handleSubmitClick}
          handleChange={handleChange}
          handleDropdown={handleDropdown}
          handleDropdownSelect={handleDropdownSelect}
          button={
            <Button
              variant="secondaryLight"
              content="Invite"
              margin="5px 10px"
              size="small"
              onClick={handleSubmitClick}
            ></Button>
          }
        />
      </Flex>
    </Flex>
  ) : (
    <Text color="red">
      Not allowed to modify user&apos;s access for this project
    </Text>
  );
};

export default Invitation;
