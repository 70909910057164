import React from "react";
import styled from "styled-components";
import { variant, layout } from "styled-system";
import theme from "../../../theme";

// Components
import Box from "../box";

const StyledContainer = styled(Box)`
  position: relative;
  width: auto;
  display: flex;
  background-color: transparent;
  justify-content: center;
  ${({ onClick }) => onClick && `cursor: pointer;`}
  ${layout}
`;

const StyledButton = styled("input")(
  {
    fontFamily: "inherit",
    fontSize: "0.9rem",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "85px",
    padding: "8px 15px",
    margin: "10px",
    borderRadius: "4px",
    textTransform: "uppercase",
    fontWeight: "500",
    border: "none",

    "&:hover": {
      boxShadow: "0px 2px 2px lightgray",
      cursor: "pointer",
    },
  },

  variant({
    prop: "size",
    variants: {
      small: {
        minWidth: "40px",
        fontSize: "0.8rem",
        padding: "7px 15px",
      },
      big: {
        minWidth: "120px",
        fontSize: "1rem",
        padding: "10px 15px",
      },
      xsmall: {
        minWidth: "40px",
        fontSize: "0.7rem",
        padding: "7px 9px",
      },
    },
  }),

  variant({
    variants: {
      primary: {
        color: theme.colors.white,
        bg: theme.colors.red,
      },

      primaryLine: {
        color: theme.colors.red,
        bg: theme.colors.white,
        border: "1px solid",
        borderColor: theme.colors.red,
      },

      primaryLight: {
        color: theme.colors.white,
        bg: theme.colors.lightRed,
      },

      secondary: {
        color: theme.colors.white,
        bg: theme.colors.blue,
      },

      secondaryLine: {
        color: theme.colors.blue,
        bg: theme.colors.white,
        border: "1px solid",
        borderColor: theme.colors.blue,
      },

      secondaryLight: {
        color: theme.colors.blue,
        bg: theme.colors.lightBlue,
      },

      disabled: {
        color: theme.colors.lighterGrey,
        bg: theme.colors.lightGrey,
        "&:hover": { cursor: "not-allowed", boxShadow: "none" },
      },
    },
  })
);

const Submit = ({ content, variant, onClick, size, disabled, ...props }) => {
  return (
    <StyledContainer onClick={onClick} {...props}>
      <StyledButton
        type="submit"
        variant={disabled ? "disabled" : variant}
        size={size}
        value={content}
        disabled={disabled}
      ></StyledButton>
    </StyledContainer>
  );
};

export default Submit;
