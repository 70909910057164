import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black)" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const Waiting = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <g>
        <path
          d="M12.5,2.4L12.5,2.4c-0.5,0-0.8,0.3-0.8,0.8s0.4,0.8,0.8,0.8c4.8,0,8.6,3.9,8.6,8.6s-3.9,8.6-8.6,8.6
		c-2.2,0-4.2-0.8-5.8-2.3h1.4c0.4,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8H4.4c-0.2,0-0.5,0.1-0.6,0.3s-0.2,0.4-0.1,0.7l1.2,3.8
		c0.1,0.3,0.4,0.5,0.7,0.5c0.1,0,0.2,0,0.2,0c0.4-0.1,0.6-0.6,0.5-0.9l-0.5-1.5c1.8,1.6,4.2,2.5,6.7,2.5c5.6,0,10.1-4.5,10.1-10.1
		S18.1,2.4,12.5,2.4z"
        />
        <path
          d="M4.8,7.8c0.3,0,0.5-0.1,0.7-0.3c0.2-0.3,0.2-0.8-0.2-1C5,6.2,4.5,6.3,4.3,6.6c0,0-0.1,0.1-0.1,0.1
		C4,7,4.1,7.5,4.4,7.7C4.5,7.8,4.7,7.8,4.8,7.8z"
        />
        <path
          d="M9.6,4.3c0.1,0,0.2,0,0.2,0c0.4-0.1,0.6-0.5,0.5-0.9C10.2,3,9.8,2.7,9.4,2.9c0,0-0.1,0-0.1,0
		C8.9,3,8.7,3.4,8.8,3.8C8.9,4.1,9.3,4.3,9.6,4.3z"
        />
        <path
          d="M6.9,5.7c0.2,0,0.3-0.1,0.5-0.2c0.3-0.2,0.4-0.7,0.2-1c-0.2-0.3-0.7-0.4-1-0.2c0,0-0.1,0.1-0.1,0.1
		c-0.3,0.2-0.4,0.7-0.1,1C6.5,5.6,6.7,5.7,6.9,5.7z"
        />
        <path
          d="M3.3,10.5c0.1,0,0.1,0,0.2,0c0.3,0,0.7-0.2,0.8-0.6C4.4,9.5,4.2,9.1,3.8,9C3.4,8.9,3,9.1,2.8,9.5
		c0,0,0,0.1,0,0.1C2.7,10,2.9,10.4,3.3,10.5z"
        />
        <path
          d="M3.1,13.4c0.4,0,0.7-0.4,0.7-0.8c0-0.4-0.3-0.7-0.7-0.7c0,0,0,0,0,0c-0.4,0-0.7,0.3-0.7,0.8c0,0,0,0.1,0,0.1
		C2.4,13.2,2.7,13.4,3.1,13.4C3.1,13.4,3.1,13.4,3.1,13.4z"
        />
        <path d="M4.4,15.6c0-0.4-0.3-0.8-0.7-0.8s-0.8,0.3-0.8,0.8v0.1c0,0.4,0.3,0.7,0.8,0.7S4.4,16,4.4,15.6z" />
        <path
          d="M11.9,13.9h3.8c0.4,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8h-3V6.9c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8v6.3
		C11.1,13.5,11.5,13.9,11.9,13.9z"
        />
      </g>
    </StyledSvg>
  );
};

export default Waiting;
