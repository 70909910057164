import React, { useState } from "react";
import styled from "styled-components";
import theme from "../../../theme";
import { color, fontSize, space, top } from "styled-system";

const HiddenInput = styled.input`
  cursor: pointer;
  height: 0;
  opacity: 0;
  outline: none;
  position: absolute;
  width: 0;
  &:focus,
  &:hover,
  &:active {
    outline: none;
  }
`;
const StyledIcon = styled.div`
  cursor: ${(props) => (props.inactive ? "not-allowed" : "pointer")};
  display: flex;
  height: 20px;
  width: 20px;
`;

const StyledLabel = styled.label`
  cursor: ${(props) => (props.inactive ? "not-allowed" : "pointer")};
  display: flex;
  height: 100%;
  outline: none;
  padding: 5px;
  position: relative;
  user-select: none;
  ${color}
  ${fontSize}
  ${top}
  ${space}

  ${HiddenInput}:checked ~ ${StyledIcon} {
    filter: drop-shadow(1px 1px 1px lightGrey);
    > * {
      &:first-child {
        fill: #1b7b99;
      }
    }
  }

  ${StyledIcon}:hover, ${HiddenInput}:checked ~ ${StyledIcon}:hover {
    > * {
      &:first-child {
        fill: #4baac8;
      }
    }
  }

  ${(props) => (props.inactive ? `color:${theme.colors.lightGrey}` : "")}
`;

const RadioIcon = ({
  inputId,
  bg = "white",
  color = "",
  fontSize = 2,
  isChecked,
  icon,
  name,
  onChange,
  shape = "circle",
  top,
  value,
  inactive,
  ...props
}) => {
  return (
    <StyledLabel
      bg={bg}
      fontSize={fontSize}
      top={top}
      inactive={inactive}
      {...props}
    >
      <HiddenInput
        type="radio"
        checked={isChecked}
        name={name}
        onChange={onChange}
        value={value}
        inactive={inactive}
      />
      <StyledIcon>{icon}</StyledIcon>
    </StyledLabel>
  );
};

export default RadioIcon;
