import React from "react";
import styled from "styled-components";

const StyledTableBar = styled.div`
  width: 100%;
  margin-bottom: 10px;
  min-height: 55px;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  position: relative;
  top:0;
  justify-content: space-between;

  ${(props) =>
    props.iconButtons || props.actionButtons ? `justify-content: flex-end` : ``}
  ${(props) => (props.tags ? `justify-content: flex-start` : ``)}
  ${(props) =>
    props.iconButtons && props.tags ? `justify-content: space-between` : ``}
`;

const StyledArea = styled.div`
  display: flex;
  min-width: 50%;
  align-items: center;
  ${(props) => (props.left ? `justify-content: flex-start` : ``)}
  ${(props) => (props.right ? `justify-content: flex-end` : ``)}
`;

const StyledIcon = styled.div`
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const TableBar = ({
  iconButtons, // Buttons - right buttons
  mainContent,
  filter, //Filter
}) => {
  return (
    <StyledTableBar iconButtons={iconButtons}>
      <StyledArea left>
        {filter}
        {mainContent}
      </StyledArea>

      <StyledArea right>
        {iconButtons ? <StyledIcon>{iconButtons}</StyledIcon> : null}
      </StyledArea>
    </StyledTableBar>
  );
};
export default TableBar;
