import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.linkable
        ? `&:hover {
      filter: drop-shadow(-1px 1px 1px #4BAAC8); cursor: pointer;}`
        : ""}

    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black);" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const Workflow = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} width="30" height="30" viewBox="2.5 2.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" variant={variant}>
      <path fillRule="evenodd" clipRule="evenodd" d="M3.75 8.125C3.75 4.84348 3.78514 3.75 8.125 3.75C12.4649 3.75 12.5 4.84348 12.5 8.125C12.5 11.4065 12.5138 12.5 8.125 12.5C3.73616 12.5 3.75 11.4065 3.75 8.125Z" stroke="#515050" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.5 8.125C17.5 4.84348 17.5351 3.75 21.875 3.75C26.2149 3.75 26.25 4.84348 26.25 8.125C26.25 11.4065 26.2638 12.5 21.875 12.5C17.4862 12.5 17.5 11.4065 17.5 8.125Z" stroke="#363636" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M3.75 21.875C3.75 18.5935 3.78514 17.5 8.125 17.5C12.4649 17.5 12.5 18.5935 12.5 21.875C12.5 25.1565 12.5138 26.25 8.125 26.25C3.73616 26.25 3.75 25.1565 3.75 21.875Z" stroke="#363636" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.5 21.875C17.5 18.5935 17.5351 17.5 21.875 17.5C26.2149 17.5 26.25 18.5935 26.25 21.875C26.25 25.1565 26.2638 26.25 21.875 26.25C17.4862 26.25 17.5 25.1565 17.5 21.875Z" stroke="#363636" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </StyledSvg>
  );
};

export default Workflow;
