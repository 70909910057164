import React from "react";
import styled from "styled-components";
import {
  border,
  color,
  display,
  flexGrow,
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  layout,
  lineHeight,
  space,
  textAlign,
  textStyle,
  width,
  flexDirection,
  alignItems,
  margin,
} from "styled-system";

const StyledText = styled.div`
  white-space: ${(props) => ((props.nowrap) ? "nowrap" : "pre-line")};
  white-space: ${(props) => (props.wrap ? "pre-line" : "nowrap")};
  ${({ pointer }) => (pointer ? "cursor: pointer;" : "cursor: default;")}
  ${({ underlined }) => underlined && "text-decoration: underline;"}
  ${border}
  ${color}
  ${display}
  ${flexGrow}
  ${fontFamily}
  ${fontSize}
  ${fontStyle}
  ${fontWeight}
  ${layout}
  ${lineHeight}
  ${space}
  ${textAlign}
  ${textStyle}
  ${width}
  ${flexDirection}
  ${alignItems}
  ${margin}
`;

const StyledIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`;

const StyledPriority = styled.div`
  height: 30px;
  width: 30px;
  margin-left: 8px;
`;

const StyledChild = styled.p`
  white-space: ${(props) => (props.wrap ? "pre-line" : "nowrap")};
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  padding: 0;
  min-width: 0;
  max-width: 450px;
`;

const Text = ({
  icon,
  width,
  priority,
  button,
  children,
  color = "grey",
  display = "block",
  fontSize = 2,
  fontFamily = "Rubik",
  fontWeight = 1,
  renderLinks = false,
  space,
  textAlign,
  underlined,
  wrap,
  ...props
}) => {
  return (
    <StyledText
      width={width}
      color={color}
      display={display}
      fontSize={fontSize}
      fontFamily={fontFamily}
      fontWeight={fontWeight}
      space={space}
      textAlign={textAlign}
      underlined={underlined}
      {...props}
    >
      {icon ? <StyledIcon>{icon}</StyledIcon> : null}
      {children ? <StyledChild wrap={wrap}>{children}</StyledChild> : null}
      {priority ? <StyledPriority>{priority}</StyledPriority> : null}
      {button ? button : null}
    </StyledText>
  );
};

export default Text;
