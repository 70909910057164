import React from "react";
import theme from "../../../theme";

//components
import Flex from "../../ui/flex";
import SubNavbarTabs from "@ui/sub-navbar-tabs";

import ModalRemove from "../../ui/modal-remove";
import AlertModal from "@ui/modal/AlertModal";

import NewProjectModalContainer from "../../../containers/projects/NewProjectModalContainer";
import EditProjectModalContainer from "../../../containers/projects/EditProjectModalContainer";
import ProjectsList from "./ProjectsList";

const Projects = ({
  viewport,
  projects,
  activeProject,
  isEditActive,
  closeEditModalHandler,
  isRemoveActive,
  itemToRemove,
  closeRemoveModalHandler,
  removeProjectHandler,
  statusProjectHandler,
  rowCurrentMenuOptions,
  rowArchivedMenuOptions,
  handleSubNavbarTabs,
  isArchiveActive,
  closeArchiveModalHandler,
  archiveProjectHandler,
  itemToArchive,
}) => {
  return (
    <Flex
      paddingTop="64px"
      alignItems="center"
      height="auto"
      width="100%"
      flexDirection="column"
      justifyContent="flex-start"
      px={{ sm: 2, md: 0 }}
      overflow="hidden"
      backgroundColor={theme.colors.lighterGrey}
    >
      <SubNavbarTabs
        labels={["Current", "Archived"]}
        onSelect={handleSubNavbarTabs}
        iconButtons={[
          <NewProjectModalContainer showButton key="newProject"/>,
        ]}
      >
        <ProjectsList
          projects={projects}
          viewport={viewport}
          rowMenuOptions={rowCurrentMenuOptions}
          statusProjectHandler={statusProjectHandler}
        />
        <ProjectsList
          projects={projects}
          viewport={viewport}
          rowMenuOptions={rowArchivedMenuOptions}
          statusProjectHandler={statusProjectHandler}
        />
      </SubNavbarTabs>

      {isEditActive && (
        <EditProjectModalContainer
          project={activeProject}
          isEditActive={isEditActive}
          closeEditModalHandler={closeEditModalHandler}
        />
      )}

      {isRemoveActive && (
        <ModalRemove
          removeItem={itemToRemove}
          title="Remove Project"
          handleRemoveClick={removeProjectHandler}
          closeModal={closeRemoveModalHandler}
        />
      )}

      {isArchiveActive && (
        <AlertModal
          title="Archive Project"
          description="You´ll not be able to edit or modify anything inside this project after you archive it."
          buttonSubmitText="Archive"
          handleSubmitClick={archiveProjectHandler}
          closeModal={closeArchiveModalHandler}
          item={itemToArchive}
        />
      )}

    </Flex>
  );
};

export default Projects;
