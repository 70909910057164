import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Spinner from "@components/ui/spinner";
import { AccountSettings } from "@components/domain/account";

//Actions
import { workspaceDelete, workspaceUpdate } from "@actions";

//Reducer
import { getAppWorkspaces, getCurrentUser, getAppLoading } from "@reducer";

//utils
import { separateWorkspaces } from "@utils/separate-workspaces";

// eslint-disable-next-line
const AccountSettingsContainer = ({ }) => {
  const dispatch = useDispatch();
  const workspaces = useSelector(getAppWorkspaces);
  const loading = useSelector(getAppLoading);
  const user = useSelector(getCurrentUser).id;

  const [activeWorkspace, setActiveWorkspace] = useState({});
  const [dirtyChanges, setDirtyChanges] = useState({});
  const [isEditActive, setIsEditActive] = useState(false);
  const [isRemoveActive, setIsRemoveActive] = useState(false);
  const [isLeaveActive, setIsLeaveActive] = useState(false);
  const [itemToRemove, setItemToRemove] = useState();

  const [newWorkspaces, setNewWorkspaces] = useState([]);

  useEffect(() => {
    if (workspaces) {
      setNewWorkspaces(separateWorkspaces(user, workspaces));
    }
  }, [workspaces]);

  const editModalHandler = (workspace) => {
    setActiveWorkspace(workspace);
    setIsEditActive(!isEditActive);
  };

  //set workspace as removeItem inside row-menu-btn
  const removeModalHandler = (removeItem) => {
    setIsRemoveActive(true);
    setItemToRemove(removeItem);
  };

  const leaveModalHandler = (removeItem) => {
    setIsLeaveActive(true);
    setItemToRemove(removeItem);
  };

  const closeRemoveModalHandler = () => {
    setIsRemoveActive(false);
  };

  const closeLeaveModalHandler = () => {
    setIsLeaveActive(false);
  };

  const removeWorkspaceHandler = (workspace) => {
    dispatch(workspaceDelete({ id: workspace.id }));
    closeRemoveModalHandler();
    closeLeaveModalHandler();
  };

  const editWorkspaceHandler = () => {
    dispatch(
      workspaceUpdate({ id: activeWorkspace.id, title: dirtyChanges.name })
    );
    closeEditModalHandler();
  };

  const closeEditModalHandler = () => {
    setDirtyChanges({});
    setIsEditActive(false);
  };

  const handleEditInputChange = (event) => {
    setDirtyChanges({
      ...dirtyChanges,
      [event.target.name]: event.target.value,
    });
  };

  const rowOwnerMenuOptions = [
    { name: "Edit", callback: editModalHandler },
    { name: "Remove", alert: removeModalHandler },
  ];

  const rowGuestMenuOptions = [{ name: "Leave", alert: leaveModalHandler }];

  const handleVisibility = (value, workspaceId) => {
    dispatch(
      workspaceUpdate({
        id: workspaceId,
        visible: value,
      })
    );
  };

  return loading ? (
    <Spinner />
  ) : (
    <AccountSettings
      workspaces={workspaces}
      ownWorkspaces={newWorkspaces.owner}
      guestWorkspaces={newWorkspaces.guest}
      rowOwnerMenuOptions={rowOwnerMenuOptions}
      rowGuestMenuOptions={rowGuestMenuOptions}
      activeWorkspace={activeWorkspace}
      isEditActive={isEditActive}
      handleInputChange={handleEditInputChange}
      closeEditModalHandler={closeEditModalHandler}
      dirtyChanges={dirtyChanges}
      handleSubmitClick={editWorkspaceHandler}
      isRemoveActive={isRemoveActive} // remove-modal status - open/close
      isLeaveActive={isLeaveActive}
      closeRemoveModalHandler={closeRemoveModalHandler} //close modal handler - prop for remove-modal component
      closeLeaveModalHandler={closeLeaveModalHandler}
      itemToRemove={itemToRemove} //complete item to remove - must have attributes.name and attributes.id - prop for remove-modal component
      removeWorkspaceHandler={removeWorkspaceHandler} // remove action - prop for remove-modal component
      handleVisibility={handleVisibility}
    />
  );
};

export default AccountSettingsContainer;
