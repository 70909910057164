import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import theme from "../../../theme";

import Avatar from "../avatar/index";
import ArrowUp from "../icons/arrowUp";
import ArrowDown from "../icons/arrowDown";

import Config from "../icons/config";
import Profile from "../icons/people";
import Logout from "../icons/logout";
import Chrome from "../icons/chrome";

import { user2Chars, user2Title } from "../../../utils/user-helper";

const StyledMenu = styled.div`
  display: flex;
  height: 60px;
  margin: 0px 10px;
  align-items: center;
  justify-content: flex-end;
`;

const StyledList = styled.div`
  display: inline-block;
  flex-direction: column;
  top: 60px;
  height: auto;
  position: absolute;
  z-index: 99999;
  background-color: white;
  box-shadow: 0px 1px 3px ${theme.colors.lightGrey};
  width: auto;
`;

const StyledOption = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  font-size: 0.85rem;
  white-space: nowrap;
  color: ${theme.colors.grey};

  &:hover {
    cursor: pointer;
    background-color: ${theme.colors.lighterOrange};
    color: ${theme.colors.blue};
  }

  &:hover * {
    fill: ${theme.colors.blue};
  }
`;

const StyledOptionIcon = styled.div`
  height: 18px;
  width: 18px;
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const StyledIcon = styled.div`
  height: 40px;
  width: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${({ disabled }) =>
    disabled &&
    `&:hover {
      cursor: default;}`}
  ${({ iconBtn, disabled }) =>
    iconBtn &&
    !disabled &&
    `&:hover {
      cursor: pointer;
    }
  `}
`;

const StyledCircleRed = styled.div`
  height: 10px;
  width: 10px;
  margin-left: 10px;
  background: linear-gradient(
    130deg,
    rgba(255, 97, 9, 1) 0%,
    rgba(160, 47, 47, 1) 100%
  );
  border-radius: 50%;
`;

const StyledCircleBlue = styled.div`
  height: 10px;
  width: 10px;
  margin-left: 10px;
  background: linear-gradient(
    130deg,
    rgba(63, 177, 213, 1) 0%,
    rgba(47, 133, 160, 1) 100%
  );
  border-radius: 50%;
`;

const NavbarUserMenu = ({ user, auth, options }) => {
  const node = useRef();
  const [open, setOpen] = useState(false);

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
  };

  const handleList = () => {
    setOpen(!open);
  };

  const handleSelect = (callback) => {
    setOpen(!open);
    if (callback) callback();
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  useEffect(() => {
    if (user && window.pendo) {
      window.pendo.initialize({
        visitor: {
          id: user.id, // Required if user is logged in
          email: user.email,
        },
      });
    }
  }, [user]);

  return (
    <StyledMenu ref={node}>
      <Avatar
        misc={auth.alert || auth.message}
        size="big"
        onClick={() => handleList()}
        key={user.id}
        variant={user.color || "blue"}
        bgimage={user.picture}
        content={user2Chars(user.attributes)}
        title={user2Title(user.attributes)}
      />
      <StyledIcon onClick={() => handleList()}>
        {open === false ? (
          <ArrowDown variant="grey" />
        ) : (
          <ArrowUp variant="grey" />
        )}
      </StyledIcon>
      {open === true && (
        <StyledList>
          {options.map((opt) => (
            <StyledOption
              onClick={() => handleSelect(opt.callback)}
              key={opt.id}
            >
              {opt.id === "settings" ? (
                <StyledOptionIcon>
                  <Config variant="orange" />
                </StyledOptionIcon>
              ) : null}
              {opt.id === "profile" ? (
                <StyledOptionIcon>
                  <Profile variant="orange" />
                </StyledOptionIcon>
              ) : null}
              {opt.id === "logout" ? (
                <StyledOptionIcon>
                  <Logout variant="orange" />
                </StyledOptionIcon>
              ) : null}
              {opt.id === "extension" ? (
                <StyledOptionIcon>
                  <Chrome variant="orange" />
                </StyledOptionIcon>
              ) : null}
              {opt.label}
              {opt.alert && <StyledCircleRed />}
              {opt.message && <StyledCircleBlue />}
            </StyledOption>
          ))}
        </StyledList>
      )}
    </StyledMenu>
  );
};

export default NavbarUserMenu;
