import { combineActions, handleActions } from "redux-actions";
import { lensProp, pipe, set, view } from "ramda";

import {
  onExpiredToken,
  workflowsGet,
  workflowsGetSuccess,
  workflowsGetFailure,
  workflowGet,
  workflowGetSuccess,
  workflowGetFailure,
  workflowAssign,
  workflowAssignSuccess,
  workflowAssignFailure,
} from "../actions";

const initialState = {
  inError: false,
  loading: false,
  token: localStorage.getItem("access-token"),
  data: [],
  current: {},
};

const isLoadingLens = lensProp("loading");
const inErrorLens = lensProp("inError");
const tokenLens = lensProp("token");
const dataLens = lensProp("data");
const currentLens = lensProp("current");

const setData = set(dataLens);
const setCurrent = set(currentLens);

export const getIsLoading = view(isLoadingLens);
export const getInError = view(inErrorLens);
export const getToken = view(tokenLens);

export default handleActions(
  {
    [workflowsGetSuccess]: (state, actions) =>
      pipe(
        setData(actions.payload.data),
        set(inErrorLens, false),
        set(isLoadingLens, false)
      )(state),

    [workflowsGetFailure]: (state) =>
      pipe(set(inErrorLens, true), set(isLoadingLens, false))(state),

    [workflowsGet]: (state) =>
      pipe(set(inErrorLens, false), set(isLoadingLens, true))(state),

    //current
    [workflowGetSuccess]: (state, actions) =>
      pipe(
        setCurrent(actions.payload.data),
        set(inErrorLens, false),
        set(isLoadingLens, false)
      )(state),

    [workflowGetFailure]: (state) =>
      pipe(set(inErrorLens, true), set(isLoadingLens, false))(state),

    [workflowGet]: (state) =>
      pipe(set(inErrorLens, false), set(isLoadingLens, true))(state),

    //assign
    [workflowAssignSuccess]: (state, actions) =>
      pipe(
        setCurrent(actions.payload.data),
        set(inErrorLens, false),
        set(isLoadingLens, false)
      )(state),

    [workflowAssignFailure]: (state) =>
      pipe(set(inErrorLens, true), set(isLoadingLens, false))(state),

    [workflowAssign]: (state) =>
      pipe(set(inErrorLens, false), set(isLoadingLens, true))(state),

    // handle expired token
    [combineActions(onExpiredToken)]: (state) => {
      return { ...initialState };
    },
  },
  initialState
);

export const getWorkflows = (state) => state.workflows;
export const getWorkflowsData = (state) => state.workflows.data;
export const getWorkflowsLoading = (state) => state.workflows.loading;

export const getWorkflowsDataData = (state) => state.workflows.data.data;
export const getWorkflowsDataIncluded = (state) =>
  state.workflows.data.included;

export const getWorkflowsCurrent = (state) => state.workflows.current;
export const getWorkflowsCurrentData = (state) => state.workflows.current.data;
export const getWorkflowsCurrentIncluded = (state) =>
  state.workflows.current.included;

export const getWorkflowById = (workflowId) => (state) => {
  return (
    state.workflows.data.find((workflow) => workflow.id === workflowId) || {}
  );
};
