import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAppWorkspaces } from "@reducer";

const WorkspaceHomeRedirect = ({ children, ...rest }) => {
  const workspaces = useSelector(getAppWorkspaces);
  const [myWorkspace, setMyWorkspace] = useState(null);

  useEffect(() => {
    if (workspaces) {
      setMyWorkspace(workspaces[0].id);
    }
  }, [workspaces]);
  return (
    <Route {...rest}>
      {!myWorkspace ? (
        children
      ) : (
        <Redirect to={`/workspaces/${myWorkspace}/home`} />
      )}
    </Route>
  );
};

export default WorkspaceHomeRedirect;
