import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.hoverLight
        ? `&:hover {
      fill: ${theme.colors.lightGrey}; cursor: pointer;}`
        : ""}
    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black)" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const ArrowDown = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <path
        d="M21.2,7.6c-0.6-0.6-1.5-0.6-2.1,0l-6.6,6.6L5.9,7.6C5.3,7,4.4,7,3.8,7.6c-0.6,0.6-0.6,1.5,0,2.1l7.7,7.7
	c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4l7.7-7.7C21.8,9.1,21.8,8.2,21.2,7.6z"
      />
    </StyledSvg>
  );
};

export default ArrowDown;
