import * as R from "ramda";
import { useSelector } from "react-redux";

import { getPayloadsIncluded } from "../reducer";

const usePayloadsStatusEvents = () => {
  const payloadIncluded = useSelector(getPayloadsIncluded);
  const STATUS_EVENTS = {
    draft_with_review: {
      name: "Draft",
      event: "event_ready",
      button: "Ready",
      variant: "",
      textColor: "#4BAAC8",
      bgColor: "#1B7B99",
      iconColor: "#FFFFFF",
      position: 1,
      editable: true,
    },
    draft_without_review: {
      name: "Draft",
      event: "event_send",
      button: "Send",
      variant: "",
      textColor: "#4BAAC8",
      bgColor: "#1B7B99",
      iconColor: "#FFFFFF",
      position: 1,
      editable: true,
    },
    ready: {
      name: "Ready",
      event: "event_send",
      button: "Send",
      variant: "blue",
      textColor: "#4BAAC8",
      bgColor: "#CAF0FC",
      iconColor: "#4BAAC8",
      position: 2,
      editable: true,
    },
    pending: {
      name: "Pending",
      event: "none",
      button: "Pending",
      variant: "disabled",
      textColor: "#4BAAC8",
      bgColor: "#FFEFE5",
      iconColor: "#F8997B",
      position: 3,
      editable: true,
    },
    sending: {
      name: "Sending",
      event: "none",
      button: "Send",
      variant: "grey",
      textColor: "#4BAAC8",
      bgColor: "#FFEFE5",
      iconColor: "#4BAAC8",
      position: 4,
      editable: false,
    },
    failed: {
      name: "Failed",
      event: "event_send",
      button: "Send",
      variant: "",
      textColor: "#F45C2C",
      bgColor: "#F45C2C",
      iconColor: "#FFFFFF",
      position: 4,
      editable: true,
    },
    bounced: {
      name: "Bounced",
      event: "event_send",
      button: "Retry",
      variant: "primary",
      textColor: "#FFEFE5",
      bgColor: "#F8997B",
      iconColor: "#FFEFE5",
      position: 5,
      editable: false,
    },
    finished: {
      name: "Finished",
      event: "none",
      button: "",
      variant: "disabled",
      textColor: "#4BAAC8",
      bgColor: "#1B7B99",
      iconColor: "#FFFFFF",
      position: 6,
      editable: false,
    },
    replied: {
      name: "Replied",
      event: "",
      button: "",
      variant: "disabled",
      textColor: "#F45C2C",
      bgColor: "#CAF0FC",
      iconColor: "#F45C2C",
      position: 7,
      editable: false,
    },
    inactive: {
      name: "Inactive",
      event: "",
      button: "",
      variant: "disabled",
      textColor: "#F45C2C",
      bgColor: "#ABABAB",
      iconColor: "#F45C2C",
      position: 8,
      editable: false,
    },
    paused: {
      name: "Paused",
      event: "event_resume",
      button: "Send",
      variant: "disabled",
      textColor: "#4BAAC8",
      bgColor: "#1B7B99",
      iconColor: "#FFFFFF",
      position: 9,
      editable: true,
    },
    default: {
      name: "",
      event: "none",
      button: "",
      variant: "",
      textColor: "#4BAAC8",
      bgColor: "#ABABAB",
      iconColor: "#4BAAC8",
      position: 10,
      editable: false,
    },
  };

  const getStatusByPayload = (payload) => {
    const procesorAttrs = getProcesorByPayload(payload)?.attributes;

    let statusEvent = STATUS_EVENTS[procesorAttrs.status];

    if (procesorAttrs.status === "failed") {
      statusEvent["failMessage"] = procesorAttrs["fail_message"];
    }

    if (procesorAttrs.status === "paused") {
      statusEvent["statusMessage"] = procesorAttrs["status_message"];
    }

    const isCompleted = isPayloadValuesComplete(payload);

    if (procesorAttrs.status === "pending" && isCompleted) {
      if (payload.meta.has_sequence_review) {
        return STATUS_EVENTS["draft_with_review"];
      } else {
        return STATUS_EVENTS["draft_without_review"];
      }
    }

    return statusEvent;
  };

  const getProcesorByPayload = (payload) => {
    return payloadIncluded.filter(
      (item) =>
        item.type === "sequence_processor" &&
        item.attributes.project_person_payload_id === payload.id
    )[0];
  };

  const byStatusToParams = (byStatus) => {
    switch (byStatus) {
      case "queue":
        return ["sending"];
      case "finished":
        return ["finished", "replied"];
      case "review":
        return "ready";
      default:
        return byStatus;
    }
  };

  //Fields empty - total
  const payloadValuesEmpty = (payload) => {
    const data = payload?.attributes?.payload_values.data;

    if (!data) return {};

    let emptyCount = 0;
    const sumEmptyValues = (field) => {
      if (!data[field["name"]]) emptyCount++;
    };

    R.forEachObjIndexed(sumEmptyValues, usedFields(payload));

    return emptyCount;
  };

  const isPayloadValuesComplete = (payload) => {
    return payloadValuesEmpty(payload) === 0;
  };

  let usedFields = (payload) => {
    const isUsed = (field) => field["used"] && field["used"] === true;
    let defaults = R.filter(
      isUsed,
      payload.attributes.payload_fields?.defaults
    );
    let customs = R.filter(isUsed, payload.attributes.payload_fields?.customs);
    return defaults.concat(customs);
  };

  return [
    getStatusByPayload,
    byStatusToParams,
    payloadValuesEmpty,
    isPayloadValuesComplete,
  ];
};

export default usePayloadsStatusEvents;
