import React from "react";
import theme from "../../../theme";

import Box from "../../ui/box";
import Card from "../../ui/card";
import Image from "../../../assets/img/sequence.svg";
import Flex from "../../ui/flex";

//container
import NewSequenceModalContainer from "../../../containers/sequences/NewSequenceModalContainer";

const SequencesIntro = ({ viewport }) => {
  return (
    <Flex
      paddingTop={"64px"}
      alignItems={"center"}
      width={"100%"}
      flexDirection={"column"}
      justifyContent={"flex-start"}
      px={{ sm: 2, md: 0 }}
      overflow="hidden"
      backgroundColor={theme.colors.lighterGrey}
    >
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        width={viewport > 2000 ? "85%" : "95%"}
        mt={"30px"}
        backgroundColor={theme.colors.lighterGrey}
      >
        <Card
          imageBlock={Image}
          textBlock={
            <Box marginTop="20px">
              <p
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                Sequences allows you to quickly and easily write and create
                groups of emails templates that will help you when contacting
                the candidates.
              </p>
              <p
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                A sequence can contain one or more steps of messages and each
                message is entirely created by you.
              </p>
              <p
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                You can also use variables that will autocomplete with the
                candidate's information.
              </p>
              <Flex alignItems="center">
                <NewSequenceModalContainer
                  variant="secondaryLight"
                  showButton
                />
                <p
                  style={{
                    fontSize: "1.1rem",
                    fontWeight: "300",
                    lineHeight: "1.5rem",
                  }}
                >
                  to be able to start messaging
                </p>
              </Flex>
            </Box>
          }
        ></Card>
      </Flex>
    </Flex>
  );
};

export default SequencesIntro;
