import React from "react";
import { useForm } from "react-hook-form";

//components
import Modal from "../../ui/modal/Modal";
import Flex from "../../ui/flex";
import Box from "../../ui/box";
import Button from "../../ui/button/index";
import Submit from "../../ui/input-submit";
import Input from "../../ui/input/index";
import Dropdown from "../../ui/dropdown/index";
import InputButton from "../../ui/input-import-csv/index";
import DatePicker from "../../ui/input-datepicker/index";
import Toggle from "@ui/toggle-switch";

//Icons
import Close from "../../ui/icons/close";
import Goal from "../../ui/icons/goal";

import { isArchivedProject } from "@utils/project-helper";


const EditProjectModal = ({
  closeModal,
  showModal,
  dirtyChanges,
  handleSubmitClick,
  handleChange,
  handleDropdown,
  handleDropdownSelect,
  handleDatepicker,
  workflows,
  currentWorkflow,
  project,
  selectedFile,
  handleInputAddPeopleChange,
  handleVisibility,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();

  const handleCLoseModal = () => {
    clearErrors();
    closeModal();
  };

  return (
    <>
      {showModal === true && (
        <Modal
          fullHeader
          formContent
          onSubmit={handleSubmit(handleSubmitClick)}
          title={"Show Project"}
          icon={<Close variant="grey"></Close>}
          closeHandler={handleCLoseModal}
          buttons={[
            <Button
              key="cancel"
              variant="secondaryLine"
              content="Cancel"
              onClick={handleCLoseModal}
            />
          ]}
        >
          <Flex width="90%" justifyContent="space-evenly">
            <Box width={"35%"}>
              <Input
                {...register("title", { required: true })}
                name="title"
                required
                error={errors.title ? true : false}
                errorMsg="This field is required"
                label="Title"
                defaultValue={project.attributes.name}
                mb={"15px"}
                mt={2}
                placeholder="Enter Project Title"
                value={dirtyChanges.title}
                onChange={handleChange}
                autoFocus
                tabIndex="1"
                disabled
              />
              <Dropdown
                refs={(e) => register({ name: "priority", required: true })}
                error={errors.priority ? true : false}
                errorMsg="This field is required"
                name="priority"
                value={dirtyChanges.priority || project.attributes.priority}
                handleDropdown={handleDropdownSelect}
                handleChange={(val) => {
                  setValue("priority", val);
                }}
                formStyle
                variant="blue"
                required
                options={["High", "Medium", "Low"]}
                label="Priority"
                mb={4}
                placeholder="Set Priority"
                tabIndex="2"
                disabled
              />
              <Dropdown
                refs={(e) => register({ name: "status", required: true })}
                error={errors.status ? true : false}
                errorMsg="This field is required"
                name="status"
                value={dirtyChanges.status || project.attributes.status}
                handleDropdown={handleDropdown}
                handleChange={(val) => {
                  setValue("status", val);
                }}
                formStyle
                variant="blue"
                required
                options={["Active", "Inactive"]}
                label="Status"
                mb={4}
                placeholder="Set Status"
                tabIndex="3"
                disabled
              />
              <DatePicker
                name="startDate"
                label="Start Date"
                mb={4}
                value={dirtyChanges.startDate || project.attributes.start_date}
                handleDatepicker={handleDatepicker}
                tabIndex="4"
                disabled
              />
              <DatePicker
                name="deadline"
                maxDate
                label="Deadline"
                mb={4}
                value={dirtyChanges.deadline || project.attributes.deadline}
                handleDatepicker={handleDatepicker}
                tabIndex="5"
                disabled
              />
            </Box>

            <Box width={"55%"}>
              <Input
                name="goal"
                showCharLimitCount
                maxLength="100"
                icon={<Goal variant="blue"></Goal>}
                label="Objective"
                defaultValue={project.goal}
                mb={2}
                mt={2}
                placeholder="Describe the objective for this project"
                value={dirtyChanges.goal}
                onChange={handleChange}
                tabIndex="6"
                disabled
              />
              <Dropdown
                disabled={true}
                name="workflow"
                value={dirtyChanges.workflow || currentWorkflow?.data?.attributes.name}
                handleDropdown={handleDropdownSelect}
                formStyle
                variant="blue"
                options={workflows?.map((workflow) => workflow.attributes.name)}
                label="Workflow"
                mb={4}
                placeholder="Select Workflow"
                tabIndex="7"
              />
              <Flex
                justifyContent="space-between"
                alignItems="center"
                marginTop="25px"
              >
                <Toggle
                  customeLabel="Visible (Chrome Extension)"
                  initialValue={dirtyChanges.visible || project.attributes.visible}
                  handleChange={handleVisibility}
                  name="visible"
                  disabled
                />
              </Flex>

            </Box>
          </Flex>
        </Modal>
      )}
    </>
  );
};

export default EditProjectModal;
