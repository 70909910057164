import { useEffect, useState } from "react";
import * as R from "ramda";

import { useCountries, useIntegrations } from ".";
import { toast } from "react-toastify";

const useSequenceForm = (
  sequence,
  sequenceSteps,
  dirtyChanges,
  setDirtyChanges,
) => {
  const [inputFocused, setInputFocused] = useState(null);
  const [subjectCursorPosition, setSubjectCursorPosition] = useState(0);

  const { countries } = useCountries();
  const { searchCredencialNameById, searchCredencialEmailById, searchCredencialTypeById } = useIntegrations();
  const candidateVariables = ["first_name", "last_name", "title", "company_name"];
  const projectVariables = ["project_title", "workspace_title"];

  const stepLabels = sequenceSteps?.map((s) => s["attributes"]["name"]);

  const handleInputChange = (event, index) => {
    if (index !== undefined && index !== null) {
      let steps = { ...dirtyChanges.steps };
      let field = event.target.name.split(".")[2];
      steps[index][field] = event.target.value;

      setDirtyChanges({
        ...dirtyChanges,
        steps,
      });
    } else {
      setDirtyChanges({
        ...dirtyChanges,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleSelectCountry = (event, value) => {
    let country = countries.filter(function (e) {
      return e.name === value;
    })[0];
    setDirtyChanges({
      ...dirtyChanges,
      ["holiday_country"]: country["code"],
    });
  };

  const handleAllowSendHoliday = (value) => {
    setDirtyChanges({
      ...dirtyChanges,
      ["allow_send_holiday"]: value,
    });
  };

  const handleAllowSendWeekend = (value) => {
    setDirtyChanges({
      ...dirtyChanges,
      ["allow_send_weekend"]: value,
    });
  };

  const handleInputName = (value, name, index) => {
    let steps = { ...dirtyChanges.steps };
    let field = name.split(".")[2];
    steps[index][field] = value;

    setDirtyChanges({
      ...dirtyChanges,
      steps,
    });
  };

  const handleDropdown = (name, value, index) => {
    if (index !== undefined && index !== null) {
      let steps = { ...dirtyChanges.steps };
      let field = name.split(".")[2];
      steps[index][field] = value;

      if (field === "provider_type") {
        steps[index]["credential"] = null;
        steps[index]["credential_email"] = null;
        steps[index]["provider_id"] = null;
        steps[index]["from"] = null;
      }

      if (field === "provider_id") {
        steps[index]["provider_id"] = value;
        steps[index]["provider_type"] = searchCredencialTypeById(value);
        steps[index]["credential"] = searchCredencialNameById(value);
        steps[index]["credential_email"] = searchCredencialEmailById(value);
        steps[index]["from"] = null;
      }

      setDirtyChanges({
        ...dirtyChanges,
        steps,
      });
    } else {
      setDirtyChanges({
        ...dirtyChanges,
        [name]: value,
      });
    }
  };

  const handleInputFocus = (input) => {
    setInputFocused(input);
  };

  const handleSubjectBlur = (event) => {
    setSubjectCursorPosition(event.target.selectionStart);
  };

  const handleSubmitNewVariable = (index) => {
    let payload_fields = dirtyChanges.payload_fields || sequence?.attributes.payload_fields;

    if (!customFieldsNames().includes(dirtyChanges.customFieldsName)) {
      payload_fields["customs"].push({
        type: dirtyChanges.customFieldsType,
        name: dirtyChanges.customFieldsName,
      });
    } else {
      toast.error("Name already taken.");
    }

    delete dirtyChanges["customFieldsName"];
    delete dirtyChanges["customFieldsType"];

    setDirtyChanges({
      ...dirtyChanges,
      ["payload_fields"]: payload_fields,
    });
  };

  const isTabDirty = (index) => {
    let dirtyTab = undefined;
    if (index === 0) {
      dirtyTab = { ...dirtyChanges };
      delete dirtyTab["steps"];
    } else {
      dirtyTab = { ...dirtyChanges.steps[index - 1] };
      delete dirtyTab["id"];
    }

    return !R.isEmpty(dirtyTab);
  };

  const handleInsertSubjectField = (field, index) => {
    let steps = { ...dirtyChanges.steps };
    let template_subject = steps[index].template_subject;
    steps[index].template_subject = [
      template_subject.slice(0, subjectCursorPosition),
      "{{" + field + "}}",
      template_subject.slice(subjectCursorPosition),
    ].join("");
    setDirtyChanges({
      ...dirtyChanges,
      steps,
    });
  };

  const customFieldsNames = () => {
    let customs = dirtyChanges.payload_fields?.customs || sequence?.attributes.payload_fields?.customs;
    const fieldName = field => field["name"];
    return R.map(fieldName, customs);
  };

  const defaultFieldsNames = () => {
    return candidateVariables.concat(projectVariables);
  };

  const handleHasProviderSignature = (name, value, index) => {
    let steps = { ...dirtyChanges.steps };
    let field = name.split(".")[2];
    steps[index][field] = value;

    setDirtyChanges({
      ...dirtyChanges,
      steps,
    });
  };

  return {
    handleInputChange,
    handleSelectCountry,
    handleAllowSendWeekend,
    handleAllowSendHoliday,
    handleInputName,
    handleDropdown,
    handleInputFocus,
    handleSubjectBlur,
    handleSubmitNewVariable,
    handleInsertSubjectField,
    customFieldsNames,
    isTabDirty,
    stepLabels,
    inputFocused,
    defaultFieldsNames,
    candidateVariables,
    projectVariables,
    handleHasProviderSignature,
  };
};

export default useSequenceForm;
