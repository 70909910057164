import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.linkable
        ? `&:hover {
       fill: ${theme.colors.lightRed}; cursor: pointer;}`
        : ""}

    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black);" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const Warning = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <circle cx="12.3" cy="18.5" r="1.5" />
      <path
        d="M12.6,15.5h-0.5c-0.7,0-1.2-0.6-1.2-1.2V7.8c0-0.7,0.6-1.2,1.2-1.2h0.5c0.7,0,1.2,0.6,1.2,1.2v6.4
	C13.8,14.9,13.2,15.5,12.6,15.5z"
      />
      <path
        d="M20.6,23.3H4.5c-1.4,0-2.6-0.7-3.3-1.9c-0.7-1.2-0.6-2.6,0-3.7l8-14c0,0,0,0,0,0c0.7-1.2,1.9-1.9,3.2-1.9
	c0,0,0,0,0,0c1.3,0,2.5,0.7,3.2,1.9l8.1,14c0.7,1.1,0.7,2.5,0,3.7C23.2,22.5,22,23.3,20.6,23.3z M10.5,4.4l-8,14
	c-0.4,0.7-0.4,1.5,0,2.2c0.4,0.7,1.2,1.2,2,1.2h16.1c0.8,0,1.6-0.4,2-1.2c0.4-0.7,0.4-1.5,0-2.2l-8.1-14c-0.4-0.7-1.2-1.1-1.9-1.1h0
	C11.7,3.3,11,3.7,10.5,4.4z"
      />
    </StyledSvg>
  );
};

export default Warning;
