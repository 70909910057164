import React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
 * circle {
  fill: #dadada;
}
 * path {
  fill: #ABABAB;
}
`;

const StatInactive = ({
  ...props
}) => {
  return (
    <StyledSvg
      {...props}
      viewBox="0 0 25 25">
      <g>
        <circle cx="12.5" cy="12.5" r="12" />
        <path d="M18.5,14.5h-12c-1.1,0-2-0.9-2-2s0.9-2,2-2h12c1.1,0,2,0.9,2,2S19.6,14.5,18.5,14.5z" />
      </g>
    </StyledSvg>
  )
}

export default StatInactive;


