import React, { useEffect } from "react";
import styled from "styled-components";
import theme from "../../../theme";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

import Button from "../../ui/button";
import Flex from "../../ui/flex";
import ImageOne from "../../../assets/img/contentManagement.png";
import ImageTwo from "../../../assets/img/userProfile.png";

import Banner from "../../../assets/img/bannerBackground.png";

import yourDataImage from "../../../assets/img/yourDataImage.png";
import superchargeImage from "../../../assets/img/superchargeImage.png";
import collaborateImage from "../../../assets/img/collaborateImage.png";
import seamlessImage from "../../../assets/img/seamlessImage.png";
import natePulley from "../../../assets/img/natePulley.png";
import jerryTing from "../../../assets/img/jerryTing.png";

import Image1 from "../../../assets/img/imagen1.png";
import Image2 from "../../../assets/img/imagen2.png";
import Image3 from "../../../assets/img/imagen3.png";
import Image4 from "../../../assets/img/imagen4.png";
import Image5 from "../../../assets/img/imagen5.png";
import Image6 from "../../../assets/img/imagen6.png";
import Image7 from "../../../assets/img/imagen7.png";


import { GTMProvider, useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

//icons
import Valid from "../../ui/icons/valid";
import Facebook from "../../ui/icons/facebook";
import Twitter from "../../ui/icons/twitter";
import Mail from "../../ui/icons/message";
import Linkedin from "../../ui/icons/linkedin";
import WebIcon from "../../ui/icons/web";

const StyledButton = styled.a`
  font-family: inherit;
  text-decoration: none;
  font-size: 1rem;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 159px;
  height: 38px;
  padding: 10px 15px;
  margin: 10px;
  border-radius: 4px;
  font-weight: 500;
  border: none;
  color: ${theme.colors.white};
  background-color: ${theme.colors.orange3};

  &:hover {
    box-shadow: 0px 2px 2px lightgray;
    background: ${theme.colors.redGradient};
    cursor: pointer;
  }
`;

const StyledBanner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: "64px";
  background-repeat: no-repeat;
  background: url(${Banner}) lightgray 0.31px 0px / 195.455% 262.071% no-repeat;
  background-size: cover;
  background-position: center;

  padding: 150px 0;
  @media (max-width: 660px) {
    width: 100%;
    height: 90vh;
  }
`;

const StyledContainer = styled.div`
  margin: 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  background-color: white;
  padding: 0;
  @media (max-width: 660px) {
    width: 100%;
  }
`;

const StyledFlex = styled.div`
  width: 900px;
  @media (max-width: 660px) {
    width: 100%;
    margin-left: 15px;
  }
`;

const StyledContainerBlue = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  background-color: ${theme.colors.lightBlue};

  @media (max-width: 660px) {
    width: 100%;
    height: 100%;
  }
`;

const StyledContainerBlueTertiary = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 50px 0 150px;
  background-color: ${theme.colors.lightBlueTertiary};

  @media (max-width: 660px) {
    width: 100%;
    height: 100%;
  }
`;

const StyledBlueArea = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  width: 70%;
  height: 100%;
  margin: 20px auto;

  @media (max-width: 660px) {
    width: 90%;
    flex-flow: wrap;
    height: 100%;
    margin-bottom: 10px;
    ${(props) => (props.reverse ? "flex-direction: column-reverse" : null)}
  }

  @media (min-width: 660px) and (max-width: 1260px) {
    width: 90%;
  }
`;

const StyledContainerWhite = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;

  @media (max-width: 660px) {
    width: 100%;
    height: auto;
  }
`;

const StyledWhiteArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 70%;
  height: 100%;
  margin: 120px 0px;

  @media (max-width: 660px) {
    width: 90%;
    justify-content: center;
    flex-flow: wrap;
    height: 100%;
    margin-bottom: 60px;
  }

  @media (min-width: 660px) and (max-width: 1260px) {
    width: 95%;
    margin: 50px 0px;
  }
`;

const StyledfeatureArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 30%;

  @media (max-width: 660px) {
    width: 100%;
    ${(props) => (props.marginBottom ? "margin-bottom: 30px;" : null)}
  }
`;

const StyledfeatureBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  @media (max-width: 660px) {
    width: 100%;
  }
`;

const StyledRow = styled.div`
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: space-around;
  width: 100%;

  @media (max-width: 660px) {
    ${(props) => (props.reverse ? "flex-direction: column-reverse" : null)}
  }
`;

const StyledTitle = styled.div`
  font-size: 4rem;
  text-align: left;
  font-weight: 500;
  width: 900px;
  color: ${theme.colors.white};
  font-family: ${theme.fonts[0]};
  
  @media (max-width: 660px) {
    font-size: 3rem;
    width: 90%;
  }
`;

const StyledTitleSmall = styled.div`
  font-size: ${(props) => (props.bold ? "1.9rem" : "2rem")};
  text-align: left;
  font-weight: ${(props) => (props.bold ? 800 : 500)};
  color: ${theme.colors.grey2};
  font-family: ${theme.fonts[0]};
  margin-left: 10px;
  margin-bottom: 10px;

  ${(props) => (props.bold ? `margin-bottom: 20px;` : null)};

  @media (min-width: 660px) and (max-width: 1260px) {
    font-size: ${(props) => (props.bold ? "1.5rem" : "1.6rem")};
  }
`;

const StyledSlogan = styled.div`
  font-size: 1.5rem;
  line-height: 2.2rem;
  margin: 30px 0px;
  text-align: left;
  font-weight: 400;
  display: inline-box;
  width: 900px;
  color: ${theme.colors.white};
  font-family: ${theme.fonts[0]};
  
  @media (max-width: 660px) {
    width: 90%;
    font-size: 1.3rem;
  }
`;

const StyledSpan = styled.span`
  font-weight: 700;
  color: ${theme.colors.orange2};
`;

const StyledTextBox = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  width: 50%;
  color: ${theme.colors.grey};
  font-family: ${theme.fonts[0]};

  @media (max-width: 660px) {
    width: 90%;
    margin-top: 30px;
    margin-bottom: 0px;
  }

  @media (min-width: 660px) and (max-width: 1260px) {
    width: 40%;
    margin-top: 40px;
    margin-bottom: 40px;
  }
`;

const StyledTextBoxSmall = styled.div`
  margin: 100px 0px;
  width: 50%;
  color: ${theme.colors.grey};
  font-family: ${theme.fonts[0]};

  @media (max-width: 660px) {
    margin: 0px;
    flex-flow: wrap;
    width: 100%;
    height: auto;
  }
  @media (min-width: 660px) and (max-width: 1260px) {
    width: 50%;
  }
`;

const StyledSubtitleSmall = styled("div")({
  width: "100%",
  fontSize: "1.4rem",
  color: (tertiary) => tertiary ? theme.colors.blueTertiary : theme.colors.orange3,
  fontFamily: theme.fonts[0],
  margin: "15px 0px",
  fontWeight: 600,
});


const StyledSubtitleBig = styled.div`
  width: 100%;
  font-size: 2.3rem;
  margin: 50px 0px 25px;
  font-weight: 500;
  color: ${theme.colors.darkGrey};
  text-align: center;
  padding-bottom: 50px;
  @media (max-width: 660px) {
    padding-bottom: 0px;
  }
`;

const StyledText = styled.div`
  font-size: 1.1rem;
  line-height: 1.6rem;
  margin: 30px 0px 10px;
  font-weight: 400;
`;

const StyledImageTitle = styled.div`
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${theme.colors.blueTertiary};
  margin: 20px 0px;
  font-weight: 600;
  width: 200px;
  height: 50px;
`;

const StyledImageText = styled.div`
  font-size: 1rem;
  line-height: 1.6rem;
  color: ${theme.colors.grey2};
  margin: 10px 0px;
  font-weight: 400;
  text-align: center;
`;

const StyledImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  @media (max-width: 660px) {
    flex-direction: column;
  }
`;

const StyledImageContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
text-align: center;
max-width: 300px;
padding: 20px;
`;

const StyledTextWhite = styled.div`
  font-size: 1.1rem;
  line-height: 1.6rem;
  margin: 10px 0px;
  font-weight: 200;
  width: 90%;
  color: white;
`;

const StyledImageBox = styled.div`
  width: 500px;
  height: 450px;
  color: ${theme.colors.grey};
  font-family: ${theme.fonts[0]};
  background-image: url(${(props) => props.image});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 660px) {
    width: 100%;
    height: 200px;
    margin-top: 10px;
    margin-bottom: 0px;
  }
  @media (min-width: 660px) and (max-width: 1260px) {
    width: 70%;
    height: 400px;
  }
`;

const StyledImageBoxSmall = styled.div`
  width: 150px;
  height: 150px;
  color: ${theme.colors.grey};
  font-family: ${theme.fonts[0]};
  background-image: url(${(props) => props.image});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 660px) {
    width: 70%;
    height: 150px;
    margin-top: 10px;
    margin-bottom: 0px;
  }
  @media (min-width: 660px) and (max-width: 1260px) {
    width: 70%;
    height: 100px;
  }
`;



// const StyledImageBuildBy = styled.div`
//   width: 400px;
//   height: 400px;
//   color: ${theme.colors.grey};
//   font-family: ${theme.fonts[0]};
//   background-image: url(${(props) => props.image});
//   background-size: cover;
//   background-position: center;
//   background-repeat: no-repeat;
//   border-radius: 100%;

//   @media (max-width: 660px) {
//     width: 100%;
//     height: 400px;
//     margin-top: 10px;
//     margin-bottom: 0px;
//   }
//   @media (min-width: 660px) and (max-width: 1260px) {
//     width: 50%;
//   }
// `;

const CardDivider = styled.div`
  width: 100%;
  height: 40px;
  background: rgb(201, 201, 201);
  background: linear-gradient(
    180deg,
    rgba(220, 220, 220, 1) 0%,
    rgba(255, 255, 255, 1) 60%
  );
`;

const CardDividerTop = styled.div`
  width: 100%;
  height: 20px;
  background: rgb(201, 201, 201);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(230, 230, 230, 1) 80%
  );
`;

const List = styled.ul`
  width: 100%;
  margin-top: 30px;
`;

const ListItem = styled.li`
  font-size: 1.2rem;
  padding: 5px 0;
  line-height: 1.8rem;
  color: ${theme.colors.darkGrey};
`;

const StyledFooter = styled.div`
  font-family: "Open Sans", sans-serif;
  min-height: 20vmin;
  background: ${theme.colors.grey};
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: auto;
  padding: 20px 0px;
`;

const StyledFooterArea = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 660px) {
    width: 85%;
    justify-content: center;
    margin: 15px 0px;
  }
  @media (min-width: 660px) and (max-width: 1260px) {
    margin: 15px 0px;
  }
`;

const StyledFooterRow = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;

  @media (max-width: 660px) {
    width: 80%;
    flex-direction: column;
  }
`;
const StyledFooterDivider = styled.div`
  margin: 15px 0px;
  width: 70%;
  border-bottom: 1px solid #666;

  @media (max-width: 1260px) {
    width: 90%;
    margin: 0px;
  }
`;

const StyledFooterLink = styled.a`
  text-decoration: none;
  text-transform: uppercase;
  color: ${theme.colors.white};
  margin-right: 20px;
  font-size: 0.7rem;

  &:hover {
    cursor: pointer;
    color: ${theme.colors.lightBlue};
  }

  @media (max-width: 660px) {
    margin-right: 0px;
    padding: 0px 5px;
    color: ${theme.colors.lighterBlue};
  }

  @media (min-width: 660px) and (max-width: 1260px) {
    margin-right: 20px;
  }
`;


const TestimonialContainer = styled.div`
  width: 100%; 
  justify-content: space-around; 
  background-color: transparent;
  display: flex;
  flex-direction: row;
  @media (max-width: 660px) {
    width: 100%;
    flex-direction: column;
    height: auto;
  }
`;

const TestimonialWrapper = styled.div`
  border-radius: 20px;
  background: #FFF;
  width: 400px;
  height: 378px;
  padding: 32px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
  justify-content: "space-around";
  margin: 20px 0;
  @media (max-width: 660px) {
    width: 100%;
    height: auto;
  }
`;



const TestimonialText = styled.div`
  color: ${theme.colors.grey2};
  font-family: ${theme.fonts[0]};
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130.6%; /* 26.12px */
  padding-bottom: 20px;
  min-height: 245px;
  text-align: left;
`;

const TestimonialTitle = styled.div`
  color: ${theme.colors.blueTertiary};
  font-family: ${theme.fonts[0]};
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130.6%; /* 26.12px */
`;

const TestimonialSubTitle = styled.div`
  color: ${theme.colors.blueTertiary};
  font-family: ${theme.fonts[0]};
  font-size: 1.2rem;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  line-height: 130.6%; /* 26.12px */
`;

const StyledTestimonialImage = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  color: ${theme.colors.grey};
  font-family: ${theme.fonts[0]};
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 660px) {
    width: 80px;
    height: 70px;
  }
`;

const CompanyWrapper = styled.div`
  width: 70%;
  margin-top: 50px;
  justify-content: center;
  flex-wrap: wrap;
  display: flex;
  @media (max-width: 660px) {
    width: 100%;
    display: flex;
  }
`;

const StyledCompanyImage = styled.div`
  width: 120px;
  min-width: 120px;
  height: 40px;
  color: ${theme.colors.grey};
  font-family: ${theme.fonts[0]};
  background-image: url(${(props) => props.image});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 5px 10px
`;



const StyledFooterIconLink = styled.a`
  text-decoration: none;
  margin-left: 20px;
  height: 25px;
  width: 25px;

  @media (max-width: 660px) {
    margin-right: 0px;
  }
`;



const LandingPage = () => {
  const dispatch = useDispatch();
  const viewportWidht = window.innerWidth;

  const sendDataToGTM = useGTMDispatch();

  const openLink = (path) => {
    dispatch(push(path));
  };

  const handleJoinNow = () => {
    openLink(`/sign_up`);
  };

  const handleContact = () => {
    // openLink(`https://calendly.com/teamsourced/learn-about-teamsourced`);
    window.location.href = "https://calendly.com/teamsourced/learn-about-teamsourced";
  };

  useEffect(() => {
    sendDataToGTM({ event: "mount", customData: "LandingPage" });
  }, []);

  return (
    <Flex
      marginTop={"64px"}
      alignItems={"center"}
      width={"100%"}
      flexDirection={"column"}
      justifyContent={"flex-start"}
      px={0}
      py={0}
      overflow="hidden"
      backgroundColor={theme.colors.white}
    >
      <StyledBanner>
        <StyledTitle>Your Data. Your Career.</StyledTitle>
        <StyledSlogan>
          The Recruiting Platform that moves with you: portable, powerful and
          free forever.
        </StyledSlogan>
        <StyledFlex
          alignItems="flex-start"
          backgroundColor={"transparent"}
          style={{ marginLeft: "30px" }}
        >
          <>
            <Button
              variant="servicesLight"
              content="Try for free!"
              size="big"
              width="180px"
              onClick={() => handleJoinNow()}
            />
            <Button
              variant="servicesLight"
              content="We Recruit for You"
              size="big"
              width="180px"
              margin="10px 0"
              onClick={() => dispatch(push("/services"))}
            />
          </>
        </StyledFlex>
      </StyledBanner>

      <StyledContainer>
        <StyledSubtitleBig style={{ color: theme.colors.orange3 }} secondary>
          The Recruiter's Secret Weapon
        </StyledSubtitleBig>
        <StyledRow reverse>
          <StyledTextBox>
            <StyledSubtitleSmall tertiary>
              The ultimate platform for all your recruiting needs
            </StyledSubtitleSmall>
            <List>
              <ListItem>
                Save and organize talent profiles across multiple work spaces
              </ListItem>
              <ListItem>
                Build your own personal talent database that stays with you
                throughout your career
              </ListItem>
              <ListItem>
                Collaborate seamlessly with your team for a cleaner, more
                efficient workflow
              </ListItem>
              <ListItem>Access a lifetime of free CRM functionality</ListItem>
              <ListItem>
                Integrate with your existing Gmail and Microsoft email
              </ListItem>
            </List>
          </StyledTextBox>
          <StyledImageBox image={ImageOne}></StyledImageBox>
        </StyledRow>

        <StyledRow>
          <StyledImageBox image={ImageTwo}></StyledImageBox>
          <StyledTextBox>
            <StyledSubtitleSmall>Coming Soon!</StyledSubtitleSmall>
            <StyledText>
              We're working on integrations with popular ATS systems like
              Greenhouse, Ashby, and Lever to provide an even more seamless
              recruiting experience. Sign up now and be the first to know when
              these integrations are available!
            </StyledText>
          </StyledTextBox>
        </StyledRow>
      </StyledContainer>

      <StyledContainerBlue>
        <StyledBlueArea>
          <StyledSubtitleBig primary>
            Our Features Stand Out, and You Will Too
          </StyledSubtitleBig>
          <StyledImageWrapper>
            <StyledImageContainer>
              <StyledImageBoxSmall
                width="50px"
                image={yourDataImage}
              ></StyledImageBoxSmall>
              <StyledImageTitle>YOUR DATA MOVES WITH YOU</StyledImageTitle>
              <StyledImageText>
                Preserve valuable context and information around the people you
                reach out to throughout your career.
              </StyledImageText>
            </StyledImageContainer>
            <StyledImageContainer>
              <StyledImageBoxSmall
                width="50px"
                image={collaborateImage}
              ></StyledImageBoxSmall>
              <StyledImageTitle>COLLABORATE</StyledImageTitle>
              <StyledImageText>
                Steamline Your Workflow: effortlessly manage multiple
                workspaces, projects and collaborators in a central hub.{" "}
              </StyledImageText>
            </StyledImageContainer>
            <StyledImageContainer>
              <StyledImageBoxSmall
                width="50px"
                image={superchargeImage}
              ></StyledImageBoxSmall>
              <StyledImageTitle>SUPERCHARGE YOUR OUTREACH</StyledImageTitle>
              <StyledImageText>
                Transform your outreach with our powerful email sequencing tool.
                Easily connect your email account (or alias) and create
                personalized, multistage campaigns to engage and drive
                responses.
              </StyledImageText>
            </StyledImageContainer>
            <StyledImageContainer>
              <StyledImageBoxSmall
                width="50px"
                image={seamlessImage}
              ></StyledImageBoxSmall>
              <StyledImageTitle>SEAMLESS INTEGRATIONS</StyledImageTitle>
              <StyledImageText>
                Easily connect with Google, Hotmail, and industry-leading ATS
                systems coming soon!
              </StyledImageText>
            </StyledImageContainer>
          </StyledImageWrapper>
          {/* <StyledImageBuildBy image={BuildByImg}></StyledImageBuildBy> */}
        </StyledBlueArea>
      </StyledContainerBlue>

      <StyledContainerBlueTertiary>
        <Flex
          flexDirection="column"
          style={{ width: "80%", background: "none", textAlign: "center" }}
        >
          <StyledBlueArea>
            <StyledSubtitleBig primary>Testimonials</StyledSubtitleBig>
          </StyledBlueArea>
          <TestimonialContainer>
            <TestimonialWrapper key="1">
              <TestimonialText>
                My favorite feature of the TeamSourced platform is their
                delegated sending (including aliases). Using it enabled them to
                engage talent as our team and allowed us to make a hire a year
                after they contacted a candidate for us. The long term network
                effects are amazing.
              </TestimonialText>
              <Flex flexDirection="row">
                <StyledTestimonialImage
                  image={jerryTing}
                ></StyledTestimonialImage>
                <Flex flexDirection="column" style={{ paddingLeft: 20 }}>
                  <TestimonialTitle>Jerry Ting</TestimonialTitle>
                  <TestimonialSubTitle>
                    Founder and CEO, Evisort
                  </TestimonialSubTitle>
                </Flex>
              </Flex>
            </TestimonialWrapper>
            <TestimonialWrapper key="2">
              <TestimonialText>
                TeamSourced was in place when I started leading talent
                operations at Bolt and had done a great job of building out a
                lot of the initial process in place. They were great partners as
                Bolt began to scale up to hiring over 200 people in a short
                period of time.
              </TestimonialText>
              <Flex flexDirection="row">
                <StyledTestimonialImage
                  image={natePulley}
                ></StyledTestimonialImage>
                <Flex flexDirection="column" style={{ paddingLeft: 20 }}>
                  <TestimonialTitle>Nate Pulley</TestimonialTitle>
                  <TestimonialSubTitle>
                    Head of Talent Operations, Bolt
                  </TestimonialSubTitle>
                </Flex>
              </Flex>
            </TestimonialWrapper>
          </TestimonialContainer>
        </Flex>
      </StyledContainerBlueTertiary>

      <StyledContainerWhite>
        <StyledWhiteArea style={{ width: "100%", marginTop: "20px" }}>
          <StyledfeatureArea
            style={{ width: "100%", alignItems: "center", marginTop: "50px" }}
            marginBottom
          >
            <StyledTitleSmall
              style={{ color: theme.colors.orange3, textAlign: "center" }}
            >
              Companies who use TeamSourced
            </StyledTitleSmall>
            <CompanyWrapper>
              <StyledCompanyImage image={Image1}></StyledCompanyImage>
              <StyledCompanyImage image={Image2}></StyledCompanyImage>
              <StyledCompanyImage image={Image3}></StyledCompanyImage>
              <StyledCompanyImage image={Image4}></StyledCompanyImage>
              <StyledCompanyImage image={Image5}></StyledCompanyImage>
              <StyledCompanyImage image={Image6}></StyledCompanyImage>
              <StyledCompanyImage image={Image7}></StyledCompanyImage>
            </CompanyWrapper>
          </StyledfeatureArea>
        </StyledWhiteArea>
      </StyledContainerWhite>

      <StyledContainerWhite>
        <StyledWhiteArea style={{ width: "100%", marginTop: "20px" }}>
          <StyledfeatureArea
            style={{ width: "100%", alignItems: "center", marginTop: "0px" }}
            marginBottom
          >
            <StyledTitleSmall style={{ textAlign: "center" }}>
              Recruit Smarter, Not Harder with TeamSourced
            </StyledTitleSmall>
            <Button
              variant="servicesLight"
              content="Get Started"
              size="big"
              marginLeft={0}
              style={{
                marginLeft: 0,
              }}
              onClick={() => handleJoinNow()}
            />
          </StyledfeatureArea>
        </StyledWhiteArea>
      </StyledContainerWhite>

      <CardDividerTop></CardDividerTop>
      <StyledFooter>
        <StyledFooterRow>
          <StyledFooterArea>
            <StyledFooterLink
              href="https://boards.greenhouse.io/teamsourced"
              target="_blank"
            >
              careers
            </StyledFooterLink>

            <StyledFooterLink
              href="https://blog.teamsourced.com/"
              target="_blank"
            >
              blog
            </StyledFooterLink>

            <StyledFooterLink href="/privacy_policy" target="_blank">
              Privacy Policy
            </StyledFooterLink>

            <StyledFooterLink
              href="mailto:security@sourcedllc.com"
              target="_blank"
            >
              Security Contact
            </StyledFooterLink>
          </StyledFooterArea>

          <StyledFooterArea>
            <StyledFooterIconLink
              href="mailto:info@sourcedllc.com"
              title="Write Us"
            >
              <Mail variant="white" landing />
            </StyledFooterIconLink>

            <StyledFooterIconLink
              href="https://www.linkedin.com/company/teamsourced/"
              title="Linkedin"
              target="_blank"
            >
              <Linkedin variant="white" landing />
            </StyledFooterIconLink>

            <StyledFooterIconLink
              href="https://twitter.com/team_sourced"
              title="Twitter"
              target="_blank"
            >
              <Twitter variant="white" landing />
            </StyledFooterIconLink>

            <StyledFooterIconLink
              href="https://www.facebook.com/teamsourced/"
              title="Facebook"
              target="_blank"
            >
              <Facebook variant="white" landing />
            </StyledFooterIconLink>
          </StyledFooterArea>
        </StyledFooterRow>

        <StyledFooterDivider />
      </StyledFooter>
    </Flex>
  );
};

export default LandingPage;