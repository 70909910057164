import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black)" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const Burger = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <g>
        <path
          d="M22.1,6.3H2.9c-0.9,0-1.5-0.7-1.5-1.5V3.9c0-0.9,0.7-1.5,1.5-1.5h19.1c0.9,0,1.5,0.7,1.5,1.5v0.9
		C23.6,5.5,22.9,6.3,22.1,6.3z"
        />
        <path
          d="M22.1,14.5H2.9c-0.9,0-1.5-0.7-1.5-1.5v-0.9c0-0.9,0.7-1.5,1.5-1.5h19.1c0.9,0,1.5,0.7,1.5,1.5v0.9
		C23.6,13.8,22.9,14.5,22.1,14.5z"
        />
        <path
          d="M22.1,22.7H2.9c-0.9,0-1.5-0.7-1.5-1.5v-0.9c0-0.9,0.7-1.5,1.5-1.5h19.1c0.9,0,1.5,0.7,1.5,1.5v0.9
		C23.6,22.1,22.9,22.7,22.1,22.7z"
        />
      </g>
    </StyledSvg>
  );
};

export default Burger;
