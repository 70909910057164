import { createActions } from "redux-actions";

export const PROJECT_PEOPLE_PAYLOADS_GET = "PROJECT_PEOPLE_PAYLOADS_GET";
export const PROJECT_PEOPLE_PAYLOADS_GET_SUCCESS =
  "PROJECT_PEOPLE_PAYLOADS_GET_SUCCESS";
export const PROJECT_PEOPLE_PAYLOADS_GET_FAILURE =
  "PROJECT_PEOPLE_PAYLOADS_GET_FAILURE";

export const PROJECT_PEOPLE_PAYLOAD_UPDATE = "PROJECT_PEOPLE_PAYLOAD_UPDATE";
export const PROJECT_PEOPLE_PAYLOAD_UPDATE_SUCCESS =
  "PROJECT_PEOPLE_PAYLOAD_UPDATE_SUCCESS";
export const PROJECT_PEOPLE_PAYLOAD_UPDATE_FAILURE =
  "PROJECT_PEOPLE_PAYLOAD_UPDATE_FAILURE";

export const PROJECT_PEOPLE_PAYLOAD_SEND_EVENT =
  "PROJECT_PEOPLE_PAYLOAD_SEND_EVENT";
export const PROJECT_PEOPLE_PAYLOAD_SEND_EVENT_SUCCESS =
  "PROJECT_PEOPLE_PAYLOAD_SEND_EVENT_SUCCESS";
export const PROJECT_PEOPLE_PAYLOAD_SEND_EVENT_FAILURE =
  "PROJECT_PEOPLE_PAYLOAD_SEND_EVENT_FAILURE";

export const {
  projectPeoplePayloadsGet,
  projectPeoplePayloadsGetSuccess,
  projectPeoplePayloadsGetFailure,
  projectPeoplePayloadUpdate,
  projectPeoplePayloadUpdateSuccess,
  projectPeoplePayloadUpdateFailure,
  projectPeoplePayloadSendEvent,
  projectPeoplePayloadSendEventSuccess,
  projectPeoplePayloadSendEventFailure,
} = createActions(
  {},
  PROJECT_PEOPLE_PAYLOADS_GET,
  PROJECT_PEOPLE_PAYLOADS_GET_SUCCESS,
  PROJECT_PEOPLE_PAYLOADS_GET_FAILURE,
  PROJECT_PEOPLE_PAYLOAD_UPDATE,
  PROJECT_PEOPLE_PAYLOAD_UPDATE_SUCCESS,
  PROJECT_PEOPLE_PAYLOAD_UPDATE_FAILURE,
  PROJECT_PEOPLE_PAYLOAD_SEND_EVENT,
  PROJECT_PEOPLE_PAYLOAD_SEND_EVENT_SUCCESS,
  PROJECT_PEOPLE_PAYLOAD_SEND_EVENT_FAILURE
);
