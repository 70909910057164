import React from "react";
// import styled from "styled-components";
import theme from "../../../theme";
import { Link } from "react-router-dom";

import Box from "../../ui/box";
import Card from "../../ui/card";
import Image from "../../../assets/img/people.svg";
import Flex from "../../ui/flex";

import AddPeopleModalContainer from "@containers/people/AddPeopleModalContainer";

const PeopleIntro = ({ viewport, peopleLink, project }) => {
  return (
    <Flex
      alignItems={"center"}
      width={"100%"}
      flexDirection={"column"}
      justifyContent={"flex-start"}
      px={{ sm: 2, md: 0 }}
      overflow="hidden"
      backgroundColor={theme.colors.lighterGrey}
    >
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        width={viewport > 2000 ? "85%" : "95%"}
        mt={"30px"}
        backgroundColor={theme.colors.lighterGrey}
      >
        <Card
          imageBlock={Image}
          textBlock={
            <Box>
              <p
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                Let's get to work!
              </p>
              <p
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                Here you will be able to see all the people you assigned to this
                project, edit their information and move them through the
                different stages of the workflow.
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                You have three ways to add people to the project:
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                From
                <Link
                  to={peopleLink}
                  style={{
                    fontSize: "1rem",
                    textDecoration: "none",
                    color: theme.colors.lightRed,
                    borderBottom: `1px solid ${theme.colors.lightRed}`,
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                >
                  People
                </Link>
                inside this workspace.
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                From your
                <Link
                  to={"/talent_pool"}
                  style={{
                    fontSize: "1rem",
                    textDecoration: "none",
                    color: theme.colors.lightRed,
                    borderBottom: `1px solid ${theme.colors.lightRed}`,
                    marginLeft: "10px",
                  }}
                >
                  Talent Pool
                </Link>
              </p>
              <Flex>
                <p
                  style={{
                    fontSize: "1rem",
                    fontWeight: "300",
                    lineHeight: "1.5rem",
                  }}
                >
                  Through a CSV file
                </p>
                <AddPeopleModalContainer
                  importPeopleBtn={true}
                  projectId={project.id}
                />
              </Flex>
              <p
                style={{
                  fontSize: "1rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                Or directly from our Chrome Extension
              </p>
              <p
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "300",
                  marginTop: "6px",
                  lineHeight: "1.1rem",
                }}
              >
                Don't worry, while using the chrome extension the candidates
                will automatically be added <br />
                to Workspace and your Talent Pool
              </p>

              {/* <p
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                Or get our
                <Link
                  to={"/"}
                  style={{
                    fontSize: "1.05rem",
                    textDecoration: "none",
                    color: theme.colors.red,
                    borderBottom: `1px solid ${theme.colors.red}`,
                    marginLeft: "10px",
                  }}
                >
                  Chrome extension
                </Link>
              </p> */}
            </Box>
          }
        ></Card>
      </Flex>
    </Flex>
  );
};

export default PeopleIntro;
