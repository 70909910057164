export const ROLE_PERMISSIONS = {
  owner: { allowed: true, roles: ['Owner', 'Manager', 'Collaborator', 'Viewer'] },
  manager: { allowed: true, roles: ['Manager', 'Collaborator', 'Viewer'] },
  collaborator: { allowed: false, roles: ['Viewer'] },
  viewer: { allowed: false, roles: [] },
  '': { allowed: false, roles: []},
  undefined: { allowed: false, roles: []},
}

export const isAllowedRole = (userRole) => ROLE_PERMISSIONS[userRole].allowed;
export const getAllowedRoles = (userRole) => ROLE_PERMISSIONS[userRole].roles;
export const isLastOwner = (projectUsers, projectUser) => {
  if (projectUser.role === 'owner') {
    const projectUserOwners = projectUsers.filter(projectUser => projectUser.role === 'owner');
    if (projectUserOwners.length === 0)
      return true;
    if (projectUserOwners.length === 1 && projectUserOwners[0].id === projectUser.id)
      return true;
    if (projectUserOwners.length > 1)
      return false;
  } else {
    return false;
  }
}
