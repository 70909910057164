import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.linkable
        ? `&:hover {
      fill: ${theme.colors.darkBlue}; cursor: pointer;}`
        : ""}
    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black)" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const Search = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <path
        d="M14.6,1.8c-4.7,0-8.5,3.8-8.5,8.5c0,1.5,0.4,3,1.1,4.2l-4.6,4.6c-0.9,0.9-0.9,2.5,0,3.4
	c0.5,0.5,1.1,0.7,1.7,0.7c0.6,0,1.2-0.2,1.7-0.7l4.7-4.7c1.2,0.6,2.5,1,3.9,1c4.7,0,8.5-3.8,8.5-8.5S19.3,1.8,14.6,1.8z M9.4,17
	l-4.5,4.5c-0.4,0.4-0.9,0.4-1.3,0c-0.4-0.4-0.4-0.9,0-1.3l4.4-4.4c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.3,0.3,0.4,0.4
	c0.1,0.1,0.1,0.1,0.2,0.1C9,16.7,9.2,16.8,9.4,17C9.4,17,9.4,17,9.4,17z M14.6,17.3c-1.3,0-2.6-0.4-3.7-1c-0.2-0.1-0.4-0.2-0.6-0.4
	c0,0,0,0-0.1,0c-0.2-0.1-0.3-0.3-0.5-0.4c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.4-0.4c-0.1-0.1-0.1-0.1-0.2-0.2
	c-0.1-0.1-0.1-0.2-0.2-0.3c-0.9-1.2-1.4-2.6-1.4-4.2c0-3.9,3.1-7,7-7s7,3.1,7,7S18.5,17.3,14.6,17.3z"
      />
    </StyledSvg>
  );
};

export default Search;
