import { combineActions, handleActions } from "redux-actions";
import { lensProp, pipe, set, view } from "ramda";

import {
  onExpiredToken,
  integrationsGet,
  integrationsGetSuccess,
  integrationsGetFailure,
  integrationGet,
  integrationGetSuccess,
  integrationGetFailure,
  integrationTest,
  integrationTestSuccess,
  integrationTestFailure,
} from "../actions";

const initialState = {
  inError: false,
  loading: false,
  token: localStorage.getItem("access-token"),
  data: [],
  current: {},
};

const isLoadingLens = lensProp("loading");
const inErrorLens = lensProp("inError");
const tokenLens = lensProp("token");
const dataLens = lensProp("data");
const currentLens = lensProp("current");

const setData = set(dataLens);
const setCurrent = set(currentLens);

export const getIsLoading = view(isLoadingLens);
export const getInError = view(inErrorLens);
export const getToken = view(tokenLens);

export default handleActions(
  {
    [integrationsGetSuccess]: (state, actions) =>
      pipe(
        setData(actions.payload.data),
        set(inErrorLens, false),
        set(isLoadingLens, false)
      )(state),

    [integrationsGetFailure]: (state) =>
      pipe(set(inErrorLens, true), set(isLoadingLens, false))(state),

    [integrationsGet]: (state) =>
      pipe(set(inErrorLens, false), set(isLoadingLens, true))(state),

    //test
    [integrationTestSuccess]: (state, actions) =>
      pipe(
        setCurrent(actions.payload.data),
        set(inErrorLens, false),
        set(isLoadingLens, false)
      )(state),

    [integrationTestFailure]: (state) =>
      pipe(set(inErrorLens, true), set(isLoadingLens, false))(state),

    [integrationTest]: (state) =>
      pipe(set(inErrorLens, false), set(isLoadingLens, true))(state),

    //current
    [integrationGetSuccess]: (state, actions) =>
      pipe(
        setCurrent(actions.payload.data),
        set(inErrorLens, false),
        set(isLoadingLens, false)
      )(state),

    [integrationGetFailure]: (state) =>
      pipe(set(inErrorLens, true), set(isLoadingLens, false))(state),

    [integrationGet]: (state) =>
      pipe(set(inErrorLens, false), set(isLoadingLens, true))(state),

    // handle expired token
    [combineActions(onExpiredToken)]: (state) => {
      localStorage.clear(); // remove token
      return {
        ...state,
        token: null,
      };
    },
  },
  initialState
);

export const getIntegrations = (state) => state.integrations;
