import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

//Component
import { PeopleSearch } from "../../components/domain/people";
import Spinner from "../../components/ui/spinner";

//Reducers
import { getProjectPeople, getAppWorkspaces } from "../../reducer";

const TalentSearchContainer = ({ viewport, ...props }) => {
  const projectPeople = useSelector(getProjectPeople);
  const workspaces = useSelector(getAppWorkspaces);

  const [decoratedProjectPeople, setDecoratedProjectPeople] = useState([]);

  useEffect(() => {
    if (projectPeople?.data?.length > 0) {
      decorateProjectPeople();
    }
  }, [projectPeople]);

  const getProjectPeopleIncludedById = (type, resource, id) =>
    projectPeople.included.find((item) => item.type === type && item.id === id);

  const getWorkspaceById = (id) => workspaces.find((item) => item.id === id);

  const decorateProjectPeople = () => {
    const result = projectPeople.data?.map((projectPerson) => {
      let personAttrs = getProjectPeopleIncludedById(
        "person",
        projectPerson,
        projectPerson.attributes.person_id
      )?.attributes;

      let projectAttrs = getProjectPeopleIncludedById(
        "project",
        projectPerson,
        projectPerson.attributes.project_id
      )?.attributes;

      let workspaceAttrs = getWorkspaceById(projectAttrs.workspace_id)
        ?.attributes;

      return {
        ...projectPerson.attributes,
        person: personAttrs,
        project: projectAttrs,
        workspace: workspaceAttrs,
      };
    });

    setDecoratedProjectPeople({ data: [...result] });
  };

  return projectPeople.loading ? (
    <Spinner />
  ) : (
    <PeopleSearch viewport={viewport} projectPeople={decoratedProjectPeople} />
  );
};

export default TalentSearchContainer;
