import { combineEpics, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap, map, mapTo, tap } from "rxjs/operators";
import { toast } from "react-toastify";

import {
  SEQUENCES_GET,
  sequencesGet,
  sequencesGetSuccess,
  sequencesGetFailure,
  SEQUENCE_CREATE,
  sequenceCreateSuccess,
  sequenceCreateFailure,
  SEQUENCE_UPDATE,
  sequenceUpdateSuccess,
  sequenceUpdateFailure,
  SEQUENCE_DELETE,
  sequenceDeleteSuccess,
  sequenceDeleteFailure,
  SEQUENCE_TEST,
  sequenceTestSuccess,
  sequenceTestFailure,
} from "../actions";

const getSequencesEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(SEQUENCES_GET),
    mergeMap(({ payload: { workspace_id, limit, url } }) => {
      const getPath = url ? url : `/workspaces/${workspace_id}/sequences`;

      return from(
        api
          .get(getPath, {
            params: {
              by_workspace: workspace_id,
              limit: limit,
            },
          })
          .then((resp) => resp)
      ).pipe(
        map(sequencesGetSuccess),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map((error) => toast.error(error.detail));
          return of(sequencesGetFailure({ err, errors }));
        })
      );
    })
  );

const createSequenceEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(SEQUENCE_CREATE),
    mergeMap(
      ({
        payload: {
          workspace_id,
          name,
          description,
          allow_send_weekend,
          allow_send_holiday,
          has_review,
          holiday_country,
          timezone,
          payload_fields,
          steps,
        },
      }) => {
        return from(
          api
            .post(`/workspaces/${workspace_id}/sequences`, {
              data: {
                type: "sequence",
                attributes: {
                  name,
                  description,
                  allow_send_weekend,
                  allow_send_holiday,
                  has_review,
                  holiday_country,
                  timezone,
                  payload_fields,
                  steps,
                },
              },
            })
            .then((resp) => resp)
        ).pipe(
          map(sequenceCreateSuccess),
          tap((res) => {
            toast.success(`Sequence ${name} created correctly`);
          }),
          mapTo(sequencesGet({ workspace_id })),
          catchError((err) => {
            const errors = err.response ? err.response.data.errors : [];
            errors.map((error) => toast.error(error.detail));
            return of(sequenceCreateFailure({ err, errors }));
          })
        );
      }
    )
  );

const updateSequenceEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(SEQUENCE_UPDATE),
    mergeMap(
      ({
        payload: {
          id,
          workspace_id,
          name,
          description,
          allow_send_weekend,
          allow_send_holiday,
          has_review,
          holiday_country,
          timezone,
          payload_fields,
          steps,
        },
      }) => {
        return from(
          api
            .put(`/workspaces/${workspace_id}/sequences/${id}`, {
              data: {
                type: "sequence",
                attributes: {
                  name,
                  description,
                  allow_send_weekend,
                  allow_send_holiday,
                  has_review,
                  holiday_country,
                  timezone,
                  payload_fields,
                  steps_attributes: steps,
                },
              },
            })
            .then((resp) => resp)
        ).pipe(
          map(sequenceUpdateSuccess),
          mapTo(sequencesGet({ workspace_id })),
          tap((res) => {
            toast.success(`Sequence updated correctly`);
          }),

          catchError((err) => {
            const errors = err.response ? err.response.data.errors : [];
            errors.map((error) => toast.error(error.detail));
            return of(sequenceUpdateFailure({ err, errors }));
          })
        );
      }
    )
  );

const deleteSequenceEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(SEQUENCE_DELETE),
    mergeMap(({ payload: { workspace_id, id } }) => {
      return from(
        api
          .delete(`/workspaces/${workspace_id}/sequences/${id}`)
          .then((resp) => resp)
      ).pipe(
        map(sequenceDeleteSuccess),
        tap((res) => {
          toast.success(`Sequence deleted`);
        }),
        mapTo(sequencesGet({ workspace_id })),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map((error) => toast.error(error.detail));
          return of(sequenceDeleteFailure({ err, errors }));
        })
      );
    })
  );

const testSequenceEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(SEQUENCE_TEST),
    mergeMap(({ payload: { workspace_id, id, testParams } }) => {
      return from(
        api
          .put(`/workspaces/${workspace_id}/sequences/${id}/test`, {
            data: {
              type: "sequence",
              attributes: {
                id,
                workspace_id,
                test_params: testParams,
              },
            },
          })
          .then((resp) => resp)
      ).pipe(
        map(sequenceTestSuccess),
        tap(res => { toast.success(`Test sent`) }),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map((error) => toast.error(error.detail));
          return of(sequenceTestFailure({ err, errors }));
        })
      );
    })
  );

export default combineEpics(
  getSequencesEpic,
  createSequenceEpic,
  updateSequenceEpic,
  deleteSequenceEpic,
  testSequenceEpic
);
