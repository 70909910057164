import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import theme from "../../../theme";

import { setLimit } from "../../../actions";
import { getLimit } from "../../../reducer";

// Components
import Box from "../box";
import Text from "../text/index";

//icons
import ArrowUp from "../icons/arrowUp";
import ArrowDown from "../icons/arrowDown";

const StyledContainer = styled(Box)`
  position: relative;
  margin-bottom: 20px;
  background-color: transparent;
  width: auto;
  display: flex;
  margin-right: 15px;
  flex-direction: row;
  align-content: center;
`;

const StyledArea = styled.div`
  font-family: inherit;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  height: 27px;
  padding: 8px;
  margin: 2px;
  border-radius: 2px;
`;
const StyledPlaceholder = styled.div`
  color: ${(props) =>
    props.disabled ? `${theme.colors.lightGrey}` : `${theme.colors.blue}`};
  font-size: 0.9rem;
  font-weight: 300;
`;

const StyledDropdown = styled(Box)`
  position: relative;
  border-bottom: 1px solid ${theme.colors.blue};
`;

const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px 0 10px;
  height: 20px;
`;

const StyledIcon = styled.div`
  height: 15px;
  width: 15px;
  margin-left: 10px;
`;

const StyledList = styled.ul`
  display: block;
  flex-direction: column;
  margin-top: 1px;
  height: auto;
  position: absolute;
  z-index: 99999;
  top: auto;
  bottom: 100%;
  background-color: white;
  box-shadow: 0px 1px 3px ${theme.colors.lightGrey};
  width: 100%;
  padding: 0px;
`;

const StyledOption = styled.li`
  padding: 10px;
  font-size: 1rem;
  border: none;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background-color: ${theme.colors.lighterBlue};
    color: ${theme.colors.darkBlue};
    cursor: pointer;
  }
`;
const StyledSelected = styled.div`
  display: flex;
  font-size: 0.9rem;
  border: none;
  color: ${theme.colors.grey};
  font-weight: 200;
`;

const RowsPerPage = ({ name, options, placeholder }) => {
  const node = useRef();
  const dispatch = useDispatch();
  const value = useSelector(getLimit);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value || null);

  const onOptionClicked = (value) => () => {
    const valueNumber = Number(value);
    setSelectedOption(valueNumber);
    dispatch(setLimit(valueNumber));
    setDropdownOpen(false);
  };

  //close dropdown when click outside
  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setDropdownOpen(false);
  };

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen]);

  return (
    <StyledContainer ref={node} key="rowsPerPage">
      <StyledArea>
        <Text>Rows per page</Text>

        <StyledDropdown>
          <StyledFormContainer onClick={() => setDropdownOpen(!dropdownOpen)}>
            {placeholder && !selectedOption ? (
              <StyledPlaceholder disabled={!options}>
                {placeholder}
              </StyledPlaceholder>
            ) : null}
            {selectedOption && (
              <StyledSelected>{selectedOption}</StyledSelected>
            )}
            <StyledIcon>
              {dropdownOpen === false ? (
                <ArrowDown variant="blue" />
              ) : (
                <ArrowUp variant="blue" />
              )}
            </StyledIcon>
          </StyledFormContainer>

          {dropdownOpen ? (
            <StyledList>
              {options.map((opt, index) => (
                <StyledOption
                  name={name}
                  onClick={onOptionClicked(opt)}
                  key={index}
                >
                  {opt}
                </StyledOption>
              ))}
            </StyledList>
          ) : null}
        </StyledDropdown>
      </StyledArea>
    </StyledContainer>
  );
};

export default RowsPerPage;
