import { createActions } from "redux-actions";

export const SEQUENCES_GET = "SEQUENCES_GET";
export const SEQUENCES_GET_SUCCESS = "SEQUENCES_GET_SUCCESS";
export const SEQUENCES_GET_FAILURE = "SEQUENCES_GET_FAILURE";

export const SEQUENCE_CREATE = "SEQUENCE_CREATE";
export const SEQUENCE_CREATE_SUCCESS = "SEQUENCE_CREATE_SUCCESS";
export const SEQUENCE_CREATE_FAILURE = "SEQUENCE_CREATE_FAILURE";

export const SEQUENCE_UPDATE = "SEQUENCE_UPDATE";
export const SEQUENCE_UPDATE_SUCCESS = "SEQUENCE_UPDATE_SUCCESS";
export const SEQUENCE_UPDATE_FAILURE = "SEQUENCE_UPDATE_FAILURE";

export const SEQUENCE_DELETE = "SEQUENCE_DELETE";
export const SEQUENCE_DELETE_SUCCESS = "SEQUENCE_DELETE_SUCCESS";
export const SEQUENCE_DELETE_FAILURE = "SEQUENCE_DELETE_FAILURE";

export const SEQUENCE_TEST = "SEQUENCE_TEST";
export const SEQUENCE_TEST_SUCCESS = "SEQUENCE_TEST_SUCCESS";
export const SEQUENCE_TEST_FAILURE = "SEQUENCE_TEST_FAILURE";

export const {
  sequencesGet,
  sequencesGetSuccess,
  sequencesGetFailure,
  sequenceCreate,
  sequenceCreateSuccess,
  sequenceCreateFailure,
  sequenceUpdate,
  sequenceUpdateSuccess,
  sequenceUpdateFailure,
  sequenceDelete,
  sequenceDeleteSuccess,
  sequenceDeleteFailure,
  sequenceTest,
  sequenceTestSuccess,
  sequenceTestFailure,
} = createActions(
  {},
  SEQUENCES_GET,
  SEQUENCES_GET_SUCCESS,
  SEQUENCES_GET_FAILURE,
  SEQUENCE_CREATE,
  SEQUENCE_CREATE_SUCCESS,
  SEQUENCE_CREATE_FAILURE,
  SEQUENCE_UPDATE,
  SEQUENCE_UPDATE_SUCCESS,
  SEQUENCE_UPDATE_FAILURE,
  SEQUENCE_DELETE,
  SEQUENCE_DELETE_SUCCESS,
  SEQUENCE_DELETE_FAILURE,
  SEQUENCE_TEST,
  SEQUENCE_TEST_SUCCESS,
  SEQUENCE_TEST_FAILURE,
);
