import React from "react";
import styled from "styled-components";
import theme from "../../../theme";
import { useForm } from "react-hook-form";

import Modal from "../../ui/modal/Modal";
import Flex from "../../ui/flex";
import Box from "../../ui/box";
import Button from "../../ui/button/index";
import Submit from "../../ui/input-submit";
import Input from "../../ui/input/index";
import Dropdown from "../../ui/dropdown/index";
import DatePicker from "../../ui/input-datepicker/index";
import Toggle from "@ui/toggle-switch";

//Icons
import Add from "../../ui/icons/add";
import Close from "../../ui/icons/close";
import Goal from "../../ui/icons/goal";

const StyledLinks = styled.a`
  margin-left: 5px;
  font-size: 0.8rem;
  text-decoration: none;
  color: ${theme.colors.darkBlue};
  border-bottom: 1px solid ${theme.colors.darkBlue};
  margin-right: 15px;
`;

const NewProjectModal = ({
  closeModal,
  showModal,
  showButton,
  variant,
  openModal,
  dirtyChanges,
  handleSubmitClick,
  handleChange,
  handleDatepicker,
  handleDropdownSelect,
  workspaceId,
  selectedFile,
  handleInputAddPeopleChange,
  workflows,
  asyncReload,
  handleVisibility
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();

  const handleCLoseModal = () => {
    clearErrors();
    closeModal();
  };

  let iconColor = "white";
  if (variant === "secondaryLight" || variant === "secondaryLine") {
    iconColor = "blue";
  } else if (variant === "primaryLine") {
    iconColor = "red";
  }

  return (
    <>
      {showButton === true && (
        <Button
          onClick={openModal}
          size="small"
          variant={variant ? variant : "primaryLight"}
          content="Project"
          icon={<Add variant={iconColor}></Add>}
        ></Button>
      )}
      {showModal === true && (
        <Modal
          fullHeader
          formContent
          onSubmit={handleSubmit(handleSubmitClick)}
          title="New Project"
          icon={<Close variant="grey"></Close>}
          closeHandler={handleCLoseModal}
          textWarning="* Field Required"
          buttons={[
            <Button
              key="cancel"
              variant="secondaryLine"
              content="Cancel"
              onClick={handleCLoseModal}
            />,
            <Submit key="continue" variant="secondary" content="Continue" />,
          ]}
        >
          <Flex width="90%" justifyContent="space-evenly">
            <Box width={"35%"}>
              <Input
                {...register("title", { required: true })}
                required
                error={errors.title ? true : false}
                errorMsg="This field is required"
                label="Title"
                mb={2}
                mt={2}
                placeholder={"Enter Project Title"}
                value={dirtyChanges.title}
                onChange={handleChange}
                autoFocus
                tabIndex="1"
              />
              <Dropdown
                {...register("priority", { required: true })}
                error={errors.priority ? true : false}
                errorMsg="This field is required"
                name="priority"
                value={dirtyChanges.priority}
                handleDropdown={handleDropdownSelect}
                handleChange={(val) => {
                  setValue("priority", val);
                }}
                formStyle
                variant="blue"
                required
                options={["High", "Medium", "Low"]}
                label="Priority"
                mb={2}
                placeholder="Set Priority"
                tabIndex="2"
              />
              <Dropdown
                {...register("status", { required: true })}
                error={errors.status ? true : false}
                errorMsg="This field is required"
                name="status"
                value={dirtyChanges.status}
                handleDropdown={handleDropdownSelect}
                handleChange={(val) => {
                  setValue("status", val);
                }}
                formStyle
                variant="blue"
                required
                options={["Active", "Inactive"]}
                label="Status"
                mb={2}
                placeholder="Set Status"
                tabIndex="3"
              />
              <DatePicker
                disabled={false}
                name="startDate"
                placeholder="Set Start Date"
                label="Start Date"
                mb={3}
                value={dirtyChanges.startDate}
                handleDatepicker={handleDatepicker}
                tabIndex="4"
              />
              <DatePicker
                disabled={false}
                name="deadline"
                placeholder="Set Deadline"
                label="Deadline"
                mb={3}
                value={dirtyChanges.deadline}
                handleDatepicker={handleDatepicker}
                tabIndex="5"
              />
            </Box>

            <Box width={"55%"}>
              <Input
                disabled={false}
                name="goal"
                showCharLimitCount
                maxLength="100"
                icon={<Goal variant="blue"></Goal>}
                label="Objective"
                defaultValue={dirtyChanges.goal}
                mb={2}
                mt={2}
                placeholder="Describe the objective for this project"
                value={dirtyChanges.goal}
                onChange={handleChange}
                tabIndex="6"
              />
              <Dropdown
                {...register("workflow", { required: true })}
                required
                error={errors.workflow ? true : false}
                errorMsg="This field is required"
                asyncReload={asyncReload}
                name="workflow"
                value={dirtyChanges.workflow}
                handleDropdown={handleDropdownSelect}
                handleChange={(val) => {
                  setValue("workflow", val);
                }}
                formStyle
                variant="blue"
                options={workflows?.map((workflow) => workflow.attributes.name)}
                label="Workflow"
                mb={0}
                placeholder="Select Workflow"
                tabIndex="7"
              />
              <StyledLinks
                target="_blank"
                href={`/workspaces/${workspaceId}/workflows`}
                tabIndex="8"
              >
                New Workflow
              </StyledLinks>
              <Flex marginTop={"5px"}></Flex>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                marginTop="25px"
              >
                <Toggle
                  customeLabel="Visible (Chrome Extension)"
                  initialValue={dirtyChanges.visible}
                  handleChange={handleVisibility}
                  name="visible"
                />
              </Flex>
            </Box>
          </Flex>
        </Modal>
      )}
    </>
  );
};

export default NewProjectModal;
