import { combineEpics, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap, map, mapTo } from "rxjs/operators";
import qs from "qs";
import { toast } from "react-toastify";

import {
  PROJECT_PEOPLE_PAYLOADS_GET,
  projectPeoplePayloadsGet,
  projectPeoplePayloadsGetSuccess,
  projectPeoplePayloadsGetFailure,
  PROJECT_PEOPLE_PAYLOAD_SEND_EVENT,
  projectPeoplePayloadSendEventSuccess,
  projectPeoplePayloadSendEventFailure,
  PROJECT_PEOPLE_PAYLOAD_UPDATE,
  projectPeoplePayloadUpdateSuccess,
  projectPeoplePayloadUpdateFailure,
} from "../actions";

const getProjectPeoplePayloadsEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(PROJECT_PEOPLE_PAYLOADS_GET),
    mergeMap(
      ({
        payload: {
          workspace_id,
          project_id,
          url,
          search_by,
          by_stages,
          limit,
          by_status,
          currentPage,
        },
      }) => {
        const getPath = url
          ? url
          : `/workspaces/${workspace_id}/projects/${project_id}/payloads`;

        return from(
          api
            .get(getPath, {
              params: {
                search_by: search_by,
                "by_stages[]": by_stages,
                "by_status[]": by_status,
                limit: limit,
                page: currentPage,
              },
              paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
              },
            })
            .then((resp) => resp)
        ).pipe(
          map(projectPeoplePayloadsGetSuccess),
          catchError((err) => {
            const errors = err.response ? err.response.data.errors : [];
            errors.map(error => toast.error(error.detail));
            return of(projectPeoplePayloadsGetFailure({ err, errors }));
          })
        );
      }
    )
  );

const sendEventProjectPeoplePayloadEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(PROJECT_PEOPLE_PAYLOAD_SEND_EVENT),
    mergeMap(
      ({
        payload: {
          workspace_id,
          project_id,
          payload_id,
          event_name,
          by_stages,
          limit,
          by_status,
        },
      }) => {
        return from(
          api
            .put(
              `/workspaces/${workspace_id}/projects/${project_id}/payloads/${payload_id}/event`,
              {
                data: {
                  type: "payload",
                  attributes: {
                    event_name,
                  },
                },
              }
            )
            .then((resp) => resp)
        ).pipe(
          map(projectPeoplePayloadSendEventSuccess),
          mapTo(
            projectPeoplePayloadsGet({
              workspace_id,
              project_id,
              by_stages,
              limit,
              by_status,
            })
          ),
          catchError((err) => {
            const errors = err.response ? err.response.data.errors : [];
            errors.map(error => toast.error(error.detail));
            return of(projectPeoplePayloadSendEventFailure({ err, errors }));
          })
        );
      }
    )
  );

const updateProjectPeoplePayloadEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(PROJECT_PEOPLE_PAYLOAD_UPDATE),
    mergeMap(
      ({
        payload: {
          workspace_id,
          project_id,
          payload_id,
          payload_values,
          by_stages,
          limit,
          by_status,
          currentPage,
          search_by,
        },
      }) => {
        return from(
          api
            .put(
              `/workspaces/${workspace_id}/projects/${project_id}/payloads/${payload_id}`,
              {
                data: {
                  type: "payload",
                  attributes: {
                    payload_values,
                  },
                },
              }
            )
            .then((resp) => resp)
        ).pipe(
          map(projectPeoplePayloadUpdateSuccess),
          mapTo(
            projectPeoplePayloadsGet({
              workspace_id,
              project_id,
              by_stages,
              limit,
              by_status,
              currentPage,
              search_by,
            })
          ),
          catchError((err) => {
            const errors = err.response ? err.response.data.errors : [];
            errors.map(error => toast.error(error.detail));
            return of(projectPeoplePayloadUpdateFailure({ err, errors }));
          })
        );
      }
    )
  );

export default combineEpics(
  getProjectPeoplePayloadsEpic,
  sendEventProjectPeoplePayloadEpic,
  updateProjectPeoplePayloadEpic
);
