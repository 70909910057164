import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { passwordUpdate } from "../../actions";

import {
  getAuthInError,
  getAuthError,
  getAuthLoading,
  getAuthMessage,
} from "../../reducer";

import { UpdatePassword } from "../../components/domain/account";
import Spinner from "../../components/ui/spinner";

const UpdatePasswordContainer = (props) => {
  const dispatch = useDispatch();
  const inError = useSelector(getAuthInError);
  const errorMsg = useSelector(getAuthError);
  const loading = useSelector(getAuthLoading);
  const message = useSelector(getAuthMessage);

  //------
  // data

  const [passwordVal, setPassword] = useState("");
  const [resetPasswordToken, setResetPasswordToken] = useState("");

  //------
  // handlers

  const handleSetPassword = (e) => setPassword(e.target.value);
  const handleSubmitClick = () => {
    dispatch(
      passwordUpdate({
        reset_password_token: resetPasswordToken,
        password: passwordVal,
      })
    );
  };

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);

    setResetPasswordToken(params.get("reset_password_token"));
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading ? <Spinner /> : null}
      <UpdatePassword
        errorMessage={errorMsg}
        inError={inError}
        passwordVal={passwordVal}
        handleSetPassword={handleSetPassword}
        handleSubmitClick={handleSubmitClick}
        message={message}
      />
    </>
  );
};

export default UpdatePasswordContainer;
