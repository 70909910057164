/* eslint-disable react/display-name */
/* eslint-disable default-case */

import React, { forwardRef, useState, useEffect, useRef } from "react";
import theme from "../../../theme";
import {
  bool,
  element,
  func,
  number,
  string,
  oneOfType,
  node,
  object,
} from "prop-types";
import { color, typography, height, flex, layout } from "styled-system";
import styled, { css } from "styled-components";

// Components
import Box from "../box";
import Text from "../text";
import Valid from "../icons/valid";

//sidebar
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.css";

const StyledContainer = styled(Box)`
  position: relative;
  width: auto;
  display: ${(props) => (props.hidden ? "none" : "block")};

  ${({ onClick }) => onClick && `cursor: pointer;`};
  ${flex};
  ${layout};
`;

const StyledIcon = styled.div`
  ${({ small }) =>
    small ? `height: 15px; width: 15px;` : `height: 23px; width: 23px;`}
  ${({ disabled }) =>
    disabled &&
    `filter: grayscale(100%);

    :hover {
      cursor: default;}`}
  ${({ iconBtn, disabled }) =>
    iconBtn &&
    !disabled &&
    `&:hover {
      cursor: pointer;
    }
  `}
`;

const StyledRequired = styled.span`
  color: ${theme.colors.red};
  font-size: 1rem;
  padding-left: 4px;
`;

const StyledInputContainer = styled(Box)`
  border-color: ${({ error, disabled, theme: { colors } }) =>
    error
      ? theme.colors.red
      : disabled
      ? theme.colors.lightGrey
      : theme.colors.blue};
  background-color: ${({ disabled, theme: { colors } }) =>
    disabled ? theme.colors.lighterGrey : ""};
  border-style: solid;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  border-width: 0 0 1px 0;
  ${(props) => (props.short ? `height: 40px;` : ` height: 100%;`)}

  ${({ disabled, error, theme: { colors } }) =>
    !disabled &&
    `&:hover {
      border-color: ${error ? theme.colors.red : theme.colors.blue};
    }
  `}

  ${color}
`;

const commonInputStyles = css`
  color: ${({ disabled, theme: { colors } }) => {
    if (disabled) {
      return theme.colors.lightGrey;
    }
    return theme.colors.grey;
  }};
  ${({ readOnly }) => readOnly && `cursor: inherit;`}
  background: transparent;
  border: 0;
  font-family: ${theme.fonts[1]};
  font-size: 1rem;
  display: block;
  width: 100%;

  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${({ error, disabled, theme: { colors } }) =>
      error
        ? theme.colors.grey
        : disabled
        ? theme.colors.lightGrey
        : theme.colors.darkBlue};

    font-style: italic;
    font-size: 0.8rem;
  }
`;

const StyledInput = styled.input`
  ${commonInputStyles}
  height: auto;
  padding-top: 12px;
  padding-bottom: 12px;

  ${({ confirmed, disabled, error }) =>
    confirmed || disabled || error ? `padding-right: 28px;` : 0};
  ${({ icon, iconBtn }) => (icon && !iconBtn ? `padding-left: 20px; ` : 0)};
  ${({ icon, iconBtn }) => (iconBtn && icon ? `padding-right: 20px;` : 0)};
  &[type="number"] {
    padding-top: 6px;
    padding-bottom: 6px;
    appearance: textfield;
    margin: 0;
  }

  &::-ms-clear {
    display: none;
  }
  ${color}
  ${typography}
`;

const StyledError = styled.span`
  color: ${theme.colors.red};
  font-size: 0.7rem;
  font-weight: 200;
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
`;

const StyledList = styled.ul`
  display: block;
  flex-direction: column;
  top: 40px;
  left: 0px;
  height: auto;
  position: absolute;
  z-index: 99999;
  /* top: auto;
  bottom: 100%; */
  background-color: white;
  box-shadow: 0px 1px 3px ${theme.colors.lightGrey};
  width: 100%;
  padding: 0px;
`;

const StyledOption = styled.li`
  padding: 10px;
  font-size: 1rem;
  border: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    background-color: ${theme.colors.lighterBlue};
    color: ${theme.colors.darkBlue};
    cursor: pointer;
  }
`;

const StyledSimpleBar = styled(SimpleBar)`
  max-height: 7rem;
  margin-right: 5px;
  width: 100%;
  .simplebar-scrollbar:before {
    background: ${theme.colors.grey};
  }
`;

const propTypes = {
  confirmed: bool,
  disabled: bool,
  error: bool,
  errorMsg: string,
  icon: element,
  iconBtn: bool,
  label: oneOfType([string, node]),
  loading: bool,
  loadingIcon: element,
  name: string,
  onChange: func,
  onClick: func,
  onEnter: func,
  onEsc: func,
  placeholder: string,
  readOnly: bool,
  rows: number,
  type: string,
  value: oneOfType([string, number]),
  required: bool,
  short: bool,
  autoFocus: bool,
  forwardedRef: object,
  handleDropdown: func,
};

const InputDatalist = forwardRef(
  (
    {
      confirmed = false,
      disabled = false,
      error: errorProp = false,
      errorMsg = null,
      inputValue,
      icon,
      onClick,
      onEnter,
      onEsc,
      label,
      loading = false,
      loadingIcon,
      name,
      height,
      placeholder,
      readOnly = false,
      rows = null,
      type = "text",
      iconBtn,
      required,
      hidden,
      short,
      autoFocus,
      forwardedRef,
      tabIndex,
      datalistOptions, //array of options inside the dropdown
      handleDropdown,
      handleChange,
      ...props
    },
    ref
  ) => {
    const node = useRef();
    const [error, setError] = useState(errorProp);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleClickOutside = (e) => {
      if (node.current.contains(e.target)) {
        // inside click
        return;
      }
      // outside click
      setDropdownOpen(false);
    };

    useEffect(() => {
      if (errorProp !== error) {
        setError(errorProp);
      }
    }, [errorProp, inputValue]);

    useEffect(() => {
      if (dropdownOpen) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
      // eslint-disable-next-line
    }, [dropdownOpen]);

    // This is here to avoid submitting form on Enter
    // (in case this component is inside a form)
    const handleKeyPressed = (event) => {
      setDropdownOpen(false);
      if (!readOnly && !disabled) {
        switch (event.key) {
          case "Enter":
            if (onEnter && !event.shiftKey) {
              event.preventDefault();
              onEnter(event);
            }
            return;
          case "Escape":
            onEsc && onEsc();
            return;
        }
      }
    };

    return (
      <StyledContainer
        hidden={hidden}
        bg={"transparent"}
        onClick={() => setDropdownOpen(!dropdownOpen)}
        ref={node}
        {...props}
      >
        {label && (
          <Text
            fontSize={"0.9rem"}
            fontFamily={theme.fonts[1]}
            fontWeight={0}
            mb={1}
            pt={2}
          >
            {label}
            {required && !disabled ? <StyledRequired>*</StyledRequired> : null}
          </Text>
        )}

        <StyledInputContainer
          disabled={disabled}
          error={error}
          pr={error || confirmed ? "52px" : null}
          px={2}
          py={0}
          icon={icon}
          confirmed={confirmed}
          loading={loading.toString()}
        >
          {icon && !iconBtn ? (
            <StyledIcon disabled={disabled}>{icon}</StyledIcon>
          ) : null}

          <StyledInput
            tabIndex={tabIndex}
            autoFocus={autoFocus}
            confirmed={confirmed}
            disabled={disabled}
            error={error}
            icon={icon}
            iconBtn={iconBtn}
            loadingIcon={loadingIcon}
            name={name}
            onChange={handleChange}
            onKeyDown={handleKeyPressed}
            placeholder={placeholder}
            readOnly={readOnly}
            ref={forwardedRef}
            type={type}
            value={inputValue}
            autoComplete="off"
          />

          {dropdownOpen && datalistOptions && !disabled ? (
            <StyledList short={short}>
              <StyledSimpleBar forceVisible="y" autoHide={false}>
                {datalistOptions.map((opt, index) => (
                  <StyledOption
                    name={name}
                    onClick={() => {
                      handleDropdown(name, opt);
                      setDropdownOpen(false);
                    }}
                    key={index}
                  >
                    {opt}
                  </StyledOption>
                ))}
              </StyledSimpleBar>
            </StyledList>
          ) : null}

          {iconBtn && icon ? (
            <StyledIcon iconBtn={iconBtn} disabled={disabled}>
              {icon}
            </StyledIcon>
          ) : null}

          {loading && loadingIcon ? loadingIcon : null}

          {confirmed ? (
            <StyledIcon small>
              <Valid variant="blue"></Valid>
            </StyledIcon>
          ) : null}
        </StyledInputContainer>

        <StyledError show={error && errorMsg}>{errorMsg}</StyledError>
      </StyledContainer>
    );
  }
);

InputDatalist.propTypes = propTypes;

export default InputDatalist;
