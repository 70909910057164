import { combineEpics, ofType } from "redux-observable";
import { from, of, merge } from "rxjs";
import { catchError, mergeMap, map, mapTo, tap } from "rxjs/operators";
import { toast } from "react-toastify";
import { push } from "connected-react-router";

import {
  WORKSPACES_GET,
  WORKSPACES_GET_SUCCESS,
  workspacesGet,
  workspacesGetSuccess,
  workspacesGetFailure,
  onWorkspacesLoaded,
  WORKSPACE_CREATE,
  workspaceCreateSuccess,
  workspaceCreateFailure,
  currentWorkspaceSet,
  WORKSPACE_UPDATE,
  workspaceUpdateSuccess,
  workspaceUpdateFailure,
  WORKSPACE_DELETE,
  workspaceDeleteSuccess,
  workspaceDeleteFailure,
  WORKSPACE_ANALYTICS_GET,
  workspaceAnalyticsGetSuccess,
  WORKSPACE_INVITE,
  workspaceInviteSuccess,
  workspaceInviteFailure,
  workspaceInvitationsGet,
  WORKSPACE_GET,
  workspaceGetSuccess,
  workspaceGetFailure,
} from "../actions";

// Requests
export const workspacesFetch = (api) => api.get("/workspaces");
//////////////////////////////////////////////

const initializeWorkspacesEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(WORKSPACES_GET),
    mergeMap(() => {
      return from(workspacesFetch(api)).pipe(
        map(workspacesGetSuccess),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map((error) => toast.error(error.detail));
          return of(workspacesGetFailure({ err, errors }));
        })
      );
    })
  );

const createWorkspaceEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(WORKSPACE_CREATE),
    mergeMap(({ payload: { user_id, title } }) => {
      return from(
        api
          .post(`/workspaces`, {
            data: {
              type: "workspace",
              attributes: {
                name: title,
                user_id: user_id,
                description: "New Workspace",
              },
            },
          })
          .then((resp) => resp)
      ).pipe(
        mergeMap((resp) =>
          merge(
            of(workspaceCreateSuccess()),
            of(workspacesGet()),
            of(currentWorkspaceSet({ workspace_id: resp.data.data.id })),
            of(push(`/workspaces/${resp.data.data.id}/home`))
          )
        ),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map((error) => toast.error(error.detail));
          return of(workspaceCreateFailure({ err, errors }));
        })
      );
    })
  );

const updateWorkspaceEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(WORKSPACE_UPDATE),
    mergeMap(({ payload: { id, title, visible } }) => {
      return from(
        api
          .put(`/workspaces/${id}`, {
            data: {
              type: "workspace",
              attributes: {
                name: title,
                visible: visible,
              },
            },
          })
          .then((resp) => resp)
      ).pipe(
        map(workspaceUpdateSuccess),
        mapTo(workspacesGet()),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map((error) => toast.error(error.detail));
          return of(workspaceUpdateFailure({ err, errors }));
        })
      );
    })
  );

const deleteWorkspaceEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(WORKSPACE_DELETE),
    mergeMap(({ payload: { id } }) => {
      return from(api.delete(`/workspaces/${id}`).then((resp) => resp)).pipe(
        map(workspaceDeleteSuccess),
        mapTo(workspacesGet()),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map((error) => toast.error(error.detail));
          return of(workspaceDeleteFailure({ err, errors }));
        })
      );
    })
  );

const workspacesChanged = (action$, state$) =>
  action$.pipe(
    ofType(WORKSPACES_GET_SUCCESS),
    map(({ payload }) => onWorkspacesLoaded(payload.data))
  );

const getWorkspaceAnalyticsEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(WORKSPACE_ANALYTICS_GET),
    mergeMap(({ payload: { id } }) => {
      return from(
        api.get(`/workspaces/${id}/export_analytics`).then((resp) => resp)
      ).pipe(
        map(workspaceAnalyticsGetSuccess),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map((error) => toast.error(error.detail));
          return of(workspacesGetFailure({ err, errors }));
        })
      );
    })
  );

const getWorkspaceEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(WORKSPACE_GET),
    mergeMap(({ payload: { id } }) => {
      return from(api.get(`/workspaces/${id}/`).then((resp) => resp)).pipe(
        map(workspaceGetSuccess),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map((error) => toast.error(error.detail));
          return of(workspaceGetFailure({ err, errors }));
        })
      );
    })
  );

const inviteWorkspaceEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(WORKSPACE_INVITE),
    mergeMap(({ payload: { workspace_id, email, workspace_role } }) => {
      return from(
        api.post(`/invitations`, {
          data: {
            type: "invitation",
            attributes: {
              user_to_email: email,
              invitable_id: workspace_id,
              invitable_type: "Workspace",
              workspace_role: workspace_role.toLowerCase(),
            },
          },
        })
      ).pipe(
        map(workspaceInviteSuccess),
        mapTo(workspaceInvitationsGet({ workspace_id: workspace_id })),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map((error) => toast.error(error.detail));
          return of(workspaceInviteFailure({ err, errors }));
        })
      );
    })
  );

export default combineEpics(
  initializeWorkspacesEpic,
  createWorkspaceEpic,
  updateWorkspaceEpic,
  deleteWorkspaceEpic,
  workspacesChanged,
  getWorkspaceAnalyticsEpic,
  getWorkspaceEpic,
  inviteWorkspaceEpic
);
