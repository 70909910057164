import { createActions } from "redux-actions";

export const INTEGRATIONS_GET = "INTEGRATIONS_GET";
export const INTEGRATIONS_GET_SUCCESS = "INTEGRATIONS_GET_SUCCESS";
export const INTEGRATIONS_GET_FAILURE = "INTEGRATIONS_GET_FAILURE";

export const INTEGRATION_DELETE = "INTEGRATION_DELETE";
export const INTEGRATION_DELETE_SUCCESS = "INTEGRATION_DELETE_SUCCESS";
export const INTEGRATION_DELETE_FAILURE = "INTEGRATION_DELETE_FAILURE";

export const INTEGRATION_UPDATE = "INTEGRATION_UPDATE";
export const INTEGRATION_ALIASES_UPDATE = "INTEGRATION_ALIASES_UPDATE";
export const INTEGRATION_UPDATE_SUCCESS = "INTEGRATION_UPDATE_SUCCESS";
export const INTEGRATION_UPDATE_FAILURE = "INTEGRATION_UPDATE_FAILURE";

export const INTEGRATION_TEST = "INTEGRATION_TEST";
export const INTEGRATION_TEST_SUCCESS = "INTEGRATION_TEST_SUCCESS";
export const INTEGRATION_TEST_FAILURE = "INTEGRATION_TEST_FAILURE";

export const INTEGRATION_GET = "INTEGRATION_GET";
export const INTEGRATION_GET_SUCCESS = "INTEGRATION_GET_SUCCESS";
export const INTEGRATION_GET_FAILURE = "INTEGRATION_GET_FAILURE";

export const {
  integrationsGet,
  integrationsGetSuccess,
  integrationsGetFailure,
  integrationDelete,
  integrationDeleteSuccess,
  integrationDeleteFailure,
  integrationUpdate,
  integrationAliasesUpdate,
  integrationUpdateSuccess,
  integrationUpdateFailure,
  integrationTest,
  integrationTestSuccess,
  integrationTestFailure,
  integrationGet,
  integrationGetSuccess,
  integrationGetFailure,
} = createActions(
  {},
  INTEGRATIONS_GET,
  INTEGRATIONS_GET_SUCCESS,
  INTEGRATIONS_GET_FAILURE,
  INTEGRATION_DELETE,
  INTEGRATION_DELETE_SUCCESS,
  INTEGRATION_DELETE_FAILURE,
  INTEGRATION_UPDATE,
  INTEGRATION_ALIASES_UPDATE,
  INTEGRATION_UPDATE_SUCCESS,
  INTEGRATION_UPDATE_FAILURE,
  INTEGRATION_TEST,
  INTEGRATION_TEST_SUCCESS,
  INTEGRATION_TEST_FAILURE,
  INTEGRATION_GET,
  INTEGRATION_GET_SUCCESS,
  INTEGRATION_GET_FAILURE
);
