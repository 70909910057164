import React, { forwardRef } from "react";
import styled from "styled-components";
import {
  borders,
  bottom,
  boxShadow,
  color,
  fontSize,
  height,
  minHeight,
  left,
  position,
  right,
  space,
  textAlign,
  top,
  width,
  minWidth,
  zIndex,
  layout
} from "styled-system";

const StyledBox = styled.div`
  ${borders}
  ${bottom}
  ${boxShadow}
  ${color}
  ${fontSize}
  ${height}
  ${minHeight}
  ${left}
  ${position}
  ${right}
  ${space}
  ${top}
  ${textAlign}
  ${width}
  ${minWidth}
  ${zIndex}
  ${layout}
`;

const Box = forwardRef(
  function box(
    {
      bg = "white",
      border,
      borderBottom,
      borderColor,
      boxShadow,
      borderRadius,
      bottom,
      color = "darkGrey",
      fontSize = 0,
      minHeight,
      height,
      left,
      position,
      right,
      space,
      textAlign,
      top,
      width,
      minWidth,
      zIndex,
      ...props
    },
    ref,
  ) {
    return <StyledBox
      bg={bg}
      border={border}
      borderBottom={borderBottom}
      borderColor={borderColor}
      borderRadius={borderRadius}
      bottom={bottom}
      boxShadow={boxShadow}
      color={color}
      fontSize={fontSize}
      height={height}
      minHeight={minHeight}
      left={left}
      position={position}
      right={right}
      space={space}
      textAlign={textAlign}
      top={top}
      width={width}
      minWidth={minWidth}
      zIndex={zIndex}
      ref={ref}
      {...props}
    />
  }
);

export default Box;
