import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black)" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const Valid = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <path
        d="M10.21,24.17c-0.66,0-1.3-0.26-1.77-0.73l-6.88-6.88c-0.98-0.98-0.98-2.56,0-3.54c0.98-0.98,2.56-0.98,3.54,0
	l4.66,4.66L19.55,2C20.29,0.83,21.83,0.48,23,1.2c1.17,0.73,1.53,2.27,0.79,3.45L12.33,23c-0.4,0.65-1.08,1.07-1.84,1.16
	C10.39,24.17,10.3,24.17,10.21,24.17z"
      />
    </StyledSvg>
  );
};

export default Valid;
