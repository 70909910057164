import React, { useState, useEffect } from "react";
import { bool, func, string } from "prop-types";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import theme from "../../../theme";

import Box from "../../ui/box";
import Flex from "../../ui/flex";
import Input from "../../ui/input";
import Submit from "../../ui/input-submit";

const propTypes = {
  emailVal: string,
  handleProjectslick: func,
  handlePeoplelick: func,
  handleSetEmail: func,
  handleSetPassword: func,
  handleSetPasswordConfirmation: func,
  handleSubmitClick: func,
  isAuthLoading: bool,
  passwordVal: string,
};

const StyledSpan = styled.span`
  display: flex;
  font-style: italic;
  color: ${theme.colors.red};
  font-size: 0.7rem;
  padding-top: 4px;
`;

const StyledImage = styled.div`
  min-height: 100vh;
  width: 60%;
  background-image: url("/images/team.jpg");
  background-size: cover;
`;

const SignUp = ({
  emailVal,
  errorMessage,
  inError,
  handleSetEmail,
  handleSetPassword,
  handleSetPasswordConfirmation,
  handleSubmitClick,
  isAuthLoading,
  passwordVal,
  passwordConfirmationVal,
  viewport,
}) => {
  const [validPassword, setValidPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValidPassword(passwordVal === passwordConfirmationVal);
  }, [passwordVal, passwordConfirmationVal]);

  return (
    <Flex
      alignItems={"center"}
      minHeight={"100vh"}
      justifyContent={"center"}
      px={0}
      width={1}
      overflow="hidden"
    >
      {viewport > 800 ? <StyledImage /> : null}
      <Flex
        height={"100%"}
        width={0.4}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <form onSubmit={handleSubmit(handleSubmitClick)}>
          <div
            style={{
              width: "100%",
              fontFamily: "Open Sans",
              textAlign: "center",
            }}
          >
            <h2
              style={{
                color: "#585858",
                marginBottom: "1rem",
                fontWeight: "600",
                fontSize: "2rem",
                marginTop: "5rem",
              }}
            >
              CREATE ACCOUNT
            </h2>
            <h3
              style={{
                color: "#585858",
                marginBottom: "2rem",
                fontWeight: "300",
                fontSize: "1rem",
              }}
            >
              SIGNUP FOR FREE AND GET STARTED
            </h3>
          </div>
          <Box mb={3} width="300px">
            <Input
              {...register("email", {
                required: true,
                pattern: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
              })}
              label={"Email"}
              error={errors.email ? true : false}
              autoFocus={true}
              value={emailVal}
              onChange={handleSetEmail}
              placeholder={"Email address"}
              type={"email"}
            />
            {errors.email && <StyledSpan>This field is required</StyledSpan>}
          </Box>
          <Box mb={4} width="300px">
            <Input
              {...register("password", { required: true })}
              label={"Password"}
              value={passwordVal}
              error={errors.password ? true : false}
              onChange={handleSetPassword}
              onEnter={handleSubmitClick}
              placeholder={"Password"}
              type={"password"}
            />
            {errors.password && <StyledSpan>This field is required</StyledSpan>}
          </Box>
          <Box
            mb={4}
            width="300px"
            style={{ borderColor: validPassword ? "green" : "red" }}
          >
            <Input
              {...register("passwordConf", {
                required: true,
                validate: (value) => value === passwordVal,
              })}
              label={"Password Confirmation"}
              value={passwordConfirmationVal}
              onChange={handleSetPasswordConfirmation}
              onEnter={handleSubmitClick}
              placeholder={"Repeat Password"}
              type={"password"}
              style={{ borderColor: "red" }}
              error={errors.passwordConf ? true : false}
            />
            {errors.passwordConf && (
              <StyledSpan>Password confirmation must match password</StyledSpan>
            )}
          </Box>

          <Submit variant="primary" content="Start" disabled={!validPassword} />
        </form>
      </Flex>
    </Flex>
  );
};

SignUp.propTypes = propTypes;

export default SignUp;
