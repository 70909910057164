import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.linkable
        ? `&:hover {
      filter: drop-shadow(-1px 1px 1px #4BAAC8); cursor: pointer;}`
        : ""}

    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black);" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const Integration = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} width="31" height="31" viewBox="2.5 2.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" variant={variant}>
      <path fillRule="evenodd" clipRule="evenodd" d="M22.0974 17.8614C22.9408 17.8614 23.6487 18.5575 23.5197 19.3904C22.7632 24.2904 18.5684 27.9285 13.5092 27.9285C7.91184 27.9285 3.375 23.3917 3.375 17.7956C3.375 13.1851 6.87763 8.89038 10.8211 7.91933C11.6684 7.71011 12.5368 8.30617 12.5368 9.17854C12.5368 15.0891 12.7355 16.618 13.8579 17.4496C14.9803 18.2812 16.3 17.8614 22.0974 17.8614Z" stroke="#515050" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M27.6157 12.9395C27.6828 9.14213 23.0183 3.02108 17.3341 3.12634C16.892 3.13423 16.538 3.50266 16.5183 3.94345C16.3749 7.06581 16.5683 11.1119 16.6762 12.9461C16.7091 13.5171 17.1578 13.9658 17.7275 13.9987C19.613 14.1066 23.8065 14.254 26.8841 13.7882C27.3025 13.725 27.6091 13.3619 27.6157 12.9395Z" stroke="#363636" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </StyledSvg>
  );
};

export default Integration;
