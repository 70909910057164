import React from "react";
import styled from "styled-components";
import theme from "../../../theme";

// Components
import Card from "../card/index";
import Flex from "../flex";
import Button from "../button/index";
import Text from "../text/index";
import Box from "../box/index";
import DropdownId from "../dropdown-with-id/index";

//Icons
import Close from "../icons/close";

const StyledBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99999;
`;

const StyledCardContainer = styled.div`
  position: fixed;
  background: transparent;
  width: 40%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledItemName = styled.div`
  font-size: 1.3rem;
  display: flex;
  color: ${theme.colors.lightRed};
  font-weight: 400;
  margin: 10px 0px;
`;

const ExportToProjectModal = ({
  title,
  projects,
  closeModal,
  assignItems,
  handleClick,
  handleSelectProject,
  exportProjectId
}) => {
  return (
    <StyledBackground>
      <StyledCardContainer>
        <Card
          fullHeader
          title={title}
          icon={<Close variant="grey"></Close>}
          closeHandler={closeModal}
          footer
          buttons={[
            <Button
              key="cancel"
              variant="secondaryLine"
              content="Cancel"
              onClick={closeModal}
            />,
            <Button
              key="continue"
              variant="secondaryLine"
              content="Export"
              disabled={!exportProjectId || assignItems == 0}
              onClick={() => handleClick(assignItems)}
            />,
          ]}
        >
          <Flex width="90%" justifyContent="center">
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="space-evenly"
            >
              <Text textAlign="center" fontSize="1.2rem" marginTop="15px">
                You are about to assign
              </Text>
              <StyledItemName>{assignItems} Candidates</StyledItemName>

              <Text textAlign="center" fontSize="1.2rem" margin="0 15px">
                to
              </Text>
              {projects && (
                <Box width="350px" marginTop="15px">
                  <DropdownId
                    formStyle
                    name="project"
                    value={exportProjectId}
                    placeholder="Add to Project..."
                    handleDropdown={handleSelectProject}
                    options={projects?.map((project) => ({
                      id: project.id,
                      val: project.attributes.name,
                    }))}
                    variant="blue"
                    small
                    short
                  />
                </Box>
              )}
            </Flex>
          </Flex>
        </Card>
      </StyledCardContainer>
    </StyledBackground>
  );
};

export default ExportToProjectModal;
