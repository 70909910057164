import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import ReactTooltip from "react-tooltip";
import SideBarNavigation from "@components/ui/sidebar";

import NotificationsModalContainer from "@containers/notifications/NotificationsModalContainer";

//Reducers
import {
  getLocationPathname,
  getInvitationsLoaded,
  getCurrentUser,
} from "@reducer";

import { useWorkspace } from "@hooks";

const SidebarNavigationContainer = ({ viewport }) => {
  const [activeWorkspace, setActiveWorkspace] = useState(null);
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser).id;
  const locationPathname = useSelector(getLocationPathname);
  const locationSplit = locationPathname.split("/");
  const isNotificationLoaded = useSelector(getInvitationsLoaded);
  const [submenuOpen, setSubmenuOpen] = useState(null);
  const [workspaces, currentWorkspace, visiblesWorkspaces, workspacesByAccess] = useWorkspace();

  useEffect(() => {
    setCurrentSubmenu();
    ReactTooltip.rebuild();
  }, [workspaces]);

  useEffect(() => {
    setCurrentSubmenu();
  }, [currentWorkspace]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [submenuOpen]);

  const setCurrentSubmenu = () => {
    workspaces.forEach((wk) => {
      if (wk.id === currentWorkspace) {
        setSubmenuOpen(wk.id);
        return;
      }
    });
  };

  const openLink = (path) => {
    dispatch(push(path));
  };

  const handleProjects = (workspace_id) => {
    openLink(`/workspaces/${workspace_id}/projects`);
  };

  const handleTalentPool = () => {
    onWorkspaceClicked(null);
    openLink(`/talent_pool`);
  };

  const handleHelpCenter = () => {
    onWorkspaceClicked(null);
    openLink(`/help_center`);
  };

  const handleWorkspace = (workspace) => {
    setActiveWorkspace(workspace);
    openLink(`/workspaces/${workspace.id}/home`);
  };

  const onWorkspaceClicked = (id) => {
    if (submenuOpen === id) {
      setSubmenuOpen();
    } else setSubmenuOpen(id);
  };

  return (
    <>
      <SideBarNavigation
        viewport={viewport}
        workspaces={workspaces}
        ownWorkspaces={workspacesByAccess.owner}
        guestWorkspaces={workspacesByAccess.guest}
        handleTalentPool={handleTalentPool}
        handleHelpCenter={handleHelpCenter}
        handleWorkspace={handleWorkspace}
        handleProjects={handleProjects}
        onWorkspaceClicked={onWorkspaceClicked}
        submenuOpen={submenuOpen}
        openLink={openLink}
        locationPath={locationPathname}
        locationSplit={locationSplit}
        activeWorkspace={activeWorkspace}
      />
      <NotificationsModalContainer showModal={isNotificationLoaded} />
      <ReactTooltip />
    </>
  );
};

export default SidebarNavigationContainer;
