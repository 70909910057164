import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.linkable
        ? `&:hover {
      fill: ${theme.colors.lightRed}; cursor: pointer;}`
        : ""}
    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black)" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const Trash = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <g>
        <path
          d="M21.5,4.6h-4.8V2.8c0-1-0.8-1.8-1.8-1.8H9.6c-1,0-1.8,0.8-1.8,1.8v1.8H3.5c-1,0-1.8,0.8-1.8,1.8v0.9
		c0,1,0.8,1.8,1.8,1.8h0.8v12.2C4.3,22.8,5.5,24,7,24h11c1.5,0,2.8-1.2,2.8-2.8V9h0.8c1,0,1.8-0.8,1.8-1.8V6.3
		C23.3,5.4,22.5,4.6,21.5,4.6z M9.3,2.8c0-0.1,0.1-0.3,0.3-0.3h5.3c0.1,0,0.3,0.1,0.3,0.3v1.8H9.3V2.8z M19.3,21.3
		c0,0.7-0.6,1.3-1.3,1.3H7c-0.7,0-1.3-0.6-1.3-1.3V9h13.5V21.3z M21.8,7.3c0,0.1-0.1,0.3-0.3,0.3H20H5H3.5c-0.1,0-0.3-0.1-0.3-0.3
		V6.3c0-0.1,0.1-0.3,0.3-0.3h4.3h8.8h4.8c0.1,0,0.3,0.1,0.3,0.3V7.3z"
        />
        <path d="M8.8,20.3c0.4,0,0.8-0.3,0.8-0.8V12c0-0.4-0.3-0.8-0.8-0.8S8,11.6,8,12v7.5C8,19.9,8.3,20.3,8.8,20.3z" />
        <path
          d="M12.5,21.5c0.4,0,0.8-0.3,0.8-0.8V12c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8v8.7
		C11.8,21.2,12.1,21.5,12.5,21.5z"
        />
        <path
          d="M16.3,20.3c0.4,0,0.8-0.3,0.8-0.8V12c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8v7.5
		C15.5,19.9,15.8,20.3,16.3,20.3z"
        />
      </g>
    </StyledSvg>
  );
};

export default Trash;
