import { createActions } from "redux-actions";

export const WORKFLOWS_GET = "WORKFLOWS_GET";
export const WORKFLOWS_GET_SUCCESS = "WORKFLOWS_GET_SUCCESS";
export const WORKFLOWS_GET_FAILURE = "WORKFLOWS_GET_FAILURE";

export const WORKFLOW_CREATE = "WORKFLOW_CREATE";
export const WORKFLOW_CREATE_SUCCESS = "WORKFLOW_CREATE_SUCCESS";
export const WORKFLOW_CREATE_FAILURE = "WORKFLOW_CREATE_FAILURE";

export const WORKFLOW_UPDATE = "WORKFLOW_UPDATE";
export const WORKFLOW_UPDATE_SUCCESS = "WORKFLOW_UPDATE_SUCCESS";
export const WORKFLOW_UPDATE_FAILURE = "WORKFLOW_UPDATE_FAILURE";

export const WORKFLOW_GET = "WORKFLOW_GET";
export const WORKFLOW_GET_SUCCESS = "WORKFLOW_GET_SUCCESS";
export const WORKFLOW_GET_FAILURE = "WORKFLOW_GET_FAILURE";

export const WORKFLOW_ASSIGN = "WORKFLOW_ASSIGN";
export const WORKFLOW_ASSIGN_SUCCESS = "WORKFLOW_ASSIGN_SUCCESS";
export const WORKFLOW_ASSIGN_FAILURE = "WORKFLOW_ASSIGN_FAILURE";

export const WORKFLOW_DELETE = "WORKFLOW_DELETE";
export const WORKFLOW_DELETE_SUCCESS = "WORKFLOW_DELETE_SUCCESS";
export const WORKFLOW_DELETE_FAILURE = "WORKFLOW_DELETE_FAILURE";

export const {
  workflowsGet,
  workflowsGetSuccess,
  workflowsGetFailure,
  workflowCreate,
  workflowCreateSuccess,
  workflowCreateFailure,
  workflowUpdate,
  workflowUpdateSuccess,
  workflowUpdateFailure,
  workflowGet,
  workflowGetSuccess,
  workflowGetFailure,
  workflowAssign,
  workflowAssignSuccess,
  workflowAssignFailure,
  workflowDelete,
  workflowDeleteSuccess,
  workflowDeleteFailure,
} = createActions(
  {},
  WORKFLOWS_GET,
  WORKFLOWS_GET_SUCCESS,
  WORKFLOWS_GET_FAILURE,
  WORKFLOW_CREATE,
  WORKFLOW_CREATE_SUCCESS,
  WORKFLOW_CREATE_FAILURE,
  WORKFLOW_UPDATE,
  WORKFLOW_UPDATE_SUCCESS,
  WORKFLOW_UPDATE_FAILURE,
  WORKFLOW_GET,
  WORKFLOW_GET_SUCCESS,
  WORKFLOW_GET_FAILURE,
  WORKFLOW_ASSIGN,
  WORKFLOW_ASSIGN_SUCCESS,
  WORKFLOW_ASSIGN_FAILURE,
  WORKFLOW_DELETE,
  WORKFLOW_DELETE_SUCCESS,
  WORKFLOW_DELETE_FAILURE,
);
