import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.linkable
        ? `&:hover {
      filter: drop-shadow(-1px 1px 1px #4BAAC8); cursor: pointer;}`
        : ""}

    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black);" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const Database = ({ variant, ...props }) => {
  return (
    <StyledSvg {...props} viewBox="0 0 25 25" variant={variant}>
      <g>
        <path d="M15,20.8c-0.4,0-0.8-0.3-0.8-0.8v-5c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8v5C15.8,20.4,15.4,20.8,15,20.8z" />
        <path
          d="M17.2,23.3H2.8c-1.3,0-2.3-1-2.3-2.2v-9.6c0-1.2,1.1-2.2,2.3-2.2h4C8,9.3,9,10.2,9,11.3c0,0.3,0.4,0.6,0.8,0.6
		h7.3c1.3,0,2.3,1,2.3,2.2v6.9C19.5,22.3,18.5,23.3,17.2,23.3z M2.8,10.8c-0.5,0-0.8,0.3-0.8,0.7v9.6c0,0.4,0.4,0.7,0.8,0.7h14.3
		c0.5,0,0.8-0.3,0.8-0.7v-6.9c0-0.4-0.4-0.7-0.8-0.7H9.8c-1.3,0-2.3-1-2.3-2.1c0-0.3-0.3-0.6-0.7-0.6H2.8z"
        />
        <path
          d="M21.3,18.1c-0.4,0-0.8-0.3-0.8-0.8v-6.9c0-0.4-0.4-0.7-0.8-0.7h-7.3c-1.3,0-2.3-1-2.3-2.1C10,7.3,9.7,7,9.3,7
		h-4C4.9,7,4.5,7.3,4.5,7.7c0,0.4-0.3,0.8-0.8,0.8S3,8.1,3,7.7c0-1.2,1.1-2.2,2.3-2.2h4c1.2,0,2.2,0.9,2.2,2.1
		c0,0.3,0.4,0.6,0.8,0.6h7.3c1.3,0,2.3,1,2.3,2.2v6.9C22,17.7,21.7,18.1,21.3,18.1z"
        />
        <path
          d="M23.8,14.3c-0.4,0-0.8-0.3-0.8-0.8V6.6c0-0.4-0.4-0.7-0.8-0.7h-7.3c-1.3,0-2.3-1-2.3-2.1
		c0-0.3-0.3-0.6-0.7-0.6h-4C7.4,3.3,7,3.6,7,3.9c0,0.4-0.3,0.8-0.8,0.8S5.5,4.3,5.5,3.9c0-1.2,1.1-2.2,2.3-2.2h4
		C13,1.8,14,2.7,14,3.8c0,0.4,0.4,0.6,0.8,0.6h7.3c1.3,0,2.3,1,2.3,2.2v6.9C24.5,14,24.2,14.3,23.8,14.3z"
        />
      </g>
    </StyledSvg>
  );
};

export default Database;
