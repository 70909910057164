import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import theme from "../../../theme";
import * as R from "ramda";

//reducer
import { getProjectPeople } from "../../../reducer";

import { getRelated } from "../../../utils";

//components
import Box from "../../ui/box";
import Flex from "../../ui/flex";
import Text from "../../ui/text";
import Card from "../../ui/card";
import Row from "../../ui/row";
import Steps from "../../ui/steps-list";
import Spinner from "../../ui/spinner";

//containers
import EditWorkflowModalContainer from "../../../containers/workflows/EditWorkflowModalContainer";

//icons
import People from "../../ui/icons/peopleGroup";
import Sequence from "../../ui/icons/template";
import Edit from "../../ui/icons/edit";

//Helper
import { isArchivedProject } from "@utils/project-helper";

const WorkflowInfo = styled(Text)`
  margin: 5px;
  width: 90%;
  align-items: center;
  font-size: 0.9rem;
  display: flex;
  flex-direction: row;
  color: ${theme.colors.gray};
  font-family: "Open Sans";
  flex-wrap: nowrap;
  text-overflow: ellipsis;
`;

const workflowsStagesList = (workflow, included) => {
  const relatedStages = getRelated(
    workflow.relationships.stages.data,
    included
  );

  return relatedStages.map((stage) => ({
    variant: "ready",
    label: stage.attributes.name,
    sequence: stage.attributes.sequence_id,
    sequenceName: stage.meta.sequence_name || '-',
  }));
};

const WorkflowProgress = ({
  workflow,
  workspace,
  stages,
  viewport,
  editActive,
  setIsEditActive,
  project
}) => {
  const projectPeople = useSelector(getProjectPeople);
  const getStagePeopleCountInWords = (stage) => {
    const matchStage = R.pathEq(["attributes", "status"], stage.label);
    let response = R.length(R.filter(matchStage, projectPeople.data));
    if (parseInt(response) > 1) {
      response += " Talents";
    } else {
      response += " Talent";
    }
    return response;
  };

  return (
    <Flex
      marginTop="3%"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width={viewport > 2000 ? "85%" : "95%"}
      backgroundColor={theme.colors.lighterGrey}
    >
      {editActive === true && (
        <EditWorkflowModalContainer
          workflow={workflow}
          stages={stages}
          isEditActive={editActive}
          closeEditModalHandler={() => setIsEditActive(false)}
        />
      )}
      {workflow.id ? (
        <>
          <Row
            icon={
              !isArchivedProject(project) &&
              <Edit
                variant="red"
                linkable
                onClick={() => setIsEditActive(true)}
              />
            }
          >
            <Text
              color={theme.colors.blue}
              fontFamily="Open Sans"
              fontSize="1.15rem"
            >
              {workflow?.attributes.name}
            </Text>
            <Box marginRight="30px">
              <Steps
                hasScroll
                alignCenter
                flat
                content={workflowsStagesList(workflow, stages)}
              />
            </Box>
          </Row>
          <Flex
            width="90%"
            justifyContent="space-evenly"
            backgroundColor="transparent"
            marginTop="10px"
            flexWrap="wrap"
            padding="20px"
          >
            {workflowsStagesList(workflow, stages).map((stage, index) => (
              <Card small header column title={stage.label} key={index}>
                <WorkflowInfo icon={<People variant="blue" />}>
                  {getStagePeopleCountInWords(stage)}
                </WorkflowInfo>
                <WorkflowInfo icon={<Sequence variant="blue" />}>
                  {stage.sequenceName}
                </WorkflowInfo>
              </Card>
            ))}
          </Flex>
        </>
      ) : (
        <>
          <Spinner />
        </>
      )}
    </Flex>
  );
};

export default WorkflowProgress;
