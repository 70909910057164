import React from "react";
import theme from "../../../theme";

//components
import Box from "../../ui/box";
import Card from "../../ui/card";
import Image from "../../../assets/img/project.svg";
import Flex from "../../ui/flex";

//containers
import NewProjectModalContainer from "../../../containers/projects/NewProjectModalContainer";

const ProjectIntro = ({ viewport }) => {
  return (
    <Flex
      paddingTop={"64px"}
      alignItems={"center"}
      width={"100%"}
      flexDirection={"column"}
      justifyContent={"flex-start"}
      px={{ sm: 2, md: 0 }}
      overflow="hidden"
      backgroundColor={theme.colors.lighterGrey}
    >
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        width={viewport > 2000 ? "85%" : "95%"}
        mt={"30px"}
        backgroundColor={theme.colors.lighterGrey}
      >
        <Card
          imageBlock={Image}
          textBlock={
            <Box>
              <p
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                Projects is the main section of TeamSourced, here you create and
                control all the projects inside the Workspace.
              </p>
              <p
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "300",
                  lineHeight: "1.5rem",
                }}
              >
                In each project you can manage people, establish a workflow and
                give access to several users.
              </p>
              <Flex alignItems="center">
                <NewProjectModalContainer showButton variant="secondaryLight" />
                <p
                  style={{
                    fontSize: "1.1rem",
                    fontWeight: "300",
                    lineHeight: "1.5rem",
                  }}
                >
                  to start working.
                </p>
              </Flex>
            </Box>
          }
        ></Card>
      </Flex>
    </Flex>
  );
};

export default ProjectIntro;
