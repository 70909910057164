import { combineActions, handleActions } from "redux-actions";
import { lensProp, pipe, set, view } from "ramda";
import {
  workspacesGet,
  workspacesGetSuccess,
  workspacesGetFailure,
  onExpiredToken,
  workspaceGet,
  workspaceGetSuccess,
  workspaceGetFailure,
  workspaceCreate,
  currentWorkspaceSet,
  currentWorkspaceClean,
  workspaceAnalyticsGetSuccess,
} from "../actions";

const initialState = {
  inError: false,
  loading: false,
  token: localStorage.getItem("access-token"),
  data: [],
  current: "",
  included: [],
  analytics: [],
};

const isLoadingLens = lensProp("loading");
const inErrorLens = lensProp("inError");
const tokenLens = lensProp("token");
const dataLens = lensProp("data");
const currentLens = lensProp("current");
const includedLens = lensProp("included");
const analyticsLens = lensProp("analytics");

const setData = set(dataLens);
const setAnalytics = set(analyticsLens);
const setIncluded = set(includedLens);

export const getIsLoading = view(isLoadingLens);
export const getInError = view(inErrorLens);
export const getToken = view(tokenLens);

export default handleActions(
  {
    //workspace
    [workspaceGetSuccess]: (state, action) =>
      pipe(
        setData(action.payload.data.data),
        set(isLoadingLens, false),
        setIncluded(action.payload.data.included)
      )(state),
    [workspaceGetFailure]: (state) =>
      pipe(set(inErrorLens, true), set(isLoadingLens, false))(state),
    [workspaceGet]: (state) =>
      pipe(set(inErrorLens, false), set(isLoadingLens, true))(state),

    //new workspace
    [workspaceCreate]: (state, action) =>
      pipe(set(inErrorLens, false), set(isLoadingLens, true))(state),
    //current
    [currentWorkspaceSet]: (state, action) =>
      pipe(set(currentLens, action.payload.workspace_id))(state),
    [currentWorkspaceClean]: (state, action) =>
      pipe(set(currentLens, ""))(state),
    [workspaceAnalyticsGetSuccess]: (state, action) =>
      pipe(setAnalytics(action.payload.data))(state),
    // handle expired token
    [combineActions(onExpiredToken)]: (state) => {
      return { ...initialState };
    },
  },
  initialState
);
export const getWorkspaces = (state) => state.workspace;
export const getWorkspacesData = (state) => state.workspace.data;
export const getWorkspaceCurrent = (state) => state.workspace.current;
export const getWorkspaceAnalytics = (state) => state.workspace.analytics;
export const getWorkspaceIncluded = (state) => state.workspace.included;

export const getCurrentWorkspace = (state) =>
  state.app.data.workspaces.find(
    (workspace) => workspace.id === state.workspace.current
  ) || {};
