import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import theme from "../../../theme";

// Import images
import sourcedLogo from "../../../assets/img/sourcedLogo.svg";
import stockTradingOnline from "../../../assets/img/stock-trading-online.png";
import stockTradingOnline1 from "../../../assets/img/stock-trading-online-1.png";
import findingBugsInTheCode from "../../../assets/img/finding-bugs-in-the-code.png";
import solvingAMentalHealthProblem from "../../../assets/img/solving-a-mental-health-problem.png";
import onlineMeetingOnATablet from "../../../assets/img/online-meeting-on-a-tablet.png";
import testimonialJerryTing from "../../../assets/img/services_img/0.png";
import testimonialErickGoss from "../../../assets/img/services_img/1.png";
import testimonialNatePulley from "../../../assets/img/services_img/2.png";
import image4Light from "../../../assets/img/image-4-light.png";
import group28 from "../../../assets/img/group-28.png";
import group31 from "../../../assets/img/group-31.png";
import group87 from "../../../assets/img/group-87.png";
import imagen16 from "../../../assets/img/imagen-16.png";
import group32 from "../../../assets/img/group-32.png";
import group30 from "../../../assets/img/group-30.png";
import group33 from "../../../assets/img/group-33.png";
import mail2 from "../../../assets/img/mail-2.svg";
import twitter1 from "../../../assets/img/twitter-1.svg";
import linkedin1 from "../../../assets/img/linkedin-1.svg";
import facebook1 from "../../../assets/img/facebook-1.svg";
import site2 from "../../../assets/img/site-2.svg";
import union3 from "../../../assets/img/union-3.svg";
import union1 from "../../../assets/img/union-1.svg";

const StyledServicesPage = styled.div`
  align-items: flex-start;
  background-color: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

const Navigation = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
  width: 100%;
  padding: 20px;
`;

const Banner = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  position: relative;
  width: 100%;
  background-image: url(${image4Light});
  background-size: cover;
  background-position: center;
  height: 682px;
`;

const BannerContent = styled.div`
  position: absolute;
  top: 69px;
  left: 0;
  width: 784px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
`;

const ButtonWrapper = styled.div`
  display: inline-block; // This will make the wrapper only as wide as its content
`;

const BannerTitle = styled.h1`
  color: ${theme.colors.darkBlue};
  font-family: ${theme.fonts[1]};
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 58px;
`;

const BannerSubtitle = styled.p`
  color: ${theme.colors.grey};
  font-family: ${theme.fonts[1]};
  font-size: 24px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 33.7px;
`;

const StyledButton = styled.a`
  all: unset;
  box-sizing: border-box;
  background-color: ${theme.colors.red};
  border-radius: 5px;
  color: ${theme.colors.white};
  font-family: ${theme.fonts[1]};
  font-size: 14px;
  font-weight: 700;
  padding: 8px 16px;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
  display: inline-block;

  &:hover {
    background-color: ${theme.colors.darkRed};
  }
`;

const Section = styled.section`
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const SectionTitle = styled.h2`
  color: ${theme.colors.red};
  font-family: ${theme.fonts[1]};
  font-size: 40px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 48.4px;
  text-align: center;
  margin-bottom: 24px;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 0 auto;
`;

const TextContent = styled.div`
  flex: 1;
  padding-right: 40px;
`;

const StyledParagraph = styled.p`
  color: ${theme.colors.grey};
  font-family: ${theme.fonts[0]};
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const FeatureSection = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 69px;
  margin-top: 64px;
  flex-wrap: wrap;
`;

const FeatureItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: calc(25% - 52px);
`;

const FeatureImage = styled.img`
  width: 180px;
  height: 180px;
  object-fit: contain;
`;

const FeatureTitle = styled.h3`
  color: ${theme.colors.blue};
  font-family: ${theme.fonts[1]};
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
`;

const FeatureDescription = styled.p`
  color: ${theme.colors.grey};
  font-family: ${theme.fonts[0]};
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  width: 100%;
`;

const TestimonialSection = styled(Section)`
  width: 100%;
  background-color: ${theme.colors.lightBlue};
  padding: 60px 0;
`;

const TestimonialSlider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  width: 100%;
`;

const TestimonialContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

const TestimonialWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
`;

const CompanyLogos = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 29px;
  margin-top: 64px;
`;

const CenteredSection = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`;

const Footer = styled.footer`
  background-color: ${theme.colors.darkGrey};
  color: ${theme.colors.white};
  padding: 56px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-bottom: 64px;
`;

const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
`;

const FooterTitle = styled.h4`
  font-family: ${theme.fonts[1]};
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 16px;
`;

const FooterLink = styled.a`
  color: ${theme.colors.white};
  font-family: ${theme.fonts[0]};
  font-size: 16px;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: ${theme.colors.lightBlue};
  }
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 13px;
`;

const SocialIcon = styled.a`
  color: ${theme.colors.white};
  font-size: 24px;
  transition: color 0.3s ease;

  &:hover {
    color: ${theme.colors.lightBlue};
  }
`;

const Copyright = styled.div`
  display: flex;
  gap: 40px;
  font-size: 12px;
  color: ${theme.colors.lightGrey};
`;

const CenteredFooter = styled(Footer)`
  align-items: center;
`;

const CenteredFooterContent = styled(FooterContent)`
  justify-content: center;
  gap: 40px;
  width: 100%;
  max-width: 1200px;
`;

const HeaderWithLogo = ({ logo }) => (
  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
    <img src={logo} alt="Logo" style={{ width: "38px", height: "38px" }} />
    <span
      style={{
        color: "#505050",
        fontFamily: '"Open Sans", Helvetica',
        fontSize: "18px",
      }}
    >
      <strong>TEAM</strong>SOURCED
    </span>
  </div>
);

const TestimonialArrows = ({ direction, onClick }) => (
  <div
    style={{
      width: "133px",
      height: "133px",
      borderRadius: "66.5px",
      backgroundColor: "#F2F2F2",
      border: "1px solid #ABABAB",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
    }}
    onClick={onClick}
  >
    <img
      src={direction === "left" ? union3 : union1}
      alt={`${direction} arrow`}
      style={{ width: "21px", height: "35px" }}
    />
  </div>
);

const Testimonial = ({ data }) => (
  <div
    style={{
      width: "650px",
      flex: "0 0 auto",
      padding: "32px",
      borderRadius: "16px", // rounded-lg
      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
      backgroundColor: "#FFFFFF",
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    }}
  >
    <p
      style={{
        color: "#515050",
        fontFamily: '"Rubik", Helvetica',
        fontSize: "20px",
        lineHeight: "26.1px",
      }}
    >
      {data.text}
    </p>
    <div style={{ display: "flex", alignItems: "center", gap: "24px" }}>
      <img
        src={data.image}
        alt="Testimonial"
        style={{ width: "88px", height: "88px", borderRadius: "50%" }} // Make image circular
      />
      <div>
        <p
          style={{
            color: "#145DA0",
            fontFamily: '"Rubik", Helvetica',
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "26.1px",
          }}
        >
          {data.name}
        </p>
        <p
          style={{
            color: "#85A5C2",
            fontFamily: '"Rubik", Helvetica',
            fontSize: "20px",
            lineHeight: "26.1px",
          }}
        >
          {data.position}
        </p>
      </div>
    </div>
  </div>
);

const ServicesPage = () => {
  const dispatch = useDispatch();
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const testimonials = [
    {
      text:
        "My favorite feature of the TeamSourced platform is their delegated sending. Using it enabled them to engage talent as our team and allowed us to make a hire a year after they contacted a candidate for us. The long term network effects are amazing.",
      name: "Jerry Ting",
      position: "Founder and CEO of Evisort",
      image: testimonialJerryTing,
    },
    {
      text:
        "TeamSourced was in place when I started leading talent operations at Bolt and had done a great job of building out a lot of the initial process in place. They were great partners as Bolt began to scale up to hiring over 200 people in a short period of time.",
      name: "Nate Pulley",
      position: "Head of Talent Operations for Bolt",
      image: testimonialNatePulley,
    },
    {
      text:
        "TeamSourced have been invaluable in building out our recruiting function, consulting with us every step of the way. Most importantly, they helped us build and install a talent evaluation and development framework that will be the basis for building a world class team for years to come.",
      name: "Erick Goss",
      position: "CEO and Co-founder at Minno",
      image: testimonialErickGoss,
    },
  ];

  const nextTestimonial = () => {
    setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentTestimonial(
      (prev) => (prev - 1 + testimonials.length) % testimonials.length
    );
  };

  useEffect(() => {
    const timer = setInterval(nextTestimonial, 7000);
    return () => clearInterval(timer);
  }, []);

  return (
    <StyledServicesPage>
      <Navigation>
        <HeaderWithLogo logo={sourcedLogo} />
      </Navigation>

      <Banner>
        <BannerContent>
          <BannerTitle>
            Scale Your Team Faster with Our Recruiting Services.
          </BannerTitle>
          <BannerSubtitle>
            Flexible solutions including monthly talent sourcing and contract
            recruiter placement.
          </BannerSubtitle>
          <ButtonWrapper>
            <StyledButton
              href="https://calendly.com/teamsourced/learn-about-teamsourced"
              target="_blank"
              rel="noopener noreferrer"
            >
              Book a Meeting
            </StyledButton>
          </ButtonWrapper>
        </BannerContent>
      </Banner>

      <Section>
        <SectionTitle>
          Scale Your Team with our Flexible Monthly Solutions
        </SectionTitle>
        <FlexContainer>
          <TextContent>
            <StyledParagraph>
              Whether you need help at the top of your talent funnel or a
              complete recruiting department build-out, TeamSourced's recruiting
              services can help.
            </StyledParagraph>
            <StyledParagraph>
              Our team of experienced recruiters has worked with high-growth
              startups and large companies alike, perfecting processes and
              strategies to deliver the best results.
            </StyledParagraph>
          </TextContent>
          <img
            src={stockTradingOnline}
            alt="Recruiting illustration"
            width="400"
          />
        </FlexContainer>
      </Section>

      <Section>
        <SectionTitle>Our Features Stand Out, and You Will Too</SectionTitle>
        <FeatureSection>
          <FeatureItem>
            <FeatureImage src={stockTradingOnline1} alt="Data Analytics" />
            <FeatureTitle>REAL TIME DATA ANALYTICS AND REPORTING</FeatureTitle>
            <FeatureDescription>
              Track your recruitment progress with our advanced analytics tools
            </FeatureDescription>
          </FeatureItem>
          <FeatureItem>
            <FeatureImage src={findingBugsInTheCode} alt="Talent Sourcing" />
            <FeatureTitle>
              AFFORDABLE, MONTHLY, TARGETED TALENT SOURCING WITH PERSONALIZED
              OUTREACH
            </FeatureTitle>
            <FeatureDescription>
              Reach the right candidates with our personalized approach
            </FeatureDescription>
          </FeatureItem>
          <FeatureItem>
            <FeatureImage
              src={solvingAMentalHealthProblem}
              alt="Flexible Support"
            />
            <FeatureTitle>
              FLEXIBLE, CONTRACT RECRUITING SUPPORT WITH SOURCING INCLUDED
            </FeatureTitle>
            <FeatureDescription>
              Get the support you need, when you need it
            </FeatureDescription>
          </FeatureItem>
          <FeatureItem>
            <FeatureImage src={onlineMeetingOnATablet} alt="Consulting" />
            <FeatureTitle>
              CONSULTING AND TRAINING TO BUILD YOUR IN-HOUSE RECRUITING FUNCTION
            </FeatureTitle>
            <FeatureDescription>
              Empower your team with our expert guidance
            </FeatureDescription>
          </FeatureItem>
        </FeatureSection>
      </Section>

      <TestimonialSection>
        <SectionTitle>Testimonials</SectionTitle>
        <TestimonialContainer>
          <TestimonialSlider>
            <TestimonialArrows direction="left" onClick={prevTestimonial} />
            <div style={{ overflow: "hidden", width: "650px" }}>
              <div
                style={{
                  display: "flex",
                  transition: "transform 0.5s ease-in-out",
                  transform: `translateX(-${currentTestimonial * 100}%)`,
                }}
              >
                {testimonials.map((testimonial, index) => (
                  <Testimonial key={index} data={testimonial} />
                ))}
              </div>
            </div>
            <TestimonialArrows direction="right" onClick={nextTestimonial} />
          </TestimonialSlider>
        </TestimonialContainer>
      </TestimonialSection>

      <Section>
        <SectionTitle>Companies who use TeamSourced</SectionTitle>
        <CompanyLogos>
          <img src={group28} alt="Company logo" />
          <img src={group31} alt="Company logo" />
          <img src={group87} alt="Company logo" />
          <img src={imagen16} alt="Company logo" />
          <img src={group32} alt="Company logo" />
          <img src={group30} alt="Company logo" />
          <img src={group33} alt="Company logo" />
        </CompanyLogos>
      </Section>

      <CenteredSection>
        <SectionTitle>
          Recruit Smarter, Not Harder with TeamSourced
        </SectionTitle>
        <ButtonWrapper>
          <StyledButton
            href="https://calendly.com/teamsourced/learn-about-teamsourced"
            target="_blank"
            rel="noopener noreferrer"
          >
            Book Now
          </StyledButton>
        </ButtonWrapper>
      </CenteredSection>

      <CenteredFooter>
        <CenteredFooterContent>
          <FooterColumn>
            <HeaderWithLogo logo={sourcedLogo} />
          </FooterColumn>
          <FooterColumn>
            <FooterTitle>Company</FooterTitle>
            <FooterLink href="https://teamsourced.com/">Home</FooterLink>
            <FooterLink href="https://boards.greenhouse.io/teamsourced">
              Careers
            </FooterLink>
            <FooterLink href="mailto:info@sourcedllc.com">
              Contact Us
            </FooterLink>
          </FooterColumn>
          <FooterColumn>
            <FooterTitle>Resources</FooterTitle>
            <FooterLink href="https://talentgrind.substack.com/">
              Blog
            </FooterLink>
            <FooterLink href="#">Help Center</FooterLink>
          </FooterColumn>
          <SocialIcons>
            <SocialIcon href="mailto:info@sourcedllc.com" aria-label="Email">
              <img src={mail2} alt="Email" width="28" height="28" />
            </SocialIcon>
            <SocialIcon href="http://x.com/team_sourced" aria-label="Twitter">
              <img src={twitter1} alt="Twitter" width="28" height="28" />
            </SocialIcon>
            <SocialIcon
              href="https://www.linkedin.com/company/sourced-llc"
              aria-label="LinkedIn"
            >
              <img src={linkedin1} alt="LinkedIn" width="28" height="28" />
            </SocialIcon>
            <SocialIcon
              href="https://www.facebook.com/teamsourced/"
              aria-label="Facebook"
            >
              <img src={facebook1} alt="Facebook" width="28" height="28" />
            </SocialIcon>
            <SocialIcon href="https://sourcedllc.com/" aria-label="Website">
              <img src={site2} alt="Website" width="28" height="28" />
            </SocialIcon>
          </SocialIcons>
        </CenteredFooterContent>
        <Copyright>
          <FooterLink href="https://teamsourced.com/privacy_policy">
            Privacy Policy
          </FooterLink>
          <FooterLink href="https://sourcedllc.com/california-consumer-privacy-act/">
            California Consumer Privacy Act
          </FooterLink>
          <span>© Sourced LLC 2024, copyright all rights reserved</span>
        </Copyright>
      </CenteredFooter>
    </StyledServicesPage>
  );
};

export default ServicesPage;
