import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import { signUpSet } from "../../actions";

import { getAuthLoading, getIsUserLoaded } from "../../reducer";

import { SignUp } from "../../components/domain/account";

import Spinner from "../../components/ui/spinner";

const SignUpContainer = ({ viewport, ...props }) => {
  const dispatch = useDispatch();
  const redirectPath = "/workspaces/me/projects"; //props.location.search.replace("?redirect=", "");
  const isUserLoaded = useSelector(getIsUserLoaded);
  const loading = useSelector(getAuthLoading);
  //------
  // data

  const [emailVal, setEmail] = useState("");
  const [passwordVal, setPassword] = useState("");
  const [passwordConfirmationVal, setPasswordConfirmation] = useState("");

  //------
  // handlers

  const handleSetEmail = (e) => setEmail(e.target.value);
  const handleSetPassword = (e) => setPassword(e.target.value);
  const handleSetPasswordConfirmation = (e) =>
    setPasswordConfirmation(e.target.value);

  const handleSubmitClick = () => {
    if (viewport > 800) {
      dispatch(
        signUpSet({
          email: emailVal,
          password: passwordVal,
          redirectPath,
        })
      );
    } else {
      dispatch(
        signUpSet({
          email: emailVal,
          password: passwordVal,
          redirectPath: "/mobile_warning",
        })
      );
    }
  };

  useEffect(() => {
    if (isUserLoaded) {
      dispatch(push("/"));
    }
  }, [isUserLoaded]);

  return (
    <>
      {loading ? <Spinner /> : null}
      <SignUp
        viewport={viewport}
        emailVal={emailVal}
        passwordVal={passwordVal}
        passwordConfirmationVal={passwordConfirmationVal}
        errorMessage={""}
        handleSetEmail={handleSetEmail}
        handleSetPassword={handleSetPassword}
        handleSetPasswordConfirmation={handleSetPasswordConfirmation}
        handleSubmitClick={handleSubmitClick}
        inError={false}
      />
    </>
  );
};

export default SignUpContainer;
