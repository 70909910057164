import React from "react";
import styled from "styled-components";
import theme from "../../../theme";

//Component
import Input from "../input/index";
import Dropdown from "../dropdown/index";
import Flex from "../flex";
import Text from "../text";

const StyledContainer = styled(Flex)`
  position: relative;
  width: auto;
  flex-direction: column;
`;

const StyledRequired = styled.span`
  color: ${theme.colors.red};
  font-size: 1rem;
  padding-left: 4px;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  ${(props) => (props.short ? `height: 40px;` : `height: 52px;`)};
`;

const InputIntegrated = ({
  mb,
  mt,
  small, //small dropdown arrow
  short, // short height (40px) - use in table-bar and short containers
  label, // label over the input - use in large forms
  button, // button for submit
  placeholderFirst, //placeholder for the input
  placeholderSecond, //placeholder for the dropdown
  nameFirst, //name for the input
  nameSecond, //name for the dropdown
  required, // * next to the label - when is required
  options, // options for the dropdown
  dirtyChanges,
  handleSubmitClick,
  handleChange,
  handleDropdown,
  handleDropdownSelect,
  ...props
}) => {
  return (
    <StyledContainer mb={mb} mt={mt} {...props}>
      {label && (
        <Text
          fontSize={theme.fontSizes[2]}
          fontFamily={theme.fonts[1]}
          fontWeight={0}
          mb={1}
          pt={2}
        >
          {label}
          {required ? <StyledRequired>*</StyledRequired> : null}
        </Text>
      )}

      <StyledButtonContainer short={short}>
        <Input
          width="350px"
          placeholder={placeholderFirst}
          name={nameFirst || "input"}
          value={dirtyChanges[nameFirst]}
          onChange={handleChange}
          marginRight="20px"
        />
        <Dropdown
          width="200px"
          name={nameSecond || "dropdown"}
          value={dirtyChanges[nameSecond]}
          handleDropdown={handleDropdown}
          handleDropdownSelect={handleDropdownSelect}
          onChange={handleChange}
          formStyle
          small={small}
          short={short}
          variant="blue"
          options={options}
          placeholder={placeholderSecond}
          marginRight="20px"
        />
        {button}
      </StyledButtonContainer>
    </StyledContainer>
  );
};

export default InputIntegrated;
