import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//Component
import { Projects } from "../../components/domain/projects";
import Spinner from "../../components/ui/spinner";

//Actions
import {
  projectUpdate,
  projectDelete,
  projectsGet,
  currentWorkspaceSet,
  currentProjectSet,
} from "../../actions";

//Reducers
import { getProjects, getProjectsLoading } from "../../reducer";

const ProjectsContainer = ({ viewport, ...props }) => {
  const workspaceId = props.match.params.id || "me";
  const projects = useSelector(getProjects);
  const [activeProject, setActiveProject] = useState({});
  const [isEditActive, setIsEditActive] = useState(false);
  const [isRemoveActive, setIsRemoveActive] = useState(false);
  const [itemToRemove, setItemToRemove] = useState();
  const [isArchiveActive, setIsArchiveActive] = useState(false);
  const [itemToArchive, setItemToArchive] = useState();
  const dispatch = useDispatch();
  const [byStatus, setByStatus] = useState('not_archived');
  const loading = useSelector(getProjectsLoading);

  useEffect(() => {
    dispatch(currentWorkspaceSet({ workspace_id: workspaceId }));
    dispatch(currentProjectSet({ project_id: "" }));
    dispatch(projectsGet({ workspace_id: workspaceId, by_status: byStatus }));
  }, [workspaceId, byStatus]);

  const closeEditModalHandler = () => {
    setIsEditActive(false);
  };

  const removeModalHandler = (removeItem) => {
    setIsRemoveActive(true);
    setItemToRemove(removeItem);
  };

  const archiveModalHandler = (archiveItem) => {
    setIsArchiveActive(true);
    setItemToArchive(archiveItem);
  };

  const closeRemoveModalHandler = () => {
    setIsRemoveActive(false);
  };

  const closeArchiveModalHandler = () => {
    setIsArchiveActive(false);
  };

  const editProjectHandler = (project) => {
    setActiveProject(project);
    setIsEditActive(!isEditActive);
  };

  const statusProjectHandler = (state, toggle_props) => {
    dispatch(
      projectUpdate({
        status: state ? "Active" : "Inactive",
        id: toggle_props.project_id,
        workspace_id: toggle_props.workspace_id,
      })
    );
  };

  const removeProjectHandler = (project) => {
    dispatch(
      projectDelete({
        workspace_id: project.attributes.workspace_id,
        id: project.id,
        by_status: byStatus
      })
    );
    closeRemoveModalHandler();
  };

  const archiveProjectHandler = (project) => {
    dispatch(
      projectUpdate({
        status: "Archived",
        id: project.id,
        workspace_id: project.attributes.workspace_id
      })
    );
    closeArchiveModalHandler();
  };

  const rowCurrentMenuOptions = [
    { name: "Edit", callback: editProjectHandler },
    { name: "Remove", alert: removeModalHandler },
    { name: "Archive", alert: archiveModalHandler },
  ];

  const rowArchivedMenuOptions = [
    { name: "Show", callback: editProjectHandler },
    { name: "Remove", alert: removeModalHandler },
  ];

  const handleSubNavbarTabs = (label) => {
    if (label == "Current")
      setByStatus("not_archived");
    if (label == "Archived")
      setByStatus("archived");
  }

  return loading && !workspaceId ? (
    <Spinner />
  ) : (
    <Projects
      viewport={viewport}
      projects={projects}
      activeProject={activeProject}
      isEditActive={isEditActive}
      closeEditModalHandler={closeEditModalHandler}
      isRemoveActive={isRemoveActive} // remove-modal status - open/close
      closeRemoveModalHandler={closeRemoveModalHandler} //close modal handler - prop for remove-modal component
      itemToRemove={itemToRemove} //complete item to remove - must have attributes.name and attributes.id - prop for remove-modal component
      removeProjectHandler={removeProjectHandler} // remove action - prop for remove-modal component
      rowCurrentMenuOptions={rowCurrentMenuOptions}
      rowArchivedMenuOptions={rowArchivedMenuOptions}
      statusProjectHandler={statusProjectHandler}
      handleSubNavbarTabs={handleSubNavbarTabs}
      isArchiveActive={isArchiveActive}
      itemToArchive={itemToArchive}
      archiveProjectHandler={archiveProjectHandler}
      closeArchiveModalHandler={closeArchiveModalHandler}
    />
  )
};

export default ProjectsContainer;
