import { combineActions, handleActions } from "redux-actions";
import { lensProp, pipe, set, view } from "ramda";

import {
  onExpiredToken,
  messagesGet,
  messagesGetSuccess,
  messagesGetFailure,
  // updateMessage,
  // updateMessageSuccess,
  // updateMessageFailure,
  // sendEventMessage,
  // sendEventMessageSuccess,
  // sendEventMessageFailure,
} from "../actions";

const initialState = {
  inError: false,
  loading: false,
  token: localStorage.getItem("access-token"),
  data: [],
  included: [],
};

const isLoadingLens = lensProp("loading");
const inErrorLens = lensProp("inError");
const tokenLens = lensProp("token");
const dataLens = lensProp("data");
const includedLens = lensProp("included");

const setData = set(dataLens);
const setIncluded = set(includedLens);

export const getIsLoading = view(isLoadingLens);
export const getInError = view(inErrorLens);
export const getToken = view(tokenLens);

export default handleActions(
  {
    [messagesGetSuccess]: (state, actions) =>
      pipe(
        setData(actions.payload.data.data),
        setIncluded(actions.payload.data.included),
        set(inErrorLens, false),
        set(isLoadingLens, false)
      )(state),

    [messagesGetFailure]: (state) =>
      pipe(set(inErrorLens, true), set(isLoadingLens, false))(state),

    [messagesGet]: (state) =>
      pipe(set(inErrorLens, false), set(isLoadingLens, true))(state),

    // handle expired token
    [combineActions(onExpiredToken)]: (state) => {
      localStorage.clear(); // remove token
      return {
        ...initialState,
        token: null,
      };
    },
  },
  initialState
);

export const getMessagesData = (state) => state.messages.data;
