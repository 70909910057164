import React from "react";
import styled from "styled-components";
import theme from "../../../theme";
import { NavLink } from "react-router-dom";

//components
import Box from "../../ui/box";
import Flex from "../../ui/flex";
import Row from "../../ui/row";
import Picture from "../../ui/picture";
import Text from "../../ui/text";
import Image from "../../../assets/img/avatar.png";

//icons
import Location from "../../ui/icons/location";
import Phone from "../../ui/icons/phone";
import CalendarLast from "../../ui/icons/calendarLast";
import Degree from "../../ui/icons/degree";
import Mail from "../../ui/icons/message";
import Project from "../../ui/icons/project";

const StyledNavLink = styled(NavLink)`
  cursor: pointer;
  margin: 0;
  text-decoration: none;
  outline: none;
  display: flex;
  width: 35%;
`;

const PersonInfo = styled(Text)`
  margin: 5px;
  align-items: center;
  font-size: 0.9rem;
  display: flex;
  flex-direction: row;
  color: ${theme.colors.gray};
  font-family: "Open Sans";
  flex-wrap: nowrap;
  cursor: pointer;

  &:hover {
    filter: drop-shadow(0px 0px 3px #caf0fc);
    color: ${theme.colors.darkBlue};
  }
`;

const TextLink = styled(Text)`
  font-family: "Open Sans";
  font-size: 1.1rem;
  padding-left: 3px;
  width: 100%;
  cursor: pointer;

  &:hover {
    filter: drop-shadow(0px 0px 3px #caf0fc);
    color: ${theme.colors.darkBlue};
  }
`;

const InfoBox = styled(Box)`
  padding: 0px 15px;
  font-family: "Open Sans";
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PeopleSearch = ({ viewport, projectPeople }) => {
  return (
    <Flex
      paddingTop="64px"
      alignItems="center"
      height="auto"
      width="100%"
      flexDirection="column"
      justifyContent="flex-start"
      backgroundColor={theme.colors.lighterGrey}
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width={viewport > 2000 ? "85%" : "95%"}
        backgroundColor={theme.colors.lighterGrey}
      >
        <Flex
          marginTop="3%"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
          backgroundColor={theme.colors.lighterGrey}
        ></Flex>

        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="auto"
          backgroundColor="transparent"
        >
          {projectPeople.data?.map((projectPerson, index) => (
            <Row key={index}>
              <StyledNavLink
                to={`/workspaces/${projectPerson.workspace.id}/profile/${projectPerson.person_id}`}
                style={{ textDecoration: "none" }}
              >
                <InfoBox>
                  <Box>
                    <Picture
                      bgImage={projectPerson.person.avatar_url || Image}
                    ></Picture>
                  </Box>
                  <Box>
                    <TextLink nowrap title="To Profile">
                      {projectPerson.person["first_name"] +
                        " " +
                        projectPerson.person["last_name"]}
                    </TextLink>
                    <PersonInfo icon={<Location variant="blue" />}>
                      {projectPerson.person["city"]},{" "}
                      {projectPerson.person["state"]}
                    </PersonInfo>
                    <PersonInfo icon={<Phone variant="blue" />}>
                      {projectPerson.person["phone"]}
                    </PersonInfo>
                  </Box>
                </InfoBox>
              </StyledNavLink>
              <Box width="35%">
                <PersonInfo icon={<CalendarLast variant="blue" />}>
                  {projectPerson.person["company"]} -{" "}
                  {projectPerson.person["title"]}
                </PersonInfo>
                <PersonInfo icon={<Degree variant="blue" />}>
                  {projectPerson.person["degree"]}
                </PersonInfo>
                <PersonInfo icon={<Mail variant="blue" />}>
                  {projectPerson.person["email"]}
                </PersonInfo>
              </Box>
              {projectPerson.project && (
                <Flex width="30%" alignItems="center">
                  <StyledNavLink
                    to={`/workspaces/${projectPerson.workspace.id}/project/${projectPerson.project_id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <PersonInfo icon={<Project variant="blue" />}>
                      {projectPerson.workspace?.name}
                    </PersonInfo>
                    <PersonInfo>({projectPerson.project["name"]})</PersonInfo>
                  </StyledNavLink>
                </Flex>
              )}
            </Row>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PeopleSearch;
