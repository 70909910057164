import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import theme from "../../theme";
import * as R from "ramda";
import { getRelated } from "../../utils";

//components
import Spinner from "@ui/spinner";
import Flex from "@ui/flex";
import ModalRemove from "@ui/modal-remove";
import StepsSelector from "@ui/steps-selector/index";
import { PeopleList } from "@components/domain/people";
import { ProjectPeopleIntro } from "@components/domain/projects";
import { AlertSequence } from "@ui/modal";

//Containers
import EditPersonModalContainer from "../people/EditPersonModalContainer";

//Actions
import {
  projectPeopleGet,
  projectPeopleMove,
  projectPeopleMoveWithReload,
  projectPeopleUpdate,
  projectPeopleDelete,
  projectPeoplePayloadsGet,
} from "../../actions";

import {
  getProjectPeopleCurrentPage,
  getProjectPeopleTotalPages,
  getProjectPeople,
  getCurrentProject,
  getWorkflowsCurrentData,
  getWorkflowsCurrentIncluded,
  getWorkspaceCurrent,
  getLimit,
  getPayloadsIncluded,
} from "../../reducer";

const PeopleProjectContainer = ({ viewport }) => {
  const dispatch = useDispatch();
  const workspace = useSelector(getWorkspaceCurrent);
  const project = useSelector(getCurrentProject);
  const projectPeople = useSelector(getProjectPeople);
  const currentPage = useSelector(getProjectPeopleCurrentPage);
  const totalPages = useSelector(getProjectPeopleTotalPages);
  const workflowDataCurrent = useSelector(getWorkflowsCurrentData);
  const workflowStagesCurrent = useSelector(getWorkflowsCurrentIncluded);
  const [isEditActive, setIsEditActive] = useState(false);
  const [isAlertActive, setIsAlertActive] = useState(false);
  const [isRemoveActive, setIsRemoveActive] = useState(false);
  const [activePerson, setActivePerson] = useState({});
  const [itemToRemove, setItemToRemove] = useState();
  const [personToMove, setPersonToMove] = useState({});
  const [searchBy, setSearchBy] = useState("");
  const [byStages, setByStages] = useState([]);
  const [stageOptions, setStageOptions] = useState([]);
  const limit = useSelector(getLimit);
  const peopleLink = `/workspaces/${workspace}/people`;
  const [totalSelected, setTotalSelected] = useState(0);
  const [checkAllState, setCheckAllState] = useState(false);
  const [peopleCheckboxStatus, setPeopleCheckboxStatus] = useState({});

  const payloadIncluded = useSelector(getPayloadsIncluded);

  useEffect(() => {
    if (projectPeople.loading === true) {
      return;
    }
    if (searchBy === "") {
      dispatch(
        projectPeopleGet({
          id: project.id,
          search_by: searchBy,
          by_stages: byStages,
          limit: limit,
        })
      );
    }
  }, [searchBy, byStages, limit]);

  const asyncReload = () => {
    dispatch(
      projectPeopleGet({
        id: project.id,
        search_by: searchBy,
        by_stages: byStages,
        limit: limit,
      })
    );
  };

  const getPersonIncludedById = (resource, id) => {
    const record = projectPeople.included.find(
      (item) => item.type === "person" && item.id === id
    );
    return record;
  };

  const getProjectPeopleList = (projectPeople) => {
    let peopleList = {
      data: [],
      links: projectPeople.links,
    };

    // eslint-disable-next-line
    projectPeople.data?.map((projectPerson, index) => {
      let personAttrs = getPersonIncludedById(
        projectPerson,
        projectPerson.attributes.person_id
      );

      personAttrs["project_person_id"] = projectPerson.id;
      personAttrs["status"] = projectPerson.attributes.status;
      personAttrs["relationships"] = projectPerson.relationships;
      personAttrs["current_sequence_processor"] = projectPerson?.meta?.current_sequence_processor;
      peopleList.data.push(personAttrs);
    });

    return peopleList;
  };

  const people = getProjectPeopleList(projectPeople);

  useEffect(() => {
    if (workflowDataCurrent?.id) {
      setWorkflowStageOptions(workflowDataCurrent, workflowStagesCurrent);
    }
  }, [workflowDataCurrent]);

  useEffect(() => {
    const isActive = R.propEq("variant", "active");
    const onlyLabels = (stage) => stage.label;
    const activeStages = R.map(onlyLabels, R.filter(isActive, stageOptions));

    setByStages(activeStages);
  }, [stageOptions]);

  useEffect(() => {
    let count = 0;
    for (const id in peopleCheckboxStatus) {
      if (peopleCheckboxStatus[id]) {
        count = count + 1;
      }
    }
    setTotalSelected(count);
  }, [peopleCheckboxStatus]);

  const handleSelectedStage = (selectedStage) => {
    const variant = selectedStage.variant === "ready" ? "active" : "ready";

    const selectedOptions = stageOptions.map((stage) => {
      if (stage.label === selectedStage.label) {
        return { ...stage, variant: variant };
      }
      return stage;
    });

    setStageOptions(selectedOptions);
  };

  const setWorkflowStageOptions = (workflowData, stagesData) => {
    const relatedStages = getRelated(
      workflowData.relationships.stages.data,
      stagesData
    );

    const options = relatedStages.map((stage) => ({
      variant: "ready",
      label: stage.attributes.name,
      hasSequence: stage.meta.sequence_name ? true : false,
    }));

    setStageOptions(options);
  };

  const editPersonHandler = (person) => {
    setActivePerson(person);
    setIsEditActive(!isEditActive);
  };

  const closeEditModalHandler = () => {
    setIsEditActive(false);
  };

  const handleSubmitPersonEdit = (attrs) => {
    dispatch(
      projectPeopleUpdate({
        ...attrs,
        workspace_id: project.attributes.workspace_id,
        project_id: project.id,
        id: activePerson.attributes.id,
        search_by: searchBy,
        by_stages: byStages,
        limit: limit,
      })
    );
  };

  const removeProjectPersonHandler = (project_people) => {
    dispatch(
      projectPeopleDelete({
        id: project_people.attributes.project_person_id,
        project_id: project.id,
        search_by: searchBy,
        by_stages: byStages,
        limit: limit,
      })
    );
    closeRemoveModalHandler();
  };

  const removeModalHandler = (removeItem) => {
    setItemToRemove({
      attributes: {
        ...removeItem.attributes,
        name: removeItem.attributes.first_name + " " + removeItem.attributes.last_name,
        project_person_id: removeItem.project_person_id,
      },
    });
    setIsRemoveActive(true);
  };

  const closeRemoveModalHandler = () => {
    setIsRemoveActive(false);
  };

  const movePersonStatusHandler = (
    project_person_id,
    stage_id,
    person,
    moveTo,
    currentStage
  ) => {
    let hasSequence = getSequenceProcessor(project_person_id, currentStage);
    if (hasSequence) {
      setPersonToMove({
        person: person,
        id: project_person_id,
        stage: stage_id,
        moveTo: moveTo,
      });
      setIsAlertActive(true);
    } else
      dispatch(
        projectPeopleMove({
          stage_id: stage_id,
          project_id: project.id,
          id: project_person_id,
          search_by: searchBy,
          by_stages: byStages,
          limit: limit,
        })
      );
  };
  const closeAlertSequenceHandler = () => {
    setIsAlertActive(false);
  };

  const confirmMovePersonHandler = (personToMove) => {
    dispatch(
      projectPeopleMoveWithReload({
        project_id: project.id,
        id: personToMove.id,
        stage_id: personToMove.stage,
        search_by: searchBy,
        limit: limit,
        by_stages: byStages,
      })
    );
    setIsAlertActive(false);
  };

  const handleChangeSearchBy = (event) => {
    setSearchBy(event.target.value);
  };

  const handleEnterSearchBy = (event) => {
    dispatch(
      projectPeopleGet({
        id: project.id,
        search_by: searchBy,
        by_stages: byStages,
        limit: limit,
      })
    );
  };

  const rowMenuOptions = [
    { name: "Edit", callback: editPersonHandler },
    {
      name: "Remove",
      alert: removeModalHandler,
    },
  ];

  const firstPagePagination = () => {
    dispatch(
      projectPeopleGet({
        url: projectPeople.links.first,
        by_stages: byStages,
        limit: limit,
      })
    );
  };

  const lastPagePagination = () => {
    dispatch(
      projectPeopleGet({
        url: projectPeople.links.last,
        by_stages: byStages,
        limit: limit,
      })
    );
  };

  const nextPagePagination = () => {
    dispatch(
      projectPeopleGet({
        url: projectPeople.links.next,
        by_stages: byStages,
        limit: limit,
      })
    );
  };

  const prevPagePagination = () => {
    dispatch(
      projectPeopleGet({
        url: projectPeople.links.prev,
        by_stages: byStages,
        limit: limit,
      })
    );
  };

  const getSequenceProcessor = (personId, stageId) => {
    return payloadIncluded.filter(
      (item) =>
        item.type === "sequence_processor" &&
        item.relationships.project_person.data.id === personId &&
        item.relationships.stage.data.id === stageId
    )[0];
  };

  const handleCheckAll = (event) => {
    setCheckAllState(!checkAllState);
    const newPeopleCheckedStatus = {};
    people.data.forEach((person) => {
      newPeopleCheckedStatus[person.id] = event.target.checked;
    });
    setPeopleCheckboxStatus(newPeopleCheckedStatus);
  };

  const handleCheckbox = (event) => {
    setPeopleCheckboxStatus({
      ...peopleCheckboxStatus,
      [event.target.value]: event.target.checked,
    });
  };

  return projectPeople.loading ? (
    <Spinner />
  ) : searchBy.length <= 0 &&
    byStages.length <= 0 &&
    people.data?.length <= 0 ? (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width={viewport > 2000 ? "85%" : "95%"}
      backgroundColor={theme.colors.lighterGrey}
    >
      <ProjectPeopleIntro
        peopleLink={peopleLink}
        viewport={viewport}
        project={project}
      />
    </Flex>
  ) : (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width={viewport > 2000 ? "85%" : "95%"}
      backgroundColor={theme.colors.lighterGrey}
    >
      <EditPersonModalContainer
        person={activePerson}
        isEditActive={isEditActive}
        closeEditModalHandler={closeEditModalHandler}
        handleSubmitEdit={handleSubmitPersonEdit}
      />
      {isRemoveActive && (
        <ModalRemove
          removeItem={itemToRemove}
          title="Remove Person"
          handleRemoveClick={removeProjectPersonHandler}
          closeModal={closeRemoveModalHandler}
          modalType="remove"
        />
      )}
      {isAlertActive && (
        <AlertSequence
          data={personToMove}
          closeModal={closeAlertSequenceHandler}
          handleSubmitClick={() => confirmMovePersonHandler(personToMove)}
        ></AlertSequence>
      )}
      {
        <PeopleList
          asyncReload={asyncReload}
          addPeopleBtn={true}
          rowMenuOptions={rowMenuOptions}
          stageOptions={stageOptions}
          project={project}
          workspace={workspace}
          people={people}
          movePersonStatusHandler={movePersonStatusHandler}
          handleChangeSearchBy={handleChangeSearchBy}
          handleEnterSearchBy={handleEnterSearchBy}
          searchBy={searchBy}
          firstPage={firstPagePagination}
          lastPage={lastPagePagination}
          prevPage={prevPagePagination}
          nextPage={nextPagePagination}
          currentPage={currentPage}
          totalPages={totalPages}
          getSequenceProcessor={getSequenceProcessor}
          handleCheckbox={handleCheckbox}
          peopleCheckboxStatus={peopleCheckboxStatus}
          totalSelected={totalSelected}
          tableBarContent={
            <StepsSelector
              hasScroll
              onSelect={handleSelectedStage}
              content={stageOptions || []}
              checkAllState={checkAllState}
              handleCheckAll={handleCheckAll}
            />
          }
        />
      }
    </Flex>
  );
};

export default PeopleProjectContainer;
