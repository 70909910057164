import React from "react";
import styled from "styled-components";
import theme from "@theme";

import Flex from "../../components/ui/flex";
import Microsoft from "@ui/microsoft/microsoftBtn";

const StyledButton = styled.div`
  height: 40px;
  border: 1px solid lightgrey;
  display: flex;
  width: 200px;
  align-items: center;
  padding: 0px 12px;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 2px 4px #e3e3e3;
  }
`;

const StyledText = styled.div`
  font-family: ${theme.fonts[2]};
  font-size: 14px;
  margin-left: 12px;
`;

const SignInMicrosoftContainer = (props) => {
  const oauthEndpoint =
    process.env.REACT_APP_API_BASE_URL +
    "/auth/microsoft_graph_auth?option=signin&origin=" +
    process.env.REACT_APP_BASE_URL +
    "/auth/microsoft_graph_auth";

  const handleClick = () => {
    window.location.href = oauthEndpoint;
  };

  return (
    <Flex margin="8px 0px">
      <StyledButton onClick={handleClick}>
        <Microsoft />
        <StyledText>Sign in with Microsoft</StyledText>
      </StyledButton>
    </Flex>
  );
};

export default SignInMicrosoftContainer;
