export const delayInWords = (minutes) => {
  const [d, h, m] = delaySplitDHM(minutes);

  const dDisplay = d > 0 ? d + "d " : "";
  const hDisplay = h > 0 ? h + "h " : "";
  const mDisplay = m > 0 ? m + "m" : "";
  let result = dDisplay + hDisplay + mDisplay;

  if (result === "") {
    result = "No delay";
  }
  return result;
};

export const delaySplitDHM = (minutes) => {
  const totalMinutes = Number(minutes) || 0;
  const d = Math.floor(totalMinutes / 1440);
  const h = Math.floor((totalMinutes % 1440) / 60);
  const m = Math.floor(totalMinutes % 60);

  return [d, h, m];
};

export const delayJoinDHM = (days, hours, minutes) => {
  const mins = parseInt(minutes) || 0;
  const hoursInMins = parseInt(hours) * 60 || 0;
  const daysInMins = parseInt(days) * 24 * 60 || 0;

  return mins + hoursInMins + daysInMins;
};
