import React from "react";
import theme from "../../../theme";
import styled from "styled-components";

import Box from "../../ui/box";
import Flex from "../../ui/flex";
import Toggle from "@ui/toggle-switch";

import { delayInWords } from "../../../utils/date";
import DOMPurify from 'dompurify';

const StyledCard = styled.div`
  display: flex;
  background-color: white;
  justify-content: space-between;
  flex-direction: row;
  flex-flow: wrap;
  box-shadow: 0px 2px 4px #8e8e8e60;
  width: 100%;
  margin: 20px 0px;
  padding: 20px;
`;

const CardLabel = styled.div`
  display: flex;
  font-family: ${theme.fonts[1]};
  font-size: 0.8rem;
  padding-right: 20px;
  justify-content: flex-end;
  width: ${(props) => (props.width ? props.width : "90px")};
`;

const CardBody = styled.div`
  width: 100%;
  padding: 20px;
  display: block;
  font-size: 0.9rem;
  border: 1px solid ${theme.colors.darkBlue};
  border-radius: 3px;
`;

const CardRow = styled.div`
  width: ${(props) => (props.short ? "45%" : "100%")};
  font-size: 0.9rem;
  padding: 5px;
  display: flex;
  border-bottom: 1px solid ${theme.colors.darkBlue};
`;

const PreviewStep = ({ step, indexStep }) => {
  return (
    <Flex key={indexStep} justifyContent="space-evenly" width="100%">
      <Box width="70%">
        <StyledCard>
          <Flex marginBottom="10px" width="100%" alignItems="center">
            <CardLabel>From</CardLabel>
            <CardRow short>{step.attributes.from || step.attributes.provider_name}</CardRow>
            <CardLabel>Delay</CardLabel>
            <CardRow short>{delayInWords(step.attributes.delay)}</CardRow>
          </Flex>
          <Flex marginBottom="20px" width="100%" alignItems="center">
            <CardLabel>Subject</CardLabel>
            <CardRow>{step.attributes.template_subject}</CardRow>
            <CardLabel width="inherit">Use email account signature?</CardLabel>
            <Toggle customeLabel={" "} initialValue={step.attributes.has_provider_signature} disabled={true} />
          </Flex>
          <CardBody
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(step.attributes.template_body),
            }}
          />
          <Flex marginBottom="10px" width="100%" alignItems="center"></Flex>
        </StyledCard>
      </Box>
    </Flex>
  );
};

export default PreviewStep;
