import React from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import theme from "../../../theme";

const StyledSvg = styled("svg")(
  css`
    ${(prop) =>
      prop.linkable
        ? `&:hover {
      filter: drop-shadow(-1px 1px 1px #4BAAC8); cursor: pointer;}`
        : ""}

    ${(prop) =>
      prop.disabled ? "filter: drop-shadow(-2px 2px 3px black);" : ""}
  `,
  variant({
    variants: {
      white: {
        fill: theme.colors.white,
      },
      lighterBlue: {
        fill: theme.colors.lighterBlue,
      },
      lightBlue: {
        fill: theme.colors.lightBlue,
      },
      blue: {
        fill: theme.colors.blue,
      },
      darkBlue: {
        fill: theme.colors.darkBlue,
      },
      darkerBlue: {
        fill: theme.colors.darkerBlue,
      },
      lighterGrey: {
        fill: theme.colors.lighterGrey,
      },
      lightGrey: {
        fill: theme.colors.lightGrey,
      },
      grey: {
        fill: theme.colors.grey,
      },
      lightRed: {
        fill: theme.colors.lightRed,
      },
      red: {
        fill: theme.colors.red,
      },
      lighterOrange: {
        fill: theme.colors.lighterOrange,
      },
      lightOrange: {
        fill: theme.colors.lightOrange,
      },
      orange: {
        fill: theme.colors.orange,
      },
    },
  })
);

const Template = ({ size, variant, ...props }) => {
  return (
    <StyledSvg {...props} width={size} height={size} viewBox="2.5 2.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" variant={variant}>
      <path fillRule="evenodd" clipRule="evenodd" d="M18.4224 3.45231H10.1062C7.50616 3.44231 5.37491 5.51481 5.31366 8.11356V21.5048C5.25616 24.1461 7.34991 26.3348 9.99116 26.3936C10.0299 26.3936 10.0674 26.3948 10.1062 26.3936H20.0924C22.7099 26.2873 24.7724 24.1248 24.7537 21.5048V10.0473L18.4224 3.45231Z" stroke="#515050" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18.0942 3.4375V7.07375C18.0942 8.84875 19.5292 10.2875 21.3042 10.2925H24.748" stroke="#363636" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17.8604 19.1981H11.1104" stroke="#363636" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.3044 14.5074H11.1094" stroke="#363636" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </StyledSvg>
  );
};

export default Template;
