import React from "react";
import { bool, func, string } from "prop-types";
import { useForm } from "react-hook-form";
import { color, typography, height, flex, layout } from "styled-system";
import styled, { css } from "styled-components";

import theme from "@theme";

import Box from "@ui/box";
import Text from "@ui/text";
import Flex from "@ui/flex";
import Input from "@ui/input";
import Submit from "@ui/input-submit";
import { Link } from "react-router-dom";

import SignInMicrosoftContainer from "../../../containers/account/SignInMicrosoftContainer";
import SignInGoogleContainer from "../../../containers/account/SignInGoogleContainer";

const propTypes = {
  emailVal: string,
  handleProjectslick: func,
  handlePeoplelick: func,
  handleSetEmail: func,
  handleSetPassword: func,
  handleSubmitClick: func,
  isAuthLoading: bool,
  passwordVal: string,
};

const StyledError = styled.span`
  display: flex;
  font-style: italic;
  color: ${theme.colors.red};
  font-size: 0.7rem;
  padding-top: 4px;
`;

const StyledImage = styled.div`
  display: flex;
  min-height: 100vh;
  width: 60%;
  background-image: url("/images/team.jpg");
  background-size: cover;
`;

const commonInputStyles = css`
  color: ${theme.colors.grey };
  cursor: inherit;
  background: transparent;
  border: 0;
  font-family: ${theme.fonts[1]};
  font-size: 1rem;
  display: block;
  width: 100%;

  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${({ error, disabled, theme: { colors } }) =>
      error
        ? theme.colors.grey
        : disabled
        ? theme.colors.lightGrey
        : theme.colors.darkBlue};
    font-style: italic;
    font-size: 0.8rem;
  }
`;

const InputWrapper = styled(Box)`
  position: relative;
  width: auto;
  display: "block";

  ${({ onClick }) => onClick && `cursor: pointer;`};
  ${flex};
  ${layout};
`;

const InputGroup = styled(Box)`
  border-color: ${({ error, disabled, theme: { colors } }) =>
    error
      ? theme.colors.red
      : disabled
      ? theme.colors.lightGrey
      : color};
  border-style: solid;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  border-width: 0 0 1px 0;
  ${color}
`;

const StyledInput = styled.input`
  ${commonInputStyles}
  height: auto;
  padding-top: 12px;
  padding-bottom: 12px;
  ${({ confirmed, disabled, error }) =>
    confirmed || disabled || error ? `padding-right: 28px;` : 0};
  ${color}
  ${typography}

  &[type="number"] {
    padding-top: 6px;
    padding-bottom: 6px;
    appearance: textfield;
    margin: 0;
  }
  &::-ms-clear {
    display: none;
  }
  &::placeholder {
    ${color}
  }
`;

const ErrorLine = styled.span`
  color: ${theme.colors.red};
  font-size: 0.7rem;
  font-weight: 200;
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
`;

const SignIn = ({
  errorMessage,
  inError,
  handleSubmitClick,
  isAuthLoading,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    shouldUseNativeValidation: false,
    shouldFocusError: true
  });

  return (
    <Flex
      alignItems={"center"}
      minHeight={"100vh"}
      justifyContent={"center"}
      px={{ sm: 2, md: 0 }}
      width={1}
      overflow="hidden"
    >
      <StyledImage />
      <Flex
        flexDirection="column"
        height={"100%"}
        width={0.4}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <form onSubmit={handleSubmit(handleSubmitClick)} noValidate>
          <div
            style={{
              width: "100%",
              fontFamily: "Open Sans",
              textAlign: "center",
            }}
          >
            <h2
              style={{
                color: "#585858",
                marginBottom: "1rem",
                fontWeight: "600",
                fontSize: "2rem",
                marginTop: "5rem",
              }}
            >
              WELCOME BACK
            </h2>
            <h3
              style={{
                color: "#585858",
                marginBottom: "2rem",
                fontWeight: "300",
                fontSize: "1rem",
              }}
            >
              PLEASE LOGIN TO YOUR ACCOUNT
            </h3>
          </div>
          <Box mb={3} width="300px">
            <InputWrapper>
              <Text
                fontSize={"0.9rem"}
                fontFamily={theme.fonts[1]}
                fontWeight={0}
                mb={1}
                pt={2}
              >
                Email
              </Text>

              <InputGroup error={errors.email ? true : false} >
                <StyledInput
                  { ...register("email", {
                    required: true,
                    pattern: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i, }
                  )}
                  placeholder={"Email address"}
                  type={"email"}
                />
              </InputGroup>
            </InputWrapper>
            {errors.email && <StyledError>Valid email is required</StyledError>}
          </Box>

          <Box mb={4} width="300px">
            <InputWrapper>
              <Text
                fontSize={"0.9rem"}
                fontFamily={theme.fonts[1]}
                fontWeight={0}
                mb={1}
                pt={2}
              >
                Password
              </Text>

              <InputGroup error={errors.password ? true : false} >
                <StyledInput
                  {...register("password", { required: true }) }
                  placeholder={"Password"}
                  type={"password"}
                />
              </InputGroup>
            </InputWrapper>
            {errors.password && <StyledError>This field is required</StyledError>}
          </Box>
          <Flex justifyContent="right">
            <Link
              style={{
                color: "#F45C2C",
                marginBottom: "0.5rem",
                fontWeight: "400",
                fontSize: "10px",
                textAlign: "right",
                width: "100%",
              }}
              to={"/reset_password"}
            >
              Forgot Password?
            </Link>
          </Flex>
          <Submit variant="primary" content="Login" />
        </form>
        <Flex marginTop="15px" flexDirection="column" alignItems="center">
          <SignInGoogleContainer />
          <SignInMicrosoftContainer />
        </Flex>
      </Flex>
    </Flex>
  );
};

SignIn.propTypes = propTypes;

export default SignIn;
