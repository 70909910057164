import { combineEpics, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, flatMap, map, mapTo, mergeAll } from "rxjs/operators";
import { toast } from "react-toastify";

import {
  INVITATIONS_GET,
  INVITATIONS_GET_SUCCESS,
  invitationsGet,
  invitationsGetSuccess,
  invitationsGetFailure,
  onInvitationsLoaded,
  PROJECT_INVITATIONS_GET,
  projectInvitationsGetSuccess,
  projectInvitationsGetFailure,
  WORKSPACE_INVITATIONS_GET,
  workspaceInvitationsGetSuccess,
  workspaceInvitationsGetFailure,
  INVITATION_ACCEPT,
  invitationAcceptSuccess,
  invitationAcceptFailure,
  INVITATION_REJECT,
  invitationRejectSuccess,
  invitationRejectFailure,
  workspacesGet,
} from "../actions";

// Requests
export const invitationsFetch = (api) => api.get("/invitations");
//////////////////////////////////////////////

const getInvitationsEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(INVITATIONS_GET),
    // eslint-disable-next-line
    flatMap(({}) => {
      return from(invitationsFetch(api)).pipe(
        map(invitationsGetSuccess),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map(error => toast.error(error.detail));
          return of(invitationsGetFailure({ err, errors }));
        })
      );
    })
  );

const getProjectInvitationsEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(PROJECT_INVITATIONS_GET),
    // eslint-disable-next-line
    flatMap(({ payload: { project_id } }) => {
      return from(
        api.get(`/projects/${project_id}/invitations`).then((resp) => resp)
      ).pipe(
        map(projectInvitationsGetSuccess),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map(error => toast.error(error.detail));
          return of(projectInvitationsGetFailure({ err, errors }));
        })
      );
    })
  );

const getWorkspaceInvitationsEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(WORKSPACE_INVITATIONS_GET),
    flatMap(({ payload: { workspace_id } }) => {
      return from(
        api.get(`/workspaces/${workspace_id}/invitations`).then((resp) => resp)
      ).pipe(
        map(workspaceInvitationsGetSuccess),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map(error => toast.error(error.detail));
          return of(workspaceInvitationsGetFailure({ err, errors }));
        })
      );
    })
  );

const acceptInvitationEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(INVITATION_ACCEPT),
    flatMap(({ payload: { id } }) => {
      return from(
        api
          .put(`/invitations/${id}/accept`, {
            data: {
              type: "invitation",
              attributes: {},
            },
          })
          .then((resp) => resp)
      ).pipe(
        map(invitationAcceptSuccess),
        mapTo([invitationsGet({}), workspacesGet({})]),
        mergeAll(),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map(error => toast.error(error.detail));
          return of(invitationAcceptFailure({ err, errors }));
        })
      );
    })
  );

const rejectInvitationEpic = (action$, state$, { api }) =>
  action$.pipe(
    ofType(INVITATION_REJECT),
    flatMap(({ payload: { id } }) => {
      return from(
        api
          .put(`/invitations/${id}/reject`, {
            data: {
              type: "invitation",
              attributes: {},
            },
          })
          .then((resp) => resp)
      ).pipe(
        map(invitationRejectSuccess),
        mapTo(invitationsGet({})),
        catchError((err) => {
          const errors = err.response ? err.response.data.errors : [];
          errors.map(error => toast.error(error.detail));
          return of(invitationRejectFailure({ err, errors }));
        })
      );
    })
  );

const invitationsChanged = (action$, state$) =>
  action$.pipe(
    ofType(INVITATIONS_GET_SUCCESS),
    map(({payload}) => onInvitationsLoaded(payload.data))
  )

export default combineEpics(
  getInvitationsEpic,
  getProjectInvitationsEpic,
  getWorkspaceInvitationsEpic,
  acceptInvitationEpic,
  rejectInvitationEpic,
  invitationsChanged
);
