import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import styled from "styled-components";
import theme from "@theme";

//components
import Box from "@ui/box";
import Dropdown from "@ui/dropdown";
import Flex from "@ui/flex";
import TableBar from "@ui/table-bar";
import Text from "@ui/text";
import Picture from "@ui/picture";
import Row from "@ui/row";
import Status from "@ui/status";
import Avatar from "@ui/avatar/index";

import Image from "@assets/img/avatar.png";

//icons
import Calendar from "@ui/icons/calendar";
import Remove from "@ui/icons/trash";
import Add from "@ui/icons/add";

//containers
import InviteToProjectModalContainer from "@containers/invitations/InviteToProjectModalContainer";

//Reducers
import { getProjectUser, getCurrentUser, getProjectUserErrors, getWorkspaceUser } from "@reducer";

//Helper
import {
  isAllowedRole,
  getAllowedRoles,
  isLastOwner,
} from "@utils/user-role-helper";
import { user2Title } from "@utils/user-helper";
import { isArchivedProject } from "@utils/project-helper";

const InfoBox = styled(Box)`
  padding: 0px 5px;
  width: 300px;
  font-family: "Open Sans";
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const UserName = styled(Text)`
  font-size: 1.2rem;
  color: ${theme.colors.gray};
  font-family: "Open Sans";
  flex-wrap: nowrap;
  ${({ capitalize }) =>
    capitalize &&
    `
    text-transform: capitalize;
  `}
`;

const UserData = styled(Text)`
  align-items: center;
  font-size: 0.9rem;
  display: flex;
  flex-direction: row;
  color: ${theme.colors.gray};
  font-family: "Open Sans";
  flex-wrap: nowrap;
`;

const StyledButton = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;

  &:hover {
    cursor: pointer;
    * svg {
      filter: drop-shadow(0px 0px 2px #cfcfcf);
    }

    span {
      text-shadow: 0px 0px 2px #cfcfcf;
    }
  }
`;

const StyleIcon = styled.div`
  width: 20px;
  height: 20px;
`;

const AccessList = ({
  project,
  projectAccessList,
  deleteProjectUserHandler,
  updateUserRoleHandler,
  viewport,
}) => {
  const projectUserErrors = useSelector(getProjectUserErrors);

  const currentUser = useSelector(getCurrentUser);
  const currentProjectUser = useSelector(getProjectUser(project, currentUser));
  const currentWorkspaceUser = useSelector(getWorkspaceUser(project.attributes.workspace_id, currentUser));
  const currentUserEditAllowed = isAllowedRole(currentProjectUser?.attributes?.role) || isAllowedRole(currentWorkspaceUser?.attributes?.role);

  useEffect(() => { }, [projectAccessList]);

  const strTitleize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  return (
    <Flex
      marginTop="3%"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width={viewport > 2000 ? "85%" : "95%"}
      backgroundColor={theme.colors.lighterGrey}
    >
      {projectUserErrors?.map((error, index) => (
        <Text color="red" key={index}>
          {error.detail}
        </Text>
      ))}

      <TableBar
        iconButtons={
          !isArchivedProject(project) && <InviteToProjectModalContainer showButton project={project} />
        }
      />

      {projectAccessList.map((projectUser, index) => (
        <Row key={index}>
          <InfoBox>
            <Box marginRight="15px">
              <Picture bgImage={Image}></Picture>
            </Box>
            <Box justifyContent="space-evenly">
              <UserName capitalize={projectUser?.first_name}>
                {user2Title(projectUser)}
              </UserName>
            </Box>
          </InfoBox>
          <Box width="220px">
            {currentUserEditAllowed && !isLastOwner(projectAccessList, projectUser) ? (
              <Flex alignItems="center">
                <Avatar
                  noLink="true"
                  size="small"
                  variant="blue"
                  content={
                    projectUser.role.charAt(0) + projectUser.role.charAt(1)
                  }
                />
                <Dropdown
                  name={projectUser.id}
                  formStyle
                  placeholder={strTitleize(projectUser.role)}
                  variant="blue"
                  options={getAllowedRoles(currentProjectUser?.attributes.role)}
                  handleDropdown={updateUserRoleHandler}
                  width={"100%"}
                />
              </Flex>
            ) : (
              <Flex alignItems="center">
                <Avatar
                  noLink="true"
                  size="small"
                  variant="blue"
                  content={
                    projectUser.role.charAt(0) + projectUser.role.charAt(1)
                  }
                />
                <UserData>{strTitleize(projectUser.role)}</UserData>
              </Flex>
            )}
          </Box>
          <Flex width="150px" justifyContent="flex-start">
            <Status status="active" />
          </Flex>
          <Flex width="150px" justifyContent="flex-start">
            <UserData icon={<Calendar variant="blue" />}>
              {moment(projectUser.last_activity).calendar() ||
                "No recent activity"}
            </UserData>
          </Flex>
          <Box width="20px">
            {currentUserEditAllowed && !isLastOwner(projectAccessList, projectUser) ? (
              <StyledButton
                onClick={() => deleteProjectUserHandler(projectUser.id)}
              >
                <Remove linkable variant="red" />
              </StyledButton>
            ) : (
              <></>
            )}
          </Box>
        </Row>
      ))}
    </Flex>
  );
};

export default AccessList;
